<template>
  <div>
    <div v-show="this.index === '13_0'">
      <v-card-title class="comm_title1" 
        >ANNEX. Vulnerability Script Inventory
      </v-card-title>
      <v-card-text>
        This system's Vulnerability Scanning feature utilizes over 700 distinct scan scripts to probe the vulnerabilities of open ports on target IP devices, gathering the results. <br />These scripts vary in type. For instance, some scripts are lightweight, gathering only information without affecting the target device. In contrast, other scripts are heavier; not only do they collect vulnerability data from open ports, but they also exploit these vulnerabilities, potentially impacting the target device.
        <br />This Demo system, designed for demonstration purposes, has only the lightweight scripts enabled (marked as "Enable" in the table below) while the heavier ones are disabled (indicated by "-" in the table below).
        <br />In response to customer requirements, our engineers can customize the system. We will selectively apply the 700 scan scripts to ensure the system aligns with the customer's objectives and environment. 
Additionally, our WiKi Lab's experts consistently develop new vulnerability scanning scripts by regularly analyzing emerging vulnerabilities. 
        <br /><br />
        You can use the search function below to access basic information about the Vulnerability Scan Scripts. It will also allow you to check which scripts are currently applied to the system or not (marked as 'Enable' or 'Disable')
      </v-card-text>
      
      <span style="font: 7pt 'Times New Roman'"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>

      <div>
        <v-row style="flex-wrap: inherit; max-width: 60%">
          <v-text-field
            label="Search"
            hide-details=""
            v-model="nseSearch"
            outlined
            solo
            flat
            dense
            style="font-size: 14px; margin-left: 12px"
            class="pl-3 pt-4 pb-0 mb-2 font-weight-light v-input__slot"
          >
          </v-text-field>

          <v-btn
            elevation="0"
            height="32px"
            min-width="32px"
            class="ml-2 mt-4 border"
            style="border-color: #999 !important; width: 30px"
            color="blue-grey lighten-5"
          >
            <v-icon size="175%"> mdi-magnify </v-icon>
          </v-btn>

          <v-btn
            elevation="0"
            height="32px"
            min-width="32px"
            class="ml-2 mt-4 border"
            style="border-color: #999 !important; width: 30px"
            color="blue-grey lighten-5"
          >
            <v-icon size="175%"> mdi-cached </v-icon>
          </v-btn>
        </v-row>
      </div>

      <div>
        <v-data-table
          class="v-data-table"
          style="margin-left: 14px; max-width: 97%"
          :headers="headers"
          :items="category_able"
          :search="nseSearch"
          dense
          :custom-filter="scriptNameFilter"
        >

          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ item.status ? "Enable" : "--" }}
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["index"],
  data() {
    return {
      nseSearch: ``,
      category_able: [],
      headers: [
        {
          text: `Script Name`,
          value: `script_name`,
          align: "center",
          sortable: true,
          width: "11%",
        },
        {
          text: `Category`,
          value: `category_text`,
          align: "center",
          sortable: false,
        },
        // {text: `Safe`, value:`safe`, align: 'center', sortable: false},
        // {text: `Default`, value:`default`, align: 'center', sortable: false},
        // {text: `Discovery`, value:`discovery`, align: 'center', sortable: false},
        // {text: `Intrusive`, value:`intrusive`, align: 'center', sortable: false},
        // {text: `brute`, value:`brute`, align: 'center', sortable: false},
        // {text: `Exploit`, value:`exploit`, align: 'center',  sortable:false},
        // {text: `Vuln`, value:`vuln`, align: 'center', sortable: false},
        // {text: `Auth`, value:`auth`, align: 'center', sortable: false},
        // {text: `Version`, value:`version`, align: 'center', sortable: false},
        // {text: `External`, value:`external`, align: 'center', sortable: false},
        // {text: `Malware`, value:`malware`, align: 'center', sortable: false},
        // {text: `Broadcast`, value:`broadcast`, align: 'center', sortable: false},
        // {text: `Dos`, value:`dos`, align: 'center', sortable: false},
        // {text: `Fuzzer`, value:`fuzzer`, align: 'center', sortable: false},

        {
          text: `Description`,
          value: `description`,
          align: "left",
          sortable: false,
        },
        {
          text: `En/Disable`,
          value: `status`,
          align: "center",
          sortable: true,
          width: "10%",
        },
      ],
    };
  },

  methods: {
    getNSEscript: function () {
      axios
        .get(`https://rav.wikisecurity.net/php-api.php/help/getallhelp`)
        .then((response) => {
          // console.log('Users get: ', response.data);
          this.category_able = response.data;
        });
    },

    scriptNameFilter(value, search, item) {
      return item.script_name.toLowerCase().includes(search.toLowerCase());
    },

    //   scriptNameFilter(value, search, item) {
    //   return item.script_name.toLowerCase().includes(search.toLowerCase());
    // },
  },

  mounted() {
    this.getNSEscript();
  },
};
</script>

<style scoped>
@import "./css/help.css";

.v-expansion-panel-content /deep/ .v-expansion-panel-content__wrap {
  padding: 0 12px 8px !important;
}

.v-text-field /deep/ .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
}

.v-data-table
  /deep/
  .v-data-table__wrapper
  /deep/
  table
  /deep/
  thead
  /deep/
  tr
  /deep/
  th {
  padding: 0 12px;
  margin-right: 2px !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 6px;
}

.v-data-table /deep/ .v-data-table__wrapper > table {
  border-bottom: solid 1px gainsboro;
}

.theme--light.v-data-table
  /deep/
  .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.expansion-cardbox {
  height: 180px;
  overflow-y: auto;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>
