<template>
  <div>
    <!-- title 1 -->
    <div style="margin-left:30px; margin-top:20px;" v-show="this.index === '1_0' || this.index === '1_1'">

      <iframe width="600" height="400" src="https://www.youtube.com/embed/kE94jeUKbJs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
     
    </div>

    <!-- title2 -->
    <div style="margin-left:30px; margin-top:20px;" v-show="this.index === '2_0' || this.index === '2_1'">
      
      <iframe width="600" height="400" src="https://www.youtube.com/embed/ZP6E1wHo51M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      
    </div>

    
    <div v-show="this.index === '3_0'">
      <v-card-title class="comm_title2" ref="3_1" id="lo3_1"
        >3.1. Basic Syntax for Search Keyword</v-card-title
      >
      <ul class="comm_ul">
        <li>
          <span class="comm_bullet">l</span
          ><span class="comm_space">&nbsp;&nbsp;</span> The search keywords
          might require a parameter, provided after the colon sign ":"
          <br />(e.g.: asnum:37654, service:ldap, net:41.242.142.1/24)
        </li>

        <li>
          <span class="comm_bullet">l</span
          ><span class="comm_space">&nbsp;&nbsp;</span> The value of the string type is case insensitive.
          <br />(e.g.: city:seoul, city:Seoul, os:Linux, os:linux)
        </li>
       
        <li>
          <span class="comm_bullet">l</span
          ><span class="comm_space">&nbsp;&nbsp;</span>
          If your search keyword includes spaces, you need to protect it by
          using single or double quotes.
          <br />(e.g.: banner:"SSH-2.0-OpenSSH_7.6p1 Ubuntu-4ubuntu0.3")
        </li>
      </ul>

      <div class="comm_explain_explainBox">
        <h4 class="comm_explain_explainBox_title">▶ NOTICE</h4>
        <ul type="none">
          <li>
            - Do not put a space between the search keyword, the colon sign, and
            its value.
          </li>
          <li>
            - The most of search keywords, such as version, service, product, are case insensitive by default.
          </li>
        </ul>
        <table class="comm_table_4cell">
          <tr>
            <td class="celltitle">Right</td>
            <td>os:linux service:telnet</td>
          </tr>
          <tr>
            <td class="celltitle">Wrong</td>
            <td>
              service:FTP<i>[space]</i> Anonftp<br>
              service:<i>[space]</i> FTP<br>
              tscorepoint:tscorepoint<i>[space]</i> =<i>[space]</i> 1 <br>
              net:192.168.0.1<i>[space]</i>-<i>[space]</i> 192.168.0.100
            </td>
          </tr>
        </table>
        <br />
      </div>
    </div>
    <div v-show="this.index === '4_0'">
      <v-card-title ref="4_1" id="lo4_1" class="comm_title2"
        >4.1 Examples of a Single Keyword Search</v-card-title
      >
      
      <v-card-text style="margin: 0; font-size: 10pt"
        >Search using one keyword is the simplest search method, and according to each search word, it provides user convenience with wildcard, case-insensitive, and multiple input value support functions. 
The examples of using search terms below allow you to learn how to use major search keywords and are the basic contents of using complex search terms.</v-card-text
      >

      <ul class="comm_ul">
        <li v-for="(ex,i) in this.examples[0].ex" v-bind:key="i" style="margin:20px, auto; width: 95%;">
          <h1 style="margin-left: 40pt; text-indent: -20pt">
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              line-height: 107%;
              font-family: Wingdings;
              color: #333333;
            "
            >l<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
          ><span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              line-height: 107%;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
              background: white;
            "
            >&nbsp;{{ex.title}}</span
          >
        </h1>

   <table
          class="MsoTableGrid1"
          border="1"
          cellspacing="0"
          cellpadding="0"
          style="margin:0 auto!important; width:95%; border-collapse: collapse; border: none"
        >
          <tr>
            <td
              width="116"
              style="
                width: 93.5pt;
                border: solid windowtext 1pt;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoListParagraph"
                align="center"
                style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  text-align: center;
                  line-height: normal;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >Example</span
                >
              </p>
            </td>
            <td
              width="385"
              style="
                width: 86%;
                border: solid windowtext 1pt;
                border-left: none;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoNormal"
                style="
                  margin-top: 0cm;
                  margin-right: 0cm;
                  margin-bottom: 0cm;
                  margin-left: 1.3pt;
                  margin-bottom: 0.0001pt;
                  line-height: normal;
                  background: white;
                  text-autospace: ideograph-numeric ideograph-other;
                  word-break: keep-all;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >{{ex.example}}</span
                >
              </p>
            </td>
          </tr>
          <tr>
            <td
              width="116"
              style="
                width: 93.5pt;
                border: solid windowtext 1pt;
                border-top: none;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoListParagraph"
                align="center"
                style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  text-align: center;
                  line-height: normal;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >Description</span
                >
              </p>
            </td>
            <td
              width="385"
              style="
                width: 86%;
                border-top: none;
                border-left: none;
                border-bottom: solid windowtext 1pt;
                border-right: solid windowtext 1pt;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoNormal"
                align="left"
                style="
                  margin-top: 0cm;
                  margin-right: 0cm;
                  margin-bottom: 0cm;
                  margin-left: 1.3pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  line-height: normal;
                  background: white;
                  text-autospace: ideograph-numeric ideograph-other;
                  word-break: keep-all;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >{{ex.description}}</span
                >
              </p>
            </td>
          </tr>
        </table>
        </li>
      </ul>
    </div>

    
    <div v-show="this.index === '4_0'">
      <v-card-title ref="4_2" id="lo4_2" class="comm_title2"
        >4.2 Examples of a Combined Keyword Search</v-card-title
      >
      
      <v-card-text style="margin: 0; font-size: 10pt"
        >This is a feature that provides users with optimal search results by using multiple search keywords in a complex manner. The use of compound search terms is more effective after familiarizing oneself with the usage of individual search terms, similar to conducting a Contents search using Google, where users employ multiple keywords in a combined fashion to obtain the desired and optimal search results.</v-card-text
      >

      <ul class="comm_ul">
        <li v-for="(ex,i) in this.examples[1].ex" v-bind:key="i" style="margin:20px, auto; width: 95%;">
          <h1 style="margin-left: 40pt; text-indent: -20pt">
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              line-height: 107%;
              font-family: Wingdings;
              color: #333333;
            "
            >l<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
          ><span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              line-height: 107%;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
              background: white;
            "
            >&nbsp;{{ex.title}}</span
          >
        </h1>

   <table
          class="MsoTableGrid1"
          border="1"
          cellspacing="0"
          cellpadding="0"
          style="margin:0 auto!important; width:95%; border-collapse: collapse; border: none"
        >
          <tr>
            <td
              width="116"
              style="
                width: 93.5pt;
                border: solid windowtext 1pt;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoListParagraph"
                align="center"
                style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  text-align: center;
                  line-height: normal;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >Example</span
                >
              </p>
            </td>
            <td
              width="385"
              style="
                width: 86%;
                border: solid windowtext 1pt;
                border-left: none;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoNormal"
                style="
                  margin-top: 0cm;
                  margin-right: 0cm;
                  margin-bottom: 0cm;
                  margin-left: 1.3pt;
                  margin-bottom: 0.0001pt;
                  line-height: normal;
                  background: white;
                  text-autospace: ideograph-numeric ideograph-other;
                  word-break: keep-all;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >{{ex.example}}</span
                >
              </p>
            </td>
          </tr>
          <tr>
            <td
              width="116"
              style="
                width: 93.5pt;
                border: solid windowtext 1pt;
                border-top: none;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoListParagraph"
                align="center"
                style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  text-align: center;
                  line-height: normal;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >Description</span
                >
              </p>
            </td>
            <td
              width="385"
              style="
                width: 86%;
                border-top: none;
                border-left: none;
                border-bottom: solid windowtext 1pt;
                border-right: solid windowtext 1pt;
                padding: 5.65pt 5.4pt 5.65pt 5.4pt;
              "
            >
              <p
                class="MsoNormal"
                align="left"
                style="
                  margin-top: 0cm;
                  margin-right: 0cm;
                  margin-bottom: 0cm;
                  margin-left: 1.3pt;
                  margin-bottom: 0.0001pt;
                  text-align: left;
                  line-height: normal;
                  background: white;
                  text-autospace: ideograph-numeric ideograph-other;
                  word-break: keep-all;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >{{ex.description}}</span
                >
              </p>
            </td>
          </tr>
        </table>
        </li>
      </ul>
    </div>

        
     
       
    <div v-show="this.index === '3_0'">
      <v-card-title ref="3_2" id="lo3_2" class="comm_title2">3.2 Search Keyword List and Usage</v-card-title>
      <v-card-text>
        <p
          class="MsoNormal"
          align="left"
          style="
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 21.3pt;
            margin-bottom: 0.0001pt;
            text-align: left;
            text-indent: -20pt;
            line-height: normal;
            mso-pagination: widow-orphan;
            background: white;
            text-autospace: ideograph-numeric ideograph-other;
            word-break: keep-all;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: 굴림;
              color: #333333;
              mso-font-kerning: 0pt;
            "
            ><strong>Description of the display of syntax</strong>
          </span>
        </p>
        <p
          class="MsoListParagraph"
          style="
            margin-top: 5pt;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 1cm;
            margin-bottom: 0.0001pt;
            text-indent: -14.15pt;
            mso-list: l0 level1 lfo1;
            background: white;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: Helvetica;
              color: #333333;
            "
            ><span style="mso-list: Ignore"
              >-<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
            "
            >[ ] means that there must be some input value(s)
          </span>
        </p>
        <p
          class="MsoListParagraph"
          style="
            margin-top: 5pt;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 1cm;
            margin-bottom: 0.0001pt;
            text-indent: -14.15pt;
            mso-list: l0 level1 lfo1;
            background: white;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: Helvetica;
              color: #333333;
            "
            ><span style="mso-list: Ignore"
              >-<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
            "
            >( ) means that the input value(s) may be omitted in some cases
          </span>
        </p>
        <p
          class="MsoListParagraph"
          style="
            margin-top: 5pt;
            text-align: justify;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 1cm;
            margin-bottom: 0.0001pt;
            text-indent: -14.15pt;
            mso-list: l0 level1 lfo1;
            background: white;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: Helvetica;
              color: #333333;
            "
            ><span style="mso-list: Ignore"
              >-<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
            "
            >FQDN is a domain name that specifies its exact location in the tree
            hierarchy of the Domain Name System (DNS)
          </span>
        </p>
        <p
          class="MsoListParagraph"
          style="
            margin-top: 5pt;
            text-align: justify;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 1cm;
            margin-bottom: 0.0001pt;
            text-indent: -14.15pt;
            mso-list: l0 level1 lfo1;
            background: white;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: Helvetica;
              color: #333333;
            "
            ><span style="mso-list: Ignore"
              >-<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
            "
            >; is not allowed
          </span>
        </p>
        <p
          class="MsoListParagraph"
          style="
            margin-top: 5pt;
            text-align: justify;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 1cm;
            margin-bottom: 0.0001pt;
            text-indent: -14.15pt;
            mso-list: l0 level1 lfo1;
            background: white;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: Helvetica;
              color: #333333;
            "
            ><span style="mso-list: Ignore"
              >-<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
            "
            >Two or more search keywords are separated by spacing.
          </span>
        </p>
        <p
          class="MsoListParagraph"
          style="
            margin-top: 5pt;
            text-align: justify;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 1cm;
            margin-bottom: 0.0001pt;
            text-indent: -14.15pt;
            mso-list: l0 level1 lfo1;
            background: white;
          "
        >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              mso-fareast-font-family: Helvetica;
              color: #333333;
            "
            ><span style="mso-list: Ignore"
              >-<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ></span
          >
          <span
            lang="EN-US"
            style="
              font-size: 10.5pt;
              font-family: 'Helvetica', sans-serif;
              color: #333333;
            "
            >The most of search keywords, such as version, service, product, are case insensitive by default.
          </span>
        </p>
        <br />
        <ul class="comm_ul" style="padding:0px;">
          <li v-for="(ex,i) in this.explain" :key="i" style="width:95%; margin:30px 0px;">
            <h1 style="margin-left:22pt; margin-bottom:2pt; text-indent: -20pt">
              <span
                lang="EN-US"
                style="
                  font-size: 10.5pt;
                  line-height: 107%;
                  font-family: Wingdings;
                  color: #333333;
                "
                >l<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
              ><span
                lang="EN-US"
                style="
                  font-size: 10.5pt;
                  line-height: 107%;
                  font-family: 'Helvetica', sans-serif;
                  color: #333333;
                  background: white;
                "
                >&nbsp;{{ex.title}}</span
              >
            </h1>

            <div style="width: 95%; margin:5px">
              <div v-for="(item,index) in ex.detail" :key=index
                class="MsoListParagraph"
                style="width: 95%;margin-left:13pt; text-indent: -18pt"
              >
                <span 
                  lang="EN-US"
                  style="font-size: 10.5pt; margin-left: 13pt;line-height: 107%; color: #333333"
                  >-</span
                ><span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span><span
                  lang="EN-US"
                  style="
                    font-size: 10.5pt;
                    line-height: 107%;
                    font-family: 'Helvetica', sans-serif;
                    color: #333333;
                    background: white;
                  "
                  >&nbsp;{{item}}</span
                ><br>
              </div>
            </div>

          <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
          >
            <tr>
              <td
                width="116"
                style="
                  width: 93.5pt;
                  border: solid windowtext 1pt;
                  padding: 5.65pt 5.4pt 5.65pt 5.4pt;
                "
              >
                <p
                  class="MsoListParagraph"
                  align="center"
                  style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    text-align: center;
                    line-height: normal;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10.5pt;
                      font-family: 'Helvetica', sans-serif;
                      color: #333333;
                      background: white;
                    "
                    >Example</span
                  >
                </p>
              </td>
              <td
                width="385"
                style="
                  width: 86%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 5.65pt 5.4pt 5.65pt 5.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 0cm;
                    margin-left: 1.3pt;
                    margin-bottom: 0.0001pt;
                    line-height: normal;
                    background: white;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                  >
                    <span v-for="(item,index) in ex.right" :key="index"
                      lang="EN-US"
                      style="
                        font-size: 10.5pt;
                        font-family: 'Helvetica', sans-serif;
                        color: #333333;
                        background: white;
                      "
                    >{{item}}<br></span
                    >
                  </p>
                </td>
              </tr>
            </table>
          </li>
        </ul>
        <br />
      </v-card-text>
    </div>


    <!-- ANNEX. User Registration Guide -->
    <div v-show="this.index === '9_0'">
      <v-card-title class="comm_title1"
        >ANNEX. User Registration Guide</v-card-title
      >
      <v-card-text>
        Since on-line user account is managed by system manager of WIKI
        Security, if you want to apply for a new user account, you need to
        contact our system administrator to follow the procedure below
      </v-card-text>
      <img style="width:45%!important" class="comm_img-center" src="./img/an1.png" />

      <div class="comm_explain_explainBox">
        <h4 class="comm_explain_explainBox_title">
          ▶ NOTICE – For On-Premise Version
        </h4>
        Since the On-Premise version of this system is operated by its own
        system administrator in your organization, please contact your system
        administrator if you want to get a user account.
        <br />
        <br />
      </div>
    </div>

    <!-- Blacklist IP Database -->
    <div v-show="this.index === '10_0'">
      <v-card-title class="comm_title1"
        >ANNEX. Blacklist IP Database</v-card-title
      >
      <v-card-text>
        The OSINT (Open Source Intelligence) Threat Scanning feature of this system scans for threats in the target IP device using a vast Blacklist IP address Database of over 100 million IP entries. 
        This Threat Database consolidates data from highly credible global threat information providers, numbering over 500, such as IBM X-Force Exchange, US Cert, Spamhaus.org, Project Honeypot.org, Threat Crowd, and many more. 
        <br />The applied Blacklist IP address Database in this system's OSINT Threat Scan is detailed in the table below. Our experts at WiKi Lab continuously discover and incorporate new Threat Databases into the system.
      </v-card-text>

      <div></div>
      <v-card-text style="vertical-align: middle !important">
        <table
          class="MsoNormalTable"
          border="0"
          cellspacing="0"
          cellpadding="0"
          width="90%"
          style="
            margin: 0 auto;
            table-layout: fixed;
            width: 90%;
            border-collapse: collapse;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 4.95pt 0cm 4.95pt;
            vertical-align: middle;
          "
        >
          <thead>
            <tr
              style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 24.4pt"
            >
              <td
                style="
                  width: 20%;
                  border: solid windowtext 1pt;
                  mso-border-alt: solid windowtext 0.5pt;
                  background: #f2f2f2;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  vertical-align: middle;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Name</span
                  >
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  mso-border-top-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  background: #f2f2f2;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Description</span
                  >
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  mso-border-top-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  background: #f2f2f2;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Update Cycle
                  </span>
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="mso-yfti-irow: 1; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >alienvault_reputation
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >AlienVault.com&nbsp;IP reputation database
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 2; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >asprox_c2
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >h3x.eu&nbsp;ASPROX Tracker - Asprox C&amp;C Sites
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 3; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_banjori
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 1.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of banjori
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 4; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_bebloh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of bebloh
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 5; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_c2
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;master feed of known, active and
                    non-sinkholed C&amp;Cs IP addresses
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 6; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_cl
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of cl C&amp;Cs
                    with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 7; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_cryptowall
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of cryptowall
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 8; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_dircrypt
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of dircrypt
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 9; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_dyre
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of dyre
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 10; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_geodo
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of geodo
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 11; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_hesperbot
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of hesperbot
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 12; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_matsnu
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of matsnu
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 13; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_necurs
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of necurs
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 14; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_p2pgoz
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of p2pgoz
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 15; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_pushdo
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of pushdo
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 16; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_pykspa
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of pykspa
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 17; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_qakbot
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of qakbot
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 18; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_ramnit
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of ramnit
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 19; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_ranbyus
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of ranbyus
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 20; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_simda
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of simda
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 21; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_suppobox
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of suppobox
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 22; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_symmi
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of symmi
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 23; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_tinba
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of tinba
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 24; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bambenek_volatile
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Bambenek Consulting&nbsp;feed of current IPs of volatile
                    C&amp;Cs with 90 minute lookback
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 25; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bbcan177_ms1
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >pfBlockerNG Malicious Threats
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 26; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bbcan177_ms3
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >pfBlockerNG Malicious Threats
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 27; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bds_atif
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Artillery Threat Intelligence Feed and Banlist Feed
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 28; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_any_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category any with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 29; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_any_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category any with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 30; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_any_2_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category any with score above 2
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 31; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_any_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category any with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 32; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_any_2_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category any with score above 2
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 33; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache-404_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache-404 with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 34; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache-modsec_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache-modsec with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 35; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache-noscript_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache-noscript with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 36; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache-noscript_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache-noscript with
                    score above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 37; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache-phpmyadmin_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache-phpmyadmin with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 38; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache-scriddies_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache-scriddies with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 39; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache with score above
                    0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 40; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache with score above
                    1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 41; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apache_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apache with score above
                    2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 42; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_apacheddos_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category apacheddos with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 43; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_assp_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category assp with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 44; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_asterisk_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category asterisk with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 45; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_asterisk_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category asterisk with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 46; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_badbots_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category badbots with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 47; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_badbots_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category badbots with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 48; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_bruteforce_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category bruteforce with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 49; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_bruteforce_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category bruteforce with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 50; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_cms_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category cms with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 51; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_cms_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category cms with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 52; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_cms_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category cms with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 53; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_courierauth_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category courierauth with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 54; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_courierauth_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category courierauth with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 55; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_default_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category default with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 56; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_default_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category default with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 57; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_default_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category default with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 58; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_dns_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category dns with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 59; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_dovecot-pop3imap_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category dovecot-pop3imap with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 60; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_dovecot-pop3imap_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category dovecot-pop3imap with
                    score above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 61; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_dovecot_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category dovecot with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 62; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_dovecot_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category dovecot with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 63; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_dovecot_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category dovecot with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 64; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_drupal_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category drupal with score above
                    0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 65; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_exim_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category exim with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 66; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_exim_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category exim with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 67; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ftp_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ftp with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 68; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ftp_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ftp with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 69; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ftp_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ftp with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 70; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_http_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category http with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 71; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_http_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category http with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 72; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_http_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category http with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 73; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_imap_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category imap with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 74; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_mail_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category mail with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 75; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_mail_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category mail with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 76; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_mail_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category mail with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 77; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_named_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category named with score above
                    0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 78; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_owncloud_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category owncloud with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 79; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_plesk-postfix_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category plesk-postfix with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 80; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_postfix-sasl_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category postfix-sasl with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 81; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_postfix-sasl_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category postfix-sasl with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 82; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_postfix-sasl_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category postfix-sasl with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 83; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_postfix_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category postfix with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 84; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_postfix_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category postfix with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 85; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_postfix_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category postfix with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 86; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_proftpd_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category proftpd with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 87; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_proftpd_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category proftpd with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 88; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_proftpd_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category proftpd with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 89; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_pureftpd_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category pureftpd with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 90; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_pureftpd_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category pureftpd with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 91; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_pureftpd_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category pureftpd with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 92; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_qmail-smtp_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category qmail-smtp with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 93; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_rdp_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category rdp with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 94; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sasl_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sasl with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 95; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sasl_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sasl with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 96; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sasl_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sasl with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 97; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sip_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sip with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 98; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sip_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sip with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 99; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sip_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sip with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 100; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_smtp_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category smtp with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 101; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_spam_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category spam with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 102; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_spam_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category spam with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 103; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sql-attack_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sql-attack with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 104; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sql_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sql with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 105; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ssh-blocklist_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ssh-blocklist with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 106; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ssh-ddos_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ssh-ddos with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 107; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ssh-ddos_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ssh-ddos with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 108; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ssh_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ssh with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 109; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ssh_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ssh with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 110; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_ssh_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category ssh with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 111; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sshd_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sshd with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 112; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sshd_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sshd with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 113; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_sshd_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category sshd with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 114; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_telnet_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category telnet with score above
                    0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 115; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_telnet_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category telnet with score above
                    1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 116; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_telnet_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category telnet with score above
                    2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 117; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_unknown_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category unknown with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 118; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_unknown_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category unknown with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 119; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_unknown_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category unknown with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 120; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_username-notfound_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category username-notfound with
                    score above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 121; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_voip_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category voip with score above 0
                    and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 122; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_voip_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category voip with score above 1
                    and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 123; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_voip_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category voip with score above 2
                    and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 124; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_vsftpd_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category vsftpd with score above
                    0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 125; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_vsftpd_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category vsftpd with score above
                    2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 126; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_wordpress_0_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category wordpress with score
                    above 0 and age less than 1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 127; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_wordpress_1_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category wordpress with score
                    above 1 and age less than 7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 128; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bi_wordpress_2_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BadIPs.com&nbsp;Bad IPs in category wordpress with score
                    above 2 and age less than 30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 129; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_blockchain_info_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blockchain.info&nbsp;Bitcoin nodes connected to
                    Blockchain.info.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 130; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_blockchain_info_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blockchain.info&nbsp;Bitcoin nodes connected to
                    Blockchain.info.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 131; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_blockchain_info_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blockchain.info&nbsp;Bitcoin nodes connected to
                    Blockchain.info.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 132; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_nodes
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BitNodes&nbsp;Bitcoin connected nodes, globally.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 133; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_nodes_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BitNodes&nbsp;Bitcoin connected nodes, globally.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 134; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_nodes_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BitNodes&nbsp;Bitcoin connected nodes, globally.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 135; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bitcoin_nodes_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BitNodes&nbsp;Bitcoin connected nodes, globally.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 136; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;IPs that have been detected by fail2ban
                    in the last 48 hours
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 137; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_apache
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses which have been reported
                    within the last 48 hours as having run attacks on the
                    service Apache, Apache-DDOS, RFI-Attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 138; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_bots
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses which have been reported
                    within the last 48 hours as having run attacks on the
                    RFI-Attacks, REG-Bots, IRC-Bots or BadBots (BadBots = it has
                    posted a Spam-Comment on a open Forum or Wiki).
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 139; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_bruteforce
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IPs which attacks Joomla, Wordpress
                    and other Web-Logins with Brute-Force Logins.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 140; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_ftp
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses which have been reported
                    within the last 48 hours for attacks on the Service FTP.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 141; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_imap
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses which have been reported
                    within the last 48 hours for attacks on the Service imap,
                    sasl, pop3, etc.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 142; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_mail
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses which have been reported
                    within the last 48 hours as having run attacks on the
                    service Mail, Postfix.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 143; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_sip
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses that tried to login in a
                    SIP, VOIP or Asterisk Server and are included in the IPs
                    list from infiltrated.net
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 144; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_ssh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IP addresses which have been reported
                    within the last 48 hours as having run attacks on the
                    service SSH.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 145; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_de_strongips
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Blocklist.de&nbsp;All IPs which are older then 2 month and
                    have more then 5.000 attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 146; height: 104.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist_net_ua
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blocklist.net.ua&nbsp;The BlockList project was created to
                    become protection against negative influence of the harmful
                    and potentially dangerous events on the Internet. First of
                    all this service will help internet and hosting providers to
                    protect subscribers sites from being hacked. BlockList will
                    help to stop receiving a large amount of spam from dubious
                    SMTP relays or from attempts of brute force passwords to
                    servers and network equipment.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 147; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_last
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Last 6 hours Cybercrime IPs, in all
                    categories: BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and
                    PHISHING (to download the source data you need an API key
                    from blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 148; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_last_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Last 6 hours Cybercrime IPs, in all
                    categories: BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and
                    PHISHING (to download the source data you need an API key
                    from blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 149; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_last_2d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Last 6 hours Cybercrime IPs, in all
                    categories: BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and
                    PHISHING (to download the source data you need an API key
                    from blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 150; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_last_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Last 6 hours Cybercrime IPs, in all
                    categories: BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and
                    PHISHING (to download the source data you need an API key
                    from blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 151; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_last_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Last 6 hours Cybercrime IPs, in all
                    categories: BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and
                    PHISHING (to download the source data you need an API key
                    from blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 152; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_online
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Online Cybercrime IPs, in all categories:
                    BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and PHISHING (to
                    download the source data you need an API key from
                    blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 153; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv_crimeserver_recent
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >blueliv.com&nbsp;Recent Cybercrime IPs, in all categories:
                    BACKDOOR, C_AND_C, EXPLOIT_KIT, MALWARE and PHISHING (to
                    download the source data you need an API key from
                    blueliv.com)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 154; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bm_tor
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >torstatus.blutmagie.de&nbsp;list of all TOR network servers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 155; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bogons
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Team-Cymru.org&nbsp;private and reserved addresses defined
                    by RFC 1918, RFC 5735, and RFC 6598 and netblocks that have
                    not been allocated to a regional internet registry
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 156; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botscout
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BotScout&nbsp;helps prevent automated web scripts, known as
                    bots, from registering on forums, polluting databases,
                    spreading spam, and abusing forms on web sites. They do this
                    by tracking the names, IPs, and email addresses that bots
                    use and logging them as unique signatures for future
                    reference. They also provide a simple yet powerful API that
                    you can use to test forms when they're submitted on your
                    site. This list is composed of the most recently-caught
                    bots.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 157; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botscout_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BotScout&nbsp;helps prevent automated web scripts, known as
                    bots, from registering on forums, polluting databases,
                    spreading spam, and abusing forms on web sites. They do this
                    by tracking the names, IPs, and email addresses that bots
                    use and logging them as unique signatures for future
                    reference. They also provide a simple yet powerful API that
                    you can use to test forms when they're submitted on your
                    site. This list is composed of the most recently-caught
                    bots.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 158; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botscout_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BotScout&nbsp;helps prevent automated web scripts, known as
                    bots, from registering on forums, polluting databases,
                    spreading spam, and abusing forms on web sites. They do this
                    by tracking the names, IPs, and email addresses that bots
                    use and logging them as unique signatures for future
                    reference. They also provide a simple yet powerful API that
                    you can use to test forms when they're submitted on your
                    site. This list is composed of the most recently-caught
                    bots.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 159; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botscout_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >BotScout&nbsp;helps prevent automated web scripts, known as
                    bots, from registering on forums, polluting databases,
                    spreading spam, and abusing forms on web sites. They do this
                    by tracking the names, IPs, and email addresses that bots
                    use and logging them as unique signatures for future
                    reference. They also provide a simple yet powerful API that
                    you can use to test forms when they're submitted on your
                    site. This list is composed of the most recently-caught
                    bots.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 160; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botvrij_dst
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botvrij.eu&nbsp;Indicators of Compromise (IOCS) about
                    malicious destination IPs, gathered via open source
                    information feeds (blog pages and PDF documents) and then
                    consolidated into different datasets. To ensure the quality
                    of the data all entries older than approx. 6 months are
                    removed.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 161; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botvrij_src
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >botvrij.eu&nbsp;Indicators of Compromise (IOCS) about
                    malicious source IPs, gathered via open source information
                    feeds (blog pages and PDF documents) and then consolidated
                    into different datasets. To ensure the quality of the data
                    all entries older than approx. 6 months are removed.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 162; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >bruteforceblocker
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >danger.rulez.sk bruteforceblocker&nbsp;(fail2ban
                    alternative for SSH on OpenBSD). This is an automatically
                    generated list from users reporting failed authentication
                    attempts. An IP seems to be included if 3 or more users
                    report it. Its retention pocily seems 30 days.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 3 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 163; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ciarmy
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CIArmy.com&nbsp;IPs with poor Rogue Packet score that have
                    not yet been identified as malicious by the community
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 3 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 164; height: 45pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cidr_report_bogons
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Unallocated (Free) Address Space, generated on a daily
                    basis using the IANA registry files, the Regional Internet
                    Registry stats files and the Regional Internet Registry
                    whois data.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 165; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleanmx_phishing
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Clean-MX.de&nbsp;IPs sending phishing messages
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 166; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleanmx_viruses
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Clean-MX.de&nbsp;IPs with viruses
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 167; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Today's HTTP Spammers (includes:
                    cleantalk_new cleantalk_updated)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 168; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Today's HTTP Spammers (includes:
                    cleantalk_new_1d cleantalk_updated_1d)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 169; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Today's HTTP Spammers (includes:
                    cleantalk_new_30d cleantalk_updated_30d)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 170; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Today's HTTP Spammers (includes:
                    cleantalk_new_7d cleantalk_updated_7d)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 171; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_new
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recent HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 172; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_new_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recent HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 173; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_new_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recent HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 174; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_new_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recent HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 175; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_top20
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Top 20 HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 176; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_updated
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recurring HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 177; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_updated_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recurring HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 178; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_updated_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recurring HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 179; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cleantalk_updated_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CleanTalk&nbsp;Recurring HTTP Spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 180; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >coinbl_hosts
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CoinBlockerLists&nbsp;Simple lists that can help prevent
                    cryptomining in the browser or other applications. This list
                    contains all domains - A list for administrators to prevent
                    mining in networks. The maintainer's file contains
                    hostnames, which have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 181; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >coinbl_hosts_browser
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CoinBlockerLists&nbsp;Simple lists that can help prevent
                    cryptomining in the browser or other applications. A hosts
                    list to prevent browser mining only. The maintainer's file
                    contains hostnames, which have been DNS resolved to IP
                    addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 182; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >coinbl_hosts_optional
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CoinBlockerLists&nbsp;Simple lists that can help prevent
                    cryptomining in the browser or other applications. This list
                    contains additional domains, for administrators to prevent
                    mining in networks. The maintainer's file contains
                    hostnames, which have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 183; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >coinbl_ips
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CoinBlockerLists&nbsp;Simple lists that can help prevent
                    cryptomining in the browser or other applications. This list
                    contains all IPs - An additional list for administrators to
                    prevent mining in networks. The maintainer's file contains
                    hostnames, which have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 184; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cruzit_web_attacks
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CruzIt.com&nbsp;IPs of compromised machines scanning for
                    vulnerabilities and DDOS attacks
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 185; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cta_cryptowall
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Cyber Threat Alliance&nbsp;CryptoWall is one of the most
                    lucrative and broad-reaching ransomware campaigns affecting
                    Internet users today. Sharing intelligence and analysis
                    resources, the CTA profiled the latest version of
                    CryptoWall, which impacted hundreds of thousands of users,
                    resulting in over US $325 million in damages worldwide.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 186; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >cybercrime
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >CyberCrime&nbsp;A project tracking Command and Control.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 187; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >darklist_de
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >darklist.de&nbsp;ssh fail2ban reporting
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 188; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >datacenters
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Nick Galbreath&nbsp;This is a list of IPv4 address that
                    correspond to datacenters, co-location centers, shared and
                    virtual webhosting providers. In other words, ip addresses
                    that end web consumers should not be using.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 189; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_dnsrd
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that have been identified
                    as sending recursive DNS queries to a remote host. This
                    report lists addresses that may be cataloging open DNS
                    resolvers or evaluating cache entries.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 190; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_dnsrdany
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that have been identified
                    as sending recursive DNS IN ANY queries to a remote host.
                    This report lists addresses that may be cataloging open DNS
                    resolvers for the purpose of later using them to facilitate
                    DNS amplification and reflection attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 191; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_dnsversion
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that have been identified
                    as sending DNS CH TXT VERSION.BIND queries to a remote host.
                    This report lists addresses that may be cataloging DNS
                    software.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 192; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_sipinvitation
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that have been seen
                    initiating a SIP INVITE operation to a remote host. This
                    report lists hosts that are suspicious of more than just
                    port scanning. These hosts may be SIP client cataloging or
                    conducting various forms of telephony abuse.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 193; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_sipquery
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that has been seen
                    initiating a SIP OPTIONS query to a remote host. This report
                    lists hosts that are suspicious of more than just port
                    scanning. These hosts may be SIP server cataloging or
                    conducting various forms of telephony abuse.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 194; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_sipregistration
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that have been seen
                    initiating a SIP REGISTER operation to a remote host. This
                    report lists hosts that are suspicious of more than just
                    port scanning. These hosts may be SIP client cataloging or
                    conducting various forms of telephony abuse.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 195; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_sshclient
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that has been seen
                    initiating an SSH connection to a remote host. This report
                    lists hosts that are suspicious of more than just port
                    scanning. These hosts may be SSH server cataloging or
                    conducting authentication attack attempts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 196; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_sshpwauth
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that has been seen
                    attempting to remotely login to a host using SSH password
                    authentication. This report lists hosts that are highly
                    suspicious and are likely conducting malicious SSH password
                    authentication attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 197; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dataplane_vncrfb
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DataPlane.org&nbsp;IP addresses that have been seen
                    initiating a VNC remote frame buffer (RFB) session to a
                    remote host. This report lists hosts that are suspicious of
                    more than just port scanning. These hosts may be VNC server
                    cataloging or conducting various forms of remote access
                    abuse.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 198; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dm_tor
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dan.me.uk&nbsp;dynamic list of TOR nodes
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 199; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_anonymizers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;List of open proxies. It includes IPs
                    which DroneBL categorizes as SOCKS proxies (8), HTTP proxies
                    (9), web page proxies (11), WinGate proxies (14), proxy
                    chains (10).
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 200; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_auto_botnets
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;IPs of automatically detected botnets. It
                    includes IPs for which DroneBL responds with 17.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 201; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_autorooting_worms
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;IPs of autorooting worms. It includes IPs
                    for which DroneBL responds with 16. These are usually SSH
                    bruteforce attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 202; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_compromised
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;IPs of compromised routers / gateways. It
                    includes IPs for which DroneBL responds with 15 (BOPM
                    detected).
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 203; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_ddos_drones
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;IPs of DDoS drones. It includes IPs for
                    which DroneBL responds with 7.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 204; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_dns_mx_on_irc
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;List of IPs of DNS / MX hostname detected
                    on IRC. It includes IPs for which DroneBL responds with 18.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 205; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_irc_drones
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;List of IRC spam drones
                    (litmus/sdbot/fyle). It includes IPs for which DroneBL
                    responds with 3.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 206; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_unknown
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;List of IPs of uncategorized threats. It
                    includes IPs for which DroneBL responds with 255.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 207; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dronebl_worms_bots
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DroneBL.org&nbsp;IPs of unknown worms or spambots. It
                    includes IPs for which DroneBL responds with 6
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 208; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dshield
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DShield.org&nbsp;top 20 attacking class C (/24) subnets
                    over the last three days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 209; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dshield_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DShield.org&nbsp;top 20 attacking class C (/24) subnets
                    over the last three days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 210; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dshield_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DShield.org&nbsp;top 20 attacking class C (/24) subnets
                    over the last three days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 211; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dshield_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DShield.org&nbsp;top 20 attacking class C (/24) subnets
                    over the last three days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 212; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dshield_top_1000
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DShield.org&nbsp;top 1000 attacking hosts in the last 30
                    days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 213; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >dyndns_ponmocup
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >DynDNS.org&nbsp;Ponmocup. The malware powering the botnet
                    has been around since 2006 and it</span
                  ><span
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >’<span lang="EN-US"
                      >s known under various names, including Ponmocup, Vundo,
                      Virtumonde, Milicenso and Swisyn. It has been used for ad
                      fraud, data theft and downloading additional threats to
                      infected systems. Ponmocup is one of the largest currently
                      active and, with nine consecutive years, also one of the
                      longest running, but it is rarely noticed as the operators
                      take care to keep it operating under the radar.
                    </span></span
                  >
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 214; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_14072015_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 215; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_14072015q_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 216; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_22072014a_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 217; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_22072014b_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 218; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_22072014c_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 219; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_atomictrivia_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Andromeda/Gamarue Checkin
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 220; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_auth_update_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 221; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_burmundisoul_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 222; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_crazyerror_su
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 223; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_dagestanskiiviskis_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 224; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_differentia_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 225; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_disorderstatus_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 226; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_dorttlokolrt_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 227; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_downs1_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 228; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_ebankoalalusys_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 229; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_emptyarray_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 230; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_fioartd_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Andromeda/Gamarue Checkin
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 231; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_getarohirodrons_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Andromeda/Gamarue Checkin
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 232; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_hasanhashsde_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 233; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_inleet_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 234; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_islamislamdi_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 235; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_krnqlwlplttc_com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 236; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_maddox1_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 237; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_manning1_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 238; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_misteryherson_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 239; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_mysebstarion_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 240; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_smartfoodsglutenfree_kz
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Malicious Botnet Serving Various Malware Families
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 241; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_venerologvasan93_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ursnif Variant CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 242; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >esentire_volaya_ru
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Win32/PSW.Papras.CK CnC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 243; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >et_block
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >EmergingThreats.net&nbsp;default blacklist (at the time of
                    writing includes spamhaus DROP, dshield and abuse.ch
                    trackers, which are available separately too - prefer to use
                    the direct ipsets instead of this, they seem to lag a bit in
                    updates)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 244; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >et_botcc
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >EmergingThreats.net Command and Control IPs&nbsp;These IPs
                    are updates every 24 hours and should be considered VERY
                    highly reliable indications that a host is communicating
                    with a known and active Bot or Malware command and control
                    server - (although they say this includes abuse.ch trackers,
                    it does not - check its overlaps)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 245; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >et_compromised
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >EmergingThreats.net compromised hosts
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 246; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >et_dshield
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >EmergingThreats.net&nbsp;dshield blocklist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 247; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >et_spamhaus
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >EmergingThreats.net&nbsp;spamhaus blocklist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 248; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >et_tor
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >EmergingThreats.net TOR list&nbsp;of TOR network IPs
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 249; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >feodo
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Feodo tracker&nbsp;trojan includes IPs which are
                    being used by Feodo (also known as Cridex or Bugat) which
                    commits ebanking fraud
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 250; height: 174pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 174pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >feodo_badips
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 174pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Feodo tracker BadIPs&nbsp;The Feodo Tracker Feodo
                    BadIP Blocklist only contains IP addresses (IPv4) used as
                    C&amp;C communication channel by the Feodo Trojan version B.
                    These IP addresses are usually servers rented by
                    cybercriminals directly and used for the exclusive purpose
                    of hosting a Feodo C&amp;C server. Hence you should expect
                    no legit traffic to those IP addresses. The site highly
                    recommends you to block/drop any traffic towards any Feodo
                    C&amp;C using the Feodo BadIP Blocklist. Please consider
                    that this blocklist only contains IP addresses used by
                    version B of the Feodo Trojan. C&amp;C communication
                    channels used by version A, version C and version D are not
                    covered by this blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 174pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 251; height: 45pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_abusers_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset made from blocklists that track abusers in the
                    last 24 hours. (includes: botscout_1d cleantalk_new_1d
                    cleantalk_updated_1d php_commenters_1d php_dictionary_1d
                    php_harvesters_1d php_spammers_1d stopforumspam_1d)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 252; height: 60pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_abusers_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset made from blocklists that track abusers in the
                    last 30 days. (includes: cleantalk_new_30d
                    cleantalk_updated_30d php_commenters_30d php_dictionary_30d
                    php_harvesters_30d php_spammers_30d stopforumspam sblam)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 253; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_anonymous
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset that includes all the anonymizing IPs of the
                    world. (includes: anonymous bm_tor dm_tor firehol_proxies
                    tor_exits)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 254; height: 60pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_level1
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >A firewall blacklist composed from IP lists, providing
                    maximum protection with minimum false positives. Suitable
                    for basic protection on all internet facing servers, routers
                    and firewalls. (includes: bambenek_c2 dshield feodo
                    fullbogons spamhaus_drop spamhaus_edrop sslbl zeus_badips
                    ransomware_rw)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 255; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_level2
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset made from blocklists that track attacks, during
                    about the last 48 hours. (includes: blocklist_de dshield_1d
                    greensnow)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 256; height: 75pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 75pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_level3
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 75pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset made from blocklists that track attacks, spyware,
                    viruses. It includes IPs than have been reported or detected
                    in the last 30 days. (includes: bruteforceblocker ciarmy
                    dshield_30d dshield_top_1000 malc0de maxmind_proxy_fraud
                    myip shunlist snort_ipfilter sslbl_aggressive
                    talosintel_ipfilter zeus vxvault)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 75pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 257; height: 60pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_level4
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset made from blocklists that track attacks, but may
                    include a large number of false positives. (includes:
                    blocklist_net_ua botscout_30d cruzit_web_attacks cybercrime
                    haley_ssh iblocklist_hijacked iblocklist_spyware
                    iblocklist_webexploit ipblacklistcloud_top iw_wormlist
                    malwaredomainlist)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 258; height: 60pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An ipset made from all sources that track open proxies. It
                    includes IPs reported or detected in the last 30 days.
                    (includes: iblocklist_proxies maxmind_proxy_fraud
                    ip2proxy_px1lite proxylists_30d proxyrss_30d proxz_30d
                    ri_connect_proxies_30d ri_web_proxies_30d socks_proxy_30d
                    sslproxies_30d xroxy_30d)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 259; height: 45pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_webclient
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >An IP blacklist made from blocklists that track IPs that a
                    web client should never talk to. This list is to be used on
                    top of firehol_level1. (includes: ransomware_online
                    sslbl_aggressive cybercrime dyndns_ponmocup
                    maxmind_proxy_fraud)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 260; height: 90pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 90pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >firehol_webserver
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 90pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >A web server IP blacklist made from blocklists that track
                    IPs that should never be used by your web users. (This list
                    includes IPs that are servers hosting malware, bots, etc or
                    users having a long criminal history. This list is to be
                    used on top of firehol_level1, firehol_level2,
                    firehol_level3 and possibly firehol_proxies or
                    firehol_anonymous). (includes: maxmind_proxy_fraud myip
                    pushing_inertia_blocklist stopforumspam_toxic)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 90pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 261; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >fullbogons
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Team-Cymru.org&nbsp;IP space that has been allocated to an
                    RIR, but not assigned by that RIR to an actual ISP or other
                    end-user
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 262; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >geolite2_asn
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >MaxMind GeoLite2 ASN
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 7 days<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 263; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >geolite2_country
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >MaxMind GeoLite2&nbsp;databases are free IP geolocation
                    databases comparable to, but less accurate than,
                    MaxMind</span
                  ><span
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >’<span lang="EN-US"
                      >s GeoIP2 databases. They include IPs per country, IPs per
                      continent, IPs used by anonymous services (VPNs, Proxies,
                      etc) and Satellite Providers.
                    </span></span
                  >
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 7 days<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 264; height: 208.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 208.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >gofferje_sip
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 208.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Stefan Gofferje&nbsp;A personal blacklist of networks and
                    IPs of SIP attackers. To end up here, the IP or network must
                    have been the origin of considerable and repeated attacks on
                    my PBX and additionally, the ISP didn't react to any
                    complaint. Note from the author: I don't give any guarantees
                    of accuracy, completeness or even usability! USE AT YOUR OWN
                    RISK! Also note that I block complete countries, namely
                    China, Korea and Palestine with blocklists from ipdeny.com,
                    so some attackers will never even get the chance to get
                    noticed by me to be put on this blacklist. I also don't
                    accept any liabilities related to this blocklist. If you're
                    an ISP and don't like your IPs being listed here, too bad!
                    You should have done something about your customers'
                    behavior and reacted to my complaints. This blocklist is
                    nothing but an expression of my personal opinion and
                    exercising my right of free speech.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 208.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 6 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 265; height: 104.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >gpf_comics
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >The GPF DNS Block List is a list of IP addresses on the
                    Internet that have attacked the&nbsp;GPF Comics&nbsp;family
                    of Web sites. IPs on this block list have been banned from
                    accessing all of our servers because they were caught in the
                    act of spamming, attempting to exploit our scripts, scanning
                    for vulnerabilities, or consuming resources to the detriment
                    of our human visitors.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 266; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >graphiclineweb
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >GraphiclineWeb&nbsp;The IP</span
                  ><span
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >’<span lang="EN-US"
                      >s, Hosts and Domains listed in this table are banned
                      universally from accessing websites controlled by the
                      maintainer. Some form of bad activity has been seen from
                      the addresses listed. Bad activity includes: unwanted
                      spiders, rule breakers, comment spammers, trackback
                      spammers, spambots, hacker bots, registration bots and
                      other scripting attackers, harvesters, nuisance spiders,
                      spy bots and organizations spying on websites for
                      commercial reasons.
                    </span></span
                  >
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 267; height: 104.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >greensnow
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >GreenSnow&nbsp;is a team harvesting a large number of IPs
                    from different computers located around the world. GreenSnow
                    is comparable with SpamHaus.org for attacks of any kind
                    except for spam. Their list is updated automatically and you
                    can withdraw at any time your IP address if it has been
                    listed. Attacks / bruteforce that are monitored are: Scan
                    Port, FTP, POP3, mod_security, IMAP, SMTP, SSH, cPanel, etc.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 268; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >haley_ssh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Charles Haley&nbsp;IPs launching SSH dictionary attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 269; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_ats
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;ad/tracking servers listed in the hpHosts
                    database. The maintainer's file contains hostnames, which
                    have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 270; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_emd
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;malware sites listed in the hpHosts database.
                    The maintainer's file contains hostnames, which have been
                    DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 271; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_exp
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;exploit sites listed in the hpHosts database.
                    The maintainer's file contains hostnames, which have been
                    DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 272; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_fsa
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;fraud sites listed in the hpHosts database.
                    The maintainer's file contains hostnames, which have been
                    DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 273; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_grm
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;sites involved in spam (that do not otherwise
                    meet any other classification criteria) listed in the
                    hpHosts database. The maintainer's file contains hostnames,
                    which have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 274; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_hfs
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;sites spamming the hpHosts forums (and not
                    meeting any other classification criteria) listed in the
                    hpHosts database. The maintainer's file contains hostnames,
                    which have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 275; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_hjk
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;hijack sites listed in the hpHosts database.
                    The maintainer's file contains hostnames, which have been
                    DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 276; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_mmt
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;sites involved in misleading marketing (e.g.
                    fake Flash update adverts) listed in the hpHosts database.
                    The maintainer's file contains hostnames, which have been
                    DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 277; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_pha
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;illegal pharmacy sites listed in the hpHosts
                    database. The maintainer's file contains hostnames, which
                    have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 278; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_psh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;phishing sites listed in the hpHosts database.
                    The maintainer's file contains hostnames, which have been
                    DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 279; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hphosts_wrz
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >hpHosts&nbsp;warez/piracy sites listed in the hpHosts
                    database. The maintainer's file contains hostnames, which
                    have been DNS resolved to IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 280; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_abuse_palevo
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >palevotracker.abuse.ch IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 281; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_abuse_spyeye
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >spyeyetracker.abuse.ch IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 282; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_abuse_zeus
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >zeustracker.abuse.ch IP blocklist that contains IP
                    addresses which are currently beeing tracked on the abuse.ch
                    ZeuS Tracker.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 283; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_ads
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Advertising trackers and a short list of bad/intrusive porn
                    sites.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 284; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_badpeers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IPs that have been reported for bad deeds in p2p.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 285; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_bogons
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Unallocated address space.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 286; height: 90pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 90pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_ciarmy_malicious
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 90pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >ciarmy.com IP blocklist. Based on information from a
                    network of Sentinel devices deployed around the world, they
                    compile a list of known bad IP addresses. Sentinel devices
                    are uniquely positioned to pick up traffic from bad guys
                    without requiring any type of signature-based or rate-based
                    identification. If an IP is identified in this way by a
                    significant number of Sentinels, the IP is malicious and
                    should be blocked.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 90pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 287; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_cidr_report_bogons
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >cidr-report.org IP list of Unallocated address space.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 288; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_cruzit_web_attacks
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >CruzIT IP list with individual IP addresses of compromised
                    machines scanning for vulnerabilities and DDOS attacks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 289; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_dshield
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >known Hackers and such people.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 290; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_edu
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IPs used by Educational Institutions.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 291; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_exclusions
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Exclusions.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 292; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_fornonlancomputers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP blocklist for non-LAN computers.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 293; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_forumspam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Forum spam.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 294; height: 75pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 75pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_hijacked
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 75pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Hijacked IP-Blocks. Contains hijacked IP-Blocks and known
                    IP-Blocks that are used to deliver Spam. This list is a
                    combination of lists with hijacked IP-Blocks. Hijacked IP
                    space are IP blocks that are being used without permission
                    by organizations that have no relation to original
                    organization (or its legal successor) that received the IP
                    block. In essence it's stealing of somebody else's IP
                    resources.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 75pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 295; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_iana_multicast
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IANA Multicast IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 296; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_iana_private
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IANA Private IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 297; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_iana_reserved
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IANA Reserved IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 298; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_aol
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >AOL IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 299; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_att
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >AT&amp;T IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 300; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_cablevision
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Cablevision IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 301; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_charter
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Charter IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 302; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_comcast
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Comcast IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 303; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_embarq
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Embarq IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 304; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_qwest
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Qwest IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 305; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_sprint
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Sprint IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 306; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_suddenlink
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Suddenlink IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 307; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_twc
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Time Warner Cable IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 308; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_isp_verizon
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Verizon IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 309; height: 135pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 135pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_level1
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 135pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Level 1 (for use in p2p): Companies or organizations who
                    are clearly involved with trying to stop filesharing (e.g.
                    Baytsp, MediaDefender, Mediasentry). Companies which
                    anti-p2p activity has been seen from. Companies that produce
                    or have a strong financial interest in copyrighted material
                    (e.g. music, movie, software industries a.o.). Government
                    ranges or companies that have a strong financial interest in
                    doing work for governments. Legal industry ranges. IPs or
                    ranges of ISPs from which anti-p2p activity has been
                    observed. Basically this list will block all kinds of
                    internet connections that most people would rather not have
                    during their internet travels.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 135pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 310; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_level2
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Level 2 (for use in p2p). General corporate ranges. Ranges
                    used by labs or researchers. Proxies.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 311; height: 60pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_level3
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Level 3 (for use in p2p). Many portal-type websites. ISP
                    ranges that may be dodgy for some reason. Ranges that belong
                    to an individual, but which have not been determined to be
                    used by a particular company. Ranges for things that are
                    unusual in some way. The L3 list is aka the paranoid list.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 312; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_malc0de
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >malc0de.com IP blocklist. Addresses that have been
                    identified distributing malware during the past 30 days.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 313; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_onion_router
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >The Onion Router IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 314; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_activision
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Activision IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 315; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_apple
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Apple IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 316; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_blizzard
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Blizzard IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 317; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_crowd_control
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Crowd Control Productions IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 318; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_electronic_arts
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Electronic Arts IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 319; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_joost
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Joost IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 320; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_linden_lab
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Linden Lab IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 321; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_logmein
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >LogMeIn IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 322; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_microsoft
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Microsoft IP ranges.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 323; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_ncsoft
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >NCsoft IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 324; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_nintendo
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Nintendo IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 325; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_pandora
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Pandora IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 326; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_pirate_bay
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >The Pirate Bay IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 327; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_punkbuster
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Punkbuster IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 328; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_riot_games
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Riot Games IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 329; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_sony_online
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Sony Online Entertainment IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 330; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_square_enix
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Square Enix IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 331; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_steam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Steam IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 332; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_ubisoft
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Ubisoft IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 333; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_org_xfire
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >XFire IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 334; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_pedophiles
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP ranges of people who we have found to be sharing child
                    pornography in the p2p community.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 335; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Open Proxies IPs list (without TOR)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 336; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_rangetest
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Suspicious IPs that are under investigation.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 337; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_spamhaus_drop
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Spamhaus.org DROP (Don't Route Or Peer) list.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 338; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_spider
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP list intended to be used by webmasters to block hostile
                    spiders from their web sites.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 339; height: 60pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_spyware
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Known malicious SPYWARE and ADWARE IP Address ranges. It is
                    compiled from various sources, including other available
                    spyware blacklists, HOSTS files, from research found at many
                    of the top anti-spyware forums, logs of spyware victims,
                    etc.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 60pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 340; height: 120pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 120pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_webexploit
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 120pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Web server hack and exploit attempts. IP addresses related
                    to current web server hack and exploit attempts that have
                    been logged or can be found in and cross referenced with
                    other related IP databases. Malicious and other non search
                    engine bots will also be listed here, along with anything
                    found that can have a negative impact on a website or
                    webserver such as proxies being used for negative SEO
                    hijacks, unauthorised site mirroring, harvesting, scraping,
                    snooping and data mining / spy bot / security &amp;
                    copyright enforcement companies that target and continuosly
                    scan webservers.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 120pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 341; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iblocklist_yoyo_adservers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >pgl.yoyo.org ad servers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 342; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ip2location_country
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP2Location.com&nbsp;geolocation database
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 343; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ip2location_country_eh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Western Sahara (EH) --&nbsp;IP2Location.com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 344; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ip2location_country_sh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Saint Helena (SH) --&nbsp;IP2Location.com
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 345; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ip2proxy_px1lite
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP2Location.com&nbsp;IP2Proxy LITE IP-COUNTRY Database
                    contains IP addresses which are used as public proxies. The
                    LITE edition is a free version of database that is limited
                    to public proxies IP address.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 346; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ipblacklistcloud_recent
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP Blacklist Cloud&nbsp;These are the most recent IP
                    addresses that have been blacklisted by websites. IP
                    Blacklist Cloud plugin protects your WordPress based website
                    from spam comments, gives details about login attacks which
                    you don't even know are happening without this plugin!
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 347; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ipblacklistcloud_recent_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP Blacklist Cloud&nbsp;These are the most recent IP
                    addresses that have been blacklisted by websites. IP
                    Blacklist Cloud plugin protects your WordPress based website
                    from spam comments, gives details about login attacks which
                    you don't even know are happening without this plugin!
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 348; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ipblacklistcloud_recent_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP Blacklist Cloud&nbsp;These are the most recent IP
                    addresses that have been blacklisted by websites. IP
                    Blacklist Cloud plugin protects your WordPress based website
                    from spam comments, gives details about login attacks which
                    you don't even know are happening without this plugin!
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 349; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ipblacklistcloud_recent_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP Blacklist Cloud&nbsp;These are the most recent IP
                    addresses that have been blacklisted by websites. IP
                    Blacklist Cloud plugin protects your WordPress based website
                    from spam comments, gives details about login attacks which
                    you don't even know are happening without this plugin!
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 350; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ipblacklistcloud_top
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IP Blacklist Cloud&nbsp;These are the top IP addresses that
                    have been blacklisted by many websites. IP Blacklist Cloud
                    plugin protects your WordPress based website from spam
                    comments, gives details about login attacks which you don't
                    even know are happening without this plugin!
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 351; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ipdeny_country
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IPDeny.com&nbsp;geolocation database
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 352; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iw_spamlist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ImproWare Antispam&nbsp;IPs sending spam, in the last 3
                    days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 353; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >iw_wormlist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ImproWare Antispam&nbsp;IPs sending emails with viruses or
                    worms, in the last 3 days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 354; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >lashback_ubl
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >The LashBack UBL&nbsp;The Unsubscribe Blacklist (UBL) is a
                    real-time blacklist of IP addresses which are sending email
                    to names harvested from suppression files (this is a big
                    list, more than 500.000 IPs)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 355; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >malc0de
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Malc0de.com&nbsp;malicious IPs of the last 30 days
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 356; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >malwaredomainlist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >malwaredomainlist.com&nbsp;list of malware active ip
                    addresses
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 357; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >maxmind_proxy_fraud
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >MaxMind.com&nbsp;sample list of high-risk IP addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 358; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >myip
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >myip.ms&nbsp;IPs identified as web bots in the last 10
                    days, using several sites that require human action
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 359; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >nixspam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NiX Spam&nbsp;IP addresses that sent spam in the last hour
                    - automatically generated entries without distinguishing
                    open proxies from relays, dialup gateways, and so on. All
                    IPs are removed after 12 hours if there is no spam from
                    there.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 360; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_attack
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category attack with severity
                    all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 361; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_bruteforce
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category bruteforce with
                    severity all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 362; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_ddosbot
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category ddosbot with severity
                    all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 363; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_dnsscan
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category dnsscan with severity
                    all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 364; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_spam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category spam with severity all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 365; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_suspicious
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category suspicious with
                    severity all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 366; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_wannacry
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category wannacry with severity
                    all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 367; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_webscan
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category webscan with severity
                    all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 368; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_all_wormscan
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category wormscan with severity
                    all
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 369; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_attack
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category attack with severity
                    high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 370; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_bruteforce
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category bruteforce with
                    severity high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 371; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_ddosbot
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category ddosbot with severity
                    high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 372; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_dnsscan
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category dnsscan with severity
                    high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 373; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_spam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category spam with severity high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 374; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_suspicious
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category suspicious with
                    severity high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 375; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_wannacry
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category wannacry with severity
                    high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 376; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_webscan
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category webscan with severity
                    high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 377; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >normshield_high_wormscan
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NormShield.com&nbsp;IPs in category wormscan with severity
                    high
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 378; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >nt_malware_dns
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >No Think&nbsp;Malware DNS (the original list includes
                    hostnames and domains, which are ignored)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 379; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >nt_malware_http
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >No Think&nbsp;Malware HTTP
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 380; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >nt_malware_irc
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >No Think&nbsp;Malware IRC
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 381; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >nt_ssh_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >NoThink&nbsp;Last 7 days SSH attacks
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 382; height: 45pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >nullsecure
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #0366d6;
                      mso-font-kerning: 0pt;
                    "
                    >nullsecure.org</span
                  ><span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >&nbsp;This is a free threat feed provided for use in any
                    acceptable manner. This feed was aggregated using
                    the&nbsp;</span
                  ><span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #0366d6;
                      mso-font-kerning: 0pt;
                    "
                    >Tango Honeypot Intelligence Splunk App</span
                  ><span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >&nbsp;by Brian Warehime, a Senior Security Analyst at
                    Defense Point Security.</span
                  ><span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #0366d6;
                      mso-font-kerning: 0pt;
                    "
                  >
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 45pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 8 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 383; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >packetmail
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >PacketMail.net&nbsp;IP addresses that have been detected
                    performing TCP SYN to 206.82.85.196/30 to a non-listening
                    service or daemon. No assertion is made, nor implied, that
                    any of the below listed IP addresses are accurate,
                    malicious, hostile, or engaged in nefarious acts. Use this
                    list at your own risk.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 384; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >packetmail_emerging_ips
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >PacketMail.net&nbsp;IP addresses that have been detected as
                    potentially of interest based on the number of unique users
                    of the packetmail IP Reputation system. No assertion is
                    made, nor implied, that any of the below listed IP addresses
                    are accurate, malicious, hostile, or engaged in nefarious
                    acts. Use this list at your own risk.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 385; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >packetmail_mail
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >PacketMail.net&nbsp;IP addresses that have been detected
                    performing behavior not in compliance with the requirements
                    this system enforces for email acceptance. No assertion is
                    made, nor implied, that any of the below listed IP addresses
                    are accurate, malicious, hostile, or engaged in nefarious
                    acts. Use this list at your own risk.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 386; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >packetmail_ramnode
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >PacketMail.net&nbsp;IP addresses that have been detected
                    performing TCP SYN to 81.4.103.251 to a non-listening
                    service or daemon. No assertion is made, nor implied, that
                    any of the below listed IP addresses are accurate,
                    malicious, hostile, or engaged in nefarious acts. Use this
                    list at your own risk.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 387; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >php_bad
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;bad web hosts (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 388; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_commenters
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;comment spammers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 389; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_commenters_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;comment spammers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 390; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_commenters_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;comment spammers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 391; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_commenters_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;comment spammers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 392; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_dictionary
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;directory attackers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 393; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_dictionary_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;directory attackers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 394; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_dictionary_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;directory attackers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 395; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_dictionary_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;directory attackers (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 396; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_harvesters
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;harvesters (IPs that surf the
                    internet looking for email addresses) (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 397; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_harvesters_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;harvesters (IPs that surf the
                    internet looking for email addresses) (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 398; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_harvesters_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;harvesters (IPs that surf the
                    internet looking for email addresses) (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 399; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_harvesters_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;harvesters (IPs that surf the
                    internet looking for email addresses) (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 400; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;spam servers (IPs used by spammers
                    to send messages) (this list is composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 401; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_spammers_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;spam servers (IPs used by spammers
                    to send messages) (this list is composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 402; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_spammers_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;spam servers (IPs used by spammers
                    to send messages) (this list is composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 403; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >php_spammers_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >projecthoneypot.org&nbsp;spam servers (IPs used by spammers
                    to send messages) (this list is composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 404; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists.net&nbsp;open proxies (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 405; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists.net&nbsp;open proxies (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 406; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists.net&nbsp;open proxies (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 407; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxylists.net&nbsp;open proxies (this list is composed
                    using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 408; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss.com&nbsp;open proxies syndicated from multiple
                    sources.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 409; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss.com&nbsp;open proxies syndicated from multiple
                    sources.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 410; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss.com&nbsp;open proxies syndicated from multiple
                    sources.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 411; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyrss.com&nbsp;open proxies syndicated from multiple
                    sources.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 412; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyspy_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ProxySpy&nbsp;open proxies (updated hourly)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 413; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyspy_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ProxySpy&nbsp;open proxies (updated hourly)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 414; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxyspy_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ProxySpy&nbsp;open proxies (updated hourly)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 415; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 416; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 417; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 418; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >proxz.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 419; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >pushing_inertia_blocklist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Pushing Inertia&nbsp;IPs of hosting providers that are
                    known to host various bots, spiders, scrapers, etc. to block
                    access from these providers to web servers.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 420; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_cryptowall_ps
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list is CW_PS_IPBL:
                    CryptoWall Ransomware Payment Sites IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 421; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_feed
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. The IPs in this list have been
                    extracted from the tracker data feed.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 422; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_locky_c2
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list is LY_C2_IPBL: Locky
                    Ransomware C2 URL blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 423; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_locky_ps
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list is LY_PS_IPBL: Locky
                    Ransomware Payment Sites IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 424; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_online
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. The IPs in this list have been
                    extracted from the tracker data feed, filtering only online
                    IPs.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 425; height: 278.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 278.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_rw
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 278.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list includes TC_PS_IPBL,
                    LY_C2_IPBL, TL_C2_IPBL, TL_PS_IPBL and it is the recommended
                    blocklist. It might not catch everything, but the false
                    positive rate should be low. However, false positives are
                    possible, especially with regards to RW_IPBL. IP addresses
                    associated with Ransomware Payment Sites (*_PS_IPBL) or
                    Locky botnet C&amp;Cs (LY_C2_IPBL) stay listed on RW_IPBL
                    for a time of 30 days after the last appearance. This means
                    that an IP address stays listed on RW_IPBL even after the
                    threat has been eliminated (e.g. the VPS / server has been
                    suspended by the hosting provider) for another 30 days.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 278.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 426; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_teslacrypt_ps
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list is TC_PS_IPBL:
                    TeslaCrypt Ransomware Payment Sites IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 427; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_torrentlocker_c2
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list is TL_C2_IPBL:
                    TorrentLocker Ransomware C2 IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 428; height: 156.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ransomware_torrentlocker_ps
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Ransomware Tracker&nbsp;Ransomware Tracker tracks
                    and monitors the status of domain names, IP addresses and
                    URLs that are associated with Ransomware, such as Botnet
                    C&amp;C servers, distribution sites and payment sites. By
                    using data provided by Ransomware Tracker, hosting- and
                    internet service provider (ISPs), as well as national
                    CERTs/CSIRTs, law enforcement agencies (LEA) and security
                    researchers can receive an overview on infrastructure used
                    by Ransomware and whether these are actively being used by
                    miscreants to commit fraud. This list is TL_PS_IPBL:
                    TorrentLocker Ransomware Payment Sites IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 156.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 429; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_connect_proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open CONNECT proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 430; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_connect_proxies_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open CONNECT proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 431; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_connect_proxies_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open CONNECT proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 432; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_connect_proxies_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open CONNECT proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 433; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_web_proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open HTTP proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 434; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_web_proxies_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open HTTP proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 435; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_web_proxies_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open HTTP proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 436; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >ri_web_proxies_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >rosinstrument.com&nbsp;open HTTP proxies (this list is
                    composed using an RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 437; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sblam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sblam.com&nbsp;IPs used by web form spammers, during the
                    last month
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 438; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >shunlist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >AutoShun.org&nbsp;IPs identified as hostile by correlating
                    logs from distributed snort installations running the
                    autoshun plugin
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 439; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >snort_ipfilter
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >labs.snort.org&nbsp;supplied IP blacklist (this list seems
                    to be updated frequently, but we found no information about
                    it)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 440; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks_proxy
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks-proxy.net&nbsp;open SOCKS proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 441; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks_proxy_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks-proxy.net&nbsp;open SOCKS proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 442; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks_proxy_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks-proxy.net&nbsp;open SOCKS proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 443; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks_proxy_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >socks-proxy.net&nbsp;open SOCKS proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 444; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_anonymizers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of open HTTP and SOCKS proxies.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 445; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_block
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of hosts demanding that they never be
                    tested by SORBS.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      mso-ascii-font-family: 'Segoe UI';
                      mso-hansi-font-family: 'Segoe UI';
                      mso-bidi-font-family: 'Segoe UI';
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                  ></span
                  ><span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                  >
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 446; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_dul
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;Dynamic IP Addresses.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 447; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_escalations
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;Netblocks of spam supporting service
                    providers, including those who provide websites, DNS or drop
                    boxes for a spammer. Spam supporters are added on a 'third
                    strike and you are out' basis, where the third spam will
                    cause the supporter to be added to the list.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 448; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_new_spam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of hosts that have been noted as
                    sending spam/UCE/UBE within the last 48 hours
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 449; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_noserver
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;IP addresses and netblocks of where system
                    administrators and ISPs owning the network have indicated
                    that servers should not be present.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 450; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_recent_spam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of hosts that have been noted as
                    sending spam/UCE/UBE within the last 28 days (includes
                    sorbs_new_spam)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 451; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_smtp
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of SMTP Open Relays.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 452; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_web
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of IPs which have spammer abusable
                    vulnerabilities (e.g. FormMail scripts)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 453; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sorbs_zombie
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Sorbs.net&nbsp;List of networks hijacked from their
                    original owners, some of which have already used for
                    spamming.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 min
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 454; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >spamhaus_drop
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Spamhaus.org&nbsp;DROP list (according to their site this
                    list should be dropped at tier-1 ISPs globally)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 455; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >spamhaus_edrop
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Spamhaus.org&nbsp;EDROP (extended matches that should be
                    used with DROP)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 456; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sslbl
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch SSL Blacklist&nbsp;bad SSL traffic related to
                    malware or botnet activities
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 457; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sslbl_aggressive
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch SSL Blacklist&nbsp;The aggressive version of the
                    SSL IP Blacklist contains all IPs that SSLBL ever detected
                    being associated with a malicious SSL certificate. Since IP
                    addresses can be reused (e.g. when the customer changes),
                    this blacklist may cause false positives. Hence I highly
                    recommend you to use the standard version instead of the
                    aggressive one.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 458; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sslproxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >SSLProxies.org&nbsp;open SSL proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 459; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sslproxies_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >SSLProxies.org&nbsp;open SSL proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 460; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sslproxies_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >SSLProxies.org&nbsp;open SSL proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 461; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >sslproxies_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >SSLProxies.org&nbsp;open SSL proxies
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 10 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 462; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;Banned IPs used by forum spammers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 463; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_180d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;IPs used by forum spammers (last 180
                    days)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 464; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;IPs used by forum spammers in the
                    last 24 hours
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 465; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;IPs used by forum spammers (last 30
                    days)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 466; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_365d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;IPs used by forum spammers (last 365
                    days)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 467; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;IPs used by forum spammers (last 7
                    days)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 468; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_90d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;IPs used by forum spammers (last 90
                    days)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 469; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >stopforumspam_toxic
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >StopForumSpam.com&nbsp;Networks that have large amounts of
                    spambots and are flagged as toxic. Toxic IP ranges are
                    infrequently changed.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 470; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >taichung
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Taichung Education Center&nbsp;Blocked IP Addresses
                    (attacks and bots).
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 471; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >talosintel_ipfilter
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >TalosIntel.com&nbsp;List of known malicious network threats
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 15 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 472; height: 69.6pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >threatcrowd
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Crowdsourced IP feed from ThreatCrowd. These feeds are not
                    a substitute for the scale of auto-extracted command and
                    control domains or the quality of some commercially provided
                    feeds. But crowd-sourcing does go some way towards the quick
                    sharing of threat intelligence between the community.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 69.6pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 473; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >tor_exits
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >TorProject.org&nbsp;list of all current TOR exit points
                    (TorDNSEL)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 474; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >tor_exits_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >TorProject.org&nbsp;list of all current TOR exit points
                    (TorDNSEL)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 475; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >tor_exits_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >TorProject.org&nbsp;list of all current TOR exit points
                    (TorDNSEL)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 476; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >tor_exits_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >TorProject.org&nbsp;list of all current TOR exit points
                    (TorDNSEL)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 5 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 477; height: 121.8pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >turris_greylist
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Turris Greylist&nbsp;IPs that are blocked on the firewalls
                    of Turris routers. The data is processed and classified
                    every week and behaviour of IP addresses that accessed a
                    larger number of Turris routers is evaluated. The result is
                    a list of addresses that have tried to obtain information
                    about services on the router or tried to gain access to
                    them. We do not recommend to use these data as a list of
                    addresses that should be blocked but it can be used for
                    example in analysis of the traffic in other networks.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 121.8pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 7 days<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 478; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_dns
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about dns, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 479; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_ftp
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about ftp, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 480; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_http
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about http, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 481; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_mailer
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about mailer, crawled from several sources,
                    including several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 482; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_malware
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about malware, crawled from several sources,
                    including several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 483; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_ntp
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about ntp, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 484; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_rdp
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about rdp, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 485; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_smb
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about smb, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 486; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_spam
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about spam, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 487; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_ssh
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about ssh, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 488; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_telnet
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about telnet, crawled from several sources,
                    including several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 489; height: 20.5pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_unspecified
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about unspecified, crawled from several sources,
                    including several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 20.5pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 490; height: 30pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urandomusto_vnc
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >IP Feed about vnc, crawled from several sources, including
                    several twitter accounts.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 30pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 491; height: 52.2pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >urlvir
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >URLVir.com&nbsp;Active Malicious IP Addresses Hosting
                    Malware. URLVir is an online security service developed by
                    NoVirusThanks Company Srl that automatically monitors
                    changes of malicious URLs (executable files).
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 52.2pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 492; height: 135pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 135pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >uscert_hidden_cobra
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 135pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #24292e;
                      mso-font-kerning: 0pt;
                    "
                    >Since 2009, HIDDEN COBRA actors have leveraged their
                    capabilities to target and compromise a range of victims;
                    some intrusions have resulted in the exfiltration of data
                    while others have been disruptive in nature. Commercial
                    reporting has referred to this activity as Lazarus Group and
                    Guardians of Peace. DHS and FBI assess that HIDDEN COBRA
                    actors will continue to use cyber operations to advance
                    their government’s military and strategic objectives. Tools
                    and capabilities used by HIDDEN COBRA actors include DDoS
                    botnets, keyloggers, remote access tools (RATs), and wiper
                    malware. Variants of malware and tools used by HIDDEN COBRA
                    actors include Destover, Wild Positron/Duuzer and Hangman.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 135pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 493; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >voipbl
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >VoIPBL.org&nbsp;a distributed VoIP blacklist that is aimed
                    to protects against VoIP Fraud and minimizing abuse for
                    network that have publicly accessible PBX's. Several
                    algorithms, external sources and manual confirmation are
                    used before they categorize something as an attack and
                    determine the threat level.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 4 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 494; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >vxvault
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >VxVault&nbsp;The latest 100 additions of VxVault.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 495; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xforce_bccs
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >IBM X-Force Exchange&nbsp;Botnet Command and Control
                    Servers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 day<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 496; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 497; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy_1d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 498; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy_30d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 499; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy_7d
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >xroxy.com&nbsp;open proxies (this list is composed using an
                    RSS feed)
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 1 hour<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 500; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >yoyo_adservers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Yoyo.org&nbsp;IPs of ad servers
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 12 hours<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 501; height: 104.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >zeus
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Zeus tracker&nbsp;standard, contains the same data
                    as the ZeuS IP blocklist (zeus_badips) but with the slight
                    difference that it doesn't exclude hijacked websites (level
                    2) and free web hosting providers (level 3). This means that
                    this blocklist contains all IPv4 addresses associated with
                    ZeuS C&amp;Cs which are currently being tracked by ZeuS
                    Tracker. Hence this blocklist will likely cause some false
                    positives.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 104.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 502; mso-yfti-lastrow: yes; height: 87pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >zeus_badips
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 63.3%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >Abuse.ch Zeus tracker&nbsp;badips includes IPv4 addresses
                    that are used by the ZeuS trojan. It is the recommened
                    blocklist if you want to block only ZeuS IPs. It excludes IP
                    addresses that ZeuS Tracker believes to be hijacked (level
                    2) or belong to a free web hosting provider (level 3). Hence
                    the false postive rate should be much lower compared to the
                    standard ZeuS IP blocklist.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.54%;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 87pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >updated every 30 mins<span style="mso-spacerun: yes"
                      >&nbsp;
                    </span>
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </div>

    <!-- CPEInventory -->
    <div v-show="this.index === '11_0'">
      <v-card-title class="comm_title1">ANNEX. CPE Inventory</v-card-title>
      <v-card-text>
        Common Platform Enumeration (CPE) is a structured naming scheme for
        information technology systems, software, and packages.
        <br />
        Since the WIKI-RAV is connected with CPE (common platform
        enumeration), you can search not only general server or network
        equipment but also more than 5,000 products such as Web Camera, VoIP,
        POS, Industrial Controller, Set-top Box, TV, etc.
      </v-card-text>

      <div class="comm_explain_explainBox">
        <h4 class="comm_explain_explainBox_title">▶ NOTICE</h4>
        Since the technology of detecting the operating system, hardware, and
        application of the remote host may not be accurate depending on the
        network environment.
        <br />
        In order to search for the correct product and version, it is
        recommended to use the search keywords such as devtype, service,
        product, etc. which provide the analysis results based on the opened
        service banner information.
        <br />
        <br />
      </div>

      <v-card-text>
        <p class="comm_title3">CPE Format</p>
        All of the CPE entries use the following format
        <table class="table_1cell" style="margin-bottom: 0">
          <tr>
            <td>cpe:/[part](:[vendor]:([product]:([version])))</td>
          </tr>
        </table>
        <ul style="padding-left: 20px">
          <li>
            part: defines the type of system detected, and can have one of the
            following values:
            <ul class="comm_ul">
              <li style="margin: 0; padding: 0">a : for Application</li>
              <li style="margin: 0; padding: 0">h : for Hardware</li>
              <li style="margin: 0; padding: 0">o : for Operating System</li>
            </ul>
          </li>
          <li>
            vendor: contains the name of the organization that developed the
            product.
          </li>
          <li>
            product: contains the name of the product that has been detected.
          </li>
          <li>version: lists the version number for the product.</li>
        </ul>
      </v-card-text>

      <v-card-text>
        <p class="comm_title3">Examples by the Part</p>
        <ul claee="comm_ul" style="margin-left: 20px">
          <li>
            Example to search Microsoft's operating system Windows Server 2008
            <table class="table_1cell" style="width: 50%">
              <tr>
                <td>cpe:/o:microsoft:windows_server_2008</td>
              </tr>
            </table>
          </li>
          <li>
            Example of searching HP's hardware Laser Printer 550 models
            <table class="table_1cell" style="width: 50%">
              <tr>
                <td>cpe:/h:hp:/laserjet/ or cpe:/h:hp:laserjet_5550</td>
              </tr>
            </table>
          </li>
          <li>
            Example of searching Cisco's application Adaptive Security Appliance
            Software (ASA)
            <table class="table_1cell" style="width: 50%">
              <tr>
                <td>cpe:/a:cisco:adaptive_security_appliance_software</td>
              </tr>
            </table>
          </li>
        </ul>
      </v-card-text>

      <v-card-text>
        <p class="comm_title3">CPE List by Manufacturer and Product</p>
        <div style="font-size: 0.8em">
          Fingerprint 2N Helios IP VoIP doorbell<br />
          cpe:/h:2n:helios<br />
          <br />
          Fingerprint 2Wire BT2700HG-V ADSL modem<br />
          cpe:/h:2wire:bt2700hg-v<br />
          <br />
          Fingerprint 2Wire 1701HG wireless ADSL modem<br />
          cpe:/h:2wire:1701hg<br />
          <br />
          Fingerprint 2Wire 1701HG, 2700HG, 2700HG-B, 2701HG-B, RG2701HG, or
          3800HGV-B wireless ADSL modem<br />
          cpe:/h:2wire:1701hg<br />
          cpe:/h:2wire:2700hg<br />
          cpe:/h:2wire:2700hg-b<br />
          cpe:/h:2wire:2701hg-b<br />
          cpe:/h:2wire:rg2701hg<br />
          cpe:/h:2wire:3800hgv-b<br />
          <br />
          Fingerprint 2Wire 2700HG-B wireless ADSL modem<br />
          cpe:/h:2wire:2700hg-b<br />
          <br />
          Fingerprint 2Wire 2701HG wireless ADSL modem<br />
          cpe:/h:2wire:2701hg<br />
          <br />
          Fingerprint 2Wire 2701HG-B wireless ADSL modem<br />
          cpe:/h:2wire:2701hg-b<br />
          <br />
          Fingerprint 2Wire 2701HG-B wireless ADSL modem<br />
          cpe:/h:2wire:2701hg-b<br />
          <br />
          Fingerprint 2Wire 2701HG-G wireless ADSL modem<br />
          cpe:/h:2wire:2701hg-g<br />
          <br />
          Fingerprint 2Wire Shasta wireless broadband router<br />
          cpe:/h:2wire:1000sw<br />
          <br />
          Fingerprint 3com OfficeConnect 812 ADSL router<br />
          cpe:/h:3com:officeconnect_812 auto<br />
          <br />
          Fingerprint 3Com 3016 router<br />
          cpe:/h:3com:3016 auto<br />
          <br />
          Fingerprint 3Com OfficeConnect 3CR858-91 router<br />
          cpe:/h:3com:officeconnect_3cr858-91 auto<br />
          <br />
          Fingerprint 3Com 3924 switch<br />
          cpe:/h:3com:3924 auto<br />
          <br />
          Fingerprint 3Com 4200G or Huawei Quidway S5600 switch<br />
          cpe:/o:huawei:vrp:3 auto<br />
          <br />
          Fingerprint 3Com 4210, or Huawei Quidway S3928P-EI or S5624F switch
          (VRP 3.10)<br />
          cpe:/o:huawei:vrp:3.10 auto<br />
          <br />
          Fingerprint 3Com 4500G switch<br />
          cpe:/h:3com:4500g auto<br />
          <br />
          Fingerprint 3Com 5500-EI switch<br />
          cpe:/h:3com:5500-ei auto<br />
          <br />
          Fingerprint 3Com 5500-EI switch<br />
          cpe:/h:3com:5500-ei auto<br />
          <br />
          Fingerprint 3Com 8810 switch<br />
          cpe:/h:3com:8810 auto<br />
          <br />
          Fingerprint 3Com Baseline Switch 2226-SFP Plus<br />
          cpe:/h:3com:baseline_switch_2226-sfp_plus<br />
          <br />
          Fingerprint 3Com Baseline Switch 2250-SFP Plus<br />
          cpe:/h:3com:baseline_switch_2250-sfp_plus<br />
          <br />
          Fingerprint 3Com Baseline Switch 2924-SFP or Cisco ESW-520 switch or
          Allied Telesis AT-8000 series switch<br />
          cpe:/h:3com:baseline_switch_2924-sfp<br />
          cpe:/h:cisco:esw-520<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3300<br />
          cpe:/h:3com:superstack_3_switch_3300 auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3300 TM<br />
          cpe:/h:3com:superstack_3_switch_3300_tm auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3300 XM<br />
          cpe:/h:3com:superstack_3_switch_3300_xm auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3300 XM<br />
          cpe:/h:3com:superstack_3_switch_3300_xm auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3300 XM<br />
          cpe:/h:3com:superstack_3_switch_3300_xm auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3300 XM, 4200, or 4400 SE<br />
          cpe:/h:3com:superstack_3_switch_3300_xm<br />
          cpe:/h:3com:superstack_3_switch_4200<br />
          cpe:/h:3com:superstack_3_switch_4200_se<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3812<br />
          cpe:/h:3com:superstack_3_switch_3812 auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 3870<br />
          cpe:/h:3com:superstack_3_switch_3870 auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4200 or 4226T<br />
          cpe:/h:3com:superstack_3_switch_4200<br />
          cpe:/h:3com:superstack_3_switch_4200t<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4400<br />
          cpe:/h:3com:superstack_3_switch_4400 auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4400 SE<br />
          cpe:/h:3com:superstack_3_switch_4400_se auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4400 SE<br />
          cpe:/h:3com:superstack_3_switch_4400_se auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4500<br />
          cpe:/h:3com:superstack_3_switch_4500 auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4500<br />
          cpe:/h:3com:superstack_3_switch_4500 auto<br />
          <br />
          Fingerprint 3Com SuperStack 3 Switch 4500 or Huawei Quidway AR 18-32
          ADSL router<br />
          cpe:/o:huawei:vrp:3 auto<br />
          <br />
          Fingerprint 3Com SuperStack II Switch 3300<br />
          cpe:/h:3com:superstack_ii_3300 auto<br />
          <br />
          Fingerprint 3Com SuperStack II Switch 3900-36<br />
          cpe:/h:3com:superstack_ii_3900-36 auto<br />
          <br />
          Fingerprint 3Com SuperStack Switch 4200<br />
          cpe:/h:3com:superstack_switch_4200 auto<br />
          <br />
          Fingerprint 3Com Switch 4200<br />
          cpe:/h:3com:switch_4200 auto<br />
          <br />
          Fingerprint 3Com Switch 4200G<br />
          cpe:/h:3com:switch_4200g auto<br />
          <br />
          Fingerprint 3Com 7760 WAP<br />
          cpe:/h:3com:7760 auto<br />
          <br />
          Fingerprint 3Com OfficeConnect 3CRWDR100A-72 wireless ADSL modem<br />
          cpe:/h:3com:officeconnect_3crwdr100a-72 auto<br />
          <br />
          Fingerprint 3Com OfficeConnect 3CRWE554G72T wireless broadband
          router<br />
          cpe:/h:3com:officeconnect_3crwe554g72t auto<br />
          <br />
          Fingerprint 3Com OfficeConnect 3CRWER100-75 wireless broadband
          router<br />
          cpe:/h:3com:officeconnect_3crwer100-75 auto<br />
          <br />
          Fingerprint 3Com OfficeConnect 3CRWER100-75 wireless router<br />
          cpe:/h:3com:officeconnect_3crwer100-75 auto<br />
          <br />
          Fingerprint 3M CT-30 thermostat<br />
          cpe:/h:3m:ct-30<br />
          <br />
          Fingerprint A-Tec MS347S or Mapower KC31N NAS device<br />
          cpe:/h:a-tec:ms347s<br />
          cpe:/h:mapower:kc31n auto<br />
          <br />
          Fingerprint Aastra AIP6500 interface card<br />
          cpe:/h:aastra:aip6500<br />
          <br />
          Fingerprint Aastra 57i VoIP phone<br />
          cpe:/h:aastra:57i<br />
          <br />
          Fingerprint Aastra 6731i VoIP phone or Apple AirPort Express WAP<br />
          cpe:/h:aastra:6731i<br />
          cpe:/h:apple:airport_express<br />
          <br />
          Fingerprint Aastra 6739i VoIP phone<br />
          cpe:/h:aastra:6739i<br />
          <br />
          Fingerprint Aastra A510 VoIP phone<br />
          cpe:/h:aastra:a510 auto<br />
          <br />
          Fingerprint Aceex BR41 broadband router<br />
          cpe:/h:aceex:br41 auto<br />
          <br />
          Fingerprint Actiontec GT701 DSL modem<br />
          cpe:/h:actiontec:gt701 auto<br />
          <br />
          Fingerprint Actiontec MI424WR-GEN3I WAP<br />
          cpe:/h:actiontec:mi424wr-gen3i auto<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Actiontec R3010UW wireless broadband router<br />
          cpe:/h:actiontec:r3010uw auto<br />
          <br />
          Fingerprint Adaptec Snap Server 100 NAS device<br />
          cpe:/h:adaptec:snap_server_100 auto<br />
          <br />
          Fingerprint Adaptec Snap Server 100 NAS device<br />
          cpe:/h:adaptec:snap_server_100 auto<br />
          <br />
          Fingerprint Adtran NetVanta switch (AOS 10.4 - 10.9)<br />
          cpe:/o:adtran:aos:10 auto<br />
          <br />
          Fingerprint Adtran AOS 18.02.01.00.E<br />
          cpe:/o:adtran:aos:18.02.01.00.e auto<br />
          <br />
          Fingerprint Adtran NetVanta 1224R or 3430 router<br />
          cpe:/h:adtran:netvanta_3430<br />
          cpe:/h:adtran:netvanta_1224r auto<br />
          <br />
          Fingerprint Adtran Total Access 5000 router<br />
          cpe:/h:adtran:total_access_5000<br />
          <br />
          Fingerprint Adtran Total Access 624 router<br />
          cpe:/h:adtran:total_access_624 auto<br />
          <br />
          Fingerprint Adtran Total Access 904 router<br />
          cpe:/h:adtran:total_access_904 auto<br />
          <br />
          Fingerprint Adtran 424RG FTTH gateway<br />
          cpe:/h:adtran:424rg<br />
          <br />
          Fingerprint Advanced Illumination DCS-100E lighting controller<br />
          cpe:/h:advanced_illumination:dcs-100e<br />
          <br />
          Fingerprint Aerohive HiveOS 3.4<br />
          cpe:/o:aerohive:hiveos:3.4 auto<br />
          <br />
          Fingerprint Aerohive HiveOS 5.1<br />
          cpe:/o:aerohive:hiveos:5.1 auto<br />
          <br />
          Fingerprint Aerohive HiveOS 6.1<br />
          cpe:/o:aerohive:hiveos:6.1 auto<br />
          <br />
          Fingerprint Aerohive HiveOS 6.8<br />
          cpe:/o:aerohive:hiveos:6.8 auto<br />
          <br />
          Fingerprint Aerohive HiveOS 7.1<br />
          cpe:/o:aerohive:hiveos:7.1 auto<br />
          <br />
          Fingerprint Aethra Starvoice 1042 ADSL router<br />
          cpe:/h:aethra:starvoice_1042 auto<br />
          <br />
          Fingerprint Aethra Vega X3 teleconference system<br />
          cpe:/h:aethra:vega_x3<br />
          <br />
          Fingerprint Agfa DryStar 5500 printer<br />
          cpe:/h:agfa:drystar_5500 auto<br />
          <br />
          Fingerprint AirLive WT-2000R, D-Link DI-524 or DI-604, SMC SMC7004VBR,
          or ZyXEL Prestige 320W broadband router<br />
          cpe:/h:airlive:wt-2000r<br />
          cpe:/h:dlink:di-524<br />
          cpe:/h:dlink:di-604<br />
          cpe:/h:smc:smc7004vbr<br />
          cpe:/h:zyxel:prestige_320w auto<br />
          <br />
          Fingerprint AirMagnet SmartEdge wireless sensor; or Foscam FI8904W,
          FI8910W, or FI8918W, or Instar IN-3010 surveillance camera (Linux
          2.4)<br />
          cpe:/h:airmagnet:smartedge<br />
          cpe:/h:foscam:fi8904w<br />
          cpe:/h:foscam:f18910w<br />
          cpe:/h:foscam:f18918w<br />
          cpe:/h:instar:in-3010<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Airvana C1-600-RT signal booster (Linux 2.6.21)<br />
          cpe:/h:airvana:c1-600-rt<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          <br />
          Fingerprint Alcatel SpeedTouch 510 DSL modem<br />
          cpe:/h:alcatel:speedtouch_510 auto<br />
          <br />
          Fingerprint Alcatel OmniStack 6024 or Enterasys Vertical Horizon
          VH-2402S2 switch, or Dell 1815dn or Samsung SCX-6x22 printer<br />
          cpe:/h:alcatel:omnistack_6024<br />
          cpe:/h:enterasys:vh-2402s2<br />
          cpe:/h:dell:1815dn<br />
          cpe:/h:samsung:scx-6x22<br />
          <br />
          Fingerprint Alcatel 4035 VoIP phone<br />
          cpe:/h:alcatel:4035 auto<br />
          <br />
          Fingerprint Alcatel-Lucent OmniPCX Enterprise PBX (Linux 2.4.17)<br />
          cpe:/h:alcatel-lucent:omnipcx<br />
          cpe:/o:linux:linux_kernel:2.4.17 auto<br />
          <br />
          Fingerprint Alcatel-Lucent OmniPCX GD3 PBX (Linux 2.6.23)<br />
          cpe:/h:alcatel-lucent:gd3<br />
          cpe:/o:linux:linux_kernel:2.6.23 auto<br />
          <br />
          Fingerprint Alcatel-Lucent OmniSwitch 6000 switch (firmware 6.3.1)<br />
          cpe:/h:alcatel-lucent:omniswitch_6000 auto<br />
          <br />
          Fingerprint Alcatel-Lucent OmniSwitch 6000 switch or Quantum DXi8500
          NAS device<br />
          cpe:/h:alcatel-lucent:omniswitch_6000 auto<br />
          cpe:/h:quantum:dxi8500<br />
          <br />
          Fingerprint Alcatel-Lucent OmniSwitch 6850 switch<br />
          cpe:/h:alcatel-lucent:omniswitch_6850 auto<br />
          <br />
          Fingerprint Alcatel-Lucent OmniSwitch 6850 switch<br />
          cpe:/h:alcatel-lucent:omniswitch_6850 auto<br />
          <br />
          Fingerprint Alcatel-Lucent 7750 Service Router (SR OS 9.0.R9)<br />
          cpe:/o:alcatel-lucent:sr_os:9.0.r9 auto<br />
          <br />
          Fingerprint Allen Bradley MicroLogix 1100 PLC<br />
          cpe:/h:allen-bradley:micrologix_1100<br />
          <br />
          Fingerprint Broadband router (Allied Data CopperJet, Belkin F5D7632-4,
          Intracom Jetspeed 500i, or Iskratel Sinope568 or Proteus932); or Adva
          Optical FSP 150CC-825 router<br />
          cpe:/h:advaoptical:fsp_150cc-825 auto<br />
          <br />
          Fingerprint Allied Telesis AlliedWare version 54291-05<br />
          cpe:/o:alliedtelesis:alliedware:54291-05<br />
          <br />
          Fingerprint Allied Telesis AT-8000S; Dell PowerConnect 2824, 3448,
          5316M, or 5324; Linksys SFE2000P, SRW2024, SRW2048, or SRW224G4; or
          TP-LINK TL-SL3428 switch<br />
          cpe:/h:alliedtelesis:at-8000s<br />
          cpe:/h:dell:powerconnect_2824<br />
          cpe:/h:dell:powerconnect_3448<br />
          cpe:/h:dell:powerconnect_5316m<br />
          cpe:/h:dell:powerconnect_5324<br />
          cpe:/h:linksys:sfe2000p<br />
          cpe:/h:linksys:srw2024<br />
          cpe:/h:linksys:srw2048<br />
          cpe:/h:linksys:srw224g4<br />
          cpe:/h:tp-link:tl-sl3428<br />
          <br />
          Fingerprint Allied Telesis AT-9000 switch<br />
          cpe:/h:alliedtelesis:at-9000<br />
          <br />
          Fingerprint Allied Telesis AT-9000, AT-FS750, or GS950 switch<br />
          <br />
          Fingerprint Allied Telesyn AT-AR300L CentreCOM router<br />
          cpe:/h:alliedtelesyn:at-ar300l<br />
          cpe:/o:alliedtelesyn:alliedware auto<br />
          <br />
          Fingerprint Allied Telesyn AT-AR410 router<br />
          cpe:/h:alliedtelesyn:at-ar410<br />
          cpe:/o:alliedtelesyn:alliedware auto<br />
          <br />
          Fingerprint Allied Telesyn AT-AR750s router<br />
          cpe:/h:alliedtelesyn:at-ar750s<br />
          cpe:/o:alliedtelesyn:alliedware auto<br />
          <br />
          Fingerprint Allied Telesyn 8326GB switch<br />
          cpe:/h:alliedtelesyn:at-8326gb<br />
          <br />
          Fingerprint Allied Telesyn AT-8350GB switch<br />
          cpe:/h:alliedtelesyn:at-8350gb<br />
          <br />
          Fingerprint Allied Telesyn AT-9006SX/SC switch<br />
          cpe:/h:alliedtelesyn:at-9006<br />
          <br />
          Fingerprint Allied Telesyn AT-9448Ts/XP switch<br />
          cpe:/h:alliedtelesyn:at-9448ts<br />
          <br />
          Fingerprint Allied Telesyn AT-GS950 Gigabit Ethernet Switch<br />
          cpe:/h:alliedtelesyn:at-gs950<br />
          <br />
          Fingerprint Allied Telesyn AT-GS950 or D-Link DES-3226L switch or
          D-Link DSL-2750U router<br />
          cpe:/h:alliedtelesyn:at-gs950<br />
          cpe:/h:dlink:des-3226l<br />
          cpe:/h:dlink:dsl-2750u<br />
          <br />
          Fingerprint Allied Telesyn Rapier 24i switch<br />
          cpe:/h:alliedtelesyn:rapier_24i<br />
          <br />
          Fingerprint Allied Telesyn Rapier G6 switch<br />
          cpe:/h:alliedtelesyn:rapier_g6<br />
          <br />
          Fingerprint Allnet 2210 webcam, Cisco MDS 9124 or 9216i switch (SAN-OS
          3.1 - 3.2), or Nortel IP Phone 1535<br />
          cpe:/h:allnet:2210 auto<br />
          cpe:/h:cisco:mds_9124<br />
          cpe:/h:cisco:mds_9216i<br />
          cpe:/o:cisco:san_os:3 auto<br />
          cpe:/h:nortel:ip_phone_1535<br />
          <br />
          Fingerprint Allworx 9212 VoIP phone<br />
          cpe:/h:allworx:9212 auto<br />
          <br />
          Fingerprint Ambit DOCSIS 2.0 cable modem, or Xerox Phaser 6250N,
          6350DT, or 8560MFP printer<br />
          cpe:/h:xerox:phaser_6250n<br />
          cpe:/h:xerox:phaser_6350dt<br />
          cpe:/h:xerox:phaser_8560mfp<br />
          <br />
          Fingerprint Ambit U10C018 or Cisco EPC3925 cable modem, or Tandberg
          video conferencing system<br />
          cpe:/h:ambit:u10c018<br />
          cpe:/h:cisco:epc3925<br />
          <br />
          Fingerprint AmigaOS 3.1 - 3.9<br />
          cpe:/o:amiga:amigaos:3 auto<br />
          <br />
          Fingerprint AmigaOS 3.9 BB2<br />
          cpe:/o:amiga:amigaos:3.9 auto<br />
          <br />
          Fingerprint APC power distribution unit (AOS 2.7.0)<br />
          cpe:/o:apc:aos:1 auto<br />
          <br />
          Fingerprint APC AP7851 power distribution unit (AOS 2.7.0)<br />
          cpe:/o:apc:aos:2.7.0 auto<br />
          <br />
          Fingerprint APC AP7920 rack PDU (AOS 2.7.0)<br />
          cpe:/o:apc:aos:2.7.0 auto<br />
          <br />
          Fingerprint APC AP9606 Network Management Card (AOS 2.5.4 - 3.2.6)<br />
          cpe:/o:apc:aos:2 auto<br />
          <br />
          Fingerprint APC 7940 or 7723 Network Management Card (AOS 3.7.3 -
          3.7.4)<br />
          cpe:/o:apc:aos:3.7<br />
          <br />
          Fingerprint APC 9340 Network Management Card (AOS 3.5.7)<br />
          cpe:/o:apc:aos:3.5.7 auto<br />
          <br />
          Fingerprint APC AOS 3.7.4<br />
          cpe:/o:apc:aos:3.7.4 auto<br />
          <br />
          Fingerprint APC AP7900-series power distribution unit (AOS 3.x)<br />
          cpe:/o:apc:aos:3.x auto<br />
          <br />
          Fingerprint APC AP7930 power distribution unit (AOS 3.3.4)<br />
          cpe:/o:apc:aos:3.3.4 auto<br />
          <br />
          Fingerprint APC AP9619 Network Management Card (AOS 3.3.1 - 3.6.1)<br />
          cpe:/o:apc:aos:3 auto<br />
          <br />
          Fingerprint APC MasterSwitch power distribution unit (AOS 3.0.3)<br />
          cpe:/o:apc:aos:3.0.3 auto<br />
          <br />
          Fingerprint APC Network Management Card<br />
          cpe:/o:apc:aos:3 auto<br />
          <br />
          Fingerprint APC Network Management Card (AOS 3.3.4 - 3.5.5)<br />
          cpe:/o:apc:aos:3 auto<br />
          <br />
          Fingerprint APC Network Management Card (AOS 3.5.7 - 3.5.8)<br />
          cpe:/o:apc:aos:3 auto<br />
          <br />
          Fingerprint APC Smart-UPS (AOS 3.3.4)<br />
          cpe:/o:apc:aos:3.3.4 auto<br />
          <br />
          Fingerprint APC AOS 5<br />
          cpe:/o:apc:aos:5 auto<br />
          <br />
          Fingerprint APC Smart-UPS (AOS 5)<br />
          cpe:/o:apc:aos:5 auto<br />
          <br />
          Fingerprint APC Smart-UPS (AOS 5.1.1)<br />
          cpe:/h:apc:smart-ups_rt_5000va<br />
          cpe:/h:apc:smart-ups_x_1000va<br />
          cpe:/o:apc:aos:5.1.1 auto<br />
          <br />
          Fingerprint APC Silicon DP320E UPS<br />
          cpe:/h:apc:silicon_dp320e<br />
          <br />
          Fingerprint Apple A/UX 3.0.1 - 3.1.1 SVR2<br />
          cpe:/o:apple:a_ux:3 auto<br />
          <br />
          Fingerprint Apple A/UX 3.1.1 SVR2<br />
          cpe:/o:apple:a_ux:3.1.1 auto<br />
          <br />
          Fingerprint Apple TV 5<br />
          cpe:/o:apple:apple_tv:5 auto<br />
          <br />
          Fingerprint Apple TV 5.2.1 or 5.3<br />
          cpe:/a:apple:apple_tv:5.2.1<br />
          cpe:/a:apple:apple_tv:5.3<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP or Time Capsule NAS device
          (NetBSD)<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple Time Capsule NAS device<br />
          <br />
          Fingerprint Apple AirPort Express WAP<br />
          cpe:/h:apple:airport_express<br />
          <br />
          Fingerprint Apple AirPort Express WAP or AMX NI-3100 controller
          (VxWorks)<br />
          cpe:/h:apple:airport_express<br />
          <br />
          Fingerprint Apple AirPort Extreme 802.11ac WAP or Time Capsule NAS
          device or NetBSD 6.1<br />
          cpe:/h:apple:airport_extreme<br />
          cpe:/h:apple:time_capsule:7.7.2<br />
          cpe:/o:netbsd:netbsd:6.1<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP or Time Capsule NAS device<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP or Time Capsule NAS device
          (NetBSD 4.99)<br />
          cpe:/h:apple:airport_extreme<br />
          <br />
          Fingerprint Apple iOS 10<br />
          cpe:/o:apple:iphone_os:10 auto<br />
          <br />
          Fingerprint Apple iOS 11.0<br />
          cpe:/o:apple:iphone_os:11.0 auto<br />
          <br />
          Fingerprint Apple iOS 4.3.3<br />
          cpe:/o:apple:iphone_os:4.3.3 auto<br />
          cpe:/o:apple:iphone_os:4.3.3 auto<br />
          <br />
          Fingerprint Apple iPad tablet computer (iOS 4)<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          <br />
          Fingerprint Apple iPad tablet computer (iOS 4.3.2)<br />
          cpe:/o:apple:iphone_os:4.3.2 auto<br />
          <br />
          Fingerprint Apple iPad tablet computer (iOS 4.3.2)<br />
          cpe:/o:apple:iphone_os:4.3.2 auto<br />
          <br />
          Fingerprint Apple iPad tablet computer (iOS 4.3.3)<br />
          cpe:/o:apple:iphone_os:4.3.3 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.3 (Lion) or iOS 4.2.1 - 4.3.4 (Darwin
          10.4.0 - 11.3.0)<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:mac_os_x:10.7.3<br />
          <br />
          Fingerprint Apple iOS 4.0<br />
          cpe:/o:apple:iphone_os:4.0 auto<br />
          <br />
          Fingerprint Apple iOS 4.1 - 4.2.1<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          <br />
          Fingerprint Apple iOS 4.3.1 - 4.3.5<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          <br />
          Fingerprint Apple iPad tablet computer or iPhone mobile phone (iOS 4.0
          - 4.1)<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          <br />
          Fingerprint Apple iPhone 4 mobile phone (iOS 4.1)<br />
          cpe:/o:apple:iphone_os:4.1 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iOS 4.3.2)<br />
          cpe:/o:apple:iphone_os:4.3.2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iOS 4.3.3)<br />
          cpe:/o:apple:iphone_os:4.3.3<br />
          <br />
          Fingerprint Apple Mac OS X 10.5 (Leopard) - 10.6.8 (Snow Leopard) or
          iOS 4.0 - 4.2.1 (Darwin 9.0.0b5 - 10.8.0)<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple iOS 5.0<br />
          cpe:/o:apple:iphone_os:5.0 auto<br />
          cpe:/o:apple:iphone_os:5.0 auto<br />
          <br />
          Fingerprint Apple iOS 5.0.1<br />
          cpe:/o:apple:iphone_os:5.0.1 auto<br />
          cpe:/o:apple:iphone_os:5.0.1 auto<br />
          <br />
          Fingerprint Apple iOS 5.0.1<br />
          cpe:/o:apple:iphone_os:5.0.1 auto<br />
          cpe:/o:apple:iphone_os:5.0.1 auto<br />
          <br />
          Fingerprint Apple iOS 5.0.1 - 5.1.1<br />
          cpe:/o:apple:iphone_os:5<br />
          <br />
          Fingerprint Apple iOS 5.1.1<br />
          cpe:/o:apple:iphone_os:5.1.1 auto<br />
          cpe:/o:apple:iphone_os:5.1.1 auto<br />
          <br />
          Fingerprint Apple iOS 5.1.1 - 6.1<br />
          cpe:/o:apple:iphone_os:5 auto<br />
          cpe:/o:apple:iphone_os:5 auto<br />
          cpe:/o:apple:iphone_os:6 auto<br />
          cpe:/o:apple:iphone_os:6 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 (Mountain Lion) - 10.9 (Mavericks) or iOS
          5.0.1 - 5.1.1 (Darwin 11.0.0 - 13.2.0)<br />
          cpe:/o:apple:iphone_os:5<br />
          cpe:/o:apple:mac_os_x:10.8<br />
          cpe:/o:apple:mac_os_x:10.9<br />
          <br />
          Fingerprint Apple iOS 6<br />
          cpe:/o:apple:iphone_os:6 auto<br />
          <br />
          Fingerprint Apple iOS 6.1.3<br />
          cpe:/o:apple:iphone_os:6.1.3 auto<br />
          <br />
          Fingerprint Apple iOS 6.0.1<br />
          cpe:/o:apple:iphone_os:6.0.1 auto<br />
          <br />
          Fingerprint Apple iOS 6.1.2<br />
          cpe:/o:apple:iphone_os:6.1.2 auto<br />
          <br />
          Fingerprint Apple iOS 6.1.4 (Darwin 13.0.0)<br />
          cpe:/o:apple:iphone_os:6.1.4 auto<br />
          <br />
          Fingerprint Apple iOS 7<br />
          cpe:/o:apple:iphone_os:7 auto<br />
          <br />
          Fingerprint Apple iOS 7.1 or 9.3 (Darwin 14.0.0 - 15.4.0)<br />
          cpe:/o:apple:iphone_os:7 auto<br />
          <br />
          Fingerprint Apple iOS 7.1.1<br />
          cpe:/o:apple:iphone_os:7.1.1 auto<br />
          <br />
          Fingerprint Apple iOS 8.0 - 8.1 (Darwin 14.0.0)<br />
          cpe:/o:apple:iphone_os:8 auto<br />
          <br />
          Fingerprint Apple iOS 8.1.2 (Darwin 14.0.0)<br />
          cpe:/o:apple:iphone_os:8.1.2 auto<br />
          <br />
          Fingerprint Apple iOS 8.2 (Darwin 14.0.0)<br />
          cpe:/o:apple:iphone_os:8.2 auto<br />
          <br />
          Fingerprint Apple iOS 8.2 (Darwin 14.0.0)<br />
          cpe:/o:apple:iphone_os:8.2 auto<br />
          <br />
          Fingerprint Apple iOS 8.3 (Darwin 14.0.0)<br />
          cpe:/o:apple:iphone_os:8.3 auto<br />
          <br />
          Fingerprint Apple iOS 8.4 - 9.3 (Darwin 14.0.0 - 15.6.0)<br />
          cpe:/o:apple:iphone_os:8 auto<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          <br />
          Fingerprint Apple OS X 10.10 (Yosemite) or iOS 8.3 - 9.0.1 (Darwin
          14.0.0 - 15.0.0)<br />
          cpe:/o:apple:iphone_os:8 auto<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          <br />
          Fingerprint Apple iOS 9 (Darwin 15.0.0)<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          <br />
          Fingerprint Apple iOS 9 (Darwin 15.0.0)<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          <br />
          Fingerprint Apple iOS 9 (Darwin 15.0.0)<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          <br />
          Fingerprint Apple iOS 9.0 (Darwin 15.0.0)<br />
          cpe:/o:apple:iphone_os:9.0 auto<br />
          <br />
          Fingerprint Apple iOS 9.3.2 (Darwin 15.5.0)<br />
          cpe:/o:apple:iphone_os:9.3.2 auto<br />
          <br />
          Fingerprint Apple iPod touch audio player (iPhone OS 1.1.2 - 1.1.4,
          Darwin 9.0.0d1)<br />
          cpe:/o:apple:iphone_os:1 auto<br />
          <br />
          Fingerprint Apple iPod touch audio player (iPhone OS 1.1.3, Darwin
          9.0.0d1)<br />
          cpe:/o:apple:iphone_os:1.1.3 auto<br />
          <br />
          Fingerprint Apple iPod touch audio player (iPhone OS 1.1.3, Darwin
          9.0.0d1)<br />
          cpe:/o:apple:iphone_os:1.1.3 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 2.1)<br />
          cpe:/o:apple:iphone_os:1 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone or iPod touch audio player
          (iPhone OS 1.0.2 - 1.1.3, Darwin 9.0.0d1)<br />
          cpe:/o:apple:iphone_os:1 auto<br />
          cpe:/o:apple:iphone_os:1 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 2.1 - 2.2, Darwin
          9.4.1)<br />
          cpe:/o:apple:iphone_os:2 auto<br />
          <br />
          Fingerprint Apple iPod touch audio player (iPhone OS 2.1)<br />
          cpe:/o:apple:iphone_os:2.1 auto<br />
          <br />
          Fingerprint Apple iPod touch audio player (iPhone OS 2.1)<br />
          cpe:/o:apple:iphone_os:2.1 auto<br />
          <br />
          Fingerprint Apple iPod touch audio player (iPhone OS 2.2)<br />
          cpe:/o:apple:iphone_os:2.2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 2.0.2)<br />
          cpe:/o:apple:iphone_os:2.0.2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 2.1)<br />
          cpe:/o:apple:iphone_os:2.1 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 2.2)<br />
          cpe:/o:apple:iphone_os:2.2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 2.2.1)<br />
          cpe:/o:apple:iphone_os:2.2.1 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS)<br />
          cpe:/o:apple:iphone_os:2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone or iPod touch audio player
          (iPhone OS 2.0.1 - 2.0.2, Darwin 9.3.1)<br />
          cpe:/o:apple:iphone_os:2 auto<br />
          cpe:/o:apple:iphone_os:2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone or iPod touch media player
          (iPhone OS 2.1 - 2.2.2, Darwin 9.4.1)<br />
          cpe:/o:apple:iphone_os:2 auto<br />
          cpe:/o:apple:iphone_os:2 auto<br />
          <br />
          Fingerprint Apple iPod touch media player (iPhone OS 2.1)<br />
          cpe:/o:apple:iphone_os:2.1 auto<br />
          cpe:/o:apple:iphone_os:2.1 auto<br />
          <br />
          Fingerprint Apple iPad tablet computer (iPhone OS 3.2)<br />
          cpe:/o:apple:iphone_os:3.2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone or iPod touch media player
          (iPhone OS 3.0 - 3.1.2)<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          <br />
          Fingerprint Apple iPhone 3GS mobile phone (iPhone OS 3.2)<br />
          cpe:/o:apple:iphone_os:3.2 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 3.0 - 3.1.2)<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS 3.0 - iOS 4.2.1)<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          cpe:/o:apple:iphone_os:4<br />
          <br />
          Fingerprint Apple iPhone mobile phone or iPod touch media player
          (iPhone OS 3.0 - 3.2, Darwin 10.0.0d3)<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          <br />
          Fingerprint Apple iPhone OS 3<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          <br />
          Fingerprint Apple iPhone OS 3.0.1<br />
          cpe:/o:apple:iphone_os:3.0.1 auto<br />
          <br />
          Fingerprint Apple iPhone OS 3.1.2<br />
          cpe:/o:apple:iphone_os:3.1.2 auto<br />
          <br />
          Fingerprint Apple iPhone OS 3.1.2 - iOS 4.2.1<br />
          cpe:/o:apple:iphone_os:3 auto<br />
          cpe:/o:apple:iphone_os:4.2.1 auto<br />
          <br />
          Fingerprint Apple iPhone mobile phone (iPhone OS, Darwin 9.3.1)<br />
          cpe:/o:apple:iphone_os auto<br />
          <br />
          Fingerprint Apple Mac OS X Server 10.1 (Darwin 5.5)<br />
          cpe:/o:apple:mac_os_x:10.1 auto<br />
          <br />
          Fingerprint Apple Mac OS X Server 10.2.8 (Jaguar) (Darwin 6.8,
          PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.2.8<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) (Darwin 7.9.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) (Darwin 7.9.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) (Darwin 7.9.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) (Darwin 7.9.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) (Darwin 7.9.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) - 10.4.5 (Tiger) (Darwin
          7.9.0 - 8.5.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.3.9 (Panther) - 10.4.7 (Tiger) (Darwin
          7.9.0 - 8.7.8, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.3 auto<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X Server 10.3.9 (Panther) (Darwin 7.9.0,
          PowerPC)<br />
          cpe:/o:apple:mac_os_x_server:10.3.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.4 (Tiger) (Darwin 8.0.0)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4 (Tiger) (Darwin 8.11.0)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 (Tiger) (Darwin 8.10.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4.10 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 (Tiger) (Darwin 8.10.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4.10<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 (Tiger) (Darwin 8.10.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4.10<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 (Tiger) (Darwin 8.10.1)<br />
          cpe:/o:apple:mac_os_x:10.4.10<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 - 10.4.11 (Tiger) (Darwin 8.10.0 -
          8.11.0)<br />
          cpe:/o:apple:mac_os_x:10.4<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 - 10.4.11 (Tiger) (Darwin 8.10.0 -
          8.11.1)<br />
          cpe:/o:apple:mac_os_x:10.4.10<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 - 10.4.11 (Tiger) (Darwin 8.10.0 -
          8.11.1)<br />
          cpe:/o:apple:mac_os_x:10.4.10<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.10 - 10.4.11 (Tiger) (Darwin 8.10.0 -
          8.11.1)<br />
          cpe:/o:apple:mac_os_x:10.4<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.11 (Tiger) (Darwin 8.11.0)<br />
          cpe:/o:apple:mac_os_x:10.4.10<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.11 (Tiger) (Darwin 8.11.0)<br />
          cpe:/o:apple:mac_os_x:10.4.11<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.11 (Tiger) (Darwin 8.11.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4.11<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.11 (Tiger) (Darwin 8.11.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4.11<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.8 - 10.4.11 (Tiger) (Darwin 8.8.0 -
          8.11.0)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.8 - 10.4.11 (Tiger) (Darwin 8.8.0 -
          8.11.0)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.8 - 10.4.11 (Tiger) (Darwin 8.8.0 -
          8.11.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.8 - 10.4.11 (Tiger) (Darwin 8.8.0 -
          8.11.1)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.8 - 10.4.9 (Tiger) (Darwin 8.8.0 -
          8.9.0, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.9 (Tiger) (PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4.9<br />
          <br />
          Fingerprint Apple Mac OS X 10.4.9 - 10.4.10 (Tiger) (Darwin 8.11.0 -
          8.11.1, PowerPC)<br />
          cpe:/o:apple:mac_os_x:10.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5 (Leopard) (Darwin 9.0.0)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5 (Leopard) (Darwin 9.0.0b4, x86)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5 (Leopard) (Darwin 9.2.2, x86)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5 (Leopard) - 10.6.7 (Snow Leopard)
          (Darwin 9.0.0 - 10.7.0)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.2 (Leopard) - 10.6.7 (Snow Leopard) or
          iOS 4.0.1 - 4.2.1 (Darwin 9.2.0 - 10.7.0)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.3 (Leopard) (Darwin 9.3.0)<br />
          cpe:/o:apple:mac_os_x:10.5.3<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.3 - 10.5.4 (Leopard) (Darwin 9.3.0 -
          9.4.0)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.4 (Leopard) (Darwin 9.4.0)<br />
          cpe:/o:apple:mac_os_x:10.5.4<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.4 (Leopard) (Darwin 9.4.0)<br />
          cpe:/o:apple:mac_os_x:10.5.4<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.5 (Leopard) (Darwin 9.5.0)<br />
          cpe:/o:apple:mac_os_x:10.5.5<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.5 (Leopard) - 10.6.6 (Snow Leopard)
          (Darwin 9.5.0 - 10.6.0)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.6 (Leopard) (Darwin 9.5.0)<br />
          cpe:/o:apple:mac_os_x:10.5.6<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.6 (Leopard) (Darwin 9.6.0)<br />
          cpe:/o:apple:mac_os_x:10.5.6<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.6 (Leopard) - 10.6.2 (Snow Leopard)
          (Darwin 9.6.0 - 10.2.0)<br />
          cpe:/o:apple:mac_os_x:10.5 auto<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.7 (Leopard) (Darwin 9.7.0)<br />
          cpe:/o:apple:mac_os_x:10.5.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) (Darwin 9.8.0)<br />
          cpe:/o:apple:mac_os_x:10.5.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) (Darwin 9.8.0)<br />
          cpe:/o:apple:mac_os_x:10.5.8<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) (Darwin 9.8.0)<br />
          cpe:/o:apple:mac_os_x:10.5.8<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) (Darwin 9.8.0)<br />
          cpe:/o:apple:mac_os_x:10.5.8<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) (Darwin 9.8.0)<br />
          cpe:/o:apple:mac_os_x:10.5.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) (Darwin 9.8.0)<br />
          cpe:/o:apple:mac_os_x:10.5.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X Server 10.5 (Leopard) pre-release build
          9A284<br />
          cpe:/o:apple:mac_os_x_server:10.5<br />
          <br />
          Fingerprint Apple Mac OS X Server 10.5.1 (Leopard) (Darwin 9.1.0)<br />
          cpe:/o:apple:mac_os_x_server:10.5.1<br />
          <br />
          Fingerprint Apple Mac OS X 10.5.8 (Leopard) - 10.6.4 (Snow Leopard)
          (Darwin 9.8.0 - 10.4.0)<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6 (Snow Leopard)<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6 (Snow Leopard) (Darwin 10.0.0)<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6 (Snow Leopard) (Darwin 10.0.0)<br />
          cpe:/o:apple:mac_os_x:10.6 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.2 (Snow Leopard) (Darwin 10.2.0)<br />
          cpe:/o:apple:mac_os_x:10.6.2<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.2 (Snow Leopard) (Darwin 10.2.0)<br />
          cpe:/o:apple:mac_os_x:10.6.2<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.2 (Snow Leopard) (Darwin 10.2.0)<br />
          cpe:/o:apple:mac_os_x:10.6.2<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.3 (Snow Leopard) (Darwin 10.3.0)<br />
          cpe:/o:apple:mac_os_x:10.6.3<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.4 (Snow Leopard) (Darwin 10.4.0)<br />
          cpe:/o:apple:mac_os_x:10.6.4<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.5 (Snow Leopard) (Darwin 10.5.0)<br />
          cpe:/o:apple:mac_os_x:10.6.5<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.5 (Snow Leopard) (Darwin 10.5.0)<br />
          cpe:/o:apple:mac_os_x:10.6.5<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.6 (Snow Leopard) (Darwin 10.6.0)<br />
          cpe:/o:apple:mac_os_x:10.6.6<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.7 (Snow Leopard) (Darwin 10.7.0)<br />
          cpe:/o:apple:mac_os_x:10.6.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.7 (Snow Leopard) (Darwin 10.7.0)<br />
          cpe:/o:apple:mac_os_x:10.6.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.8 (Darwin 10.8.0)<br />
          cpe:/o:apple:mac_os_x:10.6.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.8 (Leopard) (Darwin 10.8.0)<br />
          cpe:/o:apple:mac_os_x:10.6.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.8 (Snow Leopard) (Darwin 10.8.0)<br />
          cpe:/o:apple:mac_os_x:10.6.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.8 (Snow Leopard) (Darwin 10.8.0)<br />
          cpe:/o:apple:mac_os_x:10.6.8<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.8 (Snow Leopard) (Darwin 10.8.0)<br />
          cpe:/o:apple:mac_os_x:10.6.8<br />
          <br />
          Fingerprint Apple Mac OS X 10.6.8 (Snow Leopard) (Darwin 10.8.0)<br />
          cpe:/o:apple:mac_os_x:10.6.8 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.0 (Lion) - 10.12 (Sierra) or iOS 4.1 -
          9.3.3 (Darwin 10.0.0 - 16.4.0)<br />
          cpe:/o:apple:mac_os_x:10.7<br />
          cpe:/o:apple:mac_os_x:10.8<br />
          cpe:/o:apple:mac_os_x:10.9<br />
          cpe:/o:apple:mac_os_x:10.10<br />
          cpe:/o:apple:mac_os_x:10.11<br />
          cpe:/o:apple:mac_os_x:10.12<br />
          cpe:/o:apple:iphone_os:4<br />
          cpe:/a:apple:apple_tv:4<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:5 auto<br />
          cpe:/o:apple:iphone_os:6 auto<br />
          cpe:/o:apple:iphone_os:7 auto<br />
          cpe:/o:apple:iphone_os:8 auto<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.0 - 10.7.3 (Lion) (Darwin 11.0.0 -
          11.3.0)<br />
          cpe:/o:apple:mac_os_x:10.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.0 - 10.7.4 (Lion) (Darwin 11.0.0 -
          11.3.0)<br />
          cpe:/o:apple:mac_os_x:10.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.0 - 10.7.4 (Lion) (Darwin 11.0.0 -
          11.4.0) or iPhone mobile phone (iOS 4.3.2)<br />
          cpe:/o:apple:mac_os_x:10.7 auto<br />
          cpe:/o:apple:iphone_os:4.3.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.0 - 10.7.5 (Lion) (Darwin 11.0.0 -
          11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0)<br />
          cpe:/o:apple:mac_os_x:10.7.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0)<br />
          cpe:/o:apple:mac_os_x:10.7.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0)<br />
          cpe:/o:apple:mac_os_x:10.7.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0)<br />
          cpe:/o:apple:mac_os_x:10.7.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0)<br />
          cpe:/o:apple:mac_os_x:10.7.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0)<br />
          cpe:/o:apple:mac_os_x:10.7.2 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 (Lion) (Darwin 11.2.0) or iOS 4.3.3
          - 4.3.5<br />
          cpe:/o:apple:mac_os_x:10.7.2<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.2 - 10.7.3 (Lion) (Darwin 11.2.0 -
          11.3.0)<br />
          cpe:/o:apple:mac_os_x:10.7 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.3 (Lion) (Darwin 11.3.0)<br />
          cpe:/o:apple:mac_os_x:10.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.3 (Lion) (Darwin 11.3.0)<br />
          cpe:/o:apple:mac_os_x:10.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.4 (Lion) (Darwin 11.4.0)<br />
          cpe:/o:apple:mac_os_x:10.7.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.4 (Lion) (Darwin 11.4.0)<br />
          cpe:/o:apple:mac_os_x:10.7<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.4 (Lion) (Darwin 11.4.0) or Apple TV
          (iOS 4.3)<br />
          cpe:/o:apple:mac_os_x:10.7.4 auto<br />
          cpe:/o:apple:iphone_os:4.3 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.4 (Lion) (Darwin 11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7.4 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.4 - 10.7.5 (Lion) (Darwin 11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.5 (Lion) (Darwin 11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.5 (Lion) (Darwin 11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.5 (Mountain Lion) (Darwin 11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7.5 auto<br />
          <br />
          Fingerprint Apple Mac OS X 10.7.0 - 10.7.5 (Lion) or iOS 4.2 - 5.0.1
          (Darwin 10.4.0 - 11.4.2)<br />
          cpe:/o:apple:mac_os_x:10.7 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:4 auto<br />
          cpe:/o:apple:iphone_os:5 auto<br />
          <br />
          Fingerprint Apple Mac OS 8.1 - 8.6<br />
          cpe:/o:apple:mac_os:8 auto<br />
          <br />
          Fingerprint Apple Mac OS 9.0.4 - 9.2<br />
          cpe:/o:apple:mac_os:9 auto<br />
          <br />
          Fingerprint Apple Mac OS 9.2<br />
          cpe:/o:apple:mac_os:9.2<br />
          <br />
          Fingerprint Apple macOS 10.12 (Sierra) (Darwin 16.0.0 - 16.6.0)<br />
          cpe:/o:apple:mac_os_x:10.12 auto<br />
          <br />
          Fingerprint Apple macOS 10.12 (Sierra) (Darwin 16.1.0)<br />
          cpe:/o:apple:mac_os_x:10.12 auto<br />
          <br />
          Fingerprint Apple macOS 10.12 (Sierra) (Darwin 16.4.0)<br />
          cpe:/o:apple:mac_os_x:10.12 auto<br />
          <br />
          Fingerprint Apple macOS 10.12 (Sierra) (Darwin 16.6.0)<br />
          cpe:/o:apple:mac_os_x:10.12 auto<br />
          <br />
          Fingerprint Apple macOS 10.12.1 (Sierra) (Darwin 16.1.0)<br />
          cpe:/o:apple:mac_os_x:10.12 auto<br />
          <br />
          Fingerprint Apple macOS 10.12.5 (Sierra)<br />
          cpe:/o:apple:mac_os_x:10.12.5 auto<br />
          <br />
          Fingerprint Apple macOS 10.13 (High Sierra) (Darwin 17.0.0)<br />
          cpe:/o:apple:mac_os_x:10.13 auto<br />
          <br />
          Fingerprint Apple AirPort Express WAP (NetBSD)<br />
          cpe:/h:apple:airport_express<br />
          cpe:/o:apple:netbsd:4 auto<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP (NetBSD 4.99)<br />
          cpe:/h:apple:airport_extreme<br />
          cpe:/o:apple:netbsd:4.99 auto<br />
          <br />
          Fingerprint Apple Time Capsule NAS device (NetBSD 4.99)<br />
          cpe:/o:apple:netbsd:4.99 auto<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP or Time Capsule NAS device
          (NetBSD 4.99), or QNX RTOS 6.5.0<br />
          cpe:/h:apple:airport_extreme<br />
          cpe:/o:apple:netbsd:4.99 auto<br />
          cpe:/o:apple:netbsd:4.99 auto<br />
          cpe:/o:qnx:rtos:6.5.0 auto<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP (version 7.7.3)<br />
          cpe:/o:apple:netbsd auto<br />
          <br />
          Fingerprint Apple AirPort Extreme WAP (version 7.7.3) or NetBSD
          7.99<br />
          cpe:/o:apple:netbsd auto<br />
          cpe:/o:netbsd:netbsd:7.99 auto<br />
          <br />
          Fingerprint Apple OS X 10.10 (Yosemite) (Darwin 14.0.0)<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          <br />
          Fingerprint Apple OS X 10.10 (Yosemite) - 10.11 (El Capitan) (Darwin
          14.0.0 - 15.0.0)<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          <br />
          Fingerprint Apple OS X 10.10 (Yosemite) - 10.11 (El Capitan) (Darwin
          14.0.0 - 15.0.0)<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          <br />
          Fingerprint Apple OS X 10.10 (Yosemite) - 10.12 (Sierra) (Darwin
          14.0.0 - 16.1.0)<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          <br />
          Fingerprint Apple OS X 10.10.2 (Yosemite) (Darwin 14.1.0)<br />
          cpe:/o:apple:mac_os_x:10.10.2 auto<br />
          <br />
          Fingerprint Apple OS X 10.10.2 (Yosemite) - 10.11.1 (El Capitan) or
          iOS 9 (Darwin 14.1.0 - 15.0.0)<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          cpe:/o:apple:iphone_os:9 auto<br />
          <br />
          Fingerprint Apple OS X 10.10.3 (Yosemite) - 10.11.0 (El Capitan)
          (Darwin 14.3.0 - 15.0.0)<br />
          cpe:/o:apple:mac_os_x:10.10 auto<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          <br />
          Fingerprint Apple OS X 10.10.5 (Darwin 14.5.0)<br />
          cpe:/o:apple:mac_os_x:10.10.5 auto<br />
          <br />
          Fingerprint Apple OS X 10.11 (El Capitan) (Darwin 15.0.0)<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          <br />
          Fingerprint Apple OS X 10.11 (El Capitan) - 10.12 (Sierra) or iOS 10.1
          - 10.2 (Darwin 15.4.0 - 16.6.0)<br />
          cpe:/o:apple:mac_os_x:10.11 auto<br />
          cpe:/o:apple:mac_os_x:10.12 auto<br />
          cpe:/o:apple:iphone_os:10 auto<br />
          <br />
          Fingerprint Apple OS X 10.11.2 (El Capitan) (Darwin 15.2.0)<br />
          cpe:/o:apple:mac_os_x:10.11.2 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 (Mountain Lion)<br />
          cpe:/o:apple:mac_os_x:10.8 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 (Mountain Lion) (Darwin 12.4.0)<br />
          cpe:/o:apple:mac_os_x:10.8 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 (Mountain Lion) - 10.9 (Mavericks) (Darwin
          12.0.0 - 13.4.0) or iOS 5.0.1<br />
          cpe:/o:apple:mac_os_x:10.8<br />
          cpe:/o:apple:iphone_os:5.0.1 auto<br />
          cpe:/o:apple:iphone_os:5.0.1 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 (Mountain Lion) - 10.9 (Mavericks) or iOS
          5.1.1 - 6.1.5 (Darwin 12.0.0 - 13.4.0)<br />
          cpe:/o:apple:mac_os_x:10.8 auto<br />
          cpe:/o:apple:mac_os_x:10.9 auto<br />
          cpe:/o:apple:iphone_os:5 auto<br />
          cpe:/o:apple:iphone_os:5 auto<br />
          cpe:/o:apple:iphone_os:6 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 (Mountain Lion) - 10.9.5 (Mavericks)
          (Darwin 12.0.0 - 13.4.0)<br />
          cpe:/o:apple:mac_os_x:10.8<br />
          cpe:/o:apple:mac_os_x:10.9 auto<br />
          <br />
          Fingerprint Apple OS X 10.8 - 10.8.1 (Mountain Lion) (Darwin 12.0.0 -
          12.1.0)<br />
          cpe:/o:apple:mac_os_x:10.8<br />
          <br />
          Fingerprint Apple OS X 10.8.2 (Mountain Lion) (Darwin 12.2.0)<br />
          cpe:/o:apple:mac_os_x:10.8.2 auto<br />
          <br />
          Fingerprint Apple OS X 10.9 (Mavericks) (Darwin 13.0.0 - 13.0.2)<br />
          cpe:/o:apple:mac_os_x:10.9 auto<br />
          <br />
          Fingerprint Apple OS X 10.9 (Mavericks) (Darwin 13.0.0 - 13.2.0)<br />
          cpe:/o:apple:mac_os_x:10.9 auto<br />
          <br />
          Fingerprint Apple OS X 10.9 (Mavericks) (Darwin 13.0.0 - 13.2.0)<br />
          cpe:/o:apple:mac_os_x:10.9 auto<br />
          <br />
          Fingerprint Apple OS X 10.9 (Mavericks) (Darwin 13.0.0)<br />
          cpe:/o:apple:mac_os_x:10.9 auto<br />
          <br />
          Fingerprint Apple OS X 10.9.3 (Mavericks) (Darwin 13.2.0)<br />
          cpe:/o:apple:mac_os_x:10.9.3 auto<br />
          <br />
          Fingerprint Areca ARC-1212 or ARC-1222 RAID controller or Siemens
          HiPath 3500 PBX<br />
          cpe:/h:areca:arc-1212<br />
          cpe:/h:areca:arc-1222<br />
          cpe:/h:siemens:hipath_3500<br />
          <br />
          Fingerprint Argosy HD363N NAS device<br />
          cpe:/h:argosy:hd363n auto<br />
          <br />
          Fingerprint Arris cable modem (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Arris SB6183 cable modem<br />
          cpe:/h:arris:sb6183 auto<br />
          <br />
          Fingerprint Arris TM502G or TM602B cable modem<br />
          cpe:/h:arris:tm502b auto<br />
          cpe:/h:arris:tm602b auto<br />
          <br />
          Fingerprint Arris TG862 WAP<br />
          cpe:/h:arris:tg862 auto<br />
          <br />
          Fingerprint Aruba 200 wireless LAN controller (ArubaOS 3.3.2.5)<br />
          cpe:/o:arubanetworks:arubaos:3.3.2.5 auto<br />
          <br />
          Fingerprint Aruba 3400 or 6000 wireless LAN controller (ArubaOS
          3.3.2)<br />
          cpe:/o:arubanetworks:arubaos:3.3.2 auto<br />
          <br />
          Fingerprint Aruba A800 wireless LAN switch<br />
          cpe:/o:arubanetworks:arubaos:3 auto<br />
          <br />
          Fingerprint Aruba 100-series access point (ArubaOS 6.4.0.2)<br />
          cpe:/o:arubanetworks:arubaos:6.4.0.2 auto<br />
          <br />
          Fingerprint Aruba Instant AP (ArubaOS 6.4.2.6)<br />
          cpe:/o:arubanetworks:arubaos:6.4.2.6 auto<br />
          <br />
          Fingerprint ArubaOS 6.3<br />
          cpe:/o:arubanetworks:arubaos:6.3 auto<br />
          <br />
          Fingerprint ArubaOS 6.4.2.6<br />
          cpe:/o:arubanetworks:arubaos:6.4.2.6 auto<br />
          <br />
          Fingerprint ArubaOS 6.4.4.8<br />
          cpe:/o:arubanetworks:arubaos:6.4.4.8 auto<br />
          <br />
          Fingerprint Aruba IAP-105 WAP<br />
          cpe:/h:arubanetworks:iap-105 auto<br />
          <br />
          Fingerprint Aruba IAP-93 WAP<br />
          cpe:/h:arubanetworks:iap-93 auto<br />
          <br />
          Fingerprint Asus RT-AC66U router (Linux 2.6)<br />
          cpe:/h:asus:rt-ac66u auto<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Asus O!Play media player (Linux 2.6.12)<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          <br />
          Fingerprint Asus Zenfone 5 (Android 4.4.2)<br />
          cpe:/o:google:android:4.4.2 auto<br />
          <br />
          Fingerprint Asus RX4031 or TRENDnet TW100-S4W1CA router<br />
          cpe:/h:asus:rx4031<br />
          cpe:/h:trendnet:tw100-s4w1ca auto<br />
          <br />
          Fingerprint Asus 4G-N12 WAP<br />
          cpe:/h:asus:4g-n12 auto<br />
          <br />
          Fingerprint Asus RT-53N WAP<br />
          cpe:/h:asus:rt-53n auto<br />
          <br />
          Fingerprint ASUS RT-N11 EZ or D-Link DI-524 WAP, or LevelOne
          FBR-1415TX router<br />
          cpe:/h:asus:rt-n11_ez<br />
          cpe:/h:dlink:di-524 auto<br />
          cpe:/h:levelone:fbr-1415tx<br />
          <br />
          Fingerprint ASUS RT-N56U WAP (Linux 3.4)<br />
          cpe:/h:asus:rt-n56u auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Asus RT-N66U WAP (Linux 2.6)<br />
          cpe:/h:asus:rt-n66u auto<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Asus WL-500g WAP<br />
          cpe:/h:asus:wl-500g auto<br />
          <br />
          Fingerprint Asus WL-500gP wireless broadband router<br />
          cpe:/h:asus:wl-500gp auto<br />
          <br />
          Fingerprint AT&amp;T NetGate SG8100 router<br />
          cpe:/h:att:netgate_sg8100 auto<br />
          <br />
          Fingerprint Atari Unix System V Release 4.0<br />
          cpe:/o:atari:unix:svr4 auto<br />
          <br />
          Fingerprint Atcom AT-320 VoIP phone<br />
          cpe:/h:atcom:at-320 auto<br />
          <br />
          Fingerprint AudioCodes MediaPack 118 or Mediant 1000 VoIP adapter<br />
          cpe:/h:audiocodes:mediapack_118<br />
          cpe:/h:audiocodes:mediant_1000<br />
          <br />
          Fingerprint Audiocodes MP-1XX-series VoIP adapter or Brother HL-2270DW
          printer<br />
          cpe:/h:brother:hl-2270dw auto<br />
          <br />
          Fingerprint AudioControl D3400 network amplifier<br />
          cpe:/h:audiocontrol:d3400<br />
          <br />
          Fingerprint Avaya 4526GTX switch<br />
          cpe:/h:avaya:4526gtx auto<br />
          <br />
          Fingerprint Avaya P580 switch running Cajun Switch Agent v5.4.2<br />
          cpe:/h:avaya:p580 auto<br />
          <br />
          Fingerprint Avaya P880 switch running Cajun Switch Agent v5.3.2<br />
          cpe:/h:avaya:p880 auto<br />
          <br />
          Fingerprint Avaya Office IP403 VoIP adapter<br />
          cpe:/h:avaya:office_ip403 auto<br />
          <br />
          Fingerprint Avaya Office IP500 VoIP adapter<br />
          cpe:/h:avaya:office_ip500 auto<br />
          <br />
          Fingerprint Avaya Office IP500 VoIP adapter<br />
          cpe:/h:avaya:office_ip500 auto<br />
          <br />
          Fingerprint AVM FRITZ!Box Fon 7170 ADSL modem<br />
          cpe:/h:avm:fritz%21box_fon_7170<br />
          <br />
          Fingerprint AVM FRITZ!Box FON WLAN 7050, Linksys WAG200G, or Netgear
          DG834GT wireless broadband router<br />
          cpe:/h:avm:fritz%21box_fon_wlan_7050<br />
          cpe:/h:linksys:wag200g<br />
          cpe:/h:netgear:dg834gt<br />
          <br />
          Fingerprint AVM FRITZ!Box FON WLAN 7170 WAP<br />
          cpe:/h:avm:fritz%21box_fon_wlan_7170<br />
          <br />
          Fingerprint AVM FRITZ!Box FON WLAN 7240 WAP<br />
          cpe:/h:avm:fritz%21box_fon_wlan_7240<br />
          <br />
          Fingerprint AVM FRITZ!Box (FritzOS 6.20)<br />
          cpe:/o:avm:fritzos:6.20 auto<br />
          <br />
          Fingerprint AVM FRITZ!Box (FritzOS 6.03)<br />
          cpe:/o:avm:fritzos:6.03 auto<br />
          <br />
          Fingerprint AVM FRITZ!Box FON WLAN 7390 (FritzOS 6.01)<br />
          cpe:/o:avm:fritzos:6.01 auto<br />
          <br />
          Fingerprint AVM FRITZ!WLAN Repeater 450E (FritzOS 6.51)<br />
          cpe:/o:avm:fritzos:6.51 auto<br />
          <br />
          Fingerprint Avocent AutoView, DSR2020, or DSR8020 KVM switch<br />
          cpe:/h:avocent:autoview_3100<br />
          cpe:/h:avocent:autoview_3200<br />
          cpe:/h:avocent:dsr2020<br />
          cpe:/h:avocent:dsr8020<br />
          <br />
          Fingerprint Avocent DSR2035 KVM switch<br />
          cpe:/h:avocent:dsr2035<br />
          <br />
          Fingerprint Avocent ACS5016 console server (Linux 2.6.22)<br />
          cpe:/h:avocent:acs5016<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint AXIS 5550 print server<br />
          cpe:/h:axis:5550 auto<br />
          <br />
          Fingerprint AXIS 205 Network Camera, Buffalo TeraStation NAS device,
          Linksys WAP54G WAP, or Sony SNC-RZ50N network camera<br />
          cpe:/h:axis:205_network_camera<br />
          cpe:/h:buffalo:terastation<br />
          cpe:/h:linksys:wap54g<br />
          cpe:/h:sony:snc-rz50n<br />
          <br />
          Fingerprint AXIS 206 Network Camera<br />
          cpe:/h:axis:206_network_camera<br />
          <br />
          Fingerprint AXIS 207W Network Camera<br />
          cpe:/h:axis:207w_network_camera<br />
          <br />
          Fingerprint AXIS 2100 Network Camera<br />
          cpe:/h:axis:2100_network_camera<br />
          <br />
          Fingerprint AXIS 2100 Network Camera<br />
          cpe:/h:axis:2100_network_camera<br />
          <br />
          Fingerprint AXIS 2120 Network Camera<br />
          cpe:/h:axis:2120_network_camera<br />
          <br />
          Fingerprint Barrelfish before release2011-09-02<br />
          cpe:/o:barrelfish:barrelfish auto<br />
          <br />
          Fingerprint Bay Networks Annex Ethernet-to-serial bridge or Xerox
          DocuPrint N32 printer<br />
          cpe:/h:xerox:docuprint_n32<br />
          <br />
          Fingerprint Bay Networks BayStack 450 switch (software version
          3.1.0.22)<br />
          cpe:/h:baynetworks:baystack_450 auto<br />
          <br />
          Fingerprint Bay Networks BayStack 450 switch (software version
          4.2.0.16)<br />
          cpe:/h:baynetworks:baystack_450 auto<br />
          <br />
          Fingerprint Belkin F5D7230-4 WAP<br />
          cpe:/h:belkin:f5d7230-4 auto<br />
          <br />
          Fingerprint Belkin F5D7230-4 WAP or SMC SMC7008ABR router<br />
          cpe:/h:belkin:f5d7230-4 auto<br />
          cpe:/h:smc:smc7008abr auto<br />
          <br />
          Fingerprint Belkin F5D8233 WAP<br />
          cpe:/h:belkin:f5d8233 auto<br />
          <br />
          Fingerprint Belkin F7D2301 WAP or Philips CIA6720NB ADSL modem<br />
          cpe:/h:belkin:f7d2301 auto<br />
          cpe:/h:philips:cia6720nb auto<br />
          <br />
          Fingerprint Belkin N300 WAP (Linux 2.6.30)<br />
          cpe:/h:belkin:n300 auto<br />
          cpe:/o:linux:linux_kernel:2.6.30 auto<br />
          <br />
          Fingerprint Belkin N600 DB WAP<br />
          cpe:/h:belkin:n600_db<br />
          <br />
          Fingerprint Bell Labs Plan 9 4th edition<br />
          cpe:/o:belllabs:plan_9:4th_edition auto<br />
          <br />
          Fingerprint Bell Labs Plan 9 edition<br />
          cpe:/o:belllabs:plan_9 auto<br />
          <br />
          Fingerprint Billion 7402VGP DSL router<br />
          cpe:/h:billion:7402vgp auto<br />
          <br />
          Fingerprint Billion 7404VGO-M or Zoom X6 DSL router<br />
          cpe:/h:billion:7474vgo-m<br />
          cpe:/h:zoom:x6 auto<br />
          <br />
          Fingerprint Billion 7404VGP-M ADSL router<br />
          cpe:/h:billion:7404vgp-m auto<br />
          <br />
          Fingerprint Billion WRT610n WAP<br />
          cpe:/h:billion:wrt610n auto<br />
          <br />
          Fingerprint BinTec RS232bw ADSL modem<br />
          cpe:/h:bintec:rs232bw auto<br />
          <br />
          Fingerprint BinTec R3400 WAP<br />
          cpe:/h:bintec:r3400 auto<br />
          <br />
          Fingerprint BinTec R1200 WAP<br />
          cpe:/h:bintec:r1200 auto<br />
          <br />
          Fingerprint BlackBoard AT3000 POS device<br />
          cpe:/h:blackboard:at3000<br />
          <br />
          Fingerprint BlackBox Coalesce wireless collaborations system (Android
          5.1)<br />
          cpe:/o:google:android:5.1 auto<br />
          <br />
          Fingerprint Blue Coat PacketShaper appliance<br />
          cpe:/h:bluecoat:packetshaper<br />
          <br />
          Fingerprint Blue Coat proxy server (SGOS 3.2.4.8)<br />
          cpe:/o:bluecoat:sgos:3.2.4.8 auto<br />
          <br />
          Fingerprint Blue Coat proxy server (SGOS 4.1 - 4.2)<br />
          cpe:/o:bluecoat:sgos:4 auto<br />
          <br />
          Fingerprint Blue Coat SG200 proxy server (SGOS 4.2.2.8 - 4.2.6.1)<br />
          cpe:/o:bluecoat:sgos:4 auto<br />
          <br />
          Fingerprint Blue Coat SG810 proxy server (SGOS 4.2.3.26)<br />
          cpe:/o:bluecoat:sgos:4.2.3.26 auto<br />
          <br />
          Fingerprint Blue Coat SGOS 4.2.2.2<br />
          cpe:/o:bluecoat:sgos:4.2.2.2 auto<br />
          <br />
          Fingerprint Blue Coat SG200 proxy server (SGOS 5.1.4.4)<br />
          cpe:/o:bluecoat:sgos:5.1.4.4 auto<br />
          <br />
          Fingerprint Blue Coat SG210 proxy server (SGOS 5.2.3.3 - 5.2.3.9)<br />
          cpe:/o:bluecoat:sgos:5 auto<br />
          <br />
          Fingerprint Blue Coat SG510 or SG9000 proxy server (SGOS 5.2.2.5 -
          5.5.4.1)<br />
          cpe:/o:bluecoat:sgos:5 auto<br />
          <br />
          Fingerprint Blue Coat SG510-series proxy server (SGOS 5.1.3.7)<br />
          cpe:/o:bluecoat:sgos:5.1.3.7 auto<br />
          <br />
          Fingerprint Blue Coat SG810 web proxy (SGOS 5.3.1.9 - 5.3.3.1)<br />
          cpe:/o:bluecoat:sgos:5 auto<br />
          <br />
          Fingerprint Blue Coat SGOS 5.5.1.1<br />
          cpe:/o:bluecoat:sgos:5.5.1.1 auto<br />
          <br />
          Fingerprint Blue Coat SGOS 5.5.3.1<br />
          cpe:/o:bluecoat:sgos:5.5.3.1 auto<br />
          <br />
          Fingerprint Blue Coat proxy server (SGOS 6.2.5.1 - 6.2.8.1)<br />
          cpe:/o:bluecoat:sgos:6 auto<br />
          <br />
          Fingerprint Blue Coat proxy server (SGOS 6.3.2.201)<br />
          cpe:/o:bluecoat:sgos:6.3.2.201 auto<br />
          <br />
          Fingerprint Blue Coat proxy server (SGOS 6.5.3.2)<br />
          cpe:/o:bluecoat:sgos:6.5.3.2 auto<br />
          <br />
          Fingerprint Blue Coat ProxySG proxy server (SGOS 6.4)<br />
          cpe:/o:bluecoat:sgos:6.4 auto<br />
          <br />
          Fingerprint BlueArc Titan 2100 NAS device<br />
          cpe:/h:bluearc:titan_2100 auto<br />
          <br />
          Fingerprint Bluebird SuperDOS<br />
          cpe:/o:bluebird:superdos auto<br />
          <br />
          Fingerprint Bluebird SuperDOS<br />
          cpe:/o:bluebird:superdos auto<br />
          <br />
          Fingerprint Bose SoundTouch 20 audio receiver<br />
          cpe:/h:bose:soundtouch_20<br />
          <br />
          Fingerprint Audio receiver: Bose Soundtouch 20, Bowers &amp; Wilkins
          Zeppelin Air, Denon AVR-1900-series, Marantz NR1602, or Pioneer
          VSX-921<br />
          cpe:/h:bowers_%26_wilkins:zeppelin_air<br />
          cpe:/h:denon:avr-1912<br />
          cpe:/h:denon:avr-1920<br />
          cpe:/h:marantz:nr1602<br />
          cpe:/h:pioneer:vsx-921<br />
          <br />
          Fingerprint Brocade ICX 6610 switch<br />
          cpe:/h:brocade:icx_6610 auto<br />
          <br />
          Fingerprint Brocade TurboIron 24X or ICX6550 switch<br />
          cpe:/h:brocade:turboiron_24x<br />
          <br />
          Fingerprint Brocade Fabric OS 4.4.0 (Linux 2.4.19)<br />
          cpe:/o:brocade:fabric_os:4.4.0 auto<br />
          cpe:/o:linux:linux_kernel:2.4.19 auto<br />
          <br />
          Fingerprint Brocade Fabric OS 7.4.1 (Linux 2.6.14)<br />
          cpe:/o:brocade:fabric_os:7.4.1 auto<br />
          cpe:/o:linux:linux_kernel:2.6.14 auto<br />
          <br />
          Fingerprint Brother NC-130h print server<br />
          cpe:/h:brother:nc-130h auto<br />
          <br />
          Fingerprint Brother NC-2100p print server<br />
          cpe:/h:brother:nc-2100p auto<br />
          <br />
          Fingerprint Brother NC-3100h print server<br />
          cpe:/h:brother:nc-3100h auto<br />
          <br />
          Fingerprint Brother NC-3100h print server<br />
          cpe:/h:brother:nc-3100h auto<br />
          <br />
          Fingerprint Brother DCP-540CN printer<br />
          cpe:/h:brother:dcp-540cn auto<br />
          <br />
          Fingerprint Brother DCP-540CN printer<br />
          cpe:/h:brother:dcp-540cn auto<br />
          <br />
          Fingerprint Brother DCP-7065DN printer<br />
          cpe:/h:brother:dcp-7065dn auto<br />
          <br />
          Fingerprint Brother DCP-7070DW printer<br />
          cpe:/h:brother:dcp-7070dw auto<br />
          <br />
          Fingerprint Brother DCP-8045D printer<br />
          cpe:/h:brother:dcp-8045d auto<br />
          <br />
          Fingerprint Brother DCP-8065D printer<br />
          cpe:/h:brother:dcp-8065d<br />
          <br />
          Fingerprint Brother DCP-L2540DN printer<br />
          cpe:/h:brother:dcp-l2540dn auto<br />
          <br />
          Fingerprint Brother HL-1270N printer<br />
          cpe:/h:brother:hl-1270n auto<br />
          <br />
          Fingerprint Brother HL-1870N printer<br />
          cpe:/h:brother:hl-1870n auto<br />
          <br />
          Fingerprint Brother HL-2070N or MFC-5460CN printer<br />
          cpe:/h:brother:hl-2070n<br />
          cpe:/h:brother:mfc-5460cn<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2070N printer<br />
          cpe:/h:brother:hl-2070n auto<br />
          <br />
          Fingerprint Brother HL-2250DN printer<br />
          cpe:/h:brother:hl-2250dn<br />
          <br />
          Fingerprint Brother HL-2270DW printer<br />
          cpe:/h:brother:hl-2270dw auto<br />
          <br />
          Fingerprint Brother HL-2270DW printer<br />
          cpe:/h:brother:hl-2270dw auto<br />
          <br />
          Fingerprint Brother HL-2700CN printer<br />
          cpe:/h:brother:hl-2700cn auto<br />
          <br />
          Fingerprint Brother HL-3170CDW printer<br />
          cpe:/h:brother:hl-3170cdw auto<br />
          <br />
          Fingerprint Brother HL-495CW printer<br />
          cpe:/h:brother:hl-495cw auto<br />
          <br />
          Fingerprint Brother HL-5070N printer<br />
          cpe:/h:brother:hl-5070n auto<br />
          <br />
          Fingerprint Brother HL-5070N printer<br />
          cpe:/h:brother:hl-5070n auto<br />
          <br />
          Fingerprint Brother HL-5170DN printer<br />
          cpe:/h:brother:hl-5170dn auto<br />
          <br />
          Fingerprint Brother HL-5170DN printer<br />
          cpe:/h:brother:hl-5170dn auto<br />
          <br />
          Fingerprint Brother HL-5170DN printer<br />
          cpe:/h:brother:hl-5170dn auto<br />
          <br />
          Fingerprint Brother HL-5170DN printer<br />
          cpe:/h:brother:hl-5170dn auto<br />
          <br />
          Fingerprint Brother HL-5170DN printer<br />
          cpe:/h:brother:hl-5170dn auto<br />
          <br />
          Fingerprint Brother HL-5250DN or DCP-8065D printer<br />
          cpe:/h:brother:hl-5250dn<br />
          cpe:/h:brother:dcp-8065d<br />
          <br />
          Fingerprint Brother HL-5250DN printer<br />
          cpe:/h:brother:hl-5250dn auto<br />
          <br />
          Fingerprint Brother HL-5250DN printer<br />
          cpe:/h:brother:hl-5250dn auto<br />
          <br />
          Fingerprint Brother HL-5380dn printer<br />
          cpe:/h:brother:hl-5380dn auto<br />
          <br />
          Fingerprint Brother MFC-420CN printer<br />
          cpe:/h:brother:mfc-420cn auto<br />
          <br />
          Fingerprint Brother MFC-420CN printer<br />
          cpe:/h:brother:mfc-420cn auto<br />
          <br />
          Fingerprint Brother MFC-425CN printer<br />
          cpe:/h:brother:mfc-425cn auto<br />
          <br />
          Fingerprint Brother MFC-5840CN printer<br />
          cpe:/h:brother:mfc-5840cn auto<br />
          <br />
          Fingerprint Brother MFC-5860CN printer<br />
          cpe:/h:brother:mfc-5860cn auto<br />
          <br />
          Fingerprint Brother MFC-640CW printer<br />
          cpe:/h:brother:mfc-640cw auto<br />
          <br />
          Fingerprint Brother MFC-7820N or MFC-9420CN printer<br />
          cpe:/h:brother:mfc-7820n<br />
          cpe:/h:brother:mfc-9420n<br />
          <br />
          Fingerprint Brother MFC-7820N printer<br />
          cpe:/h:brother:mfc-7820n auto<br />
          <br />
          Fingerprint Brother MFC-7820N printer<br />
          cpe:/h:brother:mfc-7820n auto<br />
          <br />
          Fingerprint Brother MFC-7820N printer<br />
          cpe:/h:brother:mfc-7820n auto<br />
          <br />
          Fingerprint Brother MFC-7820N printer<br />
          cpe:/h:brother:mfc-7820n auto<br />
          <br />
          Fingerprint Brother MFC-7820N printer<br />
          cpe:/h:brother:mfc-7820n auto<br />
          <br />
          Fingerprint Brother MFC-7820N printer<br />
          cpe:/h:brother:mfc-7820n auto<br />
          <br />
          Fingerprint Brother MFC-8480DN printer<br />
          cpe:/h:brother:mfc-8480dn auto<br />
          <br />
          Fingerprint Brother MFC-9420CN printer<br />
          cpe:/h:brother:mfc-9420cn auto<br />
          <br />
          Fingerprint Brother MFC-9970CDW printer<br />
          cpe:/h:brother:mfc-9970cdw auto<br />
          <br />
          Fingerprint Brother MFC-J415W printer<br />
          cpe:/h:brother:mfc-j415w auto<br />
          <br />
          Fingerprint Brother MFC-J615W, MFC-9325CW, or HL-5370DW printer<br />
          cpe:/h:brother:mfc-j615w<br />
          cpe:/h:brother:mfc-9325cw<br />
          cpe:/h:brother:hl-5370dw<br />
          <br />
          Fingerprint Brother MFC-J625DW printer<br />
          cpe:/h:brother:mfc-j625dw auto<br />
          <br />
          Fingerprint Brother MFC-J6510DW printer<br />
          cpe:/h:brother:mfc-j6510dw auto<br />
          <br />
          Fingerprint 2.11BSD<br />
          cpe:/o:bsd:bsd:2.11<br />
          <br />
          Fingerprint 4.1C BSD<br />
          cpe:/o:bsd:bsd:4.1 auto<br />
          <br />
          Fingerprint 4.3BSD<br />
          cpe:/o:bsd:bsd:4.3<br />
          <br />
          Fingerprint BSDI BSD/OS 4.2<br />
          cpe:/o:bsdi:bsd_os:4.2 auto<br />
          <br />
          Fingerprint Buffalo LinkStation Quad LS-QL<br />
          cpe:/h:buffalo:ls-ql<br />
          <br />
          Fingerprint Buffalo LS-WXL NAS device<br />
          cpe:/h:buffalo:ls-wxl<br />
          <br />
          Fingerprint Cabletron ELS100-24TXM switch<br />
          cpe:/h:cabletron:els100-24txm auto<br />
          <br />
          Fingerprint Cabletron ELS100-24TXM Switch or Icom IC-7800 radio
          transceiver<br />
          cpe:/h:cabletron:els100-24txm auto<br />
          cpe:/h:icom:ic-7800<br />
          <br />
          Fingerprint Caldera Open Unix 7.1.0<br />
          cpe:/o:caldera:open_unix:7.1.0 auto<br />
          <br />
          Fingerprint Calix C7 switch (R5.0)<br />
          cpe:/h:calix:c7 auto<br />
          <br />
          Fingerprint Linksys WAP11 WAP; or Cameo SOHO-IS104A or SMC SMCWBR14-G
          or SMC2804WBR broadband router<br />
          cpe:/h:cameo:soho-is104a<br />
          cpe:/h:linksys:wap11 auto<br />
          cpe:/h:smc:smcwbr14-g<br />
          cpe:/h:smc:smc2804wbr<br />
          <br />
          Fingerprint Canon CLC4040 printer<br />
          cpe:/h:canon:clc4040 auto<br />
          <br />
          Fingerprint Canon imageCLASS D1320 printer<br />
          cpe:/h:canon:imageclass_d1320 auto<br />
          <br />
          Fingerprint Canon imageCLASS LBP6300dn printer<br />
          cpe:/h:canon:imageclass_lbp6300dn auto<br />
          <br />
          Fingerprint Canon imageCLASS LBP6300dn printer<br />
          cpe:/h:canon:imageclass_lbp6300dn auto<br />
          <br />
          Fingerprint Canon imageCLASS MF212w printer<br />
          cpe:/h:canon:imageclass_mf212w auto<br />
          <br />
          Fingerprint Canon imageRUNNER 1024 laser printer<br />
          cpe:/h:canon:imagerunner_1024<br />
          <br />
          Fingerprint Canon imageRUNNER 2020 or Kyocera FS-4000DN printer<br />
          cpe:/h:canon:imagerunner_2020<br />
          cpe:/h:kyocera:fs-4000dn<br />
          <br />
          Fingerprint Canon imageRUNNER 2270, 4570, or 6570 laser printer
          (VxWorks)<br />
          cpe:/h:canon:imagerunner_2270<br />
          cpe:/h:canon:imagerunner_4570<br />
          cpe:/h:canon:imagerunner_6570<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Canon imageRUNNER 2520 printer<br />
          cpe:/h:canon:imagerunner_2520 auto<br />
          <br />
          Fingerprint Canon imageRUNNER 2525 printer (VxWorks)<br />
          cpe:/h:canon:imagerunner_2525<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Canon imageRUNNER 3300 printer or Motorola SURFboard
          SBG900 wireless cable modem<br />
          cpe:/h:canon:imagerunner_3300<br />
          cpe:/h:motorola:surfboard_sbg900 auto<br />
          <br />
          Fingerprint Canon imageRUNNER ADVANCE 4235, 6275, 6255, or C2220
          copier<br />
          cpe:/h:canon:imagerunner_advance_4235<br />
          cpe:/h:canon:imagerunner_advance_6275<br />
          cpe:/h:canon:imagerunner_advance_6255<br />
          cpe:/h:canon:imagerunner_advance_c2220<br />
          <br />
          Fingerprint Canon imageRUNNER ADVANCE C3320i or C3325 copier<br />
          cpe:/h:canon:imagerunner_advance_c3320i<br />
          cpe:/h:canon:imagerunner_advance_c3325<br />
          <br />
          Fingerprint Canon imageRUNNER ADVANCE C5051 printer<br />
          cpe:/h:canon:imagerunner_advance_c5051 auto<br />
          <br />
          Fingerprint Canon imageRUNNER C2380 or C2880i or Xerox Phaser 8860MFP
          printer<br />
          cpe:/h:canon:imagerunner_c2380<br />
          cpe:/h:canon:imagerunner_c2880i<br />
          cpe:/h:xerox:phaser_8860mfp<br />
          <br />
          Fingerprint Canon imageRUNNER C3100 or C6800 printer<br />
          cpe:/h:canon:imagerunner_c3100<br />
          cpe:/h:canon:imagerunner_c6800<br />
          <br />
          Fingerprint Canon imageRUNNER C3200 printer or Polycom SoundStation IP
          4000 VoIP phone<br />
          cpe:/h:canon:imagerunner_c3200<br />
          cpe:/h:polycom:soundstation_ip_4000 auto<br />
          <br />
          Fingerprint Canon imageRUNNER C3580 printer<br />
          cpe:/h:canon:imagerunner_c3580<br />
          <br />
          Fingerprint Canon imageRUNNER C5185 printer<br />
          cpe:/h:canon:imagerunner_c5185<br />
          <br />
          Fingerprint Canon PIXMA MG2920 printer<br />
          cpe:/h:canon:pixma_mg2920 auto<br />
          <br />
          Fingerprint Canon PIXMA MG5200 printer<br />
          cpe:/h:canon:pixma_mg5200 auto<br />
          <br />
          Fingerprint Canon PIXMA MP495 or MG6150 printer<br />
          cpe:/h:canon:pixma_mp495 auto<br />
          cpe:/h:canon:pixma_mg6150 auto<br />
          <br />
          Fingerprint Canon PIXMA MX340, MP495, MP620, MG3100, MG6200, MX860,
          MX870, or MX890 printer<br />
          cpe:/h:canon:pixma_mx340<br />
          cpe:/h:canon:pixma_mp495<br />
          cpe:/h:canon:pixma_mp620<br />
          cpe:/h:canon:pixma_mg3100<br />
          cpe:/h:canon:pixma_mg6200<br />
          cpe:/h:canon:pixma_mx860<br />
          cpe:/h:canon:pixma_mx870<br />
          cpe:/h:canon:pixma_mx890<br />
          <br />
          Fingerprint Canon PIXMA MX850 printer<br />
          cpe:/h:canon:pixma_mx850 auto<br />
          <br />
          Fingerprint Canon 80D camera<br />
          cpe:/h:canon:80d<br />
          <br />
          Fingerprint Canon VB-C300 IP camera<br />
          cpe:/h:canon:vb-c300<br />
          <br />
          Fingerprint Cayman Systems GatorBox CS<br />
          cpe:/h:cayman_systems:gatorbox_cs<br />
          <br />
          Fingerprint Check Point SBox-200 firewall<br />
          cpe:/h:checkpoint:sbox-200 auto<br />
          <br />
          Fingerprint Check Point ZoneAlarm Z100G firewall<br />
          cpe:/h:checkpoint:zonealarm_z100g auto<br />
          <br />
          Fingerprint Check Point NGX R65 firewall (IPSO 4.2)<br />
          cpe:/o:checkpoint:ipso:4.2 auto<br />
          <br />
          Fingerprint Check Point VPN-1 firewall (IPSO 4.1)<br />
          cpe:/o:checkpoint:ipso:4.1 auto<br />
          <br />
          Fingerprint Cisco Content Engine CE590 running Application and Content
          Networking System Software 5.5.5<br />
          cpe:/o:cisco:acns:5 auto<br />
          <br />
          Fingerprint Cisco WAE-512 Wide Area Application Engine (ACNS
          5.5.5.4)<br />
          cpe:/o:cisco:acns:5.5.5.4 auto<br />
          <br />
          Fingerprint Cisco AireOS 8.0.140.0<br />
          cpe:/o:cisco:aireos:8.0.140.0 auto<br />
          <br />
          Fingerprint Cisco AireOS 8.2 (Linux 2.6.21)<br />
          cpe:/o:cisco:aireos:8.2 auto<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          <br />
          Fingerprint Cisco 2500- or 5500-series Wireless Controller (AireOS 7 -
          8)<br />
          cpe:/o:cisco:aireos auto<br />
          <br />
          Fingerprint Cisco 2500-series Wireless LAN Controller (AireOS)<br />
          cpe:/o:cisco:aireos auto<br />
          <br />
          Fingerprint Cisco Adaptive Security Appliance (ASA 7.2)<br />
          cpe:/a:cisco:adaptive_security_appliance_software:7.2 auto<br />
          <br />
          Fingerprint Cisco Adaptive Security Appliance (ASA 8.4)<br />
          cpe:/a:cisco:adaptive_security_appliance_software:8.4 auto<br />
          <br />
          Fingerprint Cisco Adaptive Security Appliance 5510 or 5540 firewall
          (ASA 8.0)<br />
          cpe:/a:cisco:adaptive_security_appliance_software:8.0 auto<br />
          <br />
          Fingerprint Cisco Adaptive Security Appliance (ASA 9.2)<br />
          cpe:/a:cisco:adaptive_security_appliance_software:9.2 auto<br />
          <br />
          Fingerprint Cisco IronPort C160 or C170 email security appliance
          (AsyncOS 7)<br />
          cpe:/h:cisco:ironport_c170<br />
          cpe:/o:cisco:asyncos:7<br />
          <br />
          Fingerprint Cisco IronPort C650 email security appliance (AsyncOS
          7.0.1)<br />
          cpe:/h:cisco:ironport_c650<br />
          cpe:/o:cisco:asyncos:7.0.1<br />
          <br />
          Fingerprint Cisco C370 Email Security Appliance (AsyncOS 8.0.1)<br />
          cpe:/o:cisco:asyncos:8.0.1 auto<br />
          <br />
          Fingerprint Cisco M670 Content Security Management appliance (AsyncOS
          8.1.1)<br />
          cpe:/o:cisco:asyncos:8.1.1 auto<br />
          <br />
          Fingerprint Cisco AsyncOS 9.6 - 9.7<br />
          cpe:/o:cisco:asyncos:9 auto<br />
          <br />
          Fingerprint Cisco Catalyst WS-C5000 switch (CatOS 4.5(1))<br />
          cpe:/h:cisco:catalyst_ws-c5000<br />
          cpe:/o:cisco:catos:4.5 auto<br />
          <br />
          Fingerprint Cisco 2948G switch (CatOS 6.3)<br />
          cpe:/h:cisco:catalyst_2948g<br />
          cpe:/o:cisco:catos:6.3<br />
          <br />
          Fingerprint Cisco Catalyst 2820 switch (CatOS 5.37)<br />
          cpe:/h:cisco:catalyst_2820<br />
          cpe:/o:cisco:catos:5.37<br />
          <br />
          Fingerprint Cisco Catalyst 4912 (CatOS 6.4)<br />
          cpe:/o:cisco:catos:6.4 auto<br />
          <br />
          Fingerprint Cisco Catalyst WS-C6509 switch (CatOS 6.3)<br />
          cpe:/h:cisco:catalyst_ws-c6509<br />
          cpe:/o:cisco:catos:6.3<br />
          <br />
          Fingerprint Cisco Catalyst 2948G, 4003, 4006, or 6509 switch (CatOS
          7.6(17) - 8.4(3))<br />
          cpe:/h:cisco:catalyst_4003<br />
          cpe:/h:cisco:catalyst_4006<br />
          cpe:/o:cisco:catos:7 auto<br />
          cpe:/h:cisco:catalyst_2948g<br />
          cpe:/h:cisco:catalyst_6509<br />
          cpe:/o:cisco:catos:8 auto<br />
          <br />
          Fingerprint Cisco Catalyst WS-C6506 switch (CatOS 7.6(16))<br />
          cpe:/h:cisco:catalyst_ws-c6506<br />
          cpe:/o:cisco:catos:7.6 auto<br />
          <br />
          Fingerprint Cisco Catalyst switch (CatOS 8.3(2))<br />
          cpe:/o:cisco:catos:8.3 auto<br />
          <br />
          Fingerprint Cisco Catalyst WS-C4506 switch (CatOS 8.1(1))<br />
          cpe:/h:cisco:catalyst_ws-c4506<br />
          cpe:/o:cisco:catos:8.1 auto<br />
          <br />
          Fingerprint Cisco 827H ADSL router (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 827H ADSL router (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco EPC3208 cable modem<br />
          cpe:/h:cisco:epc3208 auto<br />
          <br />
          Fingerprint Cisco EPC3208 cable modem<br />
          cpe:/h:cisco:epc3208 auto<br />
          <br />
          Fingerprint Cisco EPC3208 or EPC3212 cable modem<br />
          cpe:/h:cisco:epc3212<br />
          cpe:/h:cisco:epc3208<br />
          <br />
          Fingerprint Cisco EPC3212 or Scientific Atlanta WebSTAR EPX2203 cable
          modem<br />
          cpe:/h:cisco:epc3212<br />
          cpe:/h:sciatl:webstar_epx2203<br />
          <br />
          Fingerprint Cisco EPC3925 cable modem<br />
          cpe:/h:cisco:epc3925<br />
          <br />
          Fingerprint Cisco IPS 4270 intrusion prevention system<br />
          cpe:/h:cisco:ips_4270<br />
          <br />
          Fingerprint Cisco MARS 50 firewall version 4.2.1<br />
          cpe:/h:cisco:mars_50:4.2.1<br />
          <br />
          Fingerprint Cisco Micro Webserver 200, HP WP110 print server,
          Tektronix TDS3034B oscilloscope or XP350 terminal, or Xerox Document
          Centre 405 printer<br />
          cpe:/h:micro_webserver_200<br />
          cpe:/h:hp:wp110 auto<br />
          cpe:/h:tektronix:tds3034b<br />
          cpe:/o:tektronix:os_350<br />
          cpe:/h:tektronix:xp350<br />
          cpe:/o:tektronix:os_350<br />
          cpe:/h:xerox:document_centre_405<br />
          <br />
          Fingerprint Cisco ISB7150 set top box<br />
          cpe:/h:cisco:isb7150 auto<br />
          <br />
          Fingerprint Cisco VPN 3000 Concentrator VPN platform (software version
          4.1.7.O)<br />
          cpe:/h:cisco:vpn_3000_concentrator:4.1.7.0<br />
          <br />
          Fingerprint Cisco VPN 3000 Concentrator VPN platform (software version
          4.7)<br />
          cpe:/h:cisco:vpn_3000_concentrator:4.7<br />
          <br />
          Fingerprint Cisco VPN 3030 Concentrator VPN platform<br />
          cpe:/h:cisco:vpn_3030_concentrator<br />
          <br />
          Fingerprint Cisco VPN 3030 Concentrator VPN platform (software
          4.7.2.F)<br />
          cpe:/h:cisco:vpn_3030_concentrator:4.7.2.f<br />
          <br />
          Fingerprint Cisco 4402 wireless LAN controller<br />
          cpe:/h:cisco:4402_wireless_lan_controller<br />
          <br />
          Fingerprint Cisco 1700 router<br />
          cpe:/h:cisco:1700_router<br />
          <br />
          Fingerprint Cisco 1700-series router<br />
          cpe:/h:cisco:1700_router<br />
          <br />
          Fingerprint Cisco ASR 9010 router<br />
          cpe:/h:cisco:asr_9010_router<br />
          <br />
          Fingerprint Cisco Catalyst 1900 switch<br />
          cpe:/h:cisco:catalyst_1900<br />
          <br />
          Fingerprint Cisco Catalyst 1900 switch or RAD IPMUX-1 TDM-over-IP
          multiplexer<br />
          cpe:/h:cisco:catalyst_1900<br />
          <br />
          Fingerprint Cisco Catalyst 1900 Switch, Software v9.00.03<br />
          cpe:/h:cisco:catalyst_1900<br />
          <br />
          Fingerprint Cisco Catalyst 1912 switch<br />
          cpe:/h:cisco:catalyst_1912<br />
          <br />
          Fingerprint Cisco CSS 11501 switch<br />
          cpe:/h:cisco:css_11501 auto<br />
          <br />
          Fingerprint Cisco MDS 9000 switch<br />
          cpe:/h:cisco:mds_9000<br />
          <br />
          Fingerprint Cisco Nexus 5000 switch<br />
          cpe:/o:cisco:nexus_5000<br />
          <br />
          Fingerprint Cisco SF300 or SG300 switch<br />
          cpe:/h:cisco:sf300 auto<br />
          cpe:/h:cisco:sg300 auto<br />
          <br />
          Fingerprint Cisco SF300 or SG300 switch<br />
          cpe:/h:cisco:sf300<br />
          cpe:/h:cisco:sg300<br />
          <br />
          Fingerprint Cisco SG 200 or SG 300 switch<br />
          cpe:/h:cisco:sg_200<br />
          cpe:/h:cisco:sg_300<br />
          <br />
          Fingerprint Cisco SG 300-10, Dell PowerConnect 2748, Linksys SLM2024,
          SLM2048, or SLM224P, or Netgear FS728TP or GS724TP switch<br />
          cpe:/h:cisco:sg_300-10<br />
          cpe:/h:dell:powerconnect_2748<br />
          cpe:/h:linksys:slm2024<br />
          cpe:/h:linksys:slm2048<br />
          cpe:/h:linksys:slm224p<br />
          cpe:/h:netgear:fs728tp<br />
          cpe:/h:netgear:prosafe_gs724tp<br />
          <br />
          Fingerprint Cisco SG 500 switch<br />
          cpe:/h:cisco:sg_500<br />
          <br />
          Fingerprint Cisco SG200 or SG300 switch<br />
          cpe:/h:cisco:sg_300<br />
          <br />
          Fingerprint Cisco SG200 switch<br />
          cpe:/h:cisco:sg200 auto<br />
          <br />
          Fingerprint Cisco SG200 switch<br />
          cpe:/h:cisco:sg200 auto<br />
          <br />
          Fingerprint Cisco SG250 switch<br />
          cpe:/h:cisco:sg250 auto<br />
          <br />
          Fingerprint Cisco SG300 switch<br />
          cpe:/h:cisco:sg300 auto<br />
          <br />
          Fingerprint Cisco SG300 switch<br />
          cpe:/h:cisco:sg300 auto<br />
          <br />
          Fingerprint Cisco SLM2008 or HP ProCurve 1800 switch<br />
          cpe:/h:cisco:slm2008<br />
          cpe:/h:hp:procurve_1800 auto<br />
          <br />
          Fingerprint Cisco SLM2008 switch<br />
          cpe:/h:cisco:slm2008<br />
          <br />
          Fingerprint Cisco SRW2008-K9 switch<br />
          cpe:/h:cisco:srw2008-k9 auto<br />
          <br />
          Fingerprint Cisco ATA 188 VoIP adapter<br />
          cpe:/h:cisco:ata_188_voip_gateway<br />
          <br />
          Fingerprint Cisco Unified Communications Manager VoIP adapter<br />
          cpe:/a:cisco:unified_communications_manager:7.1.1<br />
          <br />
          Fingerprint Cisco Unified Communications Manager VoIP adapter<br />
          cpe:/h:cisco:unified_call_manager<br />
          <br />
          Fingerprint Cisco 7935 or 7936 IP Conference Station VoIP phone<br />
          cpe:/h:cisco:unified_ip_conference_station_7935<br />
          cpe:/h:cisco:unified_ip_conference_station_7936<br />
          <br />
          Fingerprint Cisco 7936 IP Conference Station VoIP phone<br />
          cpe:/h:cisco:unified_ip_conference_station_7936<br />
          <br />
          Fingerprint Cisco 7936G IP Conference Station VoIP phone<br />
          cpe:/h:cisco:unified_ip_conference_station_7936g<br />
          <br />
          Fingerprint Cisco CP 8945 VoIP phone<br />
          cpe:/h:cisco:cp_8945 auto<br />
          <br />
          Fingerprint Cisco CP 8945 VoIP phone<br />
          cpe:/h:cisco:cp_8945 auto<br />
          <br />
          Fingerprint Cisco CP-DX80 collaboration endpoint (Android)<br />
          cpe:/h:cisco:cp-dx80<br />
          cpe:/o:google:android auto<br />
          <br />
          Fingerprint Cisco IP Phone (7911, 7941, 7961, or 7970)<br />
          cpe:/h:cisco:unified_ip_phone_7911<br />
          cpe:/h:cisco:unified_ip_phone_7941<br />
          cpe:/h:cisco:unified_ip_phone_7961<br />
          cpe:/h:cisco:unified_ip_phone_7970<br />
          <br />
          Fingerprint Cisco IP Phone 7910<br />
          cpe:/h:cisco:unified_ip_phone_7910<br />
          <br />
          Fingerprint Cisco IP Phone 7911<br />
          cpe:/h:cisco:unified_ip_phone_7911<br />
          <br />
          Fingerprint Cisco IP Phone 7912-series<br />
          cpe:/h:cisco:unified_ip_phone_7912<br />
          <br />
          Fingerprint Cisco IP Phone 7912-series<br />
          cpe:/h:cisco:unified_ip_phone_7912<br />
          <br />
          Fingerprint Cisco IP Phone 7912G<br />
          cpe:/h:cisco:unified_ip_phone_7912g<br />
          <br />
          Fingerprint Cisco IP Phone 7941<br />
          cpe:/h:cisco:unified_ip_phone_7941<br />
          <br />
          Fingerprint Cisco IP Phone 7941, 7961, 7965G, or 7975<br />
          cpe:/h:cisco:unified_ip_phone_7941<br />
          cpe:/h:cisco:unified_ip_phone_7961<br />
          cpe:/h:cisco:unified_ip_phone_7965g<br />
          cpe:/h:cisco:unified_ip_phone_7975<br />
          cpe:/h:cisco:unified_ip_phone<br />
          <br />
          Fingerprint Cisco IP Phone 7942G<br />
          cpe:/h:cisco:unified_ip_phone_7942g<br />
          <br />
          Fingerprint Cisco IP Phone 7942G<br />
          cpe:/h:cisco:unified_ip_phone_7942g<br />
          <br />
          Fingerprint Cisco IP Phone 7945<br />
          cpe:/h:cisco:unified_ip_phone_7945<br />
          <br />
          Fingerprint Cisco IP Phone 7945G<br />
          cpe:/h:cisco:unified_ip_phone_7945g<br />
          <br />
          Fingerprint Cisco IP Phone 7961G<br />
          cpe:/h:cisco:unified_ip_phone_7961g<br />
          <br />
          Fingerprint Cisco IP Phone 7961G<br />
          cpe:/h:cisco:unified_ip_phone_7961g<br />
          <br />
          Fingerprint Cisco IP Phone 7971G-GE<br />
          cpe:/h:cisco:unified_ip_phone_7971g-ge<br />
          <br />
          Fingerprint Cisco IP Phone 7975G<br />
          cpe:/h:cisco:unified_ip_phone_7975g<br />
          <br />
          Fingerprint Cisco IP Phone 8945<br />
          cpe:/h:cisco:unified_ip_phone_8945<br />
          <br />
          Fingerprint Cisco IP Phone CP-7970G<br />
          cpe:/h:cisco:unified_ip_phone_7970g<br />
          <br />
          Fingerprint Cisco SPA 303 VoIP phone<br />
          cpe:/h:cisco:spa303_3-line_ip_phone_with_2-port_switch<br />
          <br />
          Fingerprint Cisco SPA 303 VoIP phone, Nortel 5520 Ethernet Routing
          Switch, or Sun StorageTek 6140 NAS device<br />
          cpe:/h:cisco:spa303_3-line_ip_phone_with_2-port_switch<br />
          cpe:/h:nortel:ethernet_routing_switch_5520<br />
          cpe:/h:sun:storagetek_6140 auto<br />
          <br />
          Fingerprint Cisco SPA 502G VoIP phone<br />
          cpe:/h:cisco:small_business_ip_phone:spa505g<br />
          <br />
          Fingerprint Cisco Unified IP Phone 6921<br />
          cpe:/h:cisco:unified_ip_phone_6921<br />
          <br />
          Fingerprint Cisco Unified IP Phone 7905G or 7960<br />
          cpe:/h:cisco:unified_ip_phone_7905g<br />
          cpe:/h:cisco:unified_ip_phone_7960<br />
          <br />
          Fingerprint Cisco Unified IP Phone 7942G<br />
          cpe:/h:cisco:unified_ip_phone_7924g<br />
          <br />
          Fingerprint Cisco Unified IP Phone 7960<br />
          cpe:/h:cisco:unified_ip_phone_7960<br />
          <br />
          Fingerprint Cisco Wireless IP Phone 7920-ETSI<br />
          cpe:/h:cisco:unified_wireless_ip_phone_7920<br />
          <br />
          Fingerprint Cisco ASR 1002 router<br />
          cpe:/h:cisco:asr_1002_router<br />
          cpe:/o:cisco:ios_xe:2 auto<br />
          <br />
          Fingerprint Cisco CRS-1 router (IOS XR 3.4.1 - 3.6.2)<br />
          cpe:/o:cisco:ios_xr:3 auto<br />
          <br />
          Fingerprint Cisco IOS XR 4.1.1<br />
          cpe:/o:cisco:ios_xr:4.1.1 auto<br />
          <br />
          Fingerprint Cisco IOS XR 5.1.2<br />
          cpe:/o:cisco:ios_xr:5.1.2 auto<br />
          <br />
          Fingerprint Cisco 3000 switch (IOS 10.3)<br />
          cpe:/h:cisco:catalyst_3000<br />
          cpe:/o:cisco:ios:10.3<br />
          <br />
          Fingerprint Cisco 2500 router (IOS 11.1)<br />
          cpe:/h:cisco:2500_router<br />
          cpe:/o:cisco:ios:11.1<br />
          <br />
          Fingerprint Cisco 2600 router (IOS 11.3)<br />
          cpe:/h:cisco:2600_router<br />
          cpe:/o:cisco:ios:11.3<br />
          <br />
          Fingerprint Cisco 2900XL switch (IOS 11.2)<br />
          cpe:/h:cisco:catalyst_2900xl<br />
          cpe:/o:cisco:ios:11.2<br />
          <br />
          Fingerprint Cisco 2900XL switch (IOS 11.2)<br />
          cpe:/h:cisco:catalyst_2900xl<br />
          cpe:/o:cisco:ios:11.2<br />
          <br />
          Fingerprint Cisco Catalyst 2600-, 2900- or 3500 XL-series switch (IOS
          11.3 - 12.0)<br />
          cpe:/h:cisco:catalyst_2600<br />
          cpe:/o:cisco:ios:11 auto<br />
          cpe:/h:cisco:catalyst_2900<br />
          cpe:/h:cisco:catalyst_3500_xl<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco SOHO 97 ADSL router<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco uBR10012 broadband router<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 10000 router (IOS 12.3)<br />
          cpe:/h:cisco:10000_router<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco 1600 router (IOS 12.0)<br />
          cpe:/o:cisco:ios:12.0 auto<br />
          <br />
          Fingerprint Cisco 1700 router (IOS 12.0)<br />
          cpe:/h:cisco:router_1700<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco 1812, 3640, or 3700 router (IOS 12.4)<br />
          cpe:/h:cisco:c1812<br />
          cpe:/h:cisco:c3640<br />
          cpe:/h:cisco:c3700<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco 1841 router (IOS 12)<br />
          cpe:/h:cisco:1841_integrated_services_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 1841 router (IOS 12.4)<br />
          cpe:/h:cisco:1841_integrated_services_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco 2500 router (IOS 12.1)<br />
          cpe:/h:cisco:2500_router<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2509 router (IOS 12.3)<br />
          cpe:/h:cisco:2509_router<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco 2514 router (IOS 12.1)<br />
          cpe:/h:cisco:2514_router<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2600 router (IOS 12.0)<br />
          cpe:/h:cisco:2600_router<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco 2600 router (IOS 12.1)<br />
          cpe:/h:cisco:2600_router<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2611 router (IOS 12.3)<br />
          cpe:/h:cisco:2611_router<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco 2620 or 3620 router (IOS 12.1 - 12.3)<br />
          cpe:/h:cisco:2620_router<br />
          cpe:/h:cisco:3620_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 2620 router (IOS 12.0)<br />
          cpe:/h:cisco:2620_router<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco 2800 router (IOS 12.4)<br />
          cpe:/h:cisco:2800_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco 2801 router (IOS 12.4)<br />
          cpe:/o:cisco:ios:12.4 auto<br />
          <br />
          Fingerprint Cisco 2811 router (IOS 12.2 - 12.4)<br />
          cpe:/h:cisco:2811_router<br />
          cpe:/o:cisco:ios:12<br />
          <br />
          Fingerprint Cisco 2811 router (IOS 12.X)<br />
          cpe:/h:cisco:2811_router<br />
          cpe:/o:cisco:ios:12.x auto<br />
          <br />
          Fingerprint Cisco 2900-series, 3650, or 3750 switch; 6509 or 7206VXR
          router; or uBR925 or uBR7111 cable modem (IOS 12.1 - 12.2)<br />
          cpe:/h:cisco:6506_router<br />
          cpe:/h:cisco:7206vxr_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          cpe:/h:cisco:catalyst_2900<br />
          cpe:/h:cisco:catalyst_3650<br />
          cpe:/h:cisco:catalyst_3750<br />
          cpe:/o:cisco:ios:12 auto<br />
          cpe:/h:cisco:ubr925<br />
          cpe:/h:cisco:ubr7111<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 3925 router (IOS 12.4)<br />
          cpe:/o:cisco:ios:12.4 auto<br />
          <br />
          Fingerprint Cisco 500, 2000-, 3000-, 4000-, 5000-series, or 6506
          switch; or 2500- or 4500-series router (IOS 12.1 - 12.2)<br />
          cpe:/h:cisco:router_2500<br />
          cpe:/h:cisco:router_4500<br />
          cpe:/o:cisco:ios:12 auto<br />
          cpe:/h:cisco:catalyst_500<br />
          cpe:/h:cisco:catalyst_2000<br />
          cpe:/h:cisco:catalyst_3000<br />
          cpe:/h:cisco:catalyst_4000<br />
          cpe:/h:cisco:catalyst_5000<br />
          cpe:/h:cisco:catalyst_6506<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 5300 router (IOS 12.1)<br />
          cpe:/h:cisco:5300_router<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 5300 router (IOS 12.3)<br />
          cpe:/h:cisco:5300_router<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco 6506 router (IOS 12.2)<br />
          cpe:/h:cisco:6506_router<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 7200 router (IOS 12.4)<br />
          cpe:/o:cisco:ios:12.4 auto<br />
          <br />
          Fingerprint Cisco 7206VXR router (IOS 12.4)<br />
          cpe:/h:cisco:7206vxr_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco 7600 router (IOS 12.2)<br />
          cpe:/h:cisco:7600_router<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 800-series, 1801, 2000-series, 3800, 4000, or
          7000-series router; or 1100 or 1242G WAP (IOS 12.2 - 12.4)<br />
          cpe:/h:cisco:800_router<br />
          cpe:/h:cisco:1801_router<br />
          cpe:/h:cisco:2000_router<br />
          cpe:/h:cisco:3800_router<br />
          cpe:/h:cisco:4000_router<br />
          cpe:/h:cisco:7000_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          cpe:/h:cisco:aironet_ap1100<br />
          cpe:/h:cisco:aironet_ap1242g<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 806, 1712, 1721, or 2600 router (IOS 12.2 - 12.3)<br />
          cpe:/h:cisco:806_router<br />
          cpe:/h:cisco:1712_router<br />
          cpe:/h:cisco:1721_router<br />
          cpe:/h:cisco:2600_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 820, 1721, 1750, 1751, or 2514 router (IOS 12.3)<br />
          cpe:/h:cisco:820_router<br />
          cpe:/h:cisco:1721_router<br />
          cpe:/h:cisco:1750_router<br />
          cpe:/h:cisco:1751_router<br />
          cpe:/h:cisco:2514_router<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco 836, 890, 1751, 1841, 2800, or 2900 router (IOS 12.4
          - 15.1)<br />
          cpe:/h:cisco:836_router<br />
          cpe:/h:cisco:890_router<br />
          cpe:/h:cisco:1751_router<br />
          cpe:/h:cisco:2800_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          cpe:/h:cisco:1841_router<br />
          cpe:/h:cisco:2900_router<br />
          cpe:/o:cisco:ios:15 auto<br />
          <br />
          Fingerprint Cisco 860 or 870 router (IOS 12.4)<br />
          cpe:/h:cisco:860_router<br />
          cpe:/h:cisco:870_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco 870 router (IOS 12.4)<br />
          cpe:/h:cisco:870_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco 870 router or 2960 switch (IOS 12.2 - 12.4)<br />
          cpe:/h:cisco:870_router<br />
          cpe:/o:cisco:ios:12 auto<br />
          cpe:/h:cisco:2960_switch<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 870, 2821, 6506, or 7206VXR router (IOS 12.2 -
          15.1)<br />
          cpe:/h:cisco:870_router<br />
          cpe:/h:cisco:2821_router<br />
          cpe:/h:cisco:6506_router<br />
          cpe:/h:cisco:7206vxr_router<br />
          cpe:/o:cisco:ios:12<br />
          cpe:/o:cisco:ios:15<br />
          <br />
          Fingerprint Cisco 877 router (IOS 12.4)<br />
          cpe:/h:cisco:877_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco Aironet 1200-series WAP or 2610XM router (IOS
          12.4)<br />
          cpe:/h:cisco:2610xm_router<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/h:cisco:aironet_ap1200<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco Aironet 1240AG or 1250 WAP, 1811 or 2800 router, or
          VG 224 VoIP adapter (IOS 12.4)<br />
          cpe:/h:cisco:1811_router<br />
          cpe:/h:cisco:2800_router<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/h:cisco:vg_224<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/h:cisco:aironet_ap1240ag<br />
          cpe:/h:cisco:aironet_ap1250<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco Aironet 350 or 1200 WAP or 831 router (IOS 12.3)<br />
          cpe:/h:cisco:831_router<br />
          cpe:/o:cisco:ios:12.3<br />
          cpe:/h:cisco:aironet_ap350<br />
          cpe:/h:cisco:aironet_ap1200<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco C3620 router (IOS 12.3)<br />
          cpe:/h:cisco:3620_router<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco C860, C880, or 2821 router (IOS 12.4 - 15.0)<br />
          cpe:/h:cisco:860_router<br />
          cpe:/h:cisco:880_router<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/h:cisco:880_router<br />
          cpe:/o:cisco:ios:15.0<br />
          <br />
          Fingerprint Cisco C870 router (IOS 12.4)<br />
          cpe:/h:cisco:870_router<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco IOS 12.2<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco IOS 12.4 or IOS-XE 15.3<br />
          cpe:/o:cisco:ios:12.4 auto<br />
          cpe:/o:cisco:ios_xe:15.3 auto<br />
          <br />
          Fingerprint Cisco DOCSIS cable modem termination server (IOS 12.1)<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2500 or 2924XL switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_ws-c2924-xl<br />
          cpe:/h:cisco:catalyst_2500<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco 2900-series or 3700-series switch (IOS 12.1)<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2900-series switch (IOS 12.0)<br />
          cpe:/o:cisco:ios:12.0 auto<br />
          <br />
          Fingerprint Cisco 2900XL switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_2900xl<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco 2924M XL, 2950, or 3500XL switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_ws-c2924m-xl<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/h:cisco:catalyst_3500xl<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco 2950, 2960, 3000-series, 4510R, 6000-series, or
          6500-series switch (IOS 12.1 or 12.2)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3000<br />
          cpe:/h:cisco:catalyst_4510r<br />
          cpe:/h:cisco:catalyst_6000<br />
          cpe:/h:cisco:catalyst_6500<br />
          cpe:/o:cisco:ios:12.1 auto<br />
          <br />
          Fingerprint Cisco 2950, 2960, 3550, 3560, 3750, or 4500 switch or 6500
          router (IOS 12.1 - 15.0); or Adaptive Security Appliance firewall<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3550<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/h:cisco:catalyst_3750<br />
          cpe:/h:cisco:catalyst_4500<br />
          cpe:/o:cisco:ios:12<br />
          cpe:/o:cisco:ios:15<br />
          cpe:/a:cisco:adaptive_security_appliance_software:9 auto<br />
          <br />
          Fingerprint Cisco 2950, 2960, 3550, 3560, or 3750 switch (IOS 12.1 -
          12.2)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3550<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/h:cisco:catalyst_3750<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 2960 or 3650 switch<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3650<br />
          <br />
          Fingerprint Cisco 2960 switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco 2960 switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco 3500 XL switch<br />
          cpe:/h:cisco:catalyst_3500xl<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco 3500-series switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco 3550 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_3550<br />
          cpe:/o:cisco:ios:12.1 auto<br />
          <br />
          Fingerprint Cisco 3550 switch (IOS 12.2)<br />
          cpe:/h:cisco:3550_switch<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 3550 switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco 3560 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 3560G switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3560g<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 3750 or 6500 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3750<br />
          cpe:/h:cisco:catalyst_6500<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 3750 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3750<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco 6509 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_6509:e<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco 880 router (IOS 15.0) or 3750 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3750<br />
          cpe:/o:cisco:ios:12.2<br />
          cpe:/h:cisco:880_router<br />
          cpe:/o:cisco:ios:15.0<br />
          <br />
          Fingerprint Cisco C2960 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco C3500-series switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco C3560 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 1900, 2820, 2960, 3560, 3750, 4500, or 6513
          switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_1900<br />
          cpe:/h:cisco:catalyst_2820<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/h:cisco:catalyst_4500<br />
          cpe:/h:cisco:catalyst_6513<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 2900-series or 3500-series switch (IOS
          12.2)<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 2900XL or 3500XL switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_2900xl<br />
          cpe:/h:cisco:catalyst_3500xl<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco Catalyst 2912 switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_2912<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco Catalyst 2912 switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_2912<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco Catalyst 2950 switch<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco Catalyst 2950 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12.1<br />
          <br />
          Fingerprint Cisco Catalyst 2950-series switch<br />
          cpe:/h:cisco:catalyst_2950<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco Catalyst 2960 or 3600 switch (IOS 12.3)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3600<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco Catalyst 2960 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 2960 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 2960 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 2960, 3550, or 3560 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3550<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 2960, 3560, or 6500 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_2960<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/h:cisco:catalyst_6500<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 3500 XL-series switch (12.0)<br />
          cpe:/h:cisco:catalyst_2900<br />
          cpe:/h:cisco:catalyst_3500_xl<br />
          <br />
          Fingerprint Cisco Catalyst 3500-series switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco Catalyst 3500-series switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco Catalyst 3550 switch (IOS 12.1)<br />
          cpe:/h:cisco:catalyst_3550<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 3550 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_3550<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 3560 or 6500-series switch (IOS 12.1 -
          12.2)<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/h:cisco:catalyst_6500<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco Catalyst 6509 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_6509<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 6509-E switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_6509:e<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst 8510MSR switch (IOS 12.0)<br />
          cpe:/h:cisco:catalyst_8510msr<br />
          cpe:/o:cisco:ios:12.0<br />
          <br />
          Fingerprint Cisco Catalyst Blade Switch 3020 (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_blade_switch_3020<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst Express 500 or 520 switch<br />
          cpe:/h:cisco:catalyst_express_500<br />
          cpe:/h:cisco:catalyst_express_520<br />
          cpe:/o:cisco:ios:12 auto<br />
          <br />
          Fingerprint Cisco Catalyst Express 500 switch (IOS 12.2)<br />
          cpe:/h:cisco:catalyst_express_500<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Catalyst switch (IOS 12.2)<br />
          cpe:/o:cisco:ios:12.2 auto<br />
          <br />
          Fingerprint Cisco 1131AG WAP (IOS 12.3)<br />
          cpe:/h:cisco:aironet_ap1131ag<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco Aironet 1130 WAP (IOS 12.4)<br />
          cpe:/h:cisco:aironet_ap1131ag<br />
          cpe:/o:cisco:ios:12.4<br />
          <br />
          Fingerprint Cisco Aironet 1141N (IOS 12.4) or 3602I (IOS 15.3) WAP<br />
          cpe:/o:cisco:ios:12.4 auto<br />
          cpe:/h:cisco:aironet_1141n<br />
          cpe:/h:cisco:aironet_3602i<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/o:cisco:ios:15.3<br />
          <br />
          Fingerprint Cisco Aironet 1200 WAP (IOS 12.3)<br />
          cpe:/h:cisco:aironet_ap1200<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco Aironet 1200-series WAP router (IOS 12.3 - 12.4)<br />
          cpe:/h:cisco:aironet_ap1200<br />
          cpe:/o:cisco:ios:12<br />
          <br />
          Fingerprint Cisco Aironet 1231G WAP (IOS 12.3)<br />
          cpe:/h:cisco:aironet_ap1231g<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco Aironet 1240AG WAP, C2600 or C2800 router, or C3560
          switch (IOS 12.2 - 12.4)<br />
          cpe:/h:cisco:aironet_ap1240ag<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/h:cisco:router_2600<br />
          cpe:/h:cisco:router_2800<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/h:cisco:catalyst_3560<br />
          cpe:/o:cisco:ios:12.2<br />
          <br />
          Fingerprint Cisco Aironet 1250 WAP (IOS 12.4) or IOS XE<br />
          cpe:/h:cisco:aironet_ap1250<br />
          cpe:/o:cisco:ios:12.4<br />
          cpe:/o:cisco:ios_xe auto<br />
          <br />
          Fingerprint Cisco Aironet 350, 1100, 1200, or 1131AG WAP; or Cisco
          2600 router (IOS 12.3)<br />
          cpe:/h:cisco:aironet_ap350<br />
          cpe:/h:cisco:aironet_ap1100<br />
          cpe:/h:cisco:aironet_ap1200<br />
          cpe:/o:cisco:ios:12.3<br />
          cpe:/h:cisco:catalyst_2600<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco AS5350 VoIP gateway, or Aironet 1200, 1230, or
          1240-series WAP (IOS 12.3)<br />
          cpe:/h:cisco:as5350<br />
          cpe:/h:cisco:aironet_ap1230<br />
          cpe:/h:cisco:aironet_ap1240<br />
          cpe:/h:cisco:aironet_ap1200<br />
          cpe:/o:cisco:ios:12.3<br />
          <br />
          Fingerprint Cisco 1900-series router (IOS 15.4)<br />
          cpe:/o:cisco:ios:15.4 auto<br />
          <br />
          Fingerprint Cisco 1921 router (IOS 15.1)<br />
          cpe:/h:cisco:1921_integrated_services_router<br />
          cpe:/o:cisco:ios:15.1 auto<br />
          <br />
          Fingerprint Cisco 2811 router (IOS 15.1)<br />
          cpe:/h:cisco:catalyst_2811<br />
          cpe:/o:cisco:ios:15.1<br />
          <br />
          Fingerprint Cisco 880 router (IOS 15.1)<br />
          cpe:/o:cisco:ios:15.1 auto<br />
          <br />
          Fingerprint Cisco 887 router (IOS 15.1)<br />
          cpe:/o:cisco:ios:15.1 auto<br />
          <br />
          Fingerprint Cisco C7200 router (IOS 15)<br />
          cpe:/h:cisco:catalyst_7200<br />
          cpe:/o:cisco:ios:15 auto<br />
          <br />
          Fingerprint Cisco C7200 router (IOS 15.2)<br />
          cpe:/h:cisco:catalyst_7200<br />
          cpe:/o:cisco:ios:15.2 auto<br />
          <br />
          Fingerprint Cisco C860 router (IOS 15.2)<br />
          cpe:/h:cisco:860_router<br />
          cpe:/o:cisco:ios:15.2<br />
          <br />
          Fingerprint Cisco IOS 15<br />
          cpe:/o:cisco:ios:15 auto<br />
          <br />
          Fingerprint Cisco IOS 15.2 - 15.5<br />
          cpe:/o:cisco:ios:15 auto<br />
          <br />
          Fingerprint Cisco IOS 15.5<br />
          cpe:/o:cisco:ios:15.5 auto<br />
          <br />
          Fingerprint Cisco Catalyst 2960 switch (IOS 15.2)<br />
          cpe:/o:cisco:ios:15.2 auto<br />
          <br />
          Fingerprint Cisco Catalyst 3500-series switch (IOS 15.2)<br />
          cpe:/o:cisco:ios:15.2 auto<br />
          <br />
          Fingerprint Cisco Aironet 1042N WAP (IOS 15.2)<br />
          cpe:/o:cisco:ios:15.2 auto<br />
          <br />
          Fingerprint Cisco Aironet 2600-series WAP (IOS 15.2(2))<br />
          cpe:/o:cisco:ios:15.2 auto<br />
          <br />
          Fingerprint Cisco 3560-X switch<br />
          cpe:/o:cisco:ios auto<br />
          <br />
          Fingerprint Cisco MDS 9509 switch (NX-OS 4.2)<br />
          cpe:/o:cisco:mds_9509<br />
          cpe:/o:cisco:nx_os:4.2 auto<br />
          <br />
          Fingerprint Cisco Nexus 5010 switch (NX-OS 4.1)<br />
          cpe:/o:cisco:nexus_5010<br />
          cpe:/o:cisco:nx_os:4.1 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000 switch (NX-OS 4.2(4))<br />
          cpe:/o:cisco:nexus_7000<br />
          cpe:/o:cisco:nx_os:4.2 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000 switch (NX-OS 4.2.6)<br />
          cpe:/o:cisco:nexus_7000<br />
          cpe:/o:cisco:nx_os:4.2.6 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000-series switch (NX-OS 4.0(1a))<br />
          cpe:/o:cisco:nexus_7000<br />
          cpe:/o:cisco:nx_os:4.0 auto<br />
          <br />
          Fingerprint Cisco Nexus 7010 switch (NX-OS 4.0(4))<br />
          cpe:/o:cisco:nexus_7010<br />
          cpe:/o:cisco:nx_os:4.0 auto<br />
          <br />
          Fingerprint Cisco Nexus 7010 switch (NX-OS 4.2(1))<br />
          cpe:/o:cisco:nexus_7010<br />
          cpe:/o:cisco:nx_os:4.2 auto<br />
          <br />
          Fingerprint Cisco NX-OS 4.0(1a)N1(1)<br />
          cpe:/o:cisco:nx_os:4.0 auto<br />
          <br />
          Fingerprint Cisco Nexus 5548 switch (NX-OS 5.2)<br />
          cpe:/o:cisco:nexus_5548<br />
          cpe:/o:cisco:nx_os:5.2 auto<br />
          <br />
          Fingerprint Cisco Nexus 5548P switch (NX-OS 5.1)<br />
          cpe:/o:cisco:nexus_5548p<br />
          cpe:/o:cisco:nx_os:5.1 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000 switch (NX-OS 5.2(4))<br />
          cpe:/o:cisco:nexus_7000<br />
          cpe:/o:cisco:nx_os:5.2 auto<br />
          <br />
          Fingerprint Cisco Nexus 7010 switch (NX-OS 5)<br />
          cpe:/o:cisco:nexus_7010<br />
          cpe:/o:cisco:nx_os:5 auto<br />
          <br />
          Fingerprint Cisco Nexus 7010 switch (NX-OS 5.1(2))<br />
          cpe:/o:cisco:nexus_7010<br />
          cpe:/o:cisco:nx_os:5.1 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 5 or 6.0(2))<br />
          cpe:/o:cisco:nx_os:5 auto<br />
          cpe:/o:cisco:nx_os:6 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 5)<br />
          cpe:/o:cisco:nx_os:5 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 5.1(3))<br />
          cpe:/o:cisco:nx_os:5.1 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 5.1(3))<br />
          cpe:/o:cisco:nx_os:5.1 auto<br />
          <br />
          Fingerprint Cisco NX-OS 5.2<br />
          cpe:/o:cisco:nx_os:5.2 auto<br />
          <br />
          Fingerprint Cisco Nexus 3000 switch (NX-OS 6)<br />
          cpe:/o:cisco:nx_os:6 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000 switch (NX-OS 6.0)<br />
          cpe:/o:cisco:nx_os:6.0 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000 switch (NX-OS 6.2.8)<br />
          cpe:/o:cisco:nx_os:6.2.8 auto<br />
          <br />
          Fingerprint Cisco Nexus 7000-series switch (NX-OS 6.1(2))<br />
          cpe:/o:cisco:nexus_7000<br />
          cpe:/o:cisco:nx_os:6.1 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 6)<br />
          cpe:/o:cisco:nx_os:6 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 6.0(2))<br />
          cpe:/o:cisco:nx_os:6.0 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 6.2)<br />
          cpe:/o:cisco:nx_os:6.2 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 6.2)<br />
          cpe:/o:cisco:nx_os:6.2 auto<br />
          <br />
          Fingerprint Cisco NX-OS 6.2<br />
          cpe:/o:cisco:nx_os:6.2 auto<br />
          <br />
          Fingerprint Cisco Nexus switch (NX-OS 7.2)<br />
          cpe:/o:cisco:nx_os:7.2 auto<br />
          <br />
          Fingerprint Cisco Nexus V1000 switch (NX-OS)<br />
          cpe:/o:cisco:nexus_v1000<br />
          cpe:/o:cisco:nx_os auto<br />
          <br />
          Fingerprint Cisco Nexus V1000 switch (NX-OS)<br />
          cpe:/o:cisco:nexus_v1000<br />
          cpe:/o:cisco:nx_os auto<br />
          <br />
          Fingerprint Cisco ASA 5510 firewall (PIX OS 8.2)<br />
          cpe:/o:cisco:pix_os:6 auto<br />
          <br />
          Fingerprint Cisco PIX firewall (PIX OS 6.3(5))<br />
          cpe:/o:cisco:pix_os:6.3 auto<br />
          <br />
          Fingerprint Cognex DataMan 200 ID reader (lwIP TCP/IP stack)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Compaq Tru64 UNIX V4.0F<br />
          cpe:/o:compaq:tru64:4 auto<br />
          <br />
          Fingerprint Compaq Tru64 UNIX 5.1<br />
          cpe:/o:compaq:tru64:5.1 auto<br />
          <br />
          Fingerprint Compaq Tru64 UNIX 5.1<br />
          cpe:/o:compaq:tru64:5.1 auto<br />
          <br />
          Fingerprint Compaq Tru64 UNIX 5.1 or HP OpenVMS 7.3-1<br />
          cpe:/o:compaq:tru64:5.1 auto<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint Compaq Tru64 UNIX 5.1 or HP OpenVMS 7.3-2<br />
          cpe:/o:compaq:tru64:5.1 auto<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint Compaq Tru64 UNIX 5.1B or HP OpenVMS 8.2 - 8.3<br />
          cpe:/o:compaq:tru64:5 auto<br />
          cpe:/o:hp:openvms:8 auto<br />
          <br />
          Fingerprint Comtrend 5361 ADSL router<br />
          cpe:/h:comtrend:5361 auto<br />
          <br />
          Fingerprint Comtrend CT-800 VoIP adapter<br />
          cpe:/h:comtrend:ct-800 auto<br />
          <br />
          Fingerprint Comtrend CT536 wireless ADSL router<br />
          cpe:/h:comtrend:ct536 auto<br />
          <br />
          Fingerprint Conceptronic CHD3NET NAS device<br />
          cpe:/h:conceptronic:chd3net auto<br />
          <br />
          Fingerprint Coyote Point E250GX Equalizer load balancer<br />
          cpe:/h:coyote_point:e250gx<br />
          <br />
          Fingerprint Coyote Point E350SI Equalizer load balancer<br />
          cpe:/h:coyote_point:e350si<br />
          <br />
          Fingerprint Cray UNICOS/mk 2.0.5.60<br />
          cpe:/o:cray:unicos:2 auto<br />
          <br />
          Fingerprint Crestron AV2 or CP2E automation system or TPS-6x
          touchpanel (2-Series), or Dedicated Micros Digital Sprite 2 DVR<br />
          cpe:/o:crestron:2_series auto<br />
          <br />
          Fingerprint Crestron XPanel control system<br />
          cpe:/o:crestron:2_series auto<br />
          <br />
          Fingerprint Crestron MPC-M5 AV controller or Wago Kontakttechnik
          750-852 PLC<br />
          cpe:/h:crestron:mpc-m5<br />
          cpe:/h:wago_kontakttechnik:750-852<br />
          <br />
          Fingerprint Crestron MC2E, MP2E, PRO2, or QM-RMC control and
          automation system, or HP StorageWorks MSL4048 tape library<br />
          cpe:/h:hp:storageworks_msl4048<br />
          <br />
          Fingerprint CyanogenMod 11 (Android 4.4.4)<br />
          cpe:/o:cyanogenmod:cyanogenmod:11 auto<br />
          cpe:/o:google:android:4.4.4 auto<br />
          <br />
          Fingerprint CyanogenMod 12 (Android 5.0)<br />
          cpe:/o:cyanogenmod:cyanogenmod:12 auto<br />
          cpe:/o:google:android:5.0 auto<br />
          <br />
          Fingerprint CyanogenMod 12 (Android 5.0.2)<br />
          cpe:/o:cyanogenmod:cyanogenmod:12 auto<br />
          cpe:/o:google:android:5.0.2 auto<br />
          <br />
          Fingerprint Cymphonix EX550 firewall<br />
          cpe:/h:cymphonix:ex550 auto<br />
          <br />
          Fingerprint D-Link DI-808HV router<br />
          cpe:/h:dlink:di-808hv auto<br />
          <br />
          Fingerprint D-Link DIR-100 DSL router or Motorola Canopy cluster
          management module 2.2<br />
          cpe:/h:dlink:dir-100 auto<br />
          <br />
          Fingerprint D-Link DSL-2540B ADSL router<br />
          cpe:/h:dlink:dsl-2540b auto<br />
          <br />
          Fingerprint D-Link DSL-2640B ADSL router<br />
          cpe:/h:dlink:dsl-2640b auto<br />
          <br />
          Fingerprint D-Link DSL-2890AL ADSL router<br />
          cpe:/h:dlink:dsl-2890al auto<br />
          <br />
          Fingerprint D-Link DSL-500 ADSL router<br />
          cpe:/h:dlink:dsl-500 auto<br />
          <br />
          Fingerprint D-Link DSL-500G ADSL router<br />
          cpe:/h:dlink:dsl-500g auto<br />
          <br />
          Fingerprint D-Link DSL-504 ADSL modem<br />
          cpe:/h:dlink:dsl-504 auto<br />
          <br />
          Fingerprint D-Link DSL2-300G DSL modem<br />
          cpe:/h:dlink:dsl2-300g auto<br />
          <br />
          Fingerprint D-Link DFL-200 firewall<br />
          cpe:/h:dlink:dfl-200 auto<br />
          <br />
          Fingerprint D-Link DFL-700 firewall<br />
          cpe:/h:dlink:dfl-700 auto<br />
          <br />
          Fingerprint D-Link DP-300U, DP-G310, or Hamlet HPS01UU print server<br />
          cpe:/h:dlink:dp-300u<br />
          cpe:/h:dlink:dp-g310<br />
          cpe:/h:hamlet:hps01uu auto<br />
          <br />
          Fingerprint D-Link DPR-1260 print server; or DGL-4300, DGL-4500,
          DIR-615, DIR-625, DIR-628, DIR-655, or DIR-855 WAP<br />
          cpe:/h:dlink:dpr-1260<br />
          cpe:/h:dlink:dgl-4300<br />
          cpe:/h:dlink:dgl-4500<br />
          cpe:/h:dlink:dir-615<br />
          cpe:/h:dlink:dir-625<br />
          cpe:/h:dlink:dir-628<br />
          cpe:/h:dlink:dir-655<br />
          cpe:/h:dlink:dir-855<br />
          <br />
          Fingerprint D-Link DNS-321 NAS device<br />
          cpe:/h:dlink:dns-321 auto<br />
          <br />
          Fingerprint D-Link DES-1210, DGS-1210, or DGS-1500 switch<br />
          cpe:/h:dlink:des-1210<br />
          cpe:/h:dlink:dgs-1210<br />
          cpe:/h:dlink:dgs-1500<br />
          <br />
          Fingerprint D-Link DES-3010F or DES-3010G switch<br />
          cpe:/h:dlink:des-3010f<br />
          cpe:/h:dlink:dgs-3010g<br />
          <br />
          Fingerprint D-Link DES-3010G switch<br />
          cpe:/h:dlink:des-3010g auto<br />
          <br />
          Fingerprint D-Link DES-3016 switch<br />
          cpe:/h:dlink:des-3010f<br />
          cpe:/h:dlink:dgs-3010g<br />
          <br />
          Fingerprint D-Link DES-3028P switch<br />
          cpe:/h:dlink:des-3028p auto<br />
          <br />
          Fingerprint D-Link DES-3200 switch<br />
          cpe:/h:dlink:des-3200 auto<br />
          <br />
          Fingerprint D-Link DES-3326 switch<br />
          cpe:/h:dlink:des-3326 auto<br />
          <br />
          Fingerprint D-Link DES-3526 switch<br />
          cpe:/h:dlink:des-3526 auto<br />
          <br />
          Fingerprint D-Link DES-3526 switch<br />
          cpe:/h:dlink:des-3526 auto<br />
          <br />
          Fingerprint D-Link DES-3526 switch<br />
          cpe:/h:dlink:des-3526<br />
          <br />
          Fingerprint D-Link DES-3550 switch<br />
          cpe:/h:dlink:des-3550 auto<br />
          <br />
          Fingerprint D-Link DES-7210 switch<br />
          cpe:/h:dlink:des-7210 auto<br />
          <br />
          Fingerprint D-Link DGS-1210 switch<br />
          cpe:/h:dlink:dgs-1210<br />
          <br />
          Fingerprint D-Link DGS-1210 switch<br />
          cpe:/h:dlink:dgs-1210 auto<br />
          <br />
          Fingerprint D-Link DGS-1210 switch<br />
          cpe:/h:dlink:dgs-1210<br />
          <br />
          Fingerprint D-Link DGS-1510 switch<br />
          cpe:/h:dlink:dgs-1510 auto<br />
          <br />
          Fingerprint D-Link DGS-3024 switch<br />
          cpe:/h:dlink:dgs-3024 auto<br />
          <br />
          Fingerprint D-Link DGS-3450 switch<br />
          cpe:/h:dlink:dgs-3450 auto<br />
          <br />
          Fingerprint D-Link DGS-3610 switch<br />
          cpe:/h:dlink:dgs-3610 auto<br />
          <br />
          Fingerprint D-Link DXS-3326GSR switch<br />
          cpe:/h:dlink:dxs-3326gsr auto<br />
          <br />
          Fingerprint D-Link DXS-3600 switch<br />
          cpe:/h:dlink:dxs-3600 auto<br />
          <br />
          Fingerprint D-Link DVG-4022S VoIP adapter<br />
          cpe:/h:dlink:dvg-4022s auto<br />
          <br />
          Fingerprint D-Link DPH-150S VoIP phone<br />
          cpe:/h:dlink:dph-150s auto<br />
          <br />
          Fingerprint D-Link DAP-1522 WAP, or Xerox WorkCentre Pro 245 or 6556
          printer<br />
          cpe:/h:dlink:dap-1522<br />
          cpe:/h:xerox:workcentre_pro_245<br />
          cpe:/h:xerox:workcentre_5665<br />
          <br />
          Fingerprint D-Link DI-524 WAP<br />
          cpe:/h:dlink:di-524 auto<br />
          <br />
          Fingerprint D-Link DI-524 wireless broadband router<br />
          cpe:/h:dlink:di-524 auto<br />
          <br />
          Fingerprint D-Link DI-524 wireless broadband router<br />
          cpe:/h:dlink:di-524 auto<br />
          <br />
          Fingerprint D-Link DI-604 wireless broadband router<br />
          cpe:/h:dlink:di-604 auto<br />
          <br />
          Fingerprint D-Link DI-604 wireless broadband router<br />
          cpe:/h:dlink:di-604 auto<br />
          <br />
          Fingerprint D-Link DI-604 wireless broadband router<br />
          cpe:/h:dlink:di-604 auto<br />
          <br />
          Fingerprint D-Link DI-824VUP Wireless VPN Router<br />
          cpe:/h:dlink:di-824vup auto<br />
          <br />
          Fingerprint D-Link DIR-300 WAP<br />
          cpe:/h:dlink:dir-300 auto<br />
          <br />
          Fingerprint D-Link DIR-300 WAP<br />
          cpe:/h:dlink:dir-300 auto<br />
          <br />
          Fingerprint D-Link DIR-615, Encore 3G, or EnGenius ESR-9752 WAP<br />
          cpe:/h:dlink:dir-615<br />
          cpe:/h:encore:3g<br />
          cpe:/h:engenius:esr-9752<br />
          <br />
          Fingerprint D-Link DIR-835 WAP<br />
          cpe:/h:dlink:dir-835 auto<br />
          <br />
          Fingerprint D-Link DSR-1000N WAP<br />
          cpe:/h:dlink:dsr-1000n auto<br />
          <br />
          Fingerprint D-Link DWL-624+ or DWL-2000AP, or TRENDnet TEW-432BRP
          WAP<br />
          cpe:/h:dlink:dwl-624%2b<br />
          cpe:/h:dlink:dwl-2000ap<br />
          cpe:/h:trendnet:tew-432brp auto<br />
          <br />
          Fingerprint D-Link DWL-900AP WAP<br />
          cpe:/h:dlink:dwl-900ap auto<br />
          <br />
          Fingerprint D-Link DWL-900AP+, Planet WAP-1966, or USRobotics USR5450
          WAP<br />
          cpe:/h:dlink:dwl-9000ap%2b<br />
          cpe:/h:planet:wap-1966<br />
          cpe:/h:usrobotics:usr5450 auto<br />
          <br />
          Fingerprint D-Link DWL-G710 WAP<br />
          cpe:/h:dlink:dwl-g710 auto<br />
          <br />
          Fingerprint D-Link DWL-G810 WAP<br />
          cpe:/h:dlink:dwl-g810 auto<br />
          <br />
          Fingerprint D-Link DCS-2103 webcam<br />
          cpe:/h:dlink:dcs-2103 auto<br />
          <br />
          Fingerprint D-Link DCS-3220 webcam<br />
          cpe:/h:dlink:dcs-3220 auto<br />
          <br />
          Fingerprint D-Link DCS-6620G webcam or Linksys BEFSR41 EtherFast
          router<br />
          cpe:/h:dlink:dcs-6620g auto<br />
          cpe:/h:linksys:befsr41<br />
          <br />
          Fingerprint Data General DG/UX 4.11MU06<br />
          cpe:/o:data_general:dg%2fux:4.11mu06<br />
          <br />
          Fingerprint Daysequerra M4.2SI radio<br />
          cpe:/h:daysequerra:m4.2si<br />
          <br />
          Fingerprint DECserver716 (DNAS 3.6)<br />
          cpe:/h:dec:decserver716<br />
          cpe:/o:dec:dnas:3.6<br />
          <br />
          Fingerprint DEC Digital UNIX OSF1 v4.0 1229<br />
          cpe:/o:dec:digital_unix:4 auto<br />
          <br />
          Fingerprint DEC Digital UNIX v4.0F<br />
          cpe:/o:dec:digital_unix:4 auto<br />
          <br />
          Fingerprint DEC Digital UNIX 5.X<br />
          cpe:/o:dec:digital_unix:5.x auto<br />
          <br />
          Fingerprint DEC Digital UNIX OSF1 v5.0 910 or OpenVMS 7.2<br />
          cpe:/o:dec:digital_unix:5 auto<br />
          cpe:/o:dec:openvms:7.2 auto<br />
          <br />
          Fingerprint DEC OpenVMS 5.5-2<br />
          cpe:/o:dec:openvms:5.5 auto<br />
          <br />
          Fingerprint DEC OpenVMS 7.1<br />
          cpe:/o:dec:openvms:7.1 auto<br />
          <br />
          Fingerprint DEC OpenVMS 7.1<br />
          cpe:/o:dec:openvms:7.1 auto<br />
          <br />
          Fingerprint DEC OpenVMS 7.1<br />
          cpe:/o:dec:openvms:7.1 auto<br />
          <br />
          Fingerprint DEC OpenVMS 7.3-1<br />
          cpe:/o:dec:openvms:7.3 auto<br />
          <br />
          Fingerprint DEC OSF/1 V4.0<br />
          cpe:/o:dec:osf_1:4 auto<br />
          <br />
          Fingerprint DEC OSF/1 V5.1<br />
          cpe:/o:dec:osf_1:5 auto<br />
          <br />
          Fingerprint DEC RSX-11M-PLUS 4<br />
          cpe:/o:dec:rsx_11m_plus:4 auto<br />
          <br />
          Fingerprint DEC RSX-11M-PLUS 4.6<br />
          cpe:/o:dec:rsx_11m_plus:4.6 auto<br />
          <br />
          Fingerprint RSX-11M-PLUS 5.0<br />
          cpe:/o:dec:rsx_11m_plus:5.0 auto<br />
          <br />
          Fingerprint DEC RSX-11M-PLUS<br />
          cpe:/o:dec:rsx-11m-plus<br />
          <br />
          Fingerprint DEC TOPS-20 7.1<br />
          cpe:/o:dec:tops_20:7.1<br />
          <br />
          Fingerprint DEC TOPS-20<br />
          cpe:/o:dec:tops_20 auto<br />
          <br />
          Fingerprint DEC Ultrix 4.0<br />
          cpe:/o:dec:ultrix:4.0 auto<br />
          <br />
          Fingerprint DEC Ultrix 4.5<br />
          cpe:/o:dec:ultrix:4.5 auto<br />
          <br />
          Fingerprint DEC Ultrix 4.5<br />
          cpe:/o:dec:ultrix:4.5 auto<br />
          <br />
          Fingerprint Dell Sonicwall NSA 220 firewall<br />
          cpe:/h:dell:sonicwall_nsa_220 auto<br />
          <br />
          Fingerprint Dell 1320c printer<br />
          cpe:/h:dell:1320c auto<br />
          <br />
          Fingerprint Dell 1600n printer<br />
          cpe:/h:dell:1600n auto<br />
          <br />
          Fingerprint Dell 1720dn printer<br />
          cpe:/h:dell:1720dn auto<br />
          <br />
          Fingerprint Dell 1720dn, or Lexmark E250dn or E352dn printer<br />
          cpe:/h:dell:1720dn<br />
          cpe:/h:lexmark:e250dn auto<br />
          cpe:/h:lexmark:e352dn auto<br />
          <br />
          Fingerprint Dell 1815dn printer<br />
          cpe:/h:dell:1815dn auto<br />
          <br />
          Fingerprint Dell 2335dn printer<br />
          cpe:/h:dell:2335dn auto<br />
          <br />
          Fingerprint Dell 2350dn, IBM InfoPrint 1832; or Lexmark C544dn, T612,
          T650, or X464de printer<br />
          cpe:/h:dell:2350dn<br />
          cpe:/h:ibm:infoprint_1832<br />
          <br />
          Fingerprint Dell 3000 printer<br />
          cpe:/h:dell:3000 auto<br />
          <br />
          Fingerprint Dell 3100cn printer<br />
          cpe:/h:dell:3100cn auto<br />
          <br />
          Fingerprint Dell 3100cn-series or Xerox Phaser 6180DN printer<br />
          cpe:/h:dell:3100cn<br />
          cpe:/h:dell:3110cn<br />
          cpe:/h:dell:3115cn<br />
          cpe:/h:xerox:phaser_6180dn<br />
          <br />
          Fingerprint Dell 3110cn printer<br />
          cpe:/h:dell:3110cn auto<br />
          <br />
          Fingerprint Dell 5210n or Lexmark C530-series or T640-series
          printer<br />
          cpe:/h:dell:5210n<br />
          cpe:/h:lexmark:c530<br />
          cpe:/h:lexmark:t640<br />
          cpe:/h:lexmark:t642<br />
          cpe:/h:lexmark:t644<br />
          <br />
          Fingerprint Dell H625CDW or C2665dnf printer<br />
          cpe:/h:dell:h625cdw<br />
          cpe:/h:dell:c2665dnf<br />
          <br />
          Fingerprint Dell Embedded Remote Access (ERA) Controller 3.38 or
          4/I<br />
          cpe:/h:dell:remote_access_card:3.38<br />
          cpe:/h:dell:remote_access_card:4<br />
          <br />
          Fingerprint Dell Embedded Remote Access (ERA) Controller 4/I<br />
          cpe:/h:dell:remote_access_card:4<br />
          <br />
          Fingerprint Dell Integrated Remote Access Controller (iDRAC5)<br />
          cpe:/h:dell:remote_access_card:5<br />
          <br />
          Fingerprint Dell Integrated Remote Access Controller (iDRAC6)<br />
          cpe:/h:dell:remote_access_card:6<br />
          <br />
          Fingerprint Dell Integrated Remote Access Controller (iDRAC6)<br />
          cpe:/h:dell:remote_access_card:6<br />
          <br />
          Fingerprint Dell Integrated Remote Access Controller (iDRAC6)<br />
          cpe:/h:dell:remote_access_card:6<br />
          <br />
          Fingerprint Dell Integrated Remote Access Controller (iDRAC7)<br />
          cpe:/h:dell:remote_access_card:7<br />
          <br />
          Fingerprint Dell Integrated Remote Access Controller (iDRAC7)<br />
          cpe:/h:dell:remote_access_card:7<br />
          <br />
          Fingerprint Dell PowerEdge 2650 server Embedded Remote Access (ERA)<br />
          cpe:/h:dell:poweredge_2650<br />
          <br />
          Fingerprint Dell Remote Access Controller (DRAC 5)<br />
          cpe:/h:dell:remote_access_card:6<br />
          <br />
          Fingerprint Dell Remote Access Controller (DRAC 6)<br />
          cpe:/h:dell:remote_access_card:6<br />
          <br />
          Fingerprint Dell Remote Access Controller (DRAC 6)<br />
          cpe:/h:dell:remote_access_card:6<br />
          <br />
          Fingerprint Dell Remote Access Controller (DRAC) 4/I<br />
          cpe:/h:dell:remote_access_card:4<br />
          <br />
          Fingerprint Dell Remote Access Controller 4 (DRAC 4)<br />
          cpe:/h:dell:remote_access_card:4<br />
          <br />
          Fingerprint Dell Remote Access Controller 4/I<br />
          cpe:/h:dell:remote_access_card:4<br />
          <br />
          Fingerprint Dell Remote Access Controller 4/I<br />
          cpe:/h:dell:remote_access_card:4<br />
          <br />
          Fingerprint Dell Remote Access Controller 5/I (DRAC 5/I)<br />
          cpe:/h:dell:remote_access_card:5<br />
          <br />
          Fingerprint Dell Remote Access Controller 5/I (DRAC 5/I)<br />
          cpe:/h:dell:remote_access_card:5<br />
          <br />
          Fingerprint Dell DR4100 backup appliance<br />
          cpe:/h:dell:dr4100<br />
          <br />
          Fingerprint Dell DR4100 backup appliance<br />
          cpe:/h:dell:dr4100<br />
          <br />
          Fingerprint Dell EqualLogic PeerStorage PS3000 or PS4000 NAS device<br />
          cpe:/h:dell:equallogic_ps3000<br />
          cpe:/h:dell:equallogic_ps4000<br />
          <br />
          Fingerprint Dell EqualLogic PeerStorage PS4000X NAS device<br />
          cpe:/h:dell:equallogic_ps4000x<br />
          <br />
          Fingerprint Dell PowerVault 124T LTO-3 tape autoloader<br />
          cpe:/h:dell:powervault_124t<br />
          <br />
          Fingerprint Dell PowerVault 132T tape library<br />
          cpe:/h:dell:powervault_132t<br />
          <br />
          Fingerprint Dell PowerVault 705N NAS appliance<br />
          cpe:/h:dell:powervault_705n<br />
          <br />
          Fingerprint Dell PowerVault MD3000i NAS appliance or TP-LINK
          TL-WR340GD WAP<br />
          cpe:/h:dell:powervault_md3000i<br />
          cpe:/h:tp-link:tl-wr340gd<br />
          <br />
          Fingerprint Dell PowerVault TL2000 or IBM System Storage TS3200
          Express Model tape library<br />
          cpe:/h:dell:tl2000<br />
          cpe:/h:ibm:ts3200<br />
          <br />
          Fingerprint Dell PowerConnect 2708 switch<br />
          cpe:/h:dell:powerconnect_2708 auto<br />
          <br />
          Fingerprint Dell PowerConnect 2724 switch<br />
          cpe:/h:dell:powerconnect_2724<br />
          <br />
          Fingerprint Dell PowerConnect 3024 switch<br />
          cpe:/h:dell:powerconnect_3024 auto<br />
          <br />
          Fingerprint Dell PowerConnect 3248 switch<br />
          cpe:/h:dell:powerconnect_3248 auto<br />
          <br />
          Fingerprint Dell PowerConnect 3324 switch<br />
          cpe:/h:dell:powerconnect_3324 auto<br />
          <br />
          Fingerprint Dell PowerConnect 3348 switch<br />
          cpe:/h:dell:powerconnect_3348 auto<br />
          <br />
          Fingerprint Dell PowerConnect 5316M switch<br />
          cpe:/h:dell:powerconnect_5316m auto<br />
          <br />
          Fingerprint Dell PowerConnect 5324 switch<br />
          cpe:/h:dell:powerconnect_5324 auto<br />
          <br />
          Fingerprint Dell PowerConnect 5324 switch<br />
          cpe:/h:dell:powerconnect_5324 auto<br />
          <br />
          Fingerprint Dell PowerConnect 5424 switch<br />
          cpe:/h:dell:powerconnect_5424 auto<br />
          <br />
          Fingerprint Dell PowerConnect 5524 switch<br />
          cpe:/h:dell:powerconnect_5524 auto<br />
          <br />
          Fingerprint Dell PowerConnect 6200-series switch<br />
          cpe:/h:dell:powerconnect_6200<br />
          <br />
          Fingerprint Dell PowerConnect 6248, or Enterasys B3 BG3124 or D2
          D2G124 switch<br />
          cpe:/h:dell:powerconnect_6248<br />
          cpe:/h:enterasys:bg3124<br />
          cpe:/h:enterasys:d2g124<br />
          <br />
          Fingerprint Dell PowerConnect 8024F switch<br />
          cpe:/h:dell:powerconnect_8024f<br />
          <br />
          Fingerprint Dell PowerConnect 8024F switch<br />
          cpe:/h:dell:powerconnect_8024f<br />
          <br />
          Fingerprint Dell X1052P switch<br />
          cpe:/h:dell:x1052p auto<br />
          <br />
          Fingerprint Dell EqualLogic PeerStorage PS100E NAS device (NetBSD
          1.6.2)<br />
          cpe:/h:dell:equallogic_peerstorage_ps100e<br />
          cpe:/o:dell:netbsd:1.6.2 auto<br />
          <br />
          Fingerprint Dell EqualLogic NAS device (NetBSD 1.6.2)<br />
          cpe:/h:dell:equallogic<br />
          cpe:/o:dell:netbsd:1.6.2 auto<br />
          <br />
          Fingerprint Dell Networking Operating System 6.2 (Linux 3.6)<br />
          cpe:/o:dell:network_operating_system:6 auto<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Denon AVR-2113 audio receiver<br />
          cpe:/h:denon:avr-2113<br />
          <br />
          Fingerprint Denon AVR-3808CI audio/video receiver<br />
          cpe:/h:denon:avr-3808ci<br />
          <br />
          Fingerprint Denon AVR-3808CI audio/video receiver<br />
          cpe:/h:denon:avr-3808ci<br />
          <br />
          Fingerprint Denon AVR-3808CI audio/video receiver, Philips SLA5500 or
          SLA5520 Wireless Music Adapter or WAK3300 wireless alarm clock, or
          Terratec NOXON audio system<br />
          cpe:/h:denon:avr-3808ci<br />
          <br />
          Fingerprint Yamaha RX-S600 or Denon AVR-1912 or AVR-2312 audio
          receiver<br />
          cpe:/h:denon:avr-1912<br />
          cpe:/h:denon:avr-2312<br />
          cpe:/h:yamaha:rx-S600<br />
          <br />
          Fingerprint Denver Electronics AC-5000W MK2 camera<br />
          cpe:/h:denver_electronics:ac-5000w<br />
          <br />
          Fingerprint Digi Connect ME serial-to-Ethernet bridge<br />
          cpe:/h:digi:connect_me<br />
          <br />
          Fingerprint Digi ConnectPort TS1 gateway<br />
          cpe:/h:digi:connectport_ts1<br />
          <br />
          Fingerprint Digi NET+OS 7<br />
          cpe:/o:digi:net_os:7 auto<br />
          <br />
          Fingerprint Digi NET+OS 7<br />
          cpe:/o:digi:net_os:7 auto<br />
          <br />
          Fingerprint Dish Network Hopper media device<br />
          cpe:/h:dish:hopper<br />
          <br />
          Fingerprint Dish Network Hopper media device<br />
          cpe:/h:dish:hopper<br />
          <br />
          Fingerprint DragonFly BSD 1.10.1<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:1.10.1 auto<br />
          <br />
          Fingerprint DragonFly BSD 1.8.0-RELEASE - 1.10.1 (x86)<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:1 auto<br />
          <br />
          Fingerprint DragonFly BSD 2.11<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:2.11 auto<br />
          <br />
          Fingerprint DragonFly BSD 2.3.2<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:2.3.2 auto<br />
          <br />
          Fingerprint DragonFly BSD 2.9<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:2.9 auto<br />
          <br />
          Fingerprint DragonFly BSD 2.9.1 - 3.1<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:2 auto<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:3 auto<br />
          <br />
          Fingerprint DragonFly BSD 4.6-RELEASE<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:4.6 auto<br />
          <br />
          Fingerprint DragonFly BSD 4.9<br />
          cpe:/o:dragonflybsd:dragonfly_bsd:4.9 auto<br />
          <br />
          Fingerprint Draytek Vigor 2960 VPN firewall<br />
          cpe:/h:draytek:vigor_2960<br />
          <br />
          Fingerprint DrayTek Vigor 2930n wireless router<br />
          cpe:/h:draytek:vigor_2930n auto<br />
          <br />
          Fingerprint Drobo B800i iSCSI NAS<br />
          cpe:/h:drobo:b800i<br />
          <br />
          Fingerprint Drobo5N NAS (Linux 3.2)<br />
          cpe:/h:drobo:5n<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint DTE Energy Bridge (lwIP stack)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Eaton Powerware 9170 UPS<br />
          cpe:/h:eaton:powerware_9170<br />
          <br />
          Fingerprint Arris SB6183 cable modem (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          cpe:/h:arris:sb6183 auto<br />
          <br />
          Fingerprint Cisco 200-series, HP ProCurve 1810G, or Netgear GS110TP
          switch (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          cpe:/h:hp:procurve_switch_1810g<br />
          cpe:/h:netgear:gs110tp auto<br />
          <br />
          Fingerprint Arris SB6183 cable modem (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          cpe:/h:arris:sb6183 auto<br />
          <br />
          Fingerprint Cisco EPC3925, DPC2320, Motorola SURFboard SB5101E, or
          Scientific Atlanta EPC2203 cable modem (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          cpe:/h:cisco:epc3925<br />
          cpe:/h:cisco:dpc2320<br />
          cpe:/h:motorola:sb5101e<br />
          cpe:/h:scientific_atlanta:epc2203<br />
          <br />
          Fingerprint HP ProCurve 1810G, or Netgear GS108v2, GS110TP, GS716T, or
          GS724TP switch (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          <br />
          Fingerprint Netgear CG814WG v2, Thomson TWG870U, Ubee DVW3201B, or
          Cisco DPC3825 wireless cable modem (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          cpe:/h:netgear:cg814wg<br />
          cpe:/h:thomson:twg870u<br />
          cpe:/h:ubee:dvw3201b<br />
          cpe:/h:cisco:dpc3825<br />
          <br />
          Fingerprint Netgear CG814WG wireless cable modem (eCos 2.0)<br />
          cpe:/o:ecoscentric:ecos:2.0 auto<br />
          cpe:/h:netgear:cg814wg auto<br />
          <br />
          Fingerprint HP ProCurve 1810G switch (eCos 3.0)<br />
          cpe:/o:ecoscentric:ecos:3.0 auto<br />
          cpe:/h:hp:procurve_switch_1810g auto<br />
          <br />
          Fingerprint HP ProCurve 1810G, ZyXEL GS1910, or FibroLAN switch (eCos
          3.0)<br />
          cpe:/o:ecoscentric:ecos:3.0 auto<br />
          cpe:/h:hp:procurve_1810g<br />
          cpe:/h:zyxel:gs1910<br />
          <br />
          Fingerprint Netgear GS108T or GS748Tv3 switch (eCos)<br />
          cpe:/o:ecoscentric:ecos auto<br />
          cpe:/h:netgear:gs108t<br />
          cpe:/h:netgear:gs748tv3<br />
          <br />
          Fingerprint Netgear GS108T switch (eCos)<br />
          cpe:/o:ecoscentric:ecos auto<br />
          cpe:/h:netgear:gs108t<br />
          <br />
          Fingerprint Efficient Networks 5930 ADSL router<br />
          cpe:/h:efficientnetworks:5930 auto<br />
          <br />
          Fingerprint Efficient Networks SpeedStream 4100 ADSL router<br />
          cpe:/h:efficientnetworks:speedstream_4100 auto<br />
          <br />
          Fingerprint Efficient Networks SpeedStream 5100 ADSL router<br />
          cpe:/h:efficientnetworks:speedstream_5100 auto<br />
          <br />
          Fingerprint Elfiq LB-550B load balancer (EOS 3.5.2)<br />
          cpe:/o:elfiq:eos:3.5.2 auto<br />
          <br />
          Fingerprint Elk ELK-M1EXP Ethernet-to-serial bridge<br />
          cpe:/h:elk:elk-m1exp<br />
          <br />
          Fingerprint Elk ELK-M1EXP Ethernet-to-serial bridge<br />
          cpe:/h:elk:elk-m1exp<br />
          <br />
          Fingerprint Elsag Datamat SAS-200 ADSL modem<br />
          cpe:/h:elsagdatamat:sas-200 auto<br />
          <br />
          Fingerprint EMC Celerra NS350 NAS device<br />
          cpe:/h:emc:celerra_ns350 auto<br />
          <br />
          Fingerprint EMC VNX OE for File 7<br />
          cpe:/o:emc:vnx_oe_for_file:7 auto<br />
          <br />
          Fingerprint EMC VNX OE for File 7.1<br />
          cpe:/o:emc:vnx_oe_for_file:7.1 auto<br />
          <br />
          Fingerprint Enerdis Enerium 200 energy monitoring device, Mitsubishi
          XD1000 projector, or Lantronix UDS200 external serial device server<br />
          cpe:/h:enerdis:enerium_200<br />
          cpe:/h:mitsubishi:xd1000<br />
          cpe:/h:lantronix:uds200<br />
          <br />
          Fingerprint EnGenius ESR-9250 WAP<br />
          cpe:/h:engenius:esr-9250 auto<br />
          <br />
          Fingerprint Enlogic PDU (FreeRTOS/lwIP)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Enterasys XP-8000 switch<br />
          cpe:/h:enterasys:xp-8000 auto<br />
          <br />
          Fingerprint Dell PowerConnect 6248, Enterasys C3 C3G124 or Netgear
          GSM7328Sv2 switch<br />
          cpe:/h:enterasys:c3_c3g124<br />
          cpe:/h:netgear:gsm7328sv2<br />
          cpe:/h:dell:powerconnect_6248<br />
          <br />
          Fingerprint Enterasys B5 switch<br />
          cpe:/h:enterasys:b5 auto<br />
          <br />
          Fingerprint Enterasys K6 or S4 switch<br />
          cpe:/h:enterasys:k6<br />
          cpe:/h:enterasys:s4<br />
          <br />
          Fingerprint Enterasys Matrix C1 switch or HP LaserJet 3600 printer<br />
          cpe:/h:enterasys:matrix_c1 auto<br />
          cpe:/h:hp:laserjet_3600 auto<br />
          <br />
          Fingerprint Enterasys Matrix E1 switch<br />
          cpe:/h:enterasys:matrix_e1 auto<br />
          <br />
          Fingerprint Enterasys Matrix E1 switch (VxWorks)<br />
          cpe:/h:enterasys:matrix_e1 auto<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Enterasys Matrix N7 switch<br />
          cpe:/h:enterasys:matrix_n7 auto<br />
          <br />
          Fingerprint Enterasys SecureStack C2 switch<br />
          cpe:/h:enterasys:securestack_c2 auto<br />
          <br />
          Fingerprint Enterasys AP3620 WAP<br />
          cpe:/h:enterasys:ap3620 auto<br />
          <br />
          Fingerprint Epson UB-E02 print server<br />
          cpe:/h:epson:ub-e02 auto<br />
          <br />
          Fingerprint Epson AcuLaser C1100N printer<br />
          cpe:/h:epson:aculaser_c1100n auto<br />
          <br />
          Fingerprint Epson Artisan 810 printer<br />
          cpe:/h:epson:artisan_810 auto<br />
          <br />
          Fingerprint Epson Stylus Pro 400 printer<br />
          cpe:/h:epson:stylus_pro_400 auto<br />
          <br />
          Fingerprint Epson Stylus Pro 4900<br />
          cpe:/h:epson:stylus_pro_4900<br />
          <br />
          Fingerprint Epson TM-T88V M244A printer<br />
          cpe:/h:epson:tm-t88v_m244a<br />
          <br />
          Fingerprint Epson WF-2660 printer<br />
          cpe:/h:epson:wf-2660 auto<br />
          <br />
          Fingerprint Epson XP-630 printer<br />
          cpe:/h:epson:xp-630 auto<br />
          <br />
          Fingerprint ESI ESI-100 VoIP adapter<br />
          cpe:/h:esi:esi-100 auto<br />
          <br />
          Fingerprint Espressif esp8266 firmware (lwIP stack)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Espressif esp8266 firmware (lwIP stack)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Bluebottle OS<br />
          cpe:/o:ethzurich:bluebottle auto<br />
          <br />
          Fingerprint TRENDnet TEW-432BRP WAP (ThreadX)<br />
          cpe:/o:expresslogic:threadx:4 auto<br />
          cpe:/h:trendnet:tew-432brp<br />
          <br />
          Fingerprint WAP: Airnet AWR014G; D-Link DI-524, DI-604, DI-624, or
          WBR-2310; or TRENDnet TEW-432BRP or TEW-452BRP (ThreadX)<br />
          cpe:/o:expresslogic:threadx:4 auto<br />
          cpe:/h:airnet:awr014g<br />
          cpe:/h:dlink:di-524<br />
          cpe:/h:dlink:di-604<br />
          cpe:/h:dlink:di-624<br />
          cpe:/h:dlink:wbr-2310<br />
          cpe:/h:trendnet:tew-432brp<br />
          cpe:/h:trendnet:tew-452brp<br />
          <br />
          Fingerprint Express Logic ThreadX G3.0 (broadband router)<br />
          cpe:/o:expresslogic:threadx:g3.0 auto<br />
          <br />
          Fingerprint Vegastream VoIP Gateway<br />
          cpe:/o:expresslogic:threadx auto<br />
          <br />
          Fingerprint Extreme Networks Summit48si switch (ExtremeWare 7.1 -
          7.8)<br />
          cpe:/o:extremenetworks:extremeware:7 auto<br />
          <br />
          Fingerprint Extreme Networks Summit48si switch (ExtremeWare 7.4 -
          7.8)<br />
          cpe:/o:extremenetworks:extremeware:7 auto<br />
          <br />
          Fingerprint Extreme Networks ExtremeXOS 12.5.4<br />
          cpe:/o:extremenetworks:extremexos:12.5.4 auto<br />
          <br />
          Fingerprint Extreme Networks x250e or x350 switch (ExtremeXOS 12.0.1 -
          12.0.3)<br />
          cpe:/o:extremenetworks:extremexos:12.0<br />
          <br />
          Fingerprint Extreme Networks ExtremeOS 15.3<br />
          cpe:/o:extremenetworks:extremexos:15 auto<br />
          <br />
          Fingerprint Extreme Networks ExtremeXOS 12.5.1 or 15.3<br />
          cpe:/o:extremenetworks:extremexos:15.3<br />
          cpe:/o:extremenetworks:extremexos:12.5.1<br />
          <br />
          Fingerprint Extreme Networks ExtremeXOS 15<br />
          cpe:/o:extremenetworks:extremexos:15 auto<br />
          <br />
          Fingerprint Extreme Networks ExtremeXOS 15.3<br />
          cpe:/o:extremenetworks:extremexos:15.3 auto<br />
          <br />
          Fingerprint Extreme Networks switch (ExtremeXOS 15 - 16)<br />
          cpe:/o:extremenetworks:extremexos:15 auto<br />
          cpe:/o:extremenetworks:extremexos:16 auto<br />
          <br />
          Fingerprint F5 BIG-IP load balancer (BSD/OS 4.5)<br />
          cpe:/o:f5:bsd_os:4.5 auto<br />
          <br />
          Fingerprint F5 3600 LTM load balancer<br />
          cpe:/o:f5:tmos:11.1<br />
          <br />
          Fingerprint F5 BIG-IP AFM firewall<br />
          cpe:/o:f5:tmos:11.4<br />
          <br />
          Fingerprint F5 BIG-IP load balancer (TMOS 11.4)<br />
          cpe:/o:f5:tmos:11.4 auto<br />
          <br />
          Fingerprint F5 BIG-IP Local Traffic Manager load balancer (TMOS
          11.6)<br />
          cpe:/o:f5:tmos:11.6 auto<br />
          <br />
          Fingerprint F5 BIG-IP Local Traffic Manager load balancer<br />
          cpe:/o:f5:tmos:9.1 auto<br />
          <br />
          Fingerprint Fatek FBs-CBEH PLC Ethernet communication board<br />
          cpe:/h:fatek:fbs-cbeh<br />
          <br />
          Fingerprint FireBrick FB2700 firewall<br />
          cpe:/h:firebrick:fb2700 auto<br />
          <br />
          Fingerprint FireBrick FB2700 firewall<br />
          cpe:/h:firebrick:fb2700 auto<br />
          <br />
          Fingerprint FireBrick FB2700 firewall<br />
          cpe:/h:firebrick:fb2700 auto<br />
          <br />
          Fingerprint Force10 S50N switch (FTOS 1.0)<br />
          cpe:/o:force10:ftos:1.0 auto<br />
          <br />
          Fingerprint Force10 Networks S-Series switch (SFTOS 2.5.1.3)<br />
          cpe:/o:force10:sftos:2.5.1.3 auto<br />
          <br />
          Fingerprint Force10 SFTOS 2.5.2.2<br />
          cpe:/o:force10:sftos:2.5.2.2 auto<br />
          <br />
          Fingerprint Fortinet FortiGate 100D firewall<br />
          cpe:/h:fortinet:fortigate_100d auto<br />
          <br />
          Fingerprint Fortinet FortiGate 100D firewall<br />
          cpe:/h:fortinet:fortigate_100d auto<br />
          <br />
          Fingerprint Fortinet FortiGate 1500D firewall<br />
          cpe:/h:fortinet:fortigate_1500d auto<br />
          <br />
          Fingerprint Fortinet FortiGate 200B firewall<br />
          cpe:/h:fortinet:fortigate_200b auto<br />
          <br />
          Fingerprint Fortinet FortiSwitch-124B-POE switch<br />
          cpe:/h:fortinet:fortiswitch-124b-poe<br />
          <br />
          Fingerprint Fortinet FortiOS 5.0.6<br />
          cpe:/o:fortinet:fortios:5.0.6 auto<br />
          <br />
          Fingerprint Foundry FastIron 400 switch<br />
          cpe:/h:foundrynet:fastiron_400 auto<br />
          <br />
          Fingerprint Foundry NetIron MLX-16 switch (IronWare)<br />
          cpe:/h:foundrynet:netiron_mlx-16 auto<br />
          <br />
          Fingerprint Foundry Networks FES2402 switch, ServerIron 4G proxy
          server, or ServerIron GT EGx2 load balancer<br />
          cpe:/h:foundrynet:networks_fes2402<br />
          cpe:/h:foundrynet:serveriron_4g<br />
          cpe:/h:foundrynet:serveriron_gt_egx2<br />
          <br />
          Fingerprint Foundry Networks ServerIron switch<br />
          cpe:/h:foundrynet:serveriron<br />
          <br />
          Fingerprint Foundry BigIron RX switch (IronWare 2.2.1)<br />
          cpe:/o:foundrynet:ironware:2.2.1 auto<br />
          <br />
          Fingerprint Foundry BigIron RX switch (IronWare 2.2.1lT157)<br />
          cpe:/o:foundrynet:ironware:2.2.1lt157 auto<br />
          <br />
          Fingerprint Foundry BigIron RX switch, NetIron MLX switch, or NetIron
          4000 XMR switch (IronWare 2.2.1 - 3.6.0)<br />
          cpe:/o:foundrynet:ironware:2 auto<br />
          cpe:/o:foundrynet:ironware:3 auto<br />
          <br />
          Fingerprint Foundry FastIron switch (4802, FESX, GS 648P, X448, or II
          Plus) (IronWare)<br />
          cpe:/o:foundrynet:ironware:3 auto<br />
          cpe:/o:foundrynet:ironware:4 auto<br />
          <br />
          Fingerprint Foundry NetIron MLX switch (IronWare 3.8.0)<br />
          cpe:/o:foundrynet:ironware:3.8.0 auto<br />
          <br />
          Fingerprint Brocade/Foundry NetIron CER router (IronWare 5 - 6)<br />
          cpe:/o:foundrynet:ironware:5 auto<br />
          cpe:/o:brocade:ironware:6 auto<br />
          <br />
          Fingerprint Foundry Networks BigIron 8000 switch (IronWare
          07.8.02eT53)<br />
          cpe:/o:foundrynet:ironware:7 auto<br />
          <br />
          Fingerprint FreeBSD 10.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:10.0 auto<br />
          <br />
          Fingerprint FreeBSD 10.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.0 auto<br />
          <br />
          Fingerprint FreeBSD 10.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.1 auto<br />
          <br />
          Fingerprint FreeBSD 10.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.1 auto<br />
          <br />
          Fingerprint FreeBSD 10.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.1 auto<br />
          <br />
          Fingerprint FreeBSD 10.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.2 auto<br />
          <br />
          Fingerprint FreeBSD 10.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.3 auto<br />
          <br />
          Fingerprint FreeBSD 10.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.3 auto<br />
          <br />
          Fingerprint FreeBSD 10.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.3 auto<br />
          <br />
          Fingerprint FreeBSD 10.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.3 auto<br />
          <br />
          Fingerprint FreeBSD 10.3-STABLE<br />
          cpe:/o:freebsd:freebsd:10.3 auto<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 9.3-RELEASE - 10.3-RELEASE)<br />
          cpe:/a:nas4free:nas4free<br />
          cpe:/o:freebsd:freebsd:10 auto<br />
          <br />
          Fingerprint Sony Playstation 4 or FreeBSD 10.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:10.2 auto<br />
          cpe:/h:sony:playstation_4<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 10.2-RELEASE)<br />
          cpe:/a:nas4free:nas4free<br />
          cpe:/o:freebsd:freebsd:10.2 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-RELEASE - 12.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:11 auto<br />
          cpe:/o:freebsd:freebsd:12 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-STABLE<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint FreeBSD 11.0-STABLE or 11.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:11.0 auto<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 11.0-RELEASE)<br />
          cpe:/o:freebsd:freebsd:11.0<br />
          cpe:/a:nas4free:nas4free<br />
          <br />
          Fingerprint FreeBSD 12.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:12.0 auto<br />
          <br />
          Fingerprint FreeBSD 2.2.8-STABLE - 2.2.9 (x86)<br />
          cpe:/o:freebsd:freebsd:2 auto<br />
          <br />
          Fingerprint FreeBSD 3.5.1 (x86)<br />
          cpe:/o:freebsd:freebsd:3.5.1<br />
          <br />
          Fingerprint m0n0wall 1.22 - 1.23b1 firewall (FreeBSD 4.11)<br />
          cpe:/o:freebsd:freebsd:4.11 auto<br />
          <br />
          Fingerprint FreeBSD 4.10-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.10<br />
          <br />
          Fingerprint FreeBSD 4.10-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.10 auto<br />
          <br />
          Fingerprint FreeBSD 4.10-RELEASE (x86)<br />
          cpe:/o:freebsd:freebsd:4.10<br />
          <br />
          Fingerprint FreeBSD 4.10-RELEASE - 5.4-SECURITY<br />
          cpe:/o:freebsd:freebsd:4 auto<br />
          cpe:/o:freebsd:freebsd:5 auto<br />
          <br />
          Fingerprint FreeBSD 4.10-STABLE - 4.11-SECURITY<br />
          cpe:/o:freebsd:freebsd:4 auto<br />
          <br />
          Fingerprint FreeBSD 4.11-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.11<br />
          <br />
          Fingerprint FreeBSD 4.11-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.11<br />
          <br />
          Fingerprint FreeBSD 4.11-RELEASE (x86)<br />
          cpe:/o:freebsd:freebsd:4.11<br />
          <br />
          Fingerprint FreeBSD 4.11-STABLE<br />
          cpe:/o:freebsd:freebsd:4.11<br />
          <br />
          Fingerprint FreeBSD 4.11-STABLE<br />
          cpe:/o:freebsd:freebsd:4.11<br />
          <br />
          Fingerprint FreeBSD 4.11-STABLE<br />
          cpe:/o:freebsd:freebsd:4.11 auto<br />
          <br />
          Fingerprint FreeBSD 4.3-RELEASE or IBM AIX 5.3 - 6.1<br />
          cpe:/o:freebsd:freebsd:4.3<br />
          cpe:/o:ibm:aix:5 auto<br />
          <br />
          Fingerprint FreeBSD 4.6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.6.2<br />
          <br />
          Fingerprint FreeBSD 4.7-STABLE<br />
          cpe:/o:freebsd:freebsd:4.7<br />
          <br />
          Fingerprint FreeBSD 4.9-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.9<br />
          <br />
          Fingerprint FreeBSD 4.9-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.9<br />
          <br />
          Fingerprint FreeBSD 4.9-RELEASE<br />
          cpe:/o:freebsd:freebsd:4.9 auto<br />
          <br />
          Fingerprint FreeBSD daemon 4.5-RELEASE<br />
          cpe:/o:freebsd:freebsd:4 auto<br />
          <br />
          Fingerprint FreeBSD 5.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:5.0 auto<br />
          <br />
          Fingerprint FreeBSD 5.1<br />
          cpe:/o:freebsd:freebsd:5.1<br />
          <br />
          Fingerprint FreeBSD 5.2.1-RC2<br />
          cpe:/o:freebsd:freebsd:5.2.1:rc2<br />
          <br />
          Fingerprint FreeBSD 5.2.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:5.2.1<br />
          <br />
          Fingerprint FreeBSD 5.3 - 5.5<br />
          cpe:/o:freebsd:freebsd:5 auto<br />
          <br />
          Fingerprint FreeBSD 5.4-RELEASE<br />
          cpe:/o:freebsd:freebsd:5.4<br />
          <br />
          Fingerprint FreeBSD 5.4-RELEASE<br />
          cpe:/o:freebsd:freebsd:5.4<br />
          <br />
          Fingerprint FreeBSD 5.4-RELEASE<br />
          cpe:/o:freebsd:freebsd:5.4<br />
          <br />
          Fingerprint FreeBSD 5.4-STABLE<br />
          cpe:/o:freebsd:freebsd:5.4<br />
          <br />
          Fingerprint FreeBSD 5.4-STABLE<br />
          cpe:/o:freebsd:freebsd:5.4<br />
          <br />
          Fingerprint FreeBSD 5.5-RELEASE<br />
          cpe:/o:freebsd:freebsd:5.5 auto<br />
          <br />
          Fingerprint FreeBSD 5.5-STABLE<br />
          cpe:/o:freebsd:freebsd:5.5<br />
          <br />
          Fingerprint FreeBSD 5.5-STABLE<br />
          cpe:/o:freebsd:freebsd:5.5<br />
          <br />
          Fingerprint m0n0wall 1.3b11 - 1.3b15 (FreeBSD 6.3)<br />
          cpe:/o:freebsd:freebsd:6.3 auto<br />
          <br />
          Fingerprint m0nowall 1.3b16 firewall (FreeBSD 6.3-RELEASE)<br />
          cpe:/o:freebsd:freebsd:6.3 auto<br />
          <br />
          Fingerprint FreeBSD 6.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.0<br />
          <br />
          Fingerprint FreeBSD 6.0-RELEASE - 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeBSD 6.0-SECURITY (x86)<br />
          cpe:/o:freebsd:freebsd:6.0 auto<br />
          <br />
          Fingerprint FreeBSD 6.0-STABLE - 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.1<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.1<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.1<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.1<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE - 6.2<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE - 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeBSD 6.1-RELEASE-p10 (x86_64)<br />
          cpe:/o:freebsd:freebsd:6.1 auto<br />
          <br />
          Fingerprint FreeBSD 6.1-STABLE<br />
          cpe:/o:freebsd:freebsd:6.1<br />
          <br />
          Fingerprint FreeBSD 6.1-STABLE - 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeBSD 6.2-RC1<br />
          cpe:/o:freebsd:freebsd:6.2:rc1<br />
          <br />
          Fingerprint FreeBSD 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          <br />
          Fingerprint FreeBSD 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          <br />
          Fingerprint FreeBSD 6.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          <br />
          Fingerprint FreeBSD 6.2-RELEASE (x86)<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          <br />
          Fingerprint FreeBSD 6.2-RELEASE-p2 (pf with scrub enabled)<br />
          cpe:/o:freebsd:freebsd:6.2 auto<br />
          <br />
          Fingerprint FreeBSD 6.2-STABLE - 6.4-STABLE<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeBSD 6.3-PRERELEASE<br />
          cpe:/o:freebsd:freebsd:6.3 auto<br />
          <br />
          Fingerprint FreeBSD 6.3-PRERELEASE<br />
          cpe:/o:freebsd:freebsd:6.3 auto<br />
          <br />
          Fingerprint FreeBSD 6.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.3<br />
          <br />
          Fingerprint FreeBSD 6.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:6.3<br />
          <br />
          Fingerprint FreeBSD 6.3-RELEASE-p1<br />
          cpe:/o:freebsd:freebsd:6.3 auto<br />
          <br />
          Fingerprint FreeBSD 6.3-STABLE<br />
          cpe:/o:freebsd:freebsd:6.3<br />
          <br />
          Fingerprint FreeBSD 7.0<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-BETA2 (custom compiled)<br />
          cpe:/o:freebsd:freebsd:7.0:beta2<br />
          <br />
          Fingerprint FreeBSD 7.0-BETA4<br />
          cpe:/o:freebsd:freebsd:7.0:beta4<br />
          <br />
          Fingerprint FreeBSD 7.0-BETA4 - 7.0<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-CURRENT (pre-release)<br />
          cpe:/o:freebsd:freebsd:7.0 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-RC1<br />
          cpe:/o:freebsd:freebsd:7.0:rc1<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE - 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          cpe:/o:freebsd:freebsd:8 auto<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE-p1<br />
          cpe:/o:freebsd:freebsd:7.0 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE-p1 - 10.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          cpe:/o:freebsd:freebsd:8 auto<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          cpe:/o:freebsd:freebsd:10 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE-p2 - 7.1-PRERELEASE<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-RELEASE-p5<br />
          cpe:/o:freebsd:freebsd:7.0 auto<br />
          <br />
          Fingerprint FreeBSD 7.0-STABLE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-STABLE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-STABLE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.0-STABLE<br />
          cpe:/o:freebsd:freebsd:7.0<br />
          <br />
          Fingerprint FreeBSD 7.1-PRERELEASE - 7.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          <br />
          Fingerprint FreeBSD 7.1-PRERELEASE 7.2-STABLE<br />
          cpe:/o:freebsd:freebsd:7.1 auto<br />
          <br />
          Fingerprint FreeBSD 7.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.1<br />
          <br />
          Fingerprint FreeBSD 7.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.1<br />
          <br />
          Fingerprint FreeBSD 7.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.1<br />
          <br />
          Fingerprint FreeBSD 7.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.1<br />
          <br />
          Fingerprint FreeBSD 7.1-RELEASE - 9.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          cpe:/o:freebsd:freebsd:8 auto<br />
          <br />
          Fingerprint FreeBSD 7.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.2<br />
          <br />
          Fingerprint FreeBSD 7.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.2<br />
          <br />
          Fingerprint FreeBSD 7.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:7.2<br />
          <br />
          Fingerprint FreeBSD 7.2-RELEASE - 8.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          <br />
          Fingerprint FreeBSD 7.2-STABLE<br />
          cpe:/o:freebsd:freebsd:7.2<br />
          <br />
          Fingerprint m0n0wall 1.8.1 (FreeBSD 8.4)<br />
          cpe:/o:freebsd:freebsd:8.4 auto<br />
          <br />
          Fingerprint FreeBSD 8.0-BETA2 - 10.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:8 auto<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          cpe:/o:freebsd:freebsd:10 auto<br />
          <br />
          Fingerprint FreeBSD 8.0-CURRENT<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-RC1-p1<br />
          cpe:/o:freebsd:freebsd:8.0 auto<br />
          <br />
          Fingerprint FreeBSD 8.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.0 auto<br />
          <br />
          Fingerprint FreeBSD 8.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.0 auto<br />
          <br />
          Fingerprint FreeBSD 8.0-STABLE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-STABLE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-STABLE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.0-STABLE<br />
          cpe:/o:freebsd:freebsd:8.0<br />
          <br />
          Fingerprint FreeBSD 8.1<br />
          cpe:/o:freebsd:freebsd:8.1 auto<br />
          <br />
          Fingerprint FreeBSD 8.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.1<br />
          <br />
          Fingerprint FreeBSD 8.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.1<br />
          <br />
          Fingerprint FreeBSD 8.1-STABLE<br />
          cpe:/o:freebsd:freebsd:8.1<br />
          <br />
          Fingerprint FreeBSD 8.2<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 8.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 8.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.2<br />
          <br />
          Fingerprint FreeBSD 8.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 8.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 8.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 8.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 8.2-STABLE<br />
          cpe:/o:freebsd:freebsd:8.2<br />
          <br />
          Fingerprint FreeBSD 8.2-STABLE<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint FreeBSD 9<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-RELEASE - 10.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          cpe:/o:freebsd:freebsd:10 auto<br />
          <br />
          Fingerprint FreeBSD 9.0-STABLE<br />
          cpe:/o:freebsd:freebsd:9.0 auto<br />
          <br />
          Fingerprint FreeBSD 9.1-PRERELEASE<br />
          cpe:/o:freebsd:freebsd:9.1 auto<br />
          <br />
          Fingerprint FreeBSD 9.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.1 auto<br />
          <br />
          Fingerprint FreeBSD 9.1-RELEASE or 10.1-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.1 auto<br />
          cpe:/o:freebsd:freebsd:10.1<br />
          <br />
          Fingerprint FreeBSD 9.1-STABLE<br />
          cpe:/o:freebsd:freebsd:9.1 auto<br />
          <br />
          Fingerprint FreeBSD 9.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.2 auto<br />
          <br />
          Fingerprint FreeBSD 9.2-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.2 auto<br />
          <br />
          Fingerprint FreeBSD 9.2-RELEASE-p3<br />
          cpe:/o:freebsd:freebsd:9.2 auto<br />
          <br />
          Fingerprint FreeBSD 9.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.3 auto<br />
          <br />
          Fingerprint FreeBSD 9.3-RELEASE<br />
          cpe:/o:freebsd:freebsd:9.3 auto<br />
          <br />
          Fingerprint FreeBSD 9.2-RELEASE<br />
          cpe:/a:nas4free:nas4free<br />
          cpe:/o:freebsd:freebsd:9.2 auto<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 9.1 - 9.2)<br />
          cpe:/a:nas4free:nas4free<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 9.1)<br />
          cpe:/o:freebsd:freebsd:9.1<br />
          cpe:/a:nas4free:nas4free<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 9.1)<br />
          cpe:/a:nas4free:nas4free<br />
          cpe:/o:freebsd:freebsd:9.1 auto<br />
          <br />
          Fingerprint NAS4Free (FreeBSD 9.1-RELEASE - 10.3-RELEASE)<br />
          cpe:/o:freebsd:freebsd:9 auto<br />
          cpe:/o:freebsd:freebsd:10.3<br />
          cpe:/a:nas4free:nas4free<br />
          <br />
          Fingerprint Freecom SSLBBH NAS device<br />
          cpe:/h:freecom:sslbbh<br />
          <br />
          Fingerprint FreeNAS 0.671 (FreeBSD 6.1-STABLE)<br />
          cpe:/o:freenas:freenas:0.671 auto<br />
          cpe:/o:freebsd:freebsd:6.1<br />
          <br />
          Fingerprint FreeNAS 0.686 (FreeBSD 6.2-RELEASE)<br />
          cpe:/o:freenas:freenas:0.686 auto<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          <br />
          Fingerprint FreeNAS 0.686 (FreeBSD 6.2-RELEASE) or VMware ESXi Server
          3.0 - 4.0<br />
          cpe:/o:freenas:freenas:0.686<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          cpe:/o:vmware:esx:3<br />
          cpe:/o:vmware:esx:4<br />
          <br />
          Fingerprint FreeNAS 0.686b (FreeBSD 6.2-RELEASE)<br />
          cpe:/o:freenas:freenas:0.686b auto<br />
          cpe:/o:freebsd:freebsd:6.2<br />
          <br />
          Fingerprint FreeNAS 0.69 (FreeBSD 6.4-RELEASE-p3)<br />
          cpe:/o:freenas:freenas:0.69 auto<br />
          cpe:/o:freebsd:freebsd:6.4<br />
          <br />
          Fingerprint FreeNAS 0.69.1 (FreeBSD 6.4-RELEASE-p3)<br />
          cpe:/o:freenas:freenas:0.69.1 auto<br />
          cpe:/o:freebsd:freebsd:6.4 auto<br />
          <br />
          Fingerprint FreeNAS 0.69.2 (FreeBSD 6.3-STABLE - 6.4-RELEASE)<br />
          cpe:/o:freenas:freenas:0.62.2<br />
          cpe:/o:freebsd:freebsd:6 auto<br />
          <br />
          Fingerprint FreeNAS 0.69RC2 (FreeBSD 6.4-RELEASE)<br />
          cpe:/o:freenas:freenas:0.69rc2 auto<br />
          cpe:/o:freebsd:freebsd:6.4<br />
          <br />
          Fingerprint FreeNAS 0.7 (FreeBSD 7.2-RELEASE)<br />
          cpe:/o:freenas:freenas:0.7<br />
          cpe:/o:freebsd:freebsd:7.2<br />
          <br />
          Fingerprint FreeNAS 0.7 (FreeBSD 7.2-RELEASE-p4)<br />
          cpe:/o:freenas:freenas:0.7<br />
          cpe:/o:freebsd:freebsd:7.2 auto<br />
          <br />
          Fingerprint FreeNAS 0.7 - 0.7.2 (FreeBSD 7.2-RELEASE - 9.0-RELEASE)<br />
          cpe:/o:freenas:freenas:0.7<br />
          cpe:/o:freebsd:freebsd:7 auto<br />
          <br />
          Fingerprint FreeNAS 0.7.1 - 0.7.2 (FreeBSD 7.3-RELEASE)<br />
          cpe:/o:freenas:freenas:0.7<br />
          cpe:/o:freebsd:freebsd:7.3 auto<br />
          <br />
          Fingerprint FreeNAS 0.7.2 (FreeBSD 7.3-RELEASE)<br />
          cpe:/o:freenas:freenas:0.7.2 auto<br />
          cpe:/o:freebsd:freebsd:7.3 auto<br />
          <br />
          Fingerprint FreeNAS 9.10 (FreeBSD 10.3-STABLE)<br />
          cpe:/o:freenas:freenas:9.10 auto<br />
          cpe:/o:freebsd:freebsd:10.3 auto<br />
          <br />
          Fingerprint FreeNAS (FreeBSD 6.4-RELEASE-p3)<br />
          cpe:/o:freenas:freenas auto<br />
          cpe:/o:freebsd:freebsd:6.4 auto<br />
          <br />
          Fingerprint FreeNAS (FreeBSD 8.2-RELEASE)<br />
          cpe:/o:freenas:freenas auto<br />
          cpe:/o:freebsd:freebsd:8.2 auto<br />
          <br />
          Fingerprint Fronius Datalogger Web<br />
          cpe:/h:fronius:datalogger_web<br />
          <br />
          Fingerprint Fuji Xerox ApeosPort IV C2275 printer<br />
          cpe:/h:fujixerox:apeosport_iv_c2275 auto<br />
          <br />
          Fingerprint Fuji Xerox Document Centre 706 CP printer<br />
          cpe:/h:fujixerox:document_centre_706_cp<br />
          <br />
          Fingerprint Fuji Xerox DocuPrint C525 A printer<br />
          cpe:/h:fujixerox:docuprint_c525_a<br />
          <br />
          Fingerprint Fuji Xerox DocuPrint CM205 printer<br />
          cpe:/h:fujixerox:docuprint_cm205 auto<br />
          <br />
          Fingerprint Fujian Ruijie Star-S2800 switch<br />
          cpe:/h:fujianruijie:star-s2800 auto<br />
          <br />
          Fingerprint Fujitsu Siemens BS2000/OSD<br />
          cpe:/o:fujitsu:bs2000_osd auto<br />
          <br />
          Fingerprint Fujitsu Siemens ReliantUNIX-N (SINIX-N) on RM400<br />
          cpe:/o:fujitsu:reliantunix auto<br />
          <br />
          Fingerprint Funkwerk bintec R3000 router<br />
          cpe:/h:funkwerk:bintec_r3000 auto<br />
          <br />
          Fingerprint Funkwerk Artem WI3040 WAP<br />
          cpe:/h:funkwerk:artem_wi3040 auto<br />
          <br />
          Fingerprint GalaxyMetalGear 3507LR-SA NAS device<br />
          cpe:/h:galaxymetalgear:3507lr-sa auto<br />
          <br />
          Fingerprint Garmin Virb Elite action camera<br />
          cpe:/h:garmin:virb_elite<br />
          <br />
          Fingerprint Geist Watchdog 1250 atmospheric monitor<br />
          cpe:/h:geist:watchdog_1250<br />
          <br />
          Fingerprint Gemtek P360 WAP or Siemens Gigaset SE515dsl wireless
          broadband router<br />
          cpe:/h:gemtek:p360 auto<br />
          cpe:/h:siemens:gigaset_se515dsl auto<br />
          <br />
          Fingerprint Micropython 1.8<br />
          cpe:/o:george_robotics:micropython:1.8<br />
          <br />
          Fingerprint Geovision EBD4700 CCTV camera (Linux 3.4)<br />
          cpe:/h:geovision:ebd4700<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint GlobespanVirata GS8100, Huawei MT880, or Solwise SAR 100
          ADSL modem<br />
          cpe:/h:globespanvirate:gs8100<br />
          cpe:/h:huawei:mt880<br />
          cpe:/h:solwise:sar_100<br />
          <br />
          Fingerprint GNU Hurd 0.3<br />
          cpe:/o:gnu:hurd auto<br />
          <br />
          Fingerprint GNU Hurd 0.3<br />
          cpe:/o:gnu:hurd auto<br />
          <br />
          Fingerprint Android 1.1 (Linux 2.6.25)<br />
          cpe:/o:google:android:1.1 auto<br />
          cpe:/o:linux:linux_kernel:2.6.25<br />
          cpe:/o:google:android:1.1<br />
          <br />
          Fingerprint Android 1.5 (Linux 2.6.27)<br />
          cpe:/o:google:android:1.5 auto<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          cpe:/o:google:android:1.5<br />
          <br />
          Fingerprint Android 1.5 - 1.6 (Linux 2.6.27)<br />
          cpe:/o:google:android:1 auto<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          cpe:/o:google:android:1<br />
          <br />
          Fingerprint Android 2.0.1 (Linux 2.6)<br />
          cpe:/o:google:android:2.0.1 auto<br />
          cpe:/o:google:android:2.0.1<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.1-update1 (Linux 2.6.29)<br />
          cpe:/o:google:android:2.1 auto<br />
          cpe:/o:linux:linux_kernel:2.6.29<br />
          cpe:/o:google:android:2.1:rev1<br />
          <br />
          Fingerprint Android 2.1-update1 (Linux 2.6.29)<br />
          cpe:/o:google:android:2.1 auto<br />
          cpe:/o:linux:linux_kernel:2.6.29<br />
          cpe:/o:google:android:2.1:rev1<br />
          <br />
          Fingerprint Android 2.2 (Linux 2.6)<br />
          cpe:/o:google:android:2.2 auto<br />
          cpe:/o:google:android:2.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.2 (Linux 2.6)<br />
          cpe:/o:google:android:2.2 auto<br />
          cpe:/o:google:android:2.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.2 (Linux 2.6.32)<br />
          cpe:/o:google:android:2.2 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          cpe:/o:google:android:2.2<br />
          <br />
          Fingerprint Android 2.2 (Linux 2.6.32)<br />
          cpe:/o:google:android:2.2 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          cpe:/o:google:android:2.2<br />
          <br />
          Fingerprint Android 2.2 - 2.2.1 (Linux 2.6.32)<br />
          cpe:/o:google:android:2 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          cpe:/o:google:android:2<br />
          <br />
          Fingerprint Android 2.2 - 2.3.3 (Linux 2.6.32 - 2.6.37)<br />
          cpe:/o:google:android:2 auto<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          cpe:/o:google:android:2<br />
          <br />
          Fingerprint Android 2.2 - 2.3.5 (Linux 2.6.32 - 2.6.36)<br />
          cpe:/o:google:android:2 auto<br />
          cpe:/o:google:android:2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.3.4 (Linux 2.6)<br />
          cpe:/o:google:android:2.3.4 auto<br />
          cpe:/o:google:android:2.3.4<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.3.5 (Linux 2.6)<br />
          cpe:/o:google:android:2.3.5<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.3.5 (Linux 2.6.35)<br />
          cpe:/o:google:android:2.3.5 auto<br />
          cpe:/o:google:android:2<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint Android 2.3.5 - 2.3.7 (Linux 2.6.35)<br />
          cpe:/o:google:android:2 auto<br />
          cpe:/o:google:android:2<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint Android 2.3.6 (Linux 2.6.35)<br />
          cpe:/o:google:android:2.3.6 auto<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint Android 2.3.7 (Linux 2.6)<br />
          cpe:/o:google:android:2.3.7<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 2.3.7 (Linux 2.6.37)<br />
          cpe:/o:google:android:2.3.7 auto<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          cpe:/o:google:android:2<br />
          <br />
          Fingerprint Android 3 (Linux 2.6.36)<br />
          cpe:/o:google:android:3 auto<br />
          cpe:/o:linux:linux_kernel:2.6.36<br />
          <br />
          Fingerprint Android 4.0.1 - 4.0.4 (Linux 3.0)<br />
          cpe:/o:google:android:4.0 auto<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Android 4.0.4 (Linux 2.6)<br />
          cpe:/o:google:android:4.0.4<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Android 4.0.4 (Linux 3.0)<br />
          cpe:/o:google:android:4.0.4 auto<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Android 4.0.4 (Linux 3.0.8)<br />
          cpe:/o:google:android:4.0.4 auto<br />
          cpe:/o:linux:linux_kernel:3.0.8 auto<br />
          <br />
          Fingerprint Android 4.1.1<br />
          cpe:/o:google:android:4.1.1 auto<br />
          <br />
          Fingerprint Android 4.1<br />
          cpe:/o:google:android:4.1 auto<br />
          <br />
          Fingerprint Android 4.1 (Linux 3.0)<br />
          cpe:/o:google:android:4.1 auto<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Android 4.1.1<br />
          cpe:/o:google:android:4.1<br />
          <br />
          Fingerprint Android 4.1.2<br />
          cpe:/o:google:android:4.1.2<br />
          <br />
          Fingerprint Cisco CP-DX650 VoIP phone (Android 4.1.1)<br />
          cpe:/o:google:android:4.1.1 auto<br />
          cpe:/h:cisco:cp-dx650 auto<br />
          <br />
          Fingerprint Android 4.2.1 (Linux 3.0)<br />
          cpe:/o:google:android:4.2.1 auto<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Android 4.2.2 (Linux 3.4)<br />
          cpe:/o:google:android:4.2.2 auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Android 4.2.2 (Linux 3.4)<br />
          cpe:/o:google:android:4.2.2<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Android 4.2.2 (Linux 3.4)<br />
          cpe:/o:google:android:4.2.2 auto<br />
          <br />
          Fingerprint Android 4.3<br />
          cpe:/o:google:android:4.3 auto<br />
          <br />
          Fingerprint Android 4.3<br />
          cpe:/o:google:android:4.3 auto<br />
          <br />
          Fingerprint Android 4.4.0<br />
          cpe:/o:google:android:4.4.0 auto<br />
          <br />
          Fingerprint Android 4.4.2<br />
          cpe:/o:google:android:4.4.2 auto<br />
          <br />
          Fingerprint Android 4<br />
          cpe:/o:google:android:4 auto<br />
          <br />
          Fingerprint Android 4<br />
          cpe:/o:google:android:4 auto<br />
          <br />
          Fingerprint Android 4.0<br />
          cpe:/o:google:android:4.0 auto<br />
          <br />
          Fingerprint Android 4.1 - 6.0 (Linux 3.4 - 3.14)<br />
          cpe:/o:google:android:4 auto<br />
          cpe:/o:google:android:5 auto<br />
          cpe:/o:google:android:6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Android 4.2.2 (Linux 3.4)<br />
          cpe:/o:google:android:4.2.2 auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Android 4.4 (Linux 3.10)<br />
          cpe:/o:google:android:4.4 auto<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Sony Android TV (Android 5.0)<br />
          cpe:/o:google:android:5.0 auto<br />
          <br />
          Fingerprint Android 5.0 - 6.0.1 (Linux 3.4)<br />
          cpe:/o:google:android:5<br />
          cpe:/o:google:android:6 auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Android 5.0 - 7.0 (Linux 3.4 - 3.10)<br />
          cpe:/o:google:android:5 auto<br />
          cpe:/o:google:android:6 auto<br />
          cpe:/o:google:android:7 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Android 5.0.1<br />
          cpe:/o:google:android:5.0.1<br />
          <br />
          Fingerprint Android 5.0.1<br />
          cpe:/o:google:android:5.0.1 auto<br />
          <br />
          Fingerprint Android 5.0.1 (Linux 3.10)<br />
          cpe:/o:google:android:5.0.1 auto<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Android 5.1<br />
          cpe:/o:google:android:5.1 auto<br />
          <br />
          Fingerprint Android 5.1<br />
          cpe:/o:google:android:5.1 auto<br />
          <br />
          Fingerprint Android 5.1<br />
          cpe:/o:google:android:5.1 auto<br />
          <br />
          Fingerprint Android 5.1.1<br />
          cpe:/o:google:android:5.1.1 auto<br />
          <br />
          Fingerprint Android 6.0 - 7.1.2 (Linux 3.18 - 4.4.1)<br />
          cpe:/o:google:android:6 auto<br />
          cpe:/o:google:android:7 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Android 6.0.1<br />
          cpe:/o:google:android:6.0.1 auto<br />
          <br />
          Fingerprint Android 6.0.1 (Linux 3.10)<br />
          cpe:/o:google:android:6.0.1 auto<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Android 6.0.1 (Linux 3.18)<br />
          cpe:/o:google:android:6.0.1 auto<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Android 7.0 (Linux 3.18)<br />
          cpe:/o:google:android:7.0 auto<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Android 7.1.1 - 7.1.2<br />
          cpe:/o:google:android:7 auto<br />
          <br />
          Fingerprint Android 7.1.2 (Linux 3.10)<br />
          cpe:/o:google:android:7.1.2 auto<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Android 7.1.2 (Linux 3.4)<br />
          cpe:/o:google:android:7.1.2 auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Google Nexus Player<br />
          cpe:/o:google:android auto<br />
          <br />
          Fingerprint GoPro HERO3 camera<br />
          cpe:/h:gopro:hero3<br />
          <br />
          Fingerprint GoPro Wifi-Bacpac camera<br />
          cpe:/h:gopro:wifi-bacpac<br />
          <br />
          Fingerprint Grandstream BudgeTone 100 VoIP phone<br />
          cpe:/h:grandstream:budgetone_100 auto<br />
          <br />
          Fingerprint Grandstream BudgeTone 100 VoIP phone<br />
          cpe:/h:grandstream:budgetone_100 auto<br />
          <br />
          Fingerprint Grandstream GXP1105 VoIP phone<br />
          cpe:/h:grandstream:gxp1105 auto<br />
          <br />
          Fingerprint Grandstream GXP1400 VoIP phone<br />
          cpe:/h:grandstream:gxp1400 auto<br />
          <br />
          Fingerprint Grandstream GXP1405 VoIP phone or HP LaserJet 2430
          printer<br />
          cpe:/h:grandstream:gxp1405 auto<br />
          cpe:/h:hp:laserjet_2430 auto<br />
          <br />
          Fingerprint Grandstream GXP2000 VoIP phone<br />
          cpe:/h:grandstream:gxp2000 auto<br />
          <br />
          Fingerprint Grandstream GXP2020 VoIP phone<br />
          cpe:/h:grandstream:gxp2020 auto<br />
          <br />
          Fingerprint Grandstream GXV3000 VoIP phone<br />
          cpe:/h:grandstream:gxv3000 auto<br />
          <br />
          Fingerprint Grandstream GXV3275 video phone<br />
          cpe:/h:grandstream:gxv3275<br />
          <br />
          Fingerprint Green Hills RTOS<br />
          cpe:/o:greenhills:rtos auto<br />
          <br />
          Fingerprint H3C E126A or S3100-8T-SI switch (Comware 3.10)<br />
          cpe:/h:h3c:e126a<br />
          cpe:/h:h3c:s3100-8t-si<br />
          cpe:/o:h3c:comware:3.10<br />
          <br />
          Fingerprint H3C Comware 5.20<br />
          cpe:/o:h3c:comware:5.20<br />
          <br />
          Fingerprint Haiku R1 Alpha 3<br />
          cpe:/o:haiku:haiku:r1:alpha_3<br />
          <br />
          Fingerprint Haiku R1 Alpha 4<br />
          cpe:/o:haiku:haiku:r1 auto<br />
          <br />
          Fingerprint Haiku R1 Alpha 4.1<br />
          cpe:/o:haiku:haiku:r1 auto<br />
          <br />
          Fingerprint Hawking PN7127P print server<br />
          cpe:/h:hawking:pn7127p auto<br />
          <br />
          Fingerprint Hawking PS12U, Repotech RP-1805B-V2, or TRENDnet TE100
          P2U1P print server<br />
          cpe:/h:hawking:ps12u<br />
          cpe:/h:repotech:rp-1805b-v2<br />
          cpe:/h:trendnet:te100_p2u1p auto<br />
          <br />
          Fingerprint HID EdgePlus Solo ES400 firewall<br />
          cpe:/h:hid:edgeplus_solo_es400 auto<br />
          <br />
          Fingerprint Hirschmann L2E Rail switch<br />
          cpe:/h:hirschmann:l2e auto<br />
          <br />
          Fingerprint Hirschmann MACH switch<br />
          cpe:/h:hirschmann:mach<br />
          <br />
          Fingerprint Hirschmann RS2-16M 2MM SC switch<br />
          cpe:/h:hirschmann:rs2-16m auto<br />
          <br />
          Fingerprint Hirschmann RS20 switch (VxWorks)<br />
          cpe:/h:hirschmann:rs20 auto<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Hitron BWG-35302 cable modem<br />
          cpe:/h:hitron:bwg-35302 auto<br />
          <br />
          Fingerprint Hitron CVE-30360 router<br />
          cpe:/h:hitron:cve-30360 auto<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint HP FlexFabric 5900CP switch (Comware 7.1)<br />
          cpe:/o:hp:comware:7.1 auto<br />
          <br />
          Fingerprint HP 170X print server or Inkjet 3000 printer<br />
          cpe:/h:hp:jetdirect_170x<br />
          cpe:/h:hp:inkjet_3000<br />
          <br />
          Fingerprint HP 2101nw wireless print server<br />
          cpe:/h:hp:2101nw auto<br />
          <br />
          Fingerprint HP JetDirect 175x print server or 9100c Digital Sender
          printer<br />
          cpe:/h:hp:jetdirect_175x<br />
          cpe:/h:hp:9100c_digital_sender<br />
          <br />
          Fingerprint HP JetDirect 2591A print server<br />
          cpe:/h:hp:jetdirect_2591a<br />
          <br />
          Fingerprint HP JetDirect 635n print server<br />
          cpe:/h:hp:jetdirect_635n<br />
          <br />
          Fingerprint HP LaserJet 1320 printer<br />
          cpe:/h:hp:laserjet_1320<br />
          <br />
          Fingerprint HP DesignJet 500, LaserJet 8100, or LaserJet 4050n
          printer<br />
          cpe:/h:hp:designjet_500<br />
          cpe:/h:hp:laserjet_8100<br />
          cpe:/h:hp:laserjet_4050n<br />
          <br />
          Fingerprint HP DesignJet 650C printer<br />
          cpe:/h:hp:designjet_650c auto<br />
          <br />
          Fingerprint HP Deskjet 6940 printer<br />
          cpe:/h:hp:deskjet_6940 auto<br />
          <br />
          Fingerprint HP ENVY 4500 printer<br />
          cpe:/h:hp:envy_4500 auto<br />
          <br />
          Fingerprint HP JetDirect J8013E printer<br />
          cpe:/h:hp:jetdirect_j8013e auto<br />
          <br />
          Fingerprint HP LaserJet (1020-, 2010-, 2600-, 2800-, 3050-, or
          3390-series), or Brother (DCP-375CW, HL-5250DN, HL-22700W, MFC-7840N,
          MFC-8860DN, or MFC-9970CDW) printer; or Sony SNC-RZ30N network
          camera<br />
          cpe:/h:sony:snc-rz30n<br />
          <br />
          Fingerprint HP LaserJet 1017 printer<br />
          cpe:/h:hp:laserjet_1017 auto<br />
          <br />
          Fingerprint HP LaserJet 1022n printer<br />
          cpe:/h:hp:laserjet_1022n auto<br />
          <br />
          Fingerprint HP LaserJet 1200 printer<br />
          cpe:/h:hp:laserjet_1200 auto<br />
          <br />
          Fingerprint HP LaserJet 1300n, 1320nw, or 3550 printer<br />
          cpe:/h:hp:laserjet_1320nw<br />
          cpe:/h:hp:laserjet_1300n<br />
          cpe:/h:hp:laserjet_3550<br />
          <br />
          Fingerprint HP LaserJet 1320 printer<br />
          cpe:/h:hp:laserjet_1320<br />
          <br />
          Fingerprint HP LaserJet 2200DN printer<br />
          cpe:/h:hp:laserjet_2200dn auto<br />
          <br />
          Fingerprint HP LaserJet 2200dtn printer<br />
          cpe:/h:hp:laserjet_2200dtn auto<br />
          <br />
          Fingerprint HP LaserJet 2200dtn printer<br />
          cpe:/h:hp:laserjet_2200dtn auto<br />
          <br />
          Fingerprint HP LaserJet 2300N printer<br />
          cpe:/h:hp:laserjet_2300n auto<br />
          <br />
          Fingerprint HP LaserJet 2420 printer<br />
          cpe:/h:hp:laserjet_2420 auto<br />
          <br />
          Fingerprint HP LaserJet 2420 printer<br />
          cpe:/h:hp:laserjet_2420 auto<br />
          <br />
          Fingerprint HP LaserJet 2420N printer<br />
          cpe:/h:hp:laserjet_2420n auto<br />
          <br />
          Fingerprint HP LaserJet 2600n printer<br />
          cpe:/h:hp:laserjet_2600n auto<br />
          <br />
          Fingerprint HP LaserJet 2600n printer<br />
          cpe:/h:hp:laserjet_2600n auto<br />
          <br />
          Fingerprint HP LaserJet 2600n printer<br />
          cpe:/h:hp:laserjet_2600n auto<br />
          <br />
          Fingerprint HP LaserJet 2600n printer<br />
          cpe:/h:hp:laserjet_2600n<br />
          <br />
          Fingerprint HP LaserJet 2600n printer<br />
          cpe:/h:hp:laserjet_2600n auto<br />
          <br />
          Fingerprint HP LaserJet 2605dn printer<br />
          cpe:/h:hp:laserjet_2605dn auto<br />
          <br />
          Fingerprint HP LaserJet 2840 printer<br />
          cpe:/h:hp:laserjet_2840 auto<br />
          <br />
          Fingerprint HP LaserJet 3600 printer<br />
          cpe:/h:hp:laserjet_3600 auto<br />
          <br />
          Fingerprint HP LaserJet 3600n printer<br />
          cpe:/h:hp:laserjet_3600n auto<br />
          <br />
          Fingerprint HP LaserJet 3800, 4250, 4345, 9040 printer<br />
          cpe:/h:hp:laserjet_3800<br />
          cpe:/h:hp:laserjet_4250<br />
          cpe:/h:hp:laserjet_4345<br />
          cpe:/h:hp:laserjet_9040<br />
          <br />
          Fingerprint HP LaserJet 4000 printer<br />
          cpe:/h:hp:laserjet_4000<br />
          <br />
          Fingerprint HP LaserJet 4014 printer<br />
          cpe:/h:hp:laserjet_4014 auto<br />
          <br />
          Fingerprint HP LaserJet 4050 printer<br />
          cpe:/h:hp:laserjet_4050 auto<br />
          <br />
          Fingerprint HP LaserJet 4100 printer<br />
          cpe:/h:hp:laserjet_4100 auto<br />
          <br />
          Fingerprint HP LaserJet 4100N printer<br />
          cpe:/h:hp:laserjet_4100n auto<br />
          <br />
          Fingerprint HP LaserJet 4100N printer<br />
          cpe:/h:hp:laserjet_4100n auto<br />
          <br />
          Fingerprint HP LaserJet 4200 printer<br />
          cpe:/h:hp:laserjet_4200 auto<br />
          <br />
          Fingerprint HP LaserJet 4250 printer<br />
          cpe:/h:hp:laserjet_4250<br />
          <br />
          Fingerprint HP LaserJet 4250 printer<br />
          cpe:/h:hp:laserjet_4250 auto<br />
          <br />
          Fingerprint HP LaserJet 4250 printer<br />
          cpe:/h:hp:laserjet_4250 auto<br />
          <br />
          Fingerprint HP LaserJet 4250 printer<br />
          cpe:/h:hp:laserjet_4250 auto<br />
          <br />
          Fingerprint HP LaserJet 4300 printer<br />
          cpe:/h:hp:laserjet_4300 auto<br />
          <br />
          Fingerprint HP LaserJet 4350 printer<br />
          cpe:/h:hp:laserjet_4350 auto<br />
          <br />
          Fingerprint HP LaserJet 5 printer<br />
          cpe:/h:hp:laserjet_5 auto<br />
          <br />
          Fingerprint HP LaserJet 5500dtn or 5550 printer<br />
          cpe:/h:hp:laserjet_5500dtn auto<br />
          cpe:/h:hp:laserjet_5550 auto<br />
          <br />
          Fingerprint HP LaserJet 5550 printer<br />
          cpe:/h:hp:laserjet_5550 auto<br />
          <br />
          Fingerprint HP LaserJet 5M printer<br />
          cpe:/h:hp:laserjet_5m auto<br />
          <br />
          Fingerprint HP LaserJet 5M printer<br />
          cpe:/h:hp:laserjet_5m auto<br />
          <br />
          Fingerprint HP LaserJet 8000 printer<br />
          cpe:/h:hp:laserjet_8000 auto<br />
          <br />
          Fingerprint HP LaserJet CM6040 printer<br />
          cpe:/h:hp:laserjet_cm6040 auto<br />
          <br />
          Fingerprint HP LaserJet CM6040 printer<br />
          cpe:/h:hp:laserjet_cm6040 auto<br />
          <br />
          Fingerprint HP LaserJet CP1025nw, 1102w, M1212nf, M1217nfw, P1120w, or
          P1606dn printer<br />
          cpe:/h:hp:laserjet_cp1025nw<br />
          cpe:/h:hp:laserjet_1102w<br />
          cpe:/h:hp:laserjet_m1212nf<br />
          cpe:/h:hp:laserjet_m1217nfw<br />
          cpe:/h:hp:laserjet_p1120w<br />
          cpe:/h:hp:laserjet_p1606dn<br />
          <br />
          Fingerprint HP Laserjet CP1515n printer<br />
          cpe:/h:hp:laserjet_cp1515n auto<br />
          <br />
          Fingerprint HP LaserJet CP1518ni printer<br />
          cpe:/h:hp:laserjet_cp1518ni auto<br />
          <br />
          Fingerprint HP LaserJet CP2025dn printer<br />
          cpe:/h:hp:laserjet_cp2025dn auto<br />
          <br />
          Fingerprint HP LaserJet CP2025dn printer<br />
          cpe:/h:hp:laserjet_cp2025dn auto<br />
          <br />
          Fingerprint HP LaserJet CP3505 or 4350 printer<br />
          cpe:/h:hp:laserjet_cp3505 auto<br />
          cpe:/h:hp:laserjet_4350 auto<br />
          <br />
          Fingerprint HP LaserJet CP5520 printer<br />
          cpe:/h:hp:laserjet_cp5520<br />
          <br />
          Fingerprint HP LaserJet M1522nf printer<br />
          cpe:/h:hp:laserjet_m1522nf auto<br />
          <br />
          Fingerprint HP LaserJet M375nw printer<br />
          cpe:/h:hp:laserjet_m375nw auto<br />
          <br />
          Fingerprint HP LaserJet M375nw printer<br />
          cpe:/h:hp:laserjet_m375nw auto<br />
          <br />
          Fingerprint HP LaserJet M425dn or M2727nf printer<br />
          cpe:/h:hp:laserjet_m425dn<br />
          cpe:/h:hp:laserjet_m2727nf<br />
          <br />
          Fingerprint HP LaserJet M451dn, CM1415fnw, or CP4525<br />
          cpe:/h:hp:laserjet_cp4525<br />
          cpe:/h:hp:laserjet_m451dn<br />
          <br />
          Fingerprint HP LaserJet M476dw printer<br />
          cpe:/h:hp:laserjet_m476dw auto<br />
          <br />
          Fingerprint HP LaserJet M525 printer<br />
          cpe:/h:hp:laserjet_m525 auto<br />
          <br />
          Fingerprint HP LaserJet M605 printer<br />
          cpe:/h:hp:laserjet_m605 auto<br />
          <br />
          Fingerprint HP LaserJet P1102w printer<br />
          cpe:/h:hp:laserjet_p1102w auto<br />
          <br />
          Fingerprint HP LaserJet P1606dn printer<br />
          cpe:/h:hp:laserjet_p1606dn<br />
          <br />
          Fingerprint HP LaserJet P1606dn printer<br />
          cpe:/h:hp:laserjet_p1606dn auto<br />
          <br />
          Fingerprint HP LaserJet P1606dn printer<br />
          cpe:/h:hp:laserjet_p1606dn auto<br />
          <br />
          Fingerprint HP LaserJet P1606dn printer<br />
          cpe:/h:hp:laserjet_p1606dn auto<br />
          <br />
          Fingerprint HP LaserJet P2015 printer<br />
          cpe:/h:hp:laserjet_p2015 auto<br />
          <br />
          Fingerprint HP LaserJet P2035n printer<br />
          cpe:/h:hp:laserjet_p2035n<br />
          <br />
          Fingerprint HP LaserJet P2055dn printer<br />
          cpe:/h:hp:laserjet_p2055dn auto<br />
          <br />
          Fingerprint HP LaserJet P2055x printer<br />
          cpe:/h:hp:laserjet_p2055x auto<br />
          <br />
          Fingerprint HP LaserJet P3005 printer<br />
          cpe:/h:hp:laserjet_p3005 auto<br />
          <br />
          Fingerprint HP LaserJet P3010 printer<br />
          cpe:/h:hp:laserjet_p3010 auto<br />
          <br />
          Fingerprint HP LaserJet P3015 printer<br />
          cpe:/h:hp:laserjet_p3015 auto<br />
          <br />
          Fingerprint HP LaserJet Pro 200 M275NW printer<br />
          cpe:/h:hp:laserjet_pro_200_m275nw auto<br />
          <br />
          Fingerprint HP Officejet J4680 printer<br />
          cpe:/h:hp:officejet_j4680 auto<br />
          <br />
          Fingerprint HP Officejet J6480 printer<br />
          cpe:/h:hp:officejet_j6480 auto<br />
          <br />
          Fingerprint HP Officejet Pro 8500 printer<br />
          cpe:/h:hp:officejet_pro_8500 auto<br />
          <br />
          Fingerprint HP Officejet Pro 8500 printer<br />
          cpe:/h:hp:officejet_pro_8500 auto<br />
          <br />
          Fingerprint HP Officejet Pro 8500 printer<br />
          cpe:/h:hp:officejet_pro_8500 auto<br />
          <br />
          Fingerprint HP Photosmart 5520 printer<br />
          cpe:/h:hp:photosmart_5520 auto<br />
          <br />
          Fingerprint HP Photosmart 8400 printer<br />
          cpe:/h:hp:photosmart_8400 auto<br />
          <br />
          Fingerprint HP Photosmart 8750 printer<br />
          cpe:/h:hp:photosmart_8750 auto<br />
          <br />
          Fingerprint HP PhotoSmart C390 or C4780; or Officejet 6500, 7000, or
          8500 printer<br />
          cpe:/h:hp:photosmart_c390<br />
          cpe:/h:hp:photosmart_c4780<br />
          cpe:/h:hp:officejet_6500<br />
          cpe:/h:hp:officejet_7000<br />
          cpe:/h:hp:officejet_8500<br />
          <br />
          Fingerprint HP Photosmart C4380 printer<br />
          cpe:/h:hp:photosmart_c4380 auto<br />
          <br />
          Fingerprint Kaiomy AL-2014PW, Planet ADE-4110, or Siemens C-110 ADSL
          modem; HP LaserJet 2420 printer; or IBM DF-4000 ProFibre Storage
          Array<br />
          cpe:/h:hp:laserjet_2420 auto<br />
          cpe:/h:ibm:df-4000<br />
          cpe:/h:kaiomy:al-2014pw<br />
          cpe:/h:planet:ade-4110<br />
          cpe:/h:siemens:c-110 auto<br />
          <br />
          Fingerprint HP MSM765zl WLAN controller<br />
          cpe:/h:hp:msm765zl<br />
          <br />
          Fingerprint HP Onboard Administrator 2.04<br />
          cpe:/a:hp:onboard_administrator:2.04<br />
          <br />
          Fingerprint HP Onboard Administrator 2.25 - 3.31<br />
          cpe:/a:hp:onboard_administrator<br />
          <br />
          Fingerprint HP Onboard Administrator 4.01<br />
          cpe:/a:hp:onboard_administrator:4.01<br />
          <br />
          Fingerprint HP ProCurve Secure Router 7102dl<br />
          cpe:/h:hp:procurve_7102dl<br />
          <br />
          Fingerprint HP E1200 Network Storage Router NAS device or SonicWALL
          SOHO3 firewall<br />
          cpe:/h:hp:e1200<br />
          cpe:/h:sonicwall:soho3 auto<br />
          <br />
          Fingerprint HP MSL4048 tape library<br />
          cpe:/h:hp:storageworks_msl4048<br />
          <br />
          Fingerprint HP P2000 G3 FC/iSCSI controller<br />
          cpe:/h:hp:storageworks_p2000_g3_msa_fc%2fiscsi_dual_combo_controller_lff_array_system<br />
          <br />
          Fingerprint HP P2000 G3 NAS device<br />
          cpe:/h:hp:p2000_g3 auto<br />
          <br />
          Fingerprint HP StorageWorks MSL4048 tape library<br />
          cpe:/h:hp:storageworks_msl4048<br />
          <br />
          Fingerprint HP 1905 switch<br />
          cpe:/h:hp:1905 auto<br />
          <br />
          Fingerprint HP 1905 switch<br />
          cpe:/h:hp:1905 auto<br />
          <br />
          Fingerprint HP 2424M ProCurve switch (J4093A)<br />
          cpe:/h:hp:procurve_switch_2424m<br />
          <br />
          Fingerprint HP 2530, 2920, or 5406zl switch<br />
          cpe:/h:hp:switch_2530<br />
          cpe:/h:hp:switch_2920<br />
          cpe:/h:hp:switch_5406zl<br />
          <br />
          Fingerprint HP 2920 or 3800 switch<br />
          cpe:/h:hp:2920<br />
          cpe:/h:hp:3800<br />
          <br />
          Fingerprint HP 4000M ProCurve switch (J4121A)<br />
          cpe:/h:hp:procurve_switch_4000m<br />
          <br />
          Fingerprint HP 4108GL ProCurve switch<br />
          cpe:/h:hp:procurve_switch_4108gl<br />
          <br />
          Fingerprint HP 5406zl switch<br />
          cpe:/h:hp:5406zl auto<br />
          <br />
          Fingerprint HP Brocade 1600 switch<br />
          cpe:/h:hp:brocade_1600 auto<br />
          <br />
          Fingerprint HP E3500yl or ProCurve 5406zl switch<br />
          cpe:/h:hp:switch_e3500yl<br />
          cpe:/h:hp:procurve_switch_5406zl auto<br />
          <br />
          Fingerprint HP GbE2c Ethernet Blade switch<br />
          cpe:/h:hp:gbe2c<br />
          <br />
          Fingerprint HP GbW2c Ethernet Blade Switch<br />
          cpe:/h:hp:gbe2c<br />
          <br />
          Fingerprint HP ProCurve 1810G switch<br />
          cpe:/h:hp:procurve_switch_1810g auto<br />
          <br />
          Fingerprint HP ProCurve 2510 switch<br />
          cpe:/h:hp:procurve_switch_2510 auto<br />
          <br />
          Fingerprint HP ProCurve 2520, 2915, 3500yl, or 5400zl-series switch<br />
          cpe:/h:hp:procurve_switch_2520<br />
          cpe:/h:hp:procurve_switch_3500yl<br />
          cpe:/h:hp:procurve_switch_5400zl<br />
          cpe:/h:hp:procurve_switch_5412zl<br />
          <br />
          Fingerprint HP ProCurve 2524 switch<br />
          cpe:/h:hp:procurve_switch_2524 auto<br />
          <br />
          Fingerprint HP ProCurve 2524 switch<br />
          cpe:/h:hp:procurve_switch_2524 auto<br />
          <br />
          Fingerprint HP ProCurve 2524 switch<br />
          cpe:/h:hp:procurve_switch_2524 auto<br />
          <br />
          Fingerprint HP ProCurve 2524 switch or 9100c Digital Sender printer<br />
          cpe:/h:hp:procurve_switch_2524<br />
          cpe:/h:hp:9100c_digital_sender<br />
          <br />
          Fingerprint HP ProCurve 2524 switch, HP JetDirect 175x print server,
          or Symmetricon NTS-150 time server<br />
          cpe:/h:hp:procurve_switch_2524<br />
          cpe:/h:hp:jetdirect_175x<br />
          cpe:/h:symmetricon:nts-150<br />
          <br />
          Fingerprint HP ProCurve 2610 switch<br />
          cpe:/h:hp:procurve_switch_2610 auto<br />
          <br />
          Fingerprint HP ProCurve 2650 switch<br />
          cpe:/h:hp:procurve_switch_2650 auto<br />
          <br />
          Fingerprint HP ProCurve 2650 switch<br />
          cpe:/h:hp:procurve_switch_2650 auto<br />
          <br />
          Fingerprint HP ProCurve 2650 switch or Konica Minolta bizhub C450
          printer<br />
          cpe:/h:hp:procurve_switch_2650 auto<br />
          cpe:/h:konicaminolta:bizhub_c450 auto<br />
          <br />
          Fingerprint HP ProCurve 2910al switch<br />
          cpe:/h:hp:procurve_switch_2910al auto<br />
          <br />
          Fingerprint HP ProCurve 2910al switch<br />
          cpe:/h:hp:procurve_switch_2910al auto<br />
          <br />
          Fingerprint HP ProCurve 2910al switch<br />
          cpe:/h:hp:procurve_switch_2910al auto<br />
          <br />
          Fingerprint HP ProCurve 3500yl switch<br />
          cpe:/h:hp:procurve_switch_3500yl auto<br />
          <br />
          Fingerprint HP ProCurve 3500yl, 5406zl, or 6200yl switch or UTStarcom
          F1000 VoIP phone<br />
          cpe:/h:utstarcom:f1000 auto<br />
          <br />
          Fingerprint HP ProCurve 3500yl, 5412zl, or 8212zl switch<br />
          cpe:/h:hp:procurve_switch_3500yl<br />
          cpe:/h:hp:procurve_switch_5412zl<br />
          cpe:/h:hp:procurve_switch_8212zl<br />
          <br />
          Fingerprint HP ProCurve 4000M switch (J4121A)<br />
          cpe:/h:hp:procurve_switch_4000m auto<br />
          <br />
          Fingerprint HP ProCurve 5406zl switch<br />
          cpe:/h:hp:procurve_switch_5406zl auto<br />
          <br />
          Fingerprint HP ProCurve 5412zl switch<br />
          cpe:/h:hp:procurve_switch_5412zl auto<br />
          <br />
          Fingerprint HP ProCurve E2910al switch<br />
          cpe:/h:hp:procurve_switch_e2910al auto<br />
          <br />
          Fingerprint HP MSM410 WAP<br />
          cpe:/h:hp:msm410 auto<br />
          <br />
          Fingerprint HP ProCurve MSM422 WAP<br />
          cpe:/h:hp:procurve_msm422 auto<br />
          <br />
          Fingerprint HP-UX B.09.00<br />
          cpe:/o:hp:hp-ux:09.00 auto<br />
          <br />
          Fingerprint HP-UX B.10.20<br />
          cpe:/o:hp:hp-ux:10.20 auto<br />
          <br />
          Fingerprint HP-UX B.10.20<br />
          cpe:/o:hp:hp-ux:10.20 auto<br />
          <br />
          Fingerprint HP-UX B.10.20<br />
          cpe:/o:hp:hp-ux:10.20 auto<br />
          <br />
          Fingerprint HP-UX B.11.00<br />
          cpe:/o:hp:hp-ux:11.00 auto<br />
          <br />
          Fingerprint HP-UX B.11.00<br />
          cpe:/o:hp:hp-ux:11.00 auto<br />
          <br />
          Fingerprint HP-UX B.11.00 - B.11.23<br />
          cpe:/o:hp:hp-ux:11.00 auto<br />
          <br />
          Fingerprint HP-UX B.11.00 - B.11.31<br />
          cpe:/o:hp:hp-ux:11.00 auto<br />
          <br />
          Fingerprint HP-UX B.11.11<br />
          cpe:/o:hp:hp-ux:11.11 auto<br />
          <br />
          Fingerprint HP-UX B.11.11<br />
          cpe:/o:hp:hp-ux:11.11 auto<br />
          <br />
          Fingerprint HP-UX B.11.11<br />
          cpe:/o:hp:hp-ux:11.11 auto<br />
          <br />
          Fingerprint HP-UX B.11.11<br />
          cpe:/o:hp:hp-ux:11.11 auto<br />
          <br />
          Fingerprint HP-UX B.11.11 - B.11.23<br />
          cpe:/o:hp:hp-ux:11.11 auto<br />
          <br />
          Fingerprint HP-UX B.11.11 - B.11.23<br />
          cpe:/o:hp:hp-ux:11<br />
          <br />
          Fingerprint HP-UX B.11.23<br />
          cpe:/o:hp:hp-ux:11.23 auto<br />
          <br />
          Fingerprint HP-UX B.11.23<br />
          cpe:/o:hp:hp-ux:11.23 auto<br />
          <br />
          Fingerprint HP-UX B.11.23 - B.11.31<br />
          cpe:/o:hp:hp-ux:11.23 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP-UX B.11.31<br />
          cpe:/o:hp:hp-ux:11.31 auto<br />
          <br />
          Fingerprint HP iLO 2 remote management interface<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP iLO 2 remote management interface<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP iLO 2 remote management interface<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP iLO 2 remote management interface<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP iLO 2 remote management interface<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP iLO 2 remote management interface version 2.0<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP Integrated Lights-Out 2<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP ProLiant DL320 iLO 2 remote management interface<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP iLO 3 or iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:3 auto<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 3 remote management interface<br />
          cpe:/o:hp:ilo:3 auto<br />
          <br />
          Fingerprint HP iLO 3 remote management interface<br />
          cpe:/o:hp:ilo:3 auto<br />
          <br />
          Fingerprint HP iLO 3 remote management interface<br />
          cpe:/o:hp:ilo:3 auto<br />
          <br />
          Fingerprint HP iLO 3 remote management interface<br />
          cpe:/o:hp:ilo:3 auto<br />
          <br />
          Fingerprint HP iLO 3 remote management interface or Hay Systems HSL
          2.75G Femtocell<br />
          cpe:/o:hp:ilo:3 auto<br />
          cpe:/o:hay_systems:hsl_2.75g_femtocell<br />
          <br />
          Fingerprint HP iLO 3 or iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          cpe:/o:hp:ilo:3 auto<br />
          <br />
          Fingerprint HP iLO 3 or iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          cpe:/o:hp:ilo:3 auto<br />
          <br />
          Fingerprint HP iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 4 remote management interface<br />
          cpe:/o:hp:ilo:4 auto<br />
          <br />
          Fingerprint HP iLO 100i Standard remote management interface<br />
          cpe:/o:hp:ilo auto<br />
          <br />
          Fingerprint HP iLO or iLO 2 remote management interface<br />
          cpe:/o:hp:ilo auto<br />
          cpe:/o:hp:ilo:2 auto<br />
          <br />
          Fingerprint HP ProLiant DL320s or ML310 iLO remote management
          interface<br />
          cpe:/o:hp:ilo auto<br />
          <br />
          Fingerprint HP ProLiant ML350 iLO remote management interface<br />
          cpe:/o:hp:ilo auto<br />
          <br />
          Fingerprint HP MPE/iX 7.5<br />
          cpe:/o:hp:mpe_ix:7.5 auto<br />
          <br />
          Fingerprint HP NonStop OS H06.19.00<br />
          cpe:/o:hp:nonstop_os:h06.19<br />
          <br />
          Fingerprint HP NonStop OS<br />
          cpe:/o:hp:nonstop_os auto<br />
          <br />
          Fingerprint HP NonStop OS<br />
          cpe:/o:hp:nonstop_os auto<br />
          <br />
          Fingerprint HP NonStop OS<br />
          cpe:/o:hp:nonstop_os auto<br />
          <br />
          Fingerprint HP Onboard Administrator 4.12 - 4.40<br />
          cpe:/a:hp:onboard_administrator:4<br />
          <br />
          Fingerprint HP OpenVMS 6<br />
          cpe:/o:hp:openvms:6 auto<br />
          <br />
          Fingerprint HP OpenVMS 6.1<br />
          cpe:/o:hp:openvms:6.1 auto<br />
          <br />
          Fingerprint HP OpenVMS 6.1<br />
          cpe:/o:hp:openvms:6.1 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.2<br />
          cpe:/o:hp:openvms:7.2 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.2<br />
          cpe:/o:hp:openvms:7.2 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.2<br />
          cpe:/o:hp:openvms:7.2 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.2-1<br />
          cpe:/o:hp:openvms:7.2 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3 - 8.3<br />
          cpe:/o:hp:openvms:7 auto<br />
          cpe:/o:hp:openvms:8 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3-1<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3-1<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 7.3-2<br />
          cpe:/o:hp:openvms:7.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 8.2<br />
          cpe:/o:hp:openvms:8.2 auto<br />
          <br />
          Fingerprint HP OpenVMS 8.3<br />
          cpe:/o:hp:openvms:8.3 auto<br />
          <br />
          Fingerprint HP OpenVMS 8.3<br />
          cpe:/o:hp:openvms:8.3 auto<br />
          <br />
          Fingerprint HP Tru64 UNIX 5.1 (Alpha)<br />
          cpe:/o:hp:tru64:5.1 auto<br />
          <br />
          Fingerprint HP Tru64 UNIX 5.1A<br />
          cpe:/o:hp:tru64:5.1a auto<br />
          <br />
          Fingerprint HP Tru64 UNIX 5.1B<br />
          cpe:/o:hp:tru64:5.1b auto<br />
          <br />
          Fingerprint HP Tru64 UNIX 5.1b<br />
          cpe:/o:hp:tru64:5.1b<br />
          <br />
          Fingerprint ADSL router: Huawei MT800u-T; or ZyXEL Prestige 623ME-T1,
          643, 662HW-61, 782, or 2602R-61<br />
          cpe:/o:zyxel:zynos:2 auto<br />
          cpe:/o:zyxel:zynos:3 auto<br />
          <br />
          Fingerprint Huawei HG8240 GPON ONT<br />
          cpe:/h:huawei:hg8240<br />
          <br />
          Fingerprint Huawei MT-800, Tenda TED8620R, Zoom X5, or ZTE ZXDSL 831
          ADSL modem<br />
          cpe:/h:huawei:mt-800<br />
          cpe:/h:tenda:ted8620r<br />
          cpe:/h:zoom:x5<br />
          cpe:/h:zte:zxdsl_831<br />
          <br />
          Fingerprint Huawei MT880 ADSL modem<br />
          cpe:/h:huawei:mt880 auto<br />
          <br />
          Fingerprint Huawei MT882 ADSL modem<br />
          cpe:/h:huawei:mt882 auto<br />
          <br />
          Fingerprint Huawei SmartAX MT800u-T ADSL router, NexStor Nexsan
          ATABoy2x NAS device, ZyXEL ES-4024A switch, or ZyXEL Prestige 650HW or
          660HW ADSL router<br />
          cpe:/h:huawei:smartax_mt800u-t<br />
          cpe:/h:nexstor:nexsan_ataboy2x<br />
          cpe:/h:zyxel:es-4024a<br />
          cpe:/o:zyxel:zynos:3 auto<br />
          cpe:/h:zyxel:prestige_650hw<br />
          cpe:/h:zyxel:prestige_660hw<br />
          cpe:/o:zyxel:zynos:3 auto<br />
          <br />
          Fingerprint Huawei SmartAX MT880 ADSL modem<br />
          cpe:/h:huawei:smartax_mt880 auto<br />
          <br />
          Fingerprint Huawei Secospace USG6680 firewall<br />
          cpe:/h:huawei:secospace_usg6680 auto<br />
          <br />
          Fingerprint Huawei AR 28 router<br />
          cpe:/h:huawei:ar_28 auto<br />
          <br />
          Fingerprint Huawei MA5200 router<br />
          cpe:/h:huawei:ma5200 auto<br />
          <br />
          Fingerprint Huawei CloudEngine CE6800 switch<br />
          cpe:/h:huawei:cloudengine_ce6800 auto<br />
          <br />
          Fingerprint Huawei Quidway S5600 switch<br />
          cpe:/h:huawei:quidway_s5600 auto<br />
          <br />
          Fingerprint Huawei AP6050DN WAP<br />
          cpe:/h:huawei:ap6050dn auto<br />
          <br />
          Fingerprint Huawei Quidway S3526C switch (VRP 3.10)<br />
          cpe:/o:huawei:vrp:3.10<br />
          <br />
          Fingerprint Huawei S2326 switch<br />
          cpe:/h:huawei:s2326<br />
          cpe:/o:huawei:vrp:3 auto<br />
          <br />
          Fingerprint Huawei S9300 switch<br />
          cpe:/h:huawei:s9300<br />
          cpe:/o:huawei:vrp:3 auto<br />
          <br />
          Fingerprint Huawei VRP 3 switch<br />
          cpe:/o:huawei:vrp:3 auto<br />
          <br />
          Fingerprint Huawei VRP 5.160<br />
          cpe:/o:huawei:vrp:5.160 auto<br />
          <br />
          Fingerprint Huawei VRP 8.100<br />
          cpe:/o:huawei:vrp:8.100 auto<br />
          <br />
          Fingerprint IBM 4690 OS point-of-sale system<br />
          cpe:/o:ibm:4690_operating_system<br />
          <br />
          Fingerprint IBM AIX 4.2<br />
          cpe:/o:ibm:aix:4.2 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 4.3.2 on RS/6000<br />
          cpe:/o:ibm:aix:4.3.2 auto<br />
          <br />
          Fingerprint IBM AIX AIX 4.3<br />
          cpe:/o:ibm:aix:4.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.1<br />
          cpe:/o:ibm:aix:5.1 auto<br />
          <br />
          Fingerprint IBM AIX 5.1<br />
          cpe:/o:ibm:aix:5.1 auto<br />
          <br />
          Fingerprint IBM AIX 5.1<br />
          cpe:/o:ibm:aix:5.1 auto<br />
          <br />
          Fingerprint IBM AIX 5.1 or 5.2<br />
          cpe:/o:ibm:aix:5.1 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2 ML 1<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.2 on Power5<br />
          cpe:/o:ibm:aix:5.2 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3<br />
          cpe:/o:ibm:aix:5.3 auto<br />
          <br />
          Fingerprint IBM AIX 5.3 - 6.1<br />
          cpe:/o:ibm:aix:5 auto<br />
          cpe:/o:ibm:aix:6 auto<br />
          <br />
          Fingerprint IBM AIX 5.3 - 6.1<br />
          cpe:/o:ibm:aix:5 auto<br />
          <br />
          Fingerprint IBM AIX 5.3 - 6.1<br />
          cpe:/o:ibm:aix:5 auto<br />
          cpe:/o:ibm:aix:6 auto<br />
          <br />
          Fingerprint IBM AIX 5.3 - 6.1<br />
          cpe:/o:ibm:aix:5 auto<br />
          <br />
          Fingerprint IBM AIX 5.3 - 7.1<br />
          cpe:/o:ibm:aix:5 auto<br />
          cpe:/o:ibm:aix:6 auto<br />
          cpe:/o:ibm:aix:7 auto<br />
          <br />
          Fingerprint IBM AIX 5.3 SP4<br />
          cpe:/o:ibm:aix:5.3:sp4 auto<br />
          <br />
          Fingerprint IBM AIX 6<br />
          cpe:/o:ibm:aix:6 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1<br />
          <br />
          Fingerprint IBM AIX 6.1<br />
          cpe:/o:ibm:aix:6.1 auto<br />
          <br />
          Fingerprint IBM AIX 7<br />
          cpe:/o:ibm:aix:7 auto<br />
          <br />
          Fingerprint IBM AIX 7.1<br />
          cpe:/o:ibm:aix:7.1 auto<br />
          <br />
          Fingerprint IBM AIX 7.1<br />
          cpe:/o:ibm:aix:7.1 auto<br />
          <br />
          Fingerprint IBM AIX 7.1<br />
          cpe:/o:ibm:aix:7.1 auto<br />
          <br />
          Fingerprint IBM AIX 7.1<br />
          cpe:/o:ibm:aix:7.1 auto<br />
          <br />
          Fingerprint IBM AIX 7.1<br />
          cpe:/o:ibm:aix:7.1 auto<br />
          <br />
          Fingerprint IBM AIX 7.1<br />
          cpe:/o:ibm:aix:7.1 auto<br />
          <br />
          Fingerprint IBM AIX 7.2<br />
          cpe:/o:ibm:aix:7.2 auto<br />
          <br />
          Fingerprint IBM 6400 printer (software version 7.0.9.6)<br />
          cpe:/h:ibm:6400 auto<br />
          <br />
          Fingerprint IBM InfoPrint 1140 printer<br />
          cpe:/h:ibm:infoprint_1140 auto<br />
          <br />
          Fingerprint IBM InfoPrint 1754 printer<br />
          cpe:/h:ibm:infoprint_1754 auto<br />
          <br />
          Fingerprint IBM WebSphere DataPower XI50 proxy server<br />
          cpe:/h:ibm:websphere_datapower_xi50<br />
          <br />
          Fingerprint IBM WebSphere DataPower XS40 proxy server<br />
          cpe:/h:ibm:websphere_datapower_xs40<br />
          <br />
          Fingerprint Fujitsu Externus DX80 or IBM DCS9900 NAS device<br />
          cpe:/h:ibm:dcs9900 auto<br />
          cpe:/h:fujitsu:externus_dx80<br />
          <br />
          Fingerprint IBM BladeCenter management module, IBM System Storage
          TS3100/TS3200 Express Model tape library, or HP StorageWorks MSL2024
          tape library<br />
          cpe:/h:ibm:ts3200<br />
          cpe:/h:hp:storageworks_msl2024<br />
          <br />
          Fingerprint IBM System Storage DS4700 NAS device<br />
          cpe:/h:ibm:ds4700<br />
          <br />
          Fingerprint IBM 8275-826 switch<br />
          cpe:/h:ibm:8275-826<br />
          <br />
          Fingerprint IBM i 6<br />
          cpe:/o:ibm:i:6 auto<br />
          <br />
          Fingerprint IBM i 6.1<br />
          cpe:/o:ibm:i:6.1 auto<br />
          <br />
          Fingerprint IBM i 6.1<br />
          cpe:/o:ibm:i:6.1 auto<br />
          <br />
          Fingerprint IBM i 6.1<br />
          cpe:/o:ibm:i:6.1 auto<br />
          <br />
          Fingerprint IBM i 7.1<br />
          cpe:/o:ibm:i:7.1<br />
          <br />
          Fingerprint IBM i 7.1<br />
          cpe:/o:ibm:i:7.1 auto<br />
          <br />
          Fingerprint IBM i 7.2<br />
          cpe:/o:ibm:i:7.2 auto<br />
          <br />
          Fingerprint IBM i5/OS V5R2<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          <br />
          Fingerprint IBM i5/OS V5R3<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          <br />
          Fingerprint IBM i5/OS V5R3M0<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          <br />
          Fingerprint IBM i5/OS V5R4<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          <br />
          Fingerprint IBM i5/OS V5R4<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          <br />
          Fingerprint IBM i5/OS V5R4 - V6R1<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          cpe:/o:ibm:i:6.1<br />
          <br />
          Fingerprint IBM i5/OS V5R4 on an IBM iSeries (PPC)<br />
          cpe:/o:ibm:i5os:v5 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp 3.0<br />
          cpe:/o:ibm:os2:3 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp Connect<br />
          cpe:/o:ibm:os2:3 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp 2.0<br />
          cpe:/o:ibm:os2:4 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp 4<br />
          cpe:/o:ibm:os2:4 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp 4.0 (FixPak 15)<br />
          cpe:/o:ibm:os2:4 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp 4.50<br />
          cpe:/o:ibm:os2:4 auto<br />
          <br />
          Fingerprint IBM OS/2 Warp Server 4.52<br />
          cpe:/o:ibm:os2:4 auto<br />
          <br />
          Fingerprint IBM OS/390 V2<br />
          cpe:/o:ibm:os_390:v2 auto<br />
          <br />
          Fingerprint IBM OS/390<br />
          cpe:/o:ibm:os_390 auto<br />
          <br />
          Fingerprint IBM OS/400 V4R2M0<br />
          cpe:/o:ibm:os_400:v4r2 auto<br />
          <br />
          Fingerprint IBM OS/400 V4R3<br />
          cpe:/o:ibm:os_400:v4r3 auto<br />
          <br />
          Fingerprint IBM OS/400 V4R3<br />
          cpe:/o:ibm:os_400:v4r3 auto<br />
          <br />
          Fingerprint IBM OS/400 V4R3<br />
          cpe:/o:ibm:os_400:v4r3 auto<br />
          <br />
          Fingerprint IBM OS/400 V4R5<br />
          cpe:/o:ibm:os_400:v4r5 auto<br />
          <br />
          Fingerprint IBM OS/400 V4R5M0<br />
          cpe:/o:ibm:os_400:v4r5 auto<br />
          <br />
          Fingerprint IBM OS/400 V5<br />
          cpe:/o:ibm:os_400:v5 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R1M0<br />
          cpe:/o:ibm:os_400:v5r1 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R1M0<br />
          cpe:/o:ibm:os_400:v5r1 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R2<br />
          cpe:/o:ibm:os_400:v5r2 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R2 - V5R3<br />
          cpe:/o:ibm:os_400:v5r2 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R2M0<br />
          cpe:/o:ibm:os_400:v5r2 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R3<br />
          cpe:/o:ibm:os_400:v5r3 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R3<br />
          cpe:/o:ibm:os_400:v5r3 auto<br />
          <br />
          Fingerprint IBM OS/400 V5R3<br />
          cpe:/o:ibm:os_400:v5r3 auto<br />
          <br />
          Fingerprint IBM z/OS 1.10<br />
          cpe:/o:ibm:zos:1.10 auto<br />
          <br />
          Fingerprint IBM z/OS 1.10<br />
          cpe:/o:ibm:zos:1.10 auto<br />
          <br />
          Fingerprint IBM z/OS 1.11<br />
          cpe:/o:ibm:zos:1.11 auto<br />
          <br />
          Fingerprint IBM z/OS 1.12<br />
          cpe:/o:ibm:zos:1.12 auto<br />
          <br />
          Fingerprint IBM z/OS 1.13<br />
          cpe:/o:ibm:zos:1.13 auto<br />
          <br />
          Fingerprint IBM z/OS 1.4.2<br />
          cpe:/o:ibm:zos:1.4.2 auto<br />
          <br />
          Fingerprint IBM z/OS 1.6<br />
          cpe:/o:ibm:zos:1.6 auto<br />
          <br />
          Fingerprint IBM z/OS 1.7<br />
          cpe:/o:ibm:zos:1.7 auto<br />
          <br />
          Fingerprint IBM z/OS 1.7<br />
          cpe:/o:ibm:zos:1.7 auto<br />
          <br />
          Fingerprint IBM z/OS 1.8.0<br />
          cpe:/o:ibm:zos:1.8.0 auto<br />
          <br />
          Fingerprint IBM z/OS 1.8.0<br />
          cpe:/o:ibm:zos:1.8.0 auto<br />
          <br />
          Fingerprint IBM z/OS 1.9<br />
          cpe:/o:ibm:zos:1.9 auto<br />
          <br />
          Fingerprint IBM z/OS 10<br />
          cpe:/o:ibm:zos:10 auto<br />
          <br />
          Fingerprint IBM z/OS 2.1<br />
          cpe:/o:ibm:zos:2.1 auto<br />
          <br />
          Fingerprint IBM z/OS 2.2<br />
          cpe:/o:ibm:zos:2.2 auto<br />
          <br />
          Fingerprint IBM z/OS v1r8<br />
          cpe:/o:ibm:zos auto<br />
          <br />
          Fingerprint IBM z/VM 4.2<br />
          cpe:/o:ibm:z_vm:4.2 auto<br />
          <br />
          Fingerprint IBM z/VM 5.2<br />
          cpe:/o:ibm:z_vm:5.2 auto<br />
          <br />
          Fingerprint IBM z/VM<br />
          cpe:/o:ibm:z_vm auto<br />
          <br />
          Fingerprint Novatel MiFi 2200 3G WAP or iDirect Evolution X1 satellite
          router<br />
          cpe:/h:novatel:mifi_2200_3g auto<br />
          <br />
          Fingerprint IHome SmartPlug iSP5WWC<br />
          cpe:/h:ihome:isp5wwc<br />
          <br />
          Fingerprint OpenIndiana<br />
          cpe:/o:illumos:openindiana auto<br />
          <br />
          Fingerprint OpenIndiana<br />
          cpe:/o:illumos:openindiana auto<br />
          <br />
          Fingerprint OpenIndiana oi_147 - oi_148<br />
          cpe:/o:illumos:openindiana auto<br />
          <br />
          Fingerprint Imperva MX<br />
          cpe:/o:imperva:securesphere auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:imperva:securesphere auto<br />
          <br />
          Fingerprint Infoblox Trinzic network control appliance<br />
          cpe:/h:infoblox:trinzic<br />
          <br />
          Fingerprint Infrant ReadyNAS NV NAS device (RAIDiator 3.00)<br />
          cpe:/o:infrant:raidiator:3.00 auto<br />
          <br />
          Fingerprint Infrant ReadyNAS NV+ NAS device (RAIDiator 4.1.4)<br />
          cpe:/o:infrant:raidiator:4.1.4 auto<br />
          <br />
          Fingerprint Intel Express 460T switch<br />
          cpe:/h:intel:express_460t auto<br />
          <br />
          Fingerprint Intel Express 510T switch<br />
          cpe:/h:intel:express_510t auto<br />
          <br />
          Fingerprint Interbell IB-305 VoIP phone<br />
          cpe:/h:interbell:ib-305 auto<br />
          <br />
          Fingerprint Interpeak IPCOM<br />
          cpe:/o:interpeak:ipcom<br />
          <br />
          Fingerprint Intertex IX66-EDFLC ADSL modem<br />
          cpe:/h:intertex:ix66-edflc auto<br />
          <br />
          Fingerprint Iomega StorCenter ix4-200d (Linux 2.6.31)<br />
          cpe:/h:iomega:ix4-200d<br />
          cpe:/o:linux:linux_kernel:2.6.31 auto<br />
          <br />
          Fingerprint IPAD-OS<br />
          cpe:/o:ipadownersassociation:ipad_os auto<br />
          <br />
          Fingerprint ipTIME PRO 54G WAP<br />
          cpe:/h:iptime:pro_54g auto<br />
          <br />
          Fingerprint iPXE 1.0.0+<br />
          cpe:/o:ipxe:ipxe:1.0.0%2b<br />
          <br />
          Fingerprint iRobot Roomba 980 vacuum cleaner<br />
          cpe:/h:irobot:roomba_980<br />
          <br />
          Fingerprint iRobot Roomba 980 vacuum cleaner<br />
          cpe:/h:irobot:roomba_980<br />
          <br />
          Fingerprint IronPort C100 email security appliance (AsyncOS 4.7.1)<br />
          cpe:/o:ironport:asyncos:4.7.1 auto<br />
          <br />
          Fingerprint IronPort C100 or C650 email security appliance (AsyncOS
          6.01 - 6.3)<br />
          cpe:/o:ironport:asyncos:6 auto<br />
          <br />
          Fingerprint IronPort C150 email security appliance (AsyncOS 6.5.2)<br />
          cpe:/o:ironport:asyncos:6.5.2 auto<br />
          <br />
          Fingerprint IronPort C150 email security appliance (AsyncOS 6.5.3)<br />
          cpe:/o:ironport:asyncos:6.5.3 auto<br />
          <br />
          Fingerprint IronPort AsyncOS 7.5.1<br />
          cpe:/o:ironport:asyncos:7.5.1 auto<br />
          <br />
          Fingerprint Isilon IQ 200 NAS device<br />
          cpe:/o:isilon:onefs auto<br />
          <br />
          Fingerprint Joyent SmartOS<br />
          cpe:/o:joyent:smartos auto<br />
          <br />
          Fingerprint Joyent SmartOS<br />
          cpe:/o:joyent:smartos auto<br />
          <br />
          Fingerprint Joyent SmartOS<br />
          cpe:/o:joyent:smartos auto<br />
          <br />
          Fingerprint JTEKT Toyopuc PC10 programmable logic controller<br />
          cpe:/h:jtekt:toyopuc_pc10<br />
          <br />
          Fingerprint Juniper Networks SSG 20 firewall<br />
          cpe:/h:juniper:networks_ssg_20 auto<br />
          <br />
          Fingerprint Juniper SRX100 firewall<br />
          cpe:/h:juniper:srx100 auto<br />
          <br />
          Fingerprint Juniper J4350 router<br />
          cpe:/h:juniper:j4350 auto<br />
          <br />
          Fingerprint Juniper M7i router<br />
          cpe:/h:juniper:m7i auto<br />
          <br />
          Fingerprint Juniper M7i router<br />
          cpe:/h:juniper:m7i auto<br />
          <br />
          Fingerprint Juniper M7i router<br />
          cpe:/h:juniper:m7i auto<br />
          <br />
          Fingerprint Juniper Networks ERX-700 router<br />
          cpe:/h:juniper:networks_erx-700 auto<br />
          <br />
          Fingerprint Juniper MAG2600 SSL VPN gateway (IVE OS 7.3)<br />
          cpe:/h:juniper:mag2600<br />
          <br />
          Fingerprint Juniper SA4000 SSL VPN gateway (IVE OS 7.0)<br />
          cpe:/h:juniper:sa4000<br />
          cpe:/o:juniper:ive_os:7.0 auto<br />
          <br />
          Fingerprint Juniper SRX100-series or SRX200-series firewall (JUNOS
          10.4 - 12.1)<br />
          cpe:/o:juniper:junos:10 auto<br />
          cpe:/o:juniper:junos:12 auto<br />
          <br />
          Fingerprint Juniper JUNOS 10.4R6.5<br />
          cpe:/o:juniper:junos:10<br />
          <br />
          Fingerprint Juniper SRX-series firewall (JUNOS 12.1)<br />
          cpe:/o:juniper:junos:12.1 auto<br />
          <br />
          Fingerprint Juniper SRX210 firewall (JUNOS 12.1)<br />
          cpe:/o:juniper:junos:12.1 auto<br />
          <br />
          Fingerprint Juniper JUNOS 12.3R5.7<br />
          cpe:/o:juniper:junos:12.3r5.7 auto<br />
          <br />
          Fingerprint Juniper MX480 router (JUNOS 12.3)<br />
          cpe:/o:juniper:junos:12.3 auto<br />
          <br />
          Fingerprint Juniper Networks JUNOS 12<br />
          cpe:/o:juniper:junos:12 auto<br />
          <br />
          Fingerprint Juniper EX2200 switch (JUNOS 12)<br />
          cpe:/o:juniper:junos:12 auto<br />
          <br />
          Fingerprint Juniper Networks JUNOS 12<br />
          cpe:/o:juniper:junos:12 auto<br />
          <br />
          Fingerprint Juniper JUNOS 13.3R8<br />
          cpe:/o:juniper:junos:13.3r8 auto<br />
          <br />
          Fingerprint Juniper JUNOS 13.2<br />
          cpe:/o:juniper:junos:13.2 auto<br />
          <br />
          Fingerprint Juniper Networks WXC-590 proxy server (JUNOS 5.4.4.0)<br />
          cpe:/o:juniper:junos:5.4.4.0 auto<br />
          <br />
          Fingerprint Juniper Networks JUNOS 7.3R6<br />
          cpe:/o:juniper:junos:7.3r6 auto<br />
          <br />
          Fingerprint Juniper Networks JUNOS 7.4R3.4 or 8.3<br />
          cpe:/o:juniper:junos:7.4r3.4 auto<br />
          cpe:/o:juniper:junos:8 auto<br />
          <br />
          Fingerprint Juniper Networks Olive (JUNOS 7.5-20060511)<br />
          cpe:/o:juniper:junos:7.5 auto<br />
          <br />
          Fingerprint Juniper Networks J2320 or MX5-T router; or EX2200, EX3200,
          EX4200, or EX8200 switch (JUNOS 8.5 - 11.2)<br />
          cpe:/o:juniper:junos:8 auto<br />
          cpe:/o:juniper:junos:9 auto<br />
          cpe:/o:juniper:junos:10 auto<br />
          cpe:/o:juniper:junos:11 auto<br />
          <br />
          Fingerprint Juniper Networks JUNOS 8.5B2.5<br />
          cpe:/o:juniper:junos:8.5b2.5 auto<br />
          <br />
          Fingerprint Juniper JUNOS 9.2R1.10<br />
          cpe:/o:juniper:junos:9.2r1.10 auto<br />
          <br />
          Fingerprint Juniper JUNOS 9.4R2.9<br />
          cpe:/o:juniper:junos:9.4r2.9 auto<br />
          <br />
          Fingerprint Juniper MX960 router (JUNOS 9.5R3.7)<br />
          cpe:/o:juniper:junos:9.5r3.7 auto<br />
          <br />
          Fingerprint Juniper Networks JUNOS 9.0R2.10<br />
          cpe:/o:juniper:junos:9.0r2.10 auto<br />
          <br />
          Fingerprint Juniper MX960 router<br />
          cpe:/o:juniper:junos auto<br />
          <br />
          Fingerprint KA9Q NOS TCP/IP stack for packet radio<br />
          cpe:/o:ka9q:ka9q auto<br />
          <br />
          Fingerprint Kartina SIG 220 set-top box<br />
          cpe:/h:kartina:sig_220<br />
          <br />
          Fingerprint KCorp KLG-575 WAP<br />
          cpe:/h:kcorp:klg-575 auto<br />
          <br />
          Fingerprint Keyence CV-X150F Image Sensor/Controller (VxWorks)<br />
          cpe:/h:keyence:cv-x150f<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Keyence SR-1000 code reader<br />
          cpe:/h:keyence:sr-1000<br />
          <br />
          Fingerprint Kodak ESP 5210 printer<br />
          cpe:/h:kodak:esp_5210 auto<br />
          <br />
          Fingerprint Kodak ESP 5210, ESP 7250, ESP 9200, or HERO 3.1 printer<br />
          cpe:/h:kodak:esp_5210<br />
          cpe:/h:kodak:esp_7250<br />
          cpe:/h:kodak:esp_9200<br />
          cpe:/h:kodak:hero_3.1<br />
          <br />
          Fingerprint Kodak ESP 5250 printer<br />
          cpe:/h:kodak:esp_5250 auto<br />
          <br />
          Fingerprint Kodak ESP 5250 printer<br />
          cpe:/h:kodak:esp_5250 auto<br />
          <br />
          Fingerprint Kodak ESP C310 printer<br />
          cpe:/h:kodak:esp_c310 auto<br />
          <br />
          Fingerprint Kodak ESP C310 printer<br />
          cpe:/h:kodak:esp_c310 auto<br />
          <br />
          Fingerprint Konica Minolta 1600f printer<br />
          cpe:/h:konicaminolta:1600f auto<br />
          <br />
          Fingerprint Konica Minolta 7035 printer<br />
          cpe:/h:konicaminolta:7035 auto<br />
          <br />
          Fingerprint Konica Minolta bizhub 250 printer<br />
          cpe:/h:konicaminolta:bizhub_250 auto<br />
          <br />
          Fingerprint Konica Minolta bizhub 250 printer<br />
          cpe:/h:konicaminolta:bizhub_250 auto<br />
          <br />
          Fingerprint Konica Minolta bizhub C252 printer<br />
          cpe:/h:konicaminolta:bizhub_c252 auto<br />
          <br />
          Fingerprint Konica Minolta bizhub C253 printer<br />
          cpe:/h:konicaminolta:bizhub_c253 auto<br />
          <br />
          Fingerprint Konica Minolta bizhub C450 printer with optional Fiery
          Controller<br />
          cpe:/h:konicaminolta:bizhub_c450 auto<br />
          <br />
          Fingerprint Konica Minolta C203 printer<br />
          cpe:/h:konicaminolta:c203 auto<br />
          <br />
          Fingerprint Konica Minolta C350 printer<br />
          cpe:/h:konicaminolta:c350 auto<br />
          <br />
          Fingerprint Konica Minolta Color MF24-2 printer<br />
          cpe:/h:konicaminolta:color_mf24-2 auto<br />
          <br />
          Fingerprint Konica Minolta Di2510f printer<br />
          cpe:/h:konicaminolta:di2510f auto<br />
          <br />
          Fingerprint Konica Minolta MagiColor 2430 printer (VxWorks)<br />
          cpe:/h:konicaminolta:magicolor_2430 auto<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Koukaam NETIO-230A power control device or ZyXEL SP-220E
          thermal printer<br />
          cpe:/h:koukaam:netio-230a<br />
          cpe:/h:zyxel:sp-220e<br />
          <br />
          Fingerprint Kronos Timeclock or SonicWALL TZ 180 Standard firewall
          (SonicOS 3.8)<br />
          cpe:/o:sonicwall:sonicos:3.8 auto<br />
          <br />
          Fingerprint KW-Software ProConOS<br />
          cpe:/o:kw-software:proconos auto<br />
          <br />
          Fingerprint Kyocera CopyStar CS 255 printer<br />
          cpe:/h:kyocera:cs_255<br />
          <br />
          Fingerprint Kyocera CopyStar CS-2560 printer<br />
          cpe:/h:kyocera:cs-2560<br />
          <br />
          Fingerprint Kyocera ECOSYS M2030dn printer<br />
          cpe:/h:kyocera:ecosys_m2030dn auto<br />
          <br />
          Fingerprint Kyocera EP 470DN printer<br />
          cpe:/h:kyocera:ep_470dn auto<br />
          <br />
          Fingerprint Kyocera FS-1118MFP printer<br />
          cpe:/h:kyocera:fs-1118mfp auto<br />
          <br />
          Fingerprint Kyocera FS-1750 printer<br />
          cpe:/h:kyocera:fs-1750 auto<br />
          <br />
          Fingerprint Kyocera FS2000D printer<br />
          cpe:/h:kyocera:fs2000d auto<br />
          <br />
          Fingerprint LaCrosse WA-1030U weather forecaster<br />
          cpe:/h:lacrosse:wa-1030u<br />
          <br />
          Fingerprint Lancom L-305agn WAP, 1722 VoIP router, or WLC-4006 WLAN
          controller<br />
          cpe:/h:lancom:l-305agn auto<br />
          cpe:/o:lancom:lcos:8<br />
          cpe:/h:lancom:1722_voip<br />
          cpe:/h:lancom:wlc-4006<br />
          <br />
          Fingerprint Lancom 1711 or 1821 broadband router<br />
          cpe:/o:lancom:lcos:6 auto<br />
          <br />
          Fingerprint Lancom 1721 ADSL modem or L-54ag WAP (LCOS 6 - 7)<br />
          cpe:/o:lancom:lcos:6 auto<br />
          cpe:/o:lancom:lcos:7 auto<br />
          <br />
          Fingerprint Lancom 821+ ADSL modem (LCOS 7.70)<br />
          cpe:/o:lancom:lcos:7.70 auto<br />
          <br />
          Fingerprint Lancom LCOS 8.00<br />
          cpe:/o:lancom:lcos:8.00<br />
          <br />
          Fingerprint Lancom LCOS 9.20<br />
          cpe:/o:lancom:lcos:9.20 auto<br />
          <br />
          Fingerprint Lancom L-54g WAP (LCOS)<br />
          cpe:/o:lancom:lcos auto<br />
          <br />
          Fingerprint Lanier Pro 8100s printer<br />
          cpe:/h:lanier:pro_8100s auto<br />
          <br />
          Fingerprint NetBSD 1.4.2 - 1.5.2; Lanier LS232c, NRG DSc428, Ricoh
          Aficio 2020, Ricoh NRG MP 161, or Savin 8055 printer; or Panasonic
          Network Camera (BB-HCM331, BB-HCM381, BCL-30A, BL-C1CE, or
          BL-C10CE)<br />
          cpe:/h:lanier:ls232c<br />
          cpe:/o:netbsd:netbsd:1 auto<br />
          cpe:/h:nrg:dsc428<br />
          cpe:/h:panasonic:bb-hcm331<br />
          cpe:/h:panasonic:bb-hcm381<br />
          cpe:/h:panasonic:bcl-30a<br />
          cpe:/h:panasonic:bl-c1ce<br />
          cpe:/h:panasonic:bl-c10ce<br />
          cpe:/h:ricoh:aficio_2020<br />
          cpe:/h:ricoh:nrg_mp_161<br />
          cpe:/h:savin:8055<br />
          <br />
          Fingerprint Lantronix UDS-1100 serial-to-Ethernet bridge<br />
          cpe:/h:lantronix:uds-1100 auto<br />
          <br />
          Fingerprint Lantronix XPort AR<br />
          cpe:/h:lantronix:xport_ar<br />
          <br />
          Fingerprint Lantronix ETS4P print server<br />
          cpe:/h:lantronix:ets4p auto<br />
          <br />
          Fingerprint Lantronix MPS1 print server<br />
          cpe:/h:lantronix:mps1 auto<br />
          <br />
          Fingerprint Lantronix MSS100 print server<br />
          cpe:/h:lantronix:mss100 auto<br />
          <br />
          Fingerprint Lantronix UDS1100 external serial device server<br />
          cpe:/h:lantronix:uds1100<br />
          <br />
          Fingerprint Lantronix XPort embedded Ethernet device server<br />
          cpe:/h:lantronix:xport<br />
          <br />
          Fingerprint Lantronix XPort embedded Ethernet device server<br />
          cpe:/h:lantronix:xport<br />
          <br />
          Fingerprint Lantronix XPort embedded Ethernet device server<br />
          cpe:/h:lantronix:xport<br />
          <br />
          Fingerprint Lantronix XPort embedded Ethernet device server<br />
          cpe:/h:lantronix:xport<br />
          <br />
          Fingerprint Lantronix XPort-03 embedded serial device server (firmware
          1.80)<br />
          cpe:/h:lantronix:xport-03<br />
          <br />
          Fingerprint Lantronix Xport-03 embedded serial device server (firmware
          6.1.0.3)<br />
          cpe:/h:lantronix:xport-03<br />
          <br />
          Fingerprint Lantronix MSSLite device server<br />
          cpe:/h:lantronix:msslite<br />
          <br />
          Fingerprint Lantronix ETS32Pr or LRS16 terminal server<br />
          cpe:/h:lantronix:ets32pr<br />
          cpe:/h:lantronix:lrs16<br />
          <br />
          Fingerprint Lantronix Evolution OS 5.4<br />
          cpe:/o:lantronix:evolution_os:5.4 auto<br />
          <br />
          Fingerprint Lantronix Evolution OS<br />
          cpe:/o:lantronix:evolution_os auto<br />
          <br />
          Fingerprint Lenel LNL-2200 access control board<br />
          cpe:/h:lenel:lnl-2200<br />
          <br />
          Fingerprint Lenel LNL-2220 access control board<br />
          cpe:/h:lenel:lnl-2220<br />
          <br />
          Fingerprint Lenel LNL-3300 access control board<br />
          cpe:/h:lenel:lnl-3300<br />
          <br />
          Fingerprint LNL-3300 Intelligent System Controller<br />
          cpe:/h:lenel:lnl-3300<br />
          <br />
          Fingerprint Leolink LEO-6400DL ADSL router<br />
          cpe:/h:leolink:leo-6400dl auto<br />
          <br />
          Fingerprint LevelOne FPS-1032 print server<br />
          cpe:/h:levelone:fps-1032 auto<br />
          <br />
          Fingerprint Lexmark C500 printer<br />
          cpe:/h:lexmark:c500 auto<br />
          <br />
          Fingerprint Lexmark C524 printer<br />
          cpe:/h:lexmark:c524 auto<br />
          <br />
          Fingerprint Lexmark C534dn printer<br />
          cpe:/h:lexmark:c534dn auto<br />
          <br />
          Fingerprint Lexmark CS310dn or MS410dn printer<br />
          cpe:/h:lexmark:cs310dn<br />
          cpe:/h:lexmark:ms410dn<br />
          <br />
          Fingerprint Lexmark CS410dn printer<br />
          cpe:/h:lexmark:cs410dn auto<br />
          <br />
          Fingerprint Lexmark CS410dn printer<br />
          cpe:/h:lexmark:cs410dn auto<br />
          <br />
          Fingerprint Lexmark E250DN printer<br />
          cpe:/h:lexmark:e250dn auto<br />
          <br />
          Fingerprint Lexmark E332n printer<br />
          cpe:/h:lexmark:e332n auto<br />
          <br />
          Fingerprint Lexmark E450dn printer<br />
          cpe:/h:lexmark:e450dn auto<br />
          <br />
          Fingerprint Lexmark MS811 printer<br />
          cpe:/h:lexmark:ms811 auto<br />
          <br />
          Fingerprint Lexmark MX611dhe printer<br />
          cpe:/h:lexmark:mx611dhe auto<br />
          <br />
          Fingerprint Lexmark Optra M410, T610, T612, or T622 printer<br />
          cpe:/h:lexmark:optra_m410<br />
          cpe:/h:lexmark:optra_t610<br />
          cpe:/h:lexmark:optra_t612<br />
          cpe:/h:lexmark:optra_t622<br />
          <br />
          Fingerprint Lexmark T640 printer<br />
          cpe:/h:lexmark:t640 auto<br />
          <br />
          Fingerprint Lexmark T654 or X656de printer<br />
          cpe:/h:lexmark:t654<br />
          cpe:/h:lexmark:x656de<br />
          <br />
          Fingerprint Lexmark X4530, X4650, 4800, or X9575 wireless printer<br />
          cpe:/h:lexmark:x4530<br />
          cpe:/h:lexmark:x4650<br />
          cpe:/h:lexmark:4800<br />
          cpe:/h:lexmark:x9575<br />
          <br />
          Fingerprint Lexmark X4850 or X6570 printer<br />
          cpe:/h:lexmark:x4850<br />
          cpe:/h:lexmark:x6570<br />
          <br />
          Fingerprint Lexmark X546dtn printer<br />
          cpe:/h:lexmark:x546dtn auto<br />
          <br />
          Fingerprint Lexmark X644e printer<br />
          cpe:/h:lexmark:x644e auto<br />
          <br />
          Fingerprint Lexmark X646e, C770, or C935 printer<br />
          cpe:/h:lexmark:x646e<br />
          cpe:/h:lexmark:c770<br />
          cpe:/h:lexmark:c935<br />
          <br />
          Fingerprint Lexmark X6650 printer<br />
          cpe:/h:lexmark:x6650<br />
          <br />
          Fingerprint Lexmark Z2400 printer<br />
          cpe:/h:lexmark:z2400 auto<br />
          <br />
          Fingerprint Linksys WET54G wireless bridge<br />
          cpe:/h:linksys:wet54g auto<br />
          <br />
          Fingerprint Linksys HPRO200 cable/DSL router<br />
          <br />
          Fingerprint Linksys WRV200 wireless broadband router<br />
          cpe:/h:linksys:wrv200 auto<br />
          <br />
          Fingerprint Linksys BEFSR41 router<br />
          cpe:/h:linksys:befsr41 auto<br />
          <br />
          Fingerprint Linksys BEFSR81 router<br />
          cpe:/h:linksys:befsr81 auto<br />
          <br />
          Fingerprint Linksys RV042 router<br />
          cpe:/h:linksys:rv042 auto<br />
          <br />
          Fingerprint Linksys RVS4000 router<br />
          cpe:/h:linksys:rvs4000 auto<br />
          <br />
          Fingerprint Linksys WRT54GX2 WAP<br />
          cpe:/h:linksys:wrt54gx2 auto<br />
          <br />
          Fingerprint Linksys NAS200 NAS device<br />
          cpe:/h:linksys:nas200 auto<br />
          <br />
          Fingerprint Linksys SRW2000-series or Allied Telesyn AT-8000S
          switch<br />
          cpe:/h:alliedtelesyn:at-8000s<br />
          <br />
          Fingerprint Linksys SRW2008MP switch<br />
          cpe:/h:linksys:srw2008mp auto<br />
          <br />
          Fingerprint Linksys SRW2024 switch<br />
          cpe:/h:linksys:srw2024 auto<br />
          <br />
          Fingerprint Linksys SRW2024 switch<br />
          cpe:/h:linksys:srw2024 auto<br />
          <br />
          Fingerprint Linksys PAP2T VoIP adapter<br />
          cpe:/h:linksys:pap2t auto<br />
          <br />
          Fingerprint Linksys SPA3102 VoIP adapter<br />
          cpe:/h:linksys:spa3102_voice_gateway_with_router:-<br />
          cpe:/o:linksys:spa3102_voice_gateway_with_router_firmware<br />
          <br />
          Fingerprint Linksys SPA901, SPA921, or SPA 941 SIP VoIP phone<br />
          cpe:/h:linksys:spa901_1-line_ip_phone<br />
          cpe:/h:linksys:spa921_1-line_ip_phone_with_1-port_ethernet<br />
          cpe:/h:linksys:spa941_4-line_ip_phone_with_1-port_ethernet<br />
          <br />
          Fingerprint Linksys SPA921 SIP VoIP phone<br />
          cpe:/h:linksys:spa921_1-line_ip_phone_with_1-port_ethernet<br />
          <br />
          Fingerprint Linksys SPA942 VoIP phone<br />
          cpe:/h:linksys:spa942_4-line_ip_phone_with_2-port_switch<br />
          <br />
          Fingerprint Linksys SPA942, SPA962, SPA8000 or SPA9000 VoIP phone;
          SPA3102 VoIP adapter; or Sipura SPA-2100-series VoIP adapter<br />
          cpe:/h:linksys:spa942_4-line_ip_phone_with_2-port_switch<br />
          cpe:/h:linksys:spa962_6-line_ip_phone_with_2-port_switch<br />
          cpe:/h:linksys:spa9000<br />
          cpe:/h:linksys:spa3102_voice_gateway_with_router:-<br />
          cpe:/o:linksys:spa3102_voice_gateway_with_router_firmware<br />
          cpe:/h:sipura:spa2102_phone_adapter_with_router:-<br />
          cpe:/o:sipura:spa2102_phone_adapter_with_router_firmware<br />
          <br />
          Fingerprint Linksys AM300 wireless ADSL modem<br />
          cpe:/h:linksys:am300 auto<br />
          <br />
          Fingerprint Linksys BEFSR41 router or WRK54G WAP<br />
          cpe:/h:linksys:befsr41<br />
          cpe:/h:linksys:wrk54g<br />
          <br />
          Fingerprint Linksys BEFW11S4 WAP<br />
          cpe:/h:linksys:befw11s4 auto<br />
          <br />
          Fingerprint Linksys BEFW11S4 WAP<br />
          cpe:/h:linksys:befw11s4 auto<br />
          <br />
          Fingerprint Linksys BEFW11S4 WAP<br />
          cpe:/h:linksys:befw11s4 auto<br />
          <br />
          Fingerprint Linksys WAG200G WAP (MontaVista Linux 2.4.17)<br />
          cpe:/h:linksys:wag200g auto<br />
          <br />
          Fingerprint Linksys WAP54G WAP<br />
          cpe:/h:linksys:wap54g auto<br />
          <br />
          Fingerprint Linksys WAP54G WAP<br />
          cpe:/h:linksys:wap54g auto<br />
          <br />
          Fingerprint Linksys WET54GS5 WAP, Tranzeo TR-CPQ-19f WAP, or Xerox
          WorkCentre Pro 265 printer<br />
          cpe:/h:linksys:wet54gs5 auto<br />
          cpe:/h:tranzeo:tr-cpq-19f auto<br />
          cpe:/h:xerox:workcentre_pro_265<br />
          <br />
          Fingerprint Linksys WGA54G WAP<br />
          cpe:/h:linksys:wga54g<br />
          <br />
          Fingerprint Linksys WRT100 WAP<br />
          cpe:/h:linksys:wrt100 auto<br />
          <br />
          Fingerprint Linksys WRT54G or WRT54G2, or Netgear WGR614 or WPN824v2
          wireless broadband router<br />
          <br />
          Fingerprint Linksys WRT54G v8 wireless broadband router<br />
          cpe:/h:linksys:wrt54g_v8 auto<br />
          <br />
          Fingerprint Linksys WRT54G2 WAP<br />
          cpe:/h:linksys:wrt54g2 auto<br />
          <br />
          Fingerprint Linksys WRT54GC or TRENDnet TEW-431BRP wireless broadband
          router<br />
          cpe:/h:linksys:wrt54gc<br />
          cpe:/h:trendnet:tew-431brp<br />
          <br />
          Fingerprint Linksys WRT610Nv3 WAP<br />
          cpe:/h:linksys:wrt610nv3 auto<br />
          <br />
          Fingerprint Linksys WRV54G WAP<br />
          cpe:/h:linksys:wrv54g auto<br />
          <br />
          Fingerprint Linksys WVC54G webcam<br />
          cpe:/h:linksys:wvc54g auto<br />
          <br />
          Fingerprint Linux 1.0.9<br />
          cpe:/o:linux:linux_kernel:1.0.9<br />
          <br />
          Fingerprint Cobalt Qube 2700WG (Linux 2.0.34)<br />
          cpe:/o:linux:linux_kernel:2.0.34 auto<br />
          cpe:/h:cobalt:qube_2700<br />
          <br />
          Fingerprint Linux 2.0.33<br />
          cpe:/o:linux:linux_kernel:2.0.33<br />
          <br />
          Fingerprint Linux 2.0.33 (Red Hat 5.0)<br />
          cpe:/o:linux:linux_kernel:2.0.33<br />
          <br />
          Fingerprint Linux 2.0.35 - 2.0.36<br />
          cpe:/o:linux:linux_kernel:2.0 auto<br />
          <br />
          Fingerprint Linux 2.0.36 (Red Hat 5.2)<br />
          cpe:/o:linux:linux_kernel:2.0.36<br />
          <br />
          Fingerprint Linux 2.0.39 - 2.0.40 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.0 auto<br />
          <br />
          Fingerprint elmeg T240 or T444 PABX (Linux 2.0.38)<br />
          cpe:/o:linux:linux_kernel:2.0.38 auto<br />
          <br />
          Fingerprint FREESCO single-floppy router (Linux 2.0.39)<br />
          cpe:/o:linux:linux_kernel:2.0.39 auto<br />
          <br />
          Fingerprint StarDot NetCam SC webcam (Linux 2.0.39)<br />
          cpe:/o:linux:linux_kernel:2.0.39 auto<br />
          <br />
          Fingerprint TiVo series 1 (Linux 2.1.24-TiVo-2.5)<br />
          cpe:/o:linux:linux_kernel:2.1.24 auto<br />
          <br />
          Fingerprint TiVo series 1 (Sony SVR-2000 or Philips HDR112) (Linux
          2.1.24-TiVo-2.5, PowerPC)<br />
          cpe:/o:linux:linux_kernel:2.1.24 auto<br />
          cpe:/h:philips:hdr112<br />
          cpe:/h:sony:svr-2000<br />
          <br />
          Fingerprint Linux 2.2.13 (SuSE 6.3)<br />
          cpe:/o:linux:linux_kernel:2.2.13<br />
          <br />
          Fingerprint Linux 2.2.14 (Red Hat 6.2)<br />
          cpe:/o:linux:linux_kernel:2.2.14<br />
          <br />
          Fingerprint Linux 2.2.5 - 2.2.14 (Red Hat 6.0 - 6.2)<br />
          cpe:/o:linux:linux_kernel:2.2 auto<br />
          <br />
          Fingerprint Linux 2.2.9<br />
          cpe:/o:linux:linux_kernel:2.2.9 auto<br />
          <br />
          Fingerprint Netgear WG602v1 WAP (Linux 2.2.14)<br />
          cpe:/o:linux:linux_kernel:2.2.14 auto<br />
          cpe:/h:netgear:wg602v1 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03.1 (Linux 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4.37 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09.1 (Linux 2.4.35.4)<br />
          cpe:/o:linux:linux_kernel:2.4.35.4 auto<br />
          <br />
          Fingerprint Tomato firmware (Linux 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4.37 auto<br />
          <br />
          Fingerprint Check Point SecurePlatform NGX R65 firewall (Linux
          2.4.21)<br />
          cpe:/o:linux:linux_kernel:2.4.21 auto<br />
          cpe:/a:checkpoint:secureplatform_ngx:r65<br />
          <br />
          Fingerprint Check Point SecurePlatform NGX R65 firewall (Linux
          2.4.21)<br />
          cpe:/o:linux:linux_kernel:2.4.21 auto<br />
          cpe:/a:checkpoint:secureplatform_ngx:r65<br />
          <br />
          Fingerprint Check Point VPN-1 UTM appliance<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          cpe:/a:checkpoint:vpn-1<br />
          <br />
          Fingerprint IPCop firewall 1.4.16 (Linux 2.4.34)<br />
          cpe:/o:linux:linux_kernel:2.4.34<br />
          cpe:/o:ipcop:ipcop:1.4.16<br />
          <br />
          Fingerprint IPCop firewall 1.4.21 (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36<br />
          cpe:/o:ipcop:ipcop:1.4.21<br />
          <br />
          Fingerprint ISS Proventia GX3002 firewall (Linux 2.4.18)<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          cpe:/h:iss:proventia_gx3002 auto<br />
          <br />
          Fingerprint ISS Proventia GX3002C firewall (Linux 2.4.18)<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          cpe:/h:iss:proventia_gx3002c auto<br />
          <br />
          Fingerprint Linux 2.4.31<br />
          cpe:/o:linux:linux_kernel:2.4.31 auto<br />
          <br />
          Fingerprint Linux 2.4.31<br />
          cpe:/o:linux:linux_kernel:2.4.31 auto<br />
          <br />
          Fingerprint Secure Computing SG550 firewall (Linux 2.4.31)<br />
          cpe:/o:linux:linux_kernel:2.4.31 auto<br />
          cpe:/h:securecomputing:sg550 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4.37 auto<br />
          <br />
          Fingerprint LifeSize video conferencing system (Linux 2.4.21)<br />
          cpe:/o:linux:linux_kernel:2.4.21 auto<br />
          <br />
          Fingerprint Linux 2.4.18<br />
          cpe:/o:linux:linux_kernel:2.4.18<br />
          <br />
          Fingerprint Linux 2.4.18<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          <br />
          Fingerprint Linux 2.4.18<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          <br />
          Fingerprint Linux 2.4.18 - 2.4.35 (likely embedded)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.18-14 (Red Hat 8)<br />
          cpe:/o:linux:linux_kernel:2.4.18-14<br />
          <br />
          Fingerprint Linux 2.4.19<br />
          cpe:/o:linux:linux_kernel:2.4.19<br />
          <br />
          Fingerprint Linux 2.4.19 - 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.2 (Red Hat 7.1)<br />
          cpe:/o:linux:linux_kernel:2.4.2<br />
          <br />
          Fingerprint Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20<br />
          <br />
          Fingerprint Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20<br />
          <br />
          Fingerprint Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20<br />
          <br />
          Fingerprint Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Linux 2.4.20 (Red Hat 7.2)<br />
          cpe:/o:linux:linux_kernel:2.4.20<br />
          <br />
          Fingerprint Linux 2.4.20 - 2.4.27<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.20 - 2.4.37<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.20-grsec<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Linux 2.4.21<br />
          cpe:/o:linux:linux_kernel:2.4.21<br />
          <br />
          Fingerprint Linux 2.4.21<br />
          cpe:/o:linux:linux_kernel:2.4.21<br />
          <br />
          Fingerprint Linux 2.4.21<br />
          cpe:/o:linux:linux_kernel:2.4.21<br />
          <br />
          Fingerprint Linux 2.4.21<br />
          cpe:/o:linux:linux_kernel:2.4.21 auto<br />
          <br />
          Fingerprint Linux 2.4.21<br />
          cpe:/o:linux:linux_kernel:2.4.21 auto<br />
          <br />
          Fingerprint Linux 2.4.21 (SuSE 9.0)<br />
          cpe:/o:linux:linux_kernel:2.4.21<br />
          <br />
          Fingerprint Linux 2.4.21 (SuSE 9.1)<br />
          cpe:/o:linux:linux_kernel:2.4.21<br />
          <br />
          Fingerprint Linux 2.4.21 - 2.4.25 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.21 - 2.4.27<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.21 - 2.4.31 (likely embedded)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Linux 2.4.22 (x86)<br />
          cpe:/o:linux:linux_kernel:2.4.22<br />
          <br />
          Fingerprint Linux 2.4.26 (Slackware 10.0.0)<br />
          cpe:/o:linux:linux_kernel:2.4.26<br />
          <br />
          Fingerprint Linux 2.4.27<br />
          cpe:/o:linux:linux_kernel:2.4.27<br />
          <br />
          Fingerprint Linux 2.4.31 (Slackware v10.2)<br />
          cpe:/o:linux:linux_kernel:2.4.31<br />
          <br />
          Fingerprint Linux 2.4.31 w/grsec (x86)<br />
          cpe:/o:linux:linux_kernel:2.4.31 auto<br />
          <br />
          Fingerprint Linux 2.4.32<br />
          cpe:/o:linux:linux_kernel:2.4.32<br />
          <br />
          Fingerprint Linux 2.4.32 (x86)<br />
          cpe:/o:linux:linux_kernel:2.4.32<br />
          <br />
          Fingerprint Linux 2.4.33<br />
          cpe:/o:linux:linux_kernel:2.4.33<br />
          <br />
          Fingerprint Linux 2.4.33 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.4.33<br />
          <br />
          Fingerprint Linux 2.4.35<br />
          cpe:/o:linux:linux_kernel:2.4.35<br />
          <br />
          Fingerprint Linux 2.4.7<br />
          cpe:/o:linux:linux_kernel:2.4.7<br />
          <br />
          Fingerprint Linux 2.4.7<br />
          cpe:/o:linux:linux_kernel:2.4.7<br />
          <br />
          Fingerprint Linux 2.4.9 (Red Hat Enterprise Linux 2.1 AS)<br />
          cpe:/o:linux:linux_kernel:2.4.9<br />
          <br />
          Fingerprint Linux 2.4.9 - 2.4.18 (likely embedded)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint MontaVista embedded Linux 2.4.17<br />
          cpe:/o:linux:linux_kernel:2.4.17 auto<br />
          <br />
          Fingerprint MontaVista embedded Linux 2.4.17<br />
          cpe:/o:linux:linux_kernel:2.4.17 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09 (Linux 2.4.35)<br />
          cpe:/o:linux:linux_kernel:2.4.35 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09 (Linux 2.4.35.4)<br />
          cpe:/o:linux:linux_kernel:2.4.35.4 auto<br />
          <br />
          Fingerprint Toshiba Magnia SG10 server appliance (Linux 2.4.18)<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          cpe:/h:toshiba:magnia_sg10<br />
          <br />
          Fingerprint LifeSize video conferencing system or ShoreTel 8800 VoIP
          phone (Linux 2.4)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          cpe:/h:shoretel:8800 auto<br />
          <br />
          Fingerprint Macsense HomePad music server (Linux 2.4.18)<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          cpe:/h:macsense:homepad<br />
          <br />
          Fingerprint RGB Networks Modular Media Converter (Linux 2.4.30)<br />
          cpe:/o:linux:linux_kernel:2.4.30 auto<br />
          <br />
          Fingerprint Sharp SL-5500 PDA (OpenZaurus 3.5.4, Linux 2.4.18)<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          <br />
          Fingerprint Enterasys Matrix X-series router (Linux 2.4)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint Consumer Electronics Linux Forum embedded Linux 2.4.20<br />
          cpe:/o:linux:linux_kernel:2.4.20<br />
          <br />
          Fingerprint Cypress Solutions CTM-15X modem (OpenWrt firmware)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint iDirect Protocol Processor (Red Hat Enterprise Linux 3)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          cpe:/h:idirect:protocol_processor<br />
          <br />
          Fingerprint Linux 2.4.21 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.4.21<br />
          <br />
          Fingerprint Embedded Linux 2.4.22 (Linksys NSLU2 NAS device or
          Synology DS106j NAS server)<br />
          cpe:/o:linux:linux_kernel:2.4.22 auto<br />
          cpe:/h:synology:ds106j<br />
          cpe:/h:linksys:nslu2 auto<br />
          <br />
          Fingerprint Belkin F5D7633, Inventel Livebox, or T-Sinus 1054 wireless
          broadband router; or USRobotics SureConnect 9105 or ZTE ZXDSL 831 ADSL
          modem<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          cpe:/h:belkin:f5d7633<br />
          cpe:/h:inventel:livebox<br />
          cpe:/h:telekom:sinus_1054<br />
          cpe:/h:usr:sureconnect_9105<br />
          cpe:/h:zte:zxdsl_831<br />
          <br />
          Fingerprint D-Link DIR-100; DrayTek Vigor3300; or Netgear KWRGR614,
          RT614, or WG602 router (Linux 2.4)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          cpe:/h:draytek:vigor3300<br />
          cpe:/h:dlink:dir-100<br />
          cpe:/h:netgear:kwrgr614<br />
          cpe:/h:netgear:rt614<br />
          cpe:/h:netgear:wg602<br />
          <br />
          Fingerprint D-Link DSL-G624T wireless ADSL router (MontaVista embedded
          Linux 2.4.17), or Netgear DG834Bv3 ADSL router or DG834G WAP<br />
          cpe:/o:linux:linux_kernel:2.4.17 auto<br />
          cpe:/h:dlink:dsl-g624t auto<br />
          cpe:/h:netgear:dg834bv3 auto<br />
          cpe:/h:netgear:dg834bv3 auto<br />
          <br />
          Fingerprint D-Link DWL-G700AP WAP (Linux 2.4.18)<br />
          cpe:/o:linux:linux_kernel:2.4.18 auto<br />
          cpe:/h:dlink:dwl-g700ap auto<br />
          <br />
          Fingerprint DD-WRT (Linux 2.4.35s)<br />
          cpe:/o:linux:linux_kernel:2.4.35s auto<br />
          <br />
          Fingerprint DD-WRT (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36 auto<br />
          <br />
          Fingerprint DD-WRT v23 (Linux 2.4.34)<br />
          cpe:/o:linux:linux_kernel:2.4.34 auto<br />
          <br />
          Fingerprint DD-WRT v23 (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36 auto<br />
          <br />
          Fingerprint DD-WRT v23 (Linux 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4.37 auto<br />
          <br />
          Fingerprint DD-WRT v23 - v24-sp2 (Linux 2.4.20 - 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp1 (Linux 2.4)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp1 (Linux 2.4)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp1 (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp1 (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.4.36)<br />
          cpe:/o:linux:linux_kernel:2.4.36 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4.37 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4.37 auto<br />
          <br />
          Fingerprint Neptune354 firmware (MontaVista Linux 2.4.17)<br />
          cpe:/o:linux:linux_kernel:2.4.17 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 2.4.30 - 2.4.34)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 2.4.32)<br />
          cpe:/o:linux:linux_kernel:2.4.32 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 2.4.32)<br />
          cpe:/o:linux:linux_kernel:2.4.32 auto<br />
          <br />
          Fingerprint OpenWrt 0.9 - 10.03 (Linux 2.4.30 - 2.4.37)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint OpenWrt 0.9 - 7.09 (Linux 2.4.30 - 2.4.34)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09 (Linux 2.4.35.4)<br />
          cpe:/o:linux:linux_kernel:2.4.35.4 auto<br />
          <br />
          Fingerprint OpenWrt White Russian 0.9 (Linux 2.4.30)<br />
          cpe:/o:linux:linux_kernel:2.4.30 auto<br />
          <br />
          Fingerprint OpenWrt White Russian 0.9 (Linux 2.4.30)<br />
          cpe:/o:linux:linux_kernel:2.4.30 auto<br />
          <br />
          Fingerprint Sveasoft (Linux 2.4.20)<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Sveasoft Talisman 2.0 (Linux 2.4.33.7)<br />
          cpe:/o:linux:linux_kernel:2.4.33.7 auto<br />
          <br />
          Fingerprint Tomato 1.27 - 1.28 (Linux 2.4.20)<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Tomato 1.28 (Linux 2.4.20)<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          <br />
          Fingerprint Foscam FI8908W surveillance camera (Linux 2.4.20)<br />
          cpe:/o:linux:linux_kernel:2.4.20 auto<br />
          cpe:/h:foscam:fi8908w<br />
          <br />
          Fingerprint uClinux 2.4.19-uc1 (ARM)<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          <br />
          Fingerprint mbNET industrial router (Linux 2.6.26)<br />
          cpe:/o:linux:linux_kernel:2.6.26 auto<br />
          <br />
          Fingerprint D-Link DIR-300 NRU router (Linux 2.6.21)<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          cpe:/h:dlink:dir-300_nru<br />
          <br />
          Fingerprint DD-WRT (Linux 2.6.34)<br />
          cpe:/o:linux:linux_kernel:2.6.34 auto<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.6.24)<br />
          cpe:/o:linux:linux_kernel:2.6.24 auto<br />
          <br />
          Fingerprint Huawei EchoLife HG850a router (Linux 2.6.20)<br />
          cpe:/o:linux:linux_kernel:2.6.20<br />
          cpe:/h:huawei:hg850a<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB6120 or SB6141 cable modem (Linux
          2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          cpe:/h:motorola:surfboard_sb6120<br />
          cpe:/h:motorola:surfboard_sb6141<br />
          <br />
          Fingerprint OpenWrt (Linux 2.6.39)<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint OpenWrt Attitude Adjustment 12.09 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03 - Attitude Adjustment 12.09 (Linux
          2.6.32 - 2.6.37)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09 (Linux 2.6.25 - 2.6.26)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09 (Linux 2.6.25.20)<br />
          cpe:/o:linux:linux_kernel:2.6.25.20 auto<br />
          <br />
          Fingerprint Vyatta (Linux 3.0.23)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Vyatta 4.1.4 (Linux 2.6.24)<br />
          cpe:/o:linux:linux_kernel:2.6.24 auto<br />
          <br />
          Fingerprint Check Point GAiA (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          cpe:/o:checkpoint:gaia_os auto<br />
          <br />
          Fingerprint Check Point VPN-1 Firewall-1 firewall (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          cpe:/a:checkpoint:vpn-1_firewall-1<br />
          <br />
          Fingerprint Cisco SA520 firewall (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:cisco:sa520 auto<br />
          <br />
          Fingerprint Endian 2.3 or IPCop firewall 1.4.10 - 1.4.21 (Linux 2.4.31
          - 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:2.4 auto<br />
          cpe:/a:endian:firewall:2.3<br />
          cpe:/o:ipcop:ipcop:1.4<br />
          <br />
          Fingerprint Endian Firewall 2.3 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Fortinet FortiAnalyzer-400B firewall (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:fortinet:fortianalyzer-400b auto<br />
          <br />
          Fingerprint IPCop 1.9.19 or IPFire 2.9 firewall (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/o:ipcop:ipcop:1.9.19 auto<br />
          cpe:/o:ipfire:ipfire:2.9 auto<br />
          <br />
          Fingerprint IPFire 2.11 firewall (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/o:ipfire:ipfire:2.11 auto<br />
          <br />
          Fingerprint Linux 2.6.11<br />
          cpe:/o:linux:linux_kernel:2.6.11<br />
          <br />
          Fingerprint Vyatta router (Linux 2.6.26)<br />
          cpe:/o:linux:linux_kernel:2.6.26 auto<br />
          <br />
          Fingerprint Asus RT-N10 router or AXIS 211A Network Camera (Linux
          2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:asus:rt-n10<br />
          cpe:/h:axis:211_network_camera<br />
          <br />
          Fingerprint Blue Coat Director (Linux 2.6.10)<br />
          cpe:/o:linux:linux_kernel:2.6.10 auto<br />
          <br />
          Fingerprint Citrix XenServer 5.5 (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          cpe:/o:citrix:xenserver:5.5 auto<br />
          <br />
          Fingerprint Citrix XenServer 6.1 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/o:citrix:xenserver:6.1 auto<br />
          <br />
          Fingerprint DD-WRT v24-presp2 (Linux 2.6.34)<br />
          cpe:/o:linux:linux_kernel:2.6.34 auto<br />
          <br />
          Fingerprint Excito B3 file server (Linux 2.6.39)<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          cpe:/h:excito:b3<br />
          <br />
          Fingerprint Excito Bubba Two file server (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/h:excito:bubba_two<br />
          <br />
          Fingerprint IPCop 2.0 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          cpe:/o:ipcop:ipcop:2.0<br />
          <br />
          Fingerprint Linux 2.6.10 - 2.6.13 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.11<br />
          cpe:/o:linux:linux_kernel:2.6.11<br />
          <br />
          Fingerprint Linux 2.6.11 (Auditor)<br />
          cpe:/o:linux:linux_kernel:2.6.11<br />
          <br />
          Fingerprint Linux 2.6.11 (Fedora Code 3, SMP, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.11<br />
          <br />
          Fingerprint Linux 2.6.11 - 2.6.15<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.11 - 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.11 - 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.11-auditor-10 (Auditor CD, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.11 auto<br />
          <br />
          Fingerprint Linux 2.6.12<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          <br />
          Fingerprint Linux 2.6.12<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          <br />
          Fingerprint Linux 2.6.12 - 2.6.14 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.13 - 2.6.19<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.13 - 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.13 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.14 (ASPLinux 11)<br />
          cpe:/o:linux:linux_kernel:2.6.14 auto<br />
          <br />
          Fingerprint Linux 2.6.14 - 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.14 - 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.14 - 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.14.7 (PLD Linux, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.14.7 auto<br />
          <br />
          Fingerprint Linux 2.6.15<br />
          cpe:/o:linux:linux_kernel:2.6.15<br />
          <br />
          Fingerprint Linux 2.6.15<br />
          cpe:/o:linux:linux_kernel:2.6.15<br />
          <br />
          Fingerprint Linux 2.6.15 (Ubuntu 6.06)<br />
          cpe:/o:linux:linux_kernel:2.6.15<br />
          <br />
          Fingerprint Linux 2.6.15 (Ubuntu)<br />
          cpe:/o:linux:linux_kernel:2.6.15<br />
          <br />
          Fingerprint Linux 2.6.15 - 2.6.26 (likely embedded)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.15 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.15 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.15 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.15-27 (Ubuntu 6.06)<br />
          cpe:/o:linux:linux_kernel:2.6.15-27<br />
          <br />
          Fingerprint Linux 2.6.15-27 (Ubuntu)<br />
          cpe:/o:linux:linux_kernel:2.6.15-27<br />
          <br />
          Fingerprint Linux 2.6.15-28-amd64-server (Ubuntu, x86_64, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.15 auto<br />
          <br />
          Fingerprint Linux 2.6.16<br />
          cpe:/o:linux:linux_kernel:2.6.16<br />
          <br />
          Fingerprint Linux 2.6.16<br />
          cpe:/o:linux:linux_kernel:2.6.16<br />
          <br />
          Fingerprint Linux 2.6.16<br />
          cpe:/o:linux:linux_kernel:2.6.16 auto<br />
          <br />
          Fingerprint Linux 2.6.16<br />
          cpe:/o:linux:linux_kernel:2.6.16 auto<br />
          <br />
          Fingerprint Linux 2.6.16<br />
          cpe:/o:linux:linux_kernel:2.6.16 auto<br />
          <br />
          Fingerprint Linux 2.6.16 (SUSE Linux Enterprise Server 10)<br />
          cpe:/o:linux:linux_kernel:2.6.16<br />
          <br />
          Fingerprint Linux 2.6.16 - 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.16 - 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.16 - 2.6.25<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.16 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.16 - 2.6.35 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.16.27 (fli4l 3.1.1)<br />
          cpe:/o:linux:linux_kernel:2.6.16-27<br />
          <br />
          Fingerprint Linux 2.6.17<br />
          cpe:/o:linux:linux_kernel:2.6.17<br />
          <br />
          Fingerprint Linux 2.6.17<br />
          cpe:/o:linux:linux_kernel:2.6.17 auto<br />
          <br />
          Fingerprint Linux 2.6.17 (Mandriva)<br />
          cpe:/o:linux:linux_kernel:2.6.17<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.21 (x86)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.26 (Gentoo, x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.17 - 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18 (CentOS 5)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (CentOS 5, x86_64, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (CentOS 5, x86_64, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (CentOS 5.2)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (Centos 5.3)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (CentOS 5.4)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (ClarkConnect 4.3 Enterprise Edition)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (Debian 4, VMware)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (Debian 4.0, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (Debian, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (Slackware 11.0)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 (x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.25<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.18 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.18 - 3.1<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.18-8.el5 (Red Hat Enterprise Linux 5)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18-8.el5 (Red Hat Enterprise Linux 5, x86_64,
          SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18-8.el5 (Red Hat Enterprise Linux Server 5,
          x86_64, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18-em64t (x86-64)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint Linux 2.6.18.5 (Ubuntu, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.18.5<br />
          <br />
          Fingerprint Linux 2.6.18.8 (openSUSE 10.2)<br />
          cpe:/o:linux:linux_kernel:2.6.18.8<br />
          <br />
          Fingerprint Linux 2.6.18.8 (openSUSE 10.2, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18.8<br />
          <br />
          Fingerprint Linux 2.6.18.pi (x86)<br />
          cpe:/o:linux:linux_kernel:2.6.18.pi auto<br />
          <br />
          Fingerprint Linux 2.6.19 (Gentoo)<br />
          cpe:/o:linux:linux_kernel:2.6.19<br />
          <br />
          Fingerprint Linux 2.6.19 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.19 - 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.19 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.19 - 3.0<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6.20<br />
          <br />
          Fingerprint Linux 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6.20<br />
          <br />
          Fingerprint Linux 2.6.20<br />
          cpe:/o:linux:linux_kernel:2.6.20 auto<br />
          <br />
          Fingerprint Linux 2.6.20 (Ubuntu, x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6.20<br />
          <br />
          Fingerprint Linux 2.6.20 - 2.6.24 (Ubuntu 7.04 - 8.04)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.20 - 2.6.25<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.20 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.20-1 (Fedora Core 5)<br />
          cpe:/o:linux:linux_kernel:2.6.20-1<br />
          <br />
          Fingerprint Linux 2.6.20-15-generic (x86, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.20 auto<br />
          <br />
          Fingerprint Linux 2.6.20-grml<br />
          cpe:/o:linux:linux_kernel:2.6.20 auto<br />
          <br />
          Fingerprint Linux 2.6.20.6<br />
          cpe:/o:linux:linux_kernel:2.6.20.6<br />
          <br />
          Fingerprint Linux 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6.21<br />
          <br />
          Fingerprint Linux 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6.21<br />
          <br />
          Fingerprint Linux 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6.21<br />
          <br />
          Fingerprint Linux 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          <br />
          Fingerprint Linux 2.6.21 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.21 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.21-gentoo-r4 (PowerPC)<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          <br />
          Fingerprint Linux 2.6.21.1 (x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6.21.1<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Linux 2.6.22<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Linux 2.6.22 (Debian 4.0)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (embedded, ARM)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Fedora 7)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Fedora Core 6)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Gentoo, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Kubuntu, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (LinuxMCE)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (SPARC)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Ubuntu 7.04, x86, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Ubuntu 7.10)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Ubuntu 7.10, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Ubuntu 7.10, x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Ubuntu 8.04 Server Edition)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 (Ubuntu, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Linux 2.6.22 - 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.22.1-32.fc6 (x86, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.22.1 auto<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23 (Gentoo)<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint Linux 2.6.23 - 2.6.29<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.23 - 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.23-gentoo-r3<br />
          cpe:/o:linux:linux_kernel:2.6.23 auto<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24 auto<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24 auto<br />
          <br />
          Fingerprint Linux 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 (Debian)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 (Debian)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 (Gentoo)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 (Gentoo, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 (Ubuntu 8.04)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 (Ubuntu 8.04)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.25<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.26 (Debian)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.33<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.25 (Arch Linux)<br />
          cpe:/o:linux:linux_kernel:2.6.25<br />
          <br />
          Fingerprint Linux 2.6.25 (Gentoo)<br />
          cpe:/o:linux:linux_kernel:2.6.25<br />
          <br />
          Fingerprint Linux 2.6.25 (openSUSE 11.0)<br />
          cpe:/o:linux:linux_kernel:2.6.25<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26 auto<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26 auto<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26 auto<br />
          <br />
          Fingerprint Linux 2.6.26<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26 (PCLinuxOS)<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Linux 2.6.26 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.26 - 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27 auto<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27 (Arch Linux)<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27 (Arch Linux)<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27 (openSUSE 11.1)<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27 (Ubuntu 8.10)<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Linux 2.6.27 - 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.27 - 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.27.21-grsec<br />
          cpe:/o:linux:linux_kernel:2.6.27.21 auto<br />
          <br />
          Fingerprint Linux 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6.28<br />
          <br />
          Fingerprint Linux 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6.28<br />
          <br />
          Fingerprint Linux 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6.28<br />
          <br />
          Fingerprint Linux 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6.28<br />
          <br />
          Fingerprint Linux 2.6.28<br />
          cpe:/o:linux:linux_kernel:2.6.28 auto<br />
          <br />
          Fingerprint Linux 2.6.28 (Ubuntu)<br />
          cpe:/o:linux:linux_kernel:2.6.28<br />
          <br />
          Fingerprint Linux 2.6.28 (Ubuntu)<br />
          cpe:/o:linux:linux_kernel:2.6.28<br />
          <br />
          Fingerprint Linux 2.6.29<br />
          cpe:/o:linux:linux_kernel:2.6.29<br />
          <br />
          Fingerprint Linux 2.6.29<br />
          cpe:/o:linux:linux_kernel:2.6.29<br />
          <br />
          Fingerprint Linux 2.6.29<br />
          cpe:/o:linux:linux_kernel:2.6.29<br />
          <br />
          Fingerprint Linux 2.6.29 (Gentoo)<br />
          cpe:/o:linux:linux_kernel:2.6.29<br />
          <br />
          Fingerprint Linux 2.6.29 - 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6.30<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          <br />
          Fingerprint Linux 2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.31 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.31<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 3.0<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.31 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.33<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.32 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.0<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.1<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.10<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.10<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.13<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.3<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.4.1<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.5<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.5<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 - 3.9<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.32 or 3.10<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          cpe:/o:linux:linux_kernel:3.10<br />
          <br />
          Fingerprint Linux 2.6.33<br />
          cpe:/o:linux:linux_kernel:2.6.33<br />
          <br />
          Fingerprint Linux 2.6.33<br />
          cpe:/o:linux:linux_kernel:2.6.33<br />
          <br />
          Fingerprint Linux 2.6.33<br />
          cpe:/o:linux:linux_kernel:2.6.33<br />
          <br />
          Fingerprint Linux 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6.34<br />
          <br />
          Fingerprint Linux 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6.34<br />
          <br />
          Fingerprint Linux 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6.34<br />
          <br />
          Fingerprint Linux 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6.34<br />
          <br />
          Fingerprint Linux 2.6.34<br />
          cpe:/o:linux:linux_kernel:2.6.34 auto<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint Linux 2.6.35<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint Linux 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6.36<br />
          <br />
          Fingerprint Linux 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6.36<br />
          <br />
          Fingerprint Linux 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6.36<br />
          <br />
          Fingerprint Linux 2.6.36<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          <br />
          Fingerprint Linux 2.6.36 - 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.36 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.36<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Linux 2.6.37<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Linux 2.6.37 (OpenSUSE 11.4)<br />
          cpe:/o:linux:linux_kernel:2.6.37<br />
          <br />
          Fingerprint Linux 2.6.37 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38 (likely embedded)<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Linux 2.6.38 - 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.38 - 3.0<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          cpe:/o:linux:linux_kernel:3<br />
          <br />
          Fingerprint Linux 2.6.38 - 3.0<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.38 - 3.0<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint Linux 2.6.39<br />
          cpe:/o:linux:linux_kernel:2.6.39 auto<br />
          <br />
          Fingerprint Linux 2.6.39 - 3.2<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 2.6.5<br />
          cpe:/o:linux:linux_kernel:2.6.5<br />
          <br />
          Fingerprint Linux 2.6.5<br />
          cpe:/o:linux:linux_kernel:2.6.5<br />
          <br />
          Fingerprint Linux 2.6.5<br />
          cpe:/o:linux:linux_kernel:2.6.5<br />
          <br />
          Fingerprint Linux 2.6.5<br />
          cpe:/o:linux:linux_kernel:2.6.5<br />
          <br />
          Fingerprint Linux 2.6.5 (Fedora Core 2)<br />
          cpe:/o:linux:linux_kernel:2.6.5<br />
          <br />
          Fingerprint Linux 2.6.5 (SUSE Enterprise Server 9)<br />
          cpe:/o:linux:linux_kernel:2.6.5<br />
          <br />
          Fingerprint Linux 2.6.5 - 2.6.12<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.5 - 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.5 - 2.6.19<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.5 - 2.6.9 (x86)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.5-7.283-smp (SuSE Enterprise Server 9, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.5 auto<br />
          <br />
          Fingerprint Linux 2.6.6<br />
          cpe:/o:linux:linux_kernel:2.6.6<br />
          <br />
          Fingerprint Linux 2.6.8<br />
          cpe:/o:linux:linux_kernel:2.6.8<br />
          <br />
          Fingerprint Linux 2.6.8 (Debian 3.1)<br />
          cpe:/o:linux:linux_kernel:2.6.8<br />
          <br />
          Fingerprint Linux 2.6.8 (Debian Sarge)<br />
          cpe:/o:linux:linux_kernel:2.6.8<br />
          <br />
          Fingerprint Linux 2.6.8 (Debian, x86)<br />
          cpe:/o:linux:linux_kernel:2.6.8<br />
          <br />
          Fingerprint Linux 2.6.8 (x86)<br />
          cpe:/o:linux:linux_kernel:2.6.8<br />
          <br />
          Fingerprint Linux 2.6.8 - 2.6.12<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.8 - 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.8 - 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9<br />
          cpe:/o:linux:linux_kernel:2.6.9 auto<br />
          <br />
          Fingerprint Linux 2.6.9 (CentOS 4.3)<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9 (CentOS 4.4)<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9 (Red Hat Enterprise Linux, x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6.9<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.11<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.14<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.16<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.18<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.19<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.21<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.24<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.27<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.30<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9 - 2.6.33<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.9-022stab078.19-enterprise (CentOS 4.2 x86)<br />
          cpe:/o:linux:linux_kernel:2.6.9 auto<br />
          <br />
          Fingerprint Linux 2.6.9-22.0.1.EL (CentOS 4.4)<br />
          cpe:/o:linux:linux_kernel:2.6.9 auto<br />
          <br />
          Fingerprint Linux 2.6.9-42.ELsmp (Red Hat Enterprise Linux AS release
          4, x86_64)<br />
          cpe:/o:linux:linux_kernel:2.6.9 auto<br />
          <br />
          Fingerprint Linux 2.6.9-55.0.2.EL (Red Hat Enterprise Linux)<br />
          cpe:/o:linux:linux_kernel:2.6.9 auto<br />
          <br />
          Fingerprint OpenVZ Linux virtualization kernel (Linux
          2.6.18-028stab023.1+31-openvz-smp)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint OpenVZ Linux virtualization kernel (Linux
          2.6.18-028stab045)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze - Backfire or SuperMicro BMC (Linux
          2.6.19 - 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Oracle Enterprise Linux 6 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Red Hat Enterprise Linux Server release 5.2 (Tikanga)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Roku 2 XS media player (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Smoothwall Express 3.0 (Linux 2.6.16)<br />
          cpe:/o:linux:linux_kernel:2.6.16<br />
          <br />
          Fingerprint Kemp LoadMaster 2500 load balancer<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:kemp:loadmaster_2500<br />
          <br />
          Fingerprint DirecTV HR34 DVR (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          cpe:/h:directv:hr34<br />
          <br />
          Fingerprint Dish Network VIP 722k DVR (Linux 2.6)<br />
          cpe:/h:dish:vip_722k<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Infomir MAG-250 set-top box<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:infomir:mag-250<br />
          <br />
          Fingerprint LG Smart TV LS570S (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:lg:ls570s<br />
          <br />
          Fingerprint Linux 2.6.12 (Dreambox derivative)<br />
          cpe:/o:linux:linux_kernel:2.6.12<br />
          <br />
          Fingerprint Linux 2.6.27 (likely embedded)<br />
          cpe:/o:linux:linux_kernel:2.6.27<br />
          <br />
          Fingerprint Logitech Squeezebox Touch media player (Linux 2.6.26)<br />
          cpe:/o:linux:linux_kernel:2.6.26 auto<br />
          cpe:/h:logitech:squeezebox_touch<br />
          <br />
          Fingerprint Motorola KreaTV (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Nokia N800 Internet tablet (Linux 2.6.21, ARM)<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          cpe:/h:nokia:n800<br />
          <br />
          Fingerprint ProVision-ISR security DVR<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Star Track SRT2014HD satellite receiver (Linux 2.6.23)<br />
          cpe:/o:linux:linux_kernel:2.6.23 auto<br />
          cpe:/h:star_track:srt2014hd<br />
          <br />
          Fingerprint ZyXEL STB-1001H set-top box (Linux 2.6.17)<br />
          cpe:/o:linux:linux_kernel:2.6.17 auto<br />
          cpe:/h:zyxel:stb-1001h<br />
          <br />
          Fingerprint Cisco UC320 PBX (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:cisco:uc320<br />
          <br />
          Fingerprint Cisco UC320W PBX (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:cisco:uc320w<br />
          <br />
          Fingerprint Elastix PBX (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint LG IPECS 300 VoIP PBX (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:lg:ipecs_300<br />
          <br />
          Fingerprint CyanogenMod 7.1.0 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.24 (Palm Pre mobile phone)<br />
          cpe:/o:linux:linux_kernel:2.6.24<br />
          <br />
          Fingerprint Nokia N9 phone (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/h:nokia:n9<br />
          <br />
          Fingerprint Nokia N900 mobile phone (Linux 2.6.28)<br />
          cpe:/o:linux:linux_kernel:2.6.28 auto<br />
          cpe:/h:nokia:n900<br />
          <br />
          Fingerprint WebSense proxy appliance (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Dell DRAC 5 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:dell:remote_access_card:5<br />
          <br />
          Fingerprint Dell iDRAC 6 remote access controller (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:dell:idrac6_firmware<br />
          <br />
          Fingerprint Supermicro AOC-SIMSO+ IPMI card or Epson Artisan 837
          printer<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:supermicro:aoc-simso+<br />
          cpe:/h:epson:artisan_837 auto<br />
          <br />
          Fingerprint Supermicro IPMI BMC (Linux 2.6.24)<br />
          cpe:/o:linux:linux_kernel:2.6.24 auto<br />
          cpe:/o:supermicro:intelligent_platform_management_firmware<br />
          <br />
          Fingerprint Cisco 2600-series Content Engine Module (Linux 2.4.16)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Cisco RV320 router (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/h:cisco:rv320 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 4.9 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/o:mikrotik:routeros:4.9 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 5.5 (Linux 2.6.35)<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          cpe:/o:mikrotik:routeros:5.5 auto<br />
          <br />
          Fingerprint Symantec Web Gateway 5.2.0.361 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/a:symantec:web_gateway:5.2<br />
          <br />
          Fingerprint Google Mini search appliance v.4.6.4G66<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint HIKVISION DS-7600 Linux Embedded NVR (Linux 2.6.10)<br />
          cpe:/o:linux:linux_kernel:2.6.10 auto<br />
          cpe:/h:hikvision:ds-7600<br />
          <br />
          Fingerprint Linux 2.6.31 - 2.6.35 (embedded)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Meinberg LANTIME M600 NTP server (Linux 2.6.15)<br />
          cpe:/o:linux:linux_kernel:2.6.15 auto<br />
          cpe:/h:meinberg:lantime_m600<br />
          <br />
          Fingerprint Vivint alarm panel (Linux 2.6.21)<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          <br />
          Fingerprint WANEM network emulator (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint D-Link DNS-323 NAS device (Linux 2.6.12)<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          cpe:/h:dlink:dns-323 auto<br />
          <br />
          Fingerprint D-Link DNS-323 NAS device (Linux 2.6.12)<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          cpe:/h:dlink:dns-323 auto<br />
          <br />
          Fingerprint Drobo 5D NAS (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          cpe:/h:drobo:5d<br />
          <br />
          Fingerprint Iomega StorCenter ix2-200 NAS device (Linux 2.6.31)<br />
          cpe:/o:linux:linux_kernel:2.6.31 auto<br />
          cpe:/h:iomega:storcenter_ix2-200 auto<br />
          <br />
          Fingerprint LaCie 5big Network 2 NAS device (Linux 2.6.31)<br />
          cpe:/o:linux:linux_kernel:2.6.31 auto<br />
          cpe:/h:lacie:5big_network_2<br />
          <br />
          Fingerprint LaCie d2 NAS device (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:lacie:d2 auto<br />
          <br />
          Fingerprint LG N4B2ND4 NAS device (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:lg:n4b2nd4<br />
          <br />
          Fingerprint Linksys NSLU2 NAS device running SlugOS/LE (Linux
          2.6.16)<br />
          cpe:/o:linux:linux_kernel:2.6.16<br />
          <br />
          Fingerprint Linux 2.6.12<br />
          cpe:/o:linux:linux_kernel:2.6.12<br />
          <br />
          Fingerprint Linux 2.6.32<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint MitraStar NAS (Linux 2.6.31)<br />
          cpe:/o:linux:linux_kernel:2.6.31 auto<br />
          <br />
          Fingerprint NAS device (Linux 2.6.15): GalaxyMetalGear 4500MGB, Icy
          Box IB-NAS4220-B, or MRT Dual GigaNAS<br />
          cpe:/h:galaxymetalgear:4500mgb<br />
          cpe:/h:icy_box:ib-nas4220<br />
          cpe:/h:mrt:giganas<br />
          cpe:/o:linux:linux_kernel:2.6.15 auto<br />
          <br />
          Fingerprint Netgear RAIDiator 4.00 - 4.1.8 NAS device (Linux
          2.6.17)<br />
          cpe:/o:linux:linux_kernel:2.6.17 auto<br />
          cpe:/o:netgear:raidiator:4 auto<br />
          <br />
          Fingerprint Netgear ReadyNAS 3200 NAS device (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:netgear:readynas_3200 auto<br />
          <br />
          Fingerprint Promise SmartStor NS2300 or Synology DS207 NAS device
          (Linux 2.6.15 - 2.6.20)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:promise:smartstor_ns2300<br />
          cpe:/h:synology:ds207<br />
          <br />
          Fingerprint Synology DiskStation Manager 4.3 (Linux 2.6.32 or 3.2)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/a:synology:diskstation_manager:4.3<br />
          <br />
          Fingerprint Synology DiskStation Manager 4.3 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/a:synology:diskstation_manager:4.3<br />
          <br />
          Fingerprint Synology DS209 NAS device (Linux 2.6.32) or Polycom HDX
          7000 Touch Control<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/a:synology:diskstation:3.2<br />
          <br />
          Fingerprint Synology DS212j NAS device (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/a:synology:diskstation_manager:4.1<br />
          cpe:/h:synology:ds212j<br />
          <br />
          Fingerprint Synology VS240HD Surveillance Station (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:synology:vs240hd<br />
          <br />
          Fingerprint Western Digital MyBook World Edition 2 NAS device (Linux
          2.6.17.14)<br />
          cpe:/o:linux:linux_kernel:2.6.17.14 auto<br />
          <br />
          Fingerprint Avaya Communication Manager (Linux 2.6.11)<br />
          cpe:/o:linux:linux_kernel:2.6.11 auto<br />
          cpe:/a:avaya:communication_manager<br />
          <br />
          Fingerprint IGEL UD3 thin client (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:igel:ud3 auto<br />
          <br />
          Fingerprint Wyse S50 thin client (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:wyse:s50 auto<br />
          <br />
          Fingerprint Lantronix SLC 8 terminal server (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:lantronix:slc_8<br />
          <br />
          Fingerprint Digium D70 IP phone<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:digium:d70<br />
          <br />
          Fingerprint Asus RT-N16 WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:asus:rt-n16 auto<br />
          <br />
          Fingerprint Asus RT-N16 WAP (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          cpe:/h:asus:rt-n16 auto<br />
          <br />
          Fingerprint Asus RT-N66U WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:asus:rt-n66u auto<br />
          <br />
          Fingerprint AVM FRITZ!Box FON WLAN 7170 WAP (Linux 2.6)<br />
          cpe:/h:avm:fritz%21box_fon_wlan_7170<br />
          cpe:/o:linux:linux_kernel:2.6<br />
          <br />
          Fingerprint AVM FRITZ!Box FON WLAN 7170 WAP (Linux 2.6.13)<br />
          cpe:/h:avm:fritz%21box_fon_wlan_7170<br />
          cpe:/o:linux:linux_kernel:2.6.13<br />
          <br />
          Fingerprint Ceedtec CD-730 WiMAX WAP (Linux 2.6.23)<br />
          cpe:/o:linux:linux_kernel:2.6.23 auto<br />
          cpe:/h:ceedtec:cd-730<br />
          <br />
          Fingerprint Cisco AP541N WAP (Linux 2.6.21) or Avaya ERS 5510
          switch<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          cpe:/h:cisco:ap541n auto<br />
          cpe:/h:avaya:ers_5510 auto<br />
          <br />
          Fingerprint Cisco RV042 WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:cisco:rv042 auto<br />
          <br />
          Fingerprint Cisco SRP 521W WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:cisco:srp_521w auto<br />
          <br />
          Fingerprint Cisco SRP 527 WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:cisco:srp_527 auto<br />
          <br />
          Fingerprint Cisco WAP4410N WAP (Linux 2.6.15)<br />
          cpe:/o:linux:linux_kernel:2.6.15 auto<br />
          cpe:/h:cisco:wap4410n auto<br />
          <br />
          Fingerprint D-Link DIR-300 WAP (Linux 2.6.21)<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          cpe:/h:dlink:dir-300 auto<br />
          <br />
          Fingerprint D-Link DIR-600 or DIR-645 WAP (Linux 2.6.33)<br />
          cpe:/o:linux:linux_kernel:2.6.33 auto<br />
          cpe:/h:dlink:dir-600<br />
          cpe:/h:dlink:dir-645<br />
          <br />
          Fingerprint D-Link DIR-818LW WAP (Linux 2.6.30)<br />
          cpe:/o:linux:linux_kernel:2.6.30 auto<br />
          cpe:/h:dlink:dir-818lw auto<br />
          <br />
          Fingerprint D-Link DIR-865L WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:dlink:dir-865l auto<br />
          <br />
          Fingerprint DD-WRT (Linux 2.6.23, MIPS)<br />
          cpe:/o:linux:linux_kernel:2.6.23<br />
          <br />
          Fingerprint DD-WRT (Linux 2.6.24)<br />
          cpe:/o:linux:linux_kernel:2.6.24 auto<br />
          <br />
          Fingerprint DD-WRT v24 (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 2.6.34)<br />
          cpe:/o:linux:linux_kernel:2.6.34<br />
          <br />
          Fingerprint Gennet OxyGEN wireless ADSL router (Linux 2.6.11)<br />
          cpe:/o:linux:linux_kernel:2.6.11 auto<br />
          cpe:/h:gennet:oxygen_router<br />
          <br />
          Fingerprint Huawei EchoLife WAP (Linux 2.6.34)<br />
          cpe:/o:linux:linux_kernel:2.6.34 auto<br />
          cpe:/h:huawei:echolife<br />
          <br />
          Fingerprint Huawei HG8245T GPON ONU WAP (Linux 2.6.21) or Sagemcom
          F@st 4350 WAP<br />
          cpe:/o:linux:linux_kernel:2.6.21 auto<br />
          cpe:/h:huawei:hg8245t<br />
          cpe:/h:sagemcom:fast_4350<br />
          <br />
          Fingerprint IO-Data WN-G300R WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:io-data:wn-g300r auto<br />
          <br />
          Fingerprint Linux 2.6.15 (likely TP-LINK WAP)<br />
          cpe:/o:linux:linux_kernel:2.6.15<br />
          <br />
          Fingerprint Linux 2.6.17 (ZyXEL NWA570N WAP)<br />
          cpe:/o:linux:linux_kernel:2.6.17 auto<br />
          cpe:/h:zyxel:nwa570n<br />
          <br />
          Fingerprint Netgear WNDAP660 WAP (Linux 2.6.36)<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          cpe:/h:netgear:wndap660 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03.1 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03.1 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03.1 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint OpenWrt Backfire 10.03.1 (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 7.09 (Linux 2.6.17 - 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint OpenWrt Kamikaze 7.09 (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint OpenWrt Kamikaze 8.09 (Linux 2.6.26)<br />
          cpe:/o:linux:linux_kernel:2.6.26<br />
          <br />
          Fingerprint Orange Livebox Pro v3 (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Starbridge Networks 1531 wireless ASDL modem<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:starbridge_networks:1531<br />
          <br />
          Fingerprint Tomato 1.28 (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22<br />
          <br />
          Fingerprint Tomato 1.28 (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Tomato firmware (Linux 2.6.22)<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Ubiquiti AirMax NanoStation M5 WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:ubnt:airmax_nanostation_m5 auto<br />
          <br />
          Fingerprint Ubiquiti AirMax NanoStation WAP (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          cpe:/h:ubnt:airmax_nanostation<br />
          <br />
          Fingerprint Ubiquiti AirMax NanoStation WAP (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/h:ubnt:airmax_nanostation<br />
          <br />
          Fingerprint Ubiquiti NanoStation Loco M2 WAP (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          cpe:/h:ubnt:nanostation_loco_m2 auto<br />
          <br />
          Fingerprint Ubiquiti WAP (Linux 2.6.32)<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Xiaomi Mi WiFi 2.2 (Linux 2.6.36)<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          <br />
          Fingerprint Xiaomi MiWiFi WAP (Linux 2.6.36)<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          <br />
          Fingerprint Axis 207 camera (Linux 2.6.16)<br />
          cpe:/o:linux:linux_kernel:2.6.16 auto<br />
          cpe:/h:axis:207_network_camera<br />
          <br />
          Fingerprint AXIS 210A or 211 Network Camera (Linux 2.6.17)<br />
          cpe:/o:linux:linux_kernel:2.6.17 auto<br />
          cpe:/h:axis:210a_network_camera<br />
          cpe:/h:axis:211_network_camera<br />
          <br />
          Fingerprint AXIS 211A Network Camera (Linux 2.6.20)<br />
          cpe:/o:linux:linux_kernel:2.6.20 auto<br />
          cpe:/h:axis:211a_network_camera<br />
          <br />
          Fingerprint Logitech Alert 750i camera (Linux 2.6.18)<br />
          cpe:/o:linux:linux_kernel:2.6.18 auto<br />
          cpe:/h:logitech:alert_750i<br />
          <br />
          Fingerprint Ubiquiti UniFi Video Camera (Linux 2.6.38)<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          cpe:/a:ubnt:unifi_video<br />
          <br />
          Fingerprint Vilar IP Camera (Linux 2.6)<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint DD-WRT v24 or v30 (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10<br />
          <br />
          Fingerprint Gargoyle 1.5.10 router firmware (Linux 3.3)<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          cpe:/o:eric_bishop:gargoyle:1.5.10<br />
          <br />
          Fingerprint OpenWrt (Linux 3.0)<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 3.2)<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 3.3)<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint OpenWrt (Linux 3.3)<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint OpenWrt 12.09-rc1 Attitude Adjustment (Linux 3.3 - 3.7)<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint OpenWrt Attitude Adjustment (Linux 3.3)<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint OpenWrt Attitude Adjustment (Linux 3.3) - Barrier Breaker
          (Linux 3.8)<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint OpenWrt Attitude Adjustment 12.09 (Linux 3.3.8)<br />
          cpe:/o:linux:linux_kernel:3.3.8 auto<br />
          <br />
          Fingerprint OpenWrt Attitude Adjustment 12.09-RC2 (Linux 3.3.8)<br />
          cpe:/o:linux:linux_kernel:3.3.8 auto<br />
          <br />
          Fingerprint OpenWrt Barrier Breaker (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer 15.05 (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer 15.05 (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer 15.05 (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint IPCop 2 firewall (Linux 3.4)<br />
          cpe:/o:linux:linux_kernel:3.4<br />
          cpe:/o:ipcop:ipcop:2 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.1<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.1<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.2<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.2<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.2<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.2<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 - 3.5<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.0 or 3.5<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint Linux 3.0.0<br />
          cpe:/o:linux:linux_kernel:3.0.0 auto<br />
          <br />
          Fingerprint Linux 3.0.0<br />
          cpe:/o:linux:linux_kernel:3.0.0 auto<br />
          <br />
          Fingerprint Linux 3.1<br />
          cpe:/o:linux:linux_kernel:3.1 auto<br />
          <br />
          Fingerprint Linux 3.1<br />
          cpe:/o:linux:linux_kernel:3.1 auto<br />
          <br />
          Fingerprint Linux 3.1<br />
          cpe:/o:linux:linux_kernel:3.1 auto<br />
          <br />
          Fingerprint Linux 3.1<br />
          cpe:/o:linux:linux_kernel:3.1 auto<br />
          <br />
          Fingerprint Linux 3.1<br />
          cpe:/o:linux:linux_kernel:3.1 auto<br />
          <br />
          Fingerprint Linux 3.1<br />
          cpe:/o:linux:linux_kernel:3.1 auto<br />
          <br />
          Fingerprint Linux 3.1 - 3.2<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.1 - 3.2<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Linux 3.10 - 3.12<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.10 - 3.13<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.10 - 3.16<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.10 - 4.1<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.10 - 4.11<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.10 - 4.4<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.11<br />
          cpe:/o:linux:linux_kernel:3.11 auto<br />
          <br />
          Fingerprint Linux 3.11<br />
          cpe:/o:linux:linux_kernel:3.11 auto<br />
          <br />
          Fingerprint Linux 3.11<br />
          cpe:/o:linux:linux_kernel:3.11 auto<br />
          <br />
          Fingerprint Linux 3.11 - 3.12<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.11 - 3.12<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.11 - 3.13<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.11 - 3.14<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.11 - 4.1<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.12<br />
          cpe:/o:linux:linux_kernel:3.12 auto<br />
          <br />
          Fingerprint Linux 3.12<br />
          cpe:/o:linux:linux_kernel:3.12 auto<br />
          <br />
          Fingerprint Linux 3.12<br />
          cpe:/o:linux:linux_kernel:3.12 auto<br />
          <br />
          Fingerprint Linux 3.12<br />
          cpe:/o:linux:linux_kernel:3.12 auto<br />
          <br />
          Fingerprint Linux 3.12 - 4.10<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          <br />
          Fingerprint Linux 3.13 - 3.16<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.13 - 4.4<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.13 or 4.2<br />
          cpe:/o:linux:linux_kernel:3.13<br />
          cpe:/o:linux:linux_kernel:4.2<br />
          <br />
          Fingerprint Linux 3.13 or 4.2<br />
          cpe:/o:linux:linux_kernel:3.13 auto<br />
          cpe:/o:linux:linux_kernel:4.2<br />
          <br />
          Fingerprint Linux 3.14<br />
          cpe:/o:linux:linux_kernel:3.14 auto<br />
          <br />
          Fingerprint Linux 3.14<br />
          cpe:/o:linux:linux_kernel:3.14 auto<br />
          <br />
          Fingerprint Linux 3.14<br />
          cpe:/o:linux:linux_kernel:3.14 auto<br />
          <br />
          Fingerprint Linux 3.14<br />
          cpe:/o:linux:linux_kernel:3.14 auto<br />
          <br />
          Fingerprint Linux 3.14<br />
          cpe:/o:linux:linux_kernel:3.14 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16<br />
          cpe:/o:linux:linux_kernel:3.16 auto<br />
          <br />
          Fingerprint Linux 3.16 - 4.6<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Linux 3.18<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Linux 3.18 (OpenWrt)<br />
          cpe:/o:linux:linux_kernel:3.18 auto<br />
          <br />
          Fingerprint Linux 3.19<br />
          cpe:/o:linux:linux_kernel:3.19<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Linux 3.2 - 3.10<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.2 - 3.16<br />
          cpe:/o:linux:linux_kernel:3<br />
          <br />
          Fingerprint Linux 3.2 - 3.5<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.2 - 3.5<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.2 - 3.5<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.2 - 3.8<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.2 - 3.8<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.2 - 4.9<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.2.0<br />
          cpe:/o:linux:linux_kernel:3.2.0 auto<br />
          <br />
          Fingerprint Linux 3.2.0<br />
          cpe:/o:linux:linux_kernel:3.2.0 auto<br />
          <br />
          Fingerprint Linux 3.2.1<br />
          cpe:/o:linux:linux_kernel:3.2.1 auto<br />
          <br />
          Fingerprint Linux 3.2.38<br />
          cpe:/o:linux:linux_kernel:3.2.38 auto<br />
          <br />
          Fingerprint Linux 3.3<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint Linux 3.3<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint Linux 3.3<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint Linux 3.3<br />
          cpe:/o:linux:linux_kernel:3.3 auto<br />
          <br />
          Fingerprint Linux 3.4<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Linux 3.4<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Linux 3.4<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Linux 3.4<br />
          cpe:/o:linux:linux_kernel:3.4<br />
          <br />
          Fingerprint Linux 3.4<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint Linux 3.4 - 3.10<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.5<br />
          cpe:/o:linux:linux_kernel:3.5 auto<br />
          <br />
          Fingerprint Linux 3.6<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Linux 3.6<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Linux 3.6<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Linux 3.6<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Linux 3.6<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Linux 3.6 - 3.10<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.6.10<br />
          cpe:/o:linux:linux_kernel:3.6.10 auto<br />
          <br />
          Fingerprint Linux 3.7<br />
          cpe:/o:linux:linux_kernel:3.7 auto<br />
          <br />
          Fingerprint Linux 3.7<br />
          cpe:/o:linux:linux_kernel:3.7 auto<br />
          <br />
          Fingerprint Linux 3.7<br />
          cpe:/o:linux:linux_kernel:3.7 auto<br />
          <br />
          Fingerprint Linux 3.7<br />
          cpe:/o:linux:linux_kernel:3.7 auto<br />
          <br />
          Fingerprint Linux 3.7<br />
          cpe:/o:linux:linux_kernel:3.7 auto<br />
          <br />
          Fingerprint Linux 3.7 - 3.10<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.7 - 3.11<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.7 - 3.8<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.7 - 3.9<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint Linux 3.8 - 3.11<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.8 - 3.13<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.8 - 3.9<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 3.8 - 4.14<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.8 - 4.4<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 3.9<br />
          cpe:/o:linux:linux_kernel:3.9 auto<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer (Linux 3.10 - 3.14)<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          <br />
          Fingerprint PandaBoard ES Android development board (Linux 3.2)<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Philips Hue Bridge 2.0 (Linux 3.14)<br />
          cpe:/o:linux:linux_kernel:3.14 auto<br />
          cpe:/h:philips:hue_bridge_2.0<br />
          <br />
          Fingerprint Kemp LoadMaster LM-2400 Firmware 7.1<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/h:kemp:loadmaster_2400<br />
          <br />
          Fingerprint Linux 3.0<br />
          cpe:/o:linux:linux_kernel:3.0 auto<br />
          <br />
          Fingerprint XBMCbuntu Frodo v12.2 (Linux 3.X)<br />
          cpe:/o:linux:linux_kernel:3.x auto<br />
          <br />
          Fingerprint Android 4.2.2 (Linux 3.4)<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          cpe:/o:google:android:4.2.2<br />
          <br />
          Fingerprint Android 4.4.4 (Linux 3.4)<br />
          cpe:/o:linux:linux_kernel:3.4<br />
          cpe:/o:google:android:4.4.4<br />
          <br />
          Fingerprint Linux 3.10<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint ODROID-U2 Android development board (Linux 3.0)<br />
          cpe:/o:linux:linux_kernel:3<br />
          cpe:/h:hardkernel:odroid-u2<br />
          <br />
          Fingerprint PandaBoard development board (Linux 3.2)<br />
          cpe:/o:linux:linux_kernel:3<br />
          cpe:/h:pandaboard:pandaboard<br />
          <br />
          Fingerprint QNAP NAS Firmware 3.8.3 (Linux 3.X)<br />
          cpe:/o:linux:linux_kernel:3.x auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.1 (Linux 3.2)<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          cpe:/a:synology:diskstation_manager:5.1<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.2 (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          cpe:/a:synology:diskstation_manager:5.2<br />
          <br />
          Fingerprint Tintri OS 3.2 (Linux 3.2)<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          cpe:/o:tintri:tintri_os:3.2 auto<br />
          <br />
          Fingerprint Western Digital My Cloud (Linux 3.2)<br />
          cpe:/o:linux:linux_kernel:3.2 auto<br />
          <br />
          Fingerprint Cisco CP-8841 VoIP phone<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          cpe:/h:cisco:cp-8841 auto<br />
          <br />
          Fingerprint DD-WRT (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          <br />
          Fingerprint DD-WRT v24-sp2 (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10<br />
          <br />
          Fingerprint libreCMC 1.3 Elegant Eleanor (Linux 3.14)<br />
          cpe:/o:linux:linux_kernel:3.14<br />
          <br />
          Fingerprint OpenWrt Attitude Adjustment 12.09 (Linux 3.3)<br />
          cpe:/o:linux:linux_kernel:3.3<br />
          <br />
          Fingerprint OpenWrt Barrier Breaker (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint OpenWrt Barrier Breaker (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint OpenWrt Barrier Breaker (Linux 3.8)<br />
          cpe:/o:linux:linux_kernel:3.8 auto<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer 15.05 (Linux 3.18)<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          <br />
          Fingerprint OpenWrt Chaos Calmer 15.05 (Linux 3.18) or Designated
          Driver (Linux 4.1 or 4.4)<br />
          cpe:/o:linux:linux_kernel:3.18<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint DD-WRT v3.0 (Linux 4.4.2)<br />
          cpe:/o:linux:linux_kernel:4.4.2 auto<br />
          <br />
          Fingerprint Linux 3.19 - 4.4<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          cpe:/o:linux:linux_kernel:3 auto<br />
          <br />
          Fingerprint Linux 4.0<br />
          cpe:/o:linux:linux_kernel:4.0<br />
          <br />
          Fingerprint Linux 4.0<br />
          cpe:/o:linux:linux_kernel:4.0 auto<br />
          <br />
          Fingerprint Linux 4.1<br />
          cpe:/o:linux:linux_kernel:4.1<br />
          <br />
          Fingerprint Linux 4.1<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint Linux 4.1<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint Linux 4.1<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint Linux 4.1<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint Linux 4.10<br />
          cpe:/o:linux:linux_kernel:4.10 auto<br />
          <br />
          Fingerprint Linux 4.10<br />
          cpe:/o:linux:linux_kernel:4.10 auto<br />
          <br />
          Fingerprint Linux 4.10<br />
          cpe:/o:linux:linux_kernel:4.10 auto<br />
          <br />
          Fingerprint Linux 4.10<br />
          cpe:/o:linux:linux_kernel:4.10 auto<br />
          <br />
          Fingerprint Linux 4.10<br />
          cpe:/o:linux:linux_kernel:4.10 auto<br />
          <br />
          Fingerprint Linux 4.2<br />
          cpe:/o:linux:linux_kernel:4.2 auto<br />
          <br />
          Fingerprint Linux 4.2<br />
          cpe:/o:linux:linux_kernel:4.2 auto<br />
          <br />
          Fingerprint Linux 4.2<br />
          cpe:/o:linux:linux_kernel:4.2 auto<br />
          <br />
          Fingerprint Linux 4.2<br />
          cpe:/o:linux:linux_kernel:4.2 auto<br />
          <br />
          Fingerprint Linux 4.3<br />
          cpe:/o:linux:linux_kernel:4.3 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Linux 4.4 - 4.9<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 4.4 - 4.9<br />
          cpe:/o:linux:linux_kernel:4 auto<br />
          <br />
          Fingerprint Linux 4.5<br />
          cpe:/o:linux:linux_kernel:4.5 auto<br />
          <br />
          Fingerprint Linux 4.5<br />
          cpe:/o:linux:linux_kernel:4.5 auto<br />
          <br />
          Fingerprint Linux 4.6<br />
          cpe:/o:linux:linux_kernel:4.6 auto<br />
          <br />
          Fingerprint Linux 4.8<br />
          cpe:/o:linux:linux_kernel:4.8 auto<br />
          <br />
          Fingerprint Linux 4.8<br />
          cpe:/o:linux:linux_kernel:4.8 auto<br />
          <br />
          Fingerprint Linux 4.9<br />
          cpe:/o:linux:linux_kernel:4.9 auto<br />
          <br />
          Fingerprint Linux 4.9<br />
          cpe:/o:linux:linux_kernel:4.9 auto<br />
          <br />
          Fingerprint Linux 4.9<br />
          cpe:/o:linux:linux_kernel:4.9 auto<br />
          <br />
          Fingerprint OpenWrt Designated Driver (Linux 4.1)<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint OpenWrt Designated Driver (Linux 4.1)<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint OpenWrt Designated Driver (Linux 4.4)<br />
          cpe:/o:linux:linux_kernel:4.4 auto<br />
          <br />
          Fingerprint Compal CG6640 cable modem<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:compal:cg6640 auto<br />
          <br />
          Fingerprint Free Freebox V5 ADSL modem<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:free:freebox:v5<br />
          <br />
          Fingerprint Linux 2.6.18 (CentOS 5, x86_64, SMP)<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Linux 2.6.25<br />
          cpe:/o:linux:linux_kernel:2.6.25<br />
          <br />
          Fingerprint SUSE Linux Enterprise Thin Client 11<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Dahua or Amcrest network video recorder (Linux)<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Stratacache video display device<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Olivetti 65C-9 printer<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:olivetti:65c-9 auto<br />
          <br />
          Fingerprint Cisco RV320 router<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:cisco:rv320 auto<br />
          <br />
          Fingerprint Bomara Tracker 2740 multipurpose server (Linux)<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:bomara:tracker_2740<br />
          <br />
          Fingerprint Hikvision DVR<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Rebranded surveillance DVR (Hikvision, Q-SEE, EYEsurv,
          A1Webcams, Foscam)<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint HP P2000 MSA storage controller<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:hp:p2000_msa<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.0<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/a:synology:diskstation_manager:5.0<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.0<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/a:synology:diskstation_manager:5.0<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.1<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/a:synology:diskstation_manager:5.1 auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.1<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/a:synology:diskstation_manager:5.1 auto<br />
          <br />
          Fingerprint Chip PC XtremePC thin client<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Asus RT-AC66U WAP<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:asus:rt-ac66u auto<br />
          <br />
          Fingerprint Huawei HG8245A GPON WAP<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:huawei:hg8245a<br />
          <br />
          Fingerprint Linksys EA3500 WAP<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:linksys:ea3500 auto<br />
          <br />
          Fingerprint Linksys WRTU54G-TM WAP (Linux)<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:linksys:wrtu54g-tm auto<br />
          <br />
          Fingerprint Ubiquity AirOS (Linux)<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/o:ubnt:airos auto<br />
          <br />
          Fingerprint ZyXEL Keenetic 4G II WAP<br />
          cpe:/o:linux:linux_kernel auto<br />
          cpe:/h:zyxel:keenetic_4g_ii<br />
          <br />
          Fingerprint LogiLink Gigabit NAS device<br />
          cpe:/h:logilink:gigabit<br />
          <br />
          Fingerprint Lorex NV408 video recorder<br />
          cpe:/h:lorex:nv408<br />
          <br />
          Fingerprint Lorex Security DVR<br />
          <br />
          Fingerprint Lucent PortMaster PM-25 terminal server (ComOS 3.9)<br />
          cpe:/o:lucent:comos auto<br />
          <br />
          Fingerprint Luxul XBR-2300 router<br />
          cpe:/h:luxul:xbr-2300 auto<br />
          <br />
          Fingerprint lwIP 1.1.0 lightweight TCP/IP stack<br />
          cpe:/a:lwip_project:lwip:1.1.0 auto<br />
          <br />
          Fingerprint lwIP 1.3.0 lightweight TCP/IP stack<br />
          cpe:/a:lwip_project:lwip:1.3.0 auto<br />
          <br />
          Fingerprint lwIP 1.4.0 lightweight TCP/IP stack<br />
          cpe:/a:lwip_project:lwip:1.4.0<br />
          <br />
          Fingerprint lwIP 1.4.0 lightweight TCP/IP stack<br />
          cpe:/a:lwip_project:lwip:1.4.0 auto<br />
          <br />
          Fingerprint lwIP TCP/IP stack<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint ESPEasy OS (lwIP stack)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Maipu MyPower MP3840 router<br />
          cpe:/h:maipu:mypower_mp3840 auto<br />
          <br />
          Fingerprint Metrix Scopix III oscilloscope<br />
          cpe:/h:metrix:scopix_iii<br />
          <br />
          Fingerprint MicroNet SP2267 wireless ADSL router<br />
          cpe:/h:micronet:sp2267 auto<br />
          <br />
          Fingerprint Micronet SP916NE WAP<br />
          cpe:/h:micronet:sp916ne auto<br />
          <br />
          Fingerprint Microsoft MN-700 wireless broadband router<br />
          cpe:/h:microsoft:mn-700 auto<br />
          <br />
          Fingerprint Microsoft Network Client 3.0 for MS-DOS<br />
          cpe:/o:microsoft:ms-dos auto<br />
          <br />
          Fingerprint Microsoft Windows Mobile 2003 PocketPC<br />
          cpe:/o:microsoft:windows_mobile:2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Mobile 5.0 - 6.1 or Zune audio player
          (firmware 2.2)<br />
          cpe:/o:microsoft:windows_mobile:5 auto<br />
          cpe:/o:microsoft:windows_mobile:6 auto<br />
          <br />
          Fingerprint Fujitsu Siemens Pocket LOOX 750 GPS device (Windows Mobile
          5)<br />
          cpe:/o:microsoft:windows_mobile:5 auto<br />
          <br />
          Fingerprint Microsoft Windows Mobile 6 (CE OS 5.0 - 5.2)<br />
          cpe:/o:microsoft:windows_mobile:6 auto<br />
          <br />
          Fingerprint HTC Touch mobile phone (Windows Mobile 6)<br />
          cpe:/o:microsoft:windows_mobile:6 auto<br />
          <br />
          Fingerprint Microsoft Windows Mobile 6.0 - 6.1<br />
          cpe:/o:microsoft:windows_mobile:6 auto<br />
          <br />
          Fingerprint Motorola Q9c mobile phone (Windows Mobile 6)<br />
          cpe:/o:microsoft:windows_mobile:6 auto<br />
          <br />
          Fingerprint Microsoft Windows 10<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 10586 - 14393<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1507<br />
          cpe:/o:microsoft:windows_10:1507 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1507<br />
          cpe:/o:microsoft:windows_10:1507 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1507<br />
          cpe:/o:microsoft:windows_10:1507 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1507 - 1607<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1511<br />
          cpe:/o:microsoft:windows_10:1511 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1511<br />
          cpe:/o:microsoft:windows_10:1511 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1511<br />
          cpe:/o:microsoft:windows_10:1511 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1511<br />
          cpe:/o:microsoft:windows_10:1511 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1511 - 1607<br />
          cpe:/o:microsoft:windows_10 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1607<br />
          cpe:/o:microsoft:windows_10:1607 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1703<br />
          cpe:/o:microsoft:windows_10:1703 auto<br />
          <br />
          Fingerprint Microsoft Windows 10 1511<br />
          cpe:/o:microsoft:windows_10:1511 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000<br />
          cpe:/o:microsoft:windows_2000 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000<br />
          cpe:/o:microsoft:windows_2000 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 AS<br />
          cpe:/o:microsoft:windows_2000:::advanced_server<br />
          <br />
          Fingerprint Microsoft Windows 2000 AS SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:advanced_server<br />
          <br />
          Fingerprint Microsoft Windows 2000 or Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_2000 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows 2000 Professional (no service pack)<br />
          cpe:/o:microsoft:windows_2000:::professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Professional SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Professional SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Professional SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server<br />
          cpe:/o:microsoft:windows_2000 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP3 or SP4<br />
          cpe:/o:microsoft:windows_2000::sp3 auto<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:server<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP4<br />
          cpe:/o:microsoft:windows_2000::sp4:server<br />
          <br />
          Fingerprint Microsoft Windows 2000 Server SP4 or Windows XP
          Professional SP3<br />
          cpe:/o:microsoft:windows_2000::sp4:server<br />
          cpe:/o:microsoft:windows_xp::sp3:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP0<br />
          cpe:/o:microsoft:windows_2000::- auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP0<br />
          cpe:/o:microsoft:windows_2000::- auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP0 - SP4 or Windows XP SP0 -
          SP1<br />
          cpe:/o:microsoft:windows_2000::-<br />
          cpe:/o:microsoft:windows_2000::sp1<br />
          cpe:/o:microsoft:windows_2000::sp2<br />
          cpe:/o:microsoft:windows_2000::sp3<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::-<br />
          cpe:/o:microsoft:windows_xp::sp1<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP0/SP2/SP4 or Windows XP
          SP0/SP1<br />
          cpe:/o:microsoft:windows_2000::-<br />
          cpe:/o:microsoft:windows_2000::sp2<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::-<br />
          cpe:/o:microsoft:windows_xp::sp1<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP1<br />
          cpe:/o:microsoft:windows_2000::sp1<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP2<br />
          cpe:/o:microsoft:windows_2000::sp2<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP2<br />
          cpe:/o:microsoft:windows_2000::sp2<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP2<br />
          cpe:/o:microsoft:windows_2000::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP2 - SP4, Windows XP SP2 - SP3, or
          Windows Server 2003 SP0 - SP2<br />
          cpe:/o:microsoft:windows_2000::sp2<br />
          cpe:/o:microsoft:windows_2000::sp3<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          cpe:/o:microsoft:windows_server_2003::-<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP3/SP4 or Windows XP SP1/SP2<br />
          cpe:/o:microsoft:windows_2000::sp3<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::sp1<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4 or Windows XP<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4 or Windows XP Professional
          SP1<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::sp1:professional<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4 or Windows XP SP1a<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::sp1a<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4 or Windows XP SP2 - SP3<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4 or Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_2000::sp4<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP4<br />
          cpe:/o:microsoft:windows_2000::sp4 auto<br />
          <br />
          Fingerprint Microsoft Windows 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows 2003 R2<br />
          cpe:/o:microsoft:windows_server_2003:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows 2003 R2<br />
          cpe:/o:microsoft:windows_server_2003:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 2<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 Enterprise Edition<br />
          cpe:/o:microsoft:windows_server_2003:::enterprise<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 Enterprise Edition SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2:enterprise<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2<br />
          cpe:/o:microsoft:windows_server_2003::r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2003:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2003:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP2<br />
          cpe:/o:microsoft:windows_server_2003:r2:sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP2<br />
          cpe:/o:microsoft:windows_server_2003:r2:sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP2<br />
          cpe:/o:microsoft:windows_server_2003:r2:sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP2<br />
          cpe:/o:microsoft:windows_server_2003::r2_sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP2<br />
          cpe:/o:microsoft:windows_server_2003::r2_sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 R2 SP2<br />
          cpe:/o:microsoft:windows_server_2003:r2:sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP0<br />
          cpe:/o:microsoft:windows_server_2003::- auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP0 - SP2<br />
          cpe:/o:microsoft:windows_server_2003::- auto<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 - SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 - SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 - SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP1 or SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP2 (x64)<br />
          cpe:/o:microsoft:windows_server_2003::sp2:x64<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP3<br />
          cpe:/o:microsoft:windows_server_2003::sp3<br />
          <br />
          Fingerprint Microsoft Windows Small Business Server 2003 SP1<br />
          cpe:/o:microsoft:windows_server_2003::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Small Business Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Micrososft Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_server_2003::sp2 auto<br />
          <br />
          Fingerprint Microsoft Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 or Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2 auto<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows Home Server 2011 (Windows Server 2008
          R2)<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 Enterprise SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 or 2008 Beta 3<br />
          cpe:/o:microsoft:windows_server_2008::beta3<br />
          cpe:/o:microsoft:windows_server_2008<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_server_2008:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 or Windows 8<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 or Windows 8.1<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 R2 SP1 or Windows 8<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1<br />
          cpe:/o:microsoft:windows_server_2008::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP1 or Windows Server 2008
          R2<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_server_2008::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2 Datacenter Version<br />
          cpe:/o:microsoft:windows_server_2008::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2008 SP2 or Windows 10 or Xbox
          One<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          cpe:/o:microsoft:windows_10 auto<br />
          cpe:/h:microsoft:xbox_one<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 Data Center<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 or Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012<br />
          cpe:/o:microsoft:windows_server_2012:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 or Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:-<br />
          cpe:/o:microsoft:windows_server_2012:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 or Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 or Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2 Update 1<br />
          cpe:/o:microsoft:windows_server_2012:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2 Update 1<br />
          cpe:/o:microsoft:windows_server_2012:r2<br />
          <br />
          Fingerprint Microsoft Windows Server 2012 R2 Update 1<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Windows Server 2012 R2<br />
          cpe:/o:microsoft:windows_server_2012:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2016<br />
          cpe:/o:microsoft:windows_server_2016 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2016<br />
          cpe:/o:microsoft:windows_server_2016 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2016<br />
          cpe:/o:microsoft:windows_server_2016 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2016<br />
          cpe:/o:microsoft:windows_server_2016 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2016<br />
          cpe:/o:microsoft:windows_server_2016 auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2016 build 10586 - 14393<br />
          cpe:/o:microsoft:windows_server_2016 auto<br />
          <br />
          Fingerprint Microsoft Windows 3.1 with Trumpet WinSock<br />
          cpe:/o:microsoft:windows:3.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 3.11 for Workgroups<br />
          cpe:/o:microsoft:windows:3.11 auto<br />
          <br />
          Fingerprint Microsoft Windows 3.11 for Workgroups<br />
          cpe:/o:microsoft:windows:3.11 auto<br />
          <br />
          Fingerprint Microsoft Windows 3.11 for Workgroups<br />
          cpe:/o:microsoft:windows:3.11 auto<br />
          <br />
          Fingerprint Microsoft Windows 3.11 for Workgroups or Windows NT
          3.51<br />
          cpe:/o:microsoft:windows:3.11 auto<br />
          cpe:/o:microsoft:windows_nt:3.51 auto<br />
          <br />
          Fingerprint Microsoft Windows 3.11 for Workgroups, Windows NT 3.51 SP0
          - SP5, or Windows 95<br />
          cpe:/o:microsoft:windows:3.11 auto<br />
          cpe:/o:microsoft:windows_nt:3.51 auto<br />
          cpe:/o:microsoft:windows_95 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Enterprise<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Enterprise<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Enterprise SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Enterprise SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Enterprise SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 or 8.1 R1<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_8.1:r1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 or Windows Server 2008<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 or Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional<br />
          cpe:/o:microsoft:windows_7::-:professional<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional or Windows 8<br />
          cpe:/o:microsoft:windows_7::-:professional<br />
          cpe:/o:microsoft:windows_8<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional SP1<br />
          cpe:/o:microsoft:windows_7::sp1:professional<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 Professional SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP0 - SP1<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_7::- auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP0 - SP1<br />
          cpe:/o:microsoft:windows_7::-<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          <br />
          Fingerprint Microsoft Windows 7 SP0 - SP1 or Windows Server 2008<br />
          cpe:/o:microsoft:windows_7::-<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_2008<br />
          <br />
          Fingerprint Microsoft Windows 7 SP0 - SP1, Windows Server 2008 SP1,
          Windows Server 2008 R2, Windows 8, or Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_7::-<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          cpe:/o:microsoft:windows_8 auto<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP0 - SP1, Windows Server 2008, or
          Windows Embedded Compact 7<br />
          cpe:/o:microsoft:windows_7::-<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          cpe:/o:microsoft:windows_embedded_compact_7<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1 or Windows Server 2008<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1 or Windows Server 2008 R2<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_server_2008:r2<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1 or Windows Server 2008 R2 SP1 or
          Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1 or Windows Server 2008 SP2<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          <br />
          Fingerprint Microsoft Windows 7 SP1 or Windows Server 2008 SP2 or 2008
          R2 SP1<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          cpe:/o:microsoft:windows_server_2008:r2:sp1<br />
          <br />
          Fingerprint Microsoft Windows 7 Ultimate<br />
          cpe:/o:microsoft:windows_7::-:ultimate<br />
          <br />
          Fingerprint Microsoft Windows 7 Ultimate<br />
          cpe:/o:microsoft:windows_7:::ultimate<br />
          <br />
          Fingerprint Microsoft Windows 7 Ultimate<br />
          cpe:/o:microsoft:windows_7::-:ultimate<br />
          <br />
          Fingerprint Microsoft Windows 7 Ultimate<br />
          cpe:/o:microsoft:windows_7::-:ultimate<br />
          <br />
          Fingerprint Microsoft Windows 7 Ultimate Beta (build 7000)<br />
          cpe:/o:microsoft:windows_7::-:ultimate<br />
          <br />
          Fingerprint Microsoft Windows 7 Ultimate SP1 or Windows 8.1 Update
          1<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 7, Windows Server 2012, or Windows 8.1
          Update 1<br />
          cpe:/o:microsoft:windows_7:::ultimate<br />
          cpe:/o:microsoft:windows_2012<br />
          cpe:/o:microsoft:windows_8.1<br />
          <br />
          Fingerprint Microsoft Windows Windows 7 SP1<br />
          cpe:/o:microsoft:windows_7::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Embedded POSready 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows Embedded Standard 7<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows 8<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 8<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 8<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 8 Enterprise<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_8 auto<br />
          <br />
          Fingerprint Microsoft Windows 7 or 8.1 R1 or Server 2008 R2 SP1<br />
          cpe:/o:microsoft:windows_8.1:r1 auto<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_server_2008:r2 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 Enterprise<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 R1<br />
          cpe:/o:microsoft:windows_8.1:r1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 8.1 Update 1<br />
          cpe:/o:microsoft:windows_8.1 auto<br />
          <br />
          Fingerprint Microsoft Windows 95<br />
          cpe:/o:microsoft:windows_95 auto<br />
          <br />
          Fingerprint Microsoft Windows 95<br />
          cpe:/o:microsoft:windows_95 auto<br />
          <br />
          Fingerprint Microsoft Windows 95<br />
          cpe:/o:microsoft:windows_95 auto<br />
          <br />
          Fingerprint Microsoft Windows 95<br />
          cpe:/o:microsoft:windows_95 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 (German)<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows 98 SE (no service pack)<br />
          cpe:/o:microsoft:windows_98 auto<br />
          <br />
          Fingerprint Microsoft Windows Longhorn<br />
          cpe:/o:microsoft:windows auto<br />
          <br />
          Fingerprint Microsoft Windows Millenium Edition (Me) 4.90.3000<br />
          cpe:/o:microsoft:windows_me auto<br />
          <br />
          Fingerprint Microsoft Windows Millennium Edition (Me)<br />
          cpe:/o:microsoft:windows_me auto<br />
          <br />
          Fingerprint Microsoft Windows NT<br />
          cpe:/o:microsoft:windows_nt auto<br />
          <br />
          Fingerprint Microsoft Windows NT 3.1<br />
          cpe:/o:microsoft:windows_nt:3.1 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 3.51 SP5<br />
          cpe:/o:microsoft:windows_nt:3.51:sp5<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0<br />
          cpe:/o:microsoft:windows_nt:4.0 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0<br />
          cpe:/o:microsoft:windows_nt:4.0 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP0<br />
          cpe:/o:microsoft:windows_nt:4.0:- auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP3<br />
          cpe:/o:microsoft:windows_nt:4.0:sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP5<br />
          cpe:/o:microsoft:windows_nt:4.0:sp5 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP5 - SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          cpe:/o:microsoft:windows_nt:4.0:sp5 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          cpe:/o:microsoft:windows_nt:4.0 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          cpe:/o:microsoft:windows_nt:4.0 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 SP7<br />
          cpe:/o:microsoft:windows_nt:4.0:sp7 auto<br />
          <br />
          Fingerprint Microsoft Windows NT 4.0 Terminal Server Edition SP6<br />
          cpe:/o:microsoft:windows_nt:4.0:sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT SP6<br />
          cpe:/o:microsoft:windows_nt::sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT Version 4.0 SP6<br />
          cpe:/o:microsoft:windows_nt::sp6 auto<br />
          <br />
          Fingerprint Microsoft Windows NT Workstation 4.0 SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          cpe:/o:microsoft:windows_nt auto<br />
          <br />
          Fingerprint Microsoft Windows NT Workstation 4.0 SP6a<br />
          cpe:/o:microsoft:windows_nt::sp6a<br />
          <br />
          Fingerprint Microsoft Windows Phone 7.5 or 8.0<br />
          cpe:/o:microsoft:windows auto<br />
          <br />
          Fingerprint ARCA CM18 Cash Recycler (Windows CE)<br />
          cpe:/o:microsoft:windows_ce auto<br />
          cpe:/h:arca:cm18<br />
          <br />
          Fingerprint Microsoft Windows CE 3.0<br />
          cpe:/o:microsoft:windows_ce:3.0 auto<br />
          <br />
          Fingerprint Microsoft Windows CE 5.0<br />
          cpe:/o:microsoft:windows_ce:5.0 auto<br />
          <br />
          Fingerprint Microsoft Windows CE 6.0<br />
          cpe:/o:microsoft:windows_ce:6.0 auto<br />
          <br />
          Fingerprint AT&amp;T U-Verse set-top box (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0 auto<br />
          <br />
          Fingerprint BT Vision+ set-top box (Windows CE 5.0.1400)<br />
          cpe:/o:microsoft:windows_ce:5.0.1400<br />
          cpe:/h:btvision:btvision%2b_box<br />
          <br />
          Fingerprint Cisco ISB2230 set-top box (Windows CE 5.0.1400)<br />
          cpe:/o:microsoft:windows_ce:5.0.1400<br />
          cpe:/h:cisco:isb2230<br />
          <br />
          Fingerprint Motorola VIP1200 digital set top box (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0<br />
          cpe:/h:motorola:vip1200<br />
          <br />
          Fingerprint Motorola VIP1200-series or Swisscom Bluewin TV digital set
          top box (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0 auto<br />
          <br />
          Fingerprint Motorola VIP1216 digital set top box (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0<br />
          cpe:/h:motorola:vip1216<br />
          <br />
          Fingerprint Motorola VIP1216E digital set top box (Windows CE 5.0)<br />
          cpe:/o:motorola:windows_ce:5.0<br />
          cpe:/h:motorola:vip1216e<br />
          <br />
          Fingerprint Telekom set top box (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0<br />
          <br />
          Fingerprint Intermec CK31 PDA (Windows CE 4.20)<br />
          cpe:/o:microsoft:windows_ce:4.20<br />
          cpe:/h:intermec:ck31<br />
          <br />
          Fingerprint Microsoft Windows CE 3.0<br />
          cpe:/o:microsoft:windows_ce:3.0 auto<br />
          <br />
          Fingerprint Microsoft Windows CE 4.20<br />
          cpe:/o:microsoft:windows_ce:4.20<br />
          <br />
          Fingerprint Datalogic Kyman barcode scanner (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0 auto<br />
          cpe:/h:datalogic:kyman<br />
          <br />
          Fingerprint Microsoft Windows CE 5.0 (ARM)<br />
          cpe:/o:microsoft:windows_ce:5.0 auto<br />
          <br />
          Fingerprint Microsoft Windows CE 6.0<br />
          cpe:/o:microsoft:windows_ce:6.0 auto<br />
          <br />
          Fingerprint Motorola WT4090 mobile computer (Windows CE 5)<br />
          cpe:/o:microsoft:windows_ce:5 auto<br />
          <br />
          Fingerprint NCD Thinstar 200 thin client (Windows CE)<br />
          cpe:/o:microsoft:windows_ce auto<br />
          <br />
          Fingerprint Phoenix Contact ILC 350 PN control system (Windows CE
          4.2)<br />
          cpe:/o:microsoft:windows_ce:4.2 auto<br />
          <br />
          Fingerprint Phoenix Contact ILC 350 PN control system (Windows CE
          4.2)<br />
          cpe:/o:microsoft:windows_ce:4.2 auto<br />
          cpe:/h:phoenix_contact:ilc_350_pn<br />
          <br />
          Fingerprint Symbol MC9060-G mobile computer (runs Microsoft Windows CE
          .NET 4.20)<br />
          cpe:/o:microsoft:windows_ce:4.20<br />
          cpe:/h:symbol:mc9060-g<br />
          <br />
          Fingerprint Symbol Technologies handheld scanner (Windows CE 5.0)<br />
          cpe:/o:microsoft:windows_ce:5.0 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista<br />
          cpe:/o:microsoft:windows_vista auto<br />
          <br />
          Fingerprint Microsoft Windows Vista<br />
          cpe:/o:microsoft:windows_vista auto<br />
          <br />
          Fingerprint Microsoft Windows Vista Business<br />
          cpe:/o:microsoft:windows_vista:::business<br />
          <br />
          Fingerprint Microsoft Windows Vista Enterprise<br />
          cpe:/o:microsoft:windows_vista:::enterprise<br />
          <br />
          Fingerprint Microsoft Windows Vista Home Premium SP1<br />
          cpe:/o:microsoft:windows_vista::sp1:home_premium<br />
          <br />
          Fingerprint Microsoft Windows Vista Home Premium SP1, Windows 7, or
          Windows Server 2008<br />
          cpe:/o:microsoft:windows_vista::sp1:home_premium<br />
          cpe:/o:microsoft:windows_7 auto<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista or Windows 7 SP1<br />
          cpe:/o:microsoft:windows_vista auto<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP0 - SP1<br />
          cpe:/o:microsoft:windows_vista::- auto<br />
          cpe:/o:microsoft:windows_vista::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP0 - SP2, Windows Server 2008, or
          Windows 7 Ultimate<br />
          cpe:/o:microsoft:windows_vista auto<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          cpe:/o:microsoft:windows_7:::ultimate<br />
          <br />
          Fingerprint Microsoft Windows Vista SP0 or SP1<br />
          cpe:/o:microsoft:windows_vista::- auto<br />
          cpe:/o:microsoft:windows_vista::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP0 or SP1, Windows Server 2008
          SP1, or Windows 7<br />
          cpe:/o:microsoft:windows_vista::-<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          cpe:/o:microsoft:windows_server_2008::sp1<br />
          cpe:/o:microsoft:windows_7<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Microsoft Windows Vista SP1 - SP2, Windows Server 2008
          SP2, or Windows 7<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          cpe:/o:microsoft:windows_vista::sp2<br />
          cpe:/o:microsoft:windows_server_2008::sp2<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2<br />
          cpe:/o:microsoft:windows_vista::sp2<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2<br />
          cpe:/o:microsoft:windows_vista::sp2<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2<br />
          cpe:/o:microsoft:windows_vista::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2<br />
          cpe:/o:microsoft:windows_vista::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2<br />
          cpe:/o:microsoft:windows_vista::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2 or Windows 7 Ultimate SP0 -
          SP1<br />
          cpe:/o:microsoft:windows_vista::sp2<br />
          cpe:/o:microsoft:windows_7::-:ultimate<br />
          cpe:/o:microsoft:windows_7::sp1:ultimate<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2, Windows 7 SP1, or Windows
          Server 2008<br />
          cpe:/o:microsoft:windows_vista::sp2<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista SP2, Windows 7, or Windows 7
          SP1<br />
          cpe:/o:microsoft:windows_vista::sp2<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_7 auto<br />
          <br />
          Fingerprint Microsoft Windows Vista, Windows 7 SP1, or Windows 8.1
          Update 1<br />
          cpe:/o:microsoft:windows_vista<br />
          cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_8.1<br />
          <br />
          Fingerprint Microsoft Windows 2000 SP6<br />
          cpe:/o:microsoft:windows_2000::sp6<br />
          <br />
          Fingerprint Microsoft Windows Fundamentals for Legacy PCs (XP Embedded
          derivative)<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows Server 2003 SP0 or Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_server_2003::-<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP Embedded<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Windows XP Embedded<br />
          cpe:/o:microsoft:windows_xp:::embedded<br />
          <br />
          Fingerprint Microsoft Windows XP Embedded SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP Home Edition SP3<br />
          cpe:/o:microsoft:windows_xp::sp3:home<br />
          <br />
          Fingerprint Microsoft Windows XP Home SP1<br />
          cpe:/o:microsoft:windows_xp::sp1:home<br />
          <br />
          Fingerprint Microsoft Windows XP Home SP1 (French)<br />
          cpe:/o:microsoft:windows_xp::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Home SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:home<br />
          <br />
          Fingerprint Microsoft Windows XP Home SP2 (Russian)<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional<br />
          cpe:/o:microsoft:windows_xp:::professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional<br />
          cpe:/o:microsoft:windows_xp:::professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP1<br />
          cpe:/o:microsoft:windows_xp::sp1:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP1<br />
          cpe:/o:microsoft:windows_xp::sp1:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP1<br />
          cpe:/o:microsoft:windows_xp::sp1:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP1<br />
          cpe:/o:microsoft:windows_xp::sp1:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 (firewall
          enabled)<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 (French)<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 (German)<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 (German)<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 - SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP2 or Windows Server
          2003<br />
          cpe:/o:microsoft:windows_xp::sp2:professional<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP3<br />
          cpe:/o:microsoft:windows_xp::sp3:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP3<br />
          cpe:/o:microsoft:windows_xp::sp3:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP3<br />
          cpe:/o:microsoft:windows_xp::sp3:professional<br />
          <br />
          Fingerprint Microsoft Windows XP Professional SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP 2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP0<br />
          cpe:/o:microsoft:windows_xp::- auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP0 or SP1<br />
          cpe:/o:microsoft:windows_xp::- auto<br />
          cpe:/o:microsoft:windows_xp::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP1<br />
          cpe:/o:microsoft:windows_xp::sp1<br />
          <br />
          Fingerprint Microsoft Windows XP SP1<br />
          cpe:/o:microsoft:windows_xp::sp1<br />
          <br />
          Fingerprint Microsoft Windows XP SP1<br />
          cpe:/o:microsoft:windows_xp::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP1<br />
          cpe:/o:microsoft:windows_xp::sp1 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 (firewall disabled)<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 (Norwegian)<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 - SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 - SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 - SP3<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 Media Center Edition<br />
          cpe:/o:microsoft:windows_xp::sp2:media_center<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3, or Windows Embedded
          Standard 2009<br />
          cpe:/o:microsoft:windows_xp::sp3:embedded<br />
          cpe:/o:microsoft:windows_xp::sp2 auto<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or SP3, or Windows Server 2003<br />
          cpe:/o:microsoft:windows_xp auto<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or Windows Server 2003<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_server_2003::-<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or Windows Server 2003 SP1 or
          SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_server_2003::sp1<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_xp::sp2<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP2 or Windows Small Business Server
          2003<br />
          cpe:/o:microsoft:windows_xp auto<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3<br />
          cpe:/o:microsoft:windows_xp::sp3 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3 (MicroXP)<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          <br />
          Fingerprint Microsoft Windows XP SP3 or Small Business Server 2003<br />
          cpe:/o:microsoft:windows_xp auto<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3 or Windows 7 or Windows Server
          2012<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          cpe:/o:microsoft:windows_7<br />
          cpe:/o:microsoft:windows_server_2012 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3 or Windows Server 2003<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          cpe:/o:microsoft:windows_server_2003 auto<br />
          <br />
          Fingerprint Microsoft Windows XP SP3 or Windows Server 2003 SP2<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          cpe:/o:microsoft:windows_server_2003::sp2<br />
          <br />
          Fingerprint Microsoft Windows XP SP3, Windows Server 2003, or Windows
          Vista SP1<br />
          cpe:/o:microsoft:windows_xp::sp3<br />
          cpe:/o:microsoft:windows_server_2003<br />
          cpe:/o:microsoft:windows_vista::sp1<br />
          <br />
          Fingerprint Version 5.1 (Build 2600.xpsp_sp3_gdr.100427-1636 : Service
          Pack 3<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Burny CNC controller (Microsoft Windows XP Embedded)<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Fuji DryPix medical imager (Microsoft Windows XP
          Embedded)<br />
          cpe:/o:microsoft:windows_xp auto<br />
          cpe:/h:fujifilm:drypix<br />
          <br />
          Fingerprint LaCie Ethernet Disk (Windows XP Embedded)<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint LaCie NAS device (Windows XP Embedded)<br />
          cpe:/o:microsoft:windows_xp auto<br />
          <br />
          Fingerprint Microsoft Xbox 360 Dashboard<br />
          cpe:/h:microsoft:xbox_360_kernel<br />
          <br />
          Fingerprint Microware OS-9/68000 3.0.3<br />
          cpe:/o:microware:os_9 auto<br />
          <br />
          Fingerprint Microware OS-9/PowerPC 4.8<br />
          cpe:/o:microware:os_9 auto<br />
          <br />
          Fingerprint MicroTik RouterOS 2.9.46<br />
          cpe:/o:mikrotik:routeros:2.9.46 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 3.17<br />
          cpe:/o:mikrotik:routeros:3.17 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 3.19<br />
          cpe:/o:mikrotik:routeros:3.19 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 3.30<br />
          cpe:/o:mikrotik:routeros:3.30 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 5.14 (Linux 2.6.35)<br />
          cpe:/o:mikrotik:routeros:5.14 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 5.16<br />
          cpe:/o:mikrotik:routeros:5.16 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 5.24<br />
          cpe:/o:mikrotik:routeros:5.24 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 5.25 (Linux 2.6.35)<br />
          cpe:/o:mikrotik:routeros:5.25 auto<br />
          cpe:/o:linux:linux_kernel:2.6.35 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.0<br />
          cpe:/o:mikrotik:routeros:6.0 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.15 (Linux 3.3.5)<br />
          cpe:/o:mikrotik:routeros:6.15 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.19 (Linux 3.3.5)<br />
          cpe:/o:mikrotik:routeros:6.19 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.30<br />
          cpe:/o:mikrotik:routeros:6.30 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.32.1<br />
          cpe:/o:mikrotik:routeros:6.32.1 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.33<br />
          cpe:/o:mikrotik:routeros:6.33 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.33<br />
          cpe:/o:mikrotik:routeros:6.33 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.33<br />
          cpe:/o:mikrotik:routeros:6.33 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.34<br />
          cpe:/o:mikrotik:routeros:6.34 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.34<br />
          cpe:/o:mikrotik:routeros:6.34 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.35<br />
          cpe:/o:mikrotik:routeros:6.35 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.35<br />
          cpe:/o:mikrotik:routeros:6.35 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.36<br />
          cpe:/o:mikrotik:routeros:6.36 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.37<br />
          cpe:/o:mikrotik:routeros:6.37 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.37 (Linux 3.3.5)<br />
          cpe:/o:mikrotik:routeros:6.37 auto<br />
          cpe:/o:linux:linux_kernel:3.3.5 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.38<br />
          cpe:/o:mikrotik:routeros:6.38 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.38<br />
          cpe:/o:mikrotik:routeros:6.38 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.4<br />
          cpe:/o:mikrotik:routeros:6.4 auto<br />
          <br />
          Fingerprint MikroTik RouterOS 6.7 (Linux 3.3.5)<br />
          cpe:/o:mikrotik:routeros:6.7 auto<br />
          <br />
          Fingerprint MikroTik RB750G router<br />
          cpe:/h:mikrotik:rb750g<br />
          cpe:/o:mikrotik:routeros auto<br />
          <br />
          Fingerprint Minix 2.0.4<br />
          cpe:/o:minix:minix:2.0.4 auto<br />
          <br />
          Fingerprint Minix 2.0.4<br />
          cpe:/o:minix:minix:2.0.4 auto<br />
          <br />
          Fingerprint Minix 2.0.4<br />
          cpe:/o:minix:minix:2.0.4 auto<br />
          <br />
          Fingerprint Minix 3.1.2<br />
          cpe:/o:minix:minix:3.1.2 auto<br />
          <br />
          Fingerprint Minix 3.2.0<br />
          cpe:/o:minix:minix:3.2.0 auto<br />
          <br />
          Fingerprint Motorola PTP 600 wireless Ethernet bridge<br />
          cpe:/h:motorola:ptp_600<br />
          <br />
          Fingerprint Motorola 2210-02 ADSL modem<br />
          cpe:/h:motorola:2210-02 auto<br />
          <br />
          Fingerprint Motorola SURFboard 5101 cable modem<br />
          cpe:/h:motorola:surfboard_5101 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB4200 cable modem<br />
          cpe:/h:motorola:surfboard_sb4200 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5100i cable modem<br />
          cpe:/h:motorola:surfboard_sb5100i auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5100i cable modem<br />
          cpe:/h:motorola:surfboard_sb5100i auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5101 cable modem<br />
          cpe:/h:motorola:surfboard_sb5101 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5101 cable modem<br />
          cpe:/h:motorola:surfboard_sb5101 auto<br />
          <br />
          Fingerprint Motorola QIP2500 set top box<br />
          cpe:/h:motorola:qip2500 auto<br />
          <br />
          Fingerprint Motorola QIP2500 set top box<br />
          cpe:/h:motorola:qip2500 auto<br />
          <br />
          Fingerprint Motorola QIP2500 set top box<br />
          cpe:/h:motorola:qip2500 auto<br />
          <br />
          Fingerprint Motorola QIP2500-3 set top box<br />
          cpe:/h:motorola:qip2500-3 auto<br />
          <br />
          Fingerprint Motorola QIP2500-3 Set Top Box<br />
          cpe:/h:motorola:qip2500-3 auto<br />
          <br />
          Fingerprint Motorola QIP2708 set top box<br />
          cpe:/h:motorola:qip2708 auto<br />
          <br />
          Fingerprint Motorola QIP6416-2 set top box<br />
          cpe:/h:motorola:qip6416-2 auto<br />
          <br />
          Fingerprint Motorola VIP1232 digital set top box or Telekom Media
          Receiver MR 303<br />
          cpe:/h:motorola:vip1232<br />
          cpe:/h:telekom:mr_303<br />
          <br />
          Fingerprint Motorola RFS4000 wireless controller<br />
          cpe:/h:motorola:rfs4000<br />
          cpe:/o:linux:linux_kernel:2.6 auto<br />
          <br />
          Fingerprint Motorola SVG1202 router<br />
          cpe:/h:motorola:svg1202 auto<br />
          <br />
          Fingerprint Motorola SL4010e two-way radio<br />
          cpe:/h:motorola:sl4010e<br />
          <br />
          Fingerprint Motorola AP-51xx WAP<br />
          cpe:/h:motorola:ap-51xx auto<br />
          <br />
          Fingerprint Motorola RFS 6000 wireless switch<br />
          cpe:/h:motorola:rfs_6000 auto<br />
          <br />
          Fingerprint Motorola RFS6000 Wireless Controller<br />
          cpe:/h:motorola:rfs6000<br />
          <br />
          Fingerprint Motorola SURFboard SBG900 WAP or SMC SMC8014 cable
          modem<br />
          cpe:/h:motorola:surfboard_sbg900 auto<br />
          cpe:/h:smc:smc8014 auto<br />
          <br />
          Fingerprint Motorola System V/88 Unix R4.0<br />
          cpe:/o:motorola:v_88:4 auto<br />
          <br />
          Fingerprint Motorola WiNG 5.7.1 (Linux 2.6.28)<br />
          cpe:/o:motorola:wing:5.7.1 auto<br />
          cpe:/o:linux:linux_kernel:2.6.28 auto<br />
          <br />
          Fingerprint Nashuatec Aficio MP C3000 printer<br />
          cpe:/h:nashuatec:aficio_mp_c3000 auto<br />
          <br />
          Fingerprint NEC UNIVERGE SV8100 PBX<br />
          cpe:/h:nec:sv8100<br />
          <br />
          Fingerprint NEC Univerge SV8300 PBX<br />
          cpe:/h:nec:univerge_sv8300<br />
          <br />
          Fingerprint Nest Protect smoke sensor<br />
          cpe:/h:nest:protect<br />
          <br />
          Fingerprint Data ONTAP 6.3.2<br />
          cpe:/o:netapp:data_ontap:6.3.2 auto<br />
          <br />
          Fingerprint NetApp NetCache web proxy; or FAS3020, FAS3040, FAS3050,
          or FAS6070 NAS device (Data ONTAP 6.0.X - 7.3.X)<br />
          cpe:/o:netapp:data_ontap:6 auto<br />
          cpe:/o:netapp:data_ontap:7 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 7<br />
          cpe:/o:netapp:data_ontap:7 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 7.3.5P1<br />
          cpe:/o:netapp:data_ontap:7.3.5p1 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 7.3.7<br />
          cpe:/o:netapp:data_ontap:7.3.7 auto<br />
          <br />
          Fingerprint NetApp FAS2040 NAS device (Data ONTAP)<br />
          cpe:/o:netapp:data_ontap:7 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 8<br />
          cpe:/o:netapp:data_ontap:8 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 8<br />
          cpe:/o:netapp:data_ontap:8 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 8.0.1 - 8.0.2<br />
          cpe:/o:netapp:data_ontap:8.0<br />
          <br />
          Fingerprint NetApp Data ONTAP 8.0.1RC1<br />
          cpe:/o:netapp:data_ontap:8.0.1rc1 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 8.1.2P4<br />
          cpe:/o:netapp:data_ontap:8.1.2p4 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 8.1.2RC1<br />
          cpe:/o:netapp:data_ontap:8.1.2rc1 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP 8.1RC3 or 8.1.3P3<br />
          cpe:/o:netapp:data_ontap:8.1rc3 auto<br />
          <br />
          Fingerprint NetApp Data ONTAP<br />
          cpe:/o:netapp:data_ontap auto<br />
          <br />
          Fingerprint NetApp FAS2000 NAS device<br />
          cpe:/h:netapp:fas2000 auto<br />
          <br />
          Fingerprint Netasq U70 firewall<br />
          cpe:/h:netasq:u70 auto<br />
          <br />
          Fingerprint NetBSD 2.1.0_STABLE or Ricoh C720S, 1107EX, MP 2550, or MP
          7001 printer<br />
          cpe:/o:netbsd:netbsd:2.1.0_stable auto<br />
          cpe:/h:ricoh:c720s<br />
          cpe:/h:ricoh:1107ex<br />
          cpe:/h:ricoh:aficio_mp_2550<br />
          cpe:/h:ricoh:aficio_mp_7001<br />
          <br />
          Fingerprint NetBSD 3.1 - 4.0 (AMD64)<br />
          cpe:/o:netbsd:netbsd:3 auto<br />
          cpe:/o:netbsd:netbsd:4 auto<br />
          <br />
          Fingerprint NetBSD 3.1.1<br />
          cpe:/o:netbsd:netbsd:3.1.1<br />
          <br />
          Fingerprint NetBSD 4.0<br />
          cpe:/o:netbsd:netbsd:4.0<br />
          <br />
          Fingerprint NetBSD 4.0_BETA2, Feb. 21st CVS snapshot<br />
          cpe:/o:netbsd:netbsd:4.0:beta2<br />
          <br />
          Fingerprint NetBSD 4.0_STABLE<br />
          cpe:/o:netbsd:netbsd:4.0<br />
          <br />
          Fingerprint NetBSD 4.99.49 (x86)<br />
          cpe:/o:netbsd:netbsd:4.99.49<br />
          <br />
          Fingerprint NetBSD 5.0<br />
          cpe:/o:netbsd:netbsd:5.0<br />
          <br />
          Fingerprint NetBSD 5.0 - 5.99.5<br />
          cpe:/o:netbsd:netbsd:5 auto<br />
          <br />
          Fingerprint NetBSD 5.1.2<br />
          cpe:/o:netbsd:netbsd:5.1.2 auto<br />
          <br />
          Fingerprint NetBSD 5.1.2<br />
          cpe:/o:netbsd:netbsd:5.1.2 auto<br />
          <br />
          Fingerprint NetBSD 5.1.2<br />
          cpe:/o:netbsd:netbsd:5.1.2 auto<br />
          <br />
          Fingerprint NetBSD 6.1<br />
          cpe:/o:netbsd:netbsd:6.1 auto<br />
          <br />
          Fingerprint NetBSD 6.1.2<br />
          cpe:/o:netbsd:netbsd:6.1.2 auto<br />
          <br />
          Fingerprint NetBSD 7.0<br />
          cpe:/o:netbsd:netbsd:7.0 auto<br />
          <br />
          Fingerprint NetBSD 7.0<br />
          cpe:/o:netbsd:netbsd:7.0 auto<br />
          <br />
          Fingerprint Netcomm V300 VoIP adapter<br />
          cpe:/h:netcomm:v300 auto<br />
          <br />
          Fingerprint Netgear CM500 cable modem<br />
          cpe:/h:netgear:cm500 auto<br />
          <br />
          Fingerprint Netgear DM602 ADSL router<br />
          cpe:/h:netgear:dm602 auto<br />
          <br />
          Fingerprint Netgear RT311 broadband router<br />
          cpe:/h:netgear:rt311 auto<br />
          <br />
          Fingerprint Netgear WGN824 v2 DSL router<br />
          cpe:/h:netgear:wgn824_v2 auto<br />
          <br />
          Fingerprint Netgear WGT624 WAP<br />
          cpe:/h:netgear:wgt624 auto<br />
          <br />
          Fingerprint Netgear EVA700 Digital Entertainer set top box<br />
          cpe:/h:netgear:eva700<br />
          <br />
          Fingerprint Netgear EVA8000 Digital Entertainer set top box<br />
          cpe:/h:netgear:eva8000<br />
          <br />
          Fingerprint Netgear EVA9100 Digital Entertainer set top box<br />
          cpe:/h:netgear:eva9100<br />
          <br />
          Fingerprint Netgear NeoTV550 (Linux 2.6.22)<br />
          cpe:/h:netgear:neotv550<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Netgear PS105 print server<br />
          cpe:/h:netgear:ps105 auto<br />
          <br />
          Fingerprint Netgear Prosafe UTM9S<br />
          cpe:/h:netgear:prosafe_utm9s<br />
          <br />
          Fingerprint Netgear FS752TS switch<br />
          cpe:/h:netgear:fs752ts auto<br />
          <br />
          Fingerprint Netgear GSM7224 switch (VxWorks)<br />
          cpe:/h:netgear:gsm7224 auto<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Netgear ProSafe GS724T switch<br />
          cpe:/h:netgear:prosafe_gs724t auto<br />
          <br />
          Fingerprint Netgear DG834GB wireless broadband router<br />
          cpe:/h:netgear:dg834gb auto<br />
          <br />
          Fingerprint Netgear ProSafe WG302 v2 WAP<br />
          cpe:/h:netgear:prosafe_wg302_v2 auto<br />
          <br />
          Fingerprint Netgear R6400 WAP (Linux 2.6.36)<br />
          cpe:/h:netgear:r6400 auto<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          <br />
          Fingerprint Netgear WGR614v7 WAP<br />
          cpe:/h:netgear:wgr614v7 auto<br />
          <br />
          Fingerprint Netgear WGR614v7 wireless broadband router<br />
          cpe:/h:netgear:wgr614v7 auto<br />
          <br />
          Fingerprint Netgear WNR834Bv2 WAP<br />
          cpe:/h:netgear:wnr834bv2 auto<br />
          <br />
          Fingerprint Netgear RAIDiator 4.1.12<br />
          cpe:/o:netgear:raidiator:4.1.12 auto<br />
          <br />
          Fingerprint Netgear RAIDiator 4.2.21 (Linux 2.6.37)<br />
          cpe:/o:netgear:raidiator:4.2.21 auto<br />
          cpe:/o:linux:linux_kernel:2.6.37 auto<br />
          <br />
          Fingerprint Netgear RAIDiator 4.2.24<br />
          cpe:/o:netgear:raidiator:4.2.24 auto<br />
          <br />
          Fingerprint Netgear RAIDiator 4.2.28<br />
          cpe:/o:netgear:raidiator:4.2.28 auto<br />
          <br />
          Fingerprint Netgear ReadyNAS 2100 (RAIDiator 4.2.24)<br />
          cpe:/o:netgear:raidiator:4.2.24 auto<br />
          <br />
          Fingerprint Netgear ReadyNAS device (RAIDiator 4.2.21 - 4.2.27)<br />
          cpe:/o:netgear:raidiator:4.2<br />
          <br />
          Fingerprint Netgear ReadyNAS Duo NAS device (RAIDiator 4.1.4)<br />
          cpe:/o:netgear:raidiator:4.1.4 auto<br />
          <br />
          Fingerprint Netgear ReadyNAS Duo V1<br />
          cpe:/o:netgear:raidiator:4 auto<br />
          <br />
          Fingerprint Netgem N7700 set-top box<br />
          cpe:/h:netgem:n7700<br />
          <br />
          Fingerprint Netopia 2247 ADSL router<br />
          cpe:/h:netopia:2247 auto<br />
          <br />
          Fingerprint Netopia 3366 ADSL router<br />
          cpe:/h:netopia:3366 auto<br />
          <br />
          Fingerprint Netopia 3386 ADSL router<br />
          cpe:/h:netopia:3386 auto<br />
          <br />
          Fingerprint Netopia 3387WG-ENT broadband router<br />
          cpe:/h:netopia:3387wg-ent auto<br />
          <br />
          Fingerprint Netopia 3300-series ADSL router<br />
          cpe:/o:netopia:soc_os:7 auto<br />
          cpe:/o:netopia:soc_os:7 auto<br />
          <br />
          Fingerprint Netopia 3346N-VGx ADSL router<br />
          cpe:/o:netopia:soc_os:7 auto<br />
          <br />
          Fingerprint NetOptics iBypass switch<br />
          <br />
          Fingerprint Network Systems BorderGuard 1000 firewall<br />
          cpe:/h:networksystems:borderguard_1000 auto<br />
          <br />
          Fingerprint Neuf Box Trio 3C DSL modem<br />
          cpe:/h:neuf:trio_3c<br />
          <br />
          Fingerprint Neuf Box Trio 3D DSL modem<br />
          cpe:/h:neuf:trio_3d<br />
          <br />
          Fingerprint Nexenta OS 3.0 - 3.1.2 (OpenSolaris snv_130 - snv_134f)<br />
          cpe:/o:nexenta:nexenta auto<br />
          <br />
          Fingerprint Nexsan E18 NAS device<br />
          cpe:/h:nexsan:e18<br />
          <br />
          Fingerprint NeXT NEXTSTEP 3.3 (patch level 3, m68k) or OPENSTEP 4.2<br />
          cpe:/o:next:nextstep:3.3 auto<br />
          cpe:/o:next:openstep:4.2 auto<br />
          <br />
          Fingerprint NeXT OPENSTEP 4.2<br />
          cpe:/o:next:openstep:4.2 auto<br />
          <br />
          Fingerprint NeXT OPENSTEP 4.2<br />
          cpe:/o:next:openstep auto<br />
          <br />
          Fingerprint Nibe F1155 geothermal heat pump (lwIP stack)<br />
          cpe:/h:nibe:f1155<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Nintendo 3DS<br />
          cpe:/h:nintendo:3ds<br />
          <br />
          Fingerprint Nintendo 3DS<br />
          cpe:/h:nintendo:3ds<br />
          <br />
          Fingerprint Nintendo DS game console<br />
          cpe:/h:nintendo:ds<br />
          <br />
          Fingerprint Nintendo Wii game console<br />
          cpe:/h:nintendo:wii<br />
          <br />
          Fingerprint Nintendo Wii game console<br />
          cpe:/h:nintendo:wii<br />
          <br />
          Fingerprint Nintendo Wii game console<br />
          cpe:/h:nintendo:wii<br />
          <br />
          Fingerprint NodeMCU firmware (lwIP stack)<br />
          cpe:/o:nodemcu:nodemcu<br />
          cpe:/a:lwip_project:lwip<br />
          <br />
          Fingerprint NodeMCU firmware (lwIP stack)<br />
          cpe:/o:nodemcu:nodemcu<br />
          cpe:/a:lwip_project:lwip<br />
          <br />
          Fingerprint Nokia firewall (IPSO 4.1Build19)<br />
          cpe:/o:nokia:ipso:4.1build19 auto<br />
          <br />
          Fingerprint Nokia IP330 firewall appliance (IPSO 4.1 - 4.2)<br />
          cpe:/o:nokia:ipso:4 auto<br />
          <br />
          Fingerprint Nokia IP650 firewall (IPSO 4.0 and CheckPoint
          Firewall-1/VPN-1 software)<br />
          cpe:/o:nokia:ipso:4.0 auto<br />
          <br />
          Fingerprint Nokia 5800 mobile phone (Symbian OS 9.4)<br />
          cpe:/o:nokia:symbian_os:9.4 auto<br />
          <br />
          Fingerprint Nokia E51, N86, E90 Communicator, or N95 mobile phone
          (Symbian OS 9.2 - 10.0)<br />
          cpe:/o:nokia:symbian_os:9 auto<br />
          cpe:/o:nokia:symbian_os:10 auto<br />
          <br />
          Fingerprint Nokia E55 or S60 mobile phone (Symbian OS 9.3)<br />
          cpe:/o:nokia:symbian_os:9.3 auto<br />
          <br />
          Fingerprint Nokia E70 mobile phone (Symbian OS 9.1)<br />
          cpe:/o:nokia:symbian_os:9.1 auto<br />
          <br />
          Fingerprint Nokia S60 mobile phone (Symbian OS 9.3)<br />
          cpe:/o:nokia:symbian_os:9.3 auto<br />
          <br />
          Fingerprint Nokia 3600i mobile phone<br />
          cpe:/o:nokia:symbian_os auto<br />
          <br />
          Fingerprint Nokia E60 mobile phone (Symbian OS)<br />
          cpe:/o:nokia:symbian_os auto<br />
          <br />
          Fingerprint Nokia E60, E61, E65, or E70 mobile phone (Symbian OS)<br />
          cpe:/o:nokia:symbian_os auto<br />
          <br />
          Fingerprint Nokia E65 mobile phone (Symbian OS)<br />
          cpe:/o:nokia:symbian_os auto<br />
          <br />
          Fingerprint Nokia E70 or N86 mobile phone (Symbian OS)<br />
          cpe:/o:nokia:symbian_os auto<br />
          <br />
          Fingerprint Nokia N81 mobile phone (Symbian OS)<br />
          cpe:/h:nokia:n81<br />
          cpe:/o:nokia:symbian_os auto<br />
          <br />
          Fingerprint Nortel CS1000M VoIP PBX or Xerox Phaser 8560DT printer<br />
          cpe:/h:nortel:cs1000m<br />
          cpe:/h:xerox:phaser_8560dt<br />
          <br />
          Fingerprint Nortel 5530 Ethernet Routing Switch<br />
          cpe:/h:nortel:ethernet_routing_switch_5530<br />
          <br />
          Fingerprint Nortel NVR1750D VPN router<br />
          cpe:/h:nortel:nvr1750d auto<br />
          <br />
          Fingerprint Nortel Ethernet Routing Switch 4550T-PWR<br />
          cpe:/h:nortel:ethernet_routing_switch_4550t-pwr<br />
          <br />
          Fingerprint Nortel Ethernet Routing Switch 470-24T-PWR<br />
          cpe:/h:nortel:ethernet_routing_switch_470-24t-pwr<br />
          <br />
          Fingerprint Nortel Ethernet Routing Switch 5510<br />
          cpe:/h:nortel:ethernet_routing_switch_5510<br />
          <br />
          Fingerprint Nortel Ethernet Routing Switch 8010 or 8606<br />
          cpe:/h:nortel:ethernet_routing_switch_8010<br />
          cpe:/h:nortel:ethernet_routing_switch_8606<br />
          <br />
          Fingerprint Novatel MiFi 2200 3G WAP<br />
          cpe:/h:novatel:mifi_2200_3g auto<br />
          <br />
          Fingerprint Novatel MiFi 4620L WAP<br />
          cpe:/h:novatel:mifi_4620l auto<br />
          <br />
          Fingerprint Novell NetWare 3.12 TCP/IP 2.02i<br />
          cpe:/o:novell:netware:3.12<br />
          <br />
          Fingerprint Novell NetWare 3.12 TCP/IP 4.01g<br />
          cpe:/o:novell:netware:3.12 auto<br />
          <br />
          Fingerprint Novell NetWare 4.11<br />
          cpe:/o:novell:netware:4.11<br />
          <br />
          Fingerprint Novell Netware 4.2<br />
          cpe:/o:novell:netware:4 auto<br />
          <br />
          Fingerprint Novell NetWare 5<br />
          cpe:/o:novell:netware:5.0<br />
          <br />
          Fingerprint Novell NetWare 5.1<br />
          cpe:/o:novell:netware:5.1<br />
          <br />
          Fingerprint Novell NetWare 6<br />
          cpe:/o:novell:netware:6 auto<br />
          <br />
          Fingerprint Novell NetWare 6.0 or 6.5 SP5 - SP7<br />
          cpe:/o:novell:netware:6.0:sp5<br />
          cpe:/o:novell:netware:6.0:sp6<br />
          cpe:/o:novell:netware:6.0:sp7<br />
          cpe:/o:novell:netware:6.5:sp5<br />
          cpe:/o:novell:netware:6.5:sp6<br />
          cpe:/o:novell:netware:6.5:sp7<br />
          <br />
          Fingerprint Novell NetWare 6.0 SP5 or 6.5 SP5<br />
          cpe:/o:novell:netware:6.0:sp5<br />
          cpe:/o:novell:netware:6.5:sp5<br />
          <br />
          Fingerprint Novell NetWare 6.5<br />
          cpe:/o:novell:netware:6.5<br />
          <br />
          Fingerprint Novell NetWare 6.5<br />
          cpe:/o:novell:netware:6.5<br />
          <br />
          Fingerprint Novell NetWare 6.5 Open Enterprise Server<br />
          cpe:/o:novell:netware:6.5<br />
          <br />
          Fingerprint Novell NetWare 6.5 Open Enterprise Server<br />
          cpe:/o:novell:netware:6.5<br />
          <br />
          Fingerprint Novell NetWare 6.5 SP3 Open Enterprise Server<br />
          cpe:/o:novell:netware:6.5:sp3<br />
          <br />
          Fingerprint Novell NetWare 6.5 SP4<br />
          cpe:/o:novell:netware:6.5:sp4<br />
          <br />
          Fingerprint Novell NetWare 6.5 SP5<br />
          cpe:/o:novell:netware:6.5:sp5<br />
          <br />
          Fingerprint Novell NetWare 6.5 SP7<br />
          cpe:/o:novell:netware:6.5:sp7<br />
          <br />
          Fingerprint NRG C7521n printer<br />
          cpe:/h:nrg:c7521n auto<br />
          <br />
          Fingerprint NRG MP 2500 printer<br />
          cpe:/h:nrg:mp_2500 auto<br />
          <br />
          Fingerprint NRG MP C4500 printer<br />
          cpe:/h:nrg:mp_c4500 auto<br />
          <br />
          Fingerprint NTT RV-230NE router<br />
          cpe:/h:ntt:rv-230ne auto<br />
          <br />
          Fingerprint NTT RV-230NE router<br />
          cpe:/h:ntt:rv-230ne auto<br />
          <br />
          Fingerprint Nut/OS 4.3.2 beta (ARM)<br />
          cpe:/o:ethernut:nut_os:4.3.2 auto<br />
          <br />
          Fingerprint Obihai OBi110 VoIP adapter<br />
          cpe:/h:obihai:obi110 auto<br />
          <br />
          Fingerprint Ocean Signal E101V emergency beacon (FreeRTOS/lwIP)<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Oki B4545 printer<br />
          cpe:/h:oki:b4545 auto<br />
          <br />
          Fingerprint Oki B711 printer<br />
          cpe:/h:oki:b711 auto<br />
          <br />
          Fingerprint Oki B930 printer<br />
          cpe:/h:oki:b930 auto<br />
          <br />
          Fingerprint Oki C5510 printer<br />
          cpe:/h:oki:c5510 auto<br />
          <br />
          Fingerprint OKI C801 printer<br />
          cpe:/h:oki:c801 auto<br />
          <br />
          Fingerprint Oki ES8473 printer<br />
          cpe:/h:oki:es8473 auto<br />
          <br />
          Fingerprint Oki MC342 printer<br />
          cpe:/h:oki:mc342 auto<br />
          <br />
          Fingerprint Olympus Stylus SH-2 camera<br />
          cpe:/h:olympus:stylus_sh-2<br />
          <br />
          Fingerprint Omron CJ2M PLC<br />
          cpe:/h:omron:cj2m<br />
          <br />
          Fingerprint Omron CJ2M PLC<br />
          cpe:/h:omron:cj2m<br />
          <br />
          Fingerprint Omron ITNV-EIS01 automation controller (QNX RTOS 4.25)<br />
          cpe:/h:omron:itnc-eis01<br />
          cpe:/o:qnx:rtos:4.25 auto<br />
          <br />
          Fingerprint Omron NE1A Safety PLC<br />
          cpe:/h:omron:ne1a<br />
          <br />
          Fingerprint On Time RTOS-32 3.0<br />
          cpe:/o:ontime:rtos:3 auto<br />
          <br />
          Fingerprint On Time RTOS<br />
          cpe:/o:ontime:rtos auto<br />
          <br />
          Fingerprint OneAccess 1641 router<br />
          cpe:/h:oneaccess:1641 auto<br />
          <br />
          Fingerprint OneAccess ONE20 ADSL router<br />
          cpe:/h:oneaccess:one20 auto<br />
          <br />
          Fingerprint OneAccess ONE20 ADSL router<br />
          cpe:/h:oneaccess:one20 auto<br />
          <br />
          Fingerprint OpenBox S10 set-top box<br />
          cpe:/h:openbox:s10<br />
          <br />
          Fingerprint OpenBSD 2.9 (x86)<br />
          cpe:/o:openbsd:openbsd:2.9<br />
          <br />
          Fingerprint ONStor Bobcat 2220 NAS Gateway (runs OpenBSD 2.8)<br />
          cpe:/o:openbsd:openbsd:2.8 auto<br />
          cpe:/h:onstor:bobcat_2220<br />
          <br />
          Fingerprint OpenBSD 3.2 (x86)<br />
          cpe:/o:openbsd:openbsd:3.2<br />
          <br />
          Fingerprint OpenBSD 3.3<br />
          cpe:/o:openbsd:openbsd:3.3<br />
          <br />
          Fingerprint OpenBSD 3.4 (x86)<br />
          cpe:/o:openbsd:openbsd:3.4<br />
          <br />
          Fingerprint OpenBSD 3.5<br />
          cpe:/o:openbsd:openbsd:3.5<br />
          <br />
          Fingerprint OpenBSD 3.6 (x86)<br />
          cpe:/o:openbsd:openbsd:3.6<br />
          <br />
          Fingerprint OpenBSD 3.8 - 4.6<br />
          cpe:/o:openbsd:openbsd:3 auto<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint OpenBSD 3.8 - 4.7<br />
          cpe:/o:openbsd:openbsd:3 auto<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint OpenBSD 3.9<br />
          cpe:/o:openbsd:openbsd:3.9<br />
          <br />
          Fingerprint OpenBSD 3.9<br />
          cpe:/o:openbsd:openbsd:3.9<br />
          <br />
          Fingerprint OpenBSD 3.9 - 4.2<br />
          cpe:/o:openbsd:openbsd:3 auto<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint OpenBSD 3.9 - 4.4<br />
          cpe:/o:openbsd:openbsd:3 auto<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint Genua GeNUGate Firewall 7.0 (OpenBSD 4.6)<br />
          cpe:/o:openbsd:openbsd:4.6 auto<br />
          cpe:/h:genua:genugate<br />
          <br />
          Fingerprint OpenBSD 4.0<br />
          cpe:/o:openbsd:openbsd:4.0<br />
          <br />
          Fingerprint OpenBSD 4.0<br />
          cpe:/o:openbsd:openbsd:4.0<br />
          <br />
          Fingerprint OpenBSD 4.0<br />
          cpe:/o:openbsd:openbsd:4.0<br />
          <br />
          Fingerprint OpenBSD 4.0 (x86)<br />
          cpe:/o:openbsd:openbsd:4.0<br />
          <br />
          Fingerprint OpenBSD 4.0 (x86)<br />
          cpe:/o:openbsd:openbsd:4.0<br />
          <br />
          Fingerprint OpenBSD 4.1<br />
          cpe:/o:openbsd:openbsd:4.1<br />
          <br />
          Fingerprint OpenBSD 4.1<br />
          cpe:/o:openbsd:openbsd:4.1<br />
          <br />
          Fingerprint OpenBSD 4.1<br />
          cpe:/o:openbsd:openbsd:4.1<br />
          <br />
          Fingerprint OpenBSD 4.1 (x86)<br />
          cpe:/o:openbsd:openbsd:4.1<br />
          <br />
          Fingerprint OpenBSD 4.1 - 4.3<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint OpenBSD 4.2<br />
          cpe:/o:openbsd:openbsd:4.2<br />
          <br />
          Fingerprint OpenBSD 4.2<br />
          cpe:/o:openbsd:openbsd:4.2<br />
          <br />
          Fingerprint OpenBSD 4.2<br />
          cpe:/o:openbsd:openbsd:4.2<br />
          <br />
          Fingerprint OpenBSD 4.2 - 4.4<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.3<br />
          cpe:/o:openbsd:openbsd:4.3<br />
          <br />
          Fingerprint OpenBSD 4.4<br />
          cpe:/o:openbsd:openbsd:4.4<br />
          <br />
          Fingerprint OpenBSD 4.4<br />
          cpe:/o:openbsd:openbsd:4.4<br />
          <br />
          Fingerprint OpenBSD 4.4<br />
          cpe:/o:openbsd:openbsd:4.4<br />
          <br />
          Fingerprint OpenBSD 4.4<br />
          cpe:/o:openbsd:openbsd:4.4<br />
          <br />
          Fingerprint OpenBSD 4.4<br />
          cpe:/o:openbsd:openbsd:4.4<br />
          <br />
          Fingerprint OpenBSD 4.4 - 4.5<br />
          cpe:/o:openbsd:openbsd:4.4<br />
          <br />
          Fingerprint OpenBSD 4.4 - 4.6<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          <br />
          Fingerprint OpenBSD 4.5<br />
          cpe:/o:openbsd:openbsd:4.5<br />
          <br />
          Fingerprint OpenBSD 4.5<br />
          cpe:/o:openbsd:openbsd:4.5<br />
          <br />
          Fingerprint OpenBSD 4.5<br />
          cpe:/o:openbsd:openbsd:4.5 auto<br />
          <br />
          Fingerprint OpenBSD 4.6<br />
          cpe:/o:openbsd:openbsd:4.6<br />
          <br />
          Fingerprint OpenBSD 4.6<br />
          cpe:/o:openbsd:openbsd:4.6<br />
          <br />
          Fingerprint OpenBSD 4.6<br />
          cpe:/o:openbsd:openbsd:4.6<br />
          <br />
          Fingerprint OpenBSD 4.6<br />
          cpe:/o:openbsd:openbsd:4.6<br />
          <br />
          Fingerprint OpenBSD 4.6<br />
          cpe:/o:openbsd:openbsd:4.6<br />
          <br />
          Fingerprint OpenBSD 4.6<br />
          cpe:/o:openbsd:openbsd:4.6 auto<br />
          <br />
          Fingerprint OpenBSD 4.7<br />
          cpe:/o:openbsd:openbsd:4.7<br />
          <br />
          Fingerprint OpenBSD 4.7<br />
          cpe:/o:openbsd:openbsd:4.7<br />
          <br />
          Fingerprint OpenBSD 4.8<br />
          cpe:/o:openbsd:openbsd:4.8<br />
          <br />
          Fingerprint OpenBSD 4.9<br />
          cpe:/o:openbsd:openbsd:4.9 auto<br />
          <br />
          Fingerprint OpenBSD 4.9 - 5.1<br />
          cpe:/o:openbsd:openbsd:4<br />
          cpe:/o:openbsd:openbsd:4<br />
          <br />
          Fingerprint OpenBSD 4.9 - 5.4<br />
          cpe:/o:openbsd:openbsd:4 auto<br />
          cpe:/o:openbsd:openbsd:5 auto<br />
          <br />
          Fingerprint OpenBSD 5.0<br />
          cpe:/o:openbsd:openbsd:5.0 auto<br />
          <br />
          Fingerprint OpenBSD 5.0 - 5.8<br />
          cpe:/o:openbsd:openbsd:5 auto<br />
          <br />
          Fingerprint OpenBSD 5.0 - 6.0<br />
          cpe:/o:openbsd:openbsd:5 auto<br />
          cpe:/o:openbsd:openbsd:6 auto<br />
          <br />
          Fingerprint OpenBSD 5.1<br />
          cpe:/o:openbsd:openbsd:5.1<br />
          <br />
          Fingerprint OpenBSD 5.2<br />
          cpe:/o:openbsd:openbsd:5.2 auto<br />
          <br />
          Fingerprint OpenBSD 5.2<br />
          cpe:/o:openbsd:openbsd:5.2 auto<br />
          <br />
          Fingerprint OpenBSD 5.2 - 5.3<br />
          cpe:/o:openbsd:openbsd:5 auto<br />
          <br />
          Fingerprint OpenBSD 5.3<br />
          cpe:/o:openbsd:openbsd:5.3 auto<br />
          <br />
          Fingerprint OpenBSD 5.3<br />
          cpe:/o:openbsd:openbsd:5.3 auto<br />
          <br />
          Fingerprint OpenBSD 5.4<br />
          cpe:/o:openbsd:openbsd:5.4 auto<br />
          <br />
          Fingerprint OpenBSD 5.5<br />
          cpe:/o:openbsd:openbsd:5.5 auto<br />
          <br />
          Fingerprint OpenBSD 5.5<br />
          cpe:/o:openbsd:openbsd:5.5 auto<br />
          <br />
          Fingerprint OpenBSD 5.5<br />
          cpe:/o:openbsd:openbsd:5.5 auto<br />
          <br />
          Fingerprint OpenBSD 5.5<br />
          cpe:/o:openbsd:openbsd:5.5 auto<br />
          <br />
          Fingerprint OpenBSD 5.6 - 5.8<br />
          cpe:/o:openbsd:openbsd:5 auto<br />
          <br />
          Fingerprint OpenBSD 5.8<br />
          cpe:/o:openbsd:openbsd:5.8 auto<br />
          <br />
          Fingerprint OpenBSD 5.8<br />
          cpe:/o:openbsd:openbsd:5.8 auto<br />
          <br />
          Fingerprint OpenBSD 5.9<br />
          cpe:/o:openbsd:openbsd:5.9 auto<br />
          <br />
          Fingerprint OpenBSD 5.9<br />
          cpe:/o:openbsd:openbsd:5.9 auto<br />
          <br />
          Fingerprint OpenBSD 5.9<br />
          cpe:/o:openbsd:openbsd:5.9 auto<br />
          <br />
          Fingerprint OpenBSD 6.0<br />
          cpe:/o:openbsd:openbsd:6.0 auto<br />
          <br />
          Fingerprint OpenBSD 6.0<br />
          cpe:/o:openbsd:openbsd:6.0 auto<br />
          <br />
          Fingerprint OpenBSD 6.0<br />
          cpe:/o:openbsd:openbsd:6.0 auto<br />
          <br />
          Fingerprint OpenBSD 6.0 - 6.1<br />
          cpe:/o:openbsd:openbsd:6 auto<br />
          <br />
          Fingerprint OpenBSD 6.1<br />
          cpe:/o:openbsd:openbsd:6.1 auto<br />
          <br />
          Fingerprint OpenBSD 6.1<br />
          cpe:/o:openbsd:openbsd:6.1 auto<br />
          <br />
          Fingerprint OpenBSD 6.1<br />
          cpe:/o:openbsd:openbsd:6.1 auto<br />
          <br />
          Fingerprint Oracle JRockit Java virtual machine<br />
          cpe:/o:oracle:jrockit auto<br />
          <br />
          Fingerprint Oracle Solaris 10<br />
          cpe:/o:oracle:solaris:10 auto<br />
          <br />
          Fingerprint Oracle Solaris 10<br />
          cpe:/o:oracle:solaris:10 auto<br />
          <br />
          Fingerprint Oracle Solaris 11<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Solaris 11<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Solaris 11<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Solaris 11<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Solaris 11<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Solaris 11<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Solaris 11 or OpenIndiana<br />
          cpe:/o:oracle:solaris:11 auto<br />
          cpe:/o:illumos:openindiana auto<br />
          <br />
          Fingerprint Solaris 12<br />
          cpe:/o:oracle:solaris:11 auto<br />
          <br />
          Fingerprint Oracle Virtualbox<br />
          cpe:/o:oracle:virtualbox auto<br />
          <br />
          Fingerprint Oracle VM Server 3.4.2 (Linux 4.1)<br />
          cpe:/o:oracle:vm_server:3.4.2 auto<br />
          cpe:/o:linux:linux_kernel:4.1 auto<br />
          <br />
          Fingerprint Orange Livebox Business wireless DSL router<br />
          cpe:/h:orange:livebox<br />
          <br />
          Fingerprint Orange Livebox wireless DSL router or Sagem F@st 334 or
          3304 DSL router<br />
          cpe:/h:orange:livebox<br />
          cpe:/h:sagem:f%40ast_334<br />
          cpe:/h:sagem:f%40ast_3304<br />
          <br />
          Fingerprint OSRAM Lightify ZigBee gateway<br />
          cpe:/h:osram:lightify<br />
          <br />
          Fingerprint OSRAM Lightify ZigBee gateway<br />
          cpe:/h:osram:lightify<br />
          <br />
          Fingerprint OSRAM Lightify ZigBee gateway<br />
          cpe:/h:osram:lightify<br />
          <br />
          Fingerprint Packet8 BPA430 VoIP adapter<br />
          cpe:/h:packet8:bpa430 auto<br />
          <br />
          Fingerprint Packeteer PacketShaper 7500 firewall<br />
          cpe:/h:packeteer:packetshaper_7500 auto<br />
          <br />
          Fingerprint PacketFront DRG Ease DRG601-Access switch<br />
          cpe:/h:packetfront:drg_ease_drg601-access auto<br />
          <br />
          Fingerprint Palo Alto PA-500 firewall<br />
          cpe:/h:paloalto:pa-500 auto<br />
          <br />
          Fingerprint Panasonic PT-VZ575 projector<br />
          cpe:/h:panasonic:pt-vz575<br />
          <br />
          Fingerprint Panasonic DB-3500 series printer<br />
          cpe:/h:panasonic:db-3510<br />
          cpe:/h:panasonic:db-3520<br />
          <br />
          Fingerprint Panasonic DP-8045 printer<br />
          cpe:/h:panasonic:dp-8045 auto<br />
          <br />
          Fingerprint Panasonic BB-HCM511A or BL-C140A Network Camera<br />
          cpe:/h:panasonic:bb-hcm511a<br />
          cpe:/h:panasonic:bl-c140<br />
          <br />
          Fingerprint Panasonic BL-C140 Network Camera<br />
          cpe:/h:panasonic:bl-c140<br />
          <br />
          Fingerprint Panasonic BL-C140 Network Camera<br />
          cpe:/h:panasonic:bl-c140<br />
          <br />
          Fingerprint Panasonic BL-C210 Network Camera<br />
          cpe:/h:panasonic:bl-c210<br />
          <br />
          Fingerprint Panasonic BL-C210A webcam<br />
          cpe:/h:panasonic:bl-c210a auto<br />
          <br />
          Fingerprint Panasonic KX-HCM270 Network Camera or Symbol Spectrum24
          4131 WAP<br />
          cpe:/h:panasonic:kx-hcm270<br />
          cpe:/h:spectrum24_4131<br />
          <br />
          Fingerprint Panasonic WV-NS202A security camera<br />
          cpe:/h:panasonic:wv-ns202a<br />
          <br />
          Fingerprint Panasonic WV-SP300 or WV-SF330 webcam<br />
          cpe:/h:panasonic:wv-sp300<br />
          cpe:/h:panasonic:wv-sf330<br />
          <br />
          Fingerprint Patton SmartNode 4638 VoIP adapter<br />
          cpe:/o:patton:smartware:4 auto<br />
          <br />
          Fingerprint Patton SmartNode 4960 VoIP adapter (SmartWare 4.2)<br />
          cpe:/o:patton:smartware:4.2 auto<br />
          <br />
          Fingerprint Patton SmartNode 4112 VoIP adapter (SmartWare 5.6)<br />
          cpe:/o:patton:smartware:5.6<br />
          <br />
          Fingerprint PC-BSD 1.3<br />
          cpe:/o:pc-bsd:pc_bsd:1.3 auto<br />
          <br />
          Fingerprint PC-BSD 9.1<br />
          cpe:/o:pc-bsd:pc_bsd:9.1 auto<br />
          <br />
          Fingerprint Peplink Balance 380 router<br />
          cpe:/h:peplink:balance_380 auto<br />
          <br />
          Fingerprint Peplink Pepwave Surf AP 200 WAP<br />
          cpe:/h:peplink:pepwave_surf_ap_200 auto<br />
          <br />
          Fingerprint Perfectone IP-301 VoIP phone<br />
          cpe:/h:perfectone:ip-301 auto<br />
          <br />
          Fingerprint Phar Lap ETS 13<br />
          cpe:/o:pharlap:ets:13 auto<br />
          <br />
          Fingerprint PheeNet WAP-854GP WAP<br />
          cpe:/h:pheenet:wap-854gp auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP 1.4.1)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip:1.4.1 auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack v1.4.0)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip:1.4 auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge (lwIP stack)<br />
          cpe:/h:philips:hue_bridge<br />
          cpe:/a:lwip_project:lwip auto<br />
          <br />
          Fingerprint Philips Hue Bridge 2.0 (Linux)<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Phoenix Contact ILC 171 ETH 2TX PLC<br />
          cpe:/h:phoenix_contact:ilc_171_eth_2tx<br />
          <br />
          Fingerprint Pioneer VSX-31 video receiver<br />
          cpe:/h:pioneer:vsx-31<br />
          <br />
          Fingerprint Pirelli DP-10 VoIP phone<br />
          cpe:/h:pirelli:dp-10 auto<br />
          <br />
          Fingerprint Pirelli AV4202N wireless ADSL modem<br />
          cpe:/h:pirelli:av4202n auto<br />
          <br />
          Fingerprint Pirelli DRG A226M wireless ADSL modem<br />
          cpe:/h:pirelli:drg_a226m auto<br />
          <br />
          Fingerprint Pirelli PRG AV4202N WAP<br />
          cpe:/h:pirelli:prg_av4202n<br />
          <br />
          Fingerprint Planet ATA-150S VoIP adapter<br />
          cpe:/h:planet:ata-150s auto<br />
          <br />
          Fingerprint Planet VIP-550PT VoIP phone<br />
          cpe:/h:planet:vip-550pt auto<br />
          <br />
          Fingerprint Planet WAP-1950 WAP<br />
          cpe:/h:planet:wap-1950 auto<br />
          <br />
          Fingerprint Planet VIP-154T VoIP phone (MicroC/OS-II)<br />
          cpe:/o:planet:microc_os_ii auto<br />
          <br />
          Fingerprint Polycom SoundPoint IP 301 VoIP phone<br />
          cpe:/h:polycom:soundpoint_ip_301 auto<br />
          <br />
          Fingerprint Polycom SoundPoint IP 301 VoIP phone<br />
          cpe:/h:polycom:soundpoint_ip_301 auto<br />
          <br />
          Fingerprint Polycom SoundPoint IP 331 VoIP phone<br />
          cpe:/h:polycom:soundpoint_ip_331 auto<br />
          <br />
          Fingerprint Polycom SoundPoint IP 430 VoIP phone<br />
          cpe:/h:polycom:soundpoint_ip_430 auto<br />
          <br />
          Fingerprint Polycom ViewStation 4000 video conferencing system<br />
          cpe:/h:polycom:viewstation_4000 auto<br />
          <br />
          Fingerprint Polycom VS4000 video conferencing system<br />
          cpe:/h:polycom:vs4000 auto<br />
          <br />
          Fingerprint Polycom VSFX4 video conferencing system<br />
          cpe:/h:polycom:vsfx4 auto<br />
          <br />
          Fingerprint Polycom VSX 7000a video conferencing system<br />
          cpe:/h:polycom:vsx_7000a auto<br />
          <br />
          Fingerprint Polycom VSX 8000 video conferencing system<br />
          cpe:/h:polycom:vsx_8000 auto<br />
          <br />
          Fingerprint Polycom MGC-25 videoconferencing system (pSOS 1.0.4)<br />
          cpe:/o:polycom:psos:1.0.4 auto<br />
          <br />
          Fingerprint MQX RTOS<br />
          cpe:/o:precisesoftwaretechnologies:mqx auto<br />
          <br />
          Fingerprint QEMU user mode network gateway<br />
          cpe:/a:qemu:qemu<br />
          <br />
          Fingerprint QEMU user mode network gateway<br />
          cpe:/a:qemu:qemu<br />
          <br />
          Fingerprint QNAP TS-109 NAS device (Linux 2.6.32)<br />
          cpe:/h:qnap:ts-109 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint QNAP TS-209 NAS device (Linux 2.6.12)<br />
          cpe:/h:qnap:ts-209 auto<br />
          cpe:/o:qnap:qts:4 auto<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          <br />
          Fingerprint QNAP QTS 4.0 - 4.2<br />
          cpe:/o:qnap:qts:4 auto<br />
          <br />
          Fingerprint QNAP QTS NAS device (Linux 2.6.12)<br />
          cpe:/o:qnap:qts auto<br />
          cpe:/o:linux:linux_kernel:2.6.12 auto<br />
          <br />
          Fingerprint QNX RTOS 4.25<br />
          cpe:/o:qnx:rtos:4.25 auto<br />
          <br />
          Fingerprint QNX RTOS 4.25<br />
          cpe:/o:qnx:rtos:4.25 auto<br />
          <br />
          Fingerprint QNX RTOS 6.2<br />
          cpe:/o:qnx:rtos:6.2 auto<br />
          <br />
          Fingerprint QNX RTOS 6.2.1<br />
          cpe:/o:qnx:rtos:6.2.1 auto<br />
          <br />
          Fingerprint QNX RTOS 6.3<br />
          cpe:/o:qnx:rtos:6.3 auto<br />
          <br />
          Fingerprint QNX RTOS 7.0.0<br />
          cpe:/o:qnx:rtos:7.0.0 auto<br />
          <br />
          Fingerprint QNX RTOS<br />
          cpe:/o:qnx:rtos auto<br />
          <br />
          Fingerprint QTech QSW-2900 switch<br />
          cpe:/h:qtech:qsw-2900 auto<br />
          <br />
          Fingerprint Qualisys Oqus 300 camera<br />
          cpe:/h:qualisys:oqus_300<br />
          <br />
          Fingerprint Quarterdeck DESQview/X 2.10<br />
          cpe:/o:quarterdeck:desqview_x:2.10 auto<br />
          <br />
          Fingerprint Radware LinkProof load balancer<br />
          cpe:/o:radware:apsolute_os:10 auto<br />
          <br />
          Fingerprint Radware OnDemand Switch 2 XL (APsolute OS 10.31)<br />
          cpe:/o:radware:apsolute_os:10.31 auto<br />
          <br />
          Fingerprint Raritan Dominion KX II KVM switch<br />
          cpe:/h:raritan:dominion_kx-ii auto<br />
          <br />
          Fingerprint Raritan Dominion SX32 KVM switch or Ricoh Aficio IS200e
          scanner<br />
          cpe:/h:raritan:dominion_sx32<br />
          cpe:/h:ricoh:is200e<br />
          <br />
          Fingerprint RCA DCM425C cable modem<br />
          cpe:/h:rca:dcm425c auto<br />
          <br />
          Fingerprint ReactOS 0.3.7<br />
          cpe:/o:reactos:reactos:0.3.7 auto<br />
          <br />
          Fingerprint Linksys WET54G wireless bridge or Red-M Communications
          Red-Alert PRO wireless activity detector<br />
          cpe:/h:linksys:wet54g auto<br />
          <br />
          Fingerprint Ericsson SmartEdge router (SmartEdge OS 11.1)<br />
          cpe:/o:redback:smartedge_os:11.1 auto<br />
          <br />
          Fingerprint Redback Networks SmartEdge router (SmartEdge OS 5.0)<br />
          cpe:/o:redback:smartedge_os:5.0 auto<br />
          <br />
          Fingerprint Redback Networks SmartEdge 600 router (SmartEdge OS)<br />
          cpe:/o:redback:smartedge_os auto<br />
          <br />
          Fingerprint Revo Blik Wi-Fi Internet radio<br />
          cpe:/h:revo:blik_wi-fi<br />
          <br />
          Fingerprint RF-Space SDR-IP software radio or WIZnet W5200 TCP/IP
          Ethernet chip<br />
          cpe:/h:wiznet:w5200<br />
          <br />
          Fingerprint Ricoh Aficio 1022 copier<br />
          cpe:/h:ricoh:aficio_1022 auto<br />
          <br />
          Fingerprint Ricoh Aficio 1060 or 2035e printer<br />
          cpe:/h:ricoh:aficio_2035e auto<br />
          cpe:/h:ricoh:aficio_1060 auto<br />
          <br />
          Fingerprint Ricoh Aficio 3045/3245C or Savin 8025e printer<br />
          cpe:/h:ricoh:aficio_3045<br />
          cpe:/h:ricoh:aficio_3245c<br />
          cpe:/h:savin:8025e auto<br />
          <br />
          Fingerprint Ricoh Aficio BP20N printer<br />
          cpe:/h:ricoh:aficio_bp20n auto<br />
          <br />
          Fingerprint Ricoh Aficio MP C2550 printer<br />
          cpe:/h:ricoh:aficio_mp_c2550 auto<br />
          <br />
          Fingerprint Ricoh Aficio MP C4501 printer<br />
          cpe:/h:ricoh:aficio_mp_c4501<br />
          <br />
          Fingerprint Ricoh Aficio MP C6000 or GX3050N printer<br />
          cpe:/h:ricoh:aficio_mp_c6000<br />
          cpe:/h:ricoh:aficio_mp_gx3050n<br />
          <br />
          Fingerprint Ricoh Aficio SP 4100N printer<br />
          cpe:/h:ricoh:aficio_sp_4100n auto<br />
          <br />
          Fingerprint Ricoh Aficio SP 4100N printer<br />
          cpe:/h:ricoh:aficio_sp_4100n auto<br />
          <br />
          Fingerprint Ricoh Aficio SP C210SF printer<br />
          cpe:/h:ricoh:aficio_sp_c210sf auto<br />
          <br />
          Fingerprint Ricoh Aficio SP C240SF printer<br />
          cpe:/h:ricoh:aficio_sp_c240sf auto<br />
          <br />
          Fingerprint Ricoh Aficio SP C311N printer (VxWorks)<br />
          cpe:/h:ricoh:aficio_sp_c311n auto<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Ricoh Aficio SP C420DN printer<br />
          cpe:/h:ricoh:aficio_sp_c420dn auto<br />
          <br />
          Fingerprint Ricoh MP W6700 printer<br />
          cpe:/h:ricoh:mp_w6700 auto<br />
          <br />
          Fingerprint Rigol DSG3060 signal generator<br />
          cpe:/h:rigol_technologies:dsg3060<br />
          <br />
          Fingerprint BlackBerry 10<br />
          cpe:/o:blackberry:blackberry_os:10.0<br />
          <br />
          Fingerprint BlackBerry 10<br />
          cpe:/o:blackberry:blackberry_os:10.0<br />
          <br />
          Fingerprint BlackBerry 10<br />
          cpe:/o:rim:blackberry:10 auto<br />
          <br />
          Fingerprint BlackBerry 10.3<br />
          cpe:/o:rim:blackberry:10.3 auto<br />
          <br />
          Fingerprint BlackBerry 7.1<br />
          cpe:/o:rim:blackberry:7.1 auto<br />
          <br />
          Fingerprint BlackBerry Tablet OS 2<br />
          cpe:/o:rim:tablet_os:2 auto<br />
          <br />
          Fingerprint BlackBerry Tablet OS 2.0<br />
          cpe:/h:rim:blackberry_playbook_tablet<br />
          cpe:/o:rim:blackberry_playbook_os:2.0<br />
          <br />
          Fingerprint BlackBerry Tablet OS 2.1<br />
          cpe:/o:rim:tablet_os:2.1 auto<br />
          <br />
          Fingerprint RISCOS Ltd RISC OS 4.39<br />
          cpe:/o:riscosltd:risc_os:4.39 auto<br />
          <br />
          Fingerprint RISCOS Ltd RISC OS 5.23<br />
          cpe:/o:riscosltd:risc_os:5.23 auto<br />
          <br />
          Fingerprint RISCOS Ltd RISC OS 6.20<br />
          cpe:/o:riscosltd:risc_os:6.20<br />
          <br />
          Fingerprint Riverbed Steelhead 200 proxy server<br />
          cpe:/h:riverbed:steelhead_200<br />
          <br />
          Fingerprint Riverbed RiOS<br />
          cpe:/o:riverbed:rios<br />
          <br />
          Fingerprint Riverbed Steelhead Mobile Controller 4.0.3<br />
          cpe:/o:riverbed:rios auto<br />
          <br />
          Fingerprint Roberts STREAM 202 Internet radio<br />
          cpe:/h:roberts:stream_202<br />
          <br />
          Fingerprint Rockwell Automation 1761-NET-ENI Ethernet-to-RS-232-C
          interface module<br />
          cpe:/h:rockwellautomation:1761-net-eni<br />
          <br />
          Fingerprint Rockwell Automation 1761-NET-ENI Ethernet-to-RS-232-C
          interface module<br />
          cpe:/h:rockwellautomation:1761-net-eni<br />
          <br />
          Fingerprint Rockwell Automation 1769-L23E-QB1 PLC<br />
          cpe:/h:rockwellautomation:1769-l23e-qb1<br />
          <br />
          Fingerprint Roku HD1500 media player<br />
          cpe:/h:roku:soundbridge_m1500<br />
          <br />
          Fingerprint Roku SoundBridge M1001 music player<br />
          cpe:/h:roku:soundbridge_m1001<br />
          <br />
          Fingerprint Roku SoundBridge M500 or M1000 music player<br />
          cpe:/h:roku:soundbridge_m500<br />
          cpe:/h:roku:soundbridge_m1000<br />
          <br />
          Fingerprint RSA SecurID authentication appliance<br />
          cpe:/h:rsa:securid<br />
          <br />
          Fingerprint Ruckus 7363 WAP<br />
          cpe:/h:ruckus:7363 auto<br />
          <br />
          Fingerprint Ruckus ZD1050 WAP<br />
          cpe:/h:ruckus:zd1050 auto<br />
          <br />
          Fingerprint Ruckus ZoneFlex R710 WAP (Linux 3.4)<br />
          cpe:/h:ruckus:zoneflex_r710 auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint RuggedCom RSG2288 switch (ROS 3.8.2 - 3.11)<br />
          cpe:/o:ruggedcom:ros:3 auto<br />
          <br />
          Fingerprint Ruijie N18010 switch (Linux 2.6.32)<br />
          cpe:/h:ruijie:n18010 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Ruijie N18010 switch (Linux 2.6.32)<br />
          cpe:/h:ruijie:n18010 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Sagemcom b-box 3 modem<br />
          cpe:/h:sagem:b-box_3<br />
          <br />
          Fingerprint Samsung Bada 1.2<br />
          cpe:/o:samsung:bada:1.2 auto<br />
          <br />
          Fingerprint Samsung Bada 2.0<br />
          cpe:/o:samsung:bada:2.0 auto<br />
          <br />
          Fingerprint Samsung LE32B651 TV<br />
          cpe:/h:samsung:le32b651<br />
          <br />
          Fingerprint Samsung CLP-310N or CLX-3175RW, or Xerox Phaser 6110
          printer<br />
          cpe:/h:samsung:clp-310n<br />
          cpe:/h:samsung:clx-3175rw<br />
          cpe:/h:xerox:phaser_6110<br />
          <br />
          Fingerprint Samsung CLP-315W printer<br />
          cpe:/h:samsung:clp-315w auto<br />
          <br />
          Fingerprint Samsung CLP-320N printer<br />
          cpe:/h:samsung:clp-320n auto<br />
          <br />
          Fingerprint Samsung CLP-610ND printer<br />
          cpe:/h:samsung:clp-610nd<br />
          <br />
          Fingerprint Samsung CLP-620ND, CLX-3185, CLX-6220FX, ML-1865W,
          ML-2580N, or ML-3312ND; or Xerox Phaser 3300MFP printer<br />
          cpe:/h:samsung:clx-3185<br />
          cpe:/h:samsung:clx-6220fx<br />
          cpe:/h:samsung:ml-1865w<br />
          cpe:/h:samsung:ml-2580n<br />
          cpe:/h:samsung:ml-3312nd<br />
          cpe:/h:xerox:phaser_3300<br />
          <br />
          Fingerprint Samsung CLP-770ND printer<br />
          cpe:/h:samsung:clp-770nd<br />
          <br />
          Fingerprint Samsung CLX-3160FN printer<br />
          cpe:/h:samsung:clx-3160fn auto<br />
          <br />
          Fingerprint Samsung CLX-3185FW printer<br />
          cpe:/h:samsung:clx-3185fw auto<br />
          <br />
          Fingerprint Samsung CLX-6220 or SCX-5835 or Xerox Phaser 3635MFP or
          4600 printer<br />
          cpe:/h:samsung:clx-6220<br />
          cpe:/h:samsung:scx-5835<br />
          cpe:/h:xerox:phaser_3635mfp<br />
          <br />
          Fingerprint Samsung M2020W wireless printer<br />
          cpe:/h:samsung:m2020w auto<br />
          <br />
          Fingerprint Samsung M2825 printer<br />
          cpe:/h:samsung:m2825 auto<br />
          <br />
          Fingerprint Samsung ML-1865W printer<br />
          cpe:/h:samsung:ml-1865w auto<br />
          <br />
          Fingerprint Samsung SCX-3405FW, CLX-3305FW, or ML-451x-501x-Series
          printer<br />
          cpe:/h:samsung:scx-3405fw auto<br />
          cpe:/h:samsung:clx-3305fw auto<br />
          <br />
          Fingerprint Samsung OfficeServ 7100 VoIP adapter<br />
          cpe:/h:samsung:officeserv_7100 auto<br />
          <br />
          Fingerprint Samsung OfficeServ 7200 VoIP adapter or Harris FlexStar HD
          radio/FM broadcast exciter<br />
          cpe:/h:samsung:officeserv_7200 auto<br />
          cpe:/h:harris:flexstar_hdx-fm<br />
          <br />
          Fingerprint Samsung SMT-i5220 or SMT-i3100 VoIP phone<br />
          cpe:/h:samsung:smt-i5220 auto<br />
          <br />
          Fingerprint Samsung i8910 mobile phone (Symbian OS 9.4)<br />
          cpe:/o:samsung:symbian_os:9.4 auto<br />
          <br />
          Fingerprint Sanyo PLC-XU88 digital video projector<br />
          cpe:/h:sanyo:plc-xu88<br />
          <br />
          Fingerprint Satel ETHM-2 intruder alarm<br />
          cpe:/h:satel:ethm-2<br />
          <br />
          Fingerprint Schneider Electric TSX ETY programmable logic
          controller<br />
          cpe:/h:schneiderelectric:tsx_ety<br />
          <br />
          Fingerprint Schweitzer Engineering SEL-2701 Ethernet processor<br />
          cpe:/h:schweitzerengineering:sel-2701<br />
          <br />
          Fingerprint Scientific Atlanta WebSTAR DPC2100 cable modem<br />
          cpe:/h:scientificatlanta:webstar_dpc2100 auto<br />
          <br />
          Fingerprint Scientific Atlanta WebSTAR DPC2100R2 cable modem<br />
          cpe:/h:scientificatlanta:webstar_dpc2100r2 auto<br />
          <br />
          Fingerprint Scientific Atlanta WebSTAR EPC2203 cable modem<br />
          cpe:/h:scientificatlanta:webstar_epc2203 auto<br />
          <br />
          Fingerprint SCO OpenServer 5<br />
          cpe:/o:sco:openserver:5 auto<br />
          <br />
          Fingerprint SCO OpenServer 5.0.2 - 5.0.5<br />
          cpe:/o:sco:openserver:5 auto<br />
          <br />
          Fingerprint SCO OpenServer 5.0.5<br />
          cpe:/o:sco:openserver:5.0.5 auto<br />
          <br />
          Fingerprint SCO OpenServer 5.0.7<br />
          cpe:/o:sco:openserver:5.0.7 auto<br />
          <br />
          Fingerprint SCO OpenServer 5.0.7<br />
          cpe:/o:sco:openserver:5.0.7 auto<br />
          <br />
          Fingerprint SCO OpenServer 5.0.7 (x86)<br />
          cpe:/o:sco:openserver:5.0.7 auto<br />
          <br />
          Fingerprint SCO OpenServer 6.0 or UnixWare 7.1.4<br />
          cpe:/o:sco:openserver:6.0 auto<br />
          cpe:/o:sco:unixware:7.1.4<br />
          <br />
          Fingerprint SCO UNIX 3.2<br />
          cpe:/o:sco:sco_unix:3.2 auto<br />
          <br />
          Fingerprint SCO UNIX 3.2<br />
          cpe:/o:sco:sco_unix:3.2 auto<br />
          <br />
          Fingerprint SCO UNIX 3.2v5.0.7<br />
          cpe:/o:sco:sco_unix:3.2v5.0.7 auto<br />
          <br />
          Fingerprint SCO UnixWare 2.1<br />
          cpe:/o:sco:unixware:2.1 auto<br />
          <br />
          Fingerprint SCO UnixWare 7.1.1<br />
          cpe:/o:sco:unixware:7.1.1 auto<br />
          <br />
          Fingerprint SCO UnixWare 7.1.3<br />
          cpe:/o:sco:unixware:7.1.3 auto<br />
          <br />
          Fingerprint SCO UnixWare 8.0.0<br />
          cpe:/o:sco:unixware:8.0.0 auto<br />
          <br />
          Fingerprint Secure Computing Sidewinder firewall (SecureOS
          6.1.1.05)<br />
          cpe:/o:securecomputing:secureos:6.1.1.05 auto<br />
          <br />
          Fingerprint Secure Computing Sidewinder G2 firewall (SecureOS
          6.1.2.0.3)<br />
          cpe:/o:securecomputing:secureos:6.1.2.0.3 auto<br />
          <br />
          Fingerprint Secure Computing SecureOS 7.0.0.04<br />
          cpe:/o:securecomputing:secureos:7.0.0.04 auto<br />
          <br />
          Fingerprint Secure Computing Sidewinder firewall (SecureOS
          7.0.0.04)<br />
          cpe:/o:securecomputing:secureos:7.0.0.04 auto<br />
          <br />
          Fingerprint SEH InterCon IC105 print server<br />
          cpe:/h:seh:intercon_ic105 auto<br />
          <br />
          Fingerprint Seiko NS-2232 serial-to-IP bridge<br />
          cpe:/h:seiko:ns-2232<br />
          <br />
          Fingerprint Senao NL-2611CB3 PLUS WAP<br />
          cpe:/h:senao:nl-2611cb3_plus<br />
          <br />
          Fingerprint Sensatronics E4 temperature monitor<br />
          cpe:/h:sensatronics:e4<br />
          <br />
          Fingerprint Sensatronics EM1 environmental monitor<br />
          cpe:/h:sensatronics:em1<br />
          <br />
          Fingerprint Sequent DYNIX (BSD-based Unix)<br />
          cpe:/o:sequent:dynix auto<br />
          <br />
          Fingerprint SGI IRIX 4.0.5F<br />
          cpe:/o:sgi:irix:4.0.5f auto<br />
          <br />
          Fingerprint SGI IRIX 5.2<br />
          cpe:/o:sgi:irix:5.2 auto<br />
          <br />
          Fingerprint SGI IRIX 5.3<br />
          cpe:/o:sgi:irix:5.3 auto<br />
          <br />
          Fingerprint IRIX64 IRIS 6.5<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX 6.5<br />
          cpe:/o:sgi:irix:6.5 auto<br />
          <br />
          Fingerprint SGI IRIX 6.5<br />
          cpe:/o:sgi:irix:6.5 auto<br />
          <br />
          Fingerprint SGI IRIX 6.5.15m<br />
          cpe:/o:sgi:irix:6.5.15m auto<br />
          <br />
          Fingerprint SGI IRIX 6.5.22m - 6.5.30f<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX 6.5.30<br />
          cpe:/o:sgi:irix:6.5.30 auto<br />
          <br />
          Fingerprint SGI IRIX 6.5.6m<br />
          cpe:/o:sgi:irix:6.5.6m auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5.15m<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5.19f<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5.22f<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint SGI IRIX64 6.5.30m<br />
          cpe:/o:sgi:irix:6 auto<br />
          <br />
          Fingerprint Sharp AR-C260M or AR-M351N printer<br />
          cpe:/h:sharp:ar-c260m<br />
          cpe:/h:sharp:ar-m351n<br />
          <br />
          Fingerprint Sharp AR-M236 printer<br />
          cpe:/h:sharp:ar-m236 auto<br />
          <br />
          Fingerprint Sharp AR-M237 printer<br />
          cpe:/h:sharp:ar-m237 auto<br />
          <br />
          Fingerprint Shenzhen Kinoway K-3288W or PORTech MV-374 GSM-SIP VoIP
          adapter<br />
          cpe:/h:shenzhen_kinoway:k-3288w<br />
          cpe:/h:portech:mv-374<br />
          <br />
          Fingerprint ShoreTel ShoreGear-T1 VoIP switch<br />
          cpe:/h:shoretel:shoregear-t1<br />
          <br />
          Fingerprint ShoreTel Voice Switch 220T1A VoIP gateway<br />
          cpe:/h:shoretel:voice_switch_220t1a auto<br />
          <br />
          Fingerprint Siemens C2-010-I ADSL modem<br />
          cpe:/h:siemens:c2-010-i auto<br />
          <br />
          Fingerprint Siemens Gigaset SE567 WAP or SpeedStream 4200 ADSL
          modem<br />
          cpe:/h:siemens:speedstream_4200<br />
          cpe:/h:siemens:gigaset_se567<br />
          <br />
          Fingerprint Siemens SpeedStream 4200 ADSL modem<br />
          cpe:/h:siemens:speedstream_4200 auto<br />
          <br />
          Fingerprint Siemens HiPath 3000 PBX<br />
          cpe:/h:siemens:hipath_3000<br />
          <br />
          Fingerprint Siemens AEM100 remote management unit<br />
          cpe:/h:siemens:aem100<br />
          <br />
          Fingerprint Siemens MP370 input panel (Windows CE)<br />
          cpe:/o:microsoft:windows_ce auto<br />
          <br />
          Fingerprint Siemens Simatic 300 programmable logic controller<br />
          cpe:/h:siemens:simatic_300<br />
          <br />
          Fingerprint Siemens Simatic 300 programmable logic controller<br />
          cpe:/h:siemens:simatic_300<br />
          <br />
          Fingerprint Siemens Simatic TDC control system<br />
          cpe:/h:siemens:simatic_tdc<br />
          <br />
          Fingerprint Siemens SPS programmable logic controller<br />
          cpe:/h:siemens:sps<br />
          <br />
          Fingerprint Siemens HiPath 4000 VoIP adapter<br />
          cpe:/h:siemens:hipath_4000 auto<br />
          <br />
          Fingerprint Siemens HiPath optiPoint 400 VoIP phone<br />
          cpe:/h:siemens:hipath_optipoint_400 auto<br />
          <br />
          Fingerprint Siemens optiPoint 410 VoIP phone (VxWorks)<br />
          cpe:/h:siemens:optipoint_410 auto<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Siemens Gigaset SE551 wireless broadband router<br />
          cpe:/h:siemens:gigaset_se551 auto<br />
          <br />
          Fingerprint Siemens Gigaset SE551 wireless broadband router<br />
          cpe:/h:siemens:gigaset_se551 auto<br />
          <br />
          Fingerprint Siemens W748 or W788 WAP<br />
          cpe:/h:siemens:w748 auto<br />
          cpe:/h:siemens:w788 auto<br />
          <br />
          Fingerprint Siemens SINIX-D on x86<br />
          cpe:/o:siemens:sinix auto<br />
          <br />
          Fingerprint Siemens SINIX-Z on RM400<br />
          cpe:/o:siemens:sinix auto<br />
          <br />
          Fingerprint SiliconDust HDHomeRun 3 set top box<br />
          cpe:/h:silicondust:hdhr3<br />
          <br />
          Fingerprint Silicondust HDHomeRun set top box<br />
          cpe:/h:silicondust:hdhr<br />
          <br />
          Fingerprint SiliconDust HDHomeRun set top box<br />
          cpe:/h:silicondust:hdhr<br />
          <br />
          Fingerprint SiliconDust HDHomeRun set top box<br />
          cpe:/h:silicondust:hdhr<br />
          <br />
          Fingerprint Sipura SPA1001 or SPA3000 VoIP adapter<br />
          cpe:/h:sipura:spa1001_voice_gateway_with_router:-<br />
          cpe:/o:sipura:spa1001_voice_gateway_with_router_firmware<br />
          cpe:/h:sipura:spa3000_voice_gateway_with_router:-<br />
          cpe:/o:sipura:spa3000_voice_gateway_with_router_firmware<br />
          <br />
          Fingerprint Sipura SPA3000 VoIP adapter<br />
          cpe:/h:sipura:spa3000_voice_gateway_with_router:-<br />
          cpe:/o:sipura:spa3000_voice_gateway_with_router_firmware<br />
          <br />
          Fingerprint Sipura SPA3000 VoIP adapter<br />
          cpe:/h:sipura:spa3000 auto<br />
          <br />
          Fingerprint Sitecom WL-174 wireless ADSL router or ZyXEL B-3000 WAP<br />
          cpe:/h:sitecom:wl-174 auto<br />
          cpe:/h:zyxel:b-3000 auto<br />
          <br />
          Fingerprint Sitecom WL-342 WAP<br />
          cpe:/h:sitecom:wl-342 auto<br />
          <br />
          Fingerprint Slingbox Classic TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_classic<br />
          <br />
          Fingerprint Slingbox Classic TV over IP gateway (Firmware 1.0.89)<br />
          cpe:/h:slingmedia:slingbox_classic<br />
          <br />
          Fingerprint Slingbox PRO TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_pro<br />
          <br />
          Fingerprint Slingbox Pro TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_pro<br />
          <br />
          Fingerprint Slingbox SOLO or PRO TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_solo<br />
          cpe:/h:slingmedia:slingbox_pro<br />
          <br />
          Fingerprint Slingbox Tuner TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_tuner<br />
          <br />
          Fingerprint Slingbox TV over IP gateway (Firmware 1.2.9 - 1.3.80)<br />
          cpe:/h:slingmedia:slingbox<br />
          <br />
          Fingerprint Slingmedia Slingbox AV TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_av<br />
          <br />
          Fingerprint Slingmedia Slingbox AV TV over IP gateway<br />
          cpe:/h:slingmedia:slingbox_av<br />
          <br />
          Fingerprint Smartlink DIGISOL DG-HR3400 WAP<br />
          cpe:/h:smartlink:digisol_dg-hr3400 auto<br />
          <br />
          Fingerprint SMC SMB7004ABR WAP<br />
          cpe:/h:smc:smb7004abr auto<br />
          <br />
          Fingerprint SMC SMC8014WG WAP<br />
          cpe:/h:smc:smc8014wg auto<br />
          <br />
          Fingerprint SMC SMC8014WG WAP<br />
          cpe:/h:smc:smc8014wg auto<br />
          <br />
          Fingerprint SMC Barricade 7004BR router<br />
          cpe:/h:smc:barricade_7004br auto<br />
          <br />
          Fingerprint SMC SMC7908A-ISP router<br />
          cpe:/h:smc:smc7908a-isp auto<br />
          <br />
          Fingerprint SMC 8024L2 switch<br />
          cpe:/h:smc:8024l2 auto<br />
          <br />
          Fingerprint SMC SMC6750L2 or SMC7724M/VSW switch<br />
          cpe:/h:smc:smc6750l2<br />
          cpe:/h:smc:smc7724m%2fvsw<br />
          <br />
          Fingerprint SMC TigerStack III 6824M switch<br />
          cpe:/h:smc:tigerstack_iii_6824m auto<br />
          <br />
          Fingerprint SMC TigerSwitch SMC8150L2 switch<br />
          cpe:/h:smc:tigerswitch_smc8150l2 auto<br />
          <br />
          Fingerprint SMC 7904WBRA-N wireless ADSL router<br />
          cpe:/h:smc:7904wbra-n auto<br />
          <br />
          Fingerprint SMC SMC2804WBRP-G wireless broadband router<br />
          cpe:/h:smc:smc2804wbrp-g auto<br />
          <br />
          Fingerprint SMC SMC7904WBRA wireless ADSL router or T-Home Speedport W
          700V WAP<br />
          cpe:/h:smc:smc7904wbra<br />
          cpe:/h:t-home:speedport_w_700v<br />
          <br />
          Fingerprint SMC SMC7904WBRB2 wireless broadband router<br />
          cpe:/h:smc:smc7904wbrb2 auto<br />
          <br />
          Fingerprint SMC SMCWBR14-G2 Barricade g WAP<br />
          cpe:/h:smc:smbwbr14-g2<br />
          <br />
          Fingerprint SMC SMCWBR14-G2 EU Barricade g WAP<br />
          cpe:/h:smc:smbwbr14-g2<br />
          <br />
          Fingerprint Snom 360 VoIP phone<br />
          cpe:/h:snom:360 auto<br />
          <br />
          Fingerprint SNOM 715 VoIP phone<br />
          cpe:/h:snom:715 auto<br />
          <br />
          Fingerprint SNR SNR-S2960 switch<br />
          cpe:/h:snr:snr-s2960 auto<br />
          <br />
          Fingerprint SonicWALL Aventail EX-1500 SSL VPN appliance<br />
          cpe:/o:sonicwall:aventail_ex-1500<br />
          <br />
          Fingerprint SonicWALL TZ 170 Unlimited firewall (SonicOS)<br />
          cpe:/o:sonicwall:tz_170<br />
          <br />
          Fingerprint SonicWALL Aventail EX-6000 VPN appliance<br />
          cpe:/o:sonicwall:aventail_ex-6000<br />
          <br />
          Fingerprint SonicWALL TZ 170 Standard firewall (SonicOS 2.0)<br />
          cpe:/o:sonicwall:sonicos:2.0 auto<br />
          <br />
          Fingerprint SonicWALL PRO 2040 firewall (SonicOS 3.1)<br />
          cpe:/o:sonicwall:sonicos:3.1 auto<br />
          <br />
          Fingerprint SonicWALL PRO 4060 firewall (SonicOS 3.2)<br />
          cpe:/o:sonicwall:sonicos:3.2 auto<br />
          <br />
          Fingerprint SonicWALL SonicOS 3.9<br />
          cpe:/o:sonicwall:sonicos:3.9 auto<br />
          <br />
          Fingerprint SonicWALL TZ 170 Standard firewall (SonicOS 3.1)<br />
          cpe:/o:sonicwall:sonicos:3.1 auto<br />
          <br />
          Fingerprint SonicWALL TZ 170 Standard firewall (SonicOS 3.1)<br />
          cpe:/o:sonicwall:sonicos:3.1 auto<br />
          <br />
          Fingerprint SonicWALL PRO 3060 Enhanced firewall (SonicOS 4.0.0.12)<br />
          cpe:/o:sonicwall:sonicos:4.0.0.12 auto<br />
          <br />
          Fingerprint SonicWALL TZ 190 firewall (SonicOS Enhanced 4.0)<br />
          cpe:/o:sonicwall:sonicos:4 auto<br />
          <br />
          Fingerprint Dell Sonicwall NSA 220 firewall<br />
          cpe:/o:sonicwall:sonicos:5 auto<br />
          <br />
          Fingerprint SonicWALL NSA 220 firewall (SonicOS Enhanced 5.8)<br />
          cpe:/o:sonicwall:sonicos:5 auto<br />
          <br />
          Fingerprint SonicWALL SonicOS Enhanced 5.2<br />
          cpe:/o:sonicwall:sonicos:5 auto<br />
          <br />
          Fingerprint SonicWALL TELE3 firewall (SonicOS 6.6.3.0)<br />
          cpe:/o:sonicwall:sonicos:6.6.3.0 auto<br />
          <br />
          Fingerprint SonicWALL PRO 230 firewall (SonicOS)<br />
          cpe:/o:sonicwall:sonicos auto<br />
          <br />
          Fingerprint Sonos ZonePlayer audio distribution unit<br />
          cpe:/h:sonos:zoneplayer<br />
          <br />
          Fingerprint Sonus GSX9000 VoIP proxy<br />
          cpe:/h:sonus:gsx9000 auto<br />
          <br />
          Fingerprint Sonus SBC1000 SIP gateway<br />
          cpe:/h:sonus:sbc1000<br />
          <br />
          Fingerprint Sony Ericsson Aino mobile phone<br />
          cpe:/h:sonyericsson:aino<br />
          <br />
          Fingerprint Sony Ericsson C902, W910i, or Z780i mobile phone<br />
          cpe:/h:sonyericsson:c902<br />
          cpe:/h:sonyericsson:w910i<br />
          cpe:/h:sonyericsson:z780i<br />
          <br />
          Fingerprint Sony Ericsson G900 mobile phone<br />
          cpe:/h:sonyericsson:g900<br />
          <br />
          Fingerprint Sony Ericsson Hazel (J10, J20) or Elm mobile phone<br />
          cpe:/h:sonyericsson:j20<br />
          cpe:/h:sonyericsson:j20i<br />
          <br />
          Fingerprint Sony Ericsson K850i mobile phone<br />
          cpe:/h:sonyericsson:k850i<br />
          <br />
          Fingerprint Sony Ericsson U8i Vivaz mobile phone<br />
          cpe:/h:sonyericsson:u8i_vivaz<br />
          <br />
          Fingerprint Sony Ericsson W705 or W715 Walkman mobile phone<br />
          cpe:/h:sonyericsson:w705<br />
          cpe:/h:sonyericsson:w715<br />
          <br />
          Fingerprint Sony Ericsson W705 or W995 Walkman mobile phone<br />
          cpe:/h:sonyericsson:w705<br />
          <br />
          Fingerprint Sony Ericsson M600i mobile phone (Symbian OS 9.1)<br />
          cpe:/h:sonyericsson:m600i<br />
          cpe:/o:sonyericsson:symbian_os:9.1 auto<br />
          <br />
          Fingerprint Sony Ericsson P1i mobile phone (Symbian OS 9.1)<br />
          cpe:/h:sonyericsson:p1i<br />
          cpe:/o:sonyericsson:symbian_os:9.1 auto<br />
          <br />
          Fingerprint Sony PlayStation 2 game console<br />
          cpe:/h:sony:playstation_2<br />
          <br />
          Fingerprint Sony PlayStation 2 game console test kit 2.2.1<br />
          cpe:/h:sony:playstation_2<br />
          <br />
          Fingerprint Sony PlayStation 3 game console<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PlayStation 3 game console<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PlayStation 3 game console<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PlayStation 3 game console<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PlayStation 3 game console<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PlayStation 3 game console<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PlayStation 3 game console test kit<br />
          cpe:/h:sony:playstation_3<br />
          <br />
          Fingerprint Sony PSP game console (modified, running Custom Firmware
          3.90 - 5.50)<br />
          cpe:/h:sony:playstation_portable<br />
          <br />
          Fingerprint Sony BDP-S370 or BDP-S570 Blu-ray player<br />
          cpe:/h:sony:bdp-s370<br />
          cpe:/h:sony:bdp-s570<br />
          <br />
          Fingerprint Sony BDV-E970W television<br />
          cpe:/h:sony:bdv-e970w<br />
          <br />
          Fingerprint Sony BDV-T57 television<br />
          cpe:/h:sony:bdv-t57<br />
          <br />
          Fingerprint Sony Bravia smart TV (Android)<br />
          cpe:/o:google:android auto<br />
          <br />
          Fingerprint Sony Bravia V5500-series TV<br />
          cpe:/h:sony:bravia_kdl-32v5500<br />
          <br />
          Fingerprint Sony Bravia W5500-series TV<br />
          cpe:/h:sony:bravia_kdl-32w550<br />
          <br />
          Fingerprint Sony Bravia X4500-series TV<br />
          cpe:/h:sony:bravia_kdl-40x4500<br />
          <br />
          Fingerprint Sony CMT-MX700Ni audio player<br />
          cpe:/h:sony:cmy-mx700ni<br />
          <br />
          Fingerprint Sony SMP-N200 media player<br />
          cpe:/o:sony:smp-n200<br />
          <br />
          Fingerprint Sony FWD-40LX2F display card<br />
          cpe:/h:sony:fwd-40lx2f<br />
          <br />
          Fingerprint Sony PCS-TL30 video conferencing system<br />
          cpe:/h:sony:pcs-tl30<br />
          <br />
          Fingerprint Source Technologies ST-9650 printer<br />
          cpe:/h:sourcetechnologies:st-9650 auto<br />
          <br />
          Fingerprint Specialix JetStream 8500 RS232 remote access server<br />
          cpe:/h:specialix:jetstream_8500<br />
          <br />
          Fingerprint Sphairon Turbolink IAD DSL modem<br />
          cpe:/h:sphairon:turbolink_iad<br />
          <br />
          Fingerprint Star Micronics TSP100 receipt printer<br />
          cpe:/h:starmicronics:tsp100<br />
          <br />
          Fingerprint StorageTek SN3250 router<br />
          cpe:/h:storagetek:sn3250 auto<br />
          <br />
          Fingerprint Stratus ftServer Virtual Technician Module<br />
          cpe:/h:stratus:ftserver_virtual_technician_module<br />
          <br />
          Fingerprint Sun Integrated Lights-Out Manager<br />
          cpe:/a:sun:embedded_lights_out_manager<br />
          <br />
          Fingerprint Sun Storage 7210 NAS device<br />
          cpe:/h:sun:storage_7210 auto<br />
          <br />
          Fingerprint Sun Storage 7410 NAS device<br />
          cpe:/h:sun:storage_7410 auto<br />
          <br />
          Fingerprint Sun StorEdge 3310 FC storage array<br />
          cpe:/h:sun:storedge_3310_fc_array<br />
          <br />
          Fingerprint Sun StorEdge 3510 FC storage array<br />
          cpe:/h:sun:storedge_3510_fc_array<br />
          <br />
          Fingerprint Sun OpenSolaris 2008.11<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun OpenSolaris 2008.11<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun OpenSolaris 2008.11<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun OpenSolaris 2009.06<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun OpenSolaris 2009.06<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun OpenSolaris snv_129<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10 (SPARC)<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10 (SPARC)<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10 (SPARC)<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10 (SPARC)<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10 (SPARC)<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 10 (x86)<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 11<br />
          cpe:/o:sun:sunos:5.11 auto<br />
          <br />
          Fingerprint Sun Solaris 11<br />
          cpe:/o:sun:sunos:5.11 auto<br />
          <br />
          Fingerprint Sun Solaris 11<br />
          cpe:/o:sun:sunos:5.11 auto<br />
          <br />
          Fingerprint Sun Solaris 11<br />
          cpe:/o:sun:sunos:5.11 auto<br />
          <br />
          Fingerprint Sun Solaris 11 (snv_151a) or OpenIndiana oi_147<br />
          cpe:/o:sun:sunos:5.11 auto<br />
          cpe:/o:illumos:openindiana auto<br />
          <br />
          Fingerprint Sun Solaris 11 (snv_151a) or OpenIndiana oi_147 -
          oi_151a<br />
          cpe:/o:sun:sunos:5.11 auto<br />
          cpe:/o:illumos:openindiana auto<br />
          <br />
          Fingerprint Sun Solaris 11.3<br />
          cpe:/o:sun:sunos:11.3 auto<br />
          <br />
          Fingerprint Sun Solaris 2.5.1 (SPARC)<br />
          cpe:/o:sun:sunos:5.5.1 auto<br />
          <br />
          Fingerprint Sun Solaris 2.5.1 (SPARC)<br />
          cpe:/o:sun:sunos:5.5.1 auto<br />
          <br />
          Fingerprint Sun Solaris 2.6<br />
          cpe:/o:sun:sunos:5.6 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 7<br />
          cpe:/o:sun:sunos:5.7 auto<br />
          <br />
          Fingerprint Sun Solaris 7 (SPARC)<br />
          cpe:/o:sun:sunos:5.7 auto<br />
          <br />
          Fingerprint Sun Solaris 7 (SPARC)<br />
          cpe:/o:sun:sunos:5.7 auto<br />
          <br />
          Fingerprint Sun Solaris 7 (SPARC)<br />
          cpe:/o:sun:sunos:5.7 auto<br />
          <br />
          Fingerprint Sun Solaris 8<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (SPARC)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 8 (x86)<br />
          cpe:/o:sun:sunos:5.8 auto<br />
          <br />
          Fingerprint Sun Solaris 9<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          <br />
          Fingerprint Sun Solaris 9<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          <br />
          Fingerprint Sun Solaris 9<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          <br />
          Fingerprint Sun Solaris 9 (SPARC)<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          <br />
          Fingerprint Sun Solaris 9 (x86)<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          <br />
          Fingerprint Sun Solaris 9 or 10<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          <br />
          Fingerprint Sun Solaris 9 or 10<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 9 or 10 (SPARC)<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          <br />
          Fingerprint Sun Solaris 9 or 10, or OpenSolaris 2009.06 snv_111b<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          cpe:/o:sun:sunos:5.10 auto<br />
          cpe:/o:sun:opensolaris auto<br />
          <br />
          Fingerprint Sun Solaris 9 or Oracle Solaris 10<br />
          cpe:/o:sun:sunos:5.9 auto<br />
          cpe:/o:oracle:solaris:10 auto<br />
          <br />
          Fingerprint Sun Solaris 10<br />
          cpe:/o:sun:sunos:10 auto<br />
          <br />
          Fingerprint Sun SunOS 4.1.3 (SPARC)<br />
          cpe:/o:sun:sunos:4.1.3 auto<br />
          <br />
          Fingerprint SunPower solar monitoring device (uIP stack)<br />
          cpe:/a:rise_sics:uip<br />
          <br />
          Fingerprint Syllable Desktop 0.6.7<br />
          cpe:/o:syllable:desktop:0.6.7 auto<br />
          <br />
          Fingerprint Symantec Firewall/VPN 100 or 200<br />
          cpe:/h:symantec:firewall_vpn_appliance_100<br />
          cpe:/h:symantec:firewall_vpn_appliance_200<br />
          <br />
          Fingerprint Symantec Gateway Security 5620 firewall<br />
          cpe:/h:symantec:gateway_security_5620<br />
          <br />
          Fingerprint Symantec VelociRaptor 1.5 firewall<br />
          cpe:/h:symantec:velociraptor_1.5<br />
          cpe:/a:symantec:velociraptor<br />
          <br />
          Fingerprint Symbian^3<br />
          cpe:/o:symbianos:symbian_os:3 auto<br />
          <br />
          Fingerprint Symbol AP-2411 switch<br />
          cpe:/h:symbol:ap-2411 auto<br />
          <br />
          Fingerprint Symbol AP-3021 switch<br />
          cpe:/h:symbol:ap-3021 auto<br />
          <br />
          Fingerprint Symbol ES3000 switch<br />
          cpe:/h:symbol:es3000 auto<br />
          <br />
          Fingerprint Symbol Spectrum24 4131 WAP<br />
          cpe:/h:symbol:spectrum24_4131 auto<br />
          <br />
          Fingerprint Symbol WS500 wireless switch<br />
          cpe:/h:symbol:ws500 auto<br />
          <br />
          Fingerprint Symbol WS5000 wireless switch<br />
          cpe:/h:symbol:ws5000 auto<br />
          <br />
          Fingerprint Symbol WS5000 wireless switch<br />
          cpe:/h:symbol:ws5000 auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 3.2 or Priva heating control
          manager (Linux 2.6.32)<br />
          cpe:/a:synology:diskstation_manager:3.2 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 5 (Linux)<br />
          cpe:/a:synology:diskstation_manager:5 auto<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.2-5644<br />
          cpe:/a:synology:diskstation_manager:5.2 auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 5.2-5644<br />
          cpe:/a:synology:diskstation_manager:5.2 auto<br />
          <br />
          Fingerprint Synology DiskStation Manager 6 (Linux 2.6.32)<br />
          cpe:/a:synology:diskstation_manager:6 auto<br />
          cpe:/o:linux:linux_kernel:2.6.32 auto<br />
          <br />
          Fingerprint Synology RT1900ac router<br />
          cpe:/h:synology:rt1900ac auto<br />
          <br />
          Fingerprint T-Home Speedport W 504V WAP<br />
          cpe:/h:t-home:speedport_w_504v<br />
          <br />
          Fingerprint T-Home Speedport W 504V WAP<br />
          cpe:/h:t-home:speedport_w_504v<br />
          <br />
          Fingerprint T-Home Speedport W 700V WAP<br />
          cpe:/h:t-home:speedport_w_700v<br />
          <br />
          Fingerprint T-Home Speedport W 700V WAP<br />
          cpe:/h:t-home:speedport_w_700v<br />
          <br />
          Fingerprint T-Home Speedport W 700V WAP<br />
          cpe:/h:t-home:speedport_w_700v<br />
          <br />
          Fingerprint T-Home Speedport W 723V WAP<br />
          cpe:/h:t-home:speedport_w_723v<br />
          <br />
          Fingerprint T-Home Speedport W 723V WAP<br />
          cpe:/h:t-home:speedport_w_723v<br />
          <br />
          Fingerprint T-Marc 254H switch<br />
          cpe:/h:t-marc:254h auto<br />
          <br />
          Fingerprint Tadiran FlexSet-IP 280S VoIP phone<br />
          cpe:/h:tadiran:flexset-ip_280s<br />
          <br />
          Fingerprint Tahoe 8216 power management system<br />
          cpe:/h:tahoe:8216<br />
          <br />
          Fingerprint Tandberg TT 1122 video receiver<br />
          cpe:/h:tandberg:tt_1122<br />
          <br />
          Fingerprint Tandberg Codec C60 video conferencing system<br />
          cpe:/h:tandberg:codec_c60<br />
          <br />
          Fingerprint Tandberg VCS video conferencing system<br />
          cpe:/h:tandberg:vcs<br />
          <br />
          Fingerprint Tandberg video conferencing system (1000, 2500, or 990
          MXP)<br />
          cpe:/h:tandberg:1000<br />
          cpe:/h:tandberg:2500<br />
          cpe:/h:tandberg:990_mxp<br />
          <br />
          Fingerprint Tandberg Codian videoconferencing system (NetBSD)<br />
          cpe:/o:tandberg:netbsd auto<br />
          <br />
          Fingerprint Tandem Himalaya K2000 server (Guardian 90)<br />
          cpe:/o:tandem:guardian auto<br />
          <br />
          Fingerprint TechniSat Digicorder HD S2 satellite receiver<br />
          cpe:/h:technisat:digicorder_hd_s2<br />
          <br />
          Fingerprint Tektronix Phaser 8200DP printer<br />
          cpe:/h:tektronix:phaser_8200dp<br />
          <br />
          Fingerprint Telco Systems T-Marc 250<br />
          cpe:/h:telco_systems:t-marc_250<br />
          <br />
          Fingerprint Telewell TW-EA501 ADSL modem<br />
          cpe:/h:telewell:tw-ea501 auto<br />
          <br />
          Fingerprint Telex IP-223 radio IP adapter<br />
          cpe:/h:telex:ip-223<br />
          <br />
          Fingerprint Telsey IP Video Station media server<br />
          cpe:/h:telsey:ip_video_station<br />
          <br />
          Fingerprint Teltronics NET-PATH intrusion detection system<br />
          cpe:/h:teltronics:net-path<br />
          <br />
          Fingerprint TenAsys iRMX III 2.2<br />
          cpe:/o:tenasys:irmx:2 auto<br />
          <br />
          Fingerprint Tenda N301 WAP<br />
          cpe:/h:tenda:n301 auto<br />
          <br />
          Fingerprint Teradici 10ZiG V1200 thin client<br />
          cpe:/h:teradici:10zig_v1200 auto<br />
          <br />
          Fingerprint Thales nCipher NetHSM 500 hardware security module<br />
          cpe:/h:thales:ncipher_nethsm_500<br />
          <br />
          Fingerprint Thecus 4200 or N5500 NAS device (Linux 2.6.33)<br />
          cpe:/h:thecus:4200<br />
          cpe:/h:thecus:n5500<br />
          <br />
          Fingerprint Thecus N4100PRO NAS device<br />
          cpe:/h:thecus:n4100pro<br />
          <br />
          Fingerprint Thecus N5200PRO NAS device<br />
          cpe:/h:thecus:n5200pro auto<br />
          <br />
          Fingerprint Thecus N8800PRO NAS device<br />
          cpe:/h:thecus:n8800pro auto<br />
          <br />
          Fingerprint Thomson SpeedTouch 510 DSL modem<br />
          cpe:/h:thomson:speedtouch_510 auto<br />
          <br />
          Fingerprint Thomson SpeedTouch 510 DSL modem<br />
          cpe:/h:thomson:speedtouch_510 auto<br />
          <br />
          Fingerprint Thomson SpeedTouch 510/510i/530/570/580 DSL modem<br />
          cpe:/h:thomson:speedtouch_510<br />
          cpe:/h:thomson:speedtouch_510i<br />
          cpe:/h:thomson:speedtouch_530<br />
          cpe:/h:thomson:speedtouch_570<br />
          cpe:/h:thomson:speedtouch_580<br />
          <br />
          Fingerprint Thomson ST 585 or ST 536i ADSL modem<br />
          cpe:/h:thomson:st_585<br />
          cpe:/h:thomson:st_536i<br />
          <br />
          Fingerprint Thomson TCM390 cable modem<br />
          cpe:/h:thomson:tcm390 auto<br />
          <br />
          Fingerprint Thomson TCW710 wireless cable modem<br />
          cpe:/h:thomson:tcw710 auto<br />
          <br />
          Fingerprint Thomson TG712 DSL router<br />
          cpe:/h:thomson:tg712 auto<br />
          <br />
          Fingerprint Thomson ST 2030 VoIP phone<br />
          cpe:/h:thomson:st_2030 auto<br />
          <br />
          Fingerprint Tizen<br />
          cpe:/o:tizen:tizen auto<br />
          <br />
          Fingerprint Topfield TF6000PVR set top box<br />
          cpe:/h:topfield:tf6000pvr<br />
          <br />
          Fingerprint Toptech TMS5 terminal management<br />
          cpe:/h:toptech:tms5<br />
          <br />
          Fingerprint Toshiba 4690 OS point-of-sale system<br />
          cpe:/o:toshiba:4690_operating_system:6<br />
          <br />
          Fingerprint Toshiba REGZA TV<br />
          cpe:/h:toshiba:regza<br />
          <br />
          Fingerprint Toshiba e-STUDIO 20 printer<br />
          cpe:/h:toshibatec:e-studio-20<br />
          <br />
          Fingerprint Toshiba e-STUDIO 280 printer<br />
          cpe:/h:toshibatec:e-studio-280<br />
          <br />
          Fingerprint Toshiba e-STUDIO 4511 printer<br />
          cpe:/h:toshibatec:e-studio-4511<br />
          <br />
          Fingerprint TP-LINK TL-R4239G router<br />
          cpe:/h:tp-link:tl-r4239g auto<br />
          <br />
          Fingerprint TP-LINK TL-R470T router<br />
          cpe:/h:tp-link:tl-r470t auto<br />
          <br />
          Fingerprint TP-LINK TL-R478+ router<br />
          cpe:/h:tp-link:tl-r478+<br />
          <br />
          Fingerprint TP-LINK TL-R480T+<br />
          cpe:/h:tp-link:tl-r480t+<br />
          <br />
          Fingerprint TP-LINK PS110U print server<br />
          cpe:/h:tp-link:ps110u auto<br />
          <br />
          Fingerprint TP-LINK TL-PS110U print server<br />
          cpe:/h:tp-link:tl-ps110u auto<br />
          <br />
          Fingerprint TP-LINK TL-PS110U print server<br />
          cpe:/h:tp-link:tl-ps110u auto<br />
          <br />
          Fingerprint TP-LINK TL-WPS510U print server<br />
          cpe:/h:tp-link:tl-wps510u auto<br />
          <br />
          Fingerprint TP-LINK EAP330 WAP (Linux 2.6.36)<br />
          cpe:/h:tp-link:eap330 auto<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          <br />
          Fingerprint TP-LINK TD-W8951ND wireless ADSL modem<br />
          cpe:/h:tp-link:td-w8951nd auto<br />
          <br />
          Fingerprint TP-LINK TL-WA5210G WAP<br />
          cpe:/h:tp-link:tl-wa5210g auto<br />
          <br />
          Fingerprint TP-LINK TL-WA801ND WAP (Linux 2.6.36)<br />
          cpe:/h:tp-link:tl-wa801nd auto<br />
          cpe:/o:linux:linux_kernel:2.6.36 auto<br />
          <br />
          Fingerprint Trane Tracer SC building controller<br />
          cpe:/h:trane:tracer_sc<br />
          <br />
          Fingerprint TransAct Ithaca 280 thermal printer<br />
          cpe:/h:transact:ithaca_280<br />
          <br />
          Fingerprint TRENDnet TW100-BRF114 broadband router<br />
          cpe:/h:trendnet:tw100-brf114 auto<br />
          <br />
          Fingerprint TRENDnet TV-IP100 webcam<br />
          cpe:/h:trendnet:tv-ip100 auto<br />
          <br />
          Fingerprint Tripp Lite SMART1500SLT UPS, or PDUMV30HVNET or PDUMH15AT
          power distribution unit<br />
          cpe:/h:tripplite:smart1500slt<br />
          cpe:/h:tripplite:pdumv30hvnet<br />
          <br />
          Fingerprint Tripp Lite SMART3000RMXL2U UPS<br />
          cpe:/h:tripplite:smart3000rmxl2u<br />
          <br />
          Fingerprint Tripp Lite NetOS 7.5.2tl<br />
          cpe:/o:tripplite:netos:7.5.2tl auto<br />
          <br />
          Fingerprint Tut Systems SMS2000 subscriber management system<br />
          cpe:/h:tutsystems:sms2000<br />
          <br />
          Fingerprint Ubee EVW3226 cable modem<br />
          cpe:/h:ubee:evw3226 auto<br />
          <br />
          Fingerprint Ubee EVW3226 or Arris TG1672 or TG862G cable modem (Linux
          2.6.18)<br />
          cpe:/h:ubee:evw3226 auto<br />
          cpe:/h:arris:tg1672<br />
          cpe:/h:arris:tg862g<br />
          cpe:/o:linux:linux_kernel:2.6.18<br />
          <br />
          Fingerprint Ubicom ipOS<br />
          cpe:/o:ubicom:ipos auto<br />
          <br />
          Fingerprint Ubiquiti AirOS 5.5.9<br />
          cpe:/o:ubnt:airos:5.5.9 auto<br />
          <br />
          Fingerprint Ubiquiti AirOS 5.5.9<br />
          cpe:/o:ubnt:airos:5.5.9 auto<br />
          <br />
          Fingerprint Ubiquiti AirOS 5.6.2 (Linux 2.6.32)<br />
          cpe:/o:ubnt:airos:5.6.2<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Ubiquiti Pico Station WAP (AirOS 5.2.6)<br />
          cpe:/o:ubnt:airos:5.2.6 auto<br />
          <br />
          Fingerprint Ubiquiti Edgemax router firmware 1.7.0 (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Ubiquiti EdgeSwitch (Linux 3.6)<br />
          cpe:/h:ubnt:edgeswitch<br />
          cpe:/o:linux:linux_kernel:3.6 auto<br />
          <br />
          Fingerprint Ubiquiti UniFi AP-AC WAP (Linux 2.6.22)<br />
          cpe:/h:ubnt:uap-ac<br />
          cpe:/o:linux:linux_kernel:2.6.22 auto<br />
          <br />
          Fingerprint Ubiquiti webcam (Linux 2.6.38)<br />
          cpe:/o:linux:linux_kernel:2.6.38 auto<br />
          <br />
          Fingerprint Universal Devices ISY-99i IR receiver<br />
          cpe:/h:universaldevices:isy-99i<br />
          <br />
          Fingerprint USRobotics USR8000 broadband router<br />
          cpe:/h:usrobotics:usr8000 auto<br />
          <br />
          Fingerprint USRobotics USR8000 broadband router<br />
          cpe:/h:usrobotics:usr8000 auto<br />
          <br />
          Fingerprint USRobotics USR5450 WAP<br />
          cpe:/h:usrobotics:usr5450 auto<br />
          <br />
          Fingerprint UTStarcom WA3002G4 router<br />
          cpe:/h:utstarcom:wa3002g4 auto<br />
          <br />
          Fingerprint Vantage HD7100S satellite receiver<br />
          cpe:/h:vantage:hd7100s<br />
          <br />
          Fingerprint VBrick 4300 video encoder<br />
          cpe:/h:vbrick:4300<br />
          <br />
          Fingerprint Vegastream Vega 400 VoIP Gateway<br />
          cpe:/h:vegastream:vega_400 auto<br />
          <br />
          Fingerprint Viasat SurfBeam 2 satellite modem<br />
          cpe:/h:viasat:surfbeam_2<br />
          <br />
          Fingerprint Virdi 3000 fingerprint access controller<br />
          cpe:/h:virdi:3000<br />
          <br />
          Fingerprint Visual Network Systems Visual UpTime Select IP Transport
          DS3/E3 inline ASE<br />
          <br />
          Fingerprint Visual Network Systems Visual UpTime Select IP Transport
          NxT1 inline ASE<br />
          <br />
          Fingerprint Visual Network Systems Visual UpTime Select IP Transport
          T1 CSU DROP &amp; INSERT ASE<br />
          <br />
          Fingerprint VMware ESX Server 3.0.0 - 3.5.0<br />
          cpe:/o:vmware:esx:3<br />
          <br />
          Fingerprint VMware ESX Server 3.0.2<br />
          cpe:/o:vmware:esx:3.0:2<br />
          <br />
          Fingerprint VMware ESX Server 3.5.0<br />
          cpe:/o:vmware:esx:3.5<br />
          <br />
          Fingerprint VMware ESX Server 4.0.1<br />
          cpe:/o:vmware:esx:4.0:1<br />
          <br />
          Fingerprint VMware ESX Server 5<br />
          cpe:/o:vmware:esx:5<br />
          <br />
          Fingerprint VMware ESXi 3.0 - 4.0<br />
          cpe:/o:vmware:esxi:3<br />
          cpe:/o:vmware:esxi:4<br />
          <br />
          Fingerprint VMware ESXi 3.5<br />
          cpe:/o:vmware:esxi:3.5<br />
          <br />
          Fingerprint VMware ESXi 3.5<br />
          cpe:/o:vmware:esxi:3.5<br />
          <br />
          Fingerprint VMware ESXi 3.5<br />
          cpe:/o:vmware:esxi:3.5<br />
          <br />
          Fingerprint VMware ESXi 4.0<br />
          cpe:/o:vmware:esxi:4.0<br />
          <br />
          Fingerprint VMware ESXi 4.0<br />
          cpe:/o:vmware:esxi:4.0<br />
          <br />
          Fingerprint VMware ESXi 4.0<br />
          cpe:/o:vmware:esxi:4.0<br />
          <br />
          Fingerprint VMware ESXi 4.0.0<br />
          cpe:/o:vmware:esxi:4.0<br />
          <br />
          Fingerprint VMware ESXi 4.0.1<br />
          cpe:/o:vmware:esxi:4.0:1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1.0<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1.0<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1.0<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1.0<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1.0<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 4.1.0<br />
          cpe:/o:vmware:esxi:4.1<br />
          <br />
          Fingerprint VMware ESXi 5.0<br />
          cpe:/o:vmware:esxi:5.0<br />
          <br />
          Fingerprint VMware ESXi 5.0<br />
          cpe:/o:vmware:esxi:5.0<br />
          <br />
          Fingerprint VMware ESXi 5.0<br />
          cpe:/o:vmware:esxi:5.0 auto<br />
          <br />
          Fingerprint VMware ESXi 5.0 - 5.5<br />
          cpe:/o:vmware:esxi:5<br />
          <br />
          Fingerprint VMware ESXi 5.1<br />
          cpe:/o:vmware:esxi:5.1<br />
          <br />
          Fingerprint VMware ESXi 5.5<br />
          cpe:/o:vmware:esxi:5.5<br />
          <br />
          Fingerprint VMware ESXi 5.5<br />
          cpe:/o:vmware:esxi:5.5<br />
          <br />
          Fingerprint VMware ESXi 6.0.0<br />
          cpe:/o:vmware:esxi:6.0.0 auto<br />
          <br />
          Fingerprint VMware Player virtual NAT device<br />
          cpe:/a:vmware:player<br />
          <br />
          Fingerprint Vodafone EasyBox 802 wireless ADSL router<br />
          cpe:/h:vodafone:easybox_802 auto<br />
          <br />
          Fingerprint Vodafone Easybox 904 xDSL WAP (Linux 2.6.32)<br />
          cpe:/h:vodafone:easybox_904_xdsl<br />
          cpe:/o:linux:linux_kernel:2.6.32<br />
          <br />
          Fingerprint Vodafone EasyBox A601 wireless ADSL router<br />
          cpe:/h:vodafone:easybox_a601 auto<br />
          <br />
          Fingerprint Vodavi XTS-IP PBX<br />
          cpe:/h:vodavi:xts-ip<br />
          <br />
          Fingerprint Vonage V-Portal VoIP adapter<br />
          cpe:/h:vonage:v-portal<br />
          <br />
          Fingerprint W&amp;T Ethernet-to-serial bridge<br />
          cpe:/h:w%26t:com-server<br />
          <br />
          Fingerprint W&amp;T Web-IO Thermometer model 57101<br />
          cpe:/h:w%26t:web-thermometer<br />
          <br />
          Fingerprint W&amp;T Web-Thermo-Hygrobarograph firmware 1.59 - 1.71<br />
          cpe:/h:w%26t:web-thermo-hygrobarograph<br />
          <br />
          Fingerprint W&amp;T Web-Thermograph NTC<br />
          cpe:/h:w%26t:web-thermograph_ntc<br />
          <br />
          Fingerprint W&amp;T Web-Thermograph NTC firmware 1.53<br />
          cpe:/h:w%26t:web-thermograph_ntc<br />
          <br />
          Fingerprint W&amp;T Web-Thermograph NTC firmware 1.60<br />
          cpe:/h:w%26t:web-thermograph_ntc<br />
          <br />
          Fingerprint WAGO-I/O-SYSTEM 750 automation system<br />
          cpe:/h:wago:wago-i%2f-system_750<br />
          <br />
          Fingerprint WatchGuard FireBox 500 firewall<br />
          cpe:/h:watchguard:firebox_500<br />
          <br />
          Fingerprint WatchGuard FireBox 700 or X700 firewall<br />
          cpe:/h:watchguard:firebox_700<br />
          cpe:/h:watchguard:firebox_x700<br />
          <br />
          Fingerprint WatchGuard Firebox SOHO 6 or X5w firewall/WAP<br />
          cpe:/h:watchguard:firebox_x5w<br />
          cpe:/h:watchguard:firebox_soho_6<br />
          cpe:/h:watchguard:firebox_x5w<br />
          cpe:/h:watchguard:firebox_soho_6<br />
          <br />
          Fingerprint WatchGuard FireBox X1250e firewall<br />
          cpe:/h:watchguard:firebox_x1250e<br />
          <br />
          Fingerprint WatchGuard XTM 525 firewall<br />
          cpe:/h:watchguard:xtm_525 auto<br />
          <br />
          Fingerprint WatchGuard firewall (Fireware 11.3)<br />
          cpe:/o:watchguard:fireware:11.3 auto<br />
          <br />
          Fingerprint WatchGuard Fireware 11.8<br />
          cpe:/o:watchguard:fireware:11.8 auto<br />
          <br />
          Fingerprint WatchGuard firewall (Fireware XTM)<br />
          cpe:/o:watchguard:fireware:xtm auto<br />
          <br />
          Fingerprint Websense Content Gateway<br />
          cpe:/o:linux:linux_kernel auto<br />
          <br />
          Fingerprint Welltech ATA-171 VoIP adapter<br />
          cpe:/h:welltech:ata-171 auto<br />
          <br />
          Fingerprint Westell WireSpeed B90-220030-04 DSL router (VxWorks)<br />
          cpe:/h:westell:wirespeed_b90-220030-04<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Westell WireSpeed Dual Connect 6100 DSL router<br />
          cpe:/h:westell:wirespeed_6100<br />
          <br />
          Fingerprint Westermo EDW-100 Ethernet-to-serial bridge<br />
          cpe:/h:westermo:edw-100<br />
          <br />
          Fingerprint Western Digital WD TV media player<br />
          cpe:/o:westerndigital:wd_tv<br />
          cpe:/o:westerndigital:linux:2.6 auto<br />
          <br />
          Fingerprint Western Digital My Cloud DL4100 NAS (Linux 3.10)<br />
          cpe:/o:linux:linux_kernel:3.10 auto<br />
          <br />
          Fingerprint Wind River pSOSystem<br />
          cpe:/o:windriver:psosystem auto<br />
          <br />
          Fingerprint VxWorks 12.0<br />
          cpe:/o:windriver:vxworks:12.0 auto<br />
          <br />
          Fingerprint Nomadix AG 5800 access gateway (VxWorks)<br />
          cpe:/o:windriver:vxworks:12 auto<br />
          cpe:/h:nomadix:ag_5800<br />
          <br />
          Fingerprint Motorola SURFboard SB3100 cable modem (VxWorks 5.3)<br />
          cpe:/o:windriver:vxworks:5.3 auto<br />
          cpe:/h:motorola:surfboard_sb3100 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB3100 cable modem (VxWorks 5.3)<br />
          cpe:/o:windriver:vxworks:5.3 auto<br />
          cpe:/h:motorola:surfboard_sb3100 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB3100 cable modem (VxWorks 5.3)<br />
          cpe:/o:windriver:vxworks:5.3 auto<br />
          cpe:/h:motorola:surfboard_sb3100 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5100E cable modem (VxWorks 5.4)<br />
          cpe:/o:windriver:vxworks:5.4 auto<br />
          cpe:/h:motorola:surfboard_sb5100e auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5100E cable modem (VxWorks 5.4)<br />
          cpe:/o:windriver:vxworks:5.4 auto<br />
          cpe:/h:motorola:surfboard_sb5100e auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5120 cable modem (VxWorks 5.4)<br />
          cpe:/o:windriver:vxworks:5.4 auto<br />
          cpe:/h:motorola:surfboard_sb5120 auto<br />
          <br />
          Fingerprint Motorola SURFboard SB5120 cable modem (VxWorks 5.4)<br />
          cpe:/o:windriver:vxworks:5.4 auto<br />
          cpe:/h:motorola:surfboard_sb5120 auto<br />
          <br />
          Fingerprint Motorola SURFboard SBV5121 broadband router (VxWorks
          5.4)<br />
          cpe:/o:windriver:vxworks:5.4 auto<br />
          cpe:/h:motorola:surfboard_sbv5121 auto<br />
          <br />
          Fingerprint Nortel BayStack 460 switch, or Linksys BEFCMU10 or
          Motorola SURFboard SB5100 cable modem<br />
          cpe:/o:windriver:vxworks:5 auto<br />
          cpe:/h:linksys:befcmu10<br />
          cpe:/h:motorola:surfboard_sb5100 auto<br />
          cpe:/h:nortel:baystack_460 auto<br />
          <br />
          Fingerprint VxWorks 5.4.2<br />
          cpe:/o:windriver:vxworks:5.4.2<br />
          <br />
          Fingerprint VxWorks 5.5<br />
          cpe:/o:windriver:vxworks:5.5 auto<br />
          <br />
          Fingerprint VxWorks 5.5.1<br />
          cpe:/o:windriver:vxworks:5.5.1 auto<br />
          <br />
          Fingerprint VxWorks 5.5.1<br />
          cpe:/o:windriver:vxworks:5.5.1 auto<br />
          <br />
          Fingerprint Nortel Meridian PBX<br />
          cpe:/o:windriver:vxworks:5 auto<br />
          <br />
          Fingerprint Arris Cadant C3 cable modem terminator (VxWorks 5.4.2)<br />
          cpe:/o:windriver:vxworks:5.4.2 auto<br />
          cpe:/h:arris:cadant_c3 auto<br />
          <br />
          Fingerprint VxWorks 5.5<br />
          cpe:/o:windriver:vxworks:5.5 auto<br />
          <br />
          Fingerprint Cisco AP340 WAP (VxWorks 5.4)<br />
          cpe:/o:windriver:vxworks:5.4 auto<br />
          cpe:/h:cisco:ap340 auto<br />
          <br />
          Fingerprint Netgear WGR614v7, WGT624v3, or WPN824v2 WAP (VxWorks
          5.4.2)<br />
          cpe:/o:windriver:vxworks:5.4.2 auto<br />
          cpe:/h:netgear:wgr614<br />
          cpe:/h:netgear:wgt624<br />
          cpe:/h:netgear:wpn824<br />
          <br />
          Fingerprint VxWorks 6.4<br />
          cpe:/o:windriver:vxworks:6.4 auto<br />
          <br />
          Fingerprint VxWorks 6.6<br />
          cpe:/o:windriver:vxworks:6.6 auto<br />
          <br />
          Fingerprint VxWorks 6.8<br />
          cpe:/o:windriver:vxworks:6.8 auto<br />
          <br />
          Fingerprint VxWorks 6.5 (NAS device)<br />
          cpe:/o:windriver:vxworks:6.5 auto<br />
          <br />
          Fingerprint VxWorks 7<br />
          cpe:/o:windriver:vxworks:7 auto<br />
          <br />
          Fingerprint Broadband router (VxWorks): Dick Smith Electronics XH1169,
          Netgear WGR614v6, or Neuf Box Trio 3C<br />
          cpe:/o:windriver:vxworks auto<br />
          cpe:/h:dicksmithelectronics:xh1169<br />
          cpe:/h:netgear:wgr614<br />
          cpe:/h:neuf:neuf_box_trio_3c<br />
          <br />
          Fingerprint Huawei E303s-2 broadband router (VxWorks)<br />
          cpe:/o:windriver:vxworks auto<br />
          cpe:/h:huawei:e303s-2 auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint VxWorks: HP printer or Vocality BASICS Four Wire VoIP
          gateway<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint Brother printer (VxWorks)<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint HP LaserJet 3600 printer, HP ProCurve 2650 switch, or
          Motorola SURFboard SB5100E cable modem<br />
          cpe:/o:windriver:vxworks auto<br />
          cpe:/h:hp:laserjet_3600 auto<br />
          cpe:/h:hp:procurve_2650<br />
          cpe:/h:motorola:surfboard_sb5100e auto<br />
          <br />
          Fingerprint Dell PowerVault MD3200i SAN<br />
          cpe:/o:windriver:vxworks auto<br />
          <br />
          Fingerprint 3Com NBX 100 VoIP adapter (VxWorks)<br />
          cpe:/o:windriver:vxworks auto<br />
          cpe:/h:3com:nbx_100<br />
          <br />
          Fingerprint Netgear HE102 WAP (VxWorks)<br />
          cpe:/o:windriver:vxworks auto<br />
          cpe:/h:netgear:he102 auto<br />
          <br />
          Fingerprint Tenda W311R+ WAP (VxWorks)<br />
          cpe:/o:windriver:vxworks auto<br />
          cpe:/h:tenda:w311r%2b<br />
          <br />
          Fingerprint WIZnet W3150A TCP/IP chip<br />
          cpe:/h:wiznet:w3150a<br />
          <br />
          Fingerprint WIZnet W5100 TCP/IP chip<br />
          cpe:/h:wiznet:w5100<br />
          <br />
          Fingerprint WIZnet W5100 TCP/IP chip<br />
          cpe:/h:wiznet:w5100<br />
          <br />
          Fingerprint WIZnet W5100 TCP/IP chip<br />
          cpe:/h:wiznet:w5100<br />
          <br />
          Fingerprint WowWee Rovio mobile webcam<br />
          cpe:/h:wowwee:rovio<br />
          <br />
          Fingerprint WowWee Rovio mobile webcam<br />
          cpe:/h:wowwee:rovio<br />
          <br />
          Fingerprint Wyse V10L or 1200LE thin client<br />
          cpe:/h:wyse:v10l<br />
          cpe:/h:wyse:v1200le<br />
          <br />
          Fingerprint Wyse V10L thin client<br />
          cpe:/h:wyse:v10l<br />
          <br />
          Fingerprint Wyse ThinOS 5.2<br />
          cpe:/o:wyse:thinos:5.2 auto<br />
          <br />
          Fingerprint Wyse C10LE, S10, SX0, 1200LE, or Xenith terminal (ThinOS
          6.5)<br />
          cpe:/o:wyse:thinos:6.5 auto<br />
          <br />
          Fingerprint Wyse ThinOS 6<br />
          cpe:/o:wyse:thinos:6 auto<br />
          <br />
          Fingerprint Wyse ThinOS 7.1<br />
          cpe:/o:wyse:thinos:7.1 auto<br />
          <br />
          Fingerprint Wyse ThinOS 7.1<br />
          cpe:/o:wyse:thinos:7.1 auto<br />
          <br />
          Fingerprint Wyse ThinOS 8.1<br />
          cpe:/o:wyse:thinos:8.1 auto<br />
          <br />
          Fingerprint Wyse ThinOS PCoIP device<br />
          cpe:/o:wyse:thinos auto<br />
          <br />
          Fingerprint Wyse Cx0 terminal (ThinOS)<br />
          cpe:/o:wyse:thinos auto<br />
          <br />
          Fingerprint Wyse ThinOS<br />
          cpe:/o:wyse:thinos auto<br />
          <br />
          Fingerprint XAVi 7001 DSL modem<br />
          cpe:/h:xavi:7001 auto<br />
          <br />
          Fingerprint XAVi X7721r+ DSL router<br />
          cpe:/h:xavi:x7721r%2b auto<br />
          <br />
          Fingerprint XAVi X7868r wireless ADSL modem<br />
          cpe:/h:xavi:x7868r auto<br />
          <br />
          Fingerprint Xerox ApeosPort-IV C3370 printer<br />
          cpe:/h:xerox:apeosport-iv_c3370<br />
          <br />
          Fingerprint Xerox DC220 printer<br />
          cpe:/h:xerox:dc220 auto<br />
          <br />
          Fingerprint Xerox Document Centre 440 or WorkCentre Pro 55 printer<br />
          cpe:/h:xerox:document_centre_440<br />
          cpe:/h:xerox:wordcentre_pro_55<br />
          <br />
          Fingerprint Xerox Phaser 3150 printer<br />
          cpe:/h:xerox:phaser_3150 auto<br />
          <br />
          Fingerprint Xerox Phaser 3320 printer<br />
          cpe:/h:xerox:phaser_3320 auto<br />
          <br />
          Fingerprint Xerox Phaser 3435 printer<br />
          cpe:/h:xerox:phaser_3435 auto<br />
          <br />
          Fingerprint Xerox Phaser 6020V/BI printer<br />
          cpe:/h:xerox:phaser_6020v%2fbi<br />
          <br />
          Fingerprint Xerox Phaser 6180N printer<br />
          cpe:/h:xerox:phaser_6180n auto<br />
          <br />
          Fingerprint Xerox Phaser 6250DP printer<br />
          cpe:/h:xerox:phaser_6250dp auto<br />
          <br />
          Fingerprint Xerox Phaser 6600DN printer<br />
          cpe:/h:xerox:phaser_6600dn auto<br />
          <br />
          Fingerprint Xerox Phaser 860N or 8200N printer<br />
          cpe:/h:xerox:phaser_860n<br />
          cpe:/h:xerox:phaser_8200n<br />
          <br />
          Fingerprint Xerox WorkCentre 4150 printer<br />
          cpe:/h:xerox:workcentre_4150 auto<br />
          <br />
          Fingerprint Xerox WorkCentre 7545 printer<br />
          cpe:/h:xerox:workcentre_7545 auto<br />
          <br />
          Fingerprint Xerox WorkCentre Pro 7245 printer<br />
          cpe:/h:xerox:workcentre_pro_7245<br />
          <br />
          Fingerprint Xerox WorkCentre Pro C2128 printer<br />
          cpe:/h:xerox:workcentre_pro_c2128<br />
          <br />
          Fingerprint eComStation 2.2<br />
          cpe:/o:xeu.com:ecomstation:2.2 auto<br />
          <br />
          Fingerprint Xirrus XS8 WAP (Array OS 3.3 - 3.5)<br />
          cpe:/h:xirrus:xs8<br />
          <br />
          Fingerprint XMOS XC-2 development card<br />
          cpe:/h:xmos:xc-2<br />
          <br />
          Fingerprint Xylan OmniStack 4024CF switch<br />
          cpe:/h:xylan:omnistack_4024cf auto<br />
          <br />
          Fingerprint Xylan OmniStack switch (version 3.2.5)<br />
          cpe:/h:xylan:omnistack<br />
          <br />
          Fingerprint Xylan OmniStack switch (version 3.4.7)<br />
          cpe:/h:xylan:omnistack<br />
          <br />
          Fingerprint Xyplex terminal server<br />
          <br />
          Fingerprint Yamaha RX-A2040 AV receiver<br />
          cpe:/h:yamaha:rx-a2040<br />
          <br />
          Fingerprint Yamaha RX-V2065 audio/video receiver<br />
          cpe:/h:yamaha:rx-v2065<br />
          <br />
          Fingerprint Yamaha RX-V2067 or RX-V3900 audio receiver<br />
          cpe:/h:yamaha:rx-v2067<br />
          cpe:/h:yamaha:rx-v3900<br />
          <br />
          Fingerprint Yamaha RX-V481D AV receiver<br />
          cpe:/h:yamaha:rx-v481d<br />
          <br />
          Fingerprint Yamaha RX-V671 AV receiver<br />
          cpe:/h:yamaha:rx-v671<br />
          <br />
          Fingerprint Yamaha RX-V671 AV receiver<br />
          cpe:/h:yamaha:rx-v671<br />
          <br />
          Fingerprint Yamaha NetVolante RT57i router<br />
          cpe:/h:yamaha:netvolante_rt57i auto<br />
          <br />
          Fingerprint Yamaha RT58i or RT107e router<br />
          cpe:/h:yamaha:netvolante_rt58i auto<br />
          <br />
          Fingerprint Yealink SIP-T22P VoIP phone<br />
          cpe:/h:yealink:sip-t22p auto<br />
          <br />
          Fingerprint Zebra GK420t label printer<br />
          cpe:/h:zebra:gk420t<br />
          <br />
          Fingerprint Zebra GX430T label printer<br />
          cpe:/h:zebra:gx430t<br />
          <br />
          Fingerprint Zebra LP 2844-Z label printer<br />
          cpe:/h:zebra:lp_2844-z<br />
          <br />
          Fingerprint Zebra ZTC ZM400 label printer<br />
          cpe:/h:zebra:ztc_zm400<br />
          <br />
          Fingerprint Zelax MM-201R-UNI router<br />
          cpe:/h:zelax:mm-201r-uni auto<br />
          <br />
          Fingerprint Zhone 6211-I3 series ADSL2+ modem<br />
          cpe:/h:zhone:6211-i3<br />
          <br />
          Fingerprint ZoneAlarm Z100G WAP<br />
          cpe:/h:zonealarm:z100g auto<br />
          <br />
          Fingerprint ZTE ZXV10 H201 ADSL router<br />
          cpe:/h:zte:zxv10_h201<br />
          <br />
          Fingerprint Verizon Jetpack 4G hotspot<br />
          <br />
          Fingerprint Zyfer GSync 391 clock<br />
          cpe:/h:zyfer:gsync_391<br />
          <br />
          Fingerprint ZyXEL AES-100 ADSL modem<br />
          cpe:/h:zyxel:aes-100 auto<br />
          <br />
          Fingerprint ZyXEL o2 HomeBox 6641 router<br />
          cpe:/h:zyxel:o2_homebox_6641 auto<br />
          <br />
          Fingerprint ZyXEL P-2602H-D1A DSL modem<br />
          cpe:/h:zyxel:p-2602h-d1a auto<br />
          <br />
          Fingerprint ZyXEL Prestige 660R ADSL router<br />
          cpe:/h:zyxel:prestige_660r auto<br />
          <br />
          Fingerprint Zyxel Keenetic 4G router<br />
          cpe:/h:zyxel:keenetic_4g auto<br />
          <br />
          Fingerprint ZyXEL NSA-200 NAS device<br />
          cpe:/h:zyxel:nsa-200 auto<br />
          <br />
          Fingerprint ZyXEL NSA-210 NAS device<br />
          cpe:/h:zyxel:nsa-210 auto<br />
          <br />
          Fingerprint ZyXEL NSA320S NAS (Linux 2.6.31)<br />
          cpe:/h:zyxel:nsa320s<br />
          cpe:/o:linux:linux_kernel:2.6.31 auto<br />
          <br />
          Fingerprint ZyXEL ES-3024A switch<br />
          cpe:/h:zyxel:es-3024a auto<br />
          <br />
          Fingerprint ZyXEL GS1910 switch<br />
          cpe:/h:zyxel:gs1910 auto<br />
          <br />
          Fingerprint ZyXEL Keenetic Giga WAP 2.04 - 2.05<br />
          <br />
          Fingerprint ZyXEL NBG6817 WAP (Linux 3.4)<br />
          cpe:/h:zyxel:nbg6817 auto<br />
          cpe:/o:linux:linux_kernel:3.4 auto<br />
          <br />
          Fingerprint ZyWALL Unified Security Gateway (ZLD 3.30)<br />
          cpe:/o:zyxel:zld:3.30 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL USG 20 or USG 50 firewall (ZyNOS 2.21)<br />
          cpe:/o:zyxel:zynos:2.21 auto<br />
          <br />
          Fingerprint ZyXEL Prestige 202 ISDN router (ZyNOS 2.41)<br />
          cpe:/o:zyxel:zynos:2.41 auto<br />
          <br />
          Fingerprint ZyXEL G-2000 Plus WAP<br />
          cpe:/o:zyxel:zynos:3 auto<br />
          <br />
          Fingerprint ZyXEL P-2602HW-D1A wireless DSL modem (ZyNOS 3.40)<br />
          cpe:/o:zyxel:zynos:3.40 auto<br />
          <br />
          Fingerprint ZyXEL Prestige 200 ISDN router<br />
          cpe:/o:zyxel:zynos:3 auto<br />
          <br />
          Fingerprint ZyXEL Prestige 2602R-D1A ADSL router (ZyNOS 3.40)<br />
          cpe:/o:zyxel:zynos:3.40<br />
          cpe:/h:zyxel:prestige_2602r-d1a<br />
          <br />
          Fingerprint ZyXEL ZyNOS 3.40<br />
          cpe:/o:zyxel:zynos:3.40 auto<br />
          <br />
          Fingerprint ZyXEL ZyNOS 3.40<br />
          cpe:/o:zyxel:zynos:3.40 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL 10W firewall (ZyNOS 3.62)<br />
          cpe:/o:zyxel:zynos:3.62 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL 2 firewall or Prestige 660HW-61 ADSL router
          (ZyNOS 3.62)<br />
          cpe:/o:zyxel:zynos:3.62 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL 70 firewall (ZyNOS 3.65)<br />
          cpe:/o:zyxel:zynos:3.65 auto<br />
          <br />
          Fingerprint ZyXEL G-3000H WAP (ZyNOS 3.50)<br />
          cpe:/o:zyxel:zynos:3.50 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL 2, 5, or 70 firewall (ZyNOS 4.04)<br />
          cpe:/o:zyxel:zynos:4.04 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL 5 firewall (ZyNOS 4.04)<br />
          cpe:/o:zyxel:zynos:4.04 auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL firewall (ZyNOS 4.04)<br />
          cpe:/o:zyxel:zynos:4.04 auto<br />
          <br />
          Fingerprint ZyXEL Prestige 2602R-D1A ADSL router<br />
          cpe:/o:zyxel:zynos auto<br />
          <br />
          Fingerprint ZyXEL Prestige 600-series ADSL router<br />
          cpe:/o:zyxel:zynos auto<br />
          <br />
          Fingerprint ZyXEL Prestige 660HW-61 ADSL router (ZyNOS 3.40)<br />
          cpe:/o:zyxel:zynos auto<br />
          <br />
          Fingerprint ZyXEL Prestige 660HW-D1 wireless ADSL router<br />
          cpe:/o:zyxel:zynos auto<br />
          <br />
          Fingerprint ZyXEL ZyWALL 2 Plus firewall<br />
          cpe:/o:zyxel:zynos auto
        </div>
      </v-card-text>
    </div>

    <div v-show="this.index === '12_0'">
      <v-card-title class="comm_title1"
        >ANNEX. Third-Party Software and Licenses</v-card-title
      >
      <div></div>
      <table
          class="MsoNormalTable"
          border="0"
          cellspacing="0"
          cellpadding="0"
          width="73%"
          style="
            margin: 0 auto;
            margin-top: 20px;
            width: 73%;
            border-collapse: collapse;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 4.95pt 0cm 4.95pt;
            vertical-align: middle;
          "
        >
        <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:17.4pt'>

          <td width=255 style='width:191.15pt;border:solid windowtext 1.0pt;mso-border-alt:
          solid windowtext .5pt;background:#D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;
          height:17.4pt'>

          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Open Source Software Name</span><span lang=EN-US
          style='mso-bidi-font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "맑은 고딕";color:black;mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 style='width:6.0cm;border:solid windowtext 1.0pt;border-left:
          none;mso-border-top-alt:solid windowtext .5pt;mso-border-bottom-alt:solid windowtext .5pt;
          mso-border-right-alt:solid windowtext .5pt;background:#D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;
          height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:1;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache2</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>ASF</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:2;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>apexcharts</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:3;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>argparse</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:4;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>axios</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:5;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>babel/core</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:6;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>babel/preset-<span class=SpellE>env</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:7;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>babel/runtime</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:8;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>babel-<span class=SpellE>eslint</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:9;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>bindings</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:10;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>chart.js</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:11;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>chartjs</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-plugin-crosshair</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:12;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>chartjs</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-plugin-zoom</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:13;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>codecs</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:14;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>collections</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:15;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>configparser</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:16;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>copy</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:17;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>core-<span class=SpellE>js</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:18;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>datetime</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:19;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>dayjs</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:20;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>elasticsearch</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache Software License(Apache 2.0)</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:21;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>email</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:22;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>eslint</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:23;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>eslint</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-plugin-<span class=SpellE>vue</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:24;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>file-<span class=SpellE>uri</span>-to-path</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:25;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Firehol</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-aggregator</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache Software License(Apache 2.0)</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:26;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>functools</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:27;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>getmac</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:28;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>getopt</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:29;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>growthbunker</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>/<span class=SpellE>vueflags</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:30;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>hammerjs</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:31;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>hashlib</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:32;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>inotom</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/<span class=SpellE>vue</span>-go-top</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:33;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>io</span></span><span lang=EN-US style='font-size:11.0pt;
          mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";mso-hansi-font-family:
          "맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:34;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>ipaddr.js</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:35;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>ipaddress</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:36;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>ip-cidr</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:37;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>jquery</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:38;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>json</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:39;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Kibana</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'></td>
        </tr>
        <tr style='mso-yfti-irow:40;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>loader</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:41;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>lodash</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:42;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>log4p</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:43;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>logging</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:44;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Logstash</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache Software License(Apache 2.0)</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:45;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>math</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:46;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>modsecurity</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache License 2.0</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:47;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>moment</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:48;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>multiprocessing</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:49;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>mysql</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'></td>
        </tr>
        <tr style='mso-yfti-irow:50;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>nan</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:51;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>nmap</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>GNU GPL v2</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:52;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>node</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:53;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>node</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:54;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>NSE script</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'></td>
        </tr>
        <tr style='mso-yfti-irow:55;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>numpy</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:56;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>ol</span></span><span lang=EN-US style='font-size:11.0pt;
          mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";mso-hansi-font-family:
          "맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD 2-Clause</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:57;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>OpenSSL</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache Software License(Apache 2.0)</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:58;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>optparse</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:59;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>os</span></span><span lang=EN-US style='font-size:11.0pt;
          mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";mso-hansi-font-family:
          "맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:60;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>otplib</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:61;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>papaparse</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:62;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>php</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PHP License v3.01</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:63;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>php</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PHP License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:64;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>psutil</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:65;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>pymysql</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:66;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Python</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'></td>
        </tr>
        <tr style='mso-yfti-irow:67;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Python-Package</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'></td>
        </tr>
        <tr style='mso-yfti-irow:68;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>qrcode</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:69;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>queue</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:70;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>random</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:71;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>re</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:72;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>register-service-worker</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:73;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>requests</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache Software License(Apache 2.0)</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:74;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>sass</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:75;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>sass-loader</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:76;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>select</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:77;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>serve</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:78;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>shlex</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:79;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>shutil</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:80;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>smtplib</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:81;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>socket</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:82;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>socket</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:83;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>subprocess</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:84;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>supervisor</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:85;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>sys</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:86;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>threading</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:87;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>three-globe</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:88;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>time</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:89;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>traceback</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:90;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>twisted</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:91;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>uptime</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD License</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:92;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vgauge</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:93;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>v-tooltip</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:94;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:95;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/cli-plugin-babel</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:96;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/cli-plugin-<span class=SpellE>eslint</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:97;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/cli-plugin-<span class=SpellE>pwa</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:98;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/cli-plugin-router</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:99;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/cli-plugin-<span class=SpellE>vuex</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:100;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>/cli-service</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:101;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue-apexcharts</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:102;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-auto-logout</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:103;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue-axios</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:104;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue-chartjs</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:105;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-cli</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:106;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-cli-plugin-<span class=SpellE>vuetify</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:107;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-cookies</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:108;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-easy-<span class=SpellE>dnd</span></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:109;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vuelayers</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>BSD 3-Clause</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:110;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-moment</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:111;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-router</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:112;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue-scrollto</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:113;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-simple-alert</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:114;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vue</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-template-compiler</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:115;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vuetify</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:116;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vuetify</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-loader</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:117;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vuex</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:118;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vuex</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-persist</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:119;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>vuex</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-shared-mutations</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:120;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>watchdog</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>Apache Software License(Apache 2.0)</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:121;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>webpack</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:122;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>webpack</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'>-cli</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:123;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>xml</span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:124;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>xmltodict</span></span><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>MIT</span></p>
          </td>
        </tr>
        <tr style='mso-yfti-irow:125;mso-yfti-lastrow:yes;height:17.4pt'>
          <td width=255 nowrap style='width:191.15pt;border:solid windowtext 1.0pt;
          border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
          solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
          0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span class=SpellE><span
          lang=EN-US style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>zipfile</span></span><span lang=EN-US style='font-size:
          11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:"맑은 고딕";
          mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;mso-font-kerning:
          0pt'></span></p>
          </td>
          <td width=227 nowrap style='width:6.0cm;border-top:none;border-left:none;
          border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
          mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
          padding:0cm 4.95pt 0cm 4.95pt;height:17.4pt'>
          <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
          text-align:center;line-height:normal;mso-pagination:widow-orphan;text-autospace:
          ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
          style='font-size:11.0pt;mso-ascii-font-family:"맑은 고딕";mso-fareast-font-family:
          "맑은 고딕";mso-hansi-font-family:"맑은 고딕";mso-bidi-font-family:굴림;color:black;
          mso-font-kerning:0pt'>PSF license</span></p>
          </td>
        </tr>
        </table>

    </div>

    <!-- ANNEX. Product Model Line-Up -->
    <div v-show="this.index === '14_0'">
      <v-card-title class="comm_title1"
        >ANNEX. Product Model Line-Up
      </v-card-title>
      
      <v-card-text style="vertical-align: middle !important">
      <table
          class="MsoNormalTable"
          border="0"
          cellspacing="0"
          cellpadding="0"
          width="90%"
          style="
            margin: 14px auto;
            table-layout: fixed;
            width: 90%;
            border-collapse: collapse;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 4.95pt 0cm 4.95pt;
            vertical-align: middle;
          "
        >
          <thead>
            <tr
              style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 24.4pt;  "
            >
              <td
                style="
                  width: 20%;
                  border: solid windowtext 1pt;
                  mso-border-alt: solid windowtext 0.5pt;
                  background: #777777; 
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  vertical-align: middle;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #fff;
                      mso-font-kerning: 0pt;
                    "
                    >Model Name</span
                  >
                </p>
              </td>
              <td
                style="
                  width: 15%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  mso-border-top-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  background: #777777;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="left"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #fff;
                      mso-font-kerning: 0pt;
                    "
                    >Archi. Type</span
                  >
                </p>
              </td>
              <td
                style="
                  width: 20.0%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  mso-border-top-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  background: #777777;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #fff;
                      mso-font-kerning: 0pt;
                    "
                    >Ip Volume Type
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 25.0%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  mso-border-top-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  background: #777777;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #fff;
                      mso-font-kerning: 0pt;
                    "
                    >Standard Spec.
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 20.0%;
                  border: solid windowtext 1pt;
                  border-left: none;
                  mso-border-top-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  background: #777777;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 10pt;
                      font-family: 'Segoe UI', sans-serif;
                      color: #fff;
                      mso-font-kerning: 0pt;
                    "
                    >Suitable Users Types
                  </span>
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="mso-yfti-irow: 1; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    ONP_100M
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    On-Premise
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    > 100M IP Address
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: left;
                    margin-top: 2px;
                    line-height: normal;
                    margin-bottom: 2px;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    <span class="square-symbol">&#9632;</span>  Web Svr: 1(1) <br/>
                    <span class="square-symbol">&#9632;</span>  Storage Svr: 3(3) <br/>
                    <span class="square-symbol">&#9632;</span>  Scan Server: 5(5) <br/>
                    <span class="square-symbol">&#9632;</span>  Scan Client: 20 X 6 <br/>
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    Union, Continent
                    Organization
                  </span>
                </p>
              </td>
              
            </tr>

            <tr style="mso-yfti-irow: 2; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    ONP_300K
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    On-Premise
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    > 300K IP Address
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    <span class="square-symbol">&#9632;</span>  Web Svr: 1(1) <br/>
                    <span class="square-symbol">&#9632;</span>  Storage Svr: 2(2) <br/>
                    <span class="square-symbol">&#9632;</span>  Scan Server: 3(3) <br/>
                    <span class="square-symbol">&#9632;</span>  Scan Client: 10 X 2 <br/>
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    National CSIRT
                  </span>
                </p>
              </td>
              
            </tr>
            
            <tr style="mso-yfti-irow: 3; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    ONP_SOC100K
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    On-Premise
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    > 100K IP Address
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    <span class="square-symbol">&#9632;</span>  Web Svr: 1(1) <br/>
                    <span class="square-symbol">&#9632;</span>  Storage Svr: 2(2) <br/>
                    <span class="square-symbol">&#9632;</span>  Scan Server: 2(2) <br/>
                    <span class="square-symbol">&#9632;</span>  Scan Client: 5 X 2 <br/>
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    CSIRT, SOC
                  </span>
                </p>
              </td>
              
            </tr>

            <tr style="mso-yfti-irow: 4; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    ONP_GCM10C
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    On-Premise
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    &lt; 10 C Class networks
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                  <span class="square-symbol">&#9632;</span>  Web Svr: 1(1) <br/>
                  <span class="square-symbol">&#9632;</span> Storage Svr: 3(3) <br/>
                  <span class="square-symbol">&#9632;</span>  Scan Server: 3(3) <br/>
                  <span class="square-symbol">&#9632;</span>  Scan Client: 10 X 3 <br/>
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    Global Company
                  </span>
                </p>
              </td>
              
            </tr>

            <tr style="mso-yfti-irow: 5; height: 24.4pt">
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    ONP_CLD10C
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    Cloud
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    > 10 C Class networks
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: left;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    <span class="square-symbol">&#9632;</span>  Cloud Network
                  </span>
                </p>
              </td>
              <td
                style="
                  width: 10%;
                  border: solid windowtext 1pt;
                  border-top: none;
                  mso-border-left-alt: solid windowtext 0.5pt;
                  mso-border-bottom-alt: solid windowtext 0.5pt;
                  mso-border-right-alt: solid windowtext 0.5pt;
                  padding: 0cm 4.95pt 0cm 4.95pt;
                  height: 24.4pt;
                "
              >
                <p
                  class="MsoNormal"
                  align="center"
                  style="
                    text-align: center;
                    line-height: normal;
                    margin-bottom: 0;
                    mso-pagination: widow-orphan;
                    text-autospace: ideograph-numeric ideograph-other;
                    word-break: keep-all;
                  "
                >
                  <span
                    lang="EN-US"
                    style="
                      font-size: 11pt;
                      mso-bidi-font-family: 굴림;
                      color: black;
                      mso-font-kerning: 0pt;
                    "
                    >
                    Global Company
                  </span>
                </p>
              </td>
              
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </div>

  </div>
</template>

<script>
import help_explain from "@/components/help/help_explain.json";

export default {
  props: ["index", "examples"],
  data: function () {
    return {
      explain: help_explain.keyword
    };
  }
};

</script>

<style scoped>
@import "./css/help.css";

* {
  vertical-align: middle;
}
.comm_ul li{
  margin:20px auto;
}
.MsoTableGrid{
  margin: 0 13pt !important;
  width: 95% !important;
}
</style>
