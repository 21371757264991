<template>
    <div style="height:100%">
        <v-container fluid style="height:100%">
            <v-row style="height:47%">
                <v-col>
                    <scan-config></scan-config>
                </v-col>
            </v-row>
            <v-row style="height:55%">
                <v-col class="pt-0">
                    <v-card class="pa-0" style="height:100%">
                        <update-backup-config></update-backup-config>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ScanConfig from '../components/configuration/ScanConfig.vue'
import UpdateBackupConfig from '../components/configuration/UpdateBackupConfig.vue'
export default {
    components: {
        ScanConfig,
        UpdateBackupConfig
    },
    mounted(){
        this.$store.commit('closeContextMenu');
    }
}
</script>ScanConfig