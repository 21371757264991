<template>
    <div class=" my-xl-3 text-center d-inline-block">
        <div class="font-weight-bold text-uppercase text-left ml-xl-8 ml-md-4">{{title}}</div>
        <pie-chart v-if="chartdata.labels.length > 0" :chartdata="chartdata" :options="chartoptions" :height="chartHeight" :width="chartWidth"></pie-chart>

    </div>
</template>

<script>
import PieChart from "@/components/charts/Pie.vue"
export default {
    name: `HomePie`,
    components: {
        PieChart
    },
    data () {
        return {

        }
    },
    props: {
        title: String,
        chartdata: Object,
        chartoptions: Object,
        chartHeight: Number,
        chartWidth: Number
    }
}
</script>