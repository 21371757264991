<template>
    <div class="pa-4" style="width: 100%;">
        <div class="infoV">
            <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6; margin-bottom: 0px !important;" class="mt-2 mb-2" > 
                Traceroute Information
            </div>
            <div class="pa-3" style="width: 100%; display:grid;">
                <div style="width: 100%;">
                    <div class="infoIndex">Scanner IP:</div>
                    <div class="infoValue">{{ convertIp(selectedTraceObj.scannerHostname[0]) }}</div>
                </div>
                <div style="width: 100%;">
                    <div class="infoIndex">Scan Time:</div>
                    <div class="infoValue">{{ convertTime(selectedTraceObj.starttime) }} ~ {{ convertTime(selectedTraceObj.endtime) }}</div>
                </div>
                <div style="width: 100%;">
                    <div class="infoIndex">Using Port:</div>
                    <div class="infoValue">{{ selectedTraceObj.proto }}/{{ selectedTraceObj.port }}</div>
                </div>
            </div>
        </div>
        <!-- <div 
            style="background-color:blue; width: 100%; height: 100%; z-index: 200; position: absolute; top: 0px; left: 0px;"
            v-if="!hover && userAgent=='mobile'"
        ></div> -->
        <div class="mapV">
            <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" > 
                Traceroute Map
            </div>
            <v-card id="map" @mouseover="click=true" @mouseout="click=false">
                <div style="position: relative;" @wheel="changeClick" @click="changeClick" @touchstart="changeHover" @touchend="preventMove">
                    <v-fade-transition>
                        <v-alert
                        dense
                        type="warning"
                        style="position: absolute; left:0px; top:0px; z-index: 200; width: 100%; background-color: rgba(255,152,0,0.9) !important; "
                        v-if="!hover && userAgent=='mobile'"
                        >
                        use TWO FINGERS to move map
                        </v-alert>
                        <v-alert
                        dense
                        type="warning"
                        style="position: absolute; left:0px; top:0px; z-index: 200; width: 100%; background-color: rgba(255,152,0,0.9) !important; "
                        v-if="!click && userAgent=='pc'"
                        >
                        "Ctrl + Left Click" to move map
                        </v-alert>
                    </v-fade-transition>
                    <vl-map
                        data-projection="EPSG:4326"
                        :load-tiles-while-animating="true"
                        :load-tiles-while-interacting="true"
                        style="height: 400px;"
                        ref="map"
                    >
                        <vl-view 
                        :zoom.sync="zoom" 
                        :center.sync="center" 
                        :min-zoom="minZoom"
                        :max-zoom="maxZoom"
                        :rotation.sync="rotation"
                        ></vl-view>
                        <vl-layer-tile>
                            <vl-source-xyz :url="url"> </vl-source-xyz>
                        </vl-layer-tile>
    
                        <vl-feature>
                            <vl-geom-multi-point :coordinates=traceLanLon.coordinates></vl-geom-multi-point>
                            <vl-style-box>
                                <vl-style-icon
                                src="/img/marker.png"
                                :scale="0.12"
                                :anchor="[0.5, 1]"
                                ></vl-style-icon>
                            </vl-style-box>
                        </vl-feature>
    
                        <vl-feature v-for="(coordinate, num) in traceLanLon.coordinates" :key="'b'+num">
                            <vl-geom-point :coordinates=coordinate></vl-geom-point>
                            
                            <vl-style-box>
                            <vl-style-text
                            :scale="1.2"
                            :offset-x="50"
                            :offset-y="-15"
                            :text="traceLanLon.ip[num]"
                            ></vl-style-text>
                            </vl-style-box>
                        </vl-feature>
                    
                        <vl-feature v-for="(coordinate, num) in traceLanLon.coordinates" :key="'a'+num">
                            <vl-geom-point :coordinates=coordinate></vl-geom-point>
                            
                            <vl-style-box>
                                <vl-style-circle :radius="7">
                                    <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
                                    <vl-style-stroke :color=traceLanLon.colors[num]></vl-style-stroke>
                                </vl-style-circle>
                            </vl-style-box>
                        </vl-feature>
                    
                        <vl-feature v-for="(coordinate, index) in lineLanLon.coordinates" :key="index">
                            <vl-geom-line-string :coordinates=coordinate></vl-geom-line-string>
                            <vl-style-box>
                                <vl-style-stroke :color="lineLanLon.colors[index]" :width="3"></vl-style-stroke>
                            </vl-style-box>
                        </vl-feature>
                    </vl-map>
                    <!-- <v-fade-transition>
                        <v-overlay
                            v-if="click && userAgent=='pc'"
                            absolute
                            color="rgba(0,0,0,0.5)"
                        >
                            <v-btn>"Ctrl + Left Click" to move map</v-btn>
                        </v-overlay> -->
                        <!-- <v-overlay
                            v-if="!hover && !firstLogin && userAgent=='mobile'"
                            absolute
                            color="rgba(0,0,0,0.5)"
                        >
                            <v-btn @click="hover=true">Use "Two Fingers" to move map</v-btn>
                        </v-overlay>  -->
    
                    <!-- </v-fade-transition> -->
                </div>
            </v-card>        
        </div>
        <div class="graphV" style="height: 430px;">
            <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" > 
                Traceroute Graph 
            </div>
            <v-card @mouseover="click1=true" @mouseout="click1=false" @click="changeClick1" @wheel="changeClick1">
                <div style="position: relative; height: 400px">
                    <v-fade-transition>
                        <v-alert
                        dense
                        type="warning"
                        style="position: absolute; top: 0px; left: 0px; z-index: 200; width: 100%; background-color: rgba(255,152,0,0.9) !important;"
                        v-if="!hover1 && userAgent=='mobile'"
                        >
                        use TWO FINGERS to move map
                        </v-alert>
                        <v-alert
                        dense
                        type="warning"
                        style="position: absolute; left:0px; top:0px; z-index: 200; width: 100%; background-color: rgba(255,152,0,0.9) !important; "
                        v-if="!click1 && userAgent=='pc'"
                        >
                        "Ctrl + Left Click" to move map
                        </v-alert>
                    </v-fade-transition>
                    <div id="visWrap" @touchstart="changeHover1" style="position: absolute; top: 0px; left: 0px; height: 400px;">
                    </div>
                    <!-- <v-fade-transition>
                        <v-overlay
                            v-if="!hover1 && firstLogin && userAgent=='pc'"
                            absolute
                            color="rgba(0,0,0,0.5)"
                        >
                            <v-btn>"Ctrl + Left Click" to move map</v-btn>
                        </v-overlay> -->
                        <!-- <v-overlay
                                v-if="!hover1 && !firstLogin && userAgent=='mobile'"
                                absolute
                                color="rgba(0,0,0,0.5)"
                            >
                                <v-btn @click="hover1=true">Use "Two Fingers" to move map</v-btn>
                            </v-overlay>  -->
                        <!-- <v-overlay
                            v-if="!hover1 && !firstLogin && userAgent=='mobile'"
                            absolute
                            color="rgba(0,0,0,0.5)"
                        >
                            <v-btn  @click="hover1=true">Use "Two Fingers" to move map</v-btn>
                        </v-overlay> -->
                    <!-- </v-fade-transition> -->
                </div>
            </v-card>
        </div>
        <div class="tableV" style="width: 100%;">
            <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" > 
                Traceroute Table 
            </div>
            <div class="tableWrap" style="width:100%;" >
                    <v-simple-table
                        height="250px"
                        style="z-index: 1;"
                        fixed-header
                        
                    >
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-center">IP</th>
                                <th class="text-center">TTL</th>
                                <th class="text-center">RTT</th>
                                <th class="text-center">City</th>
                                <th class="text-center">Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(route,index) in traceArr" :key="index">
                                <td class="text-center">{{ (index+1) }}</td>
                                <td class="text-center">{{ route.ip==null?'private':route.ip }}</td>
                                <td class="text-center">{{route.ttl}}</td>
                                <td class="text-center">{{route.rtt}}</td>
                                <td class="text-center">{{ route.city==null?'-':route.city}}</td>
                                <td class="text-center"> {{ route.country==null?'-':route.country }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript" src="@/assets/traceRoute/vis.min.js"></script>
<link rel="stylesheet" href="@/assets/traceRoute/vis.min.css" type="text/css"/>

<script>
import { globals} from "@/mixins/commons.js";
import vis from "vis";
import axios from "axios";
import { PTestConn } from "@/mixins/commons";
import { index } from '@/mixins/elastic';

import {DragPan, MouseWheelZoom, defaults} from 'ol/interaction';
import { platformModifierKeyOnly, touchOnly } from 'ol/events/condition';


export default {
    data(){
        return{
            userAgent:'pc',
            click:false,
            click1:false,
            hover:false,
            hover1:false,
            interactionArr:[],
            // touchStartX:0,
            // touchStartY:0,
            // container:null,
            // network:null,
            traceArr:[],
            zoom: 1.4,
            url: globals.mapTile,
            maxZoom: 12,
            minZoom: 1,
            center:[0,0],
            rotation: 0,
            traceLanLon:{
                coordinates: [],
                colors:[],
                ip:[]
            },
            deleteNullArr:[],
            lineLanLon:{
                coordinates:[],
                colors:[]
            },
            colorArr:[],
            nodes:[],
            edges:[],
            options:{
                layout: {
                    randomSeed:218,    
                    improvedLayout: true,
                },
                physics: {
                    enabled: false,
                },
                nodes:{
                    shape: "dot",
                    size:30,
                    font:{size:45, color: "rgba(0,0,0,1)"},
                    borderWidth: -5,
                    scaling:{
                        label:{
                            enabled:true,
                            maxVisible:30,
                            drawThreshold:5
                        }
                    }
                },
                edges:{
                    width:2,
                    color:{
                        color: "rgba(1,1,1,1)",
                        highlight: "#c60215"
                    },
                },
                interaction:{
                    dragView: false,
                    zoomView: false
                }
            }
        }
    },
    props:{
        selectedTraceObj: Object
    },
    created(){
        //접근 기기 구분
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.userAgent='mobile';
        }
        console.log(this.selectedTraceObj.id);
        this.createtraceArr(this.selectedTraceObj.id);
    },
    computed:{
        firstLogin() {
                return this.$store.state.firstTimeLogin
        }
    },
    methods:{
        changeClick: function(value){
            console.log(value);
            if(value.ctrlKey){
                this.click=true;
            }else{
                this.click=false;
            }
        },
        changeClick1: function(value){
            console.log(value);
            if(value.ctrlKey){
                this.click1=true;
            }else{
                this.click1=false;
            }
        },
        changeHover1:function(value){
            console.log(value)
            if(value.touches.length==2){
                this.hover1=true;
            }else{
                this.hover1=false;
            }
        },
        changeHover:function(value){
            if(value.touches.length==2){
                this.interactionArr[2].setActive(true);
                this.hover=true;
            }else{
                this.interactionArr[2].setActive(false);
                this.hover=false;
                // var x=Math.abs(value.touchstartX-value.touchendX);
                // var y=Math.abs(value.touchstartY-value.touchendY);
                // if(x<y){
                    
                // }
            }
        },
        preventMove:function(value){
            console.log(value);
            value.stopPropagation();
        },
        createtraceArr(value){
            axios
            .post(PTestConn,{
                queryurl:index.trace+'/_search',
                querybody:"{\"id\":\""+value+"\"}"
            }).then((response)=>{
                this.traceArr=response.data;

                
                var lonSum=0;
                var latSum=0;
                var arrLength=this.traceArr.length;
                this.traceArr.forEach((x)=>{
                    if(x.longitude!=0){
                        lonSum+=x.longitude;
                        latSum+=x.latitude;
                        this.deleteNullArr.push(x);
                    }
                })
                this.center[0]=lonSum/arrLength;
                this.center[1]=latSum/arrLength;
                var cityArr=[];
                // 지도의 마커 생성(중복제거, 위도경도배열 생성)
                this.traceArr.forEach((x)=>{
                    if(x.longitude!=0 && this.checkLongLan(x.longitude, x.latitude, x.ip)){
                        var coord=[];
                        coord.push(x.longitude);
                        coord.push(x.latitude);
                        if(cityArr.indexOf(x.city)==-1){
                            cityArr.push(x.city);
                            this.colorArr.push({city:x.city, color:this.RandomColor()});
                        }
                        this.traceLanLon.ip.push(x.ip);
                        this.traceLanLon.coordinates.push(coord);
                        this.traceLanLon.colors.push(this.colorArr.find(obj=>obj.city==x.city).color);
                    }
                })

                console.log(this.traceLanLon)
                
                // 지도의 각 마커를 잇는 선 배열 생성
                for(let i=0;i<this.deleteNullArr.length;i++){
                    if(i+1<this.deleteNullArr.length){
                            var coordArr=[];
                            coordArr.push([this.deleteNullArr[i].longitude,  this.deleteNullArr[i].latitude]);
                            coordArr.push([this.deleteNullArr[i+1].longitude, this.deleteNullArr[i+1].latitude]);
                            this.lineLanLon.coordinates.push(coordArr);
                            this.lineLanLon.colors.push(this.colorArr.find(obj=>obj.city==this.deleteNullArr[i].city).color)
                    }
                }

                // 그래프의 노드 배열 생성(중복 제거)
                for(let i=0;i<this.traceArr.length;i++){
                    var obj={};
                    var colorcode='';
                    if(this.traceArr[i].city==null){
                        colorcode='#000000';
                        obj={id:i+1, label:this.traceArr[i].ip+"\n"+"("+this.traceArr[i].rtt+")", color:colorcode, level:i};
                    }else{
                        colorcode=this.colorArr.find(x=>x.city==this.traceArr[i].city).color;
                        obj={id:i+1, label:this.traceArr[i].ip+"\n"+"("+this.traceArr[i].rtt+")",color:colorcode, level:i};     
                    }
                    this.nodes.push(obj);
                }
                
                // 그래프의 edge 배열
                for (let i = 0; i < this.nodes.length; i++) {
                    if(i+1<=this.nodes.length-1){
                        var from=i+1;
                        var to= i+2;
                        var edge={from: from, to:to, color:{inherit:'from'}, smooth:{type:"curvedCW", roundness:0.6}, arrows:"to"};
                        this.edges.push(edge);
                    }
                    
                }
                var container=document.getElementById("visWrap");
                var data={nodes: this.nodes, edges:this.edges};
                
                
                var network=new vis.Network(container, data, this.options);
                // this.network=network;
                // ctrlkey 적용
                var mapInstance=this.$refs.map;
                console.log(mapInstance);
                var mapInstance1=document.getElementById("map");
                var overlay=document.getElementById("overlay");
                this.interactionArr=mapInstance.getInteractions();
                this.interactionArr[1].setActive(false);
                // console.log(interactionArr)
                var config = this.options;
                var nodes=network.body.nodes;
                
                if(this.userAgent=="pc"){
                    this.interactionArr[2].condition_=platformModifierKeyOnly;
                    this.interactionArr[7].condition_=platformModifierKeyOnly;

                    network.on('dragStart', function(value){
                        console.log(value)
                        if(value.event.srcEvent.ctrlKey){
                            network.interactionHandler.options.dragView=true;
                        }else{
                            network.interactionHandler.options.dragView=false;
                        }
                    })
                    container.addEventListener('wheel', function(event){
                        if(event.ctrlKey==true){
                            this.click1=true;
                            config.interaction.zoomView=true;
                            event.preventDefault();
                            if(event.wheelDelta>0){
                                config.nodes.font.size -= 2;
                            }else{
                                config.nodes.font.size += 2;
                            }
                            var data={nodes: nodes, edges:this.edges};
                            network.setOptions(config);
                        }else if(event.ctrlKey==false){
                            this.click1=false;
                            network.interactionHandler.options.zoomView=false;
                        }
                    })
                }else{// 모바일
                    // mapInstance1.addEventListener("touchstart",function(e){
                    //     if(e.touches.length===2){
                    //         interactionArr[2].setActive(true);
                    //         this.hover=true;
                    //     }else{
                    //         this.hover=false;
                    //         interactionArr[2].setActive(false);
                    //     }
                    // })
                    
                    // mapInstance1.addEventListener("touchend",function(e){
                    //     e.stopPropagation();
                    // })

                    var touchStartX=0;
                    var touchStartY=0;

                    container.addEventListener('touchstart', function(e){
                        console.log(e.touches);
                        if(e.touches.length==2){
                            this.hover1=true;
                            console.log(2)
                            network.interactionHandler.options.zoomView=true;
                            touchStartX=e.touches[0].clientX+e.touches[1].clientX;
                            touchStartY=e.touches[0].clientY+e.touches[1].clientY;

                        }else{
                            this.hover1=false;
                            console.log(1)
                            network.interactionHandler.options.zoomView=false;
                        }
                    })

                    container.addEventListener('touchmove', function(e){
                        if(e.touches.length==2){
                            var touchMoveX = e.touches[0].clientX + e.touches[1].clientX;
                            var touchMoveY = e.touches[0].clientY + e.touches[1].clientY;
                            var deltaX = touchMoveX - touchStartX;
                            var deltaY = touchMoveY - touchStartY;

                            network.moveTo({
                                position:{ 
                                    x: network.getViewPosition().x - deltaX, y: network.getViewPosition().y - deltaY
                                }
                            });

                            touchStartX = touchMoveX;
                            touchStartY = touchMoveY;
                        }
                    })
                    container.addEventListener("touchend",function(e){
                        e.stopPropagation();
                    })
                }
                
            })
        },
        RandomColor:function(){
            var colorcode="#"+Math.round(Math.random()*16777215).toString(16);
            if(this.colorArr.findIndex(obj=>obj.color==colorcode)!=-1||colorcode=='#000000'){
                colorcode="#"+Math.round(Math.random()*16777215).toString(16);
            }  
            return colorcode;          
        },
        checkLongLan: function (long, lat, ip) {
            var res=true;
            for(let i=0;i<this.traceLanLon.coordinates.length;i++){
                if(this.traceLanLon.coordinates[i][0]==long && this.traceLanLon.coordinates[i][1]==lat && this.traceLanLon.ip[i]==ip){
                    res=false;
                }else{
                    res=true;
                }
            }
            return res;
        },
        convertTime: function(time){
            var myDate=new Date(time*1000);
            return myDate.getFullYear()+'.'+("0"+(myDate.getMonth()+1)).slice(-2)+"."+("0"+myDate.getDate()).slice(-2)+" "+("0"+myDate.getHours()).slice(-2)+":"+("0"+myDate.getMinutes()).slice(-2)
        },
        convertIp: function(ip){
            var res='';
            switch(ip){
                case "scan-client1": res="35.180.203.18"; break;
                case "scan-client2": res="15.236.239.34"; break;
                case "ip-172-31-24-2": res="13.244.75.167"; break;
                case "ip-172-31-28-98": res="13.245.17.35"; break;
                case "ip-172-26-7-137": res="35.180.229.8"; break;
                case "ip-172-26-2-90": res="13.38.26.129"; break;
                case "ip-172-26-10-104": res="13.39.112.85"; break;
                case "ip-172-26-15-254": res="15.237.40.229"; break;
            }
            return res;
        }
    }
}
</script>

<style scoped>
.text-center{
    font-size: 0.875rem !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    height: 30px !important;
}
::v-deep .v-data-table--fixed-header > .v-data-table__wrapper{
    scrollbar-width: none;
}
.mapV, .graphV, .infoV, .tableV{
    margin-bottom: 20px;
}
.infoIndex, .infoValue{
    width: max-content;
    float: left;
}
.infoValue{
    margin-left: 10px;
}
#visWrap{
    height: 400px; 
    background-color: white !important;
}
</style>