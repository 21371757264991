<template>
  <div style="height: 100%">
    <v-container fluid style="height: 100%" class="pb-0">
      <v-row style="height: 100%">
        <v-col cols="12" md="5" xl="4" style="height: 100%">
          <div style="height: 100%">
            <v-card
              outlined
              height="100%"
              elevation="2"
              class="pa-4 ml-1"
              style="border: 1px solid gainsboro"
            >
              <h4
                class="mb-2"
                @mouseover="status_eventShow"
                @mouseleave="status_eventoff"
              >
                STATUS & EVENT
              </h4>
              <div>
                <v-row>
                  <v-col cols="12" md="6">
                    <div>
                      <v-card elevation="0" class="pa-2 mt-n2 ml-n1">
                        <div
                          class="pl-2"
                          style="width: 100%"
                          @mouseover="totalUsersShow"
                          @mouseleave="totalUsersoff"
                        >
                          <span style="font-size: 2.5em; font-weight: bold">{{
                            usersStats.total
                          }}</span>
                          <span class="ml-2">Total Users</span>
                        </div>
                        <div class="text-center ma-1">
                          <v-row>
                            <v-col cols="4" class="pa-md-1 pa-lg-2 pa-xl-2">
                              <v-card
                                outlined
                                color="#CFD8DC"
                                class="rounded-1"
                                style="height:100% wwidth:100%;"
                                @mouseover="userAdminsShow"
                                @mouseleave="userAdminsoff"
                              >
                                <div style="height: 70%">
                                  <p
                                    class="my-md-auto"
                                    style="font-size: 2.5em; font-weight: bold"
                                  >
                                    {{ usersStats.admins }}
                                  </p>
                                </div>
                                <div><p>Admins</p></div>
                              </v-card>
                            </v-col>
                            <v-col cols="4" class="pa-md-1 pa-lg-2 pa-xl-2">
                              <div>
                                <v-card
                                  outlined
                                  color="#CFD8DC"
                                  class="rounded-1"
                                  style="height:100% wwidth:100%;"
                                  @mouseover="userActiveShow"
                                  @mouseleave="userActiveoff"
                                >
                                  <div style="height: 70%">
                                    <p
                                      class="my-md-auto"
                                      style="
                                        font-size: 2.5em;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ usersStats.active }}
                                    </p>
                                  </div>
                                  <div><p>Active</p></div>
                                </v-card>
                              </div>
                            </v-col>
                            <v-col cols="4" class="pa-md-1 pa-lg-2 pa-xl-2">
                              <div>
                                <v-card
                                  outlined
                                  color="#CFD8DC"
                                  class="rounded-1"
                                  style="height:100% wwidth:100%;"
                                  @mouseover="userInactiveShow"
                                  @mouseleave="userInactiveoff"
                                >
                                  <div style="height: 70%">
                                    <p
                                      class="my-md-auto"
                                      style="
                                        font-size: 2.5em;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ usersStats.inactive }}
                                    </p>
                                  </div>
                                  <div><p>Inactive</p></div>
                                </v-card>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div>
                      <v-card elevation="0" class="pa-2 mt-n2 ml-1">
                        <div
                          class="pl-3"
                          style="width: 100%"
                          @mouseover="totalGroupsShow"
                          @mouseleave="totalGroupsoff"
                        >
                          <span style="font-size: 2.5em; font-weight: bold">{{
                            groupsStats.total
                          }}</span>
                          <span class="ml-2">Total Groups</span>
                        </div>
                        <div class="text-center ma-1">
                          <v-row>
                            <v-col cols="4" class="pa-md-1 pa-lg-2 pa-xl-2">
                              <v-card
                                outlined
                                color="#CFD8DC"
                                style="height:100% wwidth:100%;"
                                class="rounded-1"
                                @mouseover="groupAdminsShow"
                                @mouseleave="groupAdminsoff"
                              >
                                <div style="height: 70%">
                                  <p
                                    class="my-md-auto"
                                    style="font-size: 2.5em; font-weight: bold"
                                  >
                                    {{ groupsStats.admins }}
                                  </p>
                                </div>
                                <div><p>Admins</p></div>
                              </v-card>
                            </v-col>
                            <v-col cols="4" class="pa-md-1 pa-lg-2 pa-xl-2">
                              <div>
                                <v-card
                                  outlined
                                  color="#CFD8DC"
                                  style="height:100% wwidth:100%;"
                                  class="rounded-1"
                                  @mouseover="groupActiveShow"
                                  @mouseleave="groupActiveoff"
                                >
                                  <div style="height: 70%">
                                    <p
                                      class="my-md-auto"
                                      style="
                                        font-size: 2.5em;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ groupsStats.active }}
                                    </p>
                                  </div>
                                  <div><p>Active</p></div>
                                </v-card>
                              </div>
                            </v-col>
                            <v-col cols="4" class="pa-md-1 pa-lg-2 pa-xl-2">
                              <div>
                                <v-card
                                  outlined
                                  color="#CFD8DC"
                                  style="height:100% wwidth:100%;"
                                  class="rounded-1"
                                  @mouseover="groupInactiveShow"
                                  @mouseleave="groupInactiveoff"
                                >
                                  <div style="height: 70%">
                                    <p
                                      class="my-md-auto"
                                      style="
                                        font-size: 2.5em;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ groupsStats.inactive }}
                                    </p>
                                  </div>
                                  <div><p>Inactive</p></div>
                                </v-card>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="mt-4">
                <v-expansion-panels
                  accordion
                  style="border: 1px solid gainsboro"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="pa-3 font-weight-bold"
                      @mouseover="recentCreareShow"
                      @mouseleave="recentCreareoff"
                    >
                      Recent create/change user account or group
                      <span
                        ><v-badge
                          class="pl-2 text-center rounded-0"
                          color="green"
                          :content="activityLog.logUserGroup.length"
                        ></v-badge
                      ></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="v-expansion-panel-content expansion-cardbox"
                    >
                      <div style="width: 100%">
                        <table class="table-sm w-100 mb-4" style="width: 100%">
                          <tbody>
                            <tr
                              v-for="(item, index) in activityLog.logUserGroup"
                              :key="index"
                            >
                              <td class="text-left">{{ item.content }}</td>
                              <td style="width: 28%" class="text-right">
                                {{ dateFormat(item.timestamp) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="pa-3 font-weight-bold"
                      @mouseover="recentChangePrivilegeShow"
                      @mouseleave="recentChangePrivilegeoff"
                    >
                      Recent change privilege
                      <span
                        ><v-badge
                          class="pl-2 text-center rounded-0"
                          color="green"
                          :content="activityLog.logPrivilege.length || '0'"
                        ></v-badge
                      ></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="v-expansion-panel-content expansion-cardbox"
                    >
                      <div style="width: 100%">
                        <table class="table-sm w-100 mb-4" style="width: 100%">
                          <tbody>
                            <tr
                              v-for="(item, index) in activityLog.logPrivilege"
                              :key="index"
                            >
                              <td class="text-left">{{ item.content }}</td>
                              <td class="text-right">
                                {{ dateFormat(item.timestamp) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="pa-3 font-weight-bold"
                      @mouseover="recentChangePasswordShow"
                      @mouseleave="recentChangePasswordoff"
                    >
                      Recent changed password
                      <span
                        ><v-badge
                          class="pl-2 text-center rounded-0"
                          color="green"
                          :content="activityLog.logPassword.length"
                        ></v-badge
                      ></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="v-expansion-panel-content expansion-cardbox"
                    >
                      <div style="width: 100%">
                        <table class="table-sm w-100 mb-4" style="width: 100%">
                          <tbody>
                            <tr
                              v-for="(item, index) in activityLog.logPassword"
                              :key="index"
                            >
                              <td class="text-left">{{ item.content }}</td>
                              <td class="text-right" style="width: 28%">
                                {{ dateFormat(item.timestamp) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="pa-3 font-weight-bold"
                      @mouseover="userAccountsLoginShow"
                      @mouseleave="userAccountsLoginoff"
                    >
                      User accounts with login failure records
                      <span
                        ><v-badge
                          class="pl-2 text-center rounded-0"
                          color="green"
                          :content="activityLog.loginFail.length || '0'"
                        ></v-badge
                      ></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="v-expansion-panel-content expansion-cardbox"
                    >
                      <div style="width: 100%">
                        <table class="table-sm w-100 mb-4" style="width: 100%">
                          <tbody>
                            <tr
                              v-for="(item, index) in activityLog.loginFail"
                              :key="index"
                            >
                              <td class="d-flex">
                                <span
                                  >{{ index + 1 }}. User account
                                  {{ item.id }} failed attempts ...</span
                                >
                                <span class="ml-auto"
                                  >{{ item.fail_count }} times</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="pa-3 font-weight-bold"
                      @mouseover="userAccountPasswordShow"
                      @mouseleave="userAccountPasswordoff"
                    >
                      User accounts coming password expiration
                      <span
                        ><v-badge
                          class="pl-2 text-center rounded-0"
                          color="green"
                          :content="activityLog.passwordExpire.length || '0'"
                        ></v-badge
                      ></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="v-expansion-panel-content expansion-cardbox"
                    >
                      <div style="width: 100%">
                        <table class="table-sm w-100 mb-4" style="width: 100%">
                          <tbody>
                            <tr
                              v-for="(
                                item, index
                              ) in activityLog.passwordExpire"
                              :key="index"
                            >
                              <td class="text-left">{{ item.id }}</td>
                              <td class="text-right">
                                {{ dateFormat(item.timestamp) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="7" xl="8" style="height: 100%">
          <div style="height: 100%">
            <v-card
              outlined
              elevation="2"
              class="pa-4 mr-1"
              style="border: 1px solid gainsboro; height: 100%"
            >
              <h4>MANAGEMENT</h4>
              <div>
                <v-container class="pl-0 pr-0 pb-0">
                  <v-card elevation="0">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <template>
                          <v-tabs v-model="tabs" color="orange darken-3">
                            <v-tab
                              class="font-weight-bold"
                              @mouseover="userTabShow"
                              @mouseleave="userTaboff"
                              >Users</v-tab
                            >
                            <v-tab
                              class="font-weight-bold"
                              @mouseover="groupTabShow"
                              @mouseleave="groupTaboff"
                              >Groups</v-tab
                            >
                          </v-tabs>
                        </template>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row class="pa-4" v-if="!tabs">
                          <v-spacer></v-spacer>
                          <v-spacer></v-spacer>
                          <v-text-field
                            flat
                            solo
                            outlined
                            dense
                            label="Search User"
                            hide-details=""
                            class="pl-12 pr-2 font-weight-light v-input__slot"
                            style="max-width: 67%"
                            prepend-inner-icon="mdi-magnify"
                            v-model="userSearch"
                            @mouseover="ut_searchUserShow"
                            @mouseleave="ut_searchUseroff"
                          >
                          </v-text-field>
                          <v-btn
                            color="#CFD8DC"
                            small
                            elevation="0"
                            style="
                              height: 32px;
                              min-width: 37px;
                              padding: 0 8px;
                            "
                            @click="
                              editUser({ secret: createSecret() });
                              flagCreateModify = 'create';
                            "
                            @mouseover="ut_addUserShow"
                            @mouseleave="ut_addUseroff"
                          >
                            <v-icon size="200%">mdi-account-plus</v-icon>
                          </v-btn>
                        </v-row>
                        <v-row class="pa-5" v-else>
                          <v-spacer></v-spacer>
                          <v-spacer></v-spacer>
                          <v-text-field
                            flat
                            solo
                            outlined
                            dense
                            label="Search Group"
                            hide-details=""
                            class="pl-12 pr-2 font-weight-light text-sm-left"
                            style="max-width: 67%"
                            prepend-inner-icon="mdi-magnify"
                            v-model="groupSearch"
                            @mouseover="gt_searchGroupShow"
                            @mouseleave="gt_searchGroupoff"
                          >
                          </v-text-field>
                          <v-btn
                            color="#CFD8DC"
                            small
                            elevation="0"
                            style="
                              height: 32px;
                              min-width: 37px;
                              padding: 0 8px;
                            "
                            @click="
                              selectedGroup = {};
                              flagCreateModify = 'create';
                              groupPermissionsDialog = true;
                            "
                            @mouseover="gt_addGroupShow"
                            @mouseleave="gt_addGroupoff"
                          >
                            <v-icon size="200%"
                              >mdi-account-multiple-plus</v-icon
                            >
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-tabs-items v-model="tabs">
                      <v-tab-item>
                        <v-card elevation="0" width="100%">
                          <v-data-table
                            :headers="usersHeader"
                            :items="users"
                            :page.sync="usersPage"
                            :items-per-page="10"
                            :search="userSearch"
                            hide-default-footer
                            class="elevation-0 v-data-table text-center no-scroll"
                            @page-count="usersPageCount = $event"
                            dense
                          >
                            <template v-slot:[`item.number`]="{ item }">
                              {{ users.indexOf(item) + 1 }}
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                              {{ item.id }}
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                              <v-chip
                                :color="item.status > 0 ? 'success' : 'red'"
                                dark
                                x-small
                              >
                              </v-chip>
                            </template>

                            <template v-slot:[`item.email_noti`]="{ item }">
                              <div class="pl-3">
                                <v-icon small>
                                  mdi-{{
                                    item.email_noti
                                      ? "checkbox-outline"
                                      : "checkbox-blank-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:[`item.created_time`]="{ item }">
                              {{ dateFormat(item.created_time) }}
                            </template>
                            <template v-slot:[`item.last_login`]="{ item }">
                              {{
                                item.last_login
                                  ? dateFormat(item.last_login)
                                  : ""
                              }}
                            </template>
                            <template v-slot:[`item.start_time`]="{ item }">
                              <span
                                v-tooltip="
                                  completeDate(item.start_time, item.end_time)
                                "
                                >{{
                                  item.start_time
                                    ? cDateFormat(item.start_time)
                                    : ""
                                }}
                                ~
                                {{
                                  item.end_time
                                    ? cDateFormat(item.end_time)
                                    : ""
                                }}</span
                              >
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-row style="flex-wrap: nowrap !important">
                                <div
                                  class="papa-ghost"
                                  style="height: auto; width: auto"
                                >
                                  <v-btn
                                    height="25px"
                                    width="25px"
                                    icon
                                    @click="userActions(item)"
                                    class="ghost-icon"
                                  >
                                    <v-icon size="170%"> mdi-history </v-icon>
                                  </v-btn>
                                </div>
                                <div class="papa-ghost">
                                  <v-btn
                                    height="25px"
                                    width="25px"
                                    icon
                                    @click="
                                      editUser(item);
                                      flagCreateModify = 'modify';
                                    "
                                    class="ghost-icon"
                                  >
                                    <v-icon size="170%">
                                      mdi-account-edit
                                    </v-icon>
                                  </v-btn>
                                </div>
                                <div class="papa-ghost">
                                  <v-btn
                                    height="25px"
                                    width="25px"
                                    icon
                                    @click="changeUserStatus(item)"
                                    class="ghost-icon"
                                  >
                                    <v-icon size="170%">
                                      {{
                                        item.status == "inactive"
                                          ? "mdi-check-bold"
                                          : "mdi-close-thick"
                                      }}
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </v-row>
                            </template>
                          </v-data-table>
                          <div class="text-center pt-2" style="height: 100%">
                            <v-card elevation="0">
                              <v-pagination
                                class="mt-3"
                                v-model="usersPage"
                                :length="usersPageCount"
                              ></v-pagination>
                            </v-card>
                          </div>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card elevation="0">
                          <v-data-table
                            :headers="groupsHeader"
                            :items="groups"
                            :page.sync="groupsPage"
                            :items-per-page="10"
                            :search="groupSearch"
                            hide-default-footer
                            class="elevation-0 v-data-table text-center"
                            @page-count="groupsPageCount = $event"
                            dense
                          >
                            <template v-slot:[`item.number`]="{ item }">
                              {{ groups.indexOf(item) + 1 }}
                            </template>
                            <template v-slot:[`item.analysis`]="{ item }">
                              <div class="pl-3">
                                <v-icon small>
                                  mdi-{{
                                    item.analysis
                                      ? "checkbox-outline"
                                      : "checkbox-blank-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:[`item.search`]="{ item }">
                              <div class="pl-3">
                                <v-icon small>
                                  mdi-{{
                                    item.search
                                      ? "checkbox-outline"
                                      : "checkbox-blank-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:[`item.management`]="{ item }">
                              <div class="pl-3">
                                <v-icon small>
                                  mdi-{{
                                    item.management
                                      ? "checkbox-outline"
                                      : "checkbox-blank-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:[`item.system`]="{ item }">
                              <div class="pl-3">
                                <v-icon small>
                                  mdi-{{
                                    item.system
                                      ? "checkbox-outline"
                                      : "checkbox-blank-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:[`item.configuration`]="{ item }">
                              <div class="pl-3">
                                <v-icon small>
                                  mdi-{{
                                    item.configuration
                                      ? "checkbox-outline"
                                      : "checkbox-blank-outline"
                                  }}
                                </v-icon>
                              </div>
                            </template>

                            <template v-slot:[`item.created_time`]="{ item }">
                              {{ dateFormat(item.created_time) }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-row style="flex-wrap: nowrap !important">
                                <div class="papa-ghost">
                                  <v-btn
                                    class="ghost-icon"
                                    height="30px"
                                    width="30px"
                                    icon
                                    @click="
                                      selectedGroup = item;
                                      flagCreateModify = 'modify';
                                      groupPermissionsDialog = true;
                                    "
                                  >
                                    <v-icon size="170%"> mdi-file-edit </v-icon>
                                  </v-btn>
                                </div>
                                <div class="papa-ghost">
                                  <v-btn
                                    class="ghost-icon"
                                    height="30px"
                                    width="30px"
                                    icon
                                    @click="
                                      selectedGroup = item;
                                      groupDeleteDialog = true;
                                    "
                                  >
                                    <v-icon size="170%"> mdi-delete </v-icon>
                                  </v-btn>
                                </div>
                              </v-row>
                            </template>
                          </v-data-table>
                          <div class="text-center pt-2">
                            <v-pagination
                              v-model="groupsPage"
                              :length="groupsPageCount"
                            ></v-pagination>
                          </div>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-container>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <!-- Group Delet Dialog -->
      <v-row justify="center">
        <v-dialog v-model="groupDeleteDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="text-uppercase subtitle-2">
              Delete group {{ selectedGroup["group_name"] }}
            </v-card-title>
            <v-card-text>
              <div v-if="selectedGroup.count < 1">
                This group cannot be restored later.
              </div>
              <div v-else>
                This group cannot be deleted with users ({{
                  selectedGroup.count
                }}) in it
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="groupDeleteDialog = false"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                v-if="selectedGroup.count < 1"
                @click="
                  deleteGroup(selectedGroup.gid);
                  groupDeleteDialog = false;
                "
              >
                DELETE
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- Group permissions Dialog -->
      <v-row justify="center">
        <v-dialog v-model="groupPermissionsDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="text-uppercase subtitle-2">
              {{
                flagCreateModify == "create"
                  ? "Create group"
                  : "Edit group permissions"
              }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" v-if="flagCreateModify == 'create'">
                  <v-text-field
                    label="Group name*"
                    required
                    v-model="selectedGroup.group"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Analysis"
                    color="green darken-2"
                    v-model="selectedGroup.analysis"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Search"
                    color="green darken-2"
                    v-model="selectedGroup.search"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Management"
                    color="green darken-2"
                    v-model="selectedGroup.management"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="System"
                    color="green darken-2"
                    v-model="selectedGroup.system"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Configuration"
                    color="green darken-2"
                    v-model="selectedGroup.configuration"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="groupPermissionsDialog = false"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="postGroupCreateModify()"
              >
                SAVE
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- User change status Dialog -->
      <v-row justify="center">
        <v-dialog v-model="userStatusDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="text-uppercase">
              Change status of {{ selectedUser.name }} {{ selectedUser.status }}
            </v-card-title>
            <v-card-text>
              <div v-if="selectedUser.status != -2">
                Change from
                <span class="text-capitalize">{{
                  activeText(selectedUser.status)
                }}</span>
                to {{ activeText(selectedUser.status * -1) }}
              </div>
              <div v-else>
                Currently this user is disabled due to usage period <br />({{
                  dateFormat(selectedUser.start_time)
                }}
                ~ {{ dateFormat(selectedUser.end_time) }}). <br />Please change
                these values in the edit screen if you intent to change the user
                current status.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="userStatusDialog = false"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="postChangeUserStatus(selectedUser.uid)"
                v-if="selectedUser.status != -2"
              >
                CHANGE
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- user actions modal -->
      <v-row justify="center">
        <v-dialog v-model="userActionsDialog" scrollable max-width="400px">
          <v-card tile>
            <v-card-title>{{ selectedUser.name }} history</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px">
              <v-list-item
                two-line
                v-for="(item, index) in selectedUserActions"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.content }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    dateFormat(item.timestamp)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="userActionsDialog = false"
              >
                Close
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- User edit dialog -->
      <v-row justify="center">
        <v-dialog v-model="editUserDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-uppercase"
                >{{ selectedUser.name }} Profile</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <img
                      height="120"
                      width="120"
                      v-if="selectedUser.avatar"
                      :src="selectedUser.avatar"
                    />
                    <v-file-input
                      :rules="[
                        (value) =>
                          !value ||
                          value.size < 2000000 ||
                          'Avatar size should be less than 2 MB!',
                      ]"
                      accept="image/*"
                      label="Avatar"
                      prepend-icon="mdi-camera"
                      @change="fileChange"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Username*"
                      required
                      v-model="selectedUser.id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Name*"
                      required
                      v-model="selectedUser.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="userGroups"
                      label="Group"
                      v-model="selectedUser.group"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Password*"
                      :type="show1 ? 'text' : 'password'"
                      required
                      v-model="selectedUser.editPassword"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email*"
                      required
                      v-model="selectedUser.email"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Organization*"
                      required
                      v-model="selectedUser.organization"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="countries"
                      item-text="name"
                      item-value="name"
                      label="Country"
                      dense
                      v-model="selectedUser.country"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      label="Email Notifications"
                      color="green darken-2"
                      v-model="selectedUser.email_noti"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="editUserStartTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectedUser.start_time"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selectedUser.start_time"
                        @input="
                          editUserStartTimeMenu = false;
                          selectedUser.start_time = parseApiFormat(
                            selectedUser.start_time
                          );
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="editUserEndTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectedUser.end_time"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selectedUser.end_time"
                        @input="
                          editUserEndTimeMenu = false;
                          selectedUser.end_time = parseApiFormat(
                            selectedUser.end_time
                          );
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" v-if="flagCreateModify == 'modify'">
                    <custom-qr
                      :id="'id' + selectedUser.uid"
                      :key="selectedUser.id"
                      :userData="selectedUser"
                    ></custom-qr>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="editUserDialog = false">
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="postUserCreateModify()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import { authenticator } from "otplib";
import {
  dateFormat,
  countries,
  apiDateFormat,
  PTestApi,
} from "@/mixins/commons";
import customQr from "@/components/charts/QR.vue";
import EventBus from "@/plugins/EventBus.js";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default {
  components: {
    customQr,
  },
  computed: {
    userGroups: function () {
      return this.groups.map((x) => {
        return x.group_name;
      });
    },
  },
  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      show1: false,
      countries: countries,
      editUserStartTimeMenu: false,
      editUserEndTimeMenu: false,
      customDateFormat: "YYYY.MM.DD",
      tabs: null,
      userSearch: ``,
      users: [],
      flagCreateModify: "",
      usersHeader: [
        {
          text: "#",
          value: `number`,
          align: "center",
          width: "35px",
          sortable: false,
        },
        { text: `Username`, value: `id`, align: "center", sortable: false },
        { text: `Group`, value: `group`, align: "center", sortable: false },
        { text: `Status`, value: `status`, align: "center", sortable: false },
        {
          text: `Email noti`,
          value: `email_noti`,
          align: "center",
          sortable: false,
        },
        {
          text: `Create Time`,
          value: `created_time`,
          align: "center",
          sortable: false,
        },
        {
          text: `Last Login`,
          value: `last_login`,
          align: "center",
          sortable: false,
        },
        {
          text: `Logins`,
          value: `login_count`,
          align: "center",
          width: "62px",
          sortable: false,
        },
        {
          text: `Usable Period`,
          value: `start_time`,
          align: "center",
          sortable: false,
        },
        {
          text: `Actions`,
          value: `actions`,
          sortable: false,
          width: "80px",
          align: "center",
        },
      ],
      usersLoading: false,
      usersPage: 1,
      usersPageCount: 0,
      usersStats: {
        total: 0,
        active: 0,
        inactive: 0,
        admins: 0,
      },
      userStatusDialog: false,
      userActionsDialog: false,
      editUserDialog: false,
      selectedUser: {},
      selectedUserActions: [],
      selectedGroup: {},
      groupSearch: ``,
      groups: [],
      groupsHeader: [
        { text: "#", value: "number", align: "center", sortable: false },
        {
          text: `Group`,
          value: `group_name`,
          align: "center",
          sortable: false,
        },
        { text: `Members`, value: `count`, align: "center", sortable: false },
        {
          text: `Analysis`,
          value: `analysis`,
          align: "center",
          sortable: false,
        },
        { text: `Search`, value: `search`, align: "center", sortable: false },
        {
          text: `Management`,
          value: `management`,
          align: "center",
          sortable: false,
        },
        { text: `System`, value: `system`, align: "center", sortable: false },
        {
          text: "Configuration",
          value: "configuration",
          align: "center",
          sortable: false,
        },
        {
          text: "Create Time",
          value: "created_time",
          align: "center",
          sortable: false,
        },
        { text: `Actions`, value: `actions`, sortable: false, align: "center" },
      ],
      groupsLoading: false,
      groupsPage: 1,
      groupsPageCount: 0,
      groupsStats: {
        total: 0,
        active: 0,
        inactive: 0,
        admins: 1,
      },
      groupDeleteDialog: false,
      groupPermissionsDialog: false,
      activityLog: {
        logUserGroup: [],
        logPrivilege: [],
        logPassword: [],
        loginFail: [],
        passwordExpire: [],
      },
    };
  },
  methods: {
    status_eventShow: function () {
      EventBus.$emit(
        "status_eventShow",
        `As a system administrator, you can see the current status of the user and group accounts registered in this system and the records of important operating events.`
      );
    },
    status_eventoff: function () {
      EventBus.$emit("status_eventShow", null);
    },
    totalUsersShow: function () {
      EventBus.$emit(
        "totalUsersShow",
        `This number shows the total number of all user accounts registered in this system.`
      );
    },
    totalUsersoff: function () {
      EventBus.$emit("totalUsersShow", null);
    },
    userInactiveShow: function () {
      EventBus.$emit(
        "userInactiveShow",
        `This number shows the total number of accounts with unactivated status.`
      );
    },
    userInactiveoff: function () {
      EventBus.$emit("userInactiveShow", null);
    },
    userAdminsShow: function () {
      EventBus.$emit(
        "userAdminsShow",
        `This number indicates the total number of all administrator accounts registered on this system.`
      );
    },
    userAdminsoff: function () {
      EventBus.$emit("userAdminsShow", null);
    },
    userActiveShow: function () {
      EventBus.$emit(
        "userActiveShow",
        `This number shows the total number of currently active accounts.`
      );
    },
    userActiveoff: function () {
      EventBus.$emit("userActiveShow", null);
    },
    totalGroupsShow: function () {
      EventBus.$emit(
        "totalGroupsShow",
        `This number shows the total number of all groups registered in this system.`
      );
    },
    totalGroupsoff: function () {
      EventBus.$emit("totalGroupsShow", null);
    },
    groupAdminsShow: function () {
      EventBus.$emit(
        "groupAdminsShow",
        `This number indicates the total number of all administrator groups registered on this system.`
      );
    },
    groupAdminsoff: function () {
      EventBus.$emit("groupAdminsShow", null);
    },
    groupActiveShow: function () {
      EventBus.$emit(
        "groupActiveShow",
        `This number shows the total number of currently active groups.`
      );
    },
    groupActiveoff: function () {
      EventBus.$emit("groupActiveShow", null);
    },
    groupInactiveShow: function () {
      EventBus.$emit(
        "groupInactiveShow",
        `This number shows the total number of groups with unactivated status.`
      );
    },
    groupInactiveoff: function () {
      EventBus.$emit("groupInactiveShow", null);
    },
    recentCreareShow: function () {
      EventBus.$emit(
        "recentCreareShow",
        `When you click here, you can see the history of creation or change of the user accounts or groups that have occurred in the last 3 months.`
      );
    },
    recentCreareoff: function () {
      EventBus.$emit("recentCreareShow", null);
    },
    recentChangePrivilegeShow: function () {
      EventBus.$emit(
        "recentChangePrivilegeShow",
        `When you click here, you can see the history of changes in the user account privileges that have occurred in the last 3 months.`
      );
    },
    recentChangePrivilegeoff: function () {
      EventBus.$emit("recentChangePrivilegeShow", null);
    },
    recentChangePasswordShow: function () {
      EventBus.$emit(
        "recentChangePasswordShow",
        `When you click here, you can see the history of password changes of the user accounts that have occurred in the last 3 months.`
      );
    },
    recentChangePasswordoff: function () {
      EventBus.$emit("recentChangePasswordShow", null);
    },
    userAccountsLoginShow: function () {
      EventBus.$emit(
        "userAccountsLoginShow",
        `When you click here, you can see the log-in failure history of the user accounts that have occurred in the last 3 months.`
      );
    },
    userAccountsLoginoff: function () {
      EventBus.$emit("userAccountsLoginShow", null);
    },
    userAccountPasswordShow: function () {
      EventBus.$emit(
        "userAccountPasswordShow",
        `When you click here, you can see the user accounts whose passwords are due to expire within a week.`
      );
    },
    userAccountPasswordoff: function () {
      EventBus.$emit("userAccountPasswordShow", null);
    },
    userTabShow: function () {
      EventBus.$emit(
        "userTabShow",
        `You can check the status of user accounts registered in this system through this tab, and change or delete them using the menus in the 'Actions'.`
      );
    },
    userTaboff: function () {
      EventBus.$emit("userTabShow", null);
    },
    groupTabShow: function () {
      EventBus.$emit(
        "groupTabShow",
        `You can check the status of account groups registered in this system through this tab, and change or delete them using the menus in the 'Actions' column.`
      );
    },
    groupTaboff: function () {
      EventBus.$emit("groupTabShow", null);
    },
    ut_searchUserShow: function () {
      EventBus.$emit(
        "ut_searchUserShow",
        `You can search for a specific user account by typing User account name in this text box.`
      );
    },
    ut_searchUseroff: function () {
      EventBus.$emit("ut_searchUserShow", null);
    },
    ut_addUserShow: function () {
      EventBus.$emit(
        "ut_addUserShow",
        `You can use this button to add a user account for this system.`
      );
    },
    ut_addUseroff: function () {
      EventBus.$emit("ut_addUserShow", null);
    },
    gt_searchGroupShow: function () {
      EventBus.$emit(
        "gt_searchGroupShow",
        `You can search for a specific account group by typing group name in this text box.`
      );
    },
    gt_searchGroupoff: function () {
      EventBus.$emit("gt_searchGroupShow", null);
    },
    gt_addGroupShow: function () {
      EventBus.$emit(
        "gt_addGroupShow",
        `You can use this button to add a group for this system.`
      );
    },
    gt_addGroupoff: function () {
      EventBus.$emit("gt_addGroupShow", null);
    },

    transformIMG(img) {
      return "data:image/png;base64," + new Buffer.from(img).toString("base64");
    },
    async fileChange(e) {
      if (e) {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        this.selectedUser.avatar = await toBase64(e);
      } else {
        this.selectedUser.avatar = null;
      }
    },
    activeText(value) {
      if (value > 0) {
        return "Active";
      }
      return "Inactive";
    },
    dateFormat(date) {
      return dayjs(date).format(dateFormat);
    },
    parseApiFormat(date) {
      return dayjs.utc(date).format(apiDateFormat);
    },
    completeDate(d1, d2) {
      return `${this.dateFormat(d1)} ~ ${this.dateFormat(d2)}`;
    },
    cDateFormat(date) {
      return dayjs(date).format(this.customDateFormat);
    },
    getUsers: function () {
      axios
        .get(`${PTestApi}/user/findAll`)
        .then((response) => {
          this.users = response.data;
          this.usersStats.total = this.users.length;

          this.usersStats.admins = 0;
          this.usersStats.active = 0;
          this.usersStats.inactive = 0;

          this.users.forEach((user) => {
            if (user.group == `admin`) {
              this.usersStats.admins++;
            }

            if (user.status >= 0) {
              this.usersStats.active++;
            } else {
              this.usersStats.inactive++;
            }
          });
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    getGroups: function () {
      axios
        .get(`${PTestApi}/group/findAll`)
        .then((response) => {
          this.groups = response.data;
          this.groupsStats.total = this.groups.length;
          this.groupsStats.active = this.groups.length;
          this.$store.commit("setGroupPermission", response.data);
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    changeUserStatus: function (user) {
      this.selectedUser = user;
      this.userStatusDialog = true;
    },
    postChangeUserStatus: function (uid) {
      axios
        .post(PTestApi, { url: `/user/status/${uid}` })
        .then(function (result) {
          console.log(result.data.result);
        })
        .catch((err) => {
          console.warn("oops: ", err);
        })
        .finally(() => {
          this.userStatusDialog = false;
          this.getUsers();
        });
    },
    userActions: function (user) {
      this.selectedUser = user;
      this.userActionsDialog = true;
      this.getUserActions(user.uid);
    },
    getUserActions: function (uid) {
      axios
        .get(`${PTestApi}/log/user/${uid}`)
        .then((result) => {
          this.selectedUserActions = result.data;
        })
        .catch((err) => {
          console.warn("oops: ", err);
        })
        .finally(() => {
          this.getUsers();
        });
    },
    editUser: function (user) {
      this.selectedUser = null;
      this.selectedUser = user;
      this.selectedUser.start_time
        ? (this.selectedUser.start_time = this.parseApiFormat(
            this.selectedUser.start_time
          ))
        : (this.selectedUser.start_time = this.parseApiFormat(
            dayjs().subtract(1, "week").format()
          ));
      this.selectedUser.end_time
        ? (this.selectedUser.end_time = this.parseApiFormat(
            this.selectedUser.end_time
          ))
        : (this.selectedUser.end_time = this.parseApiFormat(
            dayjs().add(1, "week").format()
          ));

      this.editUserDialog = true;
    },
    createSecret: function () {
      return authenticator.generateSecret();
    },
    getRecentUserGroup: function () {
      axios
        .get(`${PTestApi}/log/createorchange`)
        .then((result) => {
          this.activityLog.logUserGroup = result.data;
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    getRecentPrivilege: function () {
      axios
        .get(`${PTestApi}/log/change/privilege`)
        .then((result) => {
          this.activityLog.logPrivilege = result.data;
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    getRecentPassword: function () {
      axios
        .get(`${PTestApi}/log/change/password`)
        .then((result) => {
          this.activityLog.logPassword = result.data;
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    getRecentLoginFail: function () {
      axios
        .get(`${PTestApi}/log/login/fail`)
        .then((result) => {
          this.activityLog.loginFail = result.data;
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    getPasswordExpire: function () {
      axios
        .get(`${PTestApi}/user/expire`)
        .then((result) => {
          this.activityLog.passwordExpire = result.data;
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    deleteGroup: function (gid) {
      axios
        .delete(`${PTestApi}/group/delete/${gid}`)
        .then((result) => {
          console.log(result.data);
        })
        .catch((err) => {
          console.warn("oops: ", err);
        })
        .finally(() => {
          this.getGroups();
        });
    },
    postUserCreateModify: function () {
      if (
        this.selectedUser.editPassword != "" &&
        this.selectedUser.editPassword != null &&
        this.selectedUser.editPassword != undefined
      ) {
        this.selectedUser.password = this.selectedUser.editPassword;
        if (
          this.selectedUser.email_noti ||
          this.selectedUser.email_noti == "1"
        ) {
          this.selectedUser.email_noti = "1";
        } else {
          this.selectedUser.email_noti = "0";
        }
        const payload = this.selectedUser;
        if (this.flagCreateModify == "create") {
          delete payload.uid;
          payload.url = "/user/join";
          axios
            .post(PTestApi, payload)
            .then((response) => {
              console.log(response.data);
            })
            .catch((err) => {
              console.warn("oops: ", err);
            })
            .finally(() => {
              this.flagCreateModify = "";
              this.selectedUser = "";
              this.editUserDialog = false;
              this.getUsers();
              this.getRecentUserGroup();
              this.getRecentPrivilege();
              this.getRecentPassword();
            });
        } else if (this.flagCreateModify == "modify") {
          delete payload.status;
          payload.url = "/user/modify";
          axios
            .post(PTestApi, payload)
            .then((response) => {
              console.log(response.data);
            })
            .catch((err) => {
              console.warn("oops: ", err);
            })
            .finally(() => {
              this.flagCreateModify = "";
              this.selectedUser = "";
              this.editUserDialog = false;
              this.getUsers();
              this.getRecentUserGroup();
              this.getRecentPrivilege();
              this.getRecentPassword();
            });
        } else {
          console.warn("Incorrect parameter in flag..");
        }
      } else {
        console.warn("New password empty");
      }
    },
    validateSwitch(value) {
      if (value || value == 1) {
        return 1;
      }
      return 0;
    },
    postGroupCreateModify: function () {
      const payload = this.selectedGroup;
      if (this.flagCreateModify == "create") {
        payload.analysis = this.validateSwitch(payload.analysis);
        payload.configuration = this.validateSwitch(payload.configuration);
        payload.management = this.validateSwitch(payload.management);
        payload.search = this.validateSwitch(payload.search);
        payload.system = this.validateSwitch(payload.system);
        payload.url = "/group/addGroup";
        axios
          .post(PTestApi, payload)
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.warn("oops: ", err);
          })
          .finally(() => {
            this.flagCreateModify = "";
            this.groupPermissionsDialog = false;
            this.getGroups();
            this.getRecentPrivilege();
          });
      } else if (this.flagCreateModify == "modify") {
        delete payload.group;
        delete payload.count;
        delete payload.created_time;
        delete payload.group_name;
        payload.analysis = this.validateSwitch(payload.analysis);
        payload.configuration = this.validateSwitch(payload.configuration);
        payload.management = this.validateSwitch(payload.management);
        payload.search = this.validateSwitch(payload.search);
        payload.system = this.validateSwitch(payload.system);
        payload.url = "/group/modify";
        axios
          .post(PTestApi, payload)
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.warn("oops: ", err);
          })
          .finally(() => {
            this.flagCreateModify = "";
            this.groupPermissionsDialog = false;
            this.getGroups();
            this.getRecentPrivilege();
          });
      } else {
        console.warn("Incorrect parameter in flag..");
      }
    },
  },

  mounted() {
    this.$store.commit("closeContextMenu");
    this.getUsers();
    this.getGroups();
    this.getRecentUserGroup();
    this.getRecentPrivilege();
    this.getRecentPassword();
    this.getRecentLoginFail();
    this.getPasswordExpire();
  },
};
</script>

<style scoped>
.v-expansion-panel-content /deep/ .v-expansion-panel-content__wrap {
  padding: 0 12px 8px !important;
}

.v-text-field /deep/ .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
}

.v-data-table
  /deep/
  .v-data-table__wrapper
  /deep/
  table
  /deep/
  thead
  /deep/
  tr
  /deep/
  th {
  padding: 0 12px !important;
  margin-right: 2px !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 6px;
}

.v-data-table /deep/ .v-data-table__wrapper > table {
  border-bottom: solid 1px gainsboro;
}

.theme--light.v-data-table
  /deep/
  .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.expansion-cardbox {
  height: 180px;
  overflow-y: auto;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>
