<template>
  <v-app>
    <div>
      <v-navigation-drawer
        app
        clipped
        hide-overlay
        mobile-breakpoint=""
        :mini-variant="menuMini"
        width=""
        v-if="isLogged && drawer"
        mini-variant-width="48"
        class="d-flex flex-column justify-space-between"
      >
        <v-list dense>
          <v-list-item
            v-for="item in menuItems"
            v-tooltip="{
              content: item.msg,
              boundariesElement: 'body',
              offset: 0,
              placement: 'right-start',
            }"
            :key="item.title"
            :to="item.href"
            @mouseover="hoverMenu = item"
            @mouseleave="hoverMenu = false"
            active-class="highlighted"
            :class="item.href === $route.path ? 'highlighted' : ''"
            @click="resetError()"
          >
            <v-list-item-icon>
              <v-icon size="20" color="grey darken-2">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="mr-xl-2 mr-md-1">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-divider></v-divider>
          <v-list dense class="pt-0">
            <v-list-item link>
              <v-list-item-icon @click="collapseMenu()">
                <v-icon v-if="menuMini">mdi-chevron-double-right</v-icon>
                <v-icon v-else>mdi-chevron-double-left</v-icon>
              </v-list-item-icon>

              <v-list-item-content @click="collapseMenu()">
                <v-list-item-title>Collapse</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <div>
                  <v-btn
                    icon
                    style="width: 18px; height: 18px"
                    @click="lockedMenu = !lockedMenu"
                  >
                    <v-icon size="110%" color="grey darken-1">mdi-lock</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </div>
    <v-navigation-drawer
      :value="contextMenu"
      disable-resize-watcher
      clipped
      app
      right
      hide-overlay
      width=""
      class="context-menu"
      style="border-left: 0.5px solid #cfd8dc"
    >
      <template>
        <v-card-text
          style="
            background-color: rgba(255, 255, 255, 0.4) !important;
            height: 100%;
          "
        >
          <div v-if="contextData.loading" id="context-loading" class="d-flex">
            <div class="mx-auto my-auto pb-5">
              <div class="font-weight-bold pt-4">Loading ...</div>
            </div>
          </div>
          <div style="width: 260px" v-if="!contextData.loading">
            <div style="text-align: right">
              <v-btn
                icon
                color="gray"
                small
                @click="$store.commit('closeContextMenu')"
              >
                <v-icon size="200%">mdi-close-thick</v-icon>
              </v-btn>
            </div>
            <div class="mt-1">
              <h3
                class="font-italic font-weight-bold"
                style="border-bottom: 1px solid #d3d4e6 !important"
              >
                {{ contextData.title }}
              </h3>
            </div>
            <div v-if="contextData.listTitle" class="mt-4" style="width: 100%">
              <v-row class="pt-2 pl-3">
                <span
                  ><h2 class="font-weight-medium">
                    {{ contextData.listTitle.element }}:
                  </h2></span
                >
                <span
                  ><h2 class="font-weight-medium text-capitalize ml-1">
                    {{ contextData.listTitle.value }}
                  </h2></span
                >
              </v-row>
            </div>
            <div v-if="contextData.listTitle2" class="mt-2" style="width: 100%">
              <v-row class="pt-2 pl-3">
                <span
                  ><h2 class="font-weight-medium">
                    {{ contextData.listTitle2.element }}:
                  </h2></span
                >
                <span
                  ><h2 class="font-weight-medium text-capitalize ml-1">
                    {{ contextData.listTitle2.value }}
                  </h2></span
                >
              </v-row>
            </div>
            <div v-if="contextData.list" class="mt-6">
              <div
                v-for="(item, index) in contextData.list"
                :key="index"
                class="ml-4 font-weight-medium"
              >
                <span><v-icon dense>mdi-circle-medium</v-icon></span>
                <span class="text-capitalize">{{ item.element }} : </span>
                <span>{{ item.value }}</span>
              </div>
            </div>

            <div class="mt-4" v-if="contextData.linkedListTitle">
              <h3 class="font-weight-medium">
                {{ contextData.linkedListTitle }}:
              </h3>
            </div>
            <div class="mt-3" v-if="contextData.linkedList">
              <v-data-table
                hide-default-footer
                hide-default-header
                dense
                disable-pagination
                :headers="[{ value: 'element' }, { value: 'value' }]"
                :items="contextData.linkedList"
                class="theme--light"
                height="400"
                style="
                  border-top: solid 1px gainsboro;
                  border-bottom: solid 1px gainsboro;
                "
              >
                <template v-slot:[`item.value`]="{ item }">
                  <div class="d-flex">
                    {{ item.value }}
                    <span class="ml-auto papa-ghost">
                      <span class="ghost-icon">
                        <v-btn icon color="Gray" x-small :href="item.href">
                          <v-icon small>mdi-arrow-right-drop-circle</v-icon>
                        </v-btn>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card-text>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="blue-grey lighten-4"
      dense
      light
      elevation="1"
      id="main-bar"
      v-if="isLogged"
    >
      <v-btn
        plain
        class="ml-n3"
        style="background: unset"
        :to="'/status/'"
        :ripple="false"
        src="@/assets/logo.png"
        @click="resetError()"
      >
        <v-img
          class="mb-1"
          height="30"
          max-width="68"
          contain
          src="@/assets/logo.png"
        >
        </v-img>
      </v-btn>
      <template>
        <div v-if="timeoutWarningZone">testtest</div>
        <div style="width: 100%; height: 100%" class="text-left">
          <v-card
            elevation="0"
            style="background: none"
            width="100%"
            height="100%"
          >
            <v-card-text
              color="black"
              style="font-weight: bolder; padding: 0; width: 100%; height: 100%"
            >
              <div style="width: 100%; height: 100%">
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="cr_totalText"
                >
                  <span class="Dicon" v-show="cr_totalText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ cr_totalText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="hover"
                >
                  <span class="Dicon" v-show="hover"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="text-align: left">
                    {{ hoverMenu.helpD }}</span
                  >
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="current_riskText"
                >
                  <span class="Dicon" v-show="current_riskText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ current_riskText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="cr_totalscoreText"
                >
                  <span class="Dicon" v-show="cr_totalscoreText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ cr_totalscoreText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="cr_vulnscoreText"
                >
                  <span class="Dicon" v-show="cr_vulnscoreText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ cr_vulnscoreText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="cr_threatscoreText"
                >
                  <span class="Dicon" v-show="cr_threatscoreText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ cr_threatscoreText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="cr_vulnText"
                >
                  <span class="Dicon" v-show="cr_vulnText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ cr_vulnText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="cr_threatText"
                >
                  <span class="Dicon" v-show="cr_threatText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ cr_threatText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_targetsText"
                >
                  <span class="Dicon" v-show="gr_targetsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_targetsText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_activitiesText"
                >
                  <span class="Dicon" v-show="gr_activitiesText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_activitiesText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_openportsText"
                >
                  <span class="Dicon" v-show="gr_openportsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_openportsText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_autonomousText"
                >
                  <span class="Dicon" v-show="gr_autonomousText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_autonomousText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_threatDBText"
                >
                  <span class="Dicon" v-show="gr_threatDBText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_threatDBText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_vulncountText"
                >
                  <span class="Dicon" v-show="gr_vulncountText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_vulncountText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gr_cvecountText"
                >
                  <span class="Dicon" v-show="gr_cvecountText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ gr_cvecountText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="topStatusText"
                >
                  <span class="Dicon" v-show="topStatusText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ topStatusText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="topRiskDevicesText"
                >
                  <span class="Dicon" v-show="topRiskDevicesText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ topRiskDevicesText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="queryConditionsText"
                >
                  <span class="Dicon" v-show="queryConditionsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ queryConditionsText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="riskTimelineText"
                >
                  <span class="Dicon" v-show="riskTimelineText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ riskTimelineText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rt_reloadText"
                >
                  <span class="Dicon" v-show="rt_reloadText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rt_reloadText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="riskMapText"
                >
                  <span class="Dicon" v-show="riskMapText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ riskMapText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rm_csvText"
                >
                  <span class="Dicon" v-show="rm_csvText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rm_csvText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rm_imgeText"
                >
                  <span class="Dicon" v-show="rm_imgeText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rm_imgeText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rm_deviceCountText"
                >
                  <span class="Dicon" v-show="rm_deviceCountText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rm_deviceCountText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rm_countryText"
                >
                  <span class="Dicon" v-show="rm_countryText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rm_countryText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rm_lat_lonText"
                >
                  <span class="Dicon" v-show="rm_lat_lonText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rm_lat_lonText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="rm_mapText"
                >
                  <span class="Dicon" v-show="rm_mapText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ rm_mapText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="searchBoxText"
                >
                  <span class="Dicon" v-show="searchBoxText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ searchBoxText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="searchResultsText"
                >
                  <span class="Dicon" v-show="searchResultsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ searchResultsText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_summaryText"
                >
                  <span class="Dicon" v-show="s_summaryText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_summaryText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_vulnText"
                >
                  <span class="Dicon" v-show="s_vulnText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ s_vulnText }}</span
                  >
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_osintText"
                >
                  <span class="Dicon" v-show="s_osintText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_osintText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_traceText"
                >
                  <span class="Dicon" v-show="s_traceText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_traceText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_gptText"
                >
                  <span class="Dicon" v-show="s_gptText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_gptText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_levelText"
                >
                  <span class="Dicon" v-show="s_levelText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_levelText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_flagsText"
                >
                  <span class="Dicon" v-show="s_flagsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_flagsText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_ASNumberNameText"
                >
                  <span class="Dicon" v-show="s_ASNumberNameText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_ASNumberNameText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_upText"
                >
                  <span class="Dicon" v-show="s_upText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_upText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_scantimeText"
                >
                  <span class="Dicon" v-show="s_scantimeText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ s_scantimeText }}</span
                  >
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_iconVSText"
                >
                  <span class="Dicon" v-show="s_iconVSText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_iconVSText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="s_iconTSText"
                >
                  <span class="Dicon" v-show="s_iconTSText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ s_iconTSText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="status_eventText"
                >
                  <span class="Dicon" v-show="status_eventText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ status_eventText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="totalUsersText"
                >
                  <span class="Dicon" v-show="totalUsersText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ totalUsersText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="userAdminsText"
                >
                  <span class="Dicon" v-show="userAdminsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ userAdminsText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="userActiveText"
                >
                  <span class="Dicon" v-show="userActiveText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ userActiveText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="userInactiveText"
                >
                  <span class="Dicon" v-show="userInactiveText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ userInactiveText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="totalGroupsText"
                >
                  <span class="Dicon" v-show="totalGroupsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ totalGroupsText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="groupAdminsText"
                >
                  <span class="Dicon" v-show="groupAdminsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ groupAdminsText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="groupActiveText"
                >
                  <span class="Dicon" v-show="groupActiveText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ groupActiveText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="groupInactiveText"
                >
                  <span class="Dicon" v-show="groupInactiveText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ groupInactiveText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="recentCreareText"
                >
                  <span class="Dicon" v-show="recentCreareText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ recentCreareText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="recentChangePrivilegeText"
                >
                  <span class="Dicon" v-show="recentChangePrivilegeText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ recentChangePrivilegeText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="recentChangePasswordText"
                >
                  <span class="Dicon" v-show="recentChangePasswordText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ recentChangePasswordText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="userAccountsLoginText"
                >
                  <span class="Dicon" v-show="userAccountsLoginText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ userAccountsLoginText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="userAccountPasswordText"
                >
                  <span class="Dicon" v-show="userAccountPasswordText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ userAccountPasswordText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="userTabText"
                >
                  <span class="Dicon" v-show="userTabText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ userTabText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="groupTabText"
                >
                  <span class="Dicon" v-show="groupTabText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ groupTabText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ut_searchUserText"
                >
                  <span class="Dicon" v-show="ut_searchUserText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ ut_searchUserText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ut_addUserText"
                >
                  <span class="Dicon" v-show="ut_addUserText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ ut_addUserText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gt_searchGroupText"
                >
                  <span class="Dicon" v-show="gt_searchGroupText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ gt_searchGroupText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="gt_addGroupText"
                >
                  <span class="Dicon" v-show="gt_addGroupText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ gt_addGroupText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="system_statusText"
                >
                  <span class="Dicon" v-show="system_statusText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ system_statusText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_nText"
                >
                  <span class="Dicon" v-show="ss_nText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto"> {{ ss_nText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_bText"
                >
                  <span class="Dicon" v-show="ss_bText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto"> {{ ss_bText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_tabCpuText"
                >
                  <span class="Dicon" v-show="ss_tabCpuText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ ss_tabCpuText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_tabMemoryText"
                >
                  <span class="Dicon" v-show="ss_tabMemoryText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ ss_tabMemoryText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_tabStorageText"
                >
                  <span class="Dicon" v-show="ss_tabStorageText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ ss_tabStorageText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_tabNetworkText"
                >
                  <span class="Dicon" v-show="ss_tabNetworkText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ ss_tabNetworkText }}</span>
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="ss_cvsText"
                >
                  <span class="Dicon" v-show="ss_cvsText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD"> {{ ss_cvsText }}</span>
                </div>

                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="se_recentAlertText"
                >
                  <span class="Dicon" v-show="se_recentAlertText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ se_recentAlertText }}</span
                  >
                </div>
                <div
                  class="test"
                  color="black"
                  style="font-weight: bolder; width: 100%"
                  v-show="se_recentRegularText"
                >
                  <span class="Dicon" v-show="se_recentRegularText"
                    ><v-icon size="180%">mdi-alert-circle</v-icon></span
                  >
                  <span class="textD" style="width: auto">
                    {{ se_recentRegularText }}</span
                  >
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </template>
      <v-spacer></v-spacer>

      <span
        class="px-2 font-weight-bold mt-2 text-uppercase"
        style="width: 216px; text-align: right"
      >
        {{ userData.id }}
      </span>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="40">
              <img :src="userIMG" alt="No PICTURE" v-bind="attrs" v-on="on" />
            </v-avatar>
          </template>

          <v-list>
            <v-subheader
              >Last Login:
              {{ userData.last_login || "First Login! &#129351;" }}</v-subheader
            >
            <v-divider></v-divider>
            <v-list-item-group color="primary">
              <v-list-item @click="editUser()">
                <v-list-item-icon style="margin-right: 16px">
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-left">
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-icon style="margin-right: 16px">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-left">
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <div
        @click="clickMenu"
        style="
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
        "
        v-if="displayDialog"
      >
        <img
          style="min-width: 25px; min-height: 25px"
          src="@/assets/menu.svg"
        />
      </div>
    </v-app-bar>
    <v-main v-bind:class="[lockedMenu ? 'locked-menu' : '']">
      <error v-if="testError && showErrors == 'true'"></error>
      <router-view> </router-view>
      <un-auth-vue v-if="unAuth"></un-auth-vue>
      <pwalert @show-dialog="pw_change"></pwalert>
      <v-row justify="center">
        <v-dialog v-model="editUserDialog" persistent max-width="600px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <img
                      height="120"
                      width="120"
                      v-if="editUserData.avatar"
                      :src="editUserData.avatar"
                    />
                    <v-file-input
                      :rules="[
                        (value) =>
                          !value ||
                          value.size < 2000000 ||
                          'Avatar size should be less than 2 MB!',
                      ]"
                      accept="image/*"
                      label="Avatar"
                      prepend-icon="mdi-camera"
                      @change="fileChange"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Username"
                      disabled
                      v-model="editUserData.id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Current Password"
                      v-model="editUserData.oldPassword"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="New Password"
                      v-model="editUserData.newPassword"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Confirm New Password"
                      v-model="editUserData.confirmNewPassword"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Name"
                      disabled
                      v-model="editUserData.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email"
                      v-model="editUserData.email"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Group"
                      disabled
                      v-model="editUserData.group"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Organization"
                      v-model="editUserData.organization"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="countries"
                      item-text="name"
                      item-value="name"
                      label="Country"
                      dense
                      v-model="editUserData.country"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      label="Email Notifications"
                      color="green darken-2"
                      disabled
                      v-model="editUserData.email_noti"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      disabled
                      label="Start Date"
                      v-model="editUserData.start_time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      disabled
                      label="End Date"
                      v-model="editUserData.end_time"
                    ></v-text-field>
                  </v-col>
                  <v-card
                    v-if="error"
                    class="pa-2 mb-2"
                    outlined
                    elevation="1"
                    dark
                    color="red"
                  >
                    Incorrect Old Password, OTP or no matching New Passwword
                  </v-card>
                  <div>
                    <custom-qr
                      :key="'1' + editUserData.id"
                      :userData="editUserData"
                    ></custom-qr>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="editUserDialog = false"
              >
                Close
              </v-btn>
              <v-btn color="green darken-1" text @click="updateUser()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import Error from "@/components/TestModeError.vue";
import customQr from "@/components/charts/QR.vue";
import EventBus from "@/plugins/EventBus.js";
import axios from "axios";
import dayjs from "dayjs";
import { countries, apiDateFormat, PTestApi } from "@/mixins/commons";
import pwalert from "@/views/pwalert.vue";
import unAuthVue from './views/unAuth.vue';

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default {
  name: "App",

  components: {
    Error,
    customQr,
    pwalert,
    unAuthVue
  },

  data: () => ({
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    groups: [],
    countries: countries,
    show: false,
    drawer: true,
    info: null,
    menuHelp: false,
    hoverMenu: [],
    editUserDialog: false,
    error: false,
    defaultMenuItems: [
      {
        title: `Status`,
        icon: `mdi-monitor-dashboard`,
        href: `/status`,
        msg: `Status`,
        id: `any`,
        helpD: `This menu provides a summary of important information that you need to know, and the information is based on the vulnerability scan and threat scan results.`,
      },
      {
        title: `Timeline`,
        icon: `mdi-map-clock`,
        href: `/timeline`,
        msg: `Timeline`,
        id: `any`,
        helpD: `This menu is a page that provides analytical information on scan results for each major situation that information security administrators should carefully observe or know from a time series perspective and a geographical point of view.`,
      },
      {
        title: `Analysis`,
        icon: `mdi-chart-bar`,
        href: `/analysis`,
        msg: `Analysis`,
        id: `analysis`,
        helpD: `This menu is for advanced users, and it is a page that provides a user-defined analysis that allows the user to grasp the scan results from various viewpoints by selecting a given variable or changing a variable value.`,
      },
      {
        title: `Search`,
        icon: `mdi-magnify`,
        href: `/search`,
        msg: `Search`,
        id: `search`,
        helpD: `This is a menu that allows you to search the most detailed results for the scan results and provides detailed search functions such as IP devices or vulnerabilities using about 60 search keywords.`,
      },
      {
        title: `Management`,
        icon: `mdi-account-cog`,
        href: `/management`,
        msg: `User&Group`,
        id: `management`,
        helpD: `This menu provides a function for the system administrator to set or search about user accounts and group creation/login/change/deletion/authorization management/notification history.`,
      },
      {
        title: `System`,
        icon: `mdi-tune`,
        href: `/settings`,
        msg: `System`,
        id: `system`,
        helpD: `This menu provides the system administrator with the resource (CPU, Memory, Disk, Network Traffic) status information of the operating systems for service provision.`,
      },
      {
        title: `Configuration`,
        icon: `mdi-database-cog`,
        href: `/configuration`,
        msg: `Configuration`,
        id: `configuration`,
        helpD: `This menu provides the system administrator with the target IP list, scan history, data history of major databases (Vulnerability Database, OSINT Threat Database), and backup history.`,
      },
      {
        title: `Help`,
        icon: `mdi-help-circle-outline`,
        href: `/help`,
        msg: `Help`,
        id: `any`,
        helpD: `This menu provides a help function for learning the basic understanding of the system and how to use it.`,
      },
    ],
    lockedMenu: false,
    editUserData: {},
    current_riskText: "",
    cr_totalscoreText: "",
    cr_vulnscoreText: "",
    cr_threatscoreText: "",
    cr_totalText: "",
    cr_vulnText: "",
    cr_threatText: "",
    gr_targetsText: "",
    gr_activitiesText: "",
    gr_openportsText: "",
    gr_autonomousText: "",
    gr_threatDBText: "",
    gr_vulncountText: "",
    gr_cvecountText: "",
    topStatusText: "",
    topRiskDevicesText: "",
    queryConditionsText: "",
    riskTimelineText: "",
    rt_reloadText: "",
    riskMapText: "",
    rm_csvText: "",
    rm_imgeText: "",
    rm_deviceCountText: "",
    rm_countryText: "",
    rm_lat_lonText: "",
    rm_mapText: "",
    searchBoxText: "",
    searchResultsText: "",
    s_summaryText: "",
    s_vulnText: "",
    s_osintText: "",
    s_traceText: "",
    s_gptText: "",
    s_levelText: "",
    s_flagsText: "",
    s_ASNumberNameText: "",
    s_upText: "",
    s_scantimeText: "",
    s_iconVSText: "",
    s_iconTSText: "",
    totalUsersText: "",
    status_eventText: "",
    userAdminsText: "",
    userActiveText: "",
    userInactiveText: "",
    totalGroupsText: "",
    groupAdminsText: "",
    groupActiveText: "",
    groupInactiveText: "",
    recentCreareText: "",
    recentChangePrivilegeText: "",
    recentChangePasswordText: "",
    userAccountsLoginText: "",
    userAccountPasswordText: "",
    userTabText: "",
    groupTabText: "",
    ut_searchUserText: "",
    ut_addUserText: "",
    gt_searchGroupText: "",
    gt_addGroupText: "",
    system_statusText: "",
    ss_bText: "",
    ss_nText: "",
    ss_cvsText: "",
    ss_tabCpuText: "",
    ss_tabMemoryText: "",
    ss_tabStorageText: "",
    ss_tabNetworkText: "",
    se_recentRegularText: "",
    se_recentAlertText: "",

    // logoutTimer: null,
    timeoutWarningZone: false,
  }),

  mounted() {
    if (this.userData.uid) {
      this.editUserData = this.userData;
    }
    this.getGroups();

    // this.setTimers();  // session timeout 프론트 설정
  },

  created() {
    EventBus.$on("current_riskShow", (res) => {
      this.current_riskText = res;
    });
    EventBus.$on("cr_totalscoreShow", (res) => {
      this.cr_totalscoreText = res;
    });
    EventBus.$on("cr_vulnscoreShow", (res) => {
      this.cr_vulnscoreText = res;
    });
    EventBus.$on("cr_threatscoreShow", (res) => {
      this.cr_threatscoreText = res;
    });
    EventBus.$on("cr_totalShow", (res) => {
      this.cr_totalText = res;
    });
    EventBus.$on("cr_vulnShow", (res) => {
      this.cr_vulnText = res;
    });
    EventBus.$on("cr_threatShow", (res) => {
      this.cr_threatText = res;
    });
    EventBus.$on("gr_targetsShow", (res) => {
      this.gr_targetsText = res;
    });
    EventBus.$on("gr_activitiesShow", (res) => {
      this.gr_activitiesText = res;
    });
    EventBus.$on("gr_openportsShow", (res) => {
      this.gr_openportsText = res;
    });
    EventBus.$on("gr_autonomousShow", (res) => {
      this.gr_autonomousText = res;
    });
    EventBus.$on("gr_threatDBShow", (res) => {
      this.gr_threatDBText = res;
    });
    EventBus.$on("gr_vulncountShow", (res) => {
      this.gr_vulncountText = res;
    });
    EventBus.$on("gr_cvecountShow", (res) => {
      this.gr_cvecountText = res;
    });
    EventBus.$on("topStatusShow", (res) => {
      this.topStatusText = res;
    });
    EventBus.$on("topRiskDevicesShow", (res) => {
      this.topRiskDevicesText = res;
    });
    EventBus.$on("queryConditionsShow", (res) => {
      this.queryConditionsText = res;
    });
    EventBus.$on("riskTimelineShow", (res) => {
      this.riskTimelineText = res;
    });
    EventBus.$on("rt_reloadShow", (res) => {
      this.rt_reloadText = res;
    });
    EventBus.$on("riskMapShow", (res) => {
      this.riskMapText = res;
    });
    EventBus.$on("rm_csvShow", (res) => {
      this.rm_csvText = res;
    });
    EventBus.$on("rm_imgeShow", (res) => {
      this.rm_imgeText = res;
    });
    EventBus.$on("rm_deviceCountShow", (res) => {
      this.rm_deviceCountText = res;
    });
    EventBus.$on("rm_countryShow", (res) => {
      this.rm_countryText = res;
    });
    EventBus.$on("rm_lat_lonShow", (res) => {
      this.rm_lat_lonText = res;
    });
    EventBus.$on("rm_mapShow", (res) => {
      this.rm_mapText = res;
    });
    EventBus.$on("searchBoxShow", (res) => {
      this.searchBoxText = res;
    });
    EventBus.$on("searchResultsShow", (res) => {
      this.searchResultsText = res;
    });
    EventBus.$on("s_summaryShow", (res) => {
      this.s_summaryText = res;
    });
    EventBus.$on("s_vulnShow", (res) => {
      this.s_vulnText = res;
    });
    EventBus.$on("s_osintShow", (res) => {
      this.s_osintText = res;
    });
    EventBus.$on("s_traceShow", (res) => {
      this.s_traceText = res;
    });
    EventBus.$on("s_gptShow", (res) => {
      this.s_gptText = res;
    });
    EventBus.$on("s_levelShow", (res) => {
      this.s_levelText = res;
    });
    EventBus.$on("s_flagsShow", (res) => {
      this.s_flagsText = res;
    });
    EventBus.$on("s_ASNumberNameShow", (res) => {
      this.s_ASNumberNameText = res;
    });
    EventBus.$on("s_upShow", (res) => {
      this.s_upText = res;
    });
    EventBus.$on("s_scantimeShow", (res) => {
      this.s_scantimeText = res;
    });
    EventBus.$on("s_iconVSShow", (res) => {
      this.s_iconVSText = res;
    });
    EventBus.$on("status_eventShow", (res) => {
      this.status_eventText = res;
    });
    EventBus.$on("s_iconTSShow", (res) => {
      this.s_iconTSText = res;
    });
    EventBus.$on("totalUsersShow", (res) => {
      this.totalUsersText = res;
    });
    EventBus.$on("userAdminsShow", (res) => {
      this.userAdminsText = res;
    });
    EventBus.$on("userActiveShow", (res) => {
      this.userActiveText = res;
    });
    EventBus.$on("userInactiveShow", (res) => {
      this.userInactiveText = res;
    });
    EventBus.$on("totalGroupsShow", (res) => {
      this.totalGroupsText = res;
    });
    EventBus.$on("groupAdminsShow", (res) => {
      this.groupAdminsText = res;
    });
    EventBus.$on("groupActiveShow", (res) => {
      this.groupActiveText = res;
    });
    EventBus.$on("groupInactiveShow", (res) => {
      this.groupInactiveText = res;
    });
    EventBus.$on("recentCreareShow", (res) => {
      this.recentCreareText = res;
    });
    EventBus.$on("recentChangePrivilegeShow", (res) => {
      this.recentChangePrivilegeText = res;
    });
    EventBus.$on("recentChangePasswordShow", (res) => {
      this.recentChangePasswordText = res;
    });
    EventBus.$on("userAccountsLoginShow", (res) => {
      this.userAccountsLoginText = res;
    });
    EventBus.$on("userAccountPasswordShow", (res) => {
      this.userAccountPasswordText = res;
    });
    EventBus.$on("userTabShow", (res) => {
      this.userTabText = res;
    });
    EventBus.$on("groupTabShow", (res) => {
      this.groupTabText = res;
    });
    EventBus.$on("ut_searchUserShow", (res) => {
      this.ut_searchUserText = res;
    });
    EventBus.$on("ut_addUserShow", (res) => {
      this.ut_addUserText = res;
    });
    EventBus.$on("gt_searchGroupShow", (res) => {
      this.gt_searchGroupText = res;
    });
    EventBus.$on("gt_addGroupShow", (res) => {
      this.gt_addGroupText = res;
    });
    EventBus.$on("system_statusShow", (res) => {
      this.system_statusText = res;
    });
    EventBus.$on("ss_nShow", (res) => {
      this.ss_nText = res;
    });
    EventBus.$on("ss_bShow", (res) => {
      this.ss_bText = res;
    });
    EventBus.$on("ss_tabCpuShow", (res) => {
      this.ss_bText = res;
    });
    EventBus.$on("ss_tabMemoryShow", (res) => {
      this.ss_bText = res;
    });
    EventBus.$on("ss_tabStorageShow", (res) => {
      this.ss_bText = res;
    });
    EventBus.$on("ss_tabNetworkShow", (res) => {
      this.ss_bText = res;
    });
    EventBus.$on("ss_cvsShow", (res) => {
      this.ss_bText = res;
    });
    EventBus.$on("se_recentAlertShow", (res) => {
      this.se_recentAlertText = res;
    });
    EventBus.$on("se_recentRegularShow", (res) => {
      this.se_recentRegularText = res;
    });
  },

  computed: {
    unAuth(){
      return this.$store.state.unAuth;
    },
    hover() {
      return this.hoverMenu.helpD;
    },
    menuMini() {
      return this.$store.state.menuMini;
    },
    isLogged() {
      return this.$store.state.isLogged;
    },
    contextMenu() {
      return this.$store.state.contextMenu;
    },
    contextData() {
      return this.$store.state.contextData;
    },
    testError() {
      return this.$store.state.testError.show;
    },
    showErrors() {
      return process.env.VUE_APP_SHOW_ERRORS;
    },
    userData() {
      return this.$store.state.userData;
    },
    userIMG() {
      if (this.userData.avatar) {
        return this.userData.avatar;
      }
      return "./img/default.png";
    },
    userMenu() {
      return this.groups.filter((x) => x.group_name == this.userData.group)[0];
    },
    menuItems() {
      if (this.userMenu) {
        return this.defaultMenuItems.filter(
          (x) => x.id == "any" || this.userMenu[x.id] > 0
        );
      } else {
        return this.defaultMenuItems.filter((x) => x.id == "any");
      }
    },
    displayDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case `xs`:
          return true;
        case `sm`:
          return true;
        default:
          return false;
      }
    },
  },

  watch: {
    isLogged: function (val) {
      if (!val) {
        // console.log('Logged out: ', !val);
        this.$router.push("login").catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.warn(err);
          }
        });
      }
    },
    userData: function (val) {
      this.editUserData = val;
    },
    displayDialog: function (val) {
      if (val) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
  },

  methods: {
    clickMenu: function () {
      this.drawer = !this.drawer;
      this.lockedMenu = false;
    },
    resetError: function () {
      this.$store.commit("dismissTestError");
    },
    pw_change: function () {
      this.editUserDialog = true;
    },

    // setTimers: function () {
    //   this.logoutTimer = setTimeout(this.logout, 4 * 60 * 60 * 1000); // 15 minutes - 15 * 60 * 1000
    //   this.timeoutWarningZone = false;
    // }, //// session timeout 프론트 설정 2
    warningMessage: function () {
      this.timeoutWarningZone = false;
    },

    async fileChange(e) {
      if (e) {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        this.editUserData.avatar = await toBase64(e);
      } else {
        this.editUserData.avatar = null;
      }
    },

    collapseMenu: function () {
      this.$store.commit("menuMiniChange");
      if (this.menuMini) {
        this.lockedMenu = false;
      }
    },
    logout: function () {
      localStorage.removeItem("jwt");
      this.$store.commit("logout");
      this.$store.commit("unsetUser");
      this.$store.commit("notFirstLogin");
      this.$store.commit("closeContextMenu");
      this.$router.push("login");
    },
    editUser: function () {
      this.editUserDialog = true;
      this.editUserDialog;
    },
    getGroups: function () {
      axios
        .get(`${PTestApi}/group/findAll`)
        .then((response) => {
          this.groups = response.data;
          if (this.userData?.uid) {
            this.$store.commit("setGroupPermission", response.data);
          }
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
    updateUser: function () {
      this.error = false;
      this.loading = true;
      if (
        this.editUserData.newPassword != this.editUserData.confirmNewPassword ||
        this.editUserData.newPassword == "" ||
        this.editUserData.oldPassword == "" ||
        this.editUserData.confirmNewPassword == ""
      ) {
        this.loading = false;
        this.error = true;
        this.editUserData = this.userData;
        this.editUserData.oldPassword = "";
        this.editUserData.newPassword = "";
        this.editUserData.confirmNewPassword = "";
      } else {
        axios
          .post(PTestApi, {
            id: this.editUserData.id,
            password: this.editUserData.oldPassword,
            url: `/user/login`,
          })
          .then((response) => {
            if (response.data[0]) {
              this.editUserData.password = this.editUserData.newPassword;
              var user = this.editUserData;
              user.start_time = dayjs
                .utc(user.start_time)
                .format(apiDateFormat);
              user.end_time = dayjs.utc(user.end_time).format(apiDateFormat);
              user.url = "/user/modify";
              axios
                .post(PTestApi, user)
                .then((response) => {
                  console.log(response.data);
                  this.editUserDialog = false;
                })
                .catch((err) => {
                  console.warn("oops: ", err);
                })
                .finally(() => {
                  this.editUserData.oldPassword = "";
                  this.editUserData.newPassword = "";
                  this.editUserData.confirmNewPassword = "";
                  this.$store.commit("setUser", user);
                });
            } else {
              this.error = true;
            }
          })
          .catch((error) => {
            console.error(error.response);
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
.v-main {
  background-color: #fcfcfc;
}
.v-main__wrap {
  min-height: 91vh;
}
#main-bar {
  left: 0 !important;
}
html {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
.context-menu {
  background-color: rgba(255, 255, 255, 0.65);
}
#context-loading {
  height: 100%;
  width: 260px;
}
.locked-menu {
  margin-left: 140px;
}
</style>

<style scoped>
.textD {
  width: 95%;
  line-height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.Dicon {
  width: max-content;
  height: max-content;
  margin-right: 5px;
}

.theme--light.v-data-table
  /deep/
  .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.v-data-table--dense /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  height: 28px !important;
}
</style>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;
  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
    margin-top: 7px;
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 0px;
    border-color: black;
    z-index: 1;
  }
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
    margin-top: 5px;
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="right"] {
    margin-left: 5px;
    .tooltip-arrow {
      border-width: 7px 7px 7px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[x-placement^="left"] {
    margin-right: 5px;
    .tooltip-arrow {
      border-width: 7px 0 7px 7px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
  }
  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
  }
  &.info {
    $color: rgba(#004499, 0.9);
    .tooltip-inner {
      background: $color;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }
    .tooltip-arrow {
      border-color: $color;
    }
  }
  &.popover {
    $color: #f9f9f9;
    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }
    .popover-arrow {
      border-color: $color;
    }
  }
}
</style>
<style scoped>
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  /deep/
  .v-btn__content {
  opacity: 100;
}
.test {
  height: 100%;
  display: flex;
  align-items: center;
}
.v-navigation-drawer /deep/ .v-navigation-drawer__content {
  height: 650px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child{
  margin-right: 0px !important;
}
</style>
