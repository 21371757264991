import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import sharedMutations from 'vuex-shared-mutations'
import dayjs from 'dayjs'

// import axios from "axios";
// import VueCookies from 'vue-cookies';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // accessToken: null,
    // refreshToken: null,
    unAuth:false,
    firstTimeLogin: false,
    menuMini: true,
    isLogged: false,
    contextMenu: false,
    groupPermission: {
      status: 1,
      timeline: 1,
      help: 1
    },
    userData: {
      uid: undefined,
      id: undefined,
      password: undefined,
      name: undefined,
      group: undefined,
      status: undefined,
      created_time: undefined,
      last_login: undefined,
      login_count: undefined,
      email: undefined,
      organization: undefined,
      country: undefined,
      expire_date: undefined,
      secret: undefined,
      email_noti: undefined,
      start_time: undefined,
      end_time: undefined,
      url: undefined,
    },
    contextData: {
      loading: true,
      linkedList: [],
      list: []
    },
    timeline: {
      startDate: dayjs().subtract(3, 'month'),
      endDate:  dayjs(),
      topic: `up`
    },
    testError: {
      show: false,
      msg: ``
    }
  },

  // getters: {
  //   getToken (){
  //     let ac = VueCookies.get('accessToken');
  //     let rf = VueCookies.get('refreshToken');
  //     return {        
  //       access: ac,
  //       refresh: rf
  //     };
  //   }
  // },

  mutations: {
    // loginToken (state, payload) {
    //   VueCookies.set('accessToken', payload.accessToken, '60s');
    //   VueCookies.set('refreshToken', payload.refreshToken, '1h');
    //   state.accessToken = payload.accessToken;
    //   state.refreshToken = payload.refreshToken;
    // },
    // refreshToken(state, payload) { //accessToken 재셋팅
    //   VueCookies.set('accessToken', payload.accessToken, '60s');
    //   VueCookies.set('refreshToken', payload.refreshToken, '1h');
    //   state.accessToken = payload;
    // },
    // removeToken (state) {
    //   VueCookies.remove('accessToken');
    //   VueCookies.remove('refreshToken');
    //   localStorage.removeItem('jwt');
    //   state.isLogged = false;
    //   state.userData = {
    //     uid: undefined,
    //     id: undefined,
    //     password: undefined,
    //     name: undefined,
    //     group: undefined,
    //     status: undefined,
    //     created_time: undefined,
    //     last_login: undefined,
    //     login_count: undefined,
    //     email: undefined,
    //     organization: undefined,
    //     country: undefined,
    //     expire_date: undefined,
    //     secret: undefined,
    //     email_noti: undefined,
    //     start_time: undefined,
    //     end_time: undefined
    //   };
    //   state.firstTimeLogin = false;
    //   state.contextMenu = false;
    //   state.contextData = {
    //     loading: true,
    //     linkedList: [],
    //     list: []
    //   };

    // },
    setUnAuth(state){
      state.unAuth = true;
    },
    unsetUnAuth(state){
      state.unAuth = false;
    },
    menuMiniChange (state) {
      state.menuMini = !state.menuMini
    },
    setUser(state, payload){
      state.userData = payload;
    },
    unsetUser(state){
      state.userData = {
        uid: undefined,
        id: undefined,
        password: undefined,
        name: undefined,
        group: undefined,
        status: undefined,
        created_time: undefined,
        last_login: undefined,
        login_count: undefined,
        email: undefined,
        organization: undefined,
        country: undefined,
        expire_date: undefined,
        secret: undefined,
        email_noti: undefined,
        start_time: undefined,
        end_time: undefined
      }
    },
    login (state) {
      state.isLogged = true
    },
    firstLogin (state) {
      state.firstTimeLogin = true
    },
    notFirstLogin (state) {
      state.firstTimeLogin = false
    },
    logout (state) {
      state.isLogged = false
    },
    openContextMenu (state) {
      state.contextMenu = true
    },
    closeContextMenu (state) {
      state.contextMenu = false;
      state.contextData = {
        loading: true,
        linkedList: [],
        list: []
      }
    },
    setContextData (state, payload) {
      state.contextData = payload;
      // console.log(state.contextData);
      state.contextMenu = true;
    },
    setTimelineDates (state, payload){
      state.timeline.startDate = payload.startDate;
      state.timeline.endDate = payload.endDate;
    },
    setTimelineTopic (state, payload){
      state.timeline.topic = payload;
    },
    triggerTestError (state, payload){
      state.testError.show = true;
      state.testError.msg = payload;
    },
    dismissTestError (state){
      state.testError.show = false;
      state.testError.msg = ``;
    },
    timelineParams(state, payload){
      state.timeline.topic = payload;
    },
    setGroupPermission (state, payload){
      const usergroup = payload.filter(x => x.group_name == state.userData.group)
      if (usergroup.length > 0){
        state.groupPermission = usergroup[0];
        state.groupPermission.status= 1,
        state.groupPermission.timeline= 1,
        state.groupPermission.help= 1
      }
    }
  },
  actions: {
    // refreshToken: ({commit}) => { // accessToken 재요청
    //   //accessToken 만료로 재발급 후 재요청시 비동기처리로는 제대로 처리가 안되서 promise로 처리함
    //   return new Promise((resolve, reject) => {
    //     axios.post('/php-conn.php').then(res => {
    //       commit('refreshToken', res.data.auth_info);
    //       resolve(res.data.auth_info);
    //     }).catch(err => {
    //       console.log('refreshToken error : ', err.config);
    //       reject(err.config.data);
    //     })
    //   })
    // },
    // logout: ({commit}) => { // 로그아웃
    //   commit('removeToken');
    //   location.reload();
    // }
  },
  modules: {
  },

  


  plugins: [new VuexPersistence().plugin, sharedMutations({ predicate: ['logout', 'login', 'firstLogin', 'notFirstLogin'] })]
})
