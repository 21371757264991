<template>
    <div class="pa-4">
        <div class="gptreport">
            <div style="height: 22px; font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" > 
                <div style="float: left;">
                    GPT Report
                </div>
                <div v-if="reloadBtnShow" class="reload" @click="getGPTResponse(selectedId)">
                    <img class="reloadImg" src="./image/reload.svg" style="width: 15px; height: 15px;">
                </div>
            </div>
            <v-card
                outlined
                color="#ECEFF1"
                style="border: 1px solid #d3d4e6;margin: 0px 4px;"
                class="mt-1"
            >
                <v-card-text style="width: 100%;">
                    <pre style="width: 100%; white-space: pre-line;">
                        {{ result }}
                    </pre>
                </v-card-text>
            </v-card>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import { index } from '@/mixins/elastic';
import { PTestConn } from "@/mixins/commons";

export default {
    data:()=>({
        result:null,
        reloadBtnShow: false
    }),
    props:{
        selectedId:String
    },
    created(){
        console.log(this.selectedId);
        this.getGPTResponse(this.selectedId);
    },
    methods:{
        getGPTResponse(id){
            this.result=null;
            axios
            .post(PTestConn, {
                queryurl: index.gpt+'/_search',
                querybody:"{\"id\":\""+id+"\"}"
                // querybody:id
            }).then((response)=>{
                if(response.data.hits==undefined){
                    console.log(response);
                    this.reloadBtnShow=true;
                    this.result=response.data.message;
                }else{
                    this.reloadBtnShow=false;
                    this.result=response.data.hits.hits[0]._source.message;
                }
            })
        }
    }
}
</script>

<style>
.reload{
    margin-left: 5px;
    height: 20px;
    width: 20px;
    border-radius: 45px;
    float: left; 
    display: flex;
    justify-content: center; 
    align-items: center;
    filter: invert(40%) sepia(0%) saturate(1667%) hue-rotate(163deg) brightness(94%) contrast(92%);
    /* border: 1px solid black; */
    transition: all ease 0.4s;
}
.reload:hover{
    background-color: rgba(246, 246, 246, 0.2);
}
</style>