<template>
    <v-card :height="MapHeight" elevation="0">
        <div ref="map-root" class="" id="map"
        style="width: 100%; height: 100%">
        </div>
        <div id="popup" class="ol-popup">
            <a href="#" id="popup-closer" class="ol-popup-closer"></a>
            <div id="popup-content"></div>
        </div>
    </v-card>
</template>

<script>
    import View from 'ol/View'
    import Map from 'ol/Map'
    import TileLayer from 'ol/layer/Tile'
    import OSM from 'ol/source/OSM';
    import Feature from 'ol/Feature'
    import { fromLonLat } from 'ol/proj';
    import { globals } from "@/mixins/commons.js";
    import 'ol/ol.css'
    import Circle from 'ol/geom/Circle'
    import CircleStyle from 'ol/style/Circle'
    import Stroke from 'ol/style/Stroke'
    import Style from 'ol/style/Style'
    import Fill from 'ol/style/Fill'
    import VectorLayer from 'ol/layer/Vector'
    import VectorSource from 'ol/source/Vector'

    import {DragPan, MouseWheelZoom, defaults} from 'ol/interaction';
    import {platformModifierKeyOnly} from 'ol/events/condition';

    import Overlay from 'ol/Overlay';
    import dayjs from 'dayjs';
    export default {
        name: 'MapContainer',
        components: {},
        props: {
            zoom: Number,
            minZoom: Number,
            maxZoom: Number,
            center: Array,
            mapData: Array
        },
        data(){
            return{
            }
        },
        methods:{
        },
        created(){
            //접근 기기 구분
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.userAgent='mobile';
            }
        },
        mounted() {
            var container = document.getElementById('popup');
            var content = document.getElementById('popup-content');
            var closer = document.getElementById('popup-closer');

            var overlay = new Overlay({
                element: container,
                autoPan: true,
                autoPanAnimation: {
                    duration: 250,
                },
            });
            function closePopup (){
                overlay.setPosition(undefined);
                closer.blur();
                return false;
            }
            closer.onclick = function () {
                closePopup();
            };

            // this is where we create the OpenLayers map
            const olMap = new Map({
                interactions: defaults({dragPan: false, mouseWheelZoom: false}).extend([
                    new DragPan({
                    condition: function (event) {
                        return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
                    },
                    }),
                    new MouseWheelZoom({
                    condition: platformModifierKeyOnly,
                }) ]),
                // the map will be created using the 'map-root' ref
                target: this.$refs['map-root'],
                layers: [
                // adding a background tiled layer
                    new TileLayer({source: new OSM({url: globals.mapTile, crossOrigin: "Anonymous"})})
                ],
                overlays: [overlay],
                view: new View({
                    zoom: this.zoom,
                    center: fromLonLat(this.center),
                    minZoom: this.minZoom,
                    maxZoom: this.maxZoom
                }),
            });
            const mapData = this.mapData;
            function createLayer( sizeMultiplier, selectedFeature = null ){
                // Bigger is the factor the smaller are the circles (higher zoom you desire smaller circles)
                 let factor = 0.05
                const zoomSize = sizeMultiplier;
                if (zoomSize<4){
                    factor = 0.035;
                }else if(zoomSize >= 4 && zoomSize <8){
                    factor = 0.035;
                }else if(zoomSize >=8 && zoomSize <12){
                    factor = 0.15;
                }else if(zoomSize >= 12 && zoomSize <14){
                    factor = 0.15;
                }else{
                    factor = 2;
                }

                let dynFeatures = mapData.map( function(x){
                    let area = 0;
                    if(x.doc_count < 350){
                        area = 500;
                    }else if(x.doc_count < 750){
                        area = 4000;
                    }else if(x.doc_count < 5000){
                        area = 8000;
                    }else if(x.doc_count >= 15000 ){
                        area = 15000
                    }
                    else{
                        area =  x.doc_count
                    }
                    const tempFeature =  new Feature(new Circle(fromLonLat(x.coord), (area/(factor*sizeMultiplier)) ));
                    tempFeature.attributes = {
                        name: x.key,
                        score: x.doc_count
                    };
                    if (selectedFeature == x.key){
                        const cstStyle = [
                            new Style({
                                image: new CircleStyle({}),
                                stroke: new Stroke({
                                    color: '#3F51B5',
                                    width: 2
                                }),
                                fill: new Fill({
                                    color: 'rgba(63, 81, 181, 0.5)'
                                })
                            })
                        ];
                        tempFeature.setStyle(cstStyle);
                    }
                    return tempFeature
                });

                const layer = new VectorLayer({
                    source: new VectorSource({
                        projection: 'EPSG:4326',
                        features: dynFeatures
                    }),
                    style: [
                        new Style({
                            image: new CircleStyle({}),
                            stroke: new Stroke({
                                color: (selectedFeature)?'#c44141':'#f50707',
                                width: 2
                            }),
                            fill: new Fill({
                                color: (selectedFeature)?'rgba(196, 65, 65, 0.5)':'rgba(245, 7, 7, 0.5)'
                            })
                        })
                    ]
                });
                return layer
            }
            // Initial load of map points
            let zonesLayer = createLayer(this.zoom);
            olMap.addLayer(zonesLayer);

            // listen to map zoom (to calculate sizes for circles again)
            olMap.getView().on('change:resolution', function() {
                olMap.removeLayer(zonesLayer);
                const zoom = olMap.getView().getZoom();
                zonesLayer = createLayer(zoom);
                olMap.addLayer(zonesLayer);
                //console.log(zoom);
            });

            const displayFeatureInfo = function (pixel, coordinate) {
                const feature = olMap.forEachFeatureAtPixel(pixel, function (feature) {
                    return feature;
                });
                if (feature) {
                    content.innerHTML = `<div class="text-capitalize">Country: ${feature.attributes.name}</div><div>Devices: ${feature.attributes.score}</div>`;
                    overlay.setPosition(coordinate);
                } else {
                    closePopup();
                }
            };

            olMap.on('pointermove', function (evt) {
                if (evt.dragging) {
                    closePopup();
                    return;
                }
                displayFeatureInfo(olMap.getEventPixel(evt.originalEvent), evt.coordinate);
            });

            document.getElementById('map-export-png').addEventListener('click', function () {
                olMap.once('rendercomplete', function () {
                    var mapCanvas = document.createElement('canvas');
                    var size = olMap.getSize();
                    mapCanvas.width = size[0];
                    mapCanvas.height = size[1];
                    var mapContext = mapCanvas.getContext('2d');
                    Array.prototype.forEach.call(
                        document.querySelectorAll('.ol-layer canvas'),
                        function (canvas) {
                            if (canvas.width > 0) {
                            var opacity = canvas.parentNode.style.opacity;
                            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                            var transform = canvas.style.transform;
                            // Get the transform parameters from the style's transform matrix
                            var matrix = transform
                                .match(/^matrix\(([^(]*)\)$/)[1]
                                .split(',')
                                .map(Number);
                            // Apply the transform to the export map context
                            CanvasRenderingContext2D.prototype.setTransform.apply(
                                mapContext,
                                matrix
                            );
                            mapContext.drawImage(canvas, 0, 0);
                            }
                        }
                    );
                    const a = document.createElement('a');
                    a.href = mapCanvas.toDataURL();
                    a.download = 'Risk Map '+dayjs().format();
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                });
                olMap.renderSync();
            });
        },
        computed:{
            MapHeight () {
                switch (this.$vuetify.breakpoint.name) {
                    case `xs` : return 315
                    case `sm` : return 315
                    case `md` : return 315
                    case `lg` : return 315
                    case `xl` : return 450
                }
                return 100
            },
            firstLogin() {
                return this.$store.state.firstTimeLogin
            }
        }
    }
</script>

<style scoped>
    .ol-popup {
        position: absolute;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0,0,0,0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 200px;
    }
    .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }
    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
    .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
    }
    .ol-popup-closer:after {
        content: "✖";
    }
</style>