<template>
<div>
    <v-container fluid style="height: 100%;">
        <v-row style="height: 40%;">
            <v-col cols="12" md="3" xl="3" class="pb-2">
                <v-card outlined elevation="1" class="black--text pb-0 " style="min-height: 100%;">
                    <v-card-title class="pb-0 pt-2" @mouseover=" queryConditionsShow " @mouseleave="queryConditionsoff">QUERY CONDITIONS</v-card-title>
                    <v-card-text class="pb-0">
                        <query-conditions></query-conditions>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="9" xl="9" class="pb-2">
                <v-card outlined elevation="1"  class="black--text " style="height:100%;">
                    <v-card-text class="pb-xl-3 pb-md-1 pt-2 ">
                        <risk-timeline :params="timelineParams"></risk-timeline>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row style="height:60%;">
            <v-col class="pt-xl-3" cols="12">
                <v-card outlined elevation="1" class="black--text" style="min-height: 100%;">
                        <risk-map :params="timelineParams"></risk-map>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import QueryConditions from '@/components/timeline/QueryConditions.vue';
import RiskTimeline from '@/components/home/RiskTimeline.vue';
import RiskMap from '@/components/home/RiskMap.vue';
import EventBus from '@/plugins/EventBus.js';
import dayjs from 'dayjs';

export default {
    name: `Timeline`,
    components: {
        QueryConditions,
        RiskTimeline,
        RiskMap
    },
    created(){
        const payload = {
            startDate: dayjs().subtract(3,'months'),
            endDate: dayjs()
        }
        this.$store.commit('setTimelineDates', payload)
    },
    computed: {
        timelineParams: function(){
            return this.$store.state.timeline
        }
    },
    methods:{
        queryConditionsShow: function(){
            EventBus.$emit('queryConditionsShow', `By selecting a given topic in the combo box, entering the query period, and clicking the 'Query' button, the scan result analysis data for a specific issue you need to pay attention to is displayed in a time series graph and map.`);
        },
        queryConditionsoff: function(){
            EventBus.$emit('queryConditionsShow', null);
        },
    }

}
</script>

<style scoped>
 /* @media (max-width: 960px){
        
    .v-application .pt-md-0{
            display: inline-block;
            width: 58%;
            vertical-align: top;
        }
    } */

</style>