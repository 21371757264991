<template>
  <v-card
    class="pa-2 px-4"
    :color="selected ? 'grey lighten-5' : 'blue-grey lighten-5'"
    :elevation="selected ? 1 : 0"
  >
    <div class="host-card-title">
      <span
        class="card-cst-icon"
        v-bind:style="{ backgroundColor: scoreColor(host.score.total_score) }"
        @mouseover="s_levelShow"
        @mouseleave="s_leveloff"
      >
        {{ scoreLetter(host.score.total_score) }}
      </span>
      <span @mouseover="s_levelShow" @mouseleave="s_leveloff">
        ({{ singleDigit(host.score.total_score) }})
      </span>
      <span>
        {{ host.geoip.ip }}
      </span>
      <span>
        (
        {{
          host["xml-nmap"].host[0].hostnames[0].hostname
            ? host["xml-nmap"].host[0].hostnames[0].hostname[0].name
            : "-"
        }}
        )
      </span>
    </div>

    <div class="pt-3">
      <gb-flag
        :code="host.geoip.country_code2"
        size="mini"
        style="vertical-align: top"
      />
      <span>
        <span @mouseover="s_flagsShow" @mouseleave="s_flagsoff">
          {{
            host["xml-nmap"].geo_api
              ? host["xml-nmap"].geo_api[0].country_code[0]
              : host.geoip.country_code2
          }}
        </span>
        <span
          >(
          {{
            host["xml-nmap"].geo_api
              ? host["xml-nmap"].geo_api[0].region
                ? host.geoip.timezone
                  ? host["xml-nmap"].geo_api[0].region[0]
                  : host["xml-nmap"].geo_api[0].timezone[0].name[0].split(
                      "/"
                    )[1]
                : host.geoip.city_name
              : host.geoip.timezone.split("/")[1]
          }}
          ) </span
        >/
        <span @mouseover="s_ASNumberNameShow" @mouseleave="s_ASNumberNameoff">
          AS{{
            host["xml-nmap"].geo_api
              ? host["xml-nmap"].geo_api[0].connection[0]
                  .autonomous_system_number[0]
              : host.geoip.asn
          }}
          ({{
            host["xml-nmap"].geo_api
              ? host["xml-nmap"].geo_api[0].connection[0]
                  .autonomous_system_organization[0]
              : host.geoip.as_org
          }})</span
        >
      </span>
    </div>
    <div>
      <span
        class="card-cst-icon black"
        @mouseover="s_upShow"
        @mouseleave="s_upoff"
      >
        {{ host["xml-nmap"].host[0].status[0].state == "up" ? "UP" : "DN" }}
      </span>
      <span class="text-uppercase" @mouseover="s_upShow" @mouseleave="s_upoff">
        ({{ host["xml-nmap"].host[0].status[0].reason }})
      </span>
      <span
        class="pl-1"
        @mouseover="s_scantimeShow"
        @mouseleave="s_scantimeoff"
      >
        {{ dateFormat(host["xml-nmap"].host[0].starttime) }} ~
        {{ dateFormat(host["xml-nmap"].host[0].endtime) }} ({{
          dayFormat(host["xml-nmap"].runstats[0].finished[0].elapsed)
        }})
      </span>
    </div>
    <div class="mt-2" @mouseover="s_iconVSShow" @mouseleave="s_iconVSoff">
      <span class="card-cst-icon black"> VS </span>
      <span class="pl-2">{{ doubleDigit(host.score.vuln_score || 0) }}</span>
      <span
        class="score-badge mx-2"
        v-bind:style="{
          backgroundColor: scoreColor(host.score.vuln_score || 0),
        }"
        >{{ scoreWord(host.score.vuln_score || 0) }}</span
      >
      <span class="pr-2">{{ openedPorts }} Ports:</span>
      <span v-if="openedPorts == 0">--</span>
      <span class="pr-3" v-else>
        <span v-for="(port, index) in ports" :key="port.portid">
          <span>
            {{ port.protocol }}/{{ port.portid }}
            <span v-if="index + 1 != ports.length">,</span>
          </span>
        </span>
      </span>
    </div>
    <div @mouseover="s_iconTSShow" @mouseleave="s_iconTSoff">
      <span class="card-cst-icon black"> TS </span>
      <span class="pl-2">{{ doubleDigit(host.score.threat_score || 0) }}</span>
      <span
        class="score-badge mx-2"
        v-bind:style="{
          backgroundColor: scoreColor(host.score.threat_score || 0),
        }"
        >{{ scoreWord(host.score.threat_score || 0) }}</span
      >
      <span
        ><span class=""
          >{{ threatsFound.length }} Detects: {{ threatsFound.length }}/{{
            numbers.threatDB
          }}
          (OSINT Threat DBs)</span
        >
      </span>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import { index } from "@/mixins/elastic";
import { colors, dateFormat, dayFormat, PTestConn } from "@/mixins/commons";
import dayjs from "dayjs";
import EventBus from "@/plugins/EventBus.js";

export default {
  name: `HostCard`,
  props: {
    host: Object,
    selected: Boolean,
  },
  data: () => ({
    threatsFound: [],
    listed: false,
    openedPorts: 0,
    ports: [],
    numbers: {
      threatDB: 0,
    },
  }),

  mounted() {
    this.getData();

    if (this.host.threat.results) {
      this.threatsFound = this.host.threat.results[0].hits;
    }

    if (
      this.host["xml-nmap"].host[0].ports &&
      this.host["xml-nmap"].host[0].ports[0]
    ) {
      // console.log('hostname:::::::::', this.host['xml-nmap'].host);
      // console.log('geo_api:::::::::', this.host['xml-nmap'].geo_api );
      const totalPorts = this.host["xml-nmap"].host[0].ports[0];

      if (totalPorts.port) {
        this.ports = totalPorts?.port?.filter(
          (x) => x.state[0].state == "open"
        );
        totalPorts.port.forEach((port) => {
          if (port.state[0].state == "open") {
            this.openedPorts++;
          }
        });
      }
    }
  },

  methods: {
    s_levelShow: function () {
      EventBus.$emit(
        "s_levelShow",
        `The icon and the number in parentheses indicate the total risk of the device, and are displayed as Level(Low/Medium/High/Critical) and Score (0.0~10.0) according to the CVSS v3.0 standard.`
      );
    },
    s_leveloff: function () {
      EventBus.$emit("s_levelShow", null);
    },
    s_flagsShow: function () {
      EventBus.$emit(
        "s_flagsShow",
        `The image icon and the two letters shows the national flag and the Internet country domain name(ccTLD) of the country to which the device belongs.`
      );
    },
    s_flagsoff: function () {
      EventBus.$emit("s_flagsShow", null);
    },
    s_ASNumberNameShow: function () {
      EventBus.$emit(
        "s_ASNumberNameShow",
        `The ASxxxxxx and the string in parentheses indicate the unique identification number and name of the Autonomous System to which the device belongs.`
      );
    },
    s_ASNumberNameoff: function () {
      EventBus.$emit("s_ASNumberNameShow", null);
    },
    s_upShow: function () {
      EventBus.$emit(
        "s_upShow",
        `The icon image and the character string in parentheses indicate the current status (“UP”) of the device and the technique used to confirm the status determination.`
      );
    },
    s_upoff: function () {
      EventBus.$emit("s_upShow", null);
    },
    s_scantimeShow: function () {
      EventBus.$emit(
        "s_scantimeShow",
        `The time indicates the start time and completion time of the device scan, and the time in parentheses indicates the time taken for the scan.`
      );
    },
    s_scantimeoff: function () {
      EventBus.$emit("s_scantimeShow", null);
    },
    s_iconVSShow: function () {
      EventBus.$emit(
        "s_iconVSShow",
        `The image icon and the number indicate the score and level of the Vulnerability Risk of the device, and the strings show that how many ports are detected as the open state and which port(s) they are.`
      );
    },
    s_iconVSoff: function () {
      EventBus.$emit("s_iconVSShow", null);
    },
    s_iconTSShow: function () {
      EventBus.$emit(
        "s_iconTSShow",
        `The image and number indicate the score and level of the threat risk of the device, and it shows how many OSINT threat databases were searched and how many times it was detected.`
      );
    },
    s_iconTSoff: function () {
      EventBus.$emit("s_iconTSShow", null);
    },

    scoreColor: function (score) {
      if (score < 4) {
        return colors.low;
      } else if (score >= 4 && score < 7) {
        return colors.medium;
      } else if (score >= 7 && score < 9) {
        return colors.high;
      } else if (score >= 9 && score <= 10) {
        return colors.critical;
      } else {
        return colors.none;
      }
    },
    scoreLetter: function (score) {
      if (score < 4) {
        return `L`;
      } else if (score >= 4 && score < 7) {
        return `M`;
      } else if (score >= 7 && score < 9) {
        return `H`;
      } else if (score >= 9 && score <= 10) {
        return `C`;
      } else {
        return;
      }
    },
    scoreWord: function (score) {
      if (score < 4) {
        return `LOW`;
      } else if (score >= 4 && score < 7) {
        return `MEDIUM`;
      } else if (score >= 7 && score < 9) {
        return `HIGH`;
      } else if (score >= 9 && score <= 10) {
        return `CRITICAL`;
      } else {
        return;
      }
    },
    dateFormat: function (date) {
      // console.log(date);
      return dayjs.unix(date).format(dateFormat);
    },
    dayFormat: function (date1) {
      // console.log(date);
      return dayjs.unix(date1).format(dayFormat);
    },
    // elapsed: function(date1, date2){
    //     const d1 = dayjs.unix(date1);
    //     const d2 = dayjs.unix(date2);
    //     const diff = d1.diff(d2, 'minutes');
    //     let interval = [Math.floor(diff / 60).toString(), (diff % 60).toString()];
    //     return interval[0].padStart(2, '0') + ':' + interval[1].padStart(2, '0')
    // return diff+' min'
    // },
    singleDigit: function (number) {
      return Number.parseFloat(number).toPrecision(2);
    },
    doubleDigit: function (number) {
      return Number.parseFloat(number).toPrecision(3);
    },

    getData() {
      axios
        .post(PTestConn, {
          queryurl: index.databaseCount + "/_search",
          querybody: "",
        })
        .then((res) => {
          const data = res.data.hits.hits;
          data.forEach((x) => {
            if (x._id == `threat`) {
              this.numbers.threatDB = new Intl.NumberFormat().format(
                x._source.count
              );
            }
          });
        });
    },
  },
};
</script>

<style scoped>
.host-card-title {
  font-weight: bold;
}

    @media (min-width: 500px){
        
    .pr-3{
        display: inline-block;
        width: 50%;
        vertical-align: top;
        padding-right: 0 !important;
    }
    }
    @media (min-width: 600px){
        
        .pr-3{
            display: inline-block;
            width: 58%;
            vertical-align: top;
            padding-right: 0 !important;
        }
    }
    @media (min-width: 960px){
        .pr-3{
            display: inline-block;
            width: 53%;
            vertical-align: top;
            padding-right: 0 !important;
        }
        
    }
    @media (min-width: 1500px){
        .pr-3{
            display: inline-block;
            width: 70%;
            vertical-align: top;
            padding-right: 0 !important;
        }

    }
</style>

