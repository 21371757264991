<template>
  <div class="pa-4 pt-2">
    <v-card
      v-if="center"
      >
      <div
        @click="changeClick"
        @wheel="changeClick"
        @mouseover="click=true"
        @mouseout="click=false"
        @touchstart="changeHover"
        @touchend="preventMove"
      >
        <v-fade-transition>
            <v-alert
            dense
            type="warning"
            style="position: absolute; left:0px; top:0px; z-index: 200; width: 100%; background-color: rgba(255,152,0,0.9) !important; "
            v-if="!hover && userAgent=='mobile'"
            >
            use TWO FINGERS to move map
            </v-alert>
            <v-alert
            dense
            type="warning"
            style="position: absolute; left:0px; top:0px; z-index: 200; width: 100%; background-color: rgba(255,152,0,0.9) !important; "
            v-if="!click && userAgent=='pc'"
            >
            "Ctrl + Left Click" to move map
            </v-alert>
        </v-fade-transition>
        <vl-map
          data-projection="EPSG:4326"
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="true"
          style="height: 310px"
          ref="map"
        >
          <vl-view
            :zoom.sync="zoom"
            :center.sync="center"
            :min-zoom="minZoom"
            :max-zoom="maxZoom"
          ></vl-view>
          <vl-layer-tile>
            <vl-source-xyz :url="url"> </vl-source-xyz>
          </vl-layer-tile>

          <vl-feature>
            <vl-geom-point
              :coordinates="[host.geoip.location.lon, host.geoip.location.lat]"
            ></vl-geom-point>
            <vl-style-box>
              <vl-style-icon
                src="/img/marker.png"
                :scale="0.175"
                :anchor="[0.5, 1]"
              ></vl-style-icon>
            </vl-style-box>
          </vl-feature>
        </vl-map>
      </div>
    </v-card>

    <div style="font-weight: bold; border-bottom: 1px solid #d3d4e6; margin-top: 10px;">
      General Information
    </div>

    <div class="px-2 my-2" style="width: 100%">
      <table class="table-sm w-100 mb-4" style="width: 100%">
        <tbody>
          <tr v-for="item in infodata" :key="item.name + 'a'">
            <v-row class="pa-3">
              <td style="width: 25%">{{ item.name }}</td>
              <td v-if="item.flag" class="pr-1">
                Country:
                <gb-flag
                  :code="host.geoip.country_code2"
                  size="mini"
                  style="vertical-align: top"
                />
              </td>
              <td>{{ item.info1 }}</td>
            </v-row>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="font-weight: bold; border-bottom: 1px solid #d3d4e6">
      Risk Score
    </div>
    <div style="width: 100%" class="pa-3">
      <div style="width: 100%">
        <span style="width: 25%; display: inline-flex">Total Score:</span>
        <span class="pr-1">{{ doubleDigit(host.score.total_score) }}</span>
        <span>({{ scoreText(host.score.total_score) }})</span>
      </div>
      <div>
        <span style="width: 25%; display: inline-flex"
          >Vulnerability Score:</span
        >
        <span class="pr-1">{{ doubleDigit(host.score.vuln_score || 0) }}</span>
        <span>({{ scoreText(host.score.vuln_score || 0) }})</span>
      </div>
      <div class="mb-1">
        <span style="width: 25%; display: inline-flex">Threat Score:</span>
        <span class="pr-1">{{
          doubleDigit(host.score.threat_score || 0)
        }}</span>
        <span>({{ scoreText(host.score.threat_score || 0) }})</span>
      </div>
    </div>

    <div style="font-weight: bold; border-bottom: 1px solid #d3d4e6">
      Scan Results
    </div>
    <div class="my-2" style="width: 100%">
      <h4 class="font-weight-medium">
        <v-icon>mdi-menu-right</v-icon>OS Class
      </h4>
      <div style="width: 100%" class="pl-3">
        <div
          class="mb-3"
          style="width: 100%"
          v-if="!host['xml-nmap'].host[0].os"
        >
          <span> Failed to detect OS information(5 tries) </span>
        </div>

        <div style="width: 100%" v-if="host['xml-nmap'].host[0].os">
          <span style="width: 25%" class="mr-6">Vender/OSfamily:</span>
          <span class="pr-1">
            <span
              v-if="
                host['xml-nmap'].host[0].os &&
                host['xml-nmap'].host[0].os[0].osmatch &&
                host['xml-nmap'].host[0].os[0].osmatch[0].osclass &&
                host['xml-nmap'].host[0].os[0].osmatch[0].osclass[0].vendor
              "
              >{{
                host["xml-nmap"].host[0].os[0].osmatch[0].osclass[0].vendor
              }}/</span
            >
            <span v-else> n/a /</span>
          </span>
          <span class="pr-1">
            <span
              v-if="
                host['xml-nmap'].host[0].os &&
                host['xml-nmap'].host[0].os[0].osmatch &&
                host['xml-nmap'].host[0].os[0].osmatch[0].osclass &&
                host['xml-nmap'].host[0].os[0].osmatch[0].osclass[0].osfamily
              "
              >{{
                host["xml-nmap"].host[0].os[0].osmatch[0].osclass[0].osfamily
              }}</span
            >
            <span v-else> n/a</span>
          </span>
          <span class="pr-1">
            <span
              v-if="
                host['xml-nmap'].host[0].os &&
                host['xml-nmap'].host[0].os[0].osmatch &&
                host['xml-nmap'].host[0].os[0].osmatch[0].osclass &&
                host['xml-nmap'].host[0].os[0].osmatch[0].osclass[0].accuracy
              "
              >({{
                host["xml-nmap"].host[0].os[0].osmatch[0].osclass[0].accuracy
              }}%)</span
            >
            <span v-else> (n/a)</span>
          </span>
        </div>
        <div v-if="host['xml-nmap'].host[0].os">
          <span style="width: 24%; display: inline-flex" class="mb-3"
            >CPE:</span
          >
          <span
            class="pr-1"
            v-if="
              host['xml-nmap'].host[0].os &&
              host['xml-nmap'].host[0].os[0].osmatch &&
              host['xml-nmap'].host[0].os[0].osmatch[0].osclass &&
              host['xml-nmap'].host[0].os[0].osmatch[0].osclass[0].cpe
            "
            >{{
              host["xml-nmap"].host[0].os[0].osmatch[0].osclass[0].cpe[0]
            }}</span
          >
          <span v-else> n/a </span>
        </div>
      </div>

      <h4 class="font-weight-medium">
        <v-icon>mdi-menu-right</v-icon>Port Scan
      </h4>
    </div>
    <div class="px-1 my-2" style="width: 100%">
      <table class="table-md pl-1 mb-4 ml-xl-3">
        <tbody>
          <tr class="vertical-align-top">
            <td class="text-right" style="width: 70px">
              {{ openedPorts }} Ports
            </td>
            <td>
              <span class="score-badge mr-2 opened-bg center">OPENED</span>
            </td>
            <td>
              {{ openedPortsData }}
            </td>
          </tr>

          <tr class="vertical-align-top">
            <td class="text-right" style="width: 70px">
              {{ filteredPorts }} Ports
            </td>
            <td>
              <span class="score-badge mr-2 filtered-bg center">FILTERED</span>
            </td>
            <td class="">
              <span
                v-if="
                  host['xml-nmap'].host[0].ports &&
                  host['xml-nmap'].host[0].ports[0] &&
                  host['xml-nmap'].host[0].ports[0].extraports
                "
              >
                <span
                  v-if="
                    host['xml-nmap'].host[0].ports[0].extraports[0].state ==
                    'filtered'
                  "
                  >{{
                    host["xml-nmap"].host[0].ports[0].extraports[0]
                      .extrareasons[0].reason
                  }}</span
                >
              </span>
              <span>
                {{ filteredPortsData }}
              </span>
              <span v-if="filteredPorts === 0">--</span>
            </td>
          </tr>

          <tr class="vertical-align-top">
            <td class="text-right" style="width: 70px">
              {{ closedPorts }} Ports
            </td>
            <td><span class="score-badge mr-2 closed-bg">CLOSED</span></td>
            <td class="">
              <span
                v-if="
                  host['xml-nmap'].host[0].ports &&
                  host['xml-nmap'].host[0].ports[0] &&
                  host['xml-nmap'].host[0].ports[0].extraports
                "
              >
                <span
                  v-if="
                    host['xml-nmap'].host[0].ports[0].extraports[0].state ==
                    'closed'
                  "
                  class="mr-1"
                  >{{
                    host["xml-nmap"].host[0].ports[0].extraports[0]
                      .extrareasons[0].reason
                  }}</span
                >
              </span>
              <span>
                {{ closedPortsData }}
              </span>
              <span v-if="closedPorts === 0">--</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-2" style="width: 100%">
      <div class="mb-n1">
        <h4 class="font-weight-medium">
          <v-icon>mdi-menu-right</v-icon>Script Scan
        </h4>
      </div>
      <div class="px-1 my-2" style="width: 100%">
        <table class="table-md pl-1 mb-4 ml-xl-3">
          <tbody>
            <tr class="vertical-align-top" v-if="criticalScripts.length > 0">
              <td class="text-right" style="width: 70px">
                {{ criticalScripts.length }} Scripts
              </td>
              <td>
                <span class="score-badge mx-1 critical-bg">CRITICAL</span>
              </td>
              <td class="">
                <span
                  v-for="(script, index) in criticalScripts"
                  :key="index"
                  class="pr-1"
                >
                  <span v-if="index > 0">, </span>{{ script.id }}
                </span>
              </td>
            </tr>

            <tr class="vertical-align-top" v-if="highScripts.length > 0">
              <td class="text-right" style="width: 70px">
                {{ highScripts.length }} Scripts
              </td>
              <td><span class="score-badge mx-1 high-bg">HIGH</span></td>
              <td class="">
                <span
                  v-for="(script, index) in highScripts"
                  :key="index"
                  class="pr-1"
                >
                  <span v-if="index > 0">, </span>{{ script.id }}
                </span>
              </td>
            </tr>

            <tr class="vertical-align-top" v-if="mediumScripts.length > 0">
              <td class="text-right" style="width: 70px">
                {{ mediumScripts.length }} Scripts
              </td>
              <td><span class="score-badge mx-1 medium-bg">MEDIUM</span></td>
              <td class="">
                <span
                  v-for="(script, index) in mediumScripts"
                  :key="index"
                  class="pr-1"
                >
                  <span v-if="index > 0">, </span>{{ script.id }}
                </span>
              </td>
            </tr>

            <tr class="vertical-align-top" v-if="lowScripts.length > 0">
              <td class="text-right" style="width: 70px">
                {{ lowScripts.length }} Scripts
              </td>
              <td><span class="score-badge mx-1 low-bg">LOW</span></td>
              <td class="">
                <span
                  v-for="(script, index) in lowScripts"
                  :key="index"
                  class="pr-1"
                >
                  <span v-if="index > 0">, </span>{{ script.id }}
                </span>
              </td>
            </tr>
            <tr
              v-if="
                criticalScripts.length === 0 &&
                highScripts.length === 0 &&
                mediumScripts.length === 0 &&
                lowScripts.length === 0
              "
            >
              <td>No Script scan results</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="px- my-2" style="width: 100%">
      <h4 class="font-weight-medium">
        <v-icon>mdi-menu-right</v-icon>Threat Scan(OSINT DB)
      </h4>
      <div class="" style="width: 100%">
        <span class="pl-3 pr-1 mb-4">Current Blacklist Status:</span>
        <span
          ><v-chip
            class="font-weight-bold mr-1"
            label
            :color="listed ? 'red darken-2' : 'green'"
            text-color="white"
            x-small
            style="font-size: 76%"
            >{{ listed ? "LISTED" : "NOT LISTED" }}</v-chip
          ></span
        >
      </div>
      <div class="pl-3 pr-1 mb-4 pt-1">
        <span class="d-flax"
          >{{ threatsFound.length }} threats detected in
          {{ numbers.threatDB }} OSINT DBs</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { index } from "@/mixins/elastic";
import { globals } from "@/mixins/commons.js";
import { dateFormat, PTestConn } from "@/mixins/commons";
import dayjs from "dayjs";
import { platformModifierKeyOnly } from "ol/events/condition";
// import { count } from 'console';

export default {
  data() {
    return {
      userAgent:'pc',
      click: false,
      hover:false,
      interactionArr:[],
      listed: false,
      threatsFound: [],
      ports: [],
      scripts: [],
      criticalScripts: [],
      highScripts: [],
      mediumScripts: [],
      lowScripts: [],
      zoom: 10,
      url: globals.mapTile,
      maxZoom: 12,
      minZoom: 9,
      center: [this.host.geoip.location.lon, this.host.geoip.location.lat],
      openedPorts: 0,
      filteredPorts: 0,
      closedPorts: 0,
      numbers: {
        threatDB: 0,
      },
      infodata: [
        {
          name: "IP Address:",
          info1: this.host.geoip.ip,
        },
        {
          name: "Autonomous",
          info1: `${
            this.host["xml-nmap"].geo_api
              ? this.host["xml-nmap"].geo_api[0].connection[0]
                  .autonomous_system_organization[0]
              : this.host.geoip.as_org
          } (AS ${
            this.host["xml-nmap"].geo_api
              ? this.host["xml-nmap"].geo_api[0].connection[0]
                  .autonomous_system_number[0]
              : this.host.geoip.asn
          })`,
        },
        {
          name: "Location:",
          info1: `${
            this.host["xml-nmap"].geo_api
              ? this.host["xml-nmap"].geo_api[0].country[0]
              : this.host.geoip.country_name
          }(${
            this.host["xml-nmap"].geo_api
              ? this.host["xml-nmap"].geo_api[0].region
                ? this.host.geoip.timezone
                  ? this.host["xml-nmap"].geo_api[0].region[0]
                  : this.host["xml-nmap"].geo_api[0].timezone[0].name[0].split(
                      "/"
                    )[1]
                : this.host.geoip.city_name
              : this.host.geoip.timezone.split("/")[1]
          }) Lat/Lon: ${
            this.host["xml-nmap"].geo_api
              ? this.host["xml-nmap"].geo_api[0].latitude[0]
              : this.host.geoip.location.lat
          }/${
            this.host["xml-nmap"].geo_api
              ? this.host["xml-nmap"].geo_api[0].longitude[0]
              : this.host.geoip.location.lon
          }`,
          flag: true,
        },
        {
          name: "Scanner:",
          info1: `${this.host["xml-nmap"].scanner_hostname}`,
        },
        {
          name: "Scan Started:",
          info1: dayjs
            .unix(this.host["xml-nmap"].host[0].starttime)
            .format(dateFormat),
        },
        {
          name: "Scan Finished:",
          info1: dayjs
            .unix(this.host["xml-nmap"].host[0].endtime)
            .format(dateFormat),
        },
        {
          name: "Scan Time:",
          info1: dayjs
            .unix(this.host["xml-nmap"].runstats[0].finished[0].elapsed)
            .format("00:mm:ss.SSS"),
        },
      ],
    };
  },

  computed: {
    openedPortsData() {
      if (!this.ports) return "";

      let portsArr = [];
      this.ports.forEach((port) => {
        if (port.state[0].state === "open") {
          portsArr.push(`${port.protocol}/${port.portid}`);
        }
      });
      return portsArr.join(", ");
    },
    filteredPortsData() {
      if (!this.ports) return "";
      let portsArr = [];
      this.ports.forEach((port) => {
        if (port.state[0].state === "filtered") {
          portsArr.push(`${port.protocol}/${port.portid}`);
        }
      });
      return portsArr.join(", ");
    },
    closedPortsData() {
      if (!this.ports) return "";
      let portsArr = [];
      this.ports.forEach((port) => {
        if (port.state[0].state === "closed") {
          portsArr.push(`${port.protocol}/${port.portid}`);
        }
      });
      return portsArr.join(", ");
    },
  },

  filters: {
    removeComma: function (val) {
      return String(val).replace(",$", "");
    },
    removeComma2: function (val) {
      return String(val).replace(",$", "");
    },
  },

  methods: {
    changeClick: function(value){
        console.log(value);
        if(value.ctrlKey){
            this.click=true;
        }else{
            this.click=false;
        }
    },
    changeHover:function(value){
        if(value.touches.length==2){
            this.interactionArr[2].setActive(true);
            this.hover=true;
        }else{
            this.interactionArr[2].setActive(false);
            this.hover=false;
            // var x=Math.abs(value.touchstartX-value.touchendX);
            // var y=Math.abs(value.touchstartY-value.touchendY);
            // if(x<y){
                
            // }
        }
    },
    preventMove:function(value){
        console.log(value);
        value.stopPropagation();
    },
    dateFormat: function (date) {
      // console.log(date);
      return dayjs.unix(date).format(dateFormat);
    },
    singleDigit: function (number) {
      return parseFloat(number).toFixed(1);
    },
    doubleDigit: function (number) {
      return parseFloat(number).toFixed(2);
    },
    getData() {
      axios
        .post(PTestConn, {
          queryurl: index.databaseCount + "/_search",
          querybody: "",
        })
        .then((res) => {
          const data = res.data.hits.hits;
          data.forEach((x) => {
            if (x._id == `threat`) {
              this.numbers.threatDB = new Intl.NumberFormat().format(
                x._source.count
              );
            }
          });
        });
    },
    scoreText: function (score) {
      if (score < 4) {
        return `Low`;
      } else if (score >= 4 && score < 7) {
        return `Medium`;
      } else if (score >= 7 && score < 9) {
        return `High`;
      } else if (score >= 9 && score <= 10) {
        return `Critical`;
      } else {
        return;
      }
    },
  },
  props: {
    host: Object,
  },
  created(){
    //접근 기기 구분
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.userAgent='mobile';
    }

  },
  
  mounted() {
    var mapInstance= this.$refs.map;
    this.interactionArr=mapInstance.getInteractions();
    this.interactionArr[1].setActive(false);
  
    if(this.userAgent=='pc'){
      this.interactionArr[2].condition_=platformModifierKeyOnly;
      this.interactionArr[7].condition_=platformModifierKeyOnly;
    }
    this.getData();

    if (this.host.threat.blacklisted_count) {
      if (
        this.host.threat.blacklisted_count[0] > 0 ||
        this.host.threat.currently_blacklisted_count[0] > 0
      ) {
        if (this.host.threat.results) {
          if (this.host.threat.results[0].currently_blacklisted[0] == "true") {
            this.listed = true;
          } else {
            this.listed = false;
          }
        }
      }
    }

    if (
      this.host["xml-nmap"].host[0].ports &&
      this.host["xml-nmap"].host[0].ports[0]
    ) {
      const totalPorts = this.host["xml-nmap"].host[0].ports[0];
      this.ports = totalPorts.port;

      if (totalPorts.port) {
        totalPorts.port.forEach((port) => {
          if (port.state[0].state === "filtered") {
            this.filteredPorts++;
          } else if (port.state[0].state === "open") {
            this.openedPorts++;
          } else {
            this.closedPorts++;
          }
        });
      }

      if (totalPorts.extraports && totalPorts.extraports[0]) {
        if (totalPorts.extraports[0].state == "closed") {
          this.closedPorts = totalPorts.extraports[0].count;
        } else {
          this.filteredPorts = totalPorts.extraports[0].count;
        }
      }
    }

    if (
      this.host.score.nse_score &&
      this.host.score.nse_score.portscript_score
    ) {
      this.scripts = this.host.score.nse_score.portscript_score;
      this.scripts.forEach((script) => {
        if (script.score >= 0 && script.score < 4) {
          this.lowScripts.push(script);
        } else if (script.score >= 4 && script.score < 7) {
          this.mediumScripts.push(script);
        } else if (script.score >= 7 && script.score < 9) {
          this.highScripts.push(script);
        } else if (script.score >= 9 && script.score <= 10) {
          this.criticalScripts.push(script);
        }
      });
    }

    // console.log(';;;;;;',this.host['xml-nmap.scanner_hostname'].scanner_hostname);

    if (this.host.threat.results) {
      this.threatsFound = this.host.threat.results[0].hits;
    }
  },
};
</script>
