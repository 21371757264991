<template>
  <div>
    <v-card-title class="pb-0 pt-0 black--text pl-1 mb-xl-1 mb-md-0">
      <h4
        @mouseover="riskTimelineShow"
        @mouseleave="riskTimelineoff"
        style="font-weight: unset"
      >
        RISK TIMELINE
      </h4>
      <v-btn
        icon
        x-small
        color="primary"
        class="ml-2"
        @click="
          chartUpdate++;
          zoomDates.min = undefined;
          zoomDates.max = undefined;
          closecontext();
        "
      >
        <v-icon
          size="260%"
          @mouseover="rt_reloadShow"
          @mouseleave="rt_reloadoff"
          >mdi-cached</v-icon
        >
      </v-btn>
      <div class="ml-auto mr-n3" style="opacity: 50%">
        <v-btn
          color="red darken-1"
          style="height: 24px; min-width: 24px; padding: 0 4px"
          class="mb-1 ml-1"
          outlined
          dark
          @click="exportCSV"
        >
          <v-icon dark size="160%"> mdi-file-download-outline </v-icon>
        </v-btn>
        <v-btn
          color="red darken-1"
          style="height: 24px; min-width: 24px; padding: 0 4px"
          outlined
          dark
          class="ml-1 mb-1"
          @click="exportIMG"
        >
          <v-icon dark size="160%"> mdi-image </v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <div>
      <div
        class="mr-auto black--text font-weight-bold text-body-1"
        style="text-align: center"
      >
        <span class="pr-2">{{ topicLabel }}</span>
        <span>({{ dateLabel }})</span>
      </div>
    </div>
    <div>
      <bar-chart
        :key="'K' + lineHeight + chartUpdate"
        :height="lineHeight"
        :chartdata="chartData"
        :options="options"
        class="mt-xl-n4 mt-lg-n4 mt-md-0"
        id="chart"
      ></bar-chart>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/charts/Bar.vue";
import dayjs from "dayjs";
import axios from "axios";
import Papa from "papaparse";
import { index } from "@/mixins/elastic";
import { colors, PTestConn } from "@/mixins/commons";
import { chartDateFormat } from "@/mixins/commons";
import { timelineChartQuery, timelineClickBarQuery } from "@/mixins/queries.js";
import EventBus from "@/plugins/EventBus.js";

export default {
  components: {
    BarChart,
  },
  props: {
    params: Object,
  },
  data: () => ({
    chartUpdate: 0,
    height: 110,
    slider: 0,
    selectOptions: [
      { name: 'Devices with "UP" Status', value: "up" },
      { name: "Devices with OPEN Service Port", value: "open" },
      { name: "Devices with the Vulnerable Score", value: "vuln" },
      { name: "Devices with the Threat Score", value: "threat" },
    ],

    options: {
      legend: {
        display: true,
        reverse: true,
        align: "end",
        labels: {
          boxWidth: 20,
        },
      },
      tooltips: {
        mode: "index",
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            type: "time",
            time: {
              parser: "YYYY-MM-DDTHH:mm",
              unit: "month",
              displayFormats: {
                quarter: chartDateFormat,
                week: chartDateFormat,
                month: chartDateFormat,
              },
            },
            gridLines: {
              drawTicks: true,
              drawOnChartArea: false,
            },
            ticks: {
              min: null,
              max: null,
            },
          },
        ],
        yAxes: [
          {
            id: "y-axis-1",
            type: "linear",
            display: true,
            position: "left",
            gridLines: {
              drawTicks: true,
              drawOnChartArea: true,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          display: false,
          color: "#fff",
        },
        crosshair: {
          line: {
            color: `#BDBDBD`,
          },
          sync: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        zoom: {
          zoom: {
            enabled: false,
          },
          pan: {
            enabled: true,
            drag: true,
            mode: "x",

            rangeMax: {
              x: dayjs().subtract(1, "days").valueOf(),
            },
            rangeMin: {
              x: null,
            },
          },
        },
      },
    },
    chartData: {
      labels: [],
      datasets: [],
    },

    labelFormat: chartDateFormat,
    tempDataObject: {
      all: [],
      low: [],
      medium: [],
      high: [],
      critical: [],
    },
    zoomDates: {
      min: undefined,
      max: undefined,
    },
  }),
  mounted() {
    this.getData();
    this.options.onClick = this.clickOnBar;
    this.options.plugins.zoom.pan.onPanComplete = ({ chart }) => {
      this.zoomDates.min = chart.options.scales.xAxes[0].ticks.min;
      this.zoomDates.max = chart.options.scales.xAxes[0].ticks.max;
    };
    this.options.scales.xAxes[0].ticks.min = this.params.startDate.format();
    this.options.scales.xAxes[0].ticks.max = this.params.endDate.format();
    this.chartUpdate++;
  },
  computed: {
    selection: function () {
      return this.params.topic;
    },
    endDate: function () {
      return this.params.endDate;
    },
    startDate: function () {
      return this.params.startDate;
    },
    lineHeight: function () {
      switch (this.$vuetify.breakpoint.name) {
        case `xs`:
          return 350;
        case `sm`:
          return 240;
        case `md`:
          return 80;
        case `lg`:
          return 73;
        case `xl`:
          return 70;
      }
      return 110;
    },

    dateLabel: function () {
      if (this.zoomDates.min == undefined) {
        return (
          dayjs(this.params.startDate).format(chartDateFormat) +
          " ~ " +
          dayjs(this.params.endDate).format(chartDateFormat)
        );
      } else {
        return (
          dayjs(this.zoomDates.min).format(chartDateFormat) +
          " ~ " +
          dayjs(this.zoomDates.max).format(chartDateFormat)
        );
      }
    },
    topicLabel: function () {
      switch (this.params.topic) {
        case "up":
          return 'Devices with "UP" Status';
        case "open":
          return "Devices with OPEN Service Port";
        case "vuln":
          return "Devices with the Vulnerable Score";
        case "threat":
          return "Devices with the Threat Score";

        default:
          return "Option not recognized";
      }
    },
  },
  watch: {
    selection: function () {
      this.getData();
    },
    endDate: function () {
      this.getData();
    },
    startDate: function () {
      this.getData();
    },
  },
  methods: {
    riskTimelineShow: function () {
      EventBus.$emit(
        "riskTimelineShow",
        `The search result of 'QUERY CONDITION' is displayed, and you can change the display contents by clicking and dragging the mouse on the legend and bar graph, and the features to download the result as an image file or CSV file is provided.`
      );
    },
    riskTimelineoff: function () {
      EventBus.$emit("riskTimelineShow", null);
    },
    rt_reloadShow: function () {
      EventBus.$emit(
        "rt_reloadShow",
        `When the button is clicked, the displayed graph contents are changed to the initial search result of 'QUERY CONDITION', so this button is useful to reload the displayed contents changed by mouse click and drag.`
      );
    },
    rt_reloadoff: function () {
      EventBus.$emit("rt_reloadShow", null);
    },

    closecontext: function () {
      this.$store.commit("closeContextMenu");
    },

    exportCSV: function () {
      const fields = ["date", "all", "critical", "high", "medium", "low"];
      const data = [];
      for (let index = 0; index < this.chartData.labels.length; index++) {
        data[index] = [
          this.chartData.labels[index],
          this.tempDataObject.all[index],
          this.tempDataObject.critical[index],
          this.tempDataObject.high[index],
          this.tempDataObject.medium[index],
          this.tempDataObject.low[index],
        ];
      }

      const csv = Papa.unparse({ fields: fields, data: data });
      const blob = new Blob([csv]);
      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        blob,
        { type: "text/csv;charset=utf-8;" } + encodeURIComponent(csv)
      );
      a.download = "Risk Timeline" + dayjs().format() + ".csv";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    exportIMG: function () {
      const url_base64 = document
        .getElementById("bar-chart")
        .toDataURL("image/jpg");
      const a = document.createElement("a");
      a.href = url_base64;
      a.download = "Risk Timeline " + dayjs().format();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    clickOnBar: function (event, array) {
      if (array[0]) {
        this.$store.commit("openContextMenu");
        const start = dayjs(array[0]._view.label).format();
        const starttime = start;
        const end = dayjs(array[0]._view.label).add(24, "hours").format();
        const endtime = end;
        const title = this.topicLabel;
        const query = timelineClickBarQuery(this.selection, starttime, endtime);

        var commit = this.$store.commit;
        var list = {};
        var linkedList = {};

        axios
          .post(PTestConn, {
            queryurl: index.nmap + "/_search/template",
            querybody: JSON.stringify(query),
          })
          .then((res) => {
            // console.log(res,'timelineresresBar')

            list =
              res.data.aggregations.over_time.buckets[0].host_scores.buckets.map(
                (x) => {
                  return { element: x.key, value: x.doc_count };
                }
              );
            linkedList = res.data.hits.hits.map((x) => {
              return {
                element: x._source.ipaddr,
                value: x._source.score.total_score,
                href: `/#/search/command/ip/${x._source.ipaddr}`,
              };
            });
          })
          .finally(function () {
            const payload = {
              loading: false,
              title: title,
              listTitle: {
                element: `Scan Date`,
                value: array[0]._view.label,
              },

              list: list,
              linkedListTitle: `Move to Search:`,
              linkedList: linkedList,
            };
            commit("setContextData", payload);
          });
      }
    },
    dateRangeUpdate: function () {
      let position = 0;
      if (this.slider < 0) position = this.slider * -1 * 3;
      this.options.scales.xAxes[0].ticks.min = dayjs()
        .subtract(position + 3, "months")
        .format();
      this.options.scales.xAxes[0].ticks.max = dayjs()
        .subtract(position, "months")
        .format();
      this.chartUpdate++;
    },
    getData: function () {
      const start = this.startDate.format();
      const starttime = start;
      const end = this.endDate.format();
      const endtime = end;
      const query = timelineChartQuery(this.selection, starttime, endtime);
      var tempDataObject = {
        all: [],
        low: [],
        medium: [],
        high: [],
        critical: [],
      };
      var tempLabelArray = [];
      axios
        .post(PTestConn, {
          queryurl: index.nmap + "/_search/template",
          querybody: JSON.stringify(query),
        })
        .then((res) => {
          // console.log('riskTimeline: ', res);
          const rtData = res.data.aggregations.over_time.buckets;
          rtData.forEach(function (k) {
            tempLabelArray.push(dayjs(k.key).format("YYYY-MM-DD"));
            if (k.host_scores) {
              const scores = k.host_scores.buckets;
              scores.forEach(function (score) {
                // console.log('score:',score);
                tempDataObject[score.key].push(score.doc_count);
              });
            } else {
              // Scripts code
            }
          });
        })
        .finally(() => {
          this.chartData.labels = tempLabelArray;
          this.chartData.datasets = [
            {
              label: "Low",
              backgroundColor: colors.low,
              data: tempDataObject.low,
            },
            {
              label: "Medium",
              backgroundColor: colors.medium,
              data: tempDataObject.medium,
            },
            {
              label: "High",
              backgroundColor: colors.high,
              data: tempDataObject.high,
            },
            {
              label: "Critical",
              backgroundColor: colors.critical,
              data: tempDataObject.critical,
            },
          ];
          this.chartUpdate++;
          this.tempDataObject = tempDataObject;
        });
    },
  },
};
</script>
