<template>
  <v-row justify="center">
    <v-dialog
      v-model="pwDialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title class="text-h5">
          * Password Change Notification
        </v-card-title>

        <v-card-text class="font-weight-medium">
          - Your password will expire on {{dateFormat(userData.expire_date)}}.<br>
          - To comply with our password management policy, please change your password every three months.<br>
          - Click the 'Change Now' button to be directed to the password change screen.
        </v-card-text>
        <v-card-actions style="display: block;">
         
          <v-btn
            color="green-darken-1"
            text
            @click="pw_close()"
          >
          Change Later
          </v-btn>
          <v-btn
            color="green-darken-1"
            text
            style="float: right; text-align: right;"
            @click="pw_change() "
          >
          Change Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>

import dayjs from 'dayjs';
import { dateFormat} from "@/mixins/commons";
import EventBus from '@/plugins/EventBus.js';

export default {
    name: `Pwalert`,
    data: function(){
        return {
            pwDialog : false,
            UserData: {},
            
        }
    },

    computed: {
      userData(){
        return this.$store.state.userData
      }
    },

    methods: {

      dateFormat(date){
        return dayjs(date).format(dateFormat)
      },
      
      pw_change: function(){
        this.pwDialog = false;
        this.$emit('show-dialog');
        this.$router.push('status');
      },

      pw_close: function(){
        this.pwDialog = false;
        this.$router.push('status');
      }

    },
    created() {
      EventBus.$on('openPwalert', () => {
        this.pwDialog = true
      })
      
      
    },
    
}
</script>