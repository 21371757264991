<template >
    <div style="height:100%;">
        <v-container
            fluid class="pl-2 pr-1 pb-1 pt-1" style="height:auto;"
        >
            <p class="font-weight-bold mb-1" v-bind:style="[selected ? {color :'#0D47A1'} : {color : 'black' }]">{{serverData.server_name}} <span style="font-size:0.8em;">({{serverData.ip}}) </span> </p> 
            
                <v-col class="text-center pa-0 mb-2">
                   <v-row
                            no-gutters
                            justify="center"
                            align-content="center"
                            >
                        <v-icon 
                                small 
                                color="black"
                                class="mr-1 "
                                 >
                                     mdi-alpha-c-circle-outline
                        </v-icon>
                                 <v-card elevation="0" :color="currentColor" width="83%"  >
                                <h3 class="ping-ping-ping-ping"> 
                                    {{doubleDigit(serverData.cpu_usage)}}%
                                </h3>
                       

                    </v-card>
                    </v-row>
                </v-col>
                <v-col class="pa-0 mb-2 text-center">
                    <v-row
                            no-gutters
                            justify="center"
                            align-content="center"
                        >
                            <v-icon 
                                small 
                                color="black"
                                class="mr-1 "
                            >
                                mdi-alpha-m-circle-outline
                            </v-icon>
                    <v-card elevation="0" :color="currentColor" width="83%">
                        
                            <h3 class="ping-ping-ping-ping">
                                {{doubleDigit(serverData.memory_usage)}}%
                            </h3>
                    </v-card>
                    </v-row>
                </v-col>
            
                <v-col class="pa-0 mb-1 text-center">
                    <v-row
                            no-gutters
                            justify="center"
                            align-content="center"
                            >
                                <v-icon 
                                        small 
                                        color="black"
                                        class="mr-1 "
                                        >
                                            mdi-alpha-s-circle-outline
                                </v-icon>
                                <v-card elevation="0" :color="currentColor" width="83%">
                        
                            <h3 class="ping-ping-ping-ping">
                                {{doubleDigit(serverData.storage_usage)}}%
                            </h3>
                    </v-card>
                    </v-row>
                </v-col>
               
           
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'ServerStatusCard',
    props: {
        serverData: Object,
        selected: Boolean
    },
    methods: {
        doubleDigit: function(number){
            return parseFloat(number).toFixed(2)
        }
    },
    computed: {
        currentColor: function(){
            if(this.selected){
                return 'blue-grey lighten-5'
            }
            return 'white'
        }
    }
}
</script>

<style scoped>
@keyframes blink {
  from { color: black; }
  to { color: darkgrey; }
}

.ping-ping-ping-ping{
    animation: blink 0.15s 6 alternate;
}
</style>