<template>
    <div class="pt-1 pr-2">
        
        <div v-if="serverDetails" class="mt-2 d-flex ">
            <span>
                <v-card 
                                class="mr-2"
                                outlined
                                elevation="0"
                                color="grey lighten-2"
                                min-width="110px"
                                height="22px"
                                >

                                <span class="font-weight-bold ml-2">
                                    {{server}}
                                    </span> 
                                        </v-card>
                                            </span>
            <span @mouseover=" ss_nShow " @mouseleave="ss_noff">
                <span class="card-cst-icon blue darken-4">N</span> 
                    {{serverDetails.ip}} ({{serverDetails.mac}})
            </span>
            <span class="pl-3" @mouseover="ss_bShow " @mouseleave="ss_boff">
                <span class="card-cst-icon blue darken-4">B</span>
                     Boot Time: {{dateLabel}}
            </span>
            <span class="ml-auto">
                <v-btn
                    color="red darken-1"
                    style="height:24px; min-width:24px; padding: 0 4px;"
                    class="mb-1 ml-1"
                    outlined
                    dark
                    @click="exportCSV()"
                    @mouseover="ss_cvsShow " @mouseleave="ss_cvsoff"
                >
                    <v-icon
                        dark
                        size="160%"
                    >
                        mdi-file-download-outline
                    </v-icon>
                </v-btn>
                
            </span>
        </div>
        
        <v-tabs height="30px" class="mt-0" >
            <v-tab @click="selectedResource = 'cpu'" @mouseover="ss_tabCpuShow " @mouseleave="ss_tabCpuoff"><v-icon small class="mr-1">mdi-alpha-c-circle-outline</v-icon> CPU (%)</v-tab>
            <v-tab @click="selectedResource = 'memory'" @mouseover="ss_tabMemoryShow " @mouseleave="ss_tabMemoryoff"><v-icon small class="mr-1">mdi-alpha-m-circle-outline</v-icon> Memory (%)</v-tab>
            <v-tab @click="selectedResource = 'storage'" @mouseover="ss_tabStorageShow " @mouseleave="ss_tabStorageoff"><v-icon small class="mr-1">mdi-alpha-s-circle-outline</v-icon> Storage (%)</v-tab>
           
        </v-tabs>
        <div class=""  v-if="!ipTableLoading" >
>
            <box-plot-scatter 
                :key="chartKey" 
                :label="server+'('+selectedResource+')'" 
                :dataset="fullDataset" 
                :loading="ipTableLoading"
            ></box-plot-scatter>
        </div>
    </div>
</template>

<script>

import BoxPlotScatter from '@/components/charts/BoxPlotScatter.vue'
import axios from 'axios';
import { index } from '@/mixins/elastic';
import { dateFormat, PTestConn  } from '@/mixins/commons';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import {settingQuery2, settingChartQuery} from '@/mixins/queries.js';
import EventBus from '@/plugins/EventBus.js';

export default {
    components: {

        BoxPlotScatter
    },
    data: ()=>({
        dataset: [],
        ipTableLoading: true,
        selectedResource: 'cpu',
        boxplot: [],

        // scatterMax: [],
        // scatterMin: [],

        serverDetails: undefined
    }),
    props: {
        server: String,
        // field:String,
        dates: Array,
        chartKey: Number
    },
    mounted(){
        this.getServerDataset();
        this.getServerDetail();
    },
    watch: {
        selectedResource: function(){
            this.getServerDataset();
        }
    },
    computed: {
        dateLabel: function(){
            if (this.serverDetails){
                return dayjs(this.serverDetails.boot_time).format(dateFormat)
            }
            return false
        },
        fullDataset: function(){
            return [
                {
                    name: 'Percentiles',
                    type: 'boxPlot',
                    data: this.boxplot
                }
            ]
    },
    },
    methods: {
        ss_nShow: function(){
            EventBus.$emit('ss_nShow', `These addresses are information about the network interface of the server, and show the IPv4 address and MAC address.`);
        },
        ss_noff: function(){
            EventBus.$emit('ss_nShow', null);
        },
        ss_bShow: function(){
            EventBus.$emit('ss_bShow', `This time shows the date and time the server booted.`);
        },
        ss_boff: function(){
            EventBus.$emit('ss_bShow', null);
        },
        ss_tabCpuShow: function(){
            EventBus.$emit('ss_tabCpuShow', `You can figure out the trend of CPU usage through the time series data for the last 3 months of the server, and the box plot shows percentiles with 5 points (Min, 25%, Median, 75%, Max)`);
        },
        ss_tabCpuoff: function(){
            EventBus.$emit('ss_tabCpuShow', null);
        },
        ss_tabMemoryShow: function(){
            EventBus.$emit('ss_tabMemoryShow', `You can figure out the trend of memory usage through the time series data for the last 3 months of the server, and the box plot shows percentiles with 5 points (Min, 25%, Median, 75%, Max)`);
        },
        ss_tabMemoryoff: function(){
            EventBus.$emit('ss_tabMemoryShow', null);
        },
        ss_tabStorageShow: function(){
            EventBus.$emit('ss_tabStorageShow', `You can figure out the trend of storage usage through the time series data for the last 3 months of the server, and the box plot shows percentiles with 5 points (Min, 25%, Median, 75%, Max)`);
        },
        ss_tabStorageoff: function(){
            EventBus.$emit('ss_tabStorageShow', null);
        },
        ss_tabNetworkShow: function(){
            EventBus.$emit('ss_tabNetworkShow', `You can figure out the trend of network traffic through the time series data for the last 3 months of the server, and the box plot shows percentiles with 5 points (Min, 25%, Median, 75%, Max)`);
        },
        ss_tabNetworkoff: function(){
            EventBus.$emit('ss_tabNetworkShow', null);
        },
        ss_cvsShow: function(){
            EventBus.$emit('ss_cvsShow', `If you click this button, you can download the table contents to your local disk in CSV file format, and this function is useful when processing the downloaded data to create separate data.`);
        },
        ss_cvsoff: function(){
            EventBus.$emit('ss_cvsShow', null);
        },


        exportCSV: function(){
            const fields = ['Date', 'Minimum', '25%', 'Median', '75%', 'Max'];
            const data = this.boxplot.map(x=>{ return [x.x, x.y[0], x.y[1], x.y[2], x.y[3], x.y[0]]});
            
            const csv = Papa.unparse({"fields": fields, "data": data});
            const blob = new Blob([csv]);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob, { type: 'text/csv;charset=utf-8;'} + encodeURIComponent(csv));
            a.download = 'Custom Query '+dayjs().format()+'.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        doubleDigit: function(number){
            return parseFloat(number).toFixed(2)
        },
        getServerDetail: function(){
            const query = settingQuery2(this.server);
            axios.post( PTestConn , { 
                    queryurl :  index.serverStatus+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {
                this.serverDetails = res.data.hits.hits[0]._source;
            })
        },
        getServerDataset: function(){
            
            let field = '';
            switch (this.selectedResource) {
                case 'cpu':
                    field = 'cpu_usage'
                    break;
                case 'memory':
                    field = 'memory_usage'
                    break;
                case 'network':
                    field = 'network_speed'
                    break;
                case 'storage':
                    field = 'storage_usage'
                    break;
                default:
                    break;
            }
            const starttime =  this.dates[0]+"T00:00:00.000Z";
            const endtime = this.dates[1]+"T23:59:59.999Z";
            
            const query = settingChartQuery(this.server,starttime,endtime,field);
            this.ipTableLoading = true;
            var resource = this.selectedResource;
            axios.post( PTestConn  , { 
                    queryurl :  index.serverStatusHistory+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {

                 
                this.ipTableLoading = false;
                const boxplot = [];
                // console.log("ressssss",res);
                
                 res.data.aggregations.results_per_day.buckets.forEach(function(x){
                    if( resource == `network`){
                        boxplot.push({
                            x: new Date (x.key),
                            y: [
                                
                                ((x.min.value/1024)/1024).toFixed(2),
                                ((x.distribution.values['25.0']/1024)/1024).toFixed(2), 
                                ((x.distribution.values['50.0']/1024)/1024).toFixed(2),
                                ((x.distribution.values['75.0']/1024)/1024).toFixed(2),
                                ((x.max.value/1024)/1024).toFixed(2)
                            ] 
                        })
                    } else{
                        boxplot.push({
                            x: new Date (x.key),
                            y: [
                                x.min.value.toFixed(2),
                                x.distribution.values['25.0'].toFixed(2), 
                                x.distribution.values['50.0'].toFixed(2),
                                x.distribution.values['75.0'].toFixed(2),
                                x.max.value.toFixed(2)
                                
                            ] 
                        })
                    }
                     
                })
                
                this.boxplot = boxplot;
                
            })
        }
    }
}
</script>

<style scoped>

.card-cst-icon{
    width:20px;
    height:20px;
    font-size: 12px;
}
</style>