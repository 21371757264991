<template>
<div>

    <v-container fluid style="height:100%">
        <v-row style="height:100%">
            <v-col 
                cols="12"
                sm="5"
                md="5"
                class="pb-0"
                style="height:10%"
            >
            <div class="font-weight-bold">
                UPDATE/BACKUP HISTORY
                
            </div>
            </v-col>
            <v-col
                cols="10"
                sm="4"
                md="4"
                class="py-0 pr-0"
            >
                <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }" >
                        <v-text-field
                            v-model="dateRangeText"
                            class="pt-1"
                            style="font-size:14px;"
                            prepend-icon="mdi-calendar"
                            readonly
                            hide-details=""
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="changedDate"
                        range
                        
                    >
                        <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="dateMenu = false"
                            >
                                OK
                            </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <div>
                <v-btn
                    icon
                    small
                    color="primary"
                    v-tooltip="'Apply changes'"
                    :disabled="date == changedDate"
                    @click="setDate"
                    class="mt-4"
                >
                    <v-icon>mdi-progress-download</v-icon>
                </v-btn>
            </div>
            <div class="ml-auto pa-1 mr-2">
                <v-btn
                    color="red darken-1"
                    style="height:24px; min-width:24px; padding: 0 4px;"
                    class="mt-3 mb-2 ml-1 "
                    outlined
                    dark
                    @click="exportCSV"
                >
                    <v-icon
                        dark
                        size="160%"
                    >
                        mdi-file-download-outline
                    </v-icon>
                </v-btn>
            </div>
            <v-col
                cols="12"
                md="5"
                lg="5"
                class="pt-0 mt-n2 pb-2"
            >
                <div class="" style="height:100%">
                    <v-card class="mb-2" style="border:1px solid gainsboro; height:49%" elevation="0"  @click="selectedItems = updates">
                        <v-card-title class="d-flex pl-2 pr-2 pt-1 pb-1">
                            <span >
                                Recent Automatic Updates<span><v-badge class="pl-2 text-center rounded-0" color="green" :content="updates.length || '0'"></v-badge></span>
                            </span>
                            <span class="ml-auto" @click="miniUpdatesCard = !miniUpdatesCard">
                                <v-icon>{{!miniUpdatesCard?'mdi-chevron-down':'mdi-chevron-up'}}</v-icon>
                            </span>
                            
                        </v-card-title>
                        <v-card-text v-if="miniUpdatesCard" style="height:13vh" class="black--text pl-2 pr-2 pb-2 pt-0 scroll-bar" :class="{ 'mini-card-text': miniUpdatesCard }">
                            <table class="table-sm w-100 " style="width:100%;">
                                <tbody >
                                    <tr v-for="(item, index) in updates" :key="index" >
                                        
                                        <td class="text-left">{{item.status}} {{item.flag}} {{item.data}}</td>
                                        <td class="text-left" style="width:100px;" >{{dateFormat(item.time)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-card-text>
                    </v-card>

                    <v-card class="" style="border:1px solid gainsboro; height:49%" elevation="0"  @click="selectedItems = backups">
                        <v-card-title class="d-flex pl-2 pr-2 pt-1 pb-1">
                            <span>
                                Recent Automatic Backups<span><v-badge class="pl-2 text-center rounded-0" color="green" :content="backups.length || '0'"></v-badge></span>
                            </span>
                            <span class="ml-auto" @click="miniBackupsCard = !miniBackupsCard">
                                <v-icon>{{!miniBackupsCard?'mdi-chevron-down':'mdi-chevron-up'}}</v-icon>
                            </span>
                            
                        </v-card-title>
                        <v-card-text v-if="miniBackupsCard" style="height:13vh" class="black--text pl-2 pr-2 pb-2 pt-0 scroll-bar" :class="{ 'mini-card-text': miniBackupsCard }">
                            <table class="table-sm w-100" style="width:100%;">
                                <tbody>
                                    <tr v-for="(item, index) in backups" :key="index">
                                        
                                        <td class="text-left">{{item.status}} {{item.flag}} {{item.data}}</td>
                                        <td class="text-left" style="width:100px;">{{dateFormat(item.time)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-card-text>
                    </v-card>
                </div>

            </v-col>
            <v-col
                cols="12"
                md="7"
                lg="7"
                class="pt-0 mt-n2 pb-2 right"
            >
                
                    <v-card
                        elevation="0"
                        color="blue-grey lighten-5"
                        class="py-0 px-1 scroll-bar"
                        height="101%"

                    >
                    
                    <!-- 둘의 height차 8px -->
                        <v-data-table
                            class="mt-1 scroll-bar"
                            :headers="headers"
                            :items="selectedItems"
                            :search="search"
                            height="40vh"
                            fixed-header
                            :disable-pagination="true"
                            hide-default-footer
                            disable-sort
                        >
                        
                            <template v-slot:[`item.number`]="{ index }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.time`]="{ item }">
                                {{dateFormat(item.time)}}
                            </template>
                            
                        </v-data-table>
                 
                    </v-card>
                    
                
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { index } from '@/mixins/elastic';
import {updateLogSearch, backupLogSearch} from  '@/mixins/queries';
import { dateFormat, PTestConn  } from "@/mixins/commons";
import Papa from 'papaparse';
export default {
    data:() => ({
        miniUpdatesCard: true,
        miniBackupsCard: true,
        backups: [],
        updates: [],
        selectedItems: [],
        dateMenu: false,
        date: undefined,
        changedDate: undefined,
        defaultDate: [dayjs().subtract(3, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        search: ``
    }),
    computed: {
        dateRangeText () {
            return this.changedDate.join(' ~ ')
        },
        headers() {
            if (this.selectedItems[0] && this.selectedItems[0].flag == 'update'){
                return [
                    { text: `#`, value: `number` },
                    { text: `Status`, value: `status` },
                    { text: `Name`, value: `name` },
                    { text: `Data`, value: `data` },
                    { text: `Time `, value: `time` },
                    { text: `Duration`, value: `duration` }
                ]
            }
            return [
                { text: `#`, value: `number` },
                { text: `Status`, value: `status` },
                { text: `Type`, value: `type` },
                { text: `Data`, value: `data` },
                { text: `Destination`, value: `dest_dir` },
                { text: `Time `, value: `time` },
                { text: `Duration`, value: `duration` }
            ]
        }
    },
    methods: {
        exportCSV: function(){
            const csv = Papa.unparse(this.selectedItems);
            const blob = new Blob([csv]);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob, { type: 'text/csv;charset=utf-8;'} + encodeURIComponent(csv));
            a.download = 'Update.Backup History '+dayjs().format()+'.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        resetDates: function(){
            this.date = this.defaultDate;
            this.changedDate = this.defaultDate;
            this.selectedItems = []; 
            this.getBackupLogs();
            this.getUpdateLogs();
        },
        setDate: function(){
            this.date = this.changedDate;
            this.selectedItems = []; 
            this.getBackupLogs();
            this.getUpdateLogs();
        },
        dateFormat(date){
            return dayjs(date).format(dateFormat)
        },
        getBackupLogs: function(){
            const starttime = this.date[0];
            const endtime = this.date[1];
            const query = backupLogSearch(starttime, endtime);
             axios.post( PTestConn , { 
                    queryurl :  index.logs+'/_search',
                    querybody : JSON.stringify(query),
             })

            .then((res)=>{

                this.backups = res.data.hits.hits.map(x=>{
                    return x._source
                })
                
            })
        },
        getUpdateLogs: function(){
            const starttime = this.date[0];
            const endtime = this.date[1];
            const query = updateLogSearch(starttime, endtime);
            axios.post( PTestConn , { 
                    queryurl :  index.logs+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then((res)=>{

                this.updates = res.data.hits.hits.map(x=>{
                    return x._source
                })
                if (this.selectedItems.length == 0){
                    this.selectedItems = this.updates;
                }
            })
        },
        refresh: function(){
            this.selectedItems = [];
            this.getBackupLogs(); 
            this.getUpdateLogs();
        }
    },
    mounted(){
        this.getBackupLogs();
        this.getUpdateLogs();
    },
    created(){
        this.date = this.defaultDate;
        this.changedDate = this.defaultDate;
    }
}
</script>

<style scoped>
    .mini-card-text{
        height: 100px;
        overflow-y: auto;
        scrollbar-width: none !important;
        -ms-overflow-style: none !important;
    }

    .v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > th{
        height: 28px;
    }

    .v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td{
        padding:0px 10px;
    }

    .theme--light.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
    border-bottom:none;
    }

    .v-data-table--dense /deep/ .v-data-table__wrapper > table > tbody > tr > td{
        height :24px
    }

    .v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
        height: 24px;
    }
    
    .v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th{
        height: 30px;
    }

    


</style>