<template>
    <div style="height:100%;">
        <v-container fluid style="height:100%;">
            <v-row style="height:100%;">
                <v-col >
                    <simple-risk-query></simple-risk-query>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>

import SimpleRiskQuery from "@/components/analysis/SimpleRiskQuery.vue";
export default {
    name: `Analysis`,
    components: {

        SimpleRiskQuery
    },
    mounted(){
        this.$store.commit('closeContextMenu');
    }
}
</script>