<template>
    <v-container fluid class="pa-0" style="display: flex; justify-content: center; align-items: center;">
        <v-row style="max-width: 100%;">
            <v-col cols="12" md="4" class="text-center pt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding-right:6px; padding-left:6px;">
                <div class="pb-2"><h1 style="font-size:2em;" @mouseover=" gr_threatDBShow " @mouseleave="gr_threatDBoff">{{numbers.threatDB}}</h1></div>
                <div style="font-size:1em;" @mouseover=" gr_threatDBShow " @mouseleave="gr_threatDBoff">THREAT DB</div>
            </v-col>
            <v-col cols="12" md="4" class="text-center ppt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding-right:6px; padding-left:6px;">
                <div class="pb-2"><h1 style="font-size:2em;" @mouseover=" gr_vulncountShow " @mouseleave="gr_vulncountoff">{{numbers.vulnCount}}</h1></div>
                <div style="font-size:1em;" @mouseover=" gr_vulncountShow " @mouseleave="gr_vulncountoff">VULN. COUNT</div>
            </v-col>
            <v-col cols="12" md="4" class="text-center pt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding-right:0px; padding-left:0px;">
                <div class="pb-2"><h1 style="font-size:2em;" @mouseover=" gr_cvecountShow " @mouseleave="gr_cvecountoff">{{numbers.cveCount}}</h1></div>
                <div style="font-size:1em;" @mouseover=" gr_cvecountShow " @mouseleave="gr_cvecountoff">CVE COUNT</div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import { index } from '@/mixins/elastic';
import EventBus from '@/plugins/EventBus.js';
import { PTestConn } from '@/mixins/commons';
export default {
    name: `RiskNumbers`,
    data: function(){
        return {
            numbers: {
                threatDB: 0,
                vulnCount: 0,
                cveCount: 0
            }
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        gr_threatDBShow: function(){
            EventBus.$emit('gr_threatDBShow', `The number indicates the total number of Threat Databases used for Threat Scan, and our WiKi Security Lab verifies and updates verified databases such as IBM X-Force, Alien Vault, and US Cert on a daily basis.`);
        },
        gr_threatDBoff: function(){
            EventBus.$emit('gr_threatDBShow', null);
        },
        gr_vulncountShow: function(){
            EventBus.$emit('gr_vulncountShow', `The number indicates the total number of Scan Scripts used for Vulnerability Scan, and our WiKi Security Lab verifies and updates the well-known Vulnerability DB and Exploit on a daily basis.`);
        },
        gr_vulncountoff: function(){
            EventBus.$emit('gr_vulncountShow', null);
        },
        gr_cvecountShow: function(){
            EventBus.$emit('gr_cvecountShow', `The number indicates the total number of CVE Vulnerability Databases used for Vulnerability Scan, and our WiKi Security Lab verifies and updates MITER's CVE and US National Vulnerability Database (NVD) on a daily basis.`);
        },
        gr_cvecountoff: function(){
            EventBus.$emit('gr_cvecountShow', null);
        },


        getData(){
            axios.post( PTestConn , { 
                    queryurl : index.databaseCount+'/_search',
                    querybody : '',
             })
            .then(res => {
                // console.log("RiskNumbers:",res)
                const data = res.data.hits.hits;
                data.forEach(x => {
                    if (x._id == `cve`){
                        this.numbers.cveCount = new Intl.NumberFormat().format(x._source.count);
                    }else if(x._id == `nse`){
                        this.numbers.vulnCount = new Intl.NumberFormat().format(x._source.count);
                    }else if(x._id == `threat`){
                        this.numbers.threatDB = new Intl.NumberFormat().format(x._source.count);
                    }
                });
            })
        }
    }
}
</script>