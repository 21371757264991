<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 justify-center">
          Access Restricted
        </v-card-title>

        <v-card-text class="text-h6" style="text-align: center">
          You do not have the necessary permissions to access this menu. Your
          current account is a demo account, which has limited access to certain
          features. To gain full access, please upgrade your account. For more
          information, contact our support team!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$store.commit('unsetUnAuth')"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
    //   dialog: true,
    };
  },
//   mounted() {
//     this.dialog = true;
//   },
    // methods:{
    //     unsetunAuth(){
    //         this.$store.commit('unsetUnAuth')
    //     }
    // }
};
</script>
