<template>
    <v-container fluid class="pt-2" style="height:100%">
        <v-row style="height:100%">
            <v-col cols="12" class="pb-0"><div class="font-weight-bold">SYSTEM EVENTS</div></v-col>
            <v-col
                cols="12"
                md="5"
                lg="5"
                class="pt-1"
                style="height:100%"
            >

                <div class="" style="height:100%">
                    <v-card class="mb-4" style="border:1px solid gainsboro; height:45%;" elevation="0" >
                        <v-card-title class="d-flex pl-2 pr-2 pt-1 pb-1 clickable" @click="miniAlertCard = !miniAlertCard">
                            <span @mouseover="se_recentAlertShow " @mouseleave="se_recentAlertoff">
                                Recent alert notifications<span>
                                    <v-badge
                                        class="pl-2 text-center rounded-0"
                                        color="green" :content="alertNotifications.length || '0'"
                                        @mouseover="se_recentAlertShow " @mouseleave="se_recentAlertoff">
                                    </v-badge>
                                </span>
                            </span>
                            <span class="ml-auto" >
                                <v-icon>{{!miniAlertCard?'mdi-chevron-down':'mdi-chevron-up'}}</v-icon>
                            </span>

                        </v-card-title>
                        <v-card-text v-if="miniAlertCard" style="height:75%" class="black--text pl-2 pr-2 pb-2 pt-0  scroll-bar" :class="{ 'mini-card-text': miniAlertCard }">
                            <table class="table-sm w-100" style="width:100%;">
                                <tbody>
                                    <tr
                                        v-for="(item, index) in alertNotifications"
                                        :key="index"
                                        @click="selectedItem = item"
                                        v-bind:class="{ selected: selectedItem == item }"
                                    >
                                        <td class="text-left" style="width:25px;">{{item.id}}</td>
                                        <td class="text-left">{{item.title}}</td>
                                        <td class="text-left" style="width:100px;">{{dateFormat(item.timestamp)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-card-text>
                    </v-card>

                    <v-card class="" style="border:1px solid gainsboro;  height:45%;" elevation="0">
                        <v-card-title class="d-flex pl-2 pr-2 pt-1 pb-1 clickable" @click="miniRegularCard = !miniRegularCard">
                            <span @mouseover="se_recentRegularShow " @mouseleave="se_recentRegularoff">
                                Recent regular notifications<span>
                                    <v-badge
                                        class="pl-2 text-center rounded-0"
                                        color="green" :content="regularNotifications.length || '0'"
                                        @mouseover="se_recentRegularShow " @mouseleave="se_recentRegularoff">
                                    </v-badge>
                                </span>
                            </span>
                            <span class="ml-auto" >
                                <v-icon>{{!miniRegularCard?'mdi-chevron-down':'mdi-chevron-up'}}</v-icon>
                            </span>

                        </v-card-title>
                        <v-card-text v-if="miniRegularCard" style="height:75%" class="black--text pl-2 pr-2 pb-2 pt-0 scroll-bar" :class="{ 'mini-card-text': miniRegularCard }">
                            <table class="table-sm w-100" style="width:100%;">
                                <tbody>
                                    <tr
                                        v-for="(item, index) in regularNotifications"
                                        :key="index"
                                        @click="selectedItem = item"
                                        v-bind:class="{ selected: selectedItem == item }"
                                    >
                                        <td class="text-left" style="width:25px;">{{item.id}}</td>
                                        <td class="text-left">{{item.title}}</td>
                                        <td class="text-left" style="width:100px;">{{dateFormat(item.timestamp)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-card-text>
                    </v-card>
                </div>

            </v-col>
            <v-col
                cols="12"
                md="7"
                lg="7"
                class="pt-1"
            >
                <div v-if="selectedItem">
                    <div class="font-weight-bold">{{selectedItem.id}}. {{selectedItem.title}} ({{dateFormat(selectedItem.timestamp)}})</div>
                    <v-card
                        elevation="0"
                        color="blue-grey lighten-5"
                        class="py-2 px-3 scroll-bar"
                        style="height:37.5vh"
                    >
                        <div>Date: {{dateFormat(selectedItem.timestamp)}}</div>
                        <div>From: {{selectedItem.from}}</div>
                        <div>To: {{selectedItem.to}}</div>
                        <div class="my-2"><hr></div>
                        <div v-html="selectedItem.content"></div>
                    </v-card>

                </div>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { dateFormat } from "@/mixins/commons";
import EventBus from '@/plugins/EventBus.js';
import { PTestApi } from '@/mixins/commons';

export default {
    data: ()=>({
        miniRegularCard: true,
        miniAlertCard:true,
        alertNotifications: [],
        regularNotifications: [],
        selectedItem: null
    }),
    methods: {
        se_recentAlertShow: function(){
            EventBus.$emit('se_recentAlertShow', `You can see the contents of system's alert notifications (eg, CPU utilization exceeding 90%) that have occurred in the last 3 months.`);
        },
        se_recentAlertoff: function(){
            EventBus.$emit('se_recentAlertShow', null);
        },
        se_recentRegularShow: function(){
            EventBus.$emit('se_recentRegularShow', `You can see the contents of system's alert notifications (eg CPU usage rate, etc.) that the system sends on a regular basis once a week.`);
        },
        se_recentRegularoff: function(){
            EventBus.$emit('se_recentRegularShow', null);
        },

        dateFormat(date){
            return dayjs(date).format(dateFormat)
        },
        getAlertNotifications: function(){
            axios.get(`${PTestApi}/log/email/evt`)
            .then(result=>{
                this.alertNotifications = result.data;
                this.selectedItem = result.data[0];
            })
            .catch(err=>{
                console.warn('oops: ',err);
            })

        },
        getRegularNotifications: function(){
            axios.get(`${PTestApi}/log/email/reg`)
            .then(result=>{
                this.regularNotifications = result.data;
            })
            .catch(err=>{
                console.warn('oops: ',err);
            })
        }
    },
    mounted(){
        this.getAlertNotifications();
        this.getRegularNotifications();
    }

}
</script>

<style scoped>
    .mini-card-text{
        height: 90px;
        overflow-y: auto;
        scrollbar-width: none !important;
        -ms-overflow-style: none !important;
    }
    .selected{
        background-color: #eceff1;
        font-weight: bold;
    }
</style>