<template>
    <v-container fluid class="pa-0 pt-xl-2 pt-md-0">
        <v-row no-gutters class="pt-0 pb-xl-2 pb-md-1 pt-1 ml-3">
            <v-col cols="12" md="6" class="pa-0 ml-md-n9 ml-xl-n11 " >
                <div class="text-center black--text" @mouseover="cr_totalscoreShow " @mouseleave="cr_totalscoreoff">TOTAL SCORE</div>
                <div class="d-flex" @mouseover="cr_totalscoreShow " @mouseleave="cr_totalscoreoff">
                    <v-gauge
                        
                        :key="'T'+gaugeHeight"
                        :value="parseInt(total.number)"
                        :height="gaugeHeight"
                        :width="gaugewidth"
                        :options="options"
                        :maxValue="10"
                        :minValue="0"
                        class=" mx-auto d-flex"
                    ></v-gauge>
                </div>
                <div class=" fluid  text-center ">
                    <h2 class="black--text mt-xl-n7 mt-md-n6  ml-xl-0 ml-lg-0 ml-md-3 ml-sm-3">{{total.number}}</h2>
                    <v-chip
                        :color="scoreColor(total.number)"
                        x-small
                        style="width:3vw; height:0.8vh; "
                        class="mt-n1 ml-md-3 ml-lg-0 "
                    >
                    </v-chip>
                </div>
            </v-col>
            
            <v-col cols="12" md="6" xl="5" class=" pr-md-1 ml-md-3 ml-xl-9 fluid">
               
                <div class=" pt-4 pb-4 text-center" style="height:90%;width:100%; ">
                    <div class="box  pt-4 pb-4  pa-md-0 py-lg-2 pa-xl-4">
                        <div class="pa-1 px-1 mb-n4 row">
                            <span style="font-size:1em;  width:27%;" @mouseover="cr_totalShow " @mouseleave="cr_totaloff">TOTAL</span>
                            <span style="font-size:2em;font-weight:bold;" class=" d-lg-inline" @mouseover="cr_totalShow " @mouseleave="cr_totaloff">{{total.number}}</span>
                            <span style="font-size:2em;font-weight:bold;"> (</span>
                            <span style="font-size:2em;font-weight:bold;" v-show="!totalplus">+</span>
                            <span style="font-size:2em;font-weight:bold;">{{(total.number - total.bnumber).toFixed(2)}}</span>
                            <span style="font-size:2em;font-weight:bold;">)</span>
                        </div>
                        <div class="pa-3 px-1 mb-n4 row">
                            <span style="font-size:1em; width:27%;" @mouseover="cr_vulnShow " @mouseleave="cr_vulnoff">VULN.</span>
                            <span style="font-size:2em;font-weight:bold;"  class="  d-lg-inline" @mouseover="cr_vulnShow " @mouseleave="cr_vulnoff">{{vuln.number}}</span>
                            <span style="font-size:2em;font-weight:bold;"> (</span>
                            <span style="font-size:2em;font-weight:bold;" v-show="!vulnplus">+</span>
                            <span style="font-size:2em;font-weight:bold;">{{(vuln.number - vuln.bnumber).toFixed(2)}}</span>
                            <span style="font-size:2em;font-weight:bold;">)</span>
                        </div>
                        <div class="pa-2 px-1 row">
                            <span style="font-size:1em; width:27%;" @mouseover="cr_threatShow " @mouseleave="cr_threatoff">THREAT</span>
                            <span style="font-size:2em;font-weight:bold;"  class="  d-lg-inline" @mouseover="cr_threatShow " @mouseleave="cr_threatoff">{{threat.number}}</span>
                            <span style="font-size:2em;font-weight:bold;"> (</span>
                            <span style="font-size:2em;font-weight:bold;" v-show="!threatplus">+</span>
                            <span style="font-size:2em;font-weight:bold;">{{(threat.number - threat.bnumber).toFixed(2)}}</span>
                            <span style="font-size:2em;font-weight:bold;">)</span>
                        </div>
                    </div>
                </div>
               
            </v-col>
        </v-row>
        <v-row no-gutters class="pt-0 pb-xl-3 pb-md-0 mt-md-0 mt-xl-2 ml-3">
            <v-col cols="12" md="6" class="pa-0 ml-md-n9 ml-xl-n11 pb-mb-1 pb-lg-0">
                <div class="text-center black--text" @mouseover="cr_vulnscoreShow " @mouseleave="cr_vulnscoreoff">VULN SCORE</div>
                <div class="d-flex" @mouseover="cr_vulnscoreShow " @mouseleave="cr_vulnscoreoff">
                    <v-gauge
                        :key="'T'+gaugeHeight"
                        :value="parseInt(vuln.number)"
                        :height="gaugeHeight"
                        :width="gaugewidth"
                        :options="options"
                        :maxValue="10"
                        :minValue="0"
                        class="mx-auto d-flex"
                    ></v-gauge>
                </div>
                <div class=" flex text-center">
                    <h2 class="black--text mt-xl-n7 mt-md-n6 ml-md-3 ml-lg-0">{{vuln.number}}</h2>
                    <v-chip
                        :color="scoreColor(vuln.number)"
                        x-small
                        style="width:3vw; height:0.8vh;"
                        class="mt-n2 ml-md-3 ml-lg-0"
                    >
                    </v-chip>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
                <div class="text-center black--text" @mouseover="cr_threatscoreShow " @mouseleave="cr_threatscoreoff">THREAT SCORE</div>
                <div class="d-flex" @mouseover="cr_threatscoreShow " @mouseleave="cr_threatscoreoff">
                    <v-gauge
                        :key="'T'+gaugeHeight"
                        :value="parseInt(threat.number)"
                        :height="gaugeHeight"
                        :width="gaugewidth"
                        :options="options"
                        :maxValue="10"
                        :minValue="0"
                        class="mx-auto d-flex"
                    ></v-gauge>
                </div>
                <div class=" flex text-center">
                    <h2 class="black--text mt-xl-n7 mt-md-n6 ml-md-5 ml-lg-0">{{threat.number}}</h2>
                    <v-chip
                        :color="scoreColor(threat.number)"
                        x-small
                        style="width:3vw; height:0.8vh;"
                        class="mt-n2 ml-md-5 ml-lg-0"
                    >
                    </v-chip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VGauge from 'vgauge';
import { colors, PTestConn  } from '@/mixins/commons';
import axios from 'axios';
import { index } from '@/mixins/elastic';
import EventBus from '@/plugins/EventBus.js';
import { baseCycleQuery, baseBCycleQuery } from '@/mixins/queries';
export default {
    name: `CurrentRisk`,
    components: {
        VGauge
    },
    props: {
        cycle: {
            type: Number,
            required: true
        },
        bcycle: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            nummmm:-2,
            total: {
                number: 0,
                bnumber: 0
            },
            threat: {
                number: 0,
                bnumber: 0
            },
            vuln: {
                number: 0,
                bnumber: 0
            },
            options: {
                angle: -0.23, /// The span of the gauge arc
                radiusScale: 1,
                lineWidth: 0.18, // The line thickness
                pointer: {
                    length: 0.6, // Relative to gauge radius
                    strokeWidth: 0.04 // The thickness
                },
                strokeColor: '#E0E0E0',   // to see which ones work best for you
                staticZones: [
                {strokeStyle: colors.low, min: 0, max: 3.9},
                {strokeStyle: colors.medium, min: 4, max: 6.9},
                {strokeStyle: colors.high, min: 7, max: 8.9},
                {strokeStyle: colors.critical, min: 9, max: 10}
                ],
                staticLabels: {
                font: "12px sans-serif",  // Specifies font
                labels: [0, 4, 7, 9, 10],  // Print labels at these values
                color: "#000000",  // Optional: Label text color
                fractionDigits: 0
                }
            }
        }
    },
    computed: {
        gaugeHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case `xs` : return "80vh"
                case `sm` : return "80vh"
                case `md` : return "102vh"
                case `lg` : return "105vh"
                case `xl` : return "105vh"
            }
            return "105vh"
        },
         gaugewidth () {
            switch (this.$vuetify.breakpoint.name) {
                case `xs` : return "180px"
                case `sm` : return "180px"
                case `md` : return "180px"
                case `lg` : return "180px"
                case `xl` : return "180px"
            }
            return "180px"
        },

        totalplus(){
            if((this.total.number - this.total.bnumber) >= 0) {
                return false
            }
            return true
        },
        vulnplus(){
            if((this.vuln.number - this.vuln.bnumber) >= 0) {
                return false
            }
            return true
        },
        threatplus(){
            if((this.threat.number - this.threat.bnumber) >= 0) {
                return false
            }
            return true
        }

    },
    mounted(){
        this.getData();
        this.getBData();
    },
    created(){
        
    },
    methods:{
        cr_totalscoreShow: function(){
            EventBus.$emit('cr_totalscoreShow', `All Vulnerability Scan results and Threat Scan results so far are analyzed by the Risk Score System, and the current risk is displayed in Score (0.0~10.0) and Class Level (Low/Medium/High/Critical).`);
        },
        cr_totalscoreoff: function(){
            EventBus.$emit('cr_totalscoreShow', null);
        },
        cr_vulnscoreShow: function(){
            EventBus.$emit('cr_vulnscoreShow', `By analyzing all the vulnerability scan results so far with the Risk Score System which developed based on CVSS v3.0, the currect vulnerability level is displayed as a score(0.0~10.0) and class level(Low/Medium/High/Critical).`);
        },
        cr_vulnscoreoff: function(){
            EventBus.$emit('cr_vulnscoreShow', null);
        },
        cr_threatscoreShow: function(){
            EventBus.$emit('cr_threatscoreShow', `By analyzing all the threat scan results so far with the Risk Score System which developed based on CVSS v3.0, the currect threat level is displayed as a score(0.0~10.0) and class level(Low/Medium/High/Critical).`);
        },
        cr_threatscoreoff: function(){
            EventBus.$emit('cr_threatscoreShow', null);
        },

        cr_totalShow: function(){
            EventBus.$emit('cr_totalShow', `The Total Score based on the CVSS v3.0 is computed and displayed as the average of numerous Vulnerability Scores and Threat Scores, and the standard deviation of the score is displayed in parentheses to help interpret the meaning of the score.`);
        },
        cr_totaloff: function(){
            EventBus.$emit('cr_totalShow', null);
        },
        cr_vulnShow: function(){
            EventBus.$emit('cr_vulnShow', `The Vulnerability Score based on the CVSS v3.0 is displayed the result of analyzing numerous Vulnerability Scan result data with the Risk Score System, and the standard deviation of the score is displayed together in parentheses.`);
        },
        cr_vulnoff: function(){
            EventBus.$emit('cr_vulnShow', null);
        },
        cr_threatShow: function(){
            EventBus.$emit('cr_threatShow', `The Threat Score based on the CVSS v3.0 is displayed the result of analyzing numerous Threat Scan result data with the Risk Score System, and the standard deviation of the score is displayed together in parentheses.`);
        },
        cr_threatoff: function(){
            EventBus.$emit('cr_threatShow', null);
        },

    
        getData(){
            const query = baseCycleQuery(this.cycle);
            axios.post( PTestConn , { 
                    queryurl : index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {
                // console.log('currentRisk', res);
                const data = res.data.aggregations;
                this.total.number = data.total_median.values.score_gt_zero.toFixed(2);
                this.threat.number = data.threat_median.values.score_gt_zero.toFixed(2);
                this.vuln.number = data.vuln_median.values.score_gt_zero.toFixed(2);
            })
        },

        getBData(){
            const query = baseBCycleQuery(this.bcycle);
            axios.post( PTestConn  , { 
                    queryurl : index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {
                // console.log('bbbcurrentRisk', res);
                const data = res.data.aggregations;
                this.total.bnumber = data.total_median.values.score_gt_zero.toFixed(2);
                this.threat.bnumber = data.threat_median.values.score_gt_zero.toFixed(2);
                this.vuln.bnumber = data.vuln_median.values.score_gt_zero.toFixed(2);
            })
        },



        scoreColor: function(score){
            if (score < 4){
                return colors.low
            } else if (score >= 4 && score < 7){
                return colors.medium
            } else if (score >= 7 && score < 9){
                return colors.high
            } else if (score >= 9 && score <= 10){
                return colors.critical
            } else {
                return colors.none
            }
        }

    }
}
</script>

<style scoped>  
    ::v-deep .gauge-title{
        display: none !important;
    }
    .theme--light.v-chip:hover::before {
        opacity: 0;
    }
    .box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        color: black;
    }
</style>