<script>
import { Bar } from 'vue-chartjs'
import zoom from 'chartjs-plugin-zoom';
import crosshair from 'chartjs-plugin-crosshair';

export default {
    extends: Bar,
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted () {
        this.addPlugin(crosshair);
        this.addPlugin(zoom);
        this.renderChart(this.chartdata, this.options)
    }
}
</script>