<template>
  <div class="pa-4 pt-0">
    <v-container fluid class="px-0">
      <v-card-title class="pb-0 pt-0 black--text pl-1 mb-xl-1 mb-md-0">
        <h4
          @mouseover="riskMapShow"
          @mouseleave="riskMapoff"
          style="font-weight: unset"
        >
          RISK MAP
        </h4>
        <div class="ml-auto mr-n3" style="opacity: 50%">
          <v-btn
            color="red darken-1"
            style="height: 24px; min-width: 24px; padding: 0 4px"
            class="mb-1 ml-1"
            outlined
            dark
            @click="exportCSV"
          >
            <v-icon
              dark
              size="160%"
              @mouseover="rm_csvShow"
              @mouseleave="rm_csvoff"
            >
              mdi-file-download-outline
            </v-icon>
          </v-btn>
          <v-btn
            id="map-export-png"
            color="red darken-1"
            style="height: 24px; min-width: 24px; padding: 0 4px"
            outlined
            dark
            class="ml-1 mb-1"
          >
            <v-icon
              dark
              size="160%"
              @mouseover="rm_imgeShow"
              @mouseleave="rm_imgeoff"
            >
              mdi-image
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-row>
        <v-col cols="12" class="pa-1 mt-xl-0 mt-md-n1">
          <div>
            <div
              class="mr-auto black--text font-weight-bold text-body-1"
              style="text-align: center"
            >
              <span class="pr-2">{{ topicLabel }}</span>
              <span>({{ dateLabel }})</span>
              <span
                ><v-btn
                  icon
                  x-small
                  color="primary"
                  class="ml-2 mt-1"
                  @click="closecontext()"
                >
                  <v-icon size="260%" style="margin-bottom: 4px"
                    >mdi-cached</v-icon
                  >
                </v-btn></span
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="py-md-0">
          <v-simple-table
            dense
            de
            fixed-header
            v-bind:style="{ height: stableHeight }"
            style="scrollbar-width: none !important; border-top: outset;border-top-width: 1px;border-top-color: #dcdcdc;border-bottom: inset;border-block-width: 1px;border-bottom-color: #dcdcdc;"
            class="v-data-tabe scroll-bar"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    @mouseover="rm_deviceCountShow"
                    @mouseleave="rm_deviceCountoff"
                  >
                    Country
                  </th>
                  <th
                    class="text-center"
                    @mouseover="rm_countryShow"
                    @mouseleave="rm_countryoff"
                  >
                    Devices
                  </th>
                  <th class="text-center">Total(VS,TS)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in mapData" :key="item.key">
                  <td
                    style="border-bottom: none"
                    class="text-capitalize text-center"
                  >
                    {{ item.div_country }}
                  </td>
                  <td style="border-bottom: none" class="text-center">
                    <v-btn
                      color="#1A237E"
                      class="font-weight-black"
                      style="height: 22px"
                      text
                      @click.stop="showContext(item)"
                    >
                      {{ item.doc_count | comma }}
                    </v-btn>
                  </td>

                  <td style="border-bottom: none" class="text-center">
                    {{ item.total_score }} ({{ item.vuln_score }},{{
                      item.threat_score
                    }})
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="py-md-0 mb-3 mb-md-0 pl-2"
          v-bind:style="{ height: MapHeight }"
          @mouseover="rm_mapShow"
          @mouseleave="rm_mapoff"
        >
          <map-container
            :key="'K' + mapDefaults.loading + MapHeight"
            :zoom="mapDefaults.zoom"
            :center="mapDefaults.center"
            :minZoom="mapDefaults.minZoom"
            :maxZoom="mapDefaults.maxZoom"
            :mapData="mapData"
          ></map-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { globals, mapCenter, PTestConn } from "@/mixins/commons.js";
import { index } from "@/mixins/elastic.js";
import { chartDateFormat } from "@/mixins/commons";
import {
  riskMapQuery1,
  riskMapQuery2,
  riskMapCustomQuery,
} from "@/mixins/queries.js";
import MapContainer from "@/components/charts/MapContainer.vue";
import dayjs from "dayjs";
import Papa from "papaparse";
import EventBus from "@/plugins/EventBus.js";

export default {
  name: `RiskMap`,
  components: {
    MapContainer,
  },
  props: {
    params: Object,
  },
  data: () => ({
    drawer: false,

    selectOptions: [
      { name: 'Devices with "UP" Status', value: "up" },
      { name: "Devices with OPEN Service Port", value: "open" },
      { name: "Devices with the Vulnerable Score", value: "vuln" },
      { name: "Devices with the Threat Score", value: "threat" },
    ],
    zoom: 4,
    mapDefaults: {
      zoom: 1,
      minZoom: 1,
      maxZoom: 16,
      center: mapCenter,
      rotation: 0,
      url: globals.mapTile,
      loading: 0,
    },
    mapData: [],
  }),

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  computed: {
    selection: function () {
      return this.params.topic;
    },
    endDate: function () {
      return this.params.endDate;
    },
    startDate: function () {
      return this.params.startDate;
    },
    dateLabel: function () {
      return (
        dayjs(this.params.startDate).format(chartDateFormat) +
        " ~ " +
        dayjs(this.params.endDate).format(chartDateFormat)
      );
    },
    topicLabel: function () {
      switch (this.params.topic) {
        case "up":
          return 'Devices with "UP" Status';
        case "open":
          return "Devices with OPEN Service Port";
        case "vuln":
          return "Devices with the Vulnerable Score";
        case "threat":
          return "Devices with the Threat Score";

        default:
          return "Option not recognized";
      }
    },
    MapHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case `xs`:
        return "315px";
        case `sm`:
        return "315px";
        case `md`:
          return "315px";
        case `lg`:
          return "315px";
        case `xl`:
          return "450px";
      }
      return 100;
    },
    stableHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case `xs`:
          return "315px";
        case `sm`:
         return "315px";
        case `md`:
          return "315px";
        case `lg`:
          return "315px";
        case `xl`:
          return "450px";
      }
      return 430;
    },
  },
  mounted() {
    this.getDevices();
  },
  methods: {
    riskMapShow: function () {
      EventBus.$emit(
        "riskMapShow",
        `The search result of 'QUERY CONDITION' is displayed as a map and table, and you can control the location move or zoom in/out on the map with the mouse, and you can download the result as an image file or CSV file.`
      );
    },
    riskMapoff: function () {
      EventBus.$emit("riskMapShow", null);
    },
    rm_csvShow: function () {
      EventBus.$emit(
        "rm_csvShow",
        `If you click this button, you can download the table contents to your local disk in CSV file format, and this function is useful when processing the downloaded data to create separate data.`
      );
    },
    rm_csvoff: function () {
      EventBus.$emit("rm_csvShow", null);
    },
    rm_imgeShow: function () {
      EventBus.$emit(
        "rm_imgeShow",
        `If you click this button, you can download the current content of the map to the local disk as an image format (png) file, and this function is useful when processing the downloaded map image file to create separate data.`
      );
    },
    rm_imgeoff: function () {
      EventBus.$emit("rm_imgeShow", null);
    },
    rm_deviceCountShow: function () {
      EventBus.$emit(
        "rm_deviceCountShow",
        `The table contents indicates the searched results by 'QUERY CONDITION' at the top of the screen, grouped by geography location, and the 'Device Count' of the table indicates the total number of IP devices located in the same location.`
      );
    },
    rm_deviceCountoff: function () {
      EventBus.$emit("rm_deviceCountShow", null);
    },
    rm_countryShow: function () {
      EventBus.$emit(
        "rm_countryShow",
        `The table contents displays the results searched by 'QUERY CONDITION' at the top of the screen, grouped by location geographically, and the 'Country' displays the name of the country and city of the location.`
      );
    },
    rm_countryoff: function () {
      EventBus.$emit("rm_countryShow", null);
    },
    rm_lat_lonShow: function () {
      EventBus.$emit(
        "rm_lat_lonShow",
        `The table contents displays the results searched by 'QUERY CONDITION' at the top of the screen, grouped by location geographically, and the 'Lat/Lon' means latitude and longitude of the location.`
      );
    },
    rm_lat_lonoff: function () {
      EventBus.$emit("rm_lat_lonShow", null);
    },
    rm_mapShow: function () {
      EventBus.$emit(
        "rm_mapShow",
        `To change your location on the map, you can move it with CTL+Mouse left click drag, and for zoom-in/out of the map, you need to use the +, - buttons in the upper left corner of the map.`
      );
    },
    rm_mapoff: function () {
      EventBus.$emit("rm_mapShow", null);
    },

    closecontext: function () {
      this.mapDefaults.zoom = 1;
      this.mapDefaults.center = mapCenter;
      this.mapDefaults.loading = 0;
      this.$store.commit("closeContextMenu");
    },

    exportCSV: function () {
      const csv = Papa.unparse(this.mapData);
      const blob = new Blob([csv]);
      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        blob,
        { type: "text/csv;charset=utf-8;" } + encodeURIComponent(csv)
      );
      a.download = "Risk Map " + dayjs().format() + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    getDevices: function (keyword = `up`) {
      const start = this.startDate.format();
      const starttime = start;
      const end = this.endDate.format();
      const endtime = end;
      const query = riskMapQuery1(keyword, starttime, endtime);
      // console.log('Query: ', query);
      axios
        .post(PTestConn, {
          queryurl: index.nmap + "/_search/template",
          querybody: JSON.stringify(query),
        })
        .then((res) => {
          // console.log(res,'risk map table');
          this.mapData = res.data.aggregations.city_totals.buckets.map((x) => {
            return {
              doc_count: x.doc_count,
              key: x.key,
              div_city:
                x.coordinates.hits.hits[0]._source.geoip.city_name || " -- ",
              div_country:
                x.coordinates.hits.hits[0]._source.geoip.country_name,
              total_score: x.total_stats.avg.toFixed(2),
              vuln_score: x.vuln_stats.avg.toFixed(2),
              threat_score: x.threat_stats.avg.toFixed(2),

              div_lat: x.coordinates.hits.hits[0]._source.geoip.location.lon,
              div_lon: x.coordinates.hits.hits[0]._source.geoip.location.lat,
              coord: [
                x.coordinates.hits.hits[0]._source.geoip.location.lon,
                x.coordinates.hits.hits[0]._source.geoip.location.lat,
              ],
            };
          });
          console.log(this.mapData);
        })
        .finally(() => {
          this.mapDefaults.loading++;
        });
    },
    showContext: function (row) {
      this.$store.commit("openContextMenu");

      this.mapDefaults.zoom = 12;
      this.mapDefaults.center = [row.div_lat, row.div_lon];
      this.mapDefaults.loading++;
      const start = this.startDate.format();
      const starttime = start;
      const end = this.endDate.format();
      const endtime = end;
      const title = this.topicLabel;
      const countryname = row.div_country;
      const query = riskMapQuery2(this.selection, starttime, endtime);

      var list = {};
      var linkedList = {};
      var commit = this.$store.commit;
      // console.log(row);

      const customQuery = riskMapCustomQuery(
        countryname,
        starttime,
        endtime,
        this.selection
      );
      axios
        .post(PTestConn, {
          queryurl: index.nmap + "/_search/template",
          querybody: JSON.stringify(query),
        })
        .finally(() => {
          axios
            .post(PTestConn, {
              queryurl: index.nmap + "/_search",
              querybody: JSON.stringify(customQuery),
            })
            .then((res) => {
              // console.log(res,'qustomquery')
              list = res.data.aggregations.host_scores.buckets.map((x) => {
                return { element: x.key, value: x.doc_count };
              });
              linkedList = res.data.hits.hits.map((x) => {
                return {
                  element: x._source.ipaddr,
                  value:
                    x._source.score && x._source.score.total_score
                      ? x._source.score.total_score
                      : 0,
                  href: `/#/search/command/ip/${x._source.ipaddr}`,
                };
              });
            })
            .finally(() => {
              const payload = {
                title: title,
                loading: false,
                listTitle: {
                  element: "Country",
                  value: row.div_country,
                },

                list: list,
                linkedListTitle: `Move to Search:`,
                linkedList: linkedList,
              };
              commit("setContextData", payload);
            });
        });
    },
  },
  watch: {
    selection: function () {
      this.getDevices(this.selection);
    },
    endDate: function () {
      this.getDevices(this.selection);
    },
    startDate: function () {
      this.getDevices(this.selection);
    },
  },
};
</script>

<style scoped>
div.v-data-table__wrapper {
  overflow-y: hidden;
}

div.v-data-table__wrapper.no-scroll {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.v-data-tabe /deep/ .v-data-table__wrapper {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.v-data-table--dense /deep/.v-data-table__wrapper > table > tbody > tr > td {
  height: 31px;
}
</style>
