<template>
  <div class="pa-4 ">
    <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" > OSINT DB Scan </div>
      <div class="" style="width:100%;">
        <span class="pl-1 pr-1 mb-4">Current Blacklist Status:</span>
        <span><v-chip class=" font-weight-bold mr-1 " label :color="listed?'red darken-2':'green'" text-color="white" x-small style="font-size:76%; " >{{listed?'LISTED':'NOT LISTED'}}</v-chip></span>
      </div>
      <div class="mt-1 mb-1 pl-1" > Detections by OSINT DB categories</div>
            <div  class="pl-2 pb-3 " style=" width:100%; border-bottom: 1px solid #d3d4e6;">
                <table >
                <tbody>
                    <tr
                    v-for="(item, name) in category"
                    :key="name">
                    <v-row class="pa-3">
                        <td><v-icon small class="pr-1">mdi-circle-medium</v-icon></td>
                        <td>{{ name }}</td>
                        <td class="text-left pl-2">( {{ item.value }} / {{ item.max }} )</td>
                    </v-row>
                    </tr>
                </tbody>
                </table>
            </div>

        <div class="pl-1 pr-1 mb-4 pt-3">
          <span class="d-flax">{{threatsFound.length}} threats detected in {{numbers.threatDB}} OSINT DBs</span>
          <div v-if="threatsFound.length>0">
            <v-card 
                class="mt-1 pt-2 mb-2"
                outlined
                v-for="(threat, index) in threatsFound" :key="index"
                color="#ECEFF1"
                style="border:1px solid #d3d4e6;">
                <v-card-text class="pt-0 pb-2">
                  <div >
                    <div>
                      Category: {{threat.category ? threat.category[0] : 'N/A'}}
                    </div>
                    <div>
                      OSINT DB: {{threat.maintainer_url ? threat.maintainer_url[0] : 'N/A'}}
                    </div>
                    <div>
                    Maintainer: {{threat.maintainer ? threat.maintainer[0] : 'N/A'}}
                    </div>
                    <div>
                    Current Status: {{threat.current_status ? threat.current_status[0] : 'N/A'}}
                    </div>
                    <div>
                    First Seen: {{threat.first_seen ? threat.first_seen[0] : 'N/A'}}
                    </div>
                    <div>
                    Last Added: {{threat.last_added ? threat.last_added[0] : 'N/A'}}
                    </div>
                    <div>
                    Last Removed: <span v-if="threat.last_removed">{{threat.last_removed[0]}}</span><v-chip v-else class="font-weight-bold mr-1" label color="red darken-2" text-color="white" x-small style="font-size:76%; " >NOT YET</v-chip>
                    </div>
                  </div>
                </v-card-text>
            </v-card>
          </div>
        </div>  

  </div>
</template>

<script>
import axios from 'axios';
import { index } from '@/mixins/elastic';
import { PTestConn } from "@/mixins/commons";

export default {
  data () {
    return {
      listed: false,
      threatsFound:[],
      numbers: {
                threatDB: 0,
                vulnCount: 0,
                cveCount: 0
            },
      category: {
        abuse: {
          value: 0,
          max : 40
        },
        anonymizers:{
          value: 0,
          max : 46
        },
        attacks:{
          value: 0,
          max : 164
        },
        malware: {
          value: 0,
          max : 91
        },
        organizations: {
          value: 0,
          max : 45
        },
        reputation: {
          value: 0,
          max : 20
        },
        spam: {
          value: 0,
          max : 27
        },
        unroutable: {
          value: 0,
          max : 2
        }
      }
    } 
  },
  props: {
    threat: Object
  },
  mounted(){
    this.getData();
    // console.log(this.threat)
    if(this.threat.blacklisted_count){
      if(this.threat.blacklisted_count[0]>0 || this.threat.currently_blacklisted_count[0]>0){
        if(this.threat.results){
          if(this.threat.results[0].currently_blacklisted[0] == 'true'){
            this.listed = true;
          }else{
             this.listed = false;
          }
        }

      }
    }
    if (this.threat.results){
      // console.log(this.threat.results[0].hits)
      this.threatsFound = this.threat.results[0].hits;
      this.threat.results[0].hits.forEach(hit => {
        switch (hit.category[0]) {
          case `abuse`:
            this.category.abuse.value ++
            break;
          case `anonymizers`:
            this.category.anonymizers.value ++
            break;
          case `attacks`:
            this.category.attacks.value ++
            break;
          case `malware`:
            this.category.malware.value ++
            break;
          case `organizations`:
            this.category.organizations.value ++
            break;
          case `reputation`:
            this.category.reputation.value ++
            break;
          case `spam`:
            this.category.spam.value ++
            break;
          case `unroutable`:
            this.category.unroutable.value ++
            break;
          default:
            break;
        } 
      });
    }
  },
  methods: {
    getData(){
      console.log(index);
            axios.post( PTestConn, { 
                    queryurl : index.databaseCount+'/_search',
                    querybody : '',
             })
            .then(res => {
                const data = res.data.hits.hits;
                data.forEach(x => {
                    if (x._id == `cve`){
                        this.numbers.cveCount = new Intl.NumberFormat().format(x._source.count);
                    }else if(x._id == `nse`){
                        this.numbers.vulnCount = new Intl.NumberFormat().format(x._source.count);
                    }else if(x._id == `threat`){
                        this.numbers.threatDB = new Intl.NumberFormat().format(x._source.count);
                    }
                });
            })
        }

  }
}

</script>

