<template>
    <canvas :id="id" style="max-height:125px; max-width:125px;"></canvas>
</template>

<script>
import { authenticator } from 'otplib';
import QRCode from 'qrcode';
import {siteName} from '@/mixins/commons';
export default {
    props: {
        userData: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            default: 'qr'
        }
    },
    mounted(){
        const user = this.userData
        const service = siteName;
        const otpauth = authenticator.keyuri(user.id, service, user.secret);
        const userQR = document.getElementById(this.id);
        QRCode.toCanvas(userQR, otpauth, (err) => {
            if (err) {
                console.log('Error with QR');
                return
            }
        });
    }
}
</script>