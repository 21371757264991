<template>
<div style="height: 100%;">
    <v-container fluid style="height: 100%;">
        <v-card
            outlined
            elevation="1"
            class="pl-2 pr-2 mb-2"
            >
        <v-row style="height: 40%;">
            <v-col cols="12" md="5" lg="5" xl="4">
                <v-container fluid  class="pt-2 pb-1">
                    <span class="font-weight-bold ml-n2" @mouseover="system_statusShow " @mouseleave="system_statusoff" >
                                    SYSTEM STATUS
                                </span>
                    
                    <v-row class="mt-0 mb-1">
                        <v-col 
                            cols="12"
                            v-for="(server, index) in servers"
                            :key="index+server.updated_time"
                            md="4"
                            style="height:100%"
                            class="px-1 py-xl-1 py-lg-1 py-md-1 pr-1 "
                        >
                            <v-sheet
                                outlined
                                rounded
                                @mouseover="system_statusShow " @mouseleave="system_statusoff"
                                style="border: 1px #ddd solid !important; height:100%;"
                                class=" mb-1    clickable"
                                :elevation="selectedServer==server.server_name? 1: 0"
                                :color="selectedServer==server.server_name? '': 'blue-grey lighten-5'"
                                @click="selectedServer = servers[index].server_name"
                            >
                                <server-status-card 
                                    :serverData="server"
                                    :selected="selectedServer == server.server_name"
                                    style="height:100%;"
                                ></server-status-card>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" md="7" xl="8">
                <v-card
                    elevation="0"
                    v-if="serverList"
                    
                    style="min-height:320px;"
                >
                   
                           
                    <device-candlestick  :key="'candlestick'+selectedServer+refresh" :server="selectedServer" :dates="date" :chartKey="chartKey"></device-candlestick>
                </v-card>

            </v-col>
        </v-row>
        </v-card>
                                
        <v-row style="height: 55%;">
            <v-col>
                <v-card
                    elevation="1"
                    outlined
                    style="height: 100%;"
                >
                    <system-events></system-events>
                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</div>
</template>

<script>
import axios from 'axios';
import { index, serverList} from '@/mixins/elastic';
import ServerStatusCard from '@/components/settings/ServerStatusCard.vue';
import SystemEvents from "@/components/settings/SystemEvents.vue";
import {settingQuery} from '@/mixins/queries.js';
import dayjs from 'dayjs';
import { dateFormat, PTestConn  } from '@/mixins/commons';
import EventBus from '@/plugins/EventBus.js';
import DeviceCandlestick from '../components/settings/DeviceCandlestick.vue';
export default {
    name: `Settings`,
    components: {
        ServerStatusCard,
        SystemEvents,
        DeviceCandlestick
    },
    data: ()=>({
        chartKey: 0,
        refresh: 0,
        servers: [],
        serverDetails: undefined,
        serverList: serverList,
        selectedServer: 'EK',
        date: undefined,
        changedDate: undefined,
        defaultDate: [dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        dateMenu: false
    }),
    computed: {
      dateRangeText () {
        return this.changedDate.join(' ~ ')
      },
    },

    props: {
        server : String
        },

    created() {
        this.interval = setInterval(()=> this.getCurrentServersStatus(), 30000);
        this.date = this.defaultDate;
        this.changedDate = this.defaultDate;
    },
    mounted() {
        this.getCurrentServersStatus();
        this.$store.commit('closeContextMenu');

    },
    methods: {
        system_statusShow: function(){
            EventBus.$emit('system_statusShow', `You can check the resources status (CPU, Memory, Storage, Network Speed) of the servers, and the datas are reloaded and displayed again every 30 seconds.`);
        },
        system_statusoff: function(){
            EventBus.$emit('system_statusShow', null);
        },


        resetDates: function(){
            this.date = this.defaultDate;
            this.changedDate = this.defaultDate;
            this.refresh++;
        },
        dateLabel: function(){
            if (this.serverDetails){
                return dayjs(this.serverDetails.boot_time).format(dateFormat)
            }
            return false
        },
        setDate: function(){
            this.date = this.changedDate;
            this.refresh++;
        },
        getCurrentServersStatus: function(){
            const query = settingQuery();
            
             axios.post( PTestConn , { 
                    queryurl :  index.serverStatus+'/_search',
                    querybody : JSON.stringify(query),
             })

            .then(res => {
                
                this.servers = res.data.hits.hits.map(x=>{
                    return x._source
                });
                
            })
            .finally(()=>{
                this.servers.sort((a,b) => (a.server_name > b.server_name) ? 1 : -1)
            })
        },
          

    }
}
</script>