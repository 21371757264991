<template>
    <v-container fluid class="pt-xl-2 pt-md-0 pl-0 pr-0">
        <v-row>
            <v-col cols="12" class="py-1 mt-1 mb-xl-2 mb-md-1 pl-2">
                <v-container fluid>
                    <v-row align="center">
                        <v-col
                            cols="12"
                            xl="3"
                            md="2"
                            class=" pa-2 pt-md-1 pt-xl-4"
                        >
                            <span class="font-weight-bold text-uppercase" style="display:flex;">Topic:</span>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="9"
                            md="10"
                            class="pt-md-1 pt-xl-4 pb-md-2 pb-xl-3"
                        >
                            <v-select
                                v-model="selection"
                                :items="selectOptions"
                                hide-details=""
                                item-text="name"
                                item-value="value"
                                dense
                                style="font-size:14px;"
                                class="mb-2"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>

                <div class="d-flex mt-xl-0 mt-md-n1">
                    <p class="font-weight-bold text-uppercase align-self-end pb-1 pr-1 ml-2 mb-xl-1 mb-md-0" style="font-size:13px">From:</p>
                    <span>{{startDate}}</span>
                </div>
                <div>
                    <v-form>                              
                        <v-container>
                            <v-row >
                                <v-col
                                    cols="6"
                                    class="py-0 mt-xl-0 mt-md-n1"
                                >
                                    <v-text-field
                                        v-model="startDiff"
                                        dense
                                        hide-details
                                        @keyup="updateStart(startDiff, startUnit)"
                                        style="font-size:14px;"
                                        :disabled="startUnit == 'now'"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="py-0 mt-xl-0 mt-md-n1"
                                >
                                    <v-select
                                        :items="startUnits"
                                        item-text="text"
                                        item-value="value"
                                        dense
                                        hide-details
                                        v-model="startUnit"
                                        style="font-size:14px;"
                                        @change="updateStart(startDiff, startUnit)"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>
            </v-col>
            <v-col cols="12" class="py-1 mt-1 mb-2 pr-2">
                <div class="d-flex mt-xl-0 mt-md-n1">
                    <p class="font-weight-bold text-uppercase align-self-end pb-1 pr-1 ml-2 mb-xl-1 mb-md-0" style="font-size:13px">To:</p>
                    <span>{{endDate}}</span>
                </div>
                <div>
                    <v-form>                              
                        <v-container>
                            <v-row >
                                <v-col
                                    cols="6"
                                    class="py-0 mt-xl-0 mt-md-n1"
                                >
                                    <v-text-field
                                        v-model="endDiff"
                                        dense
                                        hide-details
                                        style="font-size:14px;"
                                        @keyup="updateEnd(endDiff, endUnit)"
                                        :disabled="endUnit == 'now'"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="py-0 mt-xl-0 mt-md-n1"
                                >
                                    <v-select
                                        :items="units"
                                        item-text="text"
                                        item-value="value"
                                        dense
                                        hide-details
                                        v-model="endUnit"
                                        style="font-size:14px;"
                                        @change="updateEnd(endDiff, endUnit)"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>

                <div class="d-flex pt-xl-5 pt-md-4 pr-3 mt-xl-0 mt-md-n1">
                    <v-btn
                        elevation="0"
                        @click="updateTimelineParams"
                        color="grey darken-1"
                        small
                        outlined
                        dark
                        class="pa-2 ml-auto"
                    >
                        QUERY
                        <span><v-icon small >mdi-arrow-right-drop-circle</v-icon></span>
                    </v-btn>
                </div>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/mixins/commons';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
export default {
    name: `QueryConditions`,
    data: function(){
        return {
            startDate: ``,
            endDate: ``,
            units: [
                {text: `Set to Now`, value: `now`},
                {text: `Days Ago`, value: `d-`},
                {text: `Months Ago`, value: `mo-`},
                {text: `Years Ago`, value: `y-`}
            ],
            startUnits: [
                {text: `Days Ago`, value: `d-`},
                {text: `Months Ago`, value: `mo-`},
                {text: `Years Ago`, value: `y-`}
            ],
            startDiff: `1`,
            endDiff: `1`,
            startUnit: `mo-`,
            endUnit: `now`,
            selectOptions: [
                {name:'Devices with "UP" Status', value:'up'},
                {name:'Devices with OPEN Service Port', value:'open'},
                {name:'Devices with the Vulnerable Score', value:'vuln'},
                {name:'Devices with the Threat Score', value:'threat'},

            ],
            selection: 'up'
        }
    },
    mounted(){
        this.startDate = dayjs().subtract(1, 'month').format(dateFormat);
        this.endDate = dayjs().format(dateFormat);
    },
    methods: {
        now: function(){
            return dayjs().format(dateFormat)
        },

        closecontext:function(){
            this.$store.commit('closeContextMenu');
        },

        updateStart: function (ammount, unit){
            switch (unit) {
                case `now`:
                    this.startDate = dayjs().format(dateFormat);
                    break;
                case `m-`:
                    this.startDate = dayjs().subtract(ammount, "minute").format(dateFormat);
                    break;
                case `h-`:
                    this.startDate = dayjs().subtract(ammount, "hour").format(dateFormat);
                    break;
                case `d-`:
                    this.startDate = dayjs().subtract(ammount, "day").format(dateFormat);
                    break;
                case `mo-`:
                    this.startDate = dayjs().subtract(ammount, "month").format(dateFormat);
                    break;
                case `y-`:
                    this.startDate = dayjs().subtract(ammount, "years").format(dateFormat);
                    break;
                case `m+`:
                    this.startDate = dayjs().add(ammount,  "minute").format(dateFormat);
                    break;
                case `h+`:
                    this.startDate = dayjs().add(ammount, "hour").format(dateFormat);
                    break;
                case `d+`:
                    this.startDate = dayjs().add(ammount, "day").format(dateFormat);
                    break;
                case `mo+`:
                    this.startDate = dayjs().add(ammount, "month").format(dateFormat);
                    break;
                default:
                    break;
            }
        },
        updateEnd: function (ammount, unit){
            
            switch (unit) {
                case `now`:
                    this.endDate = dayjs().format(dateFormat);
                    break;
                case `m-`:
                    this.endDate = dayjs().subtract(ammount, "minute").format(dateFormat)
                    break;
                case `h-`:
                    this.endDate = dayjs().subtract(ammount, "hour").format(dateFormat)
                    break;
                case `d-`:
                    this.endDate = dayjs().subtract(ammount, "day").format(dateFormat)
                    break;
                case `mo-`:
                    this.endDate = dayjs().subtract(ammount, "month").format(dateFormat)
                    break;
                case `y-`:
                    this.endDate = dayjs().subtract(ammount, "year").format(dateFormat)
                    break;
                case `m+`:
                    this.endDate = dayjs().add(ammount, "minute").format(dateFormat)
                    break;
                case `h+`:
                    this.endDate = dayjs().add(ammount, "hour").format(dateFormat)
                    break;
                case `d+`:
                    this.endDate = dayjs().add(ammount, "day").format(dateFormat)
                    break;
                case `mo+`:
                    this.endDate = dayjs().add(ammount, "month").format(dateFormat)
                    break;
                default:
                    break;
            }
        },
        updateTimelineParams: function(){
            const datePayload = {
                startDate: dayjs(this.startDate, dateFormat),
                endDate: dayjs(this.endDate, dateFormat)
            }
            this.$store.commit('setTimelineDates', datePayload);
            this.$store.commit('setTimelineTopic', this.selection);
            this.$store.commit('closeContextMenu');
        }
    }
}
</script>

<style scoped>
.drop-list > * {
    display: inline-block;
}

.stn {
    font-size: 11px;
    font-weight: bold;
    height: 20px;
    padding: 0 6px;
    
}

.v-chip.v-chip--outlined.v-chip.v-chip{
    background-color: #EEEEEE !important;
}

.v-input.v-label{
    font-size: 14px !important;
}

</style>