<template>

    <div class="pt-1 pr-2">
        <v-card-title class="pb-0 pt-0 black--text pl-1 mb-xl-1 mb-md-0">RISK TREND</v-card-title>


        <v-tabs height="30px" class="mt-0" >
            <v-tab @click="selectedResource = 'Total Score'" > Total </v-tab>
            <v-tab @click="selectedResource = 'Vuln Score'" > Vuln </v-tab>
            <v-tab @click="selectedResource = 'Threat Score'" > Threat </v-tab>
        </v-tabs>
        <div class=""  v-if="!ipTableLoading" style="position: relative; margin-top: -20px; width: 100%;">
            <status-box-polt-scatter
                :key="chartKey" 
                :label="'('+selectedResource+')'" 
                :dataset="fullDataset" 
                :loading="ipTableLoading"
            ></status-box-polt-scatter>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import { index } from '@/mixins/elastic';
import {statusChartQuery} from '@/mixins/queries.js';
import StatusBoxPoltScatter from '../charts/StatusBoxPoltScatter.vue';
import { PTestConn } from '@/mixins/commons';


export default {
    components: {
        StatusBoxPoltScatter
    },
    data: ()=>({
        dataset: [],
        ipTableLoading: true,
        selectedResource: 'Total Score',
        boxplot: [],

    }),
    props: {

        dates: Array,
        chartKey: Number
    },
    mounted(){
        this.getServerDataset();

    },
    watch: {
        selectedResource: function(){
            this.getServerDataset();
        }
    },
    computed: {

        fullDataset: function(){
            return [
                {
                    name: 'Percentiles',
                    type: 'boxPlot',
                    data: this.boxplot
                }
            ]
    },
    },
    methods: {
       

        doubleDigit: function(number){
            return parseFloat(number).toFixed(2)
        },

        getServerDataset: function(){
            
            let field = '';
            switch (this.selectedResource) {
                case 'Total Score':
                    field = 'total'
                    break;
                case 'Vuln Score':
                    field = 'vuln'
                    break;
                case 'Threat Score':
                    field = 'threat'
                    break;
                default:
                    break;
            }
            const starttime =  this.dates[0]+"T00:00:00.000Z";
            const endtime = this.dates[1]+"T23:59:59.999Z";
            
            const query = statusChartQuery(starttime,endtime,field);
            this.ipTableLoading = true;
            var resource = this.selectedResource;
            axios.post( PTestConn , { 
                    queryurl :  index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {

                 
                this.ipTableLoading = false;
                const boxplot = [];
                // console.log("ressssss",res);
                
                 res.data.aggregations.data_per_day.buckets.forEach(function(x){
                    if( resource == `Total Score`){
                        boxplot.push({
                            x: new Date (x.key),
                            y: [
                                
                                parseFloat(x.value2.min).toFixed(2),
                                parseFloat(x.value1.values['25.0']).toFixed(2),
                                parseFloat(x.value1.values['50.0']).toFixed(2),
                                parseFloat(x.value1.values['75.0']).toFixed(2),
                                parseFloat(x.value2.max).toFixed(2)
                            ] 
                        })
                    } else{
                        boxplot.push({
                            x: new Date (x.key),
                            y: [
                                parseFloat(x.value2.min).toFixed(2),
                                parseFloat(x.value1.values['25.0']).toFixed(2),
                                parseFloat(x.value1.values['50.0']).toFixed(2),
                                parseFloat(x.value1.values['75.0']).toFixed(2),
                                parseFloat(x.value2.max).toFixed(2)
                                
                            ] 
                        })
                    }
                     
                }
                )
                
                this.boxplot = boxplot;

            })
        }
    }
}
</script>

<style scoped>

.card-cst-icon{
    width:20px;
    height:20px;
    font-size: 12px;
}
</style>