<template>
  <v-container fluid>
    <!-- <a class="topbtn" href="#help">▲</a> -->
    <v-row>
      <!-- help의 index 부분 -->
      <v-col cols="3" md="3" xl="3">
        <v-card
          elevation="1"
          outlined
          class="black--text pb-0"
          style="position:relative;position:fixed;width:24%;height:90vh;overflow-x:hidden;padding-bottom:20px;"
        >
          <v-card-title class="title">Help Desk</v-card-title>
          <br />
          <div class="mt-n6" v-for="index in indexs" v-bind:key="index.name">
            <v-card-title @click="contentsChange(index.num, 0)" class="title1"
              >{{ index.num }}. {{ index.name }}</v-card-title
            >
            <v-card-text
              @click="contentsChange(index.num, title2.num_num)"
              class="title2"
              v-for="title2 in index.title2s"
              v-bind:key="title2.name"
              >{{ index.num }}.{{ title2.num_num }}.
              {{ title2.name }}</v-card-text
            >
            <br />
          </div>
          <div class="mt-n3">
            <v-card-text
              @click="contentsChange(13, 0)"
              class="title2"
              style="color: #4675ba"
              >ANNEX. Vulnerability Script Inventory</v-card-text
            >
            <v-card-text
              @click="contentsChange(11, 0)"
              class="title2"
              style="color: #4675ba"
              >ANNEX. CPE Inventory</v-card-text
            >
            <v-card-text
              @click="contentsChange(10, 0)"
              class="title2"
              style="color: #4675ba"
              >ANNEX. Blacklist IP Database</v-card-text
            >
            <v-card-text
              @click="contentsChange(9, 0)"
              class="title2"
              style="color: #4675ba"
              >ANNEX. User Registration Guide</v-card-text
            >
            <v-card-text
              @click="contentsChange(12, 0)"
              class="title2"
              style="color: #4675ba"
              >ANNEX. Third-Party Software and Licenses</v-card-text
            >
            <v-card-text
              @click="contentsChange(14, 0)"
              class="title2"
              style="color: #4675ba"
              >ANNEX. Product Model Line-Up</v-card-text
            >
          </div>
        </v-card>
      </v-col>

      <v-col cols="9" md="9" xl="9" class="scroll-bar">
        <v-card
          outlined
          elevation="1"
          id="help"
          style="padding: 20px 0; height: 90vh; overflow-y: auto; scroll-behavior:smooth;"
          class="scroll-bar"
        >
          <!-- explain title -->

          <v-card-title
            class="comm_title1"
            v-show="this.index === '1_1' || this.index === '1_0'"
            >1. System Introduction</v-card-title
          >
          <v-card-title
            class="comm_title1"
            v-show="this.index === '2_1' || this.index === '2_0'"
            >2. User Login and OTP</v-card-title
          >
          <v-card-title
            id="lo3_0"  
            class="comm_title1"
            v-show="this.index === '3_1' || this.index === '3_0'|| this.index === '3_2'"
            >3. Various Search Features</v-card-title
          >
          <div id="lo4_0" v-show="this.index === '4_1' || this.index === '4_0'">
            <v-card-title class="comm_title1"
              >4. Useful Search Examples </v-card-title
            >
            <v-card-text style="font-size:10pt"
              >The WiKi-RAV system provides various search keywords depending on the purpose of use. Since it provides a search function using multiple keywords as well as a single keyword, learning various types of search methods can greatly increase the utilization of this system.
            </v-card-text>
          </div>
          <div v-show="this.index === '5_1' || this.index === '5_0'">
            <v-card-title class="comm_title1"
              >5. Port and Vulnerability Scan</v-card-title
            >
            <v-card-text>
              WIKI-RAV provides the results by performing various scans such
              as Host discovery, OS Fingerprinting, Service Port scan, Banner
              information gathering, well-known vulnerability scan from the
              external attacker's point of view. And the scan results can be
              checked in the Right Frame by clicking the host's IP address on
              the Left Frame.
            </v-card-text>
            <img class="comm_img-center" src="../components/help/img/5.png" />
            <ul class="comm_ulIn">
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> Quickly detect all
                IP hosts within a wide range of networks
                <div class="comm_ul_explain">
                  Because searching lots of hosts within a specific country or
                  within a specific IP range requires fast speed and accuracy.
                  In addition to the commonly used ICMP Echo Request, various
                  types of packets such as TCP SYN, TCP ACK, UDP, ICMP, and IP
                  Protocol are sent it to multi-port of the remote networks. And
                  depending on the characteristics of the received packets, the
                  host discovery is performed like OS type and version
                  detection.<br />
                  In addition, thousands of Fingerprinting Databases are used to
                  search more than 5,000 Vendor products such as Web Cameras,
                  VoIP, POS, Industrial Controllers, Set-top Boxes, TVs, as well
                  as general Servers and Network equipment.
                </div>
              </li>
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> Scan and provide
                basic general information about discovered hosts
                <div class="comm_ul_explain">
                  WIKI-RAV provides city name, ASnum information by using the
                  discovered IP address of the host and also provides the
                  geographical location information (longitude, latitude) with
                  Google map.
                </div>
              </li>
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> Port and maximum
                500 vulnerability scans of discovered hosts
                <div class="comm_ul_explain">
                  The system checks the existence of the opened service port of
                  the discovered host and scans 100-200 service ports that are
                  the most vulnerable by Half-open scanning (Stealth scanning)
                  technique and divides the port status into three types (Closed
                  / Filtered / Opened)<br />
                  Once opened services are detected, security vulnerability
                  scanning of maximum 500 check items which you need to be
                  interested in is begun depending on the type and version of
                  the opened service.
                </div>
              </li>
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> Provides detected
                vulnerabilities using a total of 600,000 well-known global
                databases
                <div class="comm_ul_explain">
                  Based on the product name and version information of the
                  opened service, the system provides the relevant security
                  vulnerability scan results by using the vulnerability
                  databases which have been globally verified such as MITRE CVE,
                  Exploit-DB, SecurityFocus, OpenVAS (Nessus), IBM X-Force, etc.
                  The total of this vulnerability database is comprised of more
                  than 600,000 items of vulnerabilities and is constantly being
                  updated.
                </div>
              </li>
            </ul>
          </div>
          <div v-show="this.index === '6_1' || this.index === '6_0'">
            <v-card-title class="comm_title1"
              >6. OSINT Threat Information</v-card-title
            >
            <v-card-text>
              WIKI-RAV collects and provides threat information of OSINT
              about discovered IP Host.
              <br />
              Click the “THREAT” menu of the IP host block in the left frame,
              you can check the OSINT threat information related to the IP host
              in the right frame.
            </v-card-text>
            <img class="comm_img-center" src="../components/help/img/6.png" />

            <v-card-text>
              The OSINT threat information that has been proven by the global in
              the cybersecurity fields are provided, and the list is as follows.
            </v-card-text>
            <ul class="comm_ul">
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> VirusTotal
                <div class="comm_ul_explain">
                  VirusTotal is an online website that checks for malware
                  infection using more than 70 worldwide anti-virus engines
                  (https://www.virustotal.com).<br />
                  WIKI-RAV provides the searched records whether or not the
                  detected host is infected with malware by using this
                  anti-virus platform.
                </div>
              </li>
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> ThreatCrowd
                <div class="comm_ul_explain">
                  Threatcrowd providing service similar to VirusTotal, is a
                  popular OSINT information for SOC analysts and CSIRT personnel
                  because it provides and analyzes whether or not the host is
                  infected with malware. (https://www.threatcrowd.org/)
                  <br />
                  WIKI-RAV provides the searched records whether or not the
                  detected host is infected with malware by using this cloud
                  service platform.<br />
                </div>
              </li>
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> IBM X-Force
                Exchange
                <div class="comm_ul_explain">
                  IBM X-Force Exchange is a cloud-based threat information
                  sharing platform that operated by IBM's X-Force research team
                  for providing global threat intelligence, and it also includes
                  their third-party threat intelligence as well.
                  (https://exchange.xforce.ibmcloud.com/)
                  <br />
                  WIKI-RAV provides the searched record information on
                  whether or not the discovered host infected by SPAM, Phishing,
                  Bots, etc.
                </div>
              </li>
              <li>
                <span class="comm_bullet">l</span
                ><span class="comm_space">&nbsp;&nbsp;</span> Blacklist IP
                database
                <div class="comm_ul_explain">
                  The one of regular activity of the SOC staffs, CSIRT staffs,
                  and network security engineers of company or organization is
                  to collect black IP databases lists and register them with a
                  network firewall or IPS system to enhance their perimeter
                  security.
                  <br />
                  WIKI-RAV provides information such as registration time and
                  reason for registration after checking the host based on this
                  global Blacklist IP databases.
                  <br />
                  This checking uses approximately 500 blacklist IP databases,
                  such as AlienVault.com's IP reputation database and h3x.eu's
                  ASPROX Tracker, and the databases are updated quickly every 10
                  minutes or 1 day. (For more information on these database
                  lists, you can find it in Annex.)
                </div>
              </li>
            </ul>
          </div>
          <v-card-title
            class="comm_title1"
            v-show="this.index === '7_1' || this.index === '7_0'"
            >7. Various Search Features</v-card-title
          >
          <div v-show="this.index === '8_1' || this.index === '8_0'">
            <v-card-title class="comm_title1"
              >8. Search Keyword List</v-card-title
            >
          </div>
          <!-- index 중 ANNEX는 help_explain 안에 있습니다. -->

          <!-- explain contents -->
          <help_explain :index="index" :examples="examples" />

          <!-- explain contents -->
          <help_vulnerability_script :index="index" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import indexjson from "../components/help/indexList.json";
import help_explain from "../components/help/help_explain.vue";
import help_vulnerability_script from "../components/help/help_vulnerability.vue";
import exHelp_examples from "../components/help/help_examples.json";


export default {
  data: function () {
    return {
      indexs: [],
      index: "1_0",
      examples: exHelp_examples.exTitle,
      bool: false,
    };
  },
  components: {
    help_explain,
    help_vulnerability_script,
  },
  methods: {
    contentsChange(title1, title2) {
      this.index = title1 + "_0";
      setTimeout(() => this.moveScroll(title1,title2), 1);
    },
    moveScroll(index, index1){
      var location= document.getElementById("lo"+index+"_"+index1);
      if(location!=null){
        location.scrollIntoView();
      }
    },
  },
  created() {
    this.indexs = indexjson.title1;
  }
};
</script>

<style scoped>
@import "../components/help/css/help.css";

.title {
  margin: 0 auto;
  width: 180px;
  max-width: 100%;
  text-align: center;
  font-size: 2em !important;
  /* font-weight: bold !important; */
  text-align: center !important;
}
.title1 {
  padding: 0 !important;
  padding-left: 10px !important;

  color: #4675ba;
}

.title1:hover,
.title2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.title2 {
  padding: 0 !important;
  padding-left: 10px !important;
  color: #4675ba;
}
.blank {
  padding: 0 !important;
  padding-bottom: 5px !important;
}

</style>
