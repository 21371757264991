<template>
  <div style="height: 100%">
    <v-card outlined elevation="1" style="height: 100%">
      <v-row class="" style="height: 100%">
        <v-col
          cols="12"
          md="12"
          lg="12"
          xl="12"
          class="pb-2 pl-5"
          style="height: 100%"
        >
          <v-container fluid class="pb-0 pt-1">
            <v-row class="pb-0">
              <v-col class="pb-0" cols="12" sm="12" style="height: 100%">
                <div class="d-flex">
                  <span class="font-weight-bold">
                    SCAN HISTORY
                    <v-btn
                      icon
                      color="primary"
                      v-tooltip="'Reset to default'"
                      @click="resetFields()"
                    >
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </span>
                  <div class="ml-auto mt-2">
                    <v-btn
                      color="red darken-1"
                      style="height: 24px; min-width: 24px; padding: 0 4px"
                      class="mb-1 ml-1"
                      outlined
                      dark
                      @click="exportIPTableCSV()"
                    >
                      <v-icon dark size="160%">
                        mdi-file-download-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div>
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  xl="3"
                  class="pb-1"
                  style="height: 34vh"
                >
                  <v-form v-model="isValid" lazy-validation ref="form">
                    <v-text-field
                      v-model="treeSelected[0]"
                      label="Target IP/Range"
                      dense
                      class="v-input__slot"
                      solo
                      outlined
                      hide-details=""
                      flat
                      style="font-size: 14px"
                      clearable
                      required
                      :rules="[rules]"
                    ></v-text-field>
                  </v-form>
                  <v-container fluid>
                    <v-row style="height: 19vh; margin-top: 4px">
                      <v-col
                        cols="12"
                        class="py-1 mt-1 mb-xl-2 mb-md-1 pl-0 ml-n1"
                      >
                        <div class="d-flex mt-xl-0 mt-md-n1">
                          <p
                            class="font-weight-bold text-uppercase align-self-end pb-1 pr-1 ml-2 mb-xl-1 mb-md-0"
                            style="font-size: 13px"
                          >
                            From:
                          </p>
                          <span>{{ startDate }}</span>
                        </div>
                        <div>
                          <v-form>
                            <v-container>
                              <v-row>
                                <v-col cols="6" class="py-0 mt-xl-0 mt-md-n1">
                                  <v-text-field
                                    v-model="startDiff"
                                    maxlength="2"
                                    dense
                                    @keyup="updateStart(startDiff, startUnit)"
                                    style="font-size: 14px"
                                    :disabled="startUnit == 'now'"
                                    :rules="startDiffRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" class="py-0 mt-xl-0 mt-md-n1">
                                  <v-select
                                    :items="startUnits"
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    hide-details
                                    v-model="startUnit"
                                    style="font-size: 14px"
                                    @change="updateStart(startDiff, startUnit)"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </div>
                      </v-col>
                      <v-col cols="12" class="py-1 mt-0 mb-0 pr-2 pl-0 ml-n1">
                        <div class="d-flex mt-xl-0 mt-md-n1">
                          <p
                            class="font-weight-bold text-uppercase align-self-end pb-0 pr-1 ml-2 mb-xl-0 mb-md-0"
                            style="font-size: 13px"
                          >
                            To:
                          </p>
                          <span>{{ endDate }}</span>
                        </div>
                        <div>
                          <v-form>
                            <v-container>
                              <v-row>
                                <v-col cols="6" class="py-0 mt-xl-0 mt-md-n1">
                                  <v-text-field
                                    v-model="endDiff"
                                    maxlength="2"
                                    dense
                                    hide-details
                                    style="font-size: 14px"
                                    @keyup="updateEnd(endDiff, endUnit)"
                                    :disabled="endUnit == 'now'"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" class="py-0 mt-xl-0 mt-md-n1">
                                  <v-select
                                    :items="units"
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    hide-details
                                    v-model="endUnit"
                                    style="font-size: 14px"
                                    @change="updateEnd(endDiff, endUnit)"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-sheet class="pa-1 mb-0" rounded style="height: 7vh">
                    <div
                      class=""
                      style="
                        height: 100%;
                        vertical-align: bottom;
                        align-content: end;
                      "
                    >
                      <div
                        class="text-right mt-0"
                        style="height: 100%; float: right"
                      >
                        <v-btn
                          elevation="0"
                          @click="fetchScannedIP"
                          color="grey darken-1"
                          small
                          outlined
                          class="pa-2"
                          :disabled="!isValid || !isInputValid"
                          style="vertical-align: bottom; margin-bottom: 8px"
                        >
                          QUERY
                          <span
                            ><v-icon small
                              >mdi-arrow-right-drop-circle</v-icon
                            ></span
                          >
                        </v-btn>
                      </div>
                    </div>
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  lg="8"
                  xl="9"
                  class="pt-0 mt-n4 pb-1"
                  style="height: 100%"
                >
                  <div
                    class="text-center font-weight-bold"
                    style="margin-bottom: 5px"
                    v-if="ipTableLoading"
                  >
                    SCAN HISTORY: Target IP/Range ( - )
                  </div>
                  <div
                    class="text-center font-weight-bold"
                    style="margin-bottom: 5px"
                    v-if="!ipTableLoading && ipTableItems.length > 0"
                  >
                    SCAN HISTORY: {{ ipTableItems[0].target_ip }} -
                    {{ ipTableItems[ipTableItems.length - 1].target_ip }} (
                    {{ tableLabel }} )
                  </div>

                  <div class="scroll-bar" style="height: 95%; overflow: hidden">
                    <v-sheet class="mb-1" style="height: 100%">
                      <v-card
                        class="black--text pa-1 scroll-bar"
                        elevation="0"
                        color="blue-grey lighten-5"
                        style="
                          height: 95%;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                        "
                      >
                        <v-progress-circular
                          v-if="isLoading"
                          indeterminate
                          style="justify-content: center"
                          color="primary"
                          size="64"
                          class="mx-auto my-5"
                        ></v-progress-circular>

                        <v-data-table
                          v-if="!isLoading"
                          dense
                          :headers="ipTableHeader"
                          :items="ipTableItems"
                          class="elevation-0 black--text pt-0"
                          disable-pagination
                          hide-default-footer
                          :disabled="!ipTableLoading"
                          height="31vh"
                          style="border-bottom: none; height: 30.9vh"
                          fixed-header
                          id="ip-table"
                          disable-sort
                        ></v-data-table>
                      </v-card>
                    </v-sheet>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";

import axios from "axios";
import { index } from "@/mixins/elastic";
import { dateFormat, PTestConn } from "@/mixins/commons";
import {
  configurationScanQuery1,
  configurationScanQuery2,
  scanScrollQuery,
  scanScrollQuery2,
} from "@/mixins/queries.js";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import Papa from "papaparse";
export default {
  name: `ScanConfig`,

  data: () => ({
    ipdatatable: [],
    refresh: 0,
    dateMenu: false,
    dateMenuFilters: false,
    date: undefined,
    changedDate: undefined,
    defaultDate: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
    ipItems: [],
    treeSelected: [],
    searchAfter: [],
    searchIP: null,
    ipTableItems: [],
    ipTableHeader: [
      { text: "Target", value: "target_ip" },
      { text: "Cycle", value: "cycle" },
      { text: "Scanned Time", value: "scanned_time" },
      { text: "Scanner IP", value: "scanner_ip" },
    ],
    ipTableLoading: true,
    rules: (v) =>
      /^\s+$|^$|^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){1,2}(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){0,1}$/.test(
        v
      ) || "Incorrect IP Format. or must enter the IP up to the 2nd octet.",

    isValid: true,

    startDate: ``,
    endDate: ``,
    units: [
      { text: `Set to Now`, value: `now` },
      { text: `Days Ago`, value: `d-` },
      { text: `Weeks Ago`, value: `w-` },
      { text: `Months Ago`, value: `mo-` },
    ],
    startUnits: [
      { text: `Days Ago`, value: `d-` },
      { text: `Weeks Ago`, value: `w-` },
      { text: `Months Ago`, value: `mo-` },
    ],
    startDiff: `1`,
    endDiff: `1`,
    startUnit: `mo-`,
    endUnit: `now`,
    tableLabel: ``,
    startDiffRules: [(W) => W >= 1],
    isLoading: false,
  }),
  mounted() {},
  created() {
    this.startDate = dayjs().subtract(1, "month").format(dateFormat);
    this.endDate = dayjs().format(dateFormat);

    this.date = this.defaultDate;
    this.changedDate = this.defaultDate;
  },
  computed: {
    dateRangeText() {
      return this.changedDate.join(" ~ ");
    },
    isInputValid() {
      if (!this.startDiff) {
        return false;
      }

      const validationResult = this.startDiffRules.every(
        (rule) => rule(this.startDiff) === true
      );

      return validationResult;
    },
  },

  watch: {
    startUnit: function (newUnit) {
      if (newUnit === "mo-") {
        this.startDiffRules = [
          (W) => (W >= 1 && W <= 3) || "Please input a number between 1 and 3.",
        ];
      } else if (newUnit === "w-") {
        this.startDiffRules = [
          (W) =>
            (W >= 1 && W <= 12) || "Please input a number between 1 and 12.",
        ];
      } else if (newUnit === "d-") {
        this.startDiffRules = [
          (W) =>
            (W >= 1 && W <= 63) || "Please input a number between 1 and 63.",
        ];
      }
    },
  },

  methods: {
    resetFields: function () {
      this.startDiff = `1`;
      this.endDiff = `1`;
      this.startUnit = `mo-`;
      this.endUnit = `now`;
      this.treeSelected = " ";
      this.ipTableItems = [];
      this.ipTableLoading = true;
      this.updateEnd(this.endDiff, this.endUnit);
      this.updateStart(this.endDiff, this.endUnit);
    },
    resetDates: function () {
      this.date = this.defaultDate;
      this.changedDate = this.defaultDate;
      this.refresh++;
    },
    setDate: function () {
      this.date = this.changedDate;
      this.refresh++;
    },

    fetchScannedIP: function () {
      this.ipTableLoading = true;
      this.isLoading = true;
      this.tableLabel = `${this.startDate} ~ ${this.endDate}`;
      const starttime = dayjs(this.startDate, dateFormat).format();
      const endtime = dayjs(this.endDate, dateFormat).format();
      let scanTarget = this.treeSelected[0] + ".";
      const query = configurationScanQuery2(starttime, endtime, scanTarget);
      this.refresh++;

      axios
        .post(PTestConn, {
          queryurl: index.scanHistory + "/_search",
          querybody: JSON.stringify(query),
        })
        .then((res) => {
          // console.log(res.data.hits.hits.length, "res.data:");
          let accumulatedResults = res.data.hits.hits.map((x) => x._source);
          let searchAfter =
            res.data.hits.hits.length > 9998
              ? res.data.hits.hits[9998].sort
              : null;
          return this.fetchNextBatch(
            starttime,
            endtime,
            scanTarget,
            searchAfter,
            accumulatedResults
          );
        })
        .then((accumulatedResults) => {
          this.ipTableItems = accumulatedResults;
        })
        .catch((error) => {
          console.error("Error in fetchScannedIP:", error);
        })
        .finally(() => {
          this.ipTableLoading = false;
          this.isLoading = false;
        });
    },
    fetchNextBatch: function (
      starttime,
      endtime,
      scanTarget,
      searchAfter,
      accumulatedResults
    ) {
      if (!searchAfter) {
        return Promise.resolve(accumulatedResults);
      }

      const nextQuery = configurationScanQuery1(
        starttime,
        endtime,
        scanTarget,
        searchAfter
      );

      return axios
        .post(PTestConn, {
          queryurl: index.scanHistory + "/_search",
          querybody: JSON.stringify(nextQuery),
        })
        .then((res) => {
          // console.log(res.data.hits.hits.length, "res.data:");
          const newResults = res.data.hits.hits.map((x) => x._source);
          accumulatedResults.push(...newResults);

          if (res.data.hits.hits.length < 9999) {
            searchAfter = null;
          } else {
            searchAfter = res.data.hits.hits[9998].sort;
          }

          return this.fetchNextBatch(
            starttime,
            endtime,
            scanTarget,
            searchAfter,
            accumulatedResults
          );
        });
    },

    updateStart: function (ammount, unit) {
      switch (unit) {
        case `now`:
          this.startDate = dayjs().format(dateFormat);
          break;
        case `m-`:
          this.startDate = dayjs()
            .subtract(ammount, "minute")
            .format(dateFormat);
          break;
        case `h-`:
          this.startDate = dayjs().subtract(ammount, "hour").format(dateFormat);
          break;
        case `d-`:
          this.startDate = dayjs().subtract(ammount, "day").format(dateFormat);
          break;
        case `mo-`:
          this.startDate = dayjs()
            .subtract(ammount, "month")
            .format(dateFormat);
          break;
        case `y-`:
          this.startDate = dayjs()
            .subtract(ammount, "years")
            .format(dateFormat);
          break;
        case `w-`:
          this.startDate = dayjs().subtract(ammount, "week").format(dateFormat);
          break;
        case `m+`:
          this.startDate = dayjs().add(ammount, "minute").format(dateFormat);
          break;
        case `h+`:
          this.startDate = dayjs().add(ammount, "hour").format(dateFormat);
          break;
        case `d+`:
          this.startDate = dayjs().add(ammount, "day").format(dateFormat);
          break;
        case `mo+`:
          this.startDate = dayjs().add(ammount, "month").format(dateFormat);
          break;
        case `w+`:
          this.startDate = dayjs().add(ammount, "week").format(dateFormat);
          break;
        default:
          break;
      }
    },
    updateEnd: function (ammount, unit) {
      switch (unit) {
        case `now`:
          this.endDate = dayjs().format(dateFormat);
          break;
        case `m-`:
          this.endDate = dayjs().subtract(ammount, "minute").format(dateFormat);
          break;
        case `h-`:
          this.endDate = dayjs().subtract(ammount, "hour").format(dateFormat);
          break;
        case `d-`:
          this.endDate = dayjs().subtract(ammount, "day").format(dateFormat);
          break;
        case `mo-`:
          this.endDate = dayjs().subtract(ammount, "month").format(dateFormat);
          break;
        case `y-`:
          this.endDate = dayjs().subtract(ammount, "year").format(dateFormat);
          break;
        case `m+`:
          this.endDate = dayjs().add(ammount, "minute").format(dateFormat);
          break;
        case `h+`:
          this.endDate = dayjs().add(ammount, "hour").format(dateFormat);
          break;
        case `d+`:
          this.endDate = dayjs().add(ammount, "day").format(dateFormat);
          break;
        case `mo+`:
          this.endDate = dayjs().add(ammount, "month").format(dateFormat);
          break;
        default:
          break;
      }
    },
    scrollData: async function (scrollId) {
      const scrollQuery = scanScrollQuery(scrollId);

      const res = await axios.post(PTestConn, {
        queryurl: "/_search/scroll",
        querybody: JSON.stringify(scrollQuery),
      });
      const data = res.data.hits.hits;
      return data.map((x) => {
        return [x._source.created_time, x._source.ip_d];
      });
    },
    exportIPCSV: function () {
      const requestSize = 100000000;
      const testQuery = scanScrollQuery2();
      let exportObject = [];
      let totalSize = 0;
      let scrollId = "";
      axios
        .post(PTestConn, {
          queryurl: index.ip + "/_search?scroll=1m",
          querybody: JSON.stringify(testQuery),
        })
        .then((res) => {
          const data = res.data.hits.hits;
          exportObject.push(
            data.map((x) => {
              return [x._source.created_time, x._source.ip_d];
            })
          );
          scrollId = res.data._scroll_id;
          totalSize = res.data.hits.total.value;
        })
        .finally(async () => {
          for (let i = requestSize; i < totalSize; i = i + requestSize) {
            exportObject.push(await this.scrollData(scrollId));
            if (i >= totalSize - requestSize) {
              exportObject = exportObject.flat(1);
              const csv = Papa.unparse({
                fields: ["Added Date", "IP"],
                data: exportObject,
              });
              const blob = new Blob([csv]);
              const a = document.createElement("a");
              a.href = URL.createObjectURL(
                blob,
                { type: "text/csv;charset=utf-8;" } + encodeURIComponent(csv)
              );
              a.download = "IPLIST " + dayjs().format() + ".csv";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }
          // console.log(`Total: ${totalSize}, ScrollID: ${scrollId}`);
        });
    },
    exportIPTableCSV: function () {
      // console.log(this.ipTableItems);
      const csv = Papa.unparse(this.ipTableItems);
      const blob = new Blob([csv]);
      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        blob,
        { type: "text/csv;charset=utf-8;" } + encodeURIComponent(csv)
      );
      a.download = "IPLIST " + dayjs().format() + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style scoped>
.v-text-field--filled.v-input--dense.v-text-field--single-line
  /deep/
  .v-input__control
  > .v-input__slot {
  max-height: 20px;
}

.v-treeview--dense /deep/ .v-treeview-node__root {
  min-height: 28px;
}

.theme--light.v-data-table
  /deep/
  .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.v-data-table--dense /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  height: 24px;
}
</style>
