<template>
  <div>
    <div class="text-center font-weight-bold mb-n3 mt-1">
      <span class="text-uppercase" >{{label}}</span> Utilization Status ({{formatDate(min)}} ~ {{formatDate(max)}})
    </div>
    
    <div id="chart">
      <apexchart :key="cstKey" type="boxPlot" :height="candleHeight" :options="chartOptions" :series="dataset"></apexchart>
    </div>

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import dayjs from 'dayjs';
import {chartDateFormat, dateFormat} from '@/mixins/commons';
export default {
    components: {
        apexchart: VueApexCharts,
    },
    computed: {
      
      candleHeight: function(){
            switch (this.$vuetify.breakpoint.name) {
                case `xs` : return 350
                case `sm` : return 240
                case `md` : return '220px'
                case `lg` : return '220px'
                case `xl` : return '220px'
            }
            return '220px'
        },
    },
    props: {
      dataset: Array,
      options: Object,
      label: String
    },
    mounted(){
      let self = this;
      this.chartOptions.chart.events.scrolled = function(chartContext, {xaxis}){
         //console.log(xaxis.min);
        self.min = xaxis.min;
        self.max = xaxis.max;
        
      };
      this.cstKey = 'mounted';
    },
    methods:{
      updateDates(value){
        console.log(value)
      },
      formatDate(date){
        return dayjs(date).format(dateFormat)
      }
    },
    data: ()=>({
      cstKey:'created',
      min: dayjs().subtract(2, 'months').valueOf(),
      max: dayjs().valueOf(),
      chartOptions: {
        chart: {
          id: 'mainChart',
          type: 'boxPlot',
          height: 350,
          toolbar: {
            show: true,
            tools:{
              download:false, 
              zoom: false,
              zoomin:false,
              zoomout: false,
              reset: false,
              pan: true
            },
            autoSelected: 'pan',
          },
          events: {
            scrolled: undefined
          }
        },
        colors: ['#2196F3', '#3F51B5'],
        plotOptions: {
          boxPlot: {
            colors: {
              upper: '#0D47A1',
              lower: '#90CAF9'
            }
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'YYYY MMM',
              month: 'MMM dd',
              day: 'MMM dd',
              hour: 'HH:mm'
            }
          },
          tooltip: {
            formatter: function(val) {
              return dayjs(val).format(chartDateFormat)
            }
          },
          min: dayjs().subtract(2, 'months').valueOf(),
          max: dayjs().valueOf()
        },
        tooltip: {
          shared: false,
          intersect: true,
            custom: function({ seriesIndex, dataPointIndex, w}) {
              const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
              const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
              const m = w.globals.seriesCandleM[seriesIndex][dataPointIndex];
              const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex];
              const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex];
              const labels = ['Min','25%','Median','75%','Max'];
              if (w.config.series[seriesIndex].name != 'Percentiles') {
                return "<div class=\"apexcharts-custom-tooltip\">\n".concat(w.config.series[seriesIndex].name ? w.config.series[seriesIndex].name : 'series-' + (seriesIndex + 1), ": <strong>").concat(w.globals.series[seriesIndex][dataPointIndex], "</strong>\n        </div>");
              } else {
                return "<div class=\"apexcharts-tooltip-box apexcharts-tooltip-".concat(w.config.chart.type, "\">") + "<div>".concat(labels[0], ": <span class=\"value\">") + o + '</span></div>' + "<div>".concat(labels[1], ": <span class=\"value\">") + h + '</span></div>' + (m ? "<div>".concat(labels[2], ": <span class=\"value\">") + m + '</span></div>' : '') + "<div>".concat(labels[3], ": <span class=\"value\">") + l + '</span></div>' + "<div>".concat(labels[4], ": <span class=\"value\">") + c + '</span></div>' + '</div>';
              }
            }
        }
      }
    })
}
</script>