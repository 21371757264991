<template>
<div style="height: 100%;">
    <v-container fluid style="height: 100%;">
        <v-row>
            <v-col cols="12" md="4" class="pb-2" >
                <v-card outlined elevation="1" :loading="!finishedCycle" class="black--text pb-md-3 pb-lg-0" style="height:100%;">
                    <v-card-title class="pb-0 pt-2 " @mouseover=" current_riskShow " @mouseleave="current_riskoff">CURRENT RISK</v-card-title>
                    <v-card-text class="pb-md-1 pb-xl-2 pr-1" v-if="finishedCycle != null">
                        <current-risk 
                            :cycle="finishedCycle"
                            :bcycle="finishedBCycle"
                            ></current-risk>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8" class="pb-2">
                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col
                            cols="12"
                            md="7"
                        >
                            <v-card outlined elevation="1" :loading="!finishedCycle" class="black--text">
                                <v-card-text class="black--text" style="padding: 10px;" v-if="finishedCycle != null">
                                    <general-numbers :cycle="finishedCycle"></general-numbers>
                                </v-card-text>
                                
                            </v-card>
                        </v-col>
                        <v-col
                            cols="12"
                            md="5"
                        >
                            <v-card outlined elevation="1" style="height: 100%; display: flex; justify-content: center; align-items: center; padding: 5px;" class="black--text">
                                <v-card-text class="black--text" style="padding: 0px;">
                                    <risk-numbers></risk-numbers>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-card outlined elevation="1" class="black--text mt-3 fluid">
                                <v-card-text class="pt-0 fluid" style="padding-bottom: 0px !important;">
                                    <risk-trend-device-candlestick :dates="date" :chartKey="chartKey"></risk-trend-device-candlestick>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>

            </v-col>
        </v-row>
        <v-row style="height: 50%;">
            <v-col
                cols="12"
                md="4"
                class="pt-md-1 pt-xl-3 "
                
            >
                <v-card outlined elevation="1" class="black--text" style="min-height: 100%;">
                    <top-devices></top-devices>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                md="8"
                class="pt-md-1 pt-xl-3"
            > 
                <v-card outlined elevation="1" class="black--text" style="min-height: 100%;">
                    <v-card-title class="pb-0 pt-xl-3 pt-md-2 pt-lg-2" @mouseover=" topStatusShow " @mouseleave="topStatusoff">TOP STATUS</v-card-title>
                    <v-card-text class="pb-xl-4 pb-md-0 pr-0 pl-0" v-if="finishedCycle != null">
                        <top-status :cycle="finishedCycle"></top-status>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import CurrentRisk from '@/components/status/CurrentRisk.vue';
import RiskNumbers from '@/components/status/RiskNumbers.vue';
import GeneralNumbers from '@/components/status/GeneralNumbers.vue';
import TopStatus from '@/components/status/TopStatus.vue';
import TopDevices from '@/components/home/TopDevices.vue';
import EventBus from '@/plugins/EventBus.js';
import axios from 'axios';
import { index } from '@/mixins/elastic';
import { runningCycleQuery} from '@/mixins/queries';
import RiskTrendDeviceCandlestick from '../components/status/RiskTrendDeviceCandlestick.vue';
import dayjs from 'dayjs';
import { PTestConn } from '@/mixins/commons'

export default {
    name: `Status`,
    components: {
        CurrentRisk,
        RiskNumbers,
        GeneralNumbers,
        TopStatus,
        TopDevices,
        RiskTrendDeviceCandlestick
    },
    data: function(){
        return {
            chartKey: 0,
            date: undefined,
            finishedCycle: null,
            runningCycle: null,
            finishedBCycle: null,
            changedDate: undefined,
            defaultDate: [dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]

        }
    },
    computed: {
        cardHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case `xs` : return 130
                case `sm` : return 150
                case `md` : return 115
                case `lg` : return 104
                case `xl` : return 136
            }
            return 100
        }
    },
    mounted() {
        this.getRunningCycle();
        this.$store.commit('closeContextMenu');
    },

    created() {
        this.date = this.defaultDate;
        this.changedDate = this.defaultDate;
    },

    methods: {
        current_riskShow: function(){
            EventBus.$emit('current_riskShow', `By analyzing the scan results so far with the Risk Score System (developed based on CVSS v3.0, the risk assessment standard of MITRE), the current level of risk is displayed as a score and class level.`);
        },
        current_riskoff: function(){
            EventBus.$emit('current_riskShow', null);
        },
        topStatusShow: function(){
            EventBus.$emit('topStatusShow', `Based on the numerous scan results so far, you can see the Top 10, which takes up the most proportion in operating system, port number, AS name, city name, etc through the pie graph.`);
        },
        topStatusoff: function(){
            EventBus.$emit('topStatusShow', null);
        },

        getRunningCycle(){
            const query = runningCycleQuery;
             axios.post( PTestConn , { 
                    queryurl :  index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })

            .then(res => {
                this.runningCycle = res.data.aggregations.last_cycle.hits.hits[0]._source['xml-nmap'].cycle[0];
                if( this.runningCycle >= 3) {
                    this.finishedCycle = this.runningCycle - 1;
                    this.finishedBCycle = this.runningCycle - 2;
                }
                else if (this.runningCycle < 3){
                    this.runningCycle = 1;
                    this.finishedCycle = this.runningCycle;
                    this.finishedBCycle = this.runningCycle;

                }
            })
        }        
    }
    
}
</script>