import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css";
import "./assets/styles/mainStyle.css";
import VueFlags from "@growthbunker/vueflags";
import * as Hammer from "hammerjs";
import VTooltip from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import VueSimpleAlert from "vue-simple-alert";
import VueMoment from "vue-moment";
import VueScrollTo from "vue-scrollto";
import 'leaflet/dist/leaflet.css';

// const logoutTime = 1 * 60 * 1000;

// function autoLogout() {
//   setTimeout(() => {
//     store.commit('logout');
//     alert('자동 로그아웃 되었습니다.');
//     if (router.currentRoute.path !== '/login') {
//               router.push('/login');
//             }
//   }, logoutTime);
// }

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.commit("logout");

      if (router.currentRoute.path !== "/login") {
        router.push("/login");
      }
    }
    if (error.response && error.response.status === 402) {
      alert(
        "You do not have the necessary permissions to access this menu. Your current account is a demo account, which has limited access to certain features. To gain full access, please upgrade your account. For more information, contact our support team."
      );
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(VueLayers);
Vue.use(VueFlags, { iconPath: "/flags/" });
Vue.use(VueApexCharts);
Vue.use(VueSimpleAlert);
Vue.use(VueMoment);
Vue.use(VueScrollTo);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  VTooltip,
  axios,
  Hammer,
  VueAxios,
  // Viz
  // beforeCreate() {
  //   autoLogout();
  // },
  render: (h) => h(App),
}).$mount("#app");
