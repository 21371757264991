<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12" md="3" class="text-center pt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding:5px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <div class="pb-2" style="width: 100%"><h1 style="font-size:2em; "  @mouseover=" gr_targetsShow " @mouseleave="gr_targetsoff">{{numbers.targets}}</h1></div>
                <div style="font-size:1em; width: 100%;" @mouseover=" gr_targetsShow " @mouseleave="gr_targetsoff">TARGETS</div>
            </v-col>
            <v-col cols="12" md="3" class="text-center pt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding:5px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <div class="pb-2" style="width: 100%"><h1 style="font-size:2em;" @mouseover=" gr_activitiesShow " @mouseleave="gr_activitiesoff">{{numbers.actives}}</h1></div>
                <div style="font-size:1em; width: 100%;" @mouseover=" gr_activitiesShow " @mouseleave="gr_activitiesoff">ACTIVES</div>
            </v-col>
            <v-col cols="12" md="3" class="text-center pt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding:5px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <div class="pb-2" style="width: 100%"><h1 style="font-size:2em; " @mouseover=" gr_openportsShow " @mouseleave="gr_openportsoff">{{numbers.openPorts}}</h1></div>
                <div style="font-size:1em; width: 100%;" @mouseover=" gr_openportsShow " @mouseleave="gr_openportsoff">OPEN PORTS</div>
            </v-col>
            <v-col cols="12" md="3" class="text-center pt-md-3 pb-md-0 pt-xl-4 pb-xl-2" style="padding:5px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <div class="pb-2" style="width: 100%"><h1 style="font-size:2em; " @mouseover=" gr_autonomousShow " @mouseleave="gr_autonomousoff">{{numbers.autonomous}}</h1></div>
                <div style="font-size:1em; width: 100%;" @mouseover=" gr_autonomousShow " @mouseleave="gr_autonomousoff">AUTONOMOUS</div>
            </v-col>
        </v-row>



    </v-container>
</template>

<script>
import axios from 'axios';
import { index } from '@/mixins/elastic';
import { baseCycleQuery2 } from '@/mixins/queries';
import EventBus from '@/plugins/EventBus.js';
import { PTestConn } from '@/mixins/commons'
export default {
    name: `GeneralNumbers`,
    props: {
        cycle: {
            type: Number,
            required: true
        }
    },
    data: function(){
        return{
            numbers: {
                targets: 0,
                actives: 0,
                openPorts: 0,
                autonomous: 0
            }
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        gr_targetsShow: function(){
            EventBus.$emit('gr_targetsShow', `The number means the total number of IP addresses registered in the system as scan targets, and the target IP address database is maintained using a global public IP address database such as IP2Location by the WiKi Security Lab.`);
        },
        gr_targetsoff: function(){
            EventBus.$emit('gr_targetsShow', null);
        },
        gr_activitiesShow: function(){
            EventBus.$emit('gr_activitiesShow', `The number indicates the total number of IP devices identified as “UP” status by various host detection techniques, and techniques such as ICMP echo request, TCP SYN packet, ACK packet, and ICMP timestamp request are used for the host detection.`);
        },
        gr_activitiesoff: function(){
            EventBus.$emit('gr_activitiesShow', null);
        },
        gr_openportsShow: function(){
            EventBus.$emit('gr_openportsShow', `The number indicates the total number of IP devices that have one or more opened TCP ports, and various technologies such as TCP SYN scan, TCP connect scan, SCTP INIT scan, NULL/FIN/Xmass scan are used for the open port detection.`);
        },
        gr_openportsoff: function(){
            EventBus.$emit('gr_openportsShow', null);
        },
        gr_autonomousShow: function(){
            EventBus.$emit('gr_autonomousShow', `The number indicates the total number of Autonomous Systems (organizations that independently operate Internet routing by receiving identification numbers from the Internet Assigned Names Authority (IANA)) detected in the target network.`);
        },
        gr_autonomousoff: function(){
            EventBus.$emit('gr_autonomousShow', null);
        }, 

    
      
        getData(){
            const query = baseCycleQuery2(this.cycle);
            axios.post( PTestConn , { 
                    queryurl : index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {
                // console.log("GeneralNumber:",res)
                const data = res.data.aggregations;
                this.numbers.actives = new Intl.NumberFormat().format(data.host_count.value);
                data.port_status.buckets.forEach(x =>{
                    if (x.key == 'open')
                        this.numbers.openPorts = new Intl.NumberFormat().format(x.doc_count)
                });
                this.numbers.autonomous = new Intl.NumberFormat().format(data.autonomous_count.value);
            });

            axios.post( PTestConn  , { 
                    queryurl : index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(response => {
                const data = response.data;
                this.numbers.targets = new Intl.NumberFormat().format(data.hits.total.value);
            });

        }
    }
}
</script>