// exports indexes names
export const index = {
    apiKey: ``,
    nmap: `nmap-threat-dnmap-test2`,
    cycleHistory: `cycle-history`,
    nseInformation: `nse_information`,
    OSInt: `threat-total`,
    serverStatus: `server-status`,
    serverStatusHistory: `server-status-history`,
    portScore: `service-port-score`,
    scanHistory: `scanned-ip`,
    ip: `now-scanning-ip`,
    logs: `logs`,
    databaseCount: `database-count`,
    gpt:`gpt-nmap-240311`,
    trace:`trace-240315`
}

export const address = `https://shodanapi.wikisecurity.net:4687`;
export const auth = {
    username: `devwiki`,
    password: `!wiki!elk1`
};

export const serverList = [
    `EK`,
    // `web-asia`,
    `scan-client1`,
    `scan-client2`,
    `scan-client3`,
    `scan-server`,
    `scan-client4`
]