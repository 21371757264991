<template>
    <div class="pb-2 text-center">
        <span class="px-2 " v-for="pieChart in pieCharts" :key="pieChart.name+pieHeight">
            <home-pie v-if="pieChart.chartData.labels.length > 0" 
                class="mx-auto mt-1" :title="pieChart.name"  
                :chartdata="pieChart.chartData" 
                :chartoptions="demoPieOptions" 
                :chartHeight="pieHeight" 
                :chartWidth="pieHeight*1.8 ">
                </home-pie>
            <span v-else>
                <v-sheet elevation="0" :height="pieHeight + 17" :width="pieHeight * 1.5" class="d-inline-block" color="grey lighten-4">
                    <div class="d-flex">
                        <span class=" ma-auto text-uppercase font-weight-bold text-left">NO {{pieChart.name}} DATA</span>
                    </div>
                </v-sheet>
            </span>
        </span>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import HomePie from "@/components/home/deviceStatus/HomePie.vue";

import axios from 'axios';
import { index } from '@/mixins/elastic';
import { baseCycleQuery1 } from '@/mixins/queries';
import { colors, PTestConn } from "@/mixins/commons.js";
export default {
    name: `TopStatus`,
    components:{
        HomePie
    },
    data: () => ({
        pieCharts: [],
        demoPieOptions: {
            cutoutPercentage: 65,
            responsive: false,
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            plugins: {
                datalabels: {
                    display: false
                },
                crosshair:false
            },
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 10,
                    fontSize: 9,
                    padding: 1,
                    generateLabels: function(chart){
                        var data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            return data.labels.map(function(label, i) {
                                var meta = chart.getDatasetMeta(0);
                                var ds = data.datasets[0];
                                var arc = meta.data[i];
                                var custom = arc && arc.custom || {};
                                var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                var arcOpts = chart.options.elements.arc;
                                var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);


                                var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                                return {

                                    text: label.substring(0, 8) + " : " + value,
                                    fillStyle: fill,
                                    strokeStyle: stroke,
                                    lineWidth: bw,
                                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                    index: i
                                };
                            });
                        } else {
                            return [];
                        }
                    }
                }
            }
        }
    }),
    mounted(){
        this.getCycleData();
    },
    computed: {
        pieHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case `xs` : return 130
                case `sm` : return 150
                case `md` : return 101
                case `lg` : return 103
                case `xl` : return 136
            }
            return 100
        }
    },
    props:{
        cycle: {
            type: Number,
            required: true
        }
    },
    methods: {
        getCycleData(){
            const query = baseCycleQuery1(this.cycle);

            axios.post( PTestConn , { 
                    queryurl :  index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })
            .then(res => {
                // console.log('TopStatus:',res);
                this.finishedCycleData = res.data.aggregations;
            })
            .finally(()=>{
                this.processPieData()
            })
        },
        processPieData(){         
            this.pieCharts.push(this.formatPieData('Operating System', this.finishedCycleData.os.buckets));
            this.pieCharts.push(this.formatPieData('Opened Ports', this.finishedCycleData.port.buckets));
            this.pieCharts.push(this.formatPieData('Autonomous Name', this.finishedCycleData.autonomous.buckets));
            this.pieCharts.push(this.formatPieData('City Name', this.finishedCycleData.city.buckets));
            this.pieCharts.push(this.formatPieData('Script Name', this.finishedCycleData.script.buckets));
            this.pieCharts.push(this.formatPieDataWithScores('Risk Category', this.finishedCycleData.risk_category.buckets));
            this.pieCharts.push(this.formatPieDataWithScores('Threat Category', this.finishedCycleData.threat_category.buckets));
            this.pieCharts.push(this.formatPieDataWithScores('Vuln Category', this.finishedCycleData.vuln_category.buckets));
        },
        formatPieData(name, data){
            const pieChart = {
                name: name,
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: colors.pieColors.lighten,
                            data: []
                        }
                    ]
                }
            };
            if(data.length > 0){
                let count = 0;
                var total = 0;
                data.forEach(function(element){
                    if(count < 9){
                        pieChart.chartData.labels.push(element.key);
                        pieChart.chartData.datasets[0].data.push(element.doc_count);  
                    }else{
                        total += element.doc_count
                    }
                    count++;
                    if (count == data.length){
                        pieChart.chartData.labels.push('Others');
                        pieChart.chartData.datasets[0].data.push(total);
                    }
                    
                });
            }
            return pieChart
        },
        formatPieDataWithScores(name, data){
            const pieChart = {
                name: name,
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: colors.pieColors.scores,
                            data: []
                        }
                    ]
                }
            };
            data.forEach(function(element){
                if(element.key != `all`){
                    pieChart.chartData.labels.push(element.key);
                    pieChart.chartData.datasets[0].data.push(element.doc_count); 
                }
            });
            return pieChart
        }
    },
}
</script>
