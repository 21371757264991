<template>
    <v-alert
      color="deep-orange darken-2"
      dark
      icon="mdi-server-network-off"
      border="left"
      class="mt-2"
    >
        <div class="d-flex">
            <span>{{errorText}}</span>
            <span class="ml-auto">
                <v-btn
                    class="mx-2"
                    fab
                    light
                    x-small
                    depressed
                    @click="dismiss"
                >
                    <v-icon dark>
                        mdi-close
                    </v-icon>
                </v-btn>
            </span>
        </div>
    </v-alert>
</template>

<script>
export default {
    computed: {
        errorText: function(){
            return this.$store.state.testError.msg
        }
    },
    methods:{
        dismiss: function(){
            this.$store.commit("dismissTestError");
        }
    }    
}
</script>