import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Status from '../views/Status.vue'
import Timeline from '../views/Timeline.vue'
import Pwalert from '../views/pwalert.vue'
import Login from '../views/Login.vue'
import Analysis from '../views/Analysis.vue'
import Search from '../views/Search.vue'
import Management from '../views/Management.vue'
import Settings from '../views/Settings.vue'
import Configuration from '../views/Configuration.vue'
import Help from '../views/Help.vue'
// import connect from './connect.php'
import store from '@/store'

// import VueCookies from 'vue-cookies';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Status,
    meta: {
      requiresAuth: true,
      permissionId: 'any'
    }
  },
  {
    path: '/pwalert',
    name: 'Pwalert',
    component: Pwalert,
    meta: {
      requiresAuth: true,
      permissionId: 'any'
    },
  },

  {
    path: '/status',
    name: 'Status',
    component: Status,
    meta: {
      requiresAuth: true,
      permissionId: 'any'
    },
      // children: [{path : '/connect', component: connect}]
  },
  {
    path: '/timeline',
    name: 'Timeline',
    component: Timeline,
    meta: {
      requiresAuth: true,
      permissionId: 'any'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: Analysis,
    meta: {
      requiresAuth: true,
      permissionId: 'analysis'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      requiresAuth: true,
      permissionId: 'search'
    }
  },
  {
    path: '/search/command/:commandKey/:commandValue',
    name: 'Custom Search',
    component: Search,
    meta: {
      requiresAuth: true,
      permissionId: 'search'
    }
  },
  {
    path: '/management',
    name: 'Management',
    component: Management,
    meta: {
      requiresAuth: true,
      permissionId: 'management'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      permissionId: 'system'
    }
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: Configuration,
    meta: {
      requiresAuth: true,
      permissionId: 'configuration'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      requiresAuth: true,
      permissionId: 'help'
    }
  }
  
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//       if (localStorage.getItem('jwt') == null) {
//           next({
//               path: '/login',
//               params: { nextUrl: to.fullPath }
//           })
//       } else {
//           let user = JSON.parse(localStorage.getItem('user'))
//           if(to.matched.some(record => record.meta.is_admin)) {
//               if(user.is_admin == 1){
//                   next()
//               }
//               else{
//                   next({ path: '/'})
//               }
//           }else {
//               next()
//           }
//       }
//   } else if(to.matched.some(record => record.meta.guest)) {
//       if(localStorage.getItem('jwt') == null){
//           next()
//       }
//       else{
//           next({ path: '/'})
//       }
//   }else {
//       next()
//   }
// })


router.beforeEach( async(to, from, next) => {

  // if(VueCookies.get('accessToken')===null && VueCookies.get('refreshToken') !== null){
  //   //refreshToken은 있고 accessToken이 없을 경우 토큰 재발급 요청
  //   await store.dispatch('refreshToken');
  // }
  // if (VueCookies.get('accessToken')!==null){
  //   //accessToken이 있을 경우 진행
  //   if(to.matched.some(record => record.meta.requiresAuth)) {
  //     if (store.state.isLogged) {
  //       const permission = store.state.groupPermission[to.meta.permissionId];
  //       if(to.meta.permissionId == 'any' || permission == 1){
  //         next()
  //         return
  //       }else{
  //         console.warn('User does not have permissions for this page');
  //         next('/status');
  //         return
  //       }
  //     }

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.isLogged) {
      const permission = store.state.groupPermission[to.meta.permissionId];
      if(to.meta.permissionId == 'any' || permission == 1){
        next()
        return
      }
      else{
        console.warn('User does not have permissions for this page');
        next('/status');
        return
      }
    }
    next('/login')
  } else {
    next()
  }
})

export default router