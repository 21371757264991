<template>
    <v-card
        outlined
        elevation="1"
        class=""
        style="height:100%"
    >
        <v-card-text class="black--text pb-0">
            <v-row>
                <v-col cols="12" md="5" class="pb-0">
                    <h4 class="text-uppercase mb-4">Risk Query</h4>
            
                    <h4 class="text-uppercase font-italic mb-2">1. Select Pre-defined</h4>
                    <v-select
                        dense
                        :items="definedOptions"
                        item-text="title"
                        item-value="name"
                        v-model="selectedOption"
                    >
                    </v-select>

                    <h4 class="text-uppercase font-italic mb-2">2. Query Conditions</h4>
                    <div v-for="(field, index) in definedFields[selectedOption]" :key="index">
                        <v-row>
                            <v-col cols="4" class="pb-md-0 pt-md-2 pb-xl-4 pt-xl-4">
                                <span class="font-weight-bold">{{field.title}}</span>
                            </v-col>
                            <v-col v-if="field.type == 'bool'" cols="4">
                                <div class="text-center font-weight-bold">=</div>
                            </v-col>
                            <v-col cols="4" class="pb-md-0 pt-md-2 pb-xl-4 pt-xl-4">
                                <v-select
                                    :items="operators[field.type]"
                                    item-text="text"
                                    item-value="value"
                                    style="font-size:12px;"
                                    v-model="field.operator"
                                    hide-details=""
                                    
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="4" class="pb-md-0 pt-md-2 pb-xl-4 pt-xl-4">
                                <v-text-field
                                    v-model="field.value"
                                    dense
                                    style="font-size:12px;"
                                    v-if="field.type != 'bool'"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                    <h4 class="text-uppercase font-italic mb-2 mt-4">3. Select Scan Period</h4>
                    <v-sheet
                        class="pa-3 mb-4"
                        rounded
                        outlined
                    >
                        <v-row>
                            <v-col md="6" xl="6" cols="12" class="py-1 mt-md-1 mb-md-1 pl-md-2 mt-xl-3 mb-xl-3 pl-xl-3">
                                <div class="d-flex">
                                    <p class="font-weight-bold text-uppercase align-self-end pb-1 pr-1 ml-2 mb-1" style="font-size:13px">From:</p>
                                    <span>{{startDate}}</span>
                                </div>
                                <div>
                                   
                                    <v-form>                              
                                        <v-container>
                                            <v-row >
                                                <v-col
                                                    cols="6"
                                                    class="py-0"
                                                >
                                                    <v-text-field
                                                        v-model="startDiff"
                                                        dense
                                                        hide-details
                                                        @keyup="updateStart(startDiff, startUnit)"
                                                        style="font-size:14px;"
                                                        :disabled="startUnit == 'now'"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    class="py-0"
                                                >
                                                    <v-select
                                                        :items="startUnits"
                                                        item-text="text"
                                                        item-value="value"
                                                        dense
                                                        hide-details
                                                        v-model="startUnit"
                                                        style="font-size:14px;"
                                                        @change="updateStart(startDiff, startUnit)"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                               
                                </div>
                                
                            </v-col>
                            <v-col md="6" xl="6" cols="12" class="py-1 mt-md-1 mb-md-1 pl-md-2 mt-xl-3 mb-xl-3 pl-xl-3">
                                <div class="d-flex">
                                    <p class="font-weight-bold text-uppercase align-self-end pb-1 pr-1 ml-2 mb-1" style="font-size:13px">To:</p>
                                    <span>{{endDate}}</span>
                                </div>
                                <div>
                                    <v-form>                              
                                        <v-container>
                                            <v-row >
                                                <v-col
                                                    cols="6"
                                                    class="py-0"
                                                >
                                                    <v-text-field
                                                        v-model="endDiff"
                                                        dense
                                                        hide-details
                                                        style="font-size:14px;"
                                                        @keyup="updateEnd(endDiff, endUnit)"
                                                        :disabled="endUnit == 'now'"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    class="py-0"
                                                >
                                                    <v-select
                                                        :items="units"
                                                        item-text="text"
                                                        item-value="value"
                                                        dense
                                                        hide-details
                                                        v-model="endUnit"
                                                        style="font-size:14px;"
                                                        @change="updateEnd(endDiff, endUnit)"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                               
                                </div>

                            </v-col>
                        </v-row>
                    </v-sheet>
                    <hr >
                    <div class="my-4">
                        <div>
                            SELECT [
                            <span class="px-1 text-uppercase" v-for="(field, index) in definedFields[selectedOption]" :key="index">'{{field.title}}'</span>
                            ] FROM SCAN_LIBRARY
                        </div>
                        <div>
                            WHERE 'INITIAL DATE' > {{startDate}} 
                        </div>
                        <div>
                            AND 'FINAL DATE' &lt; {{endDate}}
                        </div>
                        <div v-for="(field, index) in definedFields[selectedOption]" :key="index">
                            <span v-if="field.value!=``">AND '{{field.name}}' {{field.operator}} {{field.value}}</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="ml-auto mt-n10">
                            <v-btn
                                elevation="0"
                                @click="executeQuery()"
                                color="grey darken-1"
                                small
                                outlined
                                dark
                                class="pa-2"
                            >
                                QUERY
                                <span><v-icon small >mdi-arrow-right-drop-circle</v-icon></span>
                            </v-btn>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" md="7" class="pt-1 pb-0">
                    <div class="d-flex  mt-5">
                        <div class="ml-auto mb-2">
                            <div class="text-center font-weight-bold">{{optionLabel}}</div>
                            <div class="text-center">{{queryDates[0]}} ~ {{queryDates[1]}}</div>
                        </div>
                        <div class="ml-auto">
                            <v-btn
                                color="red darken-1"
                                style="height:24px; min-width:24px; padding: 0 4px;"
                                class="mb-1 ml-1"
                                outlined
                                dark
                                :loading="resultsLoading"
                                @click="downloadResultData()"
                            >
                                <v-icon
                                    dark
                                    size="160%"
                                >
                                    mdi-file-download-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                    
                    <v-data-table
                        dense
                        multi-sort
                        class="elevation-0 "
                        :headers="definedHeaders[querySelectedOption]"
                        :items="resultData"
                        disable-pagination
                        hide-default-footer
                        style="height:75vh; overflow:auto;"
                        fixed-header=""
                        :loading="resultsLoading"
                    >
                    
                        <template v-slot:[`item.index`] = "{ item }">
                            {{resultData.indexOf(item)+1}}
                        </template>
                        <template v-slot:[`item.host`]="{ item }">
                            <div class="d-flex">
                            {{item.host}}
                            <span class="ml-auto papa-ghost ">
                                <span class="ghost-icon ">
                                <v-btn  icon color="Gray" x-small :href="'#/search/command/host/'+item.host">
                                    <v-icon size="180%">mdi-arrow-right-drop-circle</v-icon>
                                </v-btn>
                                </span>
                            </span>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { dateFormat, PTestConn } from '@/mixins/commons';
import axios from 'axios';
import { index } from '@/mixins/elastic';
import { runningCycleQuery, analysisQuery1, analysisQuery2, analysisField1, analysisField2} from '@/mixins/queries';
// import { addField } from '@/mixins/analysisSearchTemplates';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat)
export default {
    name: 'SimpleRiskQuery',
    computed: {
        optionLabel: function(){
            return this.definedOptions.filter(x=> x.name == this.querySelectedOption)?.[0]?.title
        }
    },
    data: ()=>({
        resultsLoading: false,
        runningCycle: null,
        finishedCycle: null,
        selectedOption: 'cycle_risk',
        querySelectedOption: '',
        queryDates: [],
        resultData: [],
        definedOptions: [
            {
                name: 'cycle_risk', title: 'What is the current risk status of the pre-defined mission-critical devices?'
            // },
            // {
            //     name: 'risk', title: 'Which devices are at high risk scores based on the risk score?'
            // },
            // {
            //     name: 'device_risk_city', title: 'Which devices are at high risk scores based on the city/country name?'
            // },
            // {
            //     name: 'device_risk_port', title: 'Which devices are at high risk scores based on the opened port?'
            // },
            // {
            //     name: 'device_risk_as', title: 'Which devices are at high risk scores based on the AS name?'
            // },
            // {
            //     name: 'device_risk_threat', title: 'Which devices are at high risk scores based on the threat category/owner?'
            },
        ],
        definedFields: {
            cycle_risk: [
                {name: 'total', elk: 'score.total_score', title: 'Total Score', type: 'number', value: '0', operator: 'gte'},
                {name: 'vuln', elk: 'score.vuln_score', title: 'Vulnerability Score', type: 'number', value: '0', operator: 'gte'},
                {name: 'threat', elk: 'score.threat_score', title: 'Threat Score', type: 'number', value: '0', operator: 'gte'},
                {name: 'blacklisted',  title: 'Current Blacklisted Status', type: 'bool', value: '1', operator: 'false'}
            ],
            // risk: [
            //     {name: 'total', elk: 'score.total_score', title: 'Total Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'vuln', elk: 'score.vuln_score', title: 'Vulnerability Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'threat', elk: 'score.threat_score', title: 'Threat Score', type: 'number', value: '1', operator: 'gt'}
            // ],
            // device_risk_city: [
            //     {name: 'total', elk: 'score.total_score', title: 'Total Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'vuln', elk: 'score.vuln_score', title: 'Vulnerability Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'threat', elk: 'score.threat_score', title: 'Threat Score', type: 'number', value: '1', operator: 'gt'}
            // ],
            // device_risk_port: [
            //     {name: 'total', elk: 'score.total_score', title: 'Total Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'vuln', elk: 'score.vuln_score', title: 'Vulnerability Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'threat', elk: 'score.threat_score', title: 'Threat Score', type: 'number', value: '1', operator: 'gt'}
            // ],
            // device_risk_as: [
            //     {name: 'total', elk: 'score.total_score', title: 'Total Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'vuln', elk: 'score.vuln_score', title: 'Vulnerability Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'threat', elk: 'score.threat_score', title: 'Threat Score', type: 'number', value: '1', operator: 'gt'}
            // ],
            // device_risk_threat: [
            //     {name: 'total', elk: 'score.total_score', title: 'Total Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'vuln', elk: 'score.vuln_score', title: 'Vulnerability Score', type: 'number', value: '1', operator: 'gt'},
            //     {name: 'threat', elk: 'score.threat_score', title: 'Threat Score', type: 'number', value: '1', operator: 'gt'}
            // ]
        },
        definedHeaders: {
            cycle_risk: [
                {text: '#', value: 'index',sortable: false},
                {text: 'IP Device (Hostname)', value: 'host',sortable: false},
                {text: 'Country Name (City Name)', value: 'location',sortable: false},
                {text: 'AS Name (Num)', value: 'as',sortable: false},
                {text: 'Total Score', value: 'total',sortable: false},
                {text: 'Threat Score', value: 'threat',sortable: false},
                {text: 'Vulnerability Score', value: 'vuln',sortable: false},
                {text: 'Blacklisted Count', sortable: false},
                {text: 'Current Blacklisted Status (Threat category, Registered Date)', value: 'status',sortable: false},
            ],
            // risk: [
            //     {text: '#', value: 'index'},
            //     {text: 'IP Device (Hostname)', value: 'host'},
            //     {text: 'Country Name (City Name)', value: 'location'},
            //     {text: 'AS Name (Num)', value: 'as'},
            //     {text: 'Total Score', value: 'total'},
            //     {text: 'Threat Score', value: 'threat'},
            //     {text: 'Vulnerability Score', value: 'vuln'},
            //     {text: 'Blacklisted Count', value: 'blacklisted'},
            //     {text: 'Current Blacklisted Status (Threat category, Registered Date)', value: 'status'},
            // ],
            // device_risk_city: [
            //     {text: '#', value: 'index'},
            //     {text: 'IP Device (Hostname)', value: 'host'},
            //     {text: 'Country Name (City Name)', value: 'location'},
            //     {text: 'AS Name (Num)', value: 'as'},
            //     {text: 'Total Score', value: 'total'},
            //     {text: 'Threat Score', value: 'threat'},
            //     {text: 'Vulnerability Score', value: 'vuln'},
            //     {text: 'Blacklisted Count', value: 'blacklisted'},
            //     {text: 'Current Blacklisted Status (Threat category, Registered Date)', value: 'status'},
            // ],
            // device_risk_port: [
            //     {text: '#', value: 'index'},
            //     {text: 'IP Device (Hostname)', value: 'host'},
            //     {text: 'Country Name (City Name)', value: 'location'},
            //     {text: 'AS Name (Num)', value: 'as'},
            //     {text: 'Total Score', value: 'total'},
            //     {text: 'Threat Score', value: 'threat'},
            //     {text: 'Vulnerability Score', value: 'vuln'},
            //     {text: 'Blacklisted Count', value: 'blacklisted'},
            //     {text: 'Current Blacklisted Status (Threat category, Registered Date)', value: 'status'},
            // ],
            // device_risk_as: [
            //     {text: '#', value: 'index'},
            //     {text: 'IP Device (Hostname)', value: 'host'},
            //     {text: 'Country Name (City Name)', value: 'location'},
            //     {text: 'AS Name (Num)', value: 'as'},
            //     {text: 'Total Score', value: 'total'},
            //     {text: 'Threat Score', value: 'threat'},
            //     {text: 'Vulnerability Score', value: 'vuln'},
            //     {text: 'Blacklisted Count', value: 'blacklisted'},
            //     {text: 'Current Blacklisted Status (Threat category, Registered Date)', value: 'status'},
            // ],
            // device_risk_threat: [
            //     {text: '#', value: 'index'},
            //     {text: 'IP Device (Hostname)', value: 'host'},
            //     {text: 'Country Name (City Name)', value: 'location'},
            //     {text: 'AS Name (Num)', value: 'as'},
            //     {text: 'Total Score', value: 'total'},
            //     {text: 'Threat Score', value: 'threat'},
            //     {text: 'Vulnerability Score', value: 'vuln'},
            //     {text: 'Blacklisted Count', value: 'blacklisted'},
            //     {text: 'Current Blacklisted Status (Threat category, Registered Date)', value: 'status'},
            // ]
        },
        startDate: dayjs().subtract(1, 'month').format(dateFormat),
        endDate: dayjs().format(dateFormat),
        units: [
            {text: `Set to Now`, value: `now`},
            {text: `Days Ago`, value: `d-`},
            {text: `Months Ago`, value: `mo-`},
            {text: `Years Ago`, value: `y-`}
        ],
        startUnits: [
            {text: `Days Ago`, value: `d-`},
            {text: `Months Ago`, value: `mo-`},
            {text: `Years Ago`, value: `y-`}
        ],
        operators: {
            bool: [
                { text: 'LISTED', value: 'true' },
                { text: 'NOT LISTED', value: 'false' },
                { text: 'ALL', value: 'any' },
            ],
            number: [
                { text: '<', value: 'lt' },
                { text: '<=', value: 'lte' },
                { text: '=', value: 'eq' },
                { text: '>=', value: 'gte' },
                { text: '>', value: 'gt' },
            ],
            text: [
                { text: '=', value: '=' },
                { text: '!=', value: '!=' }
            ] 
        },
        startDiff: `1`,
        endDiff: `1`,
        startUnit: `mo-`,
        endUnit: `now`,
    }),
    created(){
        this.getRunningCycle();
        
    },
   

    methods: {
        executeQuery:function(){
            this.resultsLoading = true;
            this.queryDates = [this.startDate, this.endDate];
            this.querySelectedOption = this.selectedOption;
            const gte = dayjs(this.startDate, dateFormat).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
            const starttime = gte;
            const lte = dayjs(this.endDate, dateFormat).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
            const endtime = lte;    
            let query = '';
            switch (this.querySelectedOption) {
                case 'cycle_risk': 
                    query = analysisQuery1(analysisField2,analysisField1,this.finishedCycle,starttime, endtime);
                    break;
                case 'something else':
                     query = 'Query B';
                    break;
            
                default:
                    query = query = analysisQuery2(analysisField1,this.finishedCycle,starttime, endtime);
                    break;
            }
            //  console.log('template:::',query);
            // const fields = this.definedFields[this.selectedOption];

            // fields.forEach(field => {
            //     if (field.name == 'blacklisted'){
            //     }
            //     else {
            //         query.params[field.name] = addField(field)
            //     }
            //     });
            // fields.forEach(field => {
            //     query.params[field.name] = addField(field)
            // });
        
            axios.post( PTestConn , { 
                    queryurl :  index.nmap+'/_search/template',
                    templateid : 'analysis_template',
                    querybody : JSON.stringify(query),
             })

            .then((res)=>{
                console.log('res:', res);
                this.resultData = res.data.hits.hits.map((x)=>{ return this.formatData(x._source) });
                // console.log('resultData:' , resultData);
            })
            .finally(()=>{
                //  console.log('here console 2021.07.14', this.resultData);
                //  console.log("status:::::::::", this.definedHeaders.value =='status')
                this.resultsLoading = false;
               
                
            })
        },
        formatData: function(data){
            
            // console.log('datadata',data);
            // console.log('data.threat', data.threat);
            //  console.log('data.threat.results', data?.threat?.results?.currently_blacklisted !== null);
            // console.log('threat score', data?.threat?.score);


            const resultObject = {};
            resultObject.host = `${data?.geoip?.ip}`;
            resultObject.location = `${data?.geoip?.country_name} (${data?.geoip?.city_name || ' -- '})`;
            resultObject.as = `${data?.geoip?.as_org} (${data?.geoip?.asn})`;
            resultObject.total = data?.score?.total_score;
            resultObject.threat = data?.score?.threat_score;
            resultObject.vuln = data?.score?.vuln_score;
            resultObject.blacklisted = data?.threat?.blacklisted_count;
            if(data?.threat?.results?.currently_blacklisted != 'null'){
                const categories = data?.threat?.results?.categories;
                const firstSeen = dayjs(data?.threat?.results?.first_seen[0]).format(dateFormat);
                if(data?.threat?.results?.currently_blacklisted == 'true'){
                    resultObject.status = `LISTED (${categories}) (${firstSeen})`
                }
                else{
                    resultObject.status = 'NOT LISTED'
                }
            }
            else{
                resultObject.status = 'NOT LISTED'
            }
            //  resultObject.status = `${data?.threat?.results[0].currently_blacklisted[0]?'LISTED': 'NOT LISTED'}`;
        
            return resultObject
            },
        downloadResultData: function(){
            const csv = Papa.unparse(this.resultData);
            const blob = new Blob([csv]);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob, { type: 'text/csv;charset=utf-8;'} + encodeURIComponent(csv));
            a.download = 'Custom Query '+dayjs().format()+'.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        getRunningCycle(){
            const query = runningCycleQuery;
            axios.post( PTestConn , { 
                    queryurl :  index.nmap+'/_search',
                    querybody : JSON.stringify(query),
             })

            .then(res => {
                this.runningCycle = res.data.aggregations.last_cycle.hits.hits[0]._source['xml-nmap'].cycle[0];
                if( this.runningCycle > 1) {
                    this.finishedCycle = this.runningCycle - 1
                }  
            })
            .finally(()=>{
                this.executeQuery();
            })
        },
        updateStart: function (ammount, unit){
            switch (unit) {
                case `now`:
                    this.startDate = dayjs().format(dateFormat);
                    break;
                case `m-`:
                    this.startDate = dayjs().subtract(ammount, "minute").format(dateFormat);
                    break;
                case `h-`:
                    this.startDate = dayjs().subtract(ammount, "hour").format(dateFormat);
                    break;
                case `d-`:
                    this.startDate = dayjs().subtract(ammount, "day").format(dateFormat);
                    break;
                case `mo-`:
                    this.startDate = dayjs().subtract(ammount, "month").format(dateFormat);
                    break;
                case `y-`:
                    this.startDate = dayjs().subtract(ammount, "years").format(dateFormat);
                    break;
                case `m+`:
                    this.startDate = dayjs().add(ammount,  "minute").format(dateFormat);
                    break;
                case `h+`:
                    this.startDate = dayjs().add(ammount, "hour").format(dateFormat);
                    break;
                case `d+`:
                    this.startDate = dayjs().add(ammount, "day").format(dateFormat);
                    break;
                case `mo+`:
                    this.startDate = dayjs().add(ammount, "month").format(dateFormat);
                    break;
                default:
                    break;
            }
        },
        updateEnd: function (ammount, unit){
            
            switch (unit) {
                case `now`:
                    this.endDate = dayjs().format(dateFormat);
                    break;
                case `m-`:
                    this.endDate = dayjs().subtract(ammount, "minute").format(dateFormat)
                    break;
                case `h-`:
                    this.endDate = dayjs().subtract(ammount, "hour").format(dateFormat)
                    break;
                case `d-`:
                    this.endDate = dayjs().subtract(ammount, "day").format(dateFormat)
                    break;
                case `mo-`:
                    this.endDate = dayjs().subtract(ammount, "month").format(dateFormat)
                    break;
                case `y-`:
                    this.endDate = dayjs().subtract(ammount, "year").format(dateFormat)
                    break;
                case `m+`:
                    this.endDate = dayjs().add(ammount, "minute").format(dateFormat)
                    break;
                case `h+`:
                    this.endDate = dayjs().add(ammount, "hour").format(dateFormat)
                    break;
                case `d+`:
                    this.endDate = dayjs().add(ammount, "day").format(dateFormat)
                    break;
                case `mo+`:
                    this.endDate = dayjs().add(ammount, "month").format(dateFormat)
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style scoped>
.theme--light.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row){
     border-bottom: none !important;
 }

 .v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td{
    padding: 0 6px  ;
    
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th{
    padding: 0 6px  ;
}

</style>